import React from 'react';
import styled from 'styled-components';
import { Tabs, Button } from 'antd';
import { Modal } from 'semantic-ui-react';
import LeaveAndSickTable from './LeaveAndSickTable';
import SearchForm from '../../../shared/components/SearchForm';
import leaveApi from '../../../services/leaveApi';
import Pagination from '../../../shared/components/Pagination';
import { humanizeText } from '../../../utilities/textUtils';
import getTotalPages from '../../../utilities/getTotalPages';
import { leaveAndSickStatuses } from '../../../constants';

const { TabPane } = Tabs;

const CustomSearchForm = styled(SearchForm)``;

const CustomBulkActions = styled.div``;

const CustomPagination = styled(Pagination)``;

const TableContainer = styled.div``;

const Container = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 30px;
  flex-direction: column;

  ${CustomSearchForm} {
    grid-column: 2 / span 1;
    grid-row: 1;
    justify-self: flex-end;
    width: 390px;
  }

  ${CustomBulkActions} {
    grid-column: 1 / span 1;
    grid-row: 1;
  }

  ${TableContainer} {
    grid-column: 1 / span 2;
    grid-row: 3;
  }

  ${CustomPagination} {
    grid-column: 2 / span 1;
    justify-self: flex-end;
    grid-row: 4;
  }
`;

const statusList = [
  {
    tab: 'Requested',
    key: leaveAndSickStatuses.REQUESTED,
  },
  {
    tab: humanizeText(leaveAndSickStatuses.APPROVED),
    key: leaveAndSickStatuses.APPROVED,
  },
  {
    tab: humanizeText(leaveAndSickStatuses.REJECTED),
    key: leaveAndSickStatuses.REJECTED,
  },
];

class LeaveAndSickView extends React.Component {
  state = {
    page: 1,
    status: leaveAndSickStatuses.REQUESTED,
    search: null,
    leaveEntries: null,
    total: null,
    totalPages: null,
    selectedPartners: [],
    approveModalOpen: null,
    loading: false,
  };

  componentDidMount() {
    if (this.props.staffRequestId) {
      this.fetchItems();
      this.fetchStatusCounts();
    }
  }

  // only do network requests (reset states and fetch) that are necessary when the relevant props have changed
  componentDidUpdate(prevProps, prevState) {
    if (prevState.page !== this.state.page) {
      this.setState(
        {
          leaveEntries: null,
          selectedPartners: [],
        },
        this.fetchItems,
      );
    }

    if (
      prevProps.staffRequestId !== this.props.staffRequestId ||
      prevState.search !== this.state.search ||
      prevState.status !== this.state.status
    ) {
      this.setState(
        {
          page: 1,
          leaveEntries: null,
          total: null,
          totalPages: null,
          selectedPartners: [],
        },
        this.fetchItems,
      );

      if (prevState.status === this.state.status) {
        this.setState(
          {
            statusCounts: null,
            allCount: null,
          },
          this.fetchStatusCounts,
        );
      }
    }
  }

  handleUpdate = () => {
    this.fetchItems();
    this.fetchStatusCounts();
  };

  fetchItems = async () => {
    const { status, page, search } = this.state;

    const data = await leaveApi.fetchLeaveEntries({
      staff_request: this.props.staffRequestId,
      status,
      page,
      search,
    });

    this.setState({ leaveEntries: data.results });

    if (this.state.page === 1) {
      this.setState({
        total: data.count,
        totalPages: getTotalPages(data.count, data.results.length),
      });
    }
  };

  fetchStatusCounts = async () => {
    const { search } = this.state;

    const data = await leaveApi.fetchLeaveEntries({
      staff_request: this.props.staffRequestId,
      search,
    });

    this.setState({
      statusCounts: data.status_counts,
      allCount: data.count,
    });
  };

  // handles individual checkboxes
  handleSelect = entry => {
    this.setState(prevState => {
      if (prevState.selectedPartners.filter(selectedEntry => selectedEntry.id === entry.id).length > 0) {
        return {
          selectedPartners: prevState.selectedPartners.filter(selectedEntry => selectedEntry.id !== entry.id),
        };
      }
      return {
        selectedPartners: [...prevState.selectedPartners, entry],
      };
    });
  };

  // handles bulk approval checkbox
  handleSelectAll = () => {
    this.setState(prevState => {
      if (prevState.selectedPartners.length === prevState.leaveEntries.length) {
        return {
          selectedPartners: [],
        };
      }
      return {
        selectedPartners: this.state.leaveEntries,
      };
    });
  };

  bulkApprove = () => {
    this.setState({ loading: true });
    const promises = this.state.selectedPartners.map(entry => {
      return leaveApi.approve(entry.id);
    });

    Promise.all(promises).then(() => {
      this.setState(
        {
          loading: false,
          approveModalOpen: false,
          selectedPartners: [],
        },
        this.fetchItems,
        this.fetchStatusCounts(),
      );
    });
  };

  getCount = status => {
    if (!this.state.statusCounts) return '-';
    if (!status) return this.state.allCount;
    return this.state.statusCounts[status] || '-';
  };

  render() {
    const { ...props } = this.props;

    return (
      <Container {...props}>
        <CustomSearchForm
          placeholder="Search by Partner Name"
          onSubmit={search => this.setState({ search })}
          onClose={() => this.setState({ search: null })}
        />
        {this.state.status === leaveAndSickStatuses.REQUESTED && this.state.selectedPartners.length > 0 && (
          <CustomBulkActions>
            <Modal
              open={this.state.approveModalOpen}
              trigger={
                <Button type="primary" onClick={() => this.setState({ approveModalOpen: true })}>
                  Authorise leave
                </Button>
              }
              size="small"
            >
              <Modal.Header>Please confirm</Modal.Header>
              <Modal.Content>
                You are approving {this.state.selectedPartners.length} leave or sick day requests.
              </Modal.Content>
              <Modal.Actions>
                <Button onClick={() => this.setState({ approveModalOpen: false })}>Cancel</Button>
                <Button
                  loading={this.state.loading}
                  type="primary"
                  onClick={() => {
                    this.bulkApprove();
                  }}
                >
                  Approve
                </Button>
              </Modal.Actions>
            </Modal>
          </CustomBulkActions>
        )}
        <TableContainer>
          <Tabs activeKey={this.state.status} onTabClick={(key, e) => this.setState({ status: key })}>
            {statusList.map(menuItem => (
              <TabPane key={menuItem.key} tab={`${menuItem.tab} (${this.getCount(menuItem.key)})`} />
            ))}
          </Tabs>
          <LeaveAndSickTable
            leaveEntries={this.state.leaveEntries}
            selectedEntries={this.state.selectedPartners}
            onUpdate={this.handleUpdate}
            onSelect={entry => this.handleSelect(entry)}
            onSelectAll={this.handleSelectAll}
            status={this.state.status}
          />
        </TableContainer>
        <CustomPagination
          page={this.state.page}
          total={this.state.total}
          totalPages={this.state.totalPages}
          onPageChange={newPage => this.setState({ page: newPage })}
        />
      </Container>
    );
  }
}

export default LeaveAndSickView;
