import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import { differenceInCalendarDays, differenceInMinutes, format, parse } from 'date-fns';
import { Table, Tooltip } from 'antd';
import EntityLink from '../../../shared/components/EntityLink';
import routes from '../../../routes';
import { colors } from '../../../styles/colors';
import TimesheetEntryStatusLabel from '../../../shared/components/EntryStatusLabel';
import PartnerAvatar from '../../../shared/components/PartnerAvatar';
import Checkbox from '../../../shared/components/forms/Checkbox';
import ClientAvatar from '../../../shared/components/ClientAvatar';
import TimesheetEntryActions from './TimesheetEntryActions';
import { DATE_WITH_DAY_FORMAT, TIME_FORMAT } from '../../../constants';

const CustomOverDay = styled.span`
  color: ${colors.orange};
`;

export default class TimesheetEntriesTable extends React.Component {
  propTypes = {
    entries: PropTypes.array.isRequired,
    selectedEntries: PropTypes.array.isRequired,
  };

  state = {
    disputeModal: null,
    voidModal: null,
    editModal: null,
    manualClockOut: null,
  };

  getDaysDiff = (end, start) => {
    return differenceInCalendarDays(parse(end), parse(start));
  };

  getHoursDiff = (end, start) => {
    return _.round(differenceInMinutes(parse(end), parse(start)) / 60, 1);
  };

  showDaysDiff = entry => {
    const dayDiff = this.getDaysDiff(entry.clock_out_time, entry.clock_in_time);

    if (dayDiff !== 0) {
      return <CustomOverDay>{` +${dayDiff}`}</CustomOverDay>;
    }

    return '';
  };

  getDataSource = () => {
    if (!this.props.entries) return [];

    return this.props.entries.map(entry => {
      return {
        key: entry.id,
        checkbox: (
          <Checkbox
            checked={this.props.selectedEntries.filter(selectedEntry => selectedEntry.id === entry.id).length > 0}
            onChange={() => this.props.onSelect(entry)}
          />
        ),
        entryId: entry.id,
        client: this.props.showStaffRequest && <ClientAvatar client={entry.staff_request.client} />,
        staffRequestId: this.props.showStaffRequest && (
          <EntityLink to={routes.staffRequestTimesheet.replace(':id', entry.staff_request.id)}>
            {entry.staff_request.id}
          </EntityLink>
        ),
        date: format(parse(entry.clock_in_time), DATE_WITH_DAY_FORMAT),
        partnerId: entry.partner && (
          <EntityLink to={`${routes.partners}/${entry.partner.id}`}>{entry.partner.id}</EntityLink>
        ),
        name: entry.partner && <PartnerAvatar partner={entry.partner} />,
        clockIn: format(parse(entry.clock_in_time), TIME_FORMAT),
        // TODO: debug clock out time not being displayed
        clockOut:
          entry.clock_in_time && entry.clock_out_time ? (
            <div>
              {format(parse(entry.clock_out_time), TIME_FORMAT)}
              {this.showDaysDiff(entry)}
            </div>
          ) : (
            ''
          ),
        duration:
          entry.clock_in_time && entry.clock_out_time
            ? `${this.getHoursDiff(entry.clock_out_time, entry.clock_in_time)} h`
            : '',
        status: <TimesheetEntryStatusLabel status={entry.status} />,
        notes: <Tooltip title={entry.notes}>{entry.notes}</Tooltip>,
        actions: <TimesheetEntryActions entry={entry} onUpdate={this.props.onUpdate} />,
      };
    });
  };

  render() {
    const { entries, showStaffRequest } = this.props;
    const columns = [
      {
        title: (
          <Checkbox
            checked={!!this.props.entries && this.props.selectedEntries.length === this.props.entries.length}
            onChange={this.props.onSelectView}
          />
        ),
        dataIndex: 'checkbox',
        width: 20,
      },
      {
        title: 'ID',
        dataIndex: 'entryId',
        width: 40,
      },
      ...(showStaffRequest
        ? [
            {
              title: 'Client',
              dataIndex: 'client',
              width: 150,
            },
          ]
        : []),
      ...(showStaffRequest
        ? [
            {
              title: 'SR ID',
              dataIndex: 'staffRequestId',
              width: 70,
            },
          ]
        : []),
      {
        title: 'Date',
        dataIndex: 'date',
        width: 80,
      },
      {
        title: 'Partner ID',
        dataIndex: 'partnerId',
        width: 50,
      },
      {
        title: 'Name',
        dataIndex: 'name',
        width: 100,
      },
      {
        title: 'Clock In',
        dataIndex: 'clockIn',
        width: 50,
      },
      {
        title: 'Clock Out',
        dataIndex: 'clockOut',
        width: 50,
      },
      {
        title: 'Duration',
        dataIndex: 'duration',
        width: 70,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        width: 70,
      },
      {
        title: 'Notes',
        dataIndex: 'notes',
        width: 100,
      },
      {
        title: 'Actions',
        dataIndex: 'actions',
        width: 100,
      },
    ];

    return (
      <Table dataSource={this.getDataSource()} columns={columns} size="middle" loading={!entries} pagination={false} />
    );
  }
}
