import React from 'react';
import { Link } from 'react-router-dom';
import { Row, List, Avatar, Tooltip, Typography } from 'antd';
import moment from 'moment';
import routes from '../../../../routes';
import textUtils from '../../../utilities/textUtils';
import StatusTag from '../../../shared/components/StatusTag';
import {
  employmentCancellationReasons,
  offerStatuses,
  employmentStatuses,
  pendingContractStatuses,
} from '../../../../constants';

const { Item } = List;
const { Text, Paragraph } = Typography;

function getStatusLabel(item, contractRequired) {
  let { status } = item;
  if (item.status === employmentStatuses.ACTIVE && !item.accepted) {
    status = offerStatuses.INVITED;
  } else if (item.status === offerStatuses.PENDING_CONTRACT) {
    status = contractRequired ? pendingContractStatuses.CONTRACT_SENT : pendingContractStatuses.JOB_OFFER_SENT;
  }
  return status;
}

const WorkerActivityItem = ({ item, staffRequest }) => {
  const positionName = (staffRequest?.position.name || item.position.name).toUpperCase();
  const clientName = staffRequest?.client.name || item?.client?.name;
  const clientLogo = staffRequest?.client.logo || item?.client?.logo;
  const rangeStart = moment(staffRequest?.start_time || item.start_date).format('DD MMM YYYY');
  const rangeEnd = moment(staffRequest?.end_time || item.end_date).format('DD MMM YYYY');
  const employmentStatus = getStatusLabel(item, staffRequest?.client?.contract_required);
  const cancellationReason =
    item.cancellation_reason && employmentCancellationReasons.find(reason => reason.value === item.cancellation_reason);
  const linkRoute = staffRequest
    ? routes.staffRequestDetail.replace(':id', staffRequest?.id)
    : routes.clientsDetail.replace(':id', item?.client?.id);
  return (
    <Item
      style={{ padding: '16px 0' }}
      key={item.id}
      extra={
        <section
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            alignSelf: 'end',
          }}
        >
          <Row style={{ marginRight: '-8px' }}>
            <StatusTag status={employmentStatus} />
          </Row>
          <Row>
            <Tooltip title={moment(item.modified_date).format('ddd, D MMM YYYY, h:mm a')}>
              {moment(item.modified_date).fromNow()}
            </Tooltip>
          </Row>
        </section>
      }
    >
      <Item.Meta
        avatar={<Avatar shape="square" size={48} style={{ height: '100%' }} src={clientLogo} />}
        title={
          <Link to={linkRoute} style={{ color: '#595959' }}>
            <section style={{ marginBottom: '-4px' }}>
              <Row style={{ color: '#8C8C8C', fontSize: '12px' }}>{positionName}</Row>
              {staffRequest && <Text strong>{staffRequest.title}</Text>}
            </section>
          </Link>
        }
        description={
          <>
            <Link to={linkRoute} style={{ color: '#595959' }}>
              <Row>{`${clientName} · ${rangeStart} – ${rangeEnd}`}</Row>
              {staffRequest && <Row>{`SR #${staffRequest?.id} (${textUtils.humanizeText(staffRequest?.status)})`}</Row>}
            </Link>
            {item.cancellation_reason && (
              <section style={{ paddingTop: '16px' }}>
                <Text strong type="secondary">
                  REJECTION/CANCELLATION REASON
                </Text>
                <Paragraph ellipsis={{ rows: 1 }} style={{ maxWidth: '600px' }}>
                  <Text strong>
                    {/*
                          TODO: We are mapping BE value for cancellation reason to FE.
                          TODO: Any changes to cancellation reason in BE WILL BREAK FE.
                          */}
                    {`${
                      cancellationReason ? cancellationReason.title : textUtils.makeFriendly(item.cancellation_reason)
                    }`}
                  </Text>
                  {item.notes && (
                    <Tooltip title={item.notes} placement="topLeft">
                      <Text type="secondary">{` · ${item.notes}`}</Text>
                    </Tooltip>
                  )}
                </Paragraph>
              </section>
            )}
          </>
        }
      />
    </Item>
  );
};

export default WorkerActivityItem;
