/* eslint-disable no-use-before-define */
import moment from 'moment';
import { humanizeText } from '../../utilities/textUtils';
import {
  partnerStatuses,
  DEFAULT_DATETIME_FORMAT,
  DATE_FORMAT,
  offerStatuses,
  staffRequestStatuses,
  NOT_PROVIDED,
} from '../../constants';
import experience_avatar from '../assets/images/experience_avatar.png';
import education_avatar from '../assets/images/education_avatar.png';
import certificate_avatar from '../assets/images/certificate_avatar.png';

const cleanProfile = partner => {
  return {
    id: partner.id,
    imgSrc: partner.image,
    status: partner.status,
    gender: partner.gender,
    createdDate: partner.created_date,
    approvalDate: partner.approval_date,
    trainingStartDate: partner.training_start_date,
    idNumber: partner.identification_number,
    approvedPositions: partner.approved_positions,
    experiences: getExperiences(partner.experiences),
    name: partner.last_name ? `${partner.first_name} ${partner.last_name}` : partner.first_name,
    age: partner.date_of_birth && `${moment().diff(partner.date_of_birth, 'years')}`,
    birthday: partner.date_of_birth ? moment(partner.date_of_birth).format('DD MMM YYYY') : NOT_PROVIDED,
    location:
      partner.address && partner.city && partner.address.country
        ? `${partner.city.name}, ${partner.address.country.name}`
        : NOT_PROVIDED,
    phone: partner.mobile || NOT_PROVIDED,
    email: partner.email || NOT_PROVIDED,
    notes: partner.notes || NOT_PROVIDED,
    lastActive: partner.last_opened_app ? `Last active ${moment(partner.last_opened_app).fromNow()}` : NOT_PROVIDED,
    daysWorked: partner.statistics.days_worked,
    ratings: partner.statistics.total_ratings,
    noShows: partner.statistics.no_shows,
    rejectionReason: partner.rejection_reason || 'N/A',
    suspensionReason: partner.suspension_reason || 'N/A',
    verifiedDate: partner.approval_date ? `Since ${moment(partner.approval_date).format('MMM YYYY')}` : `Not verified`,
    referredBy: partner.referred_by && {
      id: partner.referred_by.id,
      name: `${partner.referred_by.first_name} ${partner.referred_by.last_name}`,
    },
    inSkillQuizExperiment: partner.in_skill_quiz_experiment,
    skillQuizSubmissions: partner.skill_quiz_submissions,
  };
};

// Used to clean worker data nested inside a parent offer object
const cleanWorkerInOffer = (partner, offerStatus, appliedDate, shortlisted, offerId) => {
  return {
    offerId,
    shortlisted,
    appliedDate,
    status: offerStatus,
    id: partner.id,
    imgSrc: partner.image,
    name: `${partner.first_name} ${partner.last_name}`,
    age: partner.date_of_birth && `${moment().diff(partner.date_of_birth, 'years')}`,
    gender: humanizeText(partner.gender),
    idNumber: partner.identification_number,
    birthday: moment(partner.date_of_birth).format('DD MMM YYYY'),
    location: partner.address && partner.address.province ? `${partner.address.province}` : 'Not provided',
    phone: partner.mobile || 'Not provided',
    email: partner.email || 'Not provided',
    lastActive: partner.last_opened_app ? `Active ${moment(partner.last_opened_app).fromNow()}` : 'Not provided',
    daysWorked: partner.statistics && partner.statistics.days_worked,
    ratings: partner.statistics && partner.statistics.total_ratings,
    rejectionReason: partner.rejection_reason ? humanizeText(partner.rejection_reason) : 'N/A',
    verifiedDate: partner.approval_date ? `Since ${moment(partner.approval_date).format('MMM YYYY')}` : 'Not verified',
    experiences: getExperiences(partner.experiences),
  };
};

const cleanWorkerAndApplication = (partner, applicationStatus, createdDate, shortlisted, applicationId) => {
  return {
    applicationId,
    shortlisted,
    createdDate,
    status: applicationStatus,
    id: partner.id,
    imgSrc: partner.image,
    name: `${partner.first_name} ${partner.last_name}`,
    age: partner.date_of_birth && `${moment().diff(partner.date_of_birth, 'years')}`,
    gender: humanizeText(partner.gender),
    idNumber: partner.identification_number,
    birthday: moment(partner.date_of_birth).format('DD MMM YYYY'),
    location: partner.address && partner.address.province ? `${partner.address.province}` : 'Not provided',
    phone: partner.mobile || 'Not provided',
    email: partner.email || 'Not provided',
    lastActive: partner.last_opened_app ? `Active ${moment(partner.last_opened_app).fromNow()}` : 'Not provided',
    daysWorked: partner.statistics && partner.statistics.days_worked,
    ratings: partner.statistics && partner.statistics.total_ratings,
    rejectionReason: partner.rejection_reason ? humanizeText(partner.rejection_reason) : 'N/A',
    verifiedDate: partner.approval_date ? `Since ${moment(partner.approval_date).format('MMM YYYY')}` : 'Not verified',
    experiences: getExperiences(partner.experiences),
  };
};

const cleanWorkerAndEmployment = (partner, employmentStatus, createdDate, employmentId) => {
  return {
    employmentId,
    createdDate,
    status: employmentStatus,
    id: partner.id,
    imgSrc: partner.image,
    name: `${partner.first_name} ${partner.last_name}`,
    age: partner.date_of_birth && `${moment().diff(partner.date_of_birth, 'years')}`,
    gender: humanizeText(partner.gender),
    idNumber: partner.identification_number,
    birthday: moment(partner.date_of_birth).format('DD MMM YYYY'),
    location: partner.address && partner.address.province ? `${partner.address.province}` : 'Not provided',
    phone: partner.mobile || 'Not provided',
    email: partner.email || 'Not provided',
    lastActive: partner.last_opened_app ? `Active ${moment(partner.last_opened_app).fromNow()}` : 'Not provided',
    daysWorked: partner.statistics && partner.statistics.days_worked,
    ratings: partner.statistics && partner.statistics.total_ratings,
    rejectionReason: partner.rejection_reason ? humanizeText(partner.rejection_reason) : 'N/A',
    verifiedDate: partner.approval_date ? `Since ${moment(partner.approval_date).format('MMM YYYY')}` : 'Not verified',
    experiences: getExperiences(partner.experiences),
  };
};

// ~~~~~~~~~~~~~~~~~ Helper functions to get worker details ~~~~~~~~~~~~~~~~~
const getExperiences = experiences => {
  if (!experiences || experiences.length === 0) {
    return [];
  }

  return experiences
    .filter(experience => experience.start_month && experience.start_year)
    .sort((first, second) => {
      const firstStartDate = moment()
        .month(first.start_month)
        .year(first.start_year);
      const secondStartDate = moment()
        .month(second.start_month)
        .year(second.start_year);
      return firstStartDate.isBefore(secondStartDate);
    })
    .slice(0, 3)
    .map(experience => {
      const { start_month, start_year, end_month, end_year } = experience;
      const startDate = moment(`${start_month}/01/${start_year}`).format('MMM YYYY');
      let endDate = moment(`${end_month}/01/${end_year}`).format('MMM YYYY');
      // Some experiences are missing end date. We should replace this with an empty string
      if (endDate === 'Invalid date') {
        endDate = '';
      }
      return {
        position: experience.position.name,
        companyName: experience.company_name,
        period: `${startDate} – ${endDate}`,
      };
    });
};

const getBankingDetails = partner => {
  return [
    { name: 'Tax ID', description: partner.tax_id },
    {
      name: 'Bank',
      description:
        partner.bank_account &&
        partner.bank_account.bank &&
        `${partner.bank_account.bank.name} - ${partner.bank_account.bank.bic}`,
    },
    { name: 'Account name', description: partner.bank_account && partner.bank_account.full_name },
    { name: 'Account number', description: partner.bank_account && partner.bank_account.number },
  ];
};

const getWorkInterests = partner => {
  return [
    {
      name: "I'm approved to work as",
      description: partner.approved_positions && partner.approved_positions.map(position => position.name).join(', '),
    },
    {
      name: "I'm interested to work as",
      description: partner.preferred_positions && partner.preferred_positions.map(position => position.name).join(', '),
    },
    {
      name: "I'm interested to work at",
      description: partner.preferred_job_areas && partner.preferred_job_areas.map(area => area.name).join(', '),
    },
  ];
};

const getWorkDetails = partner => {
  const englishLevel = {
    1: 'Low',
    2: 'Average',
    3: 'Good',
  };

  return [
    {
      name: 'English level',
      description: partner.english_proficiency ? englishLevel[partner.english_proficiency] : '',
    },
  ];
};

const getAccountInformation = partner => {
  if (partner.status === partnerStatuses.ACTIVE) {
    return [
      { name: 'Account ID', description: partner.id },
      {
        name: 'Created Date',
        description: moment(partner.created_date).format(DEFAULT_DATETIME_FORMAT),
      },
      {
        name: 'Modified Date',
        description: moment(partner.modified_date).format(DEFAULT_DATETIME_FORMAT),
      },
      {
        name: 'Approval Date',
        description: partner.approval_date && moment(partner.approval_date).format(DEFAULT_DATETIME_FORMAT),
      },
      {
        name: 'Last Opened App',
        description: partner.last_opened_app && moment(partner.last_opened_app).format(DEFAULT_DATETIME_FORMAT),
      },
    ];
    // eslint-disable-next-line no-else-return
  } else if (partner.status === partnerStatuses.REJECTED) {
    return [
      { name: 'Account ID', description: partner.id },
      {
        name: 'Created Date',
        description: moment(partner.created_date).format(DEFAULT_DATETIME_FORMAT),
      },
      {
        name: 'Modified Date',
        description: moment(partner.modified_date).format(DEFAULT_DATETIME_FORMAT),
      },
      {
        name: 'Rejected Date',
        description: partner.rejection_date && moment(partner.rejection_date).format(DEFAULT_DATETIME_FORMAT),
      },
      {
        name: 'Last Opened App',
        description: partner.last_opened_app && moment(partner.last_opened_app).format(DEFAULT_DATETIME_FORMAT),
      },
    ];
  }
  return [
    { name: 'Account ID', description: partner.id },
    {
      name: 'Created Date',
      description: moment(partner.created_date).format(DEFAULT_DATETIME_FORMAT),
    },
    {
      name: 'Modified Date',
      description: moment(partner.modified_date).format(DEFAULT_DATETIME_FORMAT),
    },
    {
      name: 'Last Opened App',
      description: partner.last_opened_app && moment(partner.last_opened_app).format(DEFAULT_DATETIME_FORMAT),
    },
  ];
};

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

// Each of the detail cards are used for the worker profile page to display summarized worker information
const getDetailCards = partner => {
  const workInterests = getWorkInterests(partner);
  const workDetails = getWorkDetails(partner);
  const accountInfo = getAccountInformation(partner);
  return [
    { title: 'WORK INTERESTS', bodyData: workInterests },
    { title: 'WORK DETAILS', bodyData: workDetails },
    { title: 'ACCOUNT INFORMATION', bodyData: accountInfo },
  ];
};

// ~~~~~~~~~~~~~~~~~ Helper functions to get worker experiences ~~~~~~~~~~~~~~~~~
const sortExperiences = (firstExperience, secondExperience) => {
  if (
    firstExperience.start_year &&
    firstExperience.start_month &&
    secondExperience.start_year &&
    secondExperience.start_month &&
    firstExperience.end_year &&
    firstExperience.end_month &&
    secondExperience.end_year &&
    secondExperience.end_month
  ) {
    const firstStartDate = moment({ year: firstExperience.start_year, month: firstExperience.start_month });
    const secondStartDate = moment({ year: secondExperience.start_year, month: secondExperience.start_month });
    const firstEndDate = moment({ year: firstExperience.end_year, month: firstExperience.end_month });
    const secondEndDate = moment({ year: secondExperience.end_year, month: secondExperience.end_month });

    if (firstEndDate.isBefore(secondEndDate)) {
      return 1;
    }
    if (firstEndDate.isAfter(secondEndDate)) {
      return -1;
    }
    if (firstStartDate.isBefore(secondStartDate)) {
      // Don't pair with firstEndDate.isBefore(secondEndDate)
      return 1;
    }
    if (firstStartDate.isAfter(secondStartDate)) {
      // Don't pair with firstEndDate.isAfter(secondEndDate)
      return -1;
    }
  }
  return 0;
};

const getCertificates = partner => {
  if (!partner || !partner.certificates) {
    return [];
  }
  return partner.certificates.map(certi => {
    return {
      id: certi.id,
      title: certi.name,
      subTitle: certi.institution,
      period: certi.year,
      imgSrc: certificate_avatar,
    };
  });
};

const getEducations = partner => {
  if (!partner || !partner.educations) {
    return [];
  }
  return partner.educations.map(edu => {
    return {
      id: edu.id,
      title: edu.school,
      subTitle: `${humanizeText(edu.degree)} - ${edu.field_of_study}`,
      period: edu.graduation_year,
      imgSrc: education_avatar,
    };
  });
};

const getOtherExperiences = partner => {
  if (!partner || !partner.experiences) {
    return [];
  }
  return partner.experiences.sort(sortExperiences).map(experience => {
    return {
      id: experience.id,
      title: experience.position.name,
      subTitle: experience.company_name,
      period: `${moment(experience.start_month, 'MM').format('MMM')} ${experience.start_year} - ${moment(
        experience.end_month,
        'MM',
      ).format('MMM')} ${experience.end_year} · ${formatExperienceDuration(experience.months)}`,
      imgSrc: experience_avatar,
      description: experience.description,
    };
  });
};

const formatExperienceDuration = months => {
  if (months > 12) {
    const monthsCount = months % 12;
    const yearsCount = Math.floor(months / 12);
    return `${yearsCount} year${yearsCount > 1 ? 's' : ''}, ${monthsCount} month${monthsCount > 1 ? 's' : ''}`;
  }
  return `${months} month${months > 1 ? 's' : ''}`;
};

const cleanWorkmateExperiences = employments => {
  if (employments.length === 0) {
    return [];
  }
  return employments.sort(sortExperiences).map(employment => {
    const { start_date, end_date } = employment;
    const startDate = start_date ? moment(start_date).format(DATE_FORMAT) : '-';
    const endDate = end_date ? moment(end_date).format(DATE_FORMAT) : '-';
    const validPeriod = start_date && end_date;
    const monthPeriod = validPeriod ? moment(end_date).diff(moment(start_date), 'months') : '-';
    const range = validPeriod ? `${startDate} - ${endDate}` : 'Date not specified';
    const rangeDescription = validPeriod ? formatExperienceDuration(monthPeriod) : '-';

    return {
      id: employment.id,
      title: employment.position?.name,
      subtitle: employment.client?.name,
      period: `${range} · ${rangeDescription}`,
      imgSrc: employment.client?.logo,
    };
  });
};

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// Each of the experience cards are used for the worker profile page to display work related experiences
const getExperienceCards = (partner, employments) => {
  const workmateExperiences = cleanWorkmateExperiences(employments);
  const otherWorkExperiences = getOtherExperiences(partner);
  const educations = getEducations(partner);
  const certificates = getCertificates(partner);
  return [
    { header: 'Workmate experience', data: workmateExperiences },
    { header: 'Other work experience', data: otherWorkExperiences },
    { header: 'Education', data: educations },
    { header: 'Certificates', data: certificates },
  ];
};

// ~~~~~~~~~~~~~~~~~ Helper functions to get filters for worker activities ~~~~~~~~~~~~~~~~~
const getCheckboxFilters = (jobStatusFilters, workerStatusFilters) => {
  const jobStatusList = Object.values(staffRequestStatuses).map(srStatus => {
    return { id: srStatus, name: humanizeText(srStatus) };
  });
  const workerStatusList = Object.values(offerStatuses).map(offerStatus => {
    return { id: offerStatus, name: humanizeText(offerStatus) };
  });

  const checkboxFilters = [
    {
      title: `Job status ${jobStatusFilters.length > 0 ? `(${jobStatusFilters.length})` : ''}`,
      selectAllTitle: 'All staff requests',
      options: jobStatusList,
      selectedOptions: jobStatusFilters,
      param: 'staff_request_status',
      filterCount: jobStatusFilters.length,
      status: Object.values(staffRequestStatuses).join(','),
    },
    {
      title: `Worker status ${workerStatusFilters.length > 0 ? `(${workerStatusFilters.length})` : ''}`,
      selectAllTitle: 'All activities',
      options: workerStatusList,
      selectedOptions: workerStatusFilters,
      param: 'status',
      filterCount: workerStatusFilters.length,
      status: Object.values(offerStatuses).join(','),
    },
  ];
  return checkboxFilters;
};

// filter out unique clients that the worker has an offer with
const getClientList = offers => {
  if (!offers) return [];
  const clientObjs = offers && offers.map(offer => offer.staff_request.client);
  const clientIds = offers && offers.map(offer => offer.staff_request.client.id);
  const uniqClientIds = [...new Set(clientIds)];
  const uniqClientObjs = [];
  uniqClientIds.forEach(clientId => {
    const client = clientObjs.find(clientObj => clientObj.id === clientId);
    uniqClientObjs.push({ name: `${client.name} (${clientId})`, id: clientId });
  });
  return uniqClientObjs;
};

// Reformat filtered value IDs taken from url to match Select options value format
const getDefaultValues = (filters, options) => {
  return filters.map(value => {
    // ID retrieved from URL is a string not number
    const filteredId = parseInt(value, 10);
    const filteredObj = options.find(optionObj => optionObj.id === filteredId);
    return { key: filteredObj.name, label: `${filteredObj.name} (${filteredObj.id})` };
  });
};

const getFiltersWithSearch = (selectedClientFilters, selectedPositionFilters, clientOptions, positionOptions) => {
  const filtersWithSearch = [
    {
      title: `Client ${selectedClientFilters.length > 0 ? `(${selectedClientFilters.length})` : ''}`,
      options: clientOptions,
      param: 'client',
      filterCount: selectedClientFilters.length,
      defaultValue: selectedClientFilters && getDefaultValues(selectedClientFilters, clientOptions),
    },
    {
      title: `Job position ${selectedPositionFilters.length > 0 ? `(${selectedPositionFilters.length})` : ''}`,
      options: positionOptions || [],
      param: 'position',
      filterCount: selectedPositionFilters.length,
      defaultValue: selectedPositionFilters && getDefaultValues(selectedPositionFilters, positionOptions),
    },
  ];
  return filtersWithSearch;
};
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

export default {
  cleanProfile,
  cleanWorkerInOffer,
  cleanWorkerAndApplication,
  cleanWorkerAndEmployment,
  getBankingDetails,
  getClientList,
  getDetailCards,
  getExperienceCards,
  getCheckboxFilters,
  getFiltersWithSearch,
  getExperiences,
};
