import React, { Fragment } from 'react';
import { Typography, Icon, Modal, Button } from 'antd';
import { Link } from 'react-router-dom';
import numeral from 'numeral';
import moment from 'moment-timezone';
import routes from '../../../routes';
import staffRequestUtils from '../../utilities/staffRequestUtils';
import { DATE_FORMAT } from '../../../constants';

const { Text, Title, Paragraph } = Typography;

class StaffRequestHighlight extends React.Component {
  state = {
    openDescriptionModal: false,
  };

  newlineToLineBreak = text => {
    return typeof text === 'string'
      ? text.split('\n').map((item, key) => {
          return (
            <Fragment key={key}>
              {item}
              <br />
            </Fragment>
          );
        })
      : '';
  };

  render() {
    const { staffRequest, footNote } = this.props;
    const { position, id, title, description, client, location, start_time, end_time, auto_hire } = staffRequest;

    const { openDescriptionModal } = this.state;
    const timezone = location.address.area.city.timezone;

    return (
      <>
        <Text strong type="secondary" style={{ paddingBottom: '8px' }}>
          <Text style={{ paddingRight: 5 }} strong type="secondary">
            SR#{id} • {position.name.toUpperCase()}
          </Text>
          {auto_hire && (
            <Text strong type="secondary">
              • <Icon type="user-add" /> AUTO HIRING
            </Text>
          )}
        </Text>
        <Title strong level={4} style={{ margin: 0, paddingBottom: '4px', wordWrap: 'break-word' }}>
          {title}
        </Title>
        <Paragraph style={{ marginBottom: '0' }} ellipsis={{ rows: 5, expandable: false }}>
          <p>{this.newlineToLineBreak(description)}</p>
        </Paragraph>
        <Button
          type="link"
          onClick={() => {
            this.setState({ openDescriptionModal: true });
          }}
          style={{ padding: '0' }}
        >
          View more
        </Button>
        {/* Rows of icons and value that is highlighted */}
        <section style={{ margin: '14px 0 16px 0' }}>
          <Paragraph style={{ marginBottom: '4px' }}>
            <Icon type="shop" style={{ color: '#BFBFBF', marginRight: '8px' }} />
            <Link to={routes.clientsDetail.replace(':id', client.id)}>{client.name}</Link>
          </Paragraph>
          <Paragraph style={{ marginBottom: '4px' }}>
            <Icon type="environment" style={{ color: '#BFBFBF', marginRight: '8px' }} />
            {location.name}
            {' · '}
            {location.address.latitude && location.address.longitude && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`https://www.google.com/maps/search/?api=1&query=${location.address.latitude},${location.address.longitude}`}
              >
                Google Maps
              </a>
            )}
          </Paragraph>
          <Paragraph style={{ marginBottom: '4px' }}>
            <Icon type="calendar" style={{ color: '#BFBFBF', marginRight: '8px' }} />
            {moment.tz(start_time, timezone).format(DATE_FORMAT)} -{' '}
            {end_time ? moment.tz(end_time, timezone).format(DATE_FORMAT) : 'Permanent'}
          </Paragraph>
          <Paragraph style={{ marginBottom: '4px' }}>
            <Icon type="dollar" style={{ color: '#BFBFBF', marginRight: '8px' }} />
            {`${numeral(staffRequestUtils.getEffectiveWageAmount(staffRequest)).format(
              '0,0.00',
            )} ${staffRequest.wage_type.replace('_', ' ')}`}
          </Paragraph>
        </section>
        <section>{footNote}</section>
        <Modal
          title={title}
          visible={openDescriptionModal}
          onCancel={() => this.setState({ openDescriptionModal: false })}
          footer={null}
          style={{ whiteSpace: 'pre-line' }}
        >
          <p>{this.newlineToLineBreak(description)}</p>
        </Modal>
      </>
    );
  }
}

export default StaffRequestHighlight;
