import { minBy, maxBy, find } from 'lodash';
import moment from 'moment-timezone';
import { derivedWorkingStatus, staffRequestContractStatus, employmentStatuses } from '../../constants';

// special localized state for attendance page and shifts page
const getDerivedWorkingStatus = (date, employment) => {
  if (employment.direct_hire) {
    return derivedWorkingStatus.WORKING;
  }

  const relativeDate = moment(date);
  const { contracts } = employment;

  const signedContracts = contracts.filter(contract => contract.status === staffRequestContractStatus.SIGNED);
  const earliestContract = minBy(signedContracts, contract => moment(contract.start_date));
  const latestContract = maxBy(signedContracts, contract => moment(contract.end_date));

  if (signedContracts.length === 0) {
    return derivedWorkingStatus.CONTRACT_NOT_SIGNED;
  }
  if (relativeDate.isBefore(earliestContract.start_date)) {
    return derivedWorkingStatus.EMPLOYMENT_NOT_STARTED;
  } else if (relativeDate.isAfter(latestContract.end_date)) {
    if (employment.status === employmentStatuses.CANCELLED) {
      return derivedWorkingStatus.RESIGNED;
    } else {
      const hasFutureUnsignedContract = find(
        contracts,
        contract =>
          contract.status === staffRequestContractStatus.PENDING_SIGNATURE &&
          relativeDate.isSameOrBefore(moment(contract.end_date)),
      );
      if (hasFutureUnsignedContract) {
        return derivedWorkingStatus.CONTRACT_NOT_SIGNED;
      }
    }
    return derivedWorkingStatus.EMPLOYMENT_ENDED; // default case
  } else {
    // is within employment period
    const hasValidSignedContractForDate = find(
      contracts,
      contract =>
        contract.status === staffRequestContractStatus.SIGNED &&
        relativeDate.isBetween(moment(contract.start_date), moment(contract.end_date), undefined, '[]'),
    );
    if (!hasValidSignedContractForDate) {
      return derivedWorkingStatus.ON_BREAK;
    } else {
      return derivedWorkingStatus.WORKING;
    }
  }
};

export default { getDerivedWorkingStatus };
