import React from 'react';
import { Icon, Upload } from 'antd';

/*
 * Wrapper around the Ant Upload component to support avatar image uploads
 *
 * Supports:
 * 1. Single image upload
 * 2. Shows existing image URLs as previews
 *
 * */
export default class AvatarInput extends React.Component {
  render() {
    return (
      <Upload
        {...this.props}
        name="file"
        accept=".jpg,.jpeg,.png"
        listType="picture-card"
        customRequest={({ file, onSuccess }) => {
          setTimeout(() => {
            onSuccess('ok');
          }, 0);
        }}
      >
        <div>
          <Icon type="plus" />
          Upload
        </div>
      </Upload>
    );
  }
}
