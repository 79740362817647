import React from 'react';
import { withTranslation } from 'react-i18next';
import { Card, Typography, Spin, Row, Col, Tooltip } from 'antd';
import { QuestionCircleOutlined, MinusOutlined } from '@ant-design/icons';
import { colors } from '../../../../styles/colors';
import EndingEmploymentsLink from './EndingEmploymentsLink';

const { Text, Title } = Typography;

const WorkforceStatsCard = ({ t, loading, employmentStats, empty, onEndingEmploymentsClick }) => {
  let activeWorkersCount;
  let invitedWorkersCount;
  let endingEmploymentsCount;
  if (employmentStats) {
    const { workforce_counts } = employmentStats;
    activeWorkersCount = workforce_counts.accepted;
    invitedWorkersCount = workforce_counts.invited;
    endingEmploymentsCount = workforce_counts.ending;
  }
  return (
    <Card
      style={{
        boxShadow: '0 2px 6px #00000026',
        height: '100px',
      }}
      bodyStyle={{ padding: '22px 48px' }}
    >
      <Row gutter={36} type="flex">
        <Col span={7} style={{ borderRight: `1px solid ${colors.componentBorder}` }}>
          <Row>
            <Text type="secondary" style={{ fontSize: 12, fontWeight: 600 }}>
              {t('activeWorkers').toUpperCase()}
            </Text>
            <Tooltip title={t('activeWorkersToolTip')}>
              <QuestionCircleOutlined style={{ color: colors.darkGrey, marginLeft: '5px' }} />
            </Tooltip>
          </Row>
          <Row>
            <Title level={3} style={{ margin: 0 }}>
              {empty ? (
                <MinusOutlined style={{ opacity: 0.25 }} />
              ) : loading ? (
                <Spin data-testid={'stats-card-spinner'} />
              ) : (
                activeWorkersCount
              )}
            </Title>
          </Row>
        </Col>
        <Col span={8} style={{ paddingLeft: '40px', borderRight: `1px solid ${colors.componentBorder}` }}>
          <Row>
            <Text type="secondary" style={{ fontSize: 12, fontWeight: 600 }}>
              {t('invitedWorkers').toUpperCase()}
            </Text>
            <Tooltip title={t('invitedWorkersToolTip')}>
              <QuestionCircleOutlined style={{ color: colors.darkGrey, marginLeft: '5px' }} />
            </Tooltip>
          </Row>
          <Row>
            <Title level={3} style={{ margin: 0 }}>
              {empty ? (
                <MinusOutlined style={{ opacity: 0.25 }} />
              ) : loading ? (
                <Spin data-testid={'stats-card-spinner'} />
              ) : (
                invitedWorkersCount
              )}
            </Title>
          </Row>
        </Col>
        <Col span={9} style={{ paddingLeft: '40px' }}>
          <Row>
            <Text type="secondary" style={{ fontSize: 12, fontWeight: 600 }}>
              {t('endingEmployment').toUpperCase()}
            </Text>
            <Tooltip title={t('endingEmploymentTooltip')}>
              <QuestionCircleOutlined style={{ marginLeft: '5px', color: colors.darkGrey }} />
            </Tooltip>
          </Row>
          <Row style={{ display: 'flex' }}>
            <Title level={3} style={{ margin: 0 }}>
              {empty ? (
                <MinusOutlined style={{ opacity: 0.25 }} />
              ) : loading ? (
                <Spin data-testid={'stats-card-spinner'} />
              ) : (
                <EndingEmploymentsLink
                  endingEmploymentsCount={endingEmploymentsCount}
                  onClick={onEndingEmploymentsClick}
                />
              )}
            </Title>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default withTranslation()(WorkforceStatsCard);
