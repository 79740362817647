/* eslint-disable no-param-reassign */
import axios from 'axios';

const api = axios.create({});

// Whenever a request is made, we attach our token in the header
api.interceptors.request.use(config => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `FirebaseToken ${token}`;
  }
  return config;
});

// Whenever a response is received
api.interceptors.response.use(response => {
  // Any status code that lie within the range of 2xx cause this function to trigger
  return response.data;
});

export default { api };
