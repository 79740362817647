import React from 'react';
import { Button, Col, Divider, Popover, Row, Select, Tree } from 'antd';
import equal from 'fast-deep-equal';
import { withTranslation } from 'react-i18next';
import { colors } from '../../../styles/colors';

const { TreeNode } = Tree;
const { Option } = Select;

class TreeCheckboxFilter extends React.Component {
  state = {
    checkedKeys: [],
    visible: false,
  };

  componentDidUpdate(prevProps) {
    const { checkedKeys } = this.props;
    if (!equal(checkedKeys, prevProps.checkedKeys)) {
      this.setState({ checkedKeys });
    }
  }

  onCancel = () => {
    this.setState({ visible: false }, this.resetChanges);
  };

  onApply = () => {
    const { checkedKeys } = this.state;
    const { onApply } = this.props;
    this.setState(
      {
        visible: false,
      },
      () => onApply && onApply(checkedKeys),
    );
  };

  getKeysFromTreeData = (treeData, key = null) => {
    const keys = [];
    treeData.forEach(item => {
      let found = false;
      if (key === null || key === item.key) {
        keys.push(item.key);
        found = true;
      }
      if (item.children) {
        this.getKeysFromTreeData(item.children, !found ? key : null).forEach(subItem => {
          keys.push(subItem);
        });
      }
    });
    return keys;
  };

  onDeselect = item => {
    const { treeData } = this.props;
    const { checkedKeys } = this.state;
    const uncheckedKeys = this.getKeysFromTreeData(treeData, item.key);
    const newCheckedKeys = [];

    if (checkedKeys) {
      checkedKeys.forEach(key => {
        if (!uncheckedKeys.includes(key)) {
          newCheckedKeys.push(key);
        }
      });
    }

    this.setState({ checkedKeys: newCheckedKeys });
  };

  onSelect = value => {
    const { checkedKeys } = this.state;
    const newCheckedKeys = [];

    if (checkedKeys) {
      checkedKeys.forEach(key => {
        newCheckedKeys.push(key);
      });
    }

    const tokens = value.key.split('__');
    newCheckedKeys.push(tokens[0]);

    this.setState({ checkedKeys: newCheckedKeys });
  };

  onCheck = checkedKeys => {
    this.setState({ checkedKeys });
  };

  onVisibleChange = visible => {
    this.setState({ visible }, () => !visible && this.resetChanges());
  };

  resetChanges = () => {
    const { checkedKeys } = this.props;
    this.setState({ checkedKeys });
  };

  getCheckedItems = treeData => {
    const { checkedKeys } = this.state;
    const { formatTag } = this.props;
    const items = [];
    treeData.forEach(item => {
      if (checkedKeys?.includes(item.key)) {
        const label = formatTag ? formatTag(item) : item.title;
        items.push({ ...item, label });
      } else if (item.children) {
        const subItems = this.getCheckedItems(item.children);
        if (subItems.length === item.children.length) {
          // Just append the parent item if all of its child items are selected.
          items.push({ ...item, label: item.title });
        } else {
          subItems.forEach(subItem => {
            items.push(subItem);
          });
        }
      }
    });
    return items;
  };

  renderOptions = treeData => {
    return treeData.map(item => {
      return (
        <Option key={item.key} value={`${item.key}__${item.title}`}>
          {item.title}
        </Option>
      );
    });
  };

  renderTreeNodes = treeData => {
    return treeData.map(item => {
      if (item.children) {
        return (
          <TreeNode title={item.title} key={item.key} dataRef={item}>
            {this.renderTreeNodes(item.children)}
          </TreeNode>
        );
      }
      return <TreeNode blockNode key={item.key} {...item} />;
    });
  };

  flattenTreeData = treeData => {
    const items = [];
    treeData.forEach(item => {
      items.push(item);
      if (item.children) {
        this.flattenTreeData(item.children).forEach(subItem => {
          items.push(subItem);
        });
      }
    });
    return items;
  };

  render() {
    const { label, placeholder, treeData, t, showActionButtons = false } = this.props;
    const { visible } = this.state;

    const checkedItems = this.getCheckedItems(treeData);
    const treeCheckedKeys = checkedItems.map(item => item.key);
    const flattenedTreeData = this.flattenTreeData(treeData);

    const selectProps = {
      dropdownStyle: { display: 'none' },
      labelInValue: true,
      mode: 'multiple',
      onDeselect: this.onDeselect,
      onSelect: this.onSelect,
      placeholder,
      style: { marginTop: 4, width: '100%' },
      value: checkedItems,
    };
    const treeProps = {
      checkable: true,
      checkedKeys: treeCheckedKeys,
      onCheck: this.onCheck,
      selectable: false,
    };
    return (
      <Popover
        visible={visible}
        trigger={['click']}
        placement="bottomLeft"
        onVisibleChange={this.onVisibleChange}
        overlayStyle={{ zIndex: 2 }}
        overlayClassName="ant-popover-wrapper tree-checkbox-filter"
        content={
          <>
            <Select {...selectProps}>{this.renderOptions(flattenedTreeData)}</Select>
            <Tree {...treeProps}>{this.renderTreeNodes(treeData)}</Tree>
            {showActionButtons && (
              <>
                <Divider style={{ margin: '12px 0' }} />
                <Row type="flex" justify="space-between" style={{ paddingBottom: '12px' }}>
                  <Col>
                    <Button size="small" onClick={this.onCancel}>
                      {t('Cancel')}
                    </Button>
                  </Col>
                  <Col>
                    <Button size="small" className="ant-btn-v2-primary" onClick={this.onApply}>
                      {t('apply')}
                    </Button>
                  </Col>
                </Row>
              </>
            )}
          </>
        }
      >
        <Button
          onClick={this.toggleVisiblity}
          style={{ marginRight: 12, width: '100%' }}
          {...(treeCheckedKeys.length > 0 && {
            style: {
              borderColor: colors.blue,
              backgroundColor: colors.white,
              color: colors.blue,
              zIndex: 1,
              marginRight: 12,
              width: '100%',
            },
          })}
        >
          {label}
          {treeCheckedKeys.length > 0 ? ` (${treeCheckedKeys.length})` : ''}
        </Button>
      </Popover>
    );
  }
}

export default withTranslation()(TreeCheckboxFilter);
