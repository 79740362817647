import React from 'react';
import styled from 'styled-components';
import { Placeholder } from 'semantic-ui-react';
import { fontO10b } from '../../styles/fonts';
import { colors } from '../../styles/colors';

const Table = styled.div`
  display: table;
  white-space: nowrap;
  min-width: 100%;
  table-layout: fixed;
`;

Table.Cell = styled.div`
  display: table-cell;
  padding: 10px 15px;
  border-bottom: 1px solid ${colors.lightGrey};
  vertical-align: middle;
  text-overflow: ellipsis;
  overflow: hidden;

  text-align: ${props => props.align || 'left'};
`;

Table.Header = styled.div`
  display: table-cell;
  ${fontO10b};
  text-transform: uppercase;
  background: ${colors.lightGrey};
  color: ${colors.darkGrey};
  padding: 10px 15px;
  vertical-align: middle;

  text-align: ${props => props.align || 'left'};
`;

Table.Headers = styled.div`
  display: table-header-group;

  ${Table.Header} {
    position: ${props => (props.sticky ? 'sticky' : 'static')};
    top: 0;
    z-index: 10;
  }
`;

Table.Body = styled.div`
  display: table-row-group;
`;

Table.Row = styled.div`
  display: table-row;
  overflow: visible;
`;

Table.CellPlaceholder = props => (
  <Table.Cell>
    <Placeholder {...props}>
      <Placeholder.Line />
    </Placeholder>
  </Table.Cell>
);

export default Table;
