import styled from 'styled-components';
import React from 'react';
import { Icon } from 'semantic-ui-react';
import { colors } from '../../../styles/colors';

const Container = styled.div`
  display: inline-block;
  cursor: pointer;
  color: ${colors.blue};
`;

export default ({ children, ...props }) => (
  <Container {...props}>
    <Icon name="plus circle" />
    {children}
  </Container>
);
