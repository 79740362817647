import React from 'react';
import { Form, Input, Modal } from 'antd';
import partnerApi from '../../../../services/partnerApi';

class ResetPasswordModal extends React.Component {
  state = {
    loading: false,
  };

  handleSubmit = () => {
    const { form, partner } = this.props;

    form.validateFieldsAndScroll((errors, values) => {
      if (!errors) {
        this.setState({
          loading: true,
        });
        partnerApi.setPassword(partner.id, values.password).then(() => {
          this.setState({
            loading: false,
          });
          this.handleClose();
        });
      }
    });
  };

  handleClose = () => {
    const { form, onCancel } = this.props;
    form.resetFields();
    onCancel();
  };

  render() {
    const { loading } = this.state;
    const { visible } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <Modal
        onOk={this.handleSubmit}
        okText="Save"
        confirmLoading={loading}
        onCancel={this.handleClose}
        title={<span style={{ fontSize: '20px' }}>Reset Password</span>}
        visible={visible}
      >
        <Form hideRequiredMark>
          <Form.Item hasFeedback colon={false} label="New password" style={{ width: '50%', margin: '-14px 0 6px 0' }}>
            {getFieldDecorator('password', {
              rules: [
                {
                  required: true,
                  message: 'Please enter a password',
                },
                {
                  min: 7,
                  message: 'Please enter at least 7 characters',
                },
              ],
            })(<Input placeholder="Password" />)}
          </Form.Item>
        </Form>
        Minimum 7 characters
      </Modal>
    );
  }
}

export default Form.create()(ResetPasswordModal);
