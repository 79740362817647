import React, { useState } from 'react';
import {
  Divider,
  Input,
  Modal,
  Form,
  Row,
  Col,
  Tooltip,
  Button,
  message,
  Select,
  Typography,
  Switch,
  Radio,
  Checkbox,
} from 'antd';
import { v4 as uuidv4 } from 'uuid';
import { CloseOutlined, LinkOutlined, PlusCircleOutlined } from '@ant-design/icons';
import PhoneInput from 'react-phone-number-input';
import Text from 'antd/lib/typography/Text';
import CopyToClipboard from 'react-copy-to-clipboard';
import { isValidPhoneNumber } from 'libphonenumber-js/mobile';
import { errorCodes, mobileCountryCodes } from '../../../../constants';
import { colors } from '../../../../styles/colors';
import partnerApi from '../../../../services/partnerApi';
import textUtils from '../../../../utilities/textUtils';

const { Item } = Form;
const { Title } = Typography;
const { Option } = Select;
const REQUIRED_FIELD = 'This field is required';

const OVERTIME_ROUNDING_RULES = {
  NONE: 'none',
  PRORATED: 'prorated',
  ROUND_UP_TO_30_MINUTES: 'round_up_to_30_minutes',
  ROUND_UP_TO_60_MINUTES: 'round_up_to_60_minutes',
  ROUND_DOWN: 'round_down',
};

const PAYMENT_FREQUENCY = {
  WEEKLY: 'weekly',
  BIWEEKLY: 'bi-weekly',
  MONTHLY: 'monthly',
};

// Remove this when updated to the latest version
class PhoneInputWrapper extends React.Component {
  inputRef = React.createRef();

  render() {
    return <PhoneInput ref={this.inputRef} {...this.props} />;
  }
}

const DirectJobInviteModal = ({ visible, onCancel, country, form, staffRequestId, directJobInviteLink, onUpdate }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [workers, setWorkers] = useState([{ fieldKey: uuidv4() }]);
  const { getFieldDecorator, getFieldValue, setFields } = form;
  const mobileCountryCodeLabels = {};

  Object.keys(mobileCountryCodes).forEach(key => {
    mobileCountryCodeLabels[key] = mobileCountryCodes[key].label;
  });

  const onSubmit = async () => {
    form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        setIsSubmitting(true);
        try {
          const payload = workers.map(mobile => {
            return {
              first_name: values.firstName[mobile.fieldKey],
              last_name: values.lastName[mobile.fieldKey],
              mobile: values.mobile[mobile.fieldKey],
              email: values.email[mobile.fieldKey],
            };
          });
          await partnerApi.bulkCreateJobInvitePartner({
            workers: payload,
            staff_request_id: staffRequestId,
            ...values,
          });
          onUpdate();
          onCancel();
          message.success('Workers successfully invited and notified by Email');
        } catch (error) {
          if (error.response?.data.code === errorCodes.PARTNER_WITH_MOBILE_EXISTS) {
            const existingMobiles = Object.values(error.response.data.detail);
            const fields = {};
            workers.forEach(worker => {
              const mobileValue = getFieldValue(`mobile[${worker.fieldKey}]`);
              if (existingMobiles.includes(mobileValue)) {
                fields[`mobile[${worker.fieldKey}]`] = {
                  value: mobileValue,
                  errors: [new Error('Number already registered')],
                };
              }
            });
            setFields(fields);
          } else if (error.response?.data.code === errorCodes.PARTNER_WITH_EMAIL_EXISTS) {
            const existingEmails = Object.values(error.response.data.detail);
            const fields = {};
            workers.forEach(worker => {
              const emailValue = getFieldValue(`email[${worker.fieldKey}]`);
              if (existingEmails.includes(emailValue)) {
                fields[`email[${worker.fieldKey}]`] = {
                  value: emailValue,
                  errors: [new Error('Email already registered')],
                };
              }
            });
            setFields(fields);
          } else {
            message.error('Sorry! An error occurred in submission, please contact support');
          }
        } finally {
          setIsSubmitting(false);
        }
      }
    });
  };

  const handleCopyToClipboard = () => {
    message.success('Link copied to clipboard');
  };

  const handleRemoveWorker = selectedKey => () => {
    const filteredMobiles = workers.filter((_, key) => key !== selectedKey);
    setWorkers(filteredMobiles);
  };

  const handleAddWorker = () => {
    setWorkers(workers.concat({ fieldKey: uuidv4() }));
  };

  const formItems = workers?.map((mobile, key) => (
    <Row key={key} align="middle">
      <Col span={2} style={{ marginRight: '8px', marginLeft: '8px', width: '5%' }}>
        <Item style={{ marginBottom: 0 }}>{key + 1}.</Item>
      </Col>
      <Col span={7} style={{ marginRight: '8px', width: '15%' }}>
        <Item style={{ marginBottom: 0 }}>
          {getFieldDecorator(`firstName[${mobile.fieldKey}]`, {
            rules: [{ required: true, message: REQUIRED_FIELD }],
          })(<Input placeholder="First name" style={{ marginLeft: '4px' }} />)}
        </Item>
      </Col>
      <Col span={7} style={{ marginRight: '15px', width: '15%' }}>
        <Item style={{ marginBottom: 0 }}>
          {getFieldDecorator(`lastName[${mobile.fieldKey}]`, {
            rules: [{ required: true, message: REQUIRED_FIELD }],
          })(<Input placeholder="Last Name" style={{ marginLeft: '4px' }} />)}
        </Item>
      </Col>
      <Col span={7} style={{ marginRight: '15px', width: '25%' }}>
        <Item style={{ marginBottom: 0 }}>
          {getFieldDecorator(`mobile[${mobile.fieldKey}]`, {
            validateFirst: true,
            rules: [
              { required: true, message: REQUIRED_FIELD },
              {
                validator: (_, value) => isValidPhoneNumber(value, country.code),
                message: 'Number is not valid',
              },
            ],
            initialvalue: mobile?.value,
          })(
            <PhoneInputWrapper
              international
              addInternationalOption={false}
              countries={Object.keys(mobileCountryCodes)}
              labels={mobileCountryCodeLabels || ''}
              defaultCountry={country.code}
              onCountryChange={countryValue => {
                const mobilesValue = [...this.state.mobiles];
                mobilesValue.splice(key, 1, { ...mobilesValue[key], countryValue });
                this.setState({ mobiles: mobilesValue });
              }}
              numberInputProps={{ className: 'ant-input' }}
              style={{ margin: '4px auto' }}
            />,
          )}
        </Item>
      </Col>
      <Col span={7} style={{ marginRight: '8px', width: '20%' }}>
        <Item style={{ marginBottom: 0 }} type="email">
          {getFieldDecorator(`email[${mobile.fieldKey}]`, {
            rules: [{ required: true, type: 'email', message: 'Email is not valid' }],
          })(<Input placeholder="Email" style={{ marginLeft: '4px' }} />)}
        </Item>
      </Col>
      {workers.length > 1 ? (
        <Button onClick={handleRemoveWorker(key)} style={{ marginTop: 4, padding: 'auto 5px', marginLeft: '8px' }}>
          <CloseOutlined style={{ color: colors.red }} />
        </Button>
      ) : null}
    </Row>
  ));

  const overtimeRoundingRuleOptions = Object.keys(OVERTIME_ROUNDING_RULES).map(key => (
    <Option key={key} value={OVERTIME_ROUNDING_RULES[key]}>
      {textUtils.humanizeText(key)}
    </Option>
  ));

  const paymentFrequencyOptions = Object.keys(PAYMENT_FREQUENCY).map(key => (
    <Option key={key} value={PAYMENT_FREQUENCY[key]}>
      {textUtils.humanizeText(key)}
    </Option>
  ));

  return (
    <Modal
      width={1000}
      title={
        <Title level={4} style={{ margin: 0 }}>
          Invite new worker to this job post
        </Title>
      }
      visible={visible}
      onCancel={onCancel}
      confirmLoading={isSubmitting}
      bodyStyle={{
        height: '750px',
        overflowY: 'scroll',
      }}
      footer={
        <Row style={{ width: '100%' }}>
          <Col span={12} style={{ display: 'flex', justifyContent: 'left' }}>
            <Tooltip placement="top" title="Send this link to invited worker to remind them to install the app">
              <CopyToClipboard text={directJobInviteLink} onCopy={handleCopyToClipboard}>
                <Button type="link" style={{ padding: '0px' }}>
                  <LinkOutlined />
                  Copy invite link
                </Button>
              </CopyToClipboard>
            </Tooltip>
          </Col>
          <Col span={12}>
            <Button type="v2-secondary" onClick={onCancel}>
              Cancel
            </Button>
            <Button type="v2-primary" onClick={onSubmit}>
              Invite
            </Button>
          </Col>
        </Row>
      }
    >
      <Form hideRequiredMark colon={false} name="Job invite" autoComplete="off" style={{ overflowY: 'scroll' }}>
        {formItems}
        <Row style={{ marginTop: '8px' }}>
          <Button type="link" onClick={handleAddWorker} style={{ marginTop: 4, padding: '0px' }}>
            <PlusCircleOutlined style={{ color: colors.blue }} />
            <Text style={{ textDecoration: 'underline', color: colors.blue }}>Add another worker</Text>
          </Button>
        </Row>
        <Divider />
        <Title level={4}>Benefits & Compensations</Title>
        <Row>
          <Text style={{ fontWeight: 700, color: colors.black }}>1. Overtime</Text>
          <Item label="Enable overtime?" style={{ marginBottom: '6px' }}>
            {getFieldDecorator(`overtime.compensation`, {
              rules: [{ required: false, message: REQUIRED_FIELD }],
            })(<Switch defaultChecked={false} />)}
          </Item>
          <Row gutter={16} style={{ marginBottom: '6px' }}>
            <Col span={12}>
              <Item label="How much overtime pay per hour?" style={{ marginBottom: '6px' }}>
                {getFieldDecorator(`overtime.wage`, {
                  rules: [{ required: false, message: REQUIRED_FIELD }],
                })(<Input placeholder="0" addonAfter="IDR" disabled={!form.getFieldValue('overtime.compensation')} />)}
              </Item>
            </Col>
            <Col span={12}>
              <Item label="Max Hour(s) than can be redeemed per day" style={{ marginBottom: '6px' }}>
                {getFieldDecorator(`overtime.max_working_hours`, {
                  rules: [{ required: false, message: REQUIRED_FIELD }],
                })(<Input placeholder="0" addonAfter="hour" disabled={!form.getFieldValue('overtime.compensation')} />)}
              </Item>
            </Col>
          </Row>
          <Item label="Overtime rounding rules">
            {getFieldDecorator('overtime.rounding_rule', {
              rules: [{ required: false, message: REQUIRED_FIELD }],
            })(
              <Select allowClear placeholder="Select rule" disabled={!form.getFieldValue('overtime.compensation')}>
                {overtimeRoundingRuleOptions}
              </Select>,
            )}
          </Item>
        </Row>
        <Row>
          <Text style={{ fontWeight: 700, color: colors.black }}>2. Insurance Coverage</Text>

          {/* Enable insurance switch */}
          <Item label="Enable insurance?" style={{ marginBottom: '6px' }}>
            {getFieldDecorator(`bpjs.active`, {
              rules: [{ required: false, message: REQUIRED_FIELD }],
            })(<Switch defaultChecked={false} />)}
          </Item>

          {/* BPJS Kesehatan */}
          <Item style={{ marginBottom: '0' }}>
            {getFieldDecorator('bpjs.kesehatan_active', {
              valuePropName: 'checked',
            })(<Checkbox disabled={!form.getFieldValue('bpjs.active')}>BPJS Kesehatan</Checkbox>)}
          </Item>
          <Item style={{ marginLeft: '24px' }}>
            {getFieldDecorator('bpjs.kesehatan', {
              rules: [{ required: false, message: REQUIRED_FIELD }],
            })(
              <Radio.Group
                disabled={!form.getFieldValue('bpjs.kesehatan_active') || !form.getFieldValue('bpjs.active')}
              >
                <Radio value="full_client">Fee fully covered by client</Radio>
                <Radio value="half_half">Fee split half-half</Radio>
              </Radio.Group>,
            )}
          </Item>

          {/* BPJS Ketenagakerjaan */}
          <Item style={{ marginBottom: '0' }}>
            {getFieldDecorator('bpjs.ketenagakerjaan_active', {
              valuePropName: 'checked',
            })(<Checkbox disabled={!form.getFieldValue('bpjs.active')}>BPJS PU / BPJS TPU</Checkbox>)}
          </Item>

          {/* BPJS PU / TPU Radio */}
          <Item style={{ marginLeft: '24px' }}>
            {getFieldDecorator('bpjs.ketenagakerjaan', {
              rules: [{ required: false, message: REQUIRED_FIELD }],
            })(
              <Radio.Group
                disabled={!form.getFieldValue('bpjs.ketenagakerjaan_active') || !form.getFieldValue('bpjs.active')}
              >
                <Radio value="pu_full_client">BPJS PU - Fee fully covered by client</Radio>
                <Radio value="pu_half_half">BPJS PU - Fee split half-half</Radio>
                <Radio value="tpu">BPJS TPU</Radio>
              </Radio.Group>,
            )}
          </Item>
        </Row>
        <Row>
          <Text style={{ fontWeight: 700, color: colors.black }}>3. Public Holiday</Text>
          <Item label="Compensate for public holiday?" style={{ marginBottom: '6px' }}>
            {getFieldDecorator(`public_holiday.compensation`, {
              rules: [{ required: false, message: REQUIRED_FIELD }],
            })(<Switch defaultChecked={false} />)}
          </Item>
          <Item label="How much they will get compensated (per day)?" style={{ marginBottom: '6px' }}>
            {getFieldDecorator(`public_holiday.wage`, {
              rules: [{ required: false, message: REQUIRED_FIELD }],
            })(
              <Input placeholder="0" addonAfter="IDR" disabled={!form.getFieldValue('public_holiday.compensation')} />,
            )}
          </Item>
        </Row>
        <Row>
          <Text style={{ fontWeight: 700, color: colors.black }}>4. Other Allowances</Text>
          <Item label="Enable allowances?" style={{ marginBottom: '6px' }}>
            {getFieldDecorator(`temp_enable_allowances`, {
              rules: [{ required: false, message: REQUIRED_FIELD }],
            })(<Switch defaultChecked={false} />)}
          </Item>
          <Row gutter={16} style={{ marginBottom: '6px' }}>
            <Col span={8}>
              <Item label="Transport allowance" style={{ marginBottom: '6px' }}>
                {getFieldDecorator(`transport_allowance`, {
                  rules: [{ required: false, message: REQUIRED_FIELD }],
                })(<Input placeholder="0" addonAfter="IDR" disabled={!form.getFieldValue('temp_enable_allowances')} />)}
              </Item>
            </Col>
            <Col span={8}>
              <Item label="Meal allowance" style={{ marginBottom: '6px' }}>
                {getFieldDecorator(`meal_allowance`, {
                  rules: [{ required: false, message: REQUIRED_FIELD }],
                })(<Input placeholder="0" addonAfter="IDR" disabled={!form.getFieldValue('temp_enable_allowances')} />)}
              </Item>
            </Col>
            <Col span={8}>
              <Item label="Other allowance" style={{ marginBottom: '6px' }}>
                {getFieldDecorator(`other_allowance`, {
                  rules: [{ required: false, message: REQUIRED_FIELD }],
                })(<Input placeholder="0" addonAfter="IDR" disabled={!form.getFieldValue('temp_enable_allowances')} />)}
              </Item>
            </Col>
          </Row>
          <Item label="Payment frequency">
            {getFieldDecorator('payment_frequency', {
              rules: [{ required: false, message: REQUIRED_FIELD }],
            })(
              <Select
                allowClear
                placeholder="Select frequency"
                disabled={!form.getFieldValue('temp_enable_allowances')}
              >
                {paymentFrequencyOptions}
              </Select>,
            )}
          </Item>
        </Row>
      </Form>
    </Modal>
  );
};

export default Form.create()(DirectJobInviteModal);
