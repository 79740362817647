import { Avatar, Card, Col, Icon, Row, Tooltip, Typography } from 'antd';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { compact } from 'lodash';
import { CalendarOutlined, UserAddOutlined } from '@ant-design/icons';
import routes from '../../../../routes';
import { colors } from '../../../../styles/colors';
import textUtils from '../../../utilities/textUtils';
import workerUtils from '../../../utilities/workerUtils';
import { DATE_FORMAT, partnerSources, staffRequestContractStatus } from '../../../../constants';

const { Title, Text, Paragraph } = Typography;

const WarningIcon = () => (
  <Icon
    type="warning"
    style={{ fontSize: '16px', marginLeft: '14px', paddingRight: '4px' }}
    theme="twoTone"
    twoToneColor={colors.yellow}
  />
);

const ReferredByTooltip = ({ referredById, referredByName }) => (
  <Tooltip
    title={
      <>
        Referred by <Link to={routes.partnerDetail.replace(':id', referredById)}>{referredByName}</Link>
      </>
    }
  >
    <Icon type="usergroup-add" style={{ fontSize: '16px', marginLeft: '14px', color: colors.workmateGreen }} />
  </Tooltip>
);

const NoShowRiskTooltip = () => (
  <Tooltip title="No-show risk">
    <Text>
      <WarningIcon />
    </Text>
  </Tooltip>
);

const WorkerInfoItem = ({
  worker,
  statusSection,
  actions,
  distance,
  hasRisk,
  contracts = null,
  experiences = null,
}) => {
  const {
    id,
    first_name: firstName,
    last_name: lastName,
    image,
    statistics,
    city,
    date_of_birth: dateOfBirth,
    referred_by,
  } = worker;
  let referredBy = null;
  if (referred_by) {
    referredBy = {
      id: referred_by.id,
      name: `${referred_by.first_name} ${referred_by.last_name}`,
    };
  }

  const { days_worked: daysWorked } = statistics;

  const secondaryAttributes = compact([dateOfBirth && `${moment().diff(dateOfBirth, 'years')}yo`, city && city.name]);
  if (distance) {
    secondaryAttributes.push(`${distance} away`);
  }
  const isDirectInvite = worker.source === partnerSources.DIRECT_JOB_INVITE;
  return (
    <>
      <Card bordered={false} style={{ backgroundColor: 'inherit' }} bodyStyle={{ padding: 0 }}>
        <Row type="flex" gutter={8}>
          <Col span={3}>
            <Avatar shape="square" size={88} src={image} className="v2-avatar-wrapper" />
          </Col>
          <Col span={17} style={{ paddingLeft: '24px' }}>
            {/* Clickable title  */}
            <Paragraph ellipsis={{ row: 1 }} style={{ marginBottom: 0 }}>
              <Link to={`${routes.partners}/${id}`}>
                <Title
                  strong
                  level={4}
                  style={{
                    margin: 0,
                    display: 'inline-block',
                    cursor: 'pointer',
                    width: 'fit-content',
                  }}
                >
                  {textUtils.makeFriendly(lastName ? `${firstName} ${lastName}` : firstName || '')}
                </Title>
              </Link>
              <Text strong style={{ marginLeft: '14px', color: isDirectInvite ? '#FFAD1F' : '#69E4A6' }}>
                {isDirectInvite ? (
                  <>
                    <UserAddOutlined style={{ marginRight: '2px' }} />
                    Invited to JP
                  </>
                ) : (
                  <>
                    <CalendarOutlined style={{ marginRight: '2px' }} />
                    {`${daysWorked} days worked`}
                  </>
                )}
              </Text>
              {/* Unsigned contract indicator */}
              {contracts &&
                contracts.filter(contract => contract.status === staffRequestContractStatus.PENDING_SIGNATURE).length >
                  0 && (
                  <Text strong style={{ color: colors.yellow }}>
                    <WarningIcon />
                    Contract not signed
                  </Text>
                )}
              {referredBy && <ReferredByTooltip referredById={referredBy.id} referredByName={referredBy.name} />}
              {hasRisk && <NoShowRiskTooltip />}
            </Paragraph>

            {/* Age & recommendations if any */}
            <Paragraph ellipsis={{ rows: 1 }} style={{ marginBottom: '4px' }}>
              <Text type="secondary">{secondaryAttributes.join(' · ')}</Text>
            </Paragraph>

            <section style={{ marginBottom: '16px' }}>{statusSection}</section>

            {/* Experience */}
            {experiences && experiences.length > 0 && (
              <section>
                <Text strong type="secondary">
                  EXPERIENCE
                </Text>
                {workerUtils.getExperiences(experiences).map((experience, index) => (
                  <Paragraph key={index} ellipsis={{ rows: 1 }} style={{ marginBottom: '2px' }}>
                    <Text strong>{`${experience.position}`}</Text>
                    <Text type="secondary">{` · ${experience.companyName} · ${experience.period}`}</Text>
                  </Paragraph>
                ))}
              </section>
            )}

            {/* Contracts */}
            {contracts && (
              <section>
                <Text strong type="secondary">
                  CONTRACTS
                </Text>
                {contracts.length > 0 ? (
                  <>
                    {contracts.map((contract, index) => (
                      <Paragraph key={index} ellipsis={{ rows: 1 }} style={{ marginBottom: '2px' }}>
                        <Text strong>{textUtils.makeFriendly(contract.status)}</Text>
                        <Text type="secondary">
                          {` · ${moment(contract.start_date).format(DATE_FORMAT)} - ${moment(contract.end_date).format(
                            DATE_FORMAT,
                          )}`}
                        </Text>
                      </Paragraph>
                    ))}
                  </>
                ) : (
                  <Paragraph>
                    <Text strong>No contract</Text>
                  </Paragraph>
                )}
              </section>
            )}
          </Col>
          <Col span={4} style={{ textAlign: 'right' }}>
            {actions}
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default WorkerInfoItem;
