import React, { Component } from 'react';
import { Row, Col, Typography } from 'antd';
import UserAndPermissionsContainer from './containers/UserAndPermissionsContainer';

// eslint-disable-next-line react/prefer-stateless-function
class SettingsContainer extends Component {
  render() {
    return (
      <div>
        <Row type="flex" justify="space-between" style={{ marginBottom: '32px' }}>
          <Col>
            <Typography.Title level={3}>Settings</Typography.Title>
          </Col>
        </Row>
        <UserAndPermissionsContainer />
      </div>
    );
  }
}

export default SettingsContainer;
