import React from 'react';
import { Switch, Route } from 'react-router-dom';
import PaymentReportPage from './PaymentReportPage';
import routes from '../../../routes';
import DeprecatedPaymentReportContainer from '../../../pages/PaymentsPage/payments/reports/PaymentReportContainer';

const PaymentReports = () => {
  return (
    <Switch>
      <Route exact path={routes.paymentReports} component={PaymentReportPage} />
      <Route exact path={routes.paymentReportDetail} component={DeprecatedPaymentReportContainer} />
    </Switch>
  );
};

export default PaymentReports;
