import React from 'react';
import { matchPath, withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Row, Menu, Typography } from 'antd';
import { PostHogFeature } from 'posthog-js/react';
import routes from '../../../../routes';
import BrandLogo from '../../components/BrandLogo';
import VERSIONNAME from '../../../../versionname';
import { FEATURE_FLAGS } from '../../../../constants';

const { SubMenu } = Menu;
const menuNav = [
  { name: 'Workforce', to: routes.workforce },
  {
    name: 'Staff requests',
    children: [
      { name: 'Staff requests', to: routes.staffRequests },
      { name: 'Broadcast', to: routes.broadcast },
    ],
  },
  { name: 'Shifts', to: routes.shifts },
  {
    name: 'Workers',
    children: [
      { name: 'Workers', to: routes.partners },
      { name: 'Payment reports', to: routes.paymentReports },
      { name: 'Payment items', to: routes.paymentItems },
    ],
  },
  {
    name: 'Clients',
    children: [
      { name: 'Clients', to: routes.clients },
      { name: 'Billing reports', to: routes.billingReports },
    ],
  },
  { name: 'Timesheets', to: routes.timesheets },
  { name: 'Settings', to: routes.settings },
];

const getListOfRoutesInMenu = () => {
  return menuNav.flatMap(item => {
    if (item.children) {
      return item.children.map(childrenItem => childrenItem.to);
    }
    return item.to;
  });
};

class TopNavMenu extends React.Component {
  state = {
    windowWidth: window.innerWidth,
  };

  componentDidMount() {
    window.addEventListener('resize', this.updateWidth);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWidth);
  }

  updateWidth = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  render() {
    const { windowWidth } = this.state;
    const collapse = windowWidth < 890;
    const listOfRoutes = getListOfRoutesInMenu();
    const selectedKeys = listOfRoutes.filter(item => matchPath(this.props.history.location.pathname, { path: item }));
    if (collapse) {
      // COMING SOON
      return <span style={{ color: 'white' }}>(Menu coming soon)</span>;
    }

    return (
      <Row type="flex" style={{ height: '64px' }}>
        <Row justify="center" align="middle" style={{ marginRight: '16px' }}>
          <BrandLogo onClick={() => this.props.history.push(routes.home)} />
        </Row>
        <PostHogFeature flag={FEATURE_FLAGS.KEYS.SHOW_ADMIN_DASHBOARD_VERSION} match>
          <Typography.Text style={{ color: 'grey' }}>{VERSIONNAME}</Typography.Text>
        </PostHogFeature>
        <Menu mode="horizontal" selectedKeys={selectedKeys} className="v2-top-nav-menu">
          {menuNav.map(({ name, to, children }, index) => {
            if (children) {
              // BUG: We have to manually add selected class names for Submenus
              // Check if the nested Menu.Item is included in "selectedKeys"
              const key = selectedKeys[0];
              let className = '';
              if (children.map(nestedMenu => nestedMenu.to).includes(key)) {
                className = 'ant-menu-item-selected';
              }
              return (
                <SubMenu key={index} className={className} popupClassName="v2-top-nav-menu" title={<span>{name}</span>}>
                  {children.map(({ name, to }) => (
                    <Menu.Item key={to}>
                      <Link to={to}>
                        <span>{name}</span>
                      </Link>
                    </Menu.Item>
                  ))}
                </SubMenu>
              );
            }

            return (
              <Menu.Item key={to}>
                <Link to={to}>
                  <span>{name}</span>
                </Link>
              </Menu.Item>
            );
          })}
        </Menu>
      </Row>
    );
  }
}

export default withRouter(TopNavMenu);
