import React from 'react';
import { Row } from 'antd';

import employmentApi from '../../../../services/employmentApi';

import LoadingSpinner from '../../../shared/components/LoadingSpinner';
import ListContainer from '../../../shared/components/ListContainer';

import WorkerActivityItem from './WorkerActivityItem';
import staffRequestApi from '../../../../services/staffRequestApi';

class EmploymentTab extends React.Component {
  state = {
    employments: undefined,
    staffRequests: undefined,
    count: undefined,
    page: 1,
    loading: false,
  };

  async componentDidMount() {
    await this.fetchEmploymentsCount(); // NOTE: this will be outdated if worker employments change after
    await this.fetchEmploymentsAndStaffRequests();
  }

  fetchEmploymentsCount = async () => {
    const { partner } = this.props;
    this.setState({ loading: true });
    const response = await employmentApi.fetchEmployments({ partner: partner.id });
    this.setState({
      count: response.count,
      loading: false,
    });
  };

  fetchEmploymentsAndStaffRequests = async () => {
    const { partner } = this.props;
    const { page } = this.state;
    this.setState({ loading: true });
    const response = await employmentApi.fetchEmployments({ partner: partner.id, page });
    const employments = response.results;
    const staffRequests = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const employment of employments) {
      if (employment.staff_request_id) {
        // eslint-disable-next-line no-await-in-loop
        const staffRequestResponse = await staffRequestApi.fetchStaffRequest(employment.staff_request_id);
        staffRequests.push(staffRequestResponse);
      }
    }
    this.setState({
      employments,
      staffRequests,
      loading: false,
    });
  };

  handlePageChange = pageNum => {
    this.setState({ page: pageNum }, () => this.fetchEmploymentsAndStaffRequests());
  };

  render() {
    const { employments, staffRequests, page, count, loading } = this.state;
    const { emptyState } = this.props;
    return (
      <>
        {/* List of activities of worker e.g staff requests he has applied for... etc. */}
        {count > 0 ? (
          <ListContainer
            loading={loading}
            page={page}
            pageSize={20}
            dataSource={employments}
            totalDataCount={count}
            onPageChange={pageNum => this.handlePageChange(pageNum)}
            listItem={employment => {
              const staffRequest = staffRequests.find(sr => sr.id === employment.staff_request_id);
              return <WorkerActivityItem key={employment.id} item={employment} staffRequest={staffRequest} />;
            }}
          />
        ) : (
          emptyState
        )}
        {!employments && (
          <Row style={{ margin: '100px' }}>
            <LoadingSpinner />
          </Row>
        )}
      </>
    );
  }
}

export default EmploymentTab;
