import React from 'react';
import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { fontO11r } from '../../styles/fonts';
import { colors } from '../../styles/colors';

function getVariationStyle(variation) {
  switch (variation) {
    case 'primary':
      return css`
        background: ${colors.yellow};
        border-color: ${colors.yellow};
        color: ${colors.black};
        padding-left: 20px;
        padding-right: 20px;

        &:hover {
          background: ${rgba(colors.yellow, 0.8)};
        }
      `;
    case 'danger':
      return css`
        background: ${colors.orange};
        border-color: ${colors.orange};
        color: ${colors.white};
        padding-left: 20px;
        padding-right: 20px;
        font-weight: bold;
      `;
    case 'neutral':
      return css`
        background: ${colors.lightGrey};
        border-color: ${colors.lightGrey};
        color: ${colors.black};
        padding-left: 20px;
        padding-right: 20px;
      `;
    case 'confirm':
      return css`
        background: ${colors.green};
        border-color: ${colors.green};
        color: ${colors.white};
        padding-left: 20px;
        padding-right: 20px;

        &:hover {
          background: ${rgba(colors.green, 0.8)};
        }
      `;
    default:
      return css`
        background: ${colors.white};
        border-color: ${colors.white};
        color: ${colors.black};
        padding: 10px 12px;
        border: 1px solid ${colors.lightGrey};

        &:hover {
          background: ${colors.lightGrey};
        }
      `;
  }
}

const buttonStyle = css`
  padding: 10px 12px;
  border: 1px solid transparent;
  border-radius: 4px;
  ${fontO11r};
  cursor: pointer;
  box-shadow: 0 2px 3px ${rgba(colors.absoluteBlack, 0.05)};
  transition: all 300ms ease-out;
  display: inline-block;

  ${props => getVariationStyle(props.variation)}

  &>i.icon {
    font-size: 16px;
    display: inline-block;
    vertical-align: top;
  }

  &.ui.button:hover,
  &:hover {
    color: ${colors.black};
    box-shadow: 0 2px 3px ${rgba(colors.absoluteBlack, 0.2)};
    ${props => getVariationStyle(props.variation)}
  }
`;

const CustomButton = styled(Button)`
  ${buttonStyle};

  &.ui.button {
    ${buttonStyle};

    &:active,
    &:focus {
      ${buttonStyle};
    }
  }
`;

const CustomLink = styled(Link)`
  ${buttonStyle};
`;

const CustomA = styled.a`
  ${buttonStyle};
`;

export default ({ to, href, children, variation, ...props }) => {
  if (to) {
    return (
      <CustomLink to={to} variation={variation} {...props}>
        {children}
      </CustomLink>
    );
  }

  if (href) {
    return (
      <CustomA href={href} variation={variation} {...props}>
        {children}
      </CustomA>
    );
  }

  return (
    <CustomButton variation={variation} {...props}>
      {children}
    </CustomButton>
  );
};
