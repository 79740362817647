import React from 'react';
import { Typography, Row, Col, Card, Badge } from 'antd';

const { Text } = Typography;
// Renders a rectangular card that displays the count (number) of an entity.
const CardWithCount = ({ loading = false, additionalStyle = {}, countList }) => {
  return (
    <Card
      loading={loading}
      style={{ boxShadow: '0 2px 6px #00000026', width: '100%', marginBottom: '32px', ...additionalStyle }}
      bodyStyle={{ margin: '24px 16px', padding: 0 }}
    >
      <Row type="flex" gutter={72}>
        {countList &&
          countList.map((count, index) => {
            const colSpan = 24 / countList.length;
            // Last count would not have a border to it's right
            const border = index === countList.length - 1 ? {} : { borderRight: '1px solid #D9D9D9' };
            return (
              <Col key={index} span={colSpan} style={border}>
                <Row style={{ marginBottom: '4px' }}>
                  <Text type="secondary">{count.title.toUpperCase()}</Text>
                </Row>
                <Row>
                  <Text strong style={{ fontSize: '20px' }}>
                    {count.num} {count.num > 0 && count.hasBadge && <Badge status="error" />}
                  </Text>
                </Row>
              </Col>
            );
          })}
      </Row>
    </Card>
  );
};

export default CardWithCount;
