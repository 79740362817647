import base from './base';
import { endpoints } from '../config';

const fetchRecommendations = async params => {
  const response = await base.api.get(`${endpoints.recommendations}/`, { params });
  return response;
};

const createRecommendation = async (staffRequestId, partnerId, createSource) => {
  const response = await base.api.post(`${endpoints.recommendations}/`, {
    staff_request: staffRequestId,
    partner: partnerId,
    source: createSource,
  });
  return response;
};

const forceHire = async (recommendationId, createSource) => {
  const response = await base.api.post(`${endpoints.recommendations}/${recommendationId}/force-hire/`, {
    create_source: createSource,
  });
  return response;
};

export default {
  fetchRecommendations,
  createRecommendation,
  forceHire,
};
