import base from './base';
import { endpoints, readonlyEndpoints } from '../config';
import getCountryCode from '../utilities/getCountryCode';
import getPageFromURL from '../utilities/getPageFromURL';

const fetchPartnerById = async id => {
  const response = await base.api.get(`${endpoints.partners}/${id}/`);
  return response;
};

const fetchPartners = async params => {
  const response = await base.api.get(`${readonlyEndpoints.partners}/`, {
    params: {
      country: getCountryCode(),
      ...params,
    },
  });
  return {
    nextPage: getPageFromURL(response.next),
    ...response,
  };
};

const fetchNationalities = async () => {
  const response = await base.api.get(`${endpoints.partners}/nationalities/`);
  return response;
};

const fetchOffersByPartnerId = async (id, params) => {
  const response = await base.api.get(`${endpoints.partners}/${id}/offers/`, {
    params,
  });
  return response;
};

const fetchPartnerWithSensitiveInfo = async id => {
  const partner = await fetchPartnerById(id);
  const sensitiveInfo = (await base.api.get(`${endpoints.partners}/${id}/sensitive-info/`)).results[0];

  return {
    ...partner,
    ...sensitiveInfo,
  };
};

const fetchWorkmateExperiences = async id => {
  const response = await base.api.get(`${endpoints.partners}/${id}/workmate-experiences/`);
  return response;
};

const createPartner = async partner => {
  const response = await base.api.post(`${endpoints.partners}/`, partner);
  return response;
};

const bulkCreateJobInvitePartner = async data => {
  const response = await base.api.post(`${endpoints.partners}/create-for-job-invite/`, data);
  return response;
};

const editPartner = async (partnerId, partner) => {
  const response = await base.api.patch(`${endpoints.partners}/${partnerId}/`, partner);
  return response;
};

const uploadImage = async (partnerId, image) => {
  const formData = new FormData();
  formData.append('image', image);
  const response = await base.api.put(`${endpoints.partners}/${partnerId}/upload-image/`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return response;
};

const uploadIdCard = async (partnerId, idCard) => {
  const formData = new FormData();
  formData.append('id_card', idCard);
  const response = await base.api.put(`${endpoints.partners}/${partnerId}/upload-id-card/`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return response;
};

const setPassword = async (partnerId, password) => {
  const response = await base.api.post(`${endpoints.partners}/${partnerId}/set-password/`, { password });
  return response;
};

const setNotes = async (partnerId, notes) => {
  const response = await base.api.patch(`${endpoints.partners}/${partnerId}/`, { notes });
  return response;
};

const approvePartner = async partner => {
  const response = await base.api.post(`${endpoints.partners}/${partner.id}/approve/`);
  return response;
};

const rejectPartner = async (partner, payload) => {
  const response = await base.api.post(`${endpoints.partners}/${partner.id}/reject/`, payload);
  return response;
};

const suspendPartner = async (partner, payload) => {
  const response = await base.api.post(`${endpoints.partners}/${partner.id}/suspend/`, payload);
  return response;
};

const unsuspendPartner = async partner => {
  const response = await base.api.post(`${endpoints.partners}/${partner.id}/unsuspend/`);
  return response;
};

const reactivatePartner = async (partner, payload) => {
  const response = await base.api.post(`${endpoints.partners}/${partner.id}/reactivate/`, payload);
  return response;
};

const verifyCovidCertification = async partner => {
  const response = await base.api.post(`${endpoints.partners}/${partner.id}/verify-covid-certification/`);
  return response;
};

const invalidateCovidCertification = async partner => {
  const response = await base.api.post(`${endpoints.partners}/${partner.id}/invalidate-covid-certification/`);
  return response;
};

const invite = async (partnerId, staffRequestId) => {
  const response = await base.api.post(`${endpoints.partners}/${partnerId}/invite/`, {
    staff_request_id: staffRequestId,
  });
  return response;
};

const initiateDeleteRequest = async partnerId => {
  const response = await base.api.post(`${endpoints.partners}/${partnerId}/initiate-delete-request/`, {
    send_sms: true,
  });
  return response;
};

export default {
  fetchPartnerById,
  fetchPartners,
  fetchNationalities,
  fetchOffersByPartnerId,
  fetchPartnerWithSensitiveInfo,
  fetchWorkmateExperiences,
  createPartner,
  editPartner,
  rejectPartner,
  suspendPartner,
  unsuspendPartner,
  initiateDeleteRequest,
  reactivatePartner,
  uploadImage,
  uploadIdCard,
  setPassword,
  setNotes,
  verifyCovidCertification,
  invalidateCovidCertification,
  approvePartner,
  bulkCreateJobInvitePartner,
  invite,
};
