import React from 'react';
import moment from 'moment';
import { Select } from 'antd';
import { DATE_FORMAT } from '../../../../constants';

export default ({ onChange, selectedWeekStart, loading, weekStartOptions, mainSelector = false }) => {
  const options = weekStartOptions.map(weekStart => {
    return {
      value: `${weekStart}`,
      startDate: moment(weekStart).format(DATE_FORMAT),
      endDate: moment(weekStart)
        .endOf('isoWeek')
        .format(DATE_FORMAT),
    };
  });
  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 16 }}>
      <Select
        className={mainSelector ? 'v2-shifts-week-select' : ''}
        defaultValue={`${moment(selectedWeekStart).toISOString()}`}
        value={`${moment(selectedWeekStart).toISOString()}`}
        onChange={onChange}
        disabled={loading}
      >
        {options.map(({ value, startDate, endDate }) => (
          <Select.Option key={value} value={value}>{`${startDate} - ${endDate}`}</Select.Option>
        ))}
      </Select>
    </div>
  );
};
