import React from 'react';
import moment from 'moment';
import { Modal, Typography, Col, Button, message } from 'antd';

import { withTranslation } from 'react-i18next';
import employmentApi from '../../../../services/employmentApi';
import { DATE_FORMAT, employmentStatuses } from '../../../../constants';
import { colors } from '../../../../styles/colors';
import EndingEmploymentsTable from './EndingEmploymentsTable';
import ExtendEmploymentModal from './ExtendEmploymentModal';

const { Title, Paragraph } = Typography;
const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_ORDERING = 'end_date';

class EndingEmploymentsModal extends React.Component {
  async componentDidMount() {
    await this.fetchEndingEmployments();
  }

  state = {
    loading: false,
    employments: [],
    selectedEmployments: [],
    totalEmploymentsCount: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    page: 1,
    ordering: DEFAULT_ORDERING, // sort by earliest end date as default
    showExtendEmploymentModal: false,
  };

  /* Fetch employments ending in 7 days */
  fetchEndingEmployments = async () => {
    this.setState({ loading: true });
    const { ordering, page, pageSize } = this.state;
    const { selectedLocations, selectedPositions } = this.props;
    const timezone = moment.tz.guess(); // get browser's timezone
    const endDateThreshold = moment
      .tz(timezone)
      .add(7, 'days')
      .toISOString();
    try {
      const response = await employmentApi.fetchEmployments({
        status: employmentStatuses.ACTIVE,
        end_date_before: endDateThreshold,
        end_date__isnull: false, // do not fetch endployments with no end date
        ordering: ordering || DEFAULT_ORDERING,
        page,
        page_size: pageSize,
        position: selectedPositions.join(','),
        location: selectedLocations.join(','),
      });
      this.setState({ employments: response.results, totalEmploymentsCount: response.count, loading: false });
    } catch (err) {
      this.setState({ employments: [], totalEmploymentsCount: 0, loading: false });
    }
  };

  getLatestEndDate = () => {
    const { selectedEmployments, employments } = this.state;
    // Filter out employments that are not selected and get the end date
    const selectedEmploymentsEndDates = employments
      .filter(employment => selectedEmployments.includes(employment.id))
      .map(employment => employment.end_date);

    // Get the latest end date of the selected employments
    return moment.max(...selectedEmploymentsEndDates.map(endDate => moment(endDate)));
  };

  getOrdering = sorter => {
    const { field, order } = sorter;
    let sortField;
    switch (field) {
      case 'worker':
        sortField = 'partner__first_name';
        break;
      case 'startDate':
        sortField = 'start_date';
        break;
      case 'endDate':
        sortField = 'end_date';
        break;
      default:
        sortField = field;
    }

    return order === 'descend' ? `-${sortField}` : sortField;
  };

  handleEmploymentsSelectedChange = selectedEmployments => {
    this.setState({
      selectedEmployments,
    });
  };

  handleTableChange = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    const ordering = this.getOrdering(sorter);
    this.setState(
      {
        page: current,
        selectedEmployments: [],
        pageSize,
        ordering,
      },
      async () => await this.fetchEndingEmployments(),
    );
  };

  handleExtendEmployment = async newEndDate => {
    const { selectedEmployments } = this.state;
    const { onUpdate, t } = this.props;

    const requests = selectedEmployments.map(employmentId =>
      employmentApi.update(employmentId, {
        end_date: newEndDate ? moment(newEndDate, DATE_FORMAT).toISOString() : newEndDate,
      }),
    );
    await Promise.all(requests);
    await this.fetchEndingEmployments();
    onUpdate();
    message.success(t('extendEmploymentSuccess', { num: selectedEmployments.length }));
    this.setState({ showExtendEmploymentModal: false, selectedEmployments: [] });
  };

  render() {
    const { t, closeModal, visible } = this.props;
    const {
      selectedEmployments,
      employments,
      totalEmploymentsCount,
      loading,
      page,
      showExtendEmploymentModal,
    } = this.state;

    const latestEndDate = this.getLatestEndDate();

    return (
      <Modal
        visible={visible}
        onCancel={() => {
          this.setState({ errorDetail: '' });
          closeModal();
        }}
        title={
          <Title level={4} style={{ margin: 0 }}>
            {t('endingEmployment')}
          </Title>
        }
        footer={null}
        width={900}
      >
        <Col type="flex">
          <Typography>
            <Paragraph style={{ width: '90%' }}>{t('endingEmploymentMessage')}</Paragraph>
          </Typography>
          <Button
            disabled={!selectedEmployments.length > 0}
            onClick={() => this.setState({ showExtendEmploymentModal: true })}
            style={{
              marginBottom: '24px',
              color: selectedEmployments.length > 0 && colors.white,
              background: selectedEmployments.length > 0 && colors.workmateGreen,
              fontWeight: selectedEmployments.length > 0 && 600,
              border: selectedEmployments.length > 0 && `1px solid ${colors.borderGrey}`,
            }}
          >
            {selectedEmployments.length > 0
              ? `${t('extendEmployment')} (${selectedEmployments.length})`
              : t('extendEmployment')}
          </Button>
          <EndingEmploymentsTable
            employments={employments}
            selectedEmployments={selectedEmployments}
            totalEmploymentsCount={totalEmploymentsCount}
            loading={loading}
            page={page}
            defaultPageSize={DEFAULT_PAGE_SIZE}
            onTableChange={this.handleTableChange}
            onEmploymentsSelectedChange={this.handleEmploymentsSelectedChange}
            testId={'ending-employments-table'}
          />
          <ExtendEmploymentModal
            visible={showExtendEmploymentModal}
            closeModal={() => this.setState({ showExtendEmploymentModal: false })}
            onConfirm={this.handleExtendEmployment}
            endDate={latestEndDate}
            confirmLoading={loading}
          />
        </Col>
      </Modal>
    );
  }
}

export default withTranslation()(EndingEmploymentsModal);
