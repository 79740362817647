import React from 'react';
import { Modal, Typography } from 'antd';

import { colors } from '../../../styles/colors';

const { Title } = Typography;

// TODO P2 Do a global override of Ant's primary button style instead of locally here
// workmate theme primary button style
const customPrimaryButtonStyleProps = {
  background: colors.workmateGreen,
  border: '1px solid #5ECC95',
};

export default ({ title, body, visible, onOk, okText = 'OK', okType = 'primary', onCancel }) => {
  const okButtonProps = {
    style: {
      paddingLeft: 32,
      paddingRight: 32,
      fontWeight: 900,
    },
  };
  if (okType === 'primary') {
    // override primary button with workmate styles
    Object.assign(okButtonProps.style, customPrimaryButtonStyleProps);
  }

  return (
    <Modal
      title={
        <Title level={4} style={{ fontWeight: 800 }}>
          {title}
        </Title>
      }
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      okText={okText}
      okType={okType}
      okButtonProps={okButtonProps}
    >
      {body}
    </Modal>
  );
};
