import React from 'react';
import { Route, Switch } from 'react-router-dom';
import routes from '../../../routes';

import ClientListView from './ClientListView';
import DeprecatedClientDetailContainer from './clients/ClientDetailContainer';
import DeprecatedClientForm from './clients/ClientForm';
import DeprecatedClientEdit from './clients/ClientEdit';

const ClientsPage = () => {
  return (
    <Switch>
      <Route exact path={routes.createClient} component={DeprecatedClientForm} />
      <Route exact path={routes.clients} component={ClientListView} />
      <Route exact path={routes.clientsDetail} component={DeprecatedClientDetailContainer} />
      <Route exact path={routes.editClient} component={DeprecatedClientEdit} />
    </Switch>
  );
};

export default ClientsPage;
