import React from 'react';
import { message } from 'antd';
import SelectionWithNotesModal from '../../../shared/components/SelectionWithNotesModal';

import employmentApi from '../../../../services/employmentApi';
import { employmentCancellationReasons } from '../../../../constants';

export default class EmploymentCancellationModal extends React.Component {
  state = {
    loading: false,
  };

  handleCancel = async values => {
    const {
      employment: { id },
      onCancel,
    } = this.props;
    const { option, notes } = values;

    await this.setState({ loading: true });
    try {
      await employmentApi.cancelEmployment(id, option, notes);
      onCancel(id, option, notes);
    } catch (error) {
      const detail = error.response.data.detail;
      message.error(detail ?? error.message);
    }
    await this.setState({ loading: false });
  };

  render() {
    const { visible, workerName, onClose } = this.props;
    const { loading } = this.state;

    return (
      <SelectionWithNotesModal
        visible={visible}
        loading={loading}
        modalTitle="Cancel Job Employment"
        modalPrompt={
          <>
            Are you sure you want to cancel <span style={{ fontWeight: 'bold' }}>{workerName}</span> from the job?
          </>
        }
        optionsLabel="Reason for cancelling employment"
        options={employmentCancellationReasons}
        optionValidationRules={[
          {
            required: true,
            message: 'Please select an option',
          },
        ]}
        notesLabel="Additional notes (optional)"
        notesInputPlaceholderText="Please give your reasons and details"
        onOk={this.handleCancel}
        okButtonType="danger"
        okButtonLabel="Yes, cancel"
        onCancel={onClose}
      />
    );
  }
}
