import React from 'react';
import { Menu, Button, Dropdown, Icon } from 'antd';

const getButtons = buttons => {
  return (
    <Menu style={{ padding: 0 }}>
      {buttons.map((button, index) => {
        // Override color of button if it is disabled
        if (button.disabled) button.style = { color: '#00000040', ...button.style };
        return (
          <Menu.Item key={index} style={{ padding: '5px 12px', width: '80px' }}>
            <Button
              block
              disabled={button.disabled}
              onClick={button.onClick}
              style={{ borderWidth: 0, padding: 0, textAlign: 'left', backgroundColor: 'inherit', ...button.style }}
            >
              {button.title}
            </Button>
          </Menu.Item>
        );
      })}
    </Menu>
  );
};

const DropdownButtons = ({ buttons, width, size = 'default' }) => {
  const dropDownMenu = getButtons(buttons);
  return (
    <Dropdown overlay={dropDownMenu} placement="bottomLeft" trigger={['click']} style={{ width }}>
      <Button size={size}>
        <Icon type="ellipsis" />
      </Button>
    </Dropdown>
  );
};

export default DropdownButtons;
