import base from './base';
import { endpoints } from '../config';

const createOperator = async operator => {
  const response = await base.api.post(`${endpoints.operators}/`, operator);
  return response;
};

const updateOperator = async operator => {
  const response = await base.api.put(`${endpoints.operators}/${operator.id}/`, operator);
  return response;
};

const fetchOperators = async ({ page, search }) => {
  const response = await base.api.get(`${endpoints.operators}/`, {
    params: { page, search },
  });
  return response;
};

const fetchOperatorById = async id => {
  const response = await base.api.get(`${endpoints.operators}/${id}/`);
  return response;
};

const removeOperator = async id => {
  const response = await base.api.post(`${endpoints.operators}/${id}/remove/`);
  return response;
};

export default {
  createOperator,
  fetchOperatorById,
  updateOperator,
  fetchOperators,
  removeOperator,
};
