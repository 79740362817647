import React from 'react';
import { Typography } from 'antd';
import { paymentItemTypes } from '../../../constants';
import textUtils from '../../utilities/textUtils';

const { Text } = Typography;

const PaymentItemTypeLabel = ({ type, ...props }) => {
  const greenLabel = [paymentItemTypes.BASE];

  const goldLabel = [paymentItemTypes.OVERTIME, paymentItemTypes.BONUS, paymentItemTypes.ADJUSTMENT];

  if (greenLabel.includes(type)) {
    return (
      <Text strong style={{ color: '#69E4A6' }}>
        {textUtils.makeFriendly(type)}
      </Text>
    );
  }
  if (goldLabel.includes(type)) {
    return (
      <Text strong style={{ color: '#FFC400' }}>
        {textUtils.makeFriendly(type)}
      </Text>
    );
  }
  return <Text strong>{textUtils.makeFriendly(type)}</Text>;
};

export default PaymentItemTypeLabel;
