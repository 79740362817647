import React from 'react';
import { Button, Card, Empty, List, Typography } from 'antd';
import moment from 'moment';

import Access from '../../../../../shared/access/Access';
import { permissions } from '../../../../../shared/access/accessConfig';
import { DATE_FORMAT, roles } from '../../../../../constants';
import { colors } from '../../../../../styles/colors';
import { capitalizeWord } from '../../../../utilities/textUtils';
import ManagerModal from '../modals/ManagerModal';
import ManagerSetPasswordModal from '../modals/ManagerResetPasswordModal';
import RemoveManagerModal from '../modals/RemoveManagerModal';

export default class ClientManagerCard extends React.Component {
  state = {
    managerModalOpen: false,
    resetPasswordModalOpen: false,
    removeManagerModalOpen: false,
    manager: null,
  };

  // Get names of locations that managers are in charge of
  getManagerLocations = locationIds => {
    const { locations } = this.props.client;
    let concatLocationNames = '';
    for (let i = 0; i < locations.length; i++) {
      if (locationIds.includes(locations[i].id)) {
        if (concatLocationNames.length === 0) {
          concatLocationNames = locations[i].name || `"No name"`;
        } else {
          concatLocationNames += `, ${locations[i].name}`;
        }
      }
    }
    return concatLocationNames;
  };

  render() {
    const { client, onUpdate, ...props } = this.props;
    const { managerModalOpen } = this.state;
    const managers = client ? client.managers : null;
    let adminCount = 0;
    if (managers) {
      for (let i = 0; i < managers.length; i++) {
        if (managers[i].role === roles.ADMIN) {
          adminCount += 1;
        }
      }
    }
    return (
      <Card
        {...props}
        title="Managers"
        extra={
          <Access
            action={permissions.editClient}
            yes={() => (
              <Button icon="plus" size="large" onClick={() => this.setState({ managerModalOpen: true, manager: null })}>
                Add manager
              </Button>
            )}
          />
        }
      >
        {managerModalOpen && (
          <ManagerModal
            client={client}
            adminCount={adminCount}
            onUpdate={onUpdate}
            visible={this.state.managerModalOpen}
            manager={this.state.manager}
            onClose={() => this.setState({ managerModalOpen: false })}
          />
        )}
        <RemoveManagerModal
          visible={this.state.removeManagerModalOpen}
          manager={this.state.manager}
          onUpdate={onUpdate}
          onClose={() => this.setState({ removeManagerModalOpen: false })}
        />
        <ManagerSetPasswordModal
          visible={this.state.resetPasswordModalOpen}
          manager={this.state.manager}
          onUpdate={onUpdate}
          onClose={() => this.setState({ resetPasswordModalOpen: false })}
        />
        {managers && managers.length === 0 ? (
          <Empty image={Empty.PRESENTED_IMAGE_DEFAULT} description={<span>Please add a manager</span>}>
            <Button type="primary" onClick={() => this.setState({ managerModalOpen: true, manager: null })}>
              Add manager
            </Button>
          </Empty>
        ) : (
          <List
            itemLayout="horizontal"
            dataSource={managers || []}
            loading={!managers}
            renderItem={manager => (
              <List.Item
                actions={[
                  <Access
                    action={permissions.editClient}
                    yes={() => (
                      <Button
                        type="link"
                        onClick={() =>
                          this.setState({
                            managerModalOpen: true,
                            manager,
                          })
                        }
                      >
                        Edit
                      </Button>
                    )}
                  />,
                  <Access
                    action={permissions.editClient}
                    yes={() => (
                      <Button
                        type="link"
                        onClick={() =>
                          this.setState({
                            resetPasswordModalOpen: true,
                            manager,
                          })
                        }
                      >
                        Reset Password
                      </Button>
                    )}
                  />,
                  <Access
                    action={permissions.suspendManager}
                    yes={() => (
                      <Button
                        type="link"
                        style={{ color: colors.functionalError }}
                        onClick={() =>
                          this.setState({
                            removeManagerModalOpen: true,
                            manager,
                          })
                        }
                      >
                        Remove Manager
                      </Button>
                    )}
                  />,
                ]}
              >
                <List.Item.Meta
                  title={`${manager.name} (${manager.email})`}
                  description={
                    <>
                      <Typography.Text type="secondary">
                        {manager.role === 'admin'
                          ? `${capitalizeWord(manager.role)}`
                          : `${capitalizeWord(manager.role)} (${this.getManagerLocations(manager.locations)})`}
                      </Typography.Text>
                      <br />
                      <Typography.Text type="secondary">{`HubSpot VID (${manager.hubspot_vid ||
                        'Not Found'})`}</Typography.Text>
                      <br />
                      <Typography.Text type="secondary">
                        {`Created ${moment(manager.created_date).format(DATE_FORMAT)}`}
                      </Typography.Text>
                    </>
                  }
                />
              </List.Item>
            )}
          />
        )}
      </Card>
    );
  }
}
