// Should be in HC library
import React from 'react';
import { Modal, Icon, Typography } from 'antd';

const { Text } = Typography;
const { confirm } = Modal;

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Worker modals */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
const verifyWorker = (workerName, onOk) => {
  confirm({
    centered: true,
    title: `Verify worker account`,
    content: (
      <Text>
        You are about to verify
        <Text strong>{` ${workerName}'s `}</Text>
        account. Clients will start to see this worker’s application on applied SR. Are you sure?
      </Text>
    ),
    okText: 'Yes, verify',
    okType: 'v2-primary',
    cancelText: 'Cancel',
    icon: <Icon style={{ fontSize: '20px' }} type="question-circle" theme="twoTone" />,
    onOk,
  });
};

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Timesheet modals */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
const voidTimesheetEntry = (workerName, onOk) => {
  confirm({
    centered: true,
    title: `Void disputed timesheet entry`,
    content: (
      <Text>
        You are about to void
        <Text strong>{` ${workerName} `}</Text>
        disputed timesheet entry. Are you sure?
      </Text>
    ),
    okText: 'Yes, void',
    okType: 'danger',
    cancelText: 'Cancel',
    icon: <Icon style={{ fontSize: '20px' }} type="question-circle" />,
    onOk,
  });
};

const resolveTimesheetEntry = (workerName, onOk) => {
  confirm({
    centered: true,
    title: `Resolve disputed timesheet entry`,
    content: (
      <Text>
        You are about to resolve
        <Text strong>{` ${workerName} `}</Text>
        timesheet entry. Are you sure?
      </Text>
    ),
    okText: 'Yes, resolve',
    okType: 'v2-primary',
    cancelText: 'Cancel',
    icon: <Icon style={{ fontSize: '20px' }} type="question-circle" theme="twoTone" />,
    onOk,
  });
};

const approveTimesheetEntries = (numTimesheet, onOk) => {
  confirm({
    centered: true,
    title: `Approve timesheet entries`,
    content: (
      <Text>
        You are about to approve
        <Text strong>{` ${numTimesheet} `}</Text>
        timesheet entries. Are you sure?
      </Text>
    ),
    okText: 'Yes, approve',
    okType: 'v2-primary',
    cancelText: 'Cancel',
    icon: <Icon style={{ fontSize: '20px' }} type="question-circle" theme="twoTone" />,
    onOk,
  });
};

const markInprogressTimesheetEntries = (numTimesheet, onOk) => {
  confirm({
    centered: true,
    title: `Mark timesheet entries as in-progress`,
    content: (
      <Text>
        You are about to mark
        <Text strong>{` ${numTimesheet} `}</Text>
        timesheet entries as in-progress. Are you sure?
      </Text>
    ),
    okText: 'Ok',
    okType: 'v2-primary',
    cancelText: 'Cancel',
    icon: <Icon style={{ fontSize: '20px' }} type="question-circle" theme="twoTone" />,
    onOk,
  });
};

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Leave Claim modals */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
const rejectLeaveClaims = (numLeaveClaims, onOk) => {
  confirm({
    centered: true,
    title: `Reject leave claims`,
    content: (
      <Text>
        You are about to reject
        <Text strong>{` ${numLeaveClaims} `}</Text>
        leave claims. Are you sure?
      </Text>
    ),
    okText: 'Yes, reject',
    okType: 'danger',
    cancelText: 'Cancel',
    icon: <Icon style={{ fontSize: '20px' }} type="question-circle" />,
    onOk,
  });
};

const approveLeaveClaims = (numLeaveClaims, onOk) => {
  confirm({
    centered: true,
    title: `Approve leave claims`,
    content: (
      <Text>
        You are about to approve
        <Text strong>{` ${numLeaveClaims} `}</Text>
        leave claims. Are you sure?
      </Text>
    ),
    okText: 'Yes, approve',
    okType: 'v2-primary',
    cancelText: 'Cancel',
    icon: <Icon style={{ fontSize: '20px' }} type="question-circle" theme="twoTone" />,
    onOk,
  });
};

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* Offer modals */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
const cancelOffer = (workerName, onOk) => {
  confirm({
    centered: true,
    title: `Cancel worker from staff request`,
    content: (
      <Text>
        You are about to cancel
        <Text strong>{` ${workerName}`}</Text>
        from this staff request. Are you sure?
      </Text>
    ),
    okText: 'Yes, cancel',
    okType: 'danger',
    cancelText: 'Cancel',
    icon: <Icon style={{ fontSize: '20px' }} type="question-circle" />,
    onOk,
  });
};

const reportWorkerNoShow = (workerName, onOk) => {
  confirm({
    centered: true,
    title: `Report worker as no-show`,
    content: (
      <Text>
        You are about to report
        <Text strong>{` ${workerName}'s `}</Text>
        as no-show. Are you sure?
      </Text>
    ),
    okText: 'Yes, report',
    okType: 'danger',
    cancelText: 'Cancel',
    icon: <Icon style={{ fontSize: '20px' }} type="question-circle" />,
    onOk,
  });
};

const rejectWorker = (workerName, onOk) => {
  confirm({
    centered: true,
    title: `Reject ${workerName}`,
    content: (
      <Text>
        You are about to reject
        <Text strong>{` ${workerName}'s `}</Text>
        application. Are you sure?
      </Text>
    ),
    okText: 'Yes, reject',
    okType: 'danger',
    cancelText: 'Cancel',
    icon: <Icon style={{ fontSize: '20px' }} type="question-circle" />,
    onOk,
  });
};

const hireWorker = (workerName, onOk) => {
  confirm({
    centered: true,
    title: `Confirm hiring ${workerName}`,
    content: (
      <Text>
        You are about to hire
        <Text strong>{` ${workerName} `}</Text>
        for this staff request. A contract will be sent for them to sign. Are you sure?
      </Text>
    ),
    okText: 'Yes, hire',
    okType: 'v2-primary',
    cancelText: 'Cancel',
    icon: <Icon style={{ fontSize: '20px' }} type="question-circle" theme="twoTone" />,
    onOk,
  });
};

/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
/* SR modals */
/* ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ */
const cancelSr = (srTitle, srId, clientName, onOk) => {
  confirm({
    centered: true,
    title: <Text style={{ color: 'red' }}>WARNING: Cancel staff request</Text>,
    content: (
      <Text>
        You are about to cancel
        <Text strong>{` ${srTitle}(#${srId}) `}</Text>
        by
        <Text strong>{` ${clientName}`}</Text>. Are you sure?
      </Text>
    ),
    okText: 'Yes, cancel SR',
    okType: 'danger',
    cancelText: 'Back',
    icon: <Icon style={{ fontSize: '20px' }} type="exclamation-circle" theme="twoTone" twoToneColor="red" />,
    onOk,
  });
};

const approveSr = (srTitle, srId, clientName, onOk) => {
  confirm({
    centered: true,
    title: 'Approve staff request',
    content: (
      <Text>
        You are about to approve
        <Text strong>{` ${srTitle}(#${srId}) `}</Text>
        by
        <Text strong>{` ${clientName}`}</Text>. Are you sure?
      </Text>
    ),
    okText: 'Yes, approve SR',
    okType: 'v2-primary',
    cancelText: 'Cancel',
    icon: <Icon style={{ fontSize: '20px' }} type="question-circle" theme="twoTone" />,
    onOk,
  });
};

const submitSr = (srTitle, srId, clientName, onOk) => {
  confirm({
    centered: true,
    title: 'Submit staff request for review',
    content: (
      <Text>
        You are about to submit
        <Text strong>{` ${srTitle}(#${srId}) `}</Text>
        by
        <Text strong>{` ${clientName}`}</Text> for review. Are you sure?
      </Text>
    ),
    okText: 'Yes, submit SR',
    okType: 'v2-primary',
    cancelText: 'Cancel',
    icon: <Icon style={{ fontSize: '20px' }} type="question-circle" theme="twoTone" />,
    onOk,
  });
};

const publishShift = (startDate, endDate, workersCount, onOk) => {
  confirm({
    centered: true,
    title: (
      <Text strong>
        Publish updates for {startDate} – {endDate}
      </Text>
    ),
    content: <Text>{workersCount} worker(s) will be notified of schedule updates.</Text>,
    okText: 'Publish',
    okType: 'v2-primary',
    cancelText: 'Cancel',
    icon: <Icon style={{ fontSize: '20px' }} type="question-circle" theme="twoTone" />,
    onOk,
  });
};

export default {
  reportWorkerNoShow,
  rejectWorker,
  hireWorker,
  cancelSr,
  approveSr,
  submitSr,
  cancelOffer,
  rejectLeaveClaims,
  approveLeaveClaims,
  approveTimesheetEntries,
  resolveTimesheetEntry,
  voidTimesheetEntry,
  verifyWorker,
  publishShift,
  markInprogressTimesheetEntries,
};
