import styled from 'styled-components';
import { colors } from '../../styles/colors';
import { fontO18r } from '../../styles/fonts';

const ProfilePhoto = styled.div`
  display: inline-block;
  width: 130px;
  height: 130px;
  img {
    display: block;
    border-radius: 100%;
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
`;

const Placeholder = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background: ${colors.lightGrey};
  display: flex;
  align-items: center;
  justify-content: center;
  ${fontO18r};
`;

ProfilePhoto.Placeholder = Placeholder;

export default ProfilePhoto;
