import React from 'react';
import moment from 'moment-timezone';
import { Typography, DatePicker, Divider, Button, Row, Col, Select, Input } from 'antd';
import paymentApi from '../../../services/paymentApi';

import CardWithCount from '../../shared/components/CardWithCount';
import UploadAdjustmentModal from './Modals/UploadAdjustmentModal';
import WorkerFilterButton from '../../shared/components/WorkerFilterButton';
import StatusFilterButton from './components/StatusFilterButton';
import { checkAccess } from '../../../shared/access/Access';
import { permissions } from '../../../shared/access/accessConfig';
import PaymentItemList from './components/PaymentItemList';
import getCountryCode from '../../../utilities/getCountryCode';

const { RangePicker } = DatePicker;
const { Text, Title } = Typography;

class PaymentItemPage extends React.Component {
  state = {
    isLoading: false,
    paymentItems: null,
    status_counts: undefined,
    status: [],
    partner: [],
    search: undefined,
    ordering: '-date',
    page: 1,
    date_after: undefined,
    date_before: undefined,
    showUploadAdjustmentModal: false,
    selectedPaymentItems: [],
  };

  async componentDidMount() {
    this.fetchPaymentItemsWithParams({ include_counts: true });
  }

  fetchPaymentItemsWithParams = async (params = undefined) => {
    const { partner, status, search, ordering, page, date_after, date_before } = this.state;
    this.setState({ isLoading: true });
    let searchParams = {
      country: getCountryCode(),
      partner: partner.length > 0 ? partner.join(',') : undefined,
      status: status.join(','),
      search,
      ordering,
      page,
      date_after,
      date_before,
    };
    if (params) {
      searchParams = { ...params, ...searchParams };
    }
    const response = await paymentApi.fetchPaymentItems(searchParams);
    this.setState({
      isLoading: false,
      paymentItems: response.results,
      numRecords: response.count,
    });
    if (response.status_counts) {
      this.setState({
        status_counts: response.status_counts,
      });
    }
  };

  getStatusCounts = () => {
    if (this.state.status_counts)
      return [
        { title: 'Disputed', num: this.state.status_counts.disputed, hasBadge: false },
        { title: 'Unpaid', num: this.state.status_counts.unpaid, hasBadge: this.state.status_counts.unpaid > 0 },
        { title: 'Paid', num: this.state.status_counts.paid, hasBadge: false },
        { title: 'Void', num: this.state.status_counts.void, hasBadge: false },
      ];
  };

  handleSearchChange = search => {
    this.setState({ search, page: 1 }, () => this.fetchPaymentItemsWithParams());
  };

  onDateChange = dates => {
    let before;
    let after;
    if (dates[0] && dates[1]) {
      after = moment(dates[0])
        .startOf('day')
        .toISOString();
      before = moment(dates[1])
        .endOf('day')
        .toISOString();
    }
    this.setState({ date_after: after, date_before: before }, () => this.fetchPaymentItemsWithParams());
  };

  handleStatusFilterChange = async status => {
    this.setState({ status, page: 1 }, () => this.fetchPaymentItemsWithParams());
  };

  handleWorkerFilterChange = async partner => {
    this.setState({ partner, page: 1 }, () => this.fetchPaymentItemsWithParams());
  };

  handledOpenUploadAdjustment = () => {
    this.setState({ showUploadAdjustmentModal: true });
  };

  handleUploadAdjustmentComplete = () => {
    this.fetchPaymentItemsWithParams({ include_counts: true });
  };

  handlePageChange = page => {
    this.setState({ page }, () => this.fetchPaymentItemsWithParams());
  };

  handleOrderChange = ordering => {
    this.setState({ ordering }, () => this.fetchPaymentItemsWithParams());
  };

  handleSelectionChange = items => {
    this.setState({ selectedPaymentItems: items });
  };

  handleVoidPaymentItems = async () => {
    if (this.state.selectedPaymentItems.length > 0) this.setState({ isSubmitting: true });
    await paymentApi.bulkMarkVoid({ id: this.state.selectedPaymentItems.join(',') });
    this.fetchPaymentItemsWithParams({ include_counts: true });
    this.setState({ isSubmitting: false, selectedPaymentItems: [] });
  };

  render() {
    const {
      isLoading,
      isSubmitting,
      paymentItems,
      page,
      numRecords,
      selectedPaymentItems,
      showUploadAdjustmentModal,
    } = this.state;

    return (
      <>
        {/* Header & Create button */}
        <Row type="flex" justify="space-between" style={{ marginBottom: '32px' }}>
          <Col>
            <Title level={3}>Payment Items</Title>
          </Col>
        </Row>
        {/* Top level summary */}
        <CardWithCount loading={!paymentItems} countList={this.getStatusCounts()} />

        {/* Period Filters */}
        <Row type="flex" justify="space-between" style={{ marginBottom: '16px' }}>
          <Col span={6}>
            <RangePicker
              ranges={{
                Today: [moment(), moment()],
                '7 days ago': [moment().subtract(7, 'd'), moment()],
                '14 days ago': [moment().subtract(14, 'd'), moment()],
                '30 days ago': [moment().subtract(30, 'd'), moment()],
              }}
              onChange={this.onDateChange}
              format="DD/MM/YYYY"
            />
          </Col>
          <Col>
            {checkAccess(permissions.createPaymentItem) && (
              <Button icon="edit" onClick={this.handledOpenUploadAdjustment}>
                Bulk adjustment
              </Button>
            )}
          </Col>
        </Row>

        {/* Status & Workers filter */}
        <Row type="flex" gutter={8}>
          <Col span={3}>
            <StatusFilterButton onApply={this.handleStatusFilterChange} initialOptions={this.state.status} />
          </Col>
          <Col span={3}>
            <WorkerFilterButton onSelectChange={this.handleWorkerFilterChange} selectedOptions={this.state.partner} />
          </Col>
          <Col span={5} style={{ marginLeft: 'auto' }}>
            <Input.Search allowClear placeholder="Search Item #" onSearch={this.handleSearchChange} />
          </Col>
        </Row>
        <Divider />

        <Row type="flex" justify="space-between" style={{ marginBottom: '32px' }}>
          <Col span={3}>
            <Button
              block
              disabled={isSubmitting || selectedPaymentItems.length === 0}
              onClick={this.handleVoidPaymentItems}
            >
              Void
            </Button>
          </Col>
          <Col span={5}>
            <Text type="secondary">Sort by</Text>
            <Select
              defaultValue="-created_date"
              style={{ marginLeft: '8px', width: '148px' }}
              onSelect={orderByValue => this.handleOrderChange(orderByValue)}
            >
              <Select.Option value="-created_date">Most recent</Select.Option>
            </Select>
          </Col>
        </Row>
        <PaymentItemList
          loading={isLoading}
          dataSource={paymentItems}
          count={numRecords}
          onPageChange={this.handlePageChange}
          onSelectionChange={this.handleSelectionChange}
          selectedRowKeys={selectedPaymentItems}
          page={page || 1}
        />

        {/* Modals */}
        <UploadAdjustmentModal
          visible={showUploadAdjustmentModal}
          onCancel={() => this.setState({ showUploadAdjustmentModal: false })}
          onChange={this.handleUploadAdjustmentComplete}
        />
      </>
    );
  }
}

export default PaymentItemPage;
