import { format, parse } from 'date-fns';
import moment from 'moment-timezone';
import { DEFAULT_DATETIME_FORMAT, MINUTES_IN_HOUR } from '../constants';

export const reformatDate = dateString => {
  return format(parse(dateString), DEFAULT_DATETIME_FORMAT);
};

export const getDateStart = (date, timezone) => {
  const localDate = moment(date).format('YYYY-MM-DD 00:00');
  return moment.tz(localDate, timezone).utc();
};

export const getStartOfDay = dateString => {
  return moment(dateString)
    .startOf('day')
    .toISOString();
};

export const getStartOfNextDay = dateString => {
  return moment(dateString)
    .add(1, 'day')
    .startOf('day')
    .toISOString();
};

export const getWeekStartOptions = (currentWeekStart, numWeeksBefore, numWeeksAfter) => {
  const weeksBefore = [...Array(numWeeksBefore).keys()].map(weekOffset => {
    return moment(currentWeekStart)
      .subtract(weekOffset + 1, 'weeks')
      .toISOString();
  });
  const weeksAfter = [...Array(numWeeksAfter).keys()].map(weekOffset => {
    return moment(currentWeekStart)
      .add(weekOffset + 1, 'weeks')
      .toISOString();
  });
  const sortedWeekStartOptions = [...weeksBefore, currentWeekStart, ...weeksAfter].sort((a, b) => {
    if (a > b) {
      return 1;
    } else {
      return -1;
    }
  });
  return sortedWeekStartOptions;
};

// This is to map RRule's recurrences which are 0 indexed to days of the week
export const getDayOfWeek = val => {
  switch (val) {
    case 0:
      return 'Mon';
    case 1:
      return 'Tue';
    case 2:
      return 'Wed';
    case 3:
      return 'Thu';
    case 4:
      return 'Fri';
    case 5:
      return 'Sat';
    case 6:
      return 'Sun';
    default:
      return '';
  }
};

export const formatDuration = duration => {
  if (!duration || duration === 0) {
    return '-';
  }
  const hours = Math.floor(duration / MINUTES_IN_HOUR);
  const minutes = Math.floor(duration % MINUTES_IN_HOUR);

  return hours > 0 ? `${hours}h ${minutes}m` : `${minutes}m`;
};
