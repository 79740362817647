import React from 'react';
import { Tag } from 'antd';
import {
  clientStatuses,
  partnerStatuses,
  staffRequestStatuses,
  offerStatuses,
  timesheetEntryStatuses,
  leaveAndSickStatuses,
  paymentItemStatuses,
  billingReportStatuses,
  billingItemStatuses,
  applicationStatuses,
  pendingContractStatuses,
} from '../../../constants';
import textUtils from '../../utilities/textUtils';

// default verified to true for cases where status tag is used outside of Client Status
const StatusTag = ({ status, style, verified = true, confirmedClockedOut }) => {
  const greenTags = [
    partnerStatuses.ACTIVE,
    clientStatuses.ACTIVE,
    offerStatuses.CONFIRMED,
    staffRequestStatuses.IN_PROGRESS,
    paymentItemStatuses.PAID,
    billingItemStatuses.BILLED,
    billingReportStatuses.BILLED,
    applicationStatuses.HIRED, // NOTE: used in broadcast status
  ];
  const blueTags = [
    staffRequestStatuses.POSTED,
    timesheetEntryStatuses.CLOCKED_IN,
    offerStatuses.PENDING_CONTRACT,
    partnerStatuses.TRAINING,
    pendingContractStatuses.CONTRACT_SENT,
    pendingContractStatuses.JOB_OFFER_SENT,
  ];
  const redTags = [
    partnerStatuses.REJECTED,
    staffRequestStatuses.CANCELLED,
    offerStatuses.CANCELLED,
    offerStatuses.NO_SHOW,
    timesheetEntryStatuses.DISPUTED,
    leaveAndSickStatuses.REJECTED,
    paymentItemStatuses.DISPUTED,
  ];
  const goldTags = [
    partnerStatuses.LEAD,
    staffRequestStatuses.PENDING_REVIEW,
    offerStatuses.APPLIED,
    offerStatuses.INVITED,
    timesheetEntryStatuses.CLOCKED_OUT,
    timesheetEntryStatuses.RESOLVED,
    leaveAndSickStatuses.REQUESTED,
    paymentItemStatuses.UNPAID,
    billingItemStatuses.UNBILLED,
    billingReportStatuses.UNBILLED,
  ];
  const purpleTags = [
    offerStatuses.PENDING_ONBOARDING,
    timesheetEntryStatuses.APPROVED,
    leaveAndSickStatuses.APPROVED,
    clientStatuses.PILOT,
  ];
  const unverifiedStatuses = [clientStatuses.DEACTIVATED, clientStatuses.SUSPENDED, clientStatuses.ARCHIVED];
  if (!verified && !unverifiedStatuses.includes(status)) {
    return (
      <Tag color="gold" style={{ borderRadius: '16px', ...style }}>
        {textUtils.makeFriendly(clientStatuses.PENDING_APPROVAL)}
      </Tag>
    );
  }
  if (purpleTags.includes(status)) {
    return (
      <Tag color="purple" style={{ borderRadius: '16px', ...style }}>
        {textUtils.makeFriendly(status)}
      </Tag>
    );
  }
  if (greenTags.includes(status)) {
    return (
      <Tag color="green" style={{ borderRadius: '16px', ...style }}>
        {textUtils.makeFriendly(status)}
      </Tag>
    );
  }
  if (blueTags.includes(status)) {
    return (
      <Tag color="blue" style={{ borderRadius: '16px', ...style }}>
        {textUtils.makeFriendly(status)}
      </Tag>
    );
  }
  if (redTags.includes(status)) {
    return (
      <Tag color="red" style={{ borderRadius: '16px', ...style }}>
        {textUtils.makeFriendly(status)}
      </Tag>
    );
  }
  if (
    status === timesheetEntryStatuses.RESOLVED ||
    (status === timesheetEntryStatuses.CLOCKED_OUT && confirmedClockedOut)
  ) {
    return (
      <Tag color="green" style={{ borderRadius: '16px', ...style }}>
        Confirmed Clocked Out
      </Tag>
    );
  }
  if (goldTags.includes(status)) {
    let unconfirmedText = status;
    if (status === timesheetEntryStatuses.CLOCKED_OUT) {
      unconfirmedText = `unconfirmed_${status}`;
    }
    return (
      <Tag color="gold" style={{ borderRadius: '16px', ...style }}>
        {textUtils.makeFriendly(unconfirmedText)}
      </Tag>
    );
  }
  // Default
  return (
    <Tag style={{ borderRadius: '16px', backgroundColor: 'white', ...style }}>{textUtils.makeFriendly(status)}</Tag>
  );
};

export default StatusTag;
