import { DatePicker } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';

export default class DateRangeFilter extends React.Component {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
  };

  handleDatesChange = dates => {
    const [startDate, endDate] = dates;

    this.props.onChange({
      startDate,
      endDate,
    });
  };

  render() {
    const { startDate, endDate } = this.props.value;
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <div style={{ marginRight: 10 }}>Date Range </div>
        <DatePicker.RangePicker
          showTime={{ format: 'HH:mm', defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('23:59:59', 'HH:mm:ss')] }}
          format="DD/MM/YYYY HH:mm"
          onChange={this.handleDatesChange}
          value={[startDate, endDate]}
        />
      </div>
    );
  }
}
