import { FunnelPlotOutlined } from '@ant-design/icons';
import { Row, Typography } from 'antd';
import React from 'react';
import { colors } from '../../../styles/colors';

const SelfSignupIndicator = ({ style = { marginBottom: 2, fontSize: 13 }, color = colors.black }) => (
  <Row style={style}>
    <FunnelPlotOutlined style={{ marginRight: 5, color }} />
    <Typography.Text style={{ color }}>Self sign up</Typography.Text>
  </Row>
);

export default SelfSignupIndicator;
