import React from 'react';
import moment from 'moment-timezone';
import { message, Form, Input, DatePicker, Typography, Row, Col, Modal, Avatar } from 'antd';

import timesheetEntryApi from '../../../services/timesheetEntryApi';

const { TextArea } = Input;
const { Title } = Typography;
const { RangePicker } = DatePicker;

class EditTimesheetModal extends React.Component {
  state = {
    isLoading: false,
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        this.setState({ isLoading: true });
        const { clockInOutTime, notes } = values;
        const clock_in_time = clockInOutTime[0].toISOString();
        const clock_out_time = clockInOutTime[1].toISOString();

        try {
          await timesheetEntryApi.update(this.props.timesheet.id, {
            clock_in_time,
            clock_out_time,
            notes,
          });
          message.success(`Successfully edited timesheet entry #${this.props.timesheet.id}`);
          this.props.onOk();
        } catch (error) {
          const detail = error?.response?.data?.detail;
          message.error(detail ?? error.message);
        }
        this.setState({ isLoading: false });
      }
    });
  };

  render() {
    const { onCancel, visible, timesheet } = this.props;
    if (!visible) return <> </>;

    const { isLoading } = this.state;
    const { getFieldDecorator } = this.props.form;
    const { partner } = timesheet;
    const timesheetId = timesheet.id;
    const fullName = `${partner.first_name} ${partner.last_name}`;

    return (
      <Modal
        title={<Title level={4}>{`Edit timesheet entry #${timesheetId}`}</Title>}
        centered
        visible={visible}
        onCancel={onCancel}
        onOk={this.handleSubmit}
        width={480}
        okType="v2-primary"
        okText="Save"
        okButtonProps={{ loading: isLoading }}
      >
        <Row style={{ marginBottom: '16px' }}>
          Edit the clock in and out times. If relevant, you can add optional notes.
        </Row>

        <Row type="flex" style={{ marginBottom: '16px' }} align="middle" gutter={16}>
          <Col>
            <Avatar shape="square" icon="user" size={88} src={partner.image} />
          </Col>
          <Col>
            <Title level={4}>{fullName}</Title>
          </Col>
        </Row>

        <Form hideRequiredMark>
          <Form.Item label="Edit clock in & out time" style={{ marginBottom: '16px' }}>
            {getFieldDecorator('clockInOutTime', {
              rules: [{ type: 'array', required: true, message: 'Please select a time' }],
              initialValue: [moment(timesheet.clock_in_time), moment(timesheet.clock_out_time)],
            })(
              <RangePicker
                style={{ width: '100%' }}
                format="DD MMM, hh:mm A"
                showTime={{ use12Hours: true, format: 'h:mm a' }}
              />,
            )}
          </Form.Item>

          <Form.Item label="Notes (optional)">
            {getFieldDecorator('notes', { initialValue: timesheet.notes })(
              <TextArea rows={4} placeholder="Type here..." />,
            )}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default Form.create({ name: 'edit-timesheet-entry-form' })(EditTimesheetModal);
