import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import routes from '../../../routes';
import RouteWithProps from '../../shared/components/RouteWithProps';

import WorkerProfileView from './WorkerProfileView';
import WorkerListView from './WorkerListView';
import DeprecatedPartnerForm from '../../../pages/PartnersPage/partners/PartnerForm';
import DeprecatedEditPartner from '../../../pages/PartnersPage/partners/EditPartner';

const PartnersPage = props => {
  return (
    <Switch>
      <Route exact path={routes.createPartners} component={DeprecatedPartnerForm} />
      <Route exact path={routes.partners} component={WorkerListView} />
      <RouteWithProps
        exact
        path={routes.partnerDetail}
        component={WorkerProfileView}
        extraProps={{ positions: props.positions }}
      />
      <Route exact path={routes.editPartner} component={DeprecatedEditPartner} />
    </Switch>
  );
};

const mapStateToProps = state => ({
  positions: state.global.positions,
});

export default connect(mapStateToProps)(PartnersPage);
