import styled from 'styled-components';
import { fontO10b } from '../../styles/fonts';

export default styled.div`
  border: 1px solid;
  border-radius: 2em;
  text-align: center;
  padding: 2px 5px;
  min-width: 60px;
  ${fontO10b};
`;
