import React from 'react';
import { Typography, Select } from 'antd';

const SortSelection = ({ onChange, options, defaultValue = '-created_date' }) => {
  return (
    <>
      <Typography.Text type="secondary">Sort by</Typography.Text>
      <Select defaultValue={defaultValue} style={{ marginLeft: '8px', width: '180px' }} onChange={onChange}>
        {options.map(option => (
          <Select.Option value={option.value}>{option.name}</Select.Option>
        ))}
      </Select>
    </>
  );
};

export default SortSelection;
