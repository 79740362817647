import { Button, List, Row, Typography, Divider } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { checkAccess } from '../../../../shared/access/Access';
import { permissions } from '../../../../shared/access/accessConfig';
import { colors } from '../../../../styles/colors';
import StatusTag from '../../../shared/components/StatusTag';
import getPaginationConfig from '../../../utilities/getPaginationConfig';
import ForceHireRecommendationModal from '../Modals/ForceHireRecommendationModal';
import WorkerInfoItem from './WorkerInfoItem';

class MatchedWorkerList extends Component {
  state = { showForceHireModal: false, selectedRecommendation: undefined };

  openForceHireModal = recommendation => {
    this.setState({
      showForceHireModal: true,
      selectedRecommendation: recommendation,
    });
  };

  closeForceHireModal = () => {
    this.setState({ showForceHireModal: false });
  };

  render() {
    const { totalCount, onPageChange, page, loading, recommendations, staffRequestId, onUpdate } = this.props;
    const { selectedRecommendation, showForceHireModal } = this.state;
    return (
      <>
        <List
          itemLayout="vertical"
          pagination={{ ...getPaginationConfig(totalCount, onPageChange), current: page || 1 }}
          loading={loading}
          dataSource={recommendations || []}
          renderItem={(recommendation, index) => {
            return (
              <Row key={index}>
                <WorkerInfoItem
                  staffRequestId={staffRequestId}
                  worker={recommendation.partner}
                  onUpdate={onUpdate}
                  experiences={recommendation.partner.experiences}
                  statusSection={
                    <>
                      <StatusTag status={recommendation.status} style={{ marginRight: '8px' }} />
                      <Typography.Text type="secondary" style={{ fontSize: '12px' }}>
                        {`${moment(recommendation.created_date).toNow()}`}
                      </Typography.Text>
                    </>
                  }
                  actions={
                    checkAccess(permissions.editStaffRequest) && (
                      <Button
                        icon="thunderbolt"
                        style={{ color: colors.yellow }}
                        onClick={() => {
                          this.openForceHireModal(recommendation);
                        }}
                      >
                        Force hire
                      </Button>
                    )
                  }
                />
                <Divider />
              </Row>
            );
          }}
        />

        {selectedRecommendation && (
          <ForceHireRecommendationModal
            visible={showForceHireModal}
            worker={selectedRecommendation.partner}
            recommendationId={selectedRecommendation.id}
            onCancel={this.closeForceHireModal}
            onUpdate={onUpdate}
          />
        )}
      </>
    );
  }
}

export default MatchedWorkerList;
