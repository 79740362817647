import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Icon, Menu, Modal } from 'semantic-ui-react';
import fileDownload from 'js-file-download';
import moment from 'moment';
import Card from '../../../../shared/components/Card';
import TimesheetEntriesTable from '../TimesheetEntriesTable';
import SearchForm from '../../../../shared/components/SearchForm';
import DateRangeFilter from '../../../../shared/components/DateRangeFilter';
import Pagination from '../../../../shared/components/Pagination';
import getTotalPages from '../../../../utilities/getTotalPages';
import getCountryCode from '../../../../utilities/getCountryCode';
import { humanizeText } from '../../../../utilities/textUtils';
import timesheetEntryApi from '../../../../services/timesheetEntryApi';
import Button from '../../../../shared/components/Button';
import { timesheetEntryStatuses, timesheetEntryStatusList } from '../../../../constants';
import AddTimesheetEntryModal from '../modals/AddTimesheetEntryModal';
import TimesheetEntryStatusLabel from '../../../../shared/components/EntryStatusLabel';
import DownloadFileButton from '../../../../shared/components/DownloadFileButton';
import TimesheetEntriesFilter from '../TimesheetEntriesFilter';

const CustomSearchForm = styled(SearchForm)``;

const Filters = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Actions = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 15px;
  justify-self: flex-start;
`;

const BulkActions = styled.div``;

const CustomPagination = styled(Pagination)``;

const CustomLabel = styled(TimesheetEntryStatusLabel)`
  margin-left: 10px;
`;

const CustomMenu = styled(Menu)``;

const CustomTimesheetEntriesTable = styled(TimesheetEntriesTable)``;

const TableContainer = styled.div``;

const CustomCard = styled(Card)`
  display: grid;
  grid-template-columns: auto auto;
  grid-row-gap: 30px;
  flex-direction: column;
  min-width: 1400px;

  ${Card.Header} {
    grid-column: 1 / span 2;
    //grid-row: 1;
    margin-bottom: 0;
  }

  ${Actions} {
    grid-column: 1 / span 1;
    //grid-row: 2;
  }

  ${Filters} {
    grid-column: 1 / span 2;
    //grid-row: 3;
  }

  ${CustomSearchForm} {
    grid-column: 2 / span 1;
    //grid-row: 3;
    justify-self: flex-end;
    width: 390px;
  }

  ${BulkActions} {
    grid-column: 1 / span 1;
    //grid-row: 4;
  }

  ${TableContainer} {
    grid-column: 1 / span 2;
    //grid-row: 5;
  }

  ${CustomPagination} {
    grid-column: 2 / span 1;
    justify-self: flex-end;
    //grid-row: 6;
  }
`;

const statusList = [
  {
    key: undefined,
    value: undefined,
    text: 'All',
  },
  ...timesheetEntryStatusList.map(status => ({
    key: status,
    value: status,
    text: humanizeText(status),
  })),
];

export default class TimesheetEntriesCard extends React.Component {
  static propTypes = {
    staffRequestId: PropTypes.number,
  };

  state = {
    loading: false,
    exporting: null,
    // Filters
    search: undefined,
    startDate: undefined,
    endDate: undefined,
    entries: null,
    page: 1,
    partner: undefined,
    client: undefined,
    clientName: undefined,
    staffRequest: undefined,
    // Pagination
    total: null,
    totalPages: null,
    // Selection
    selectedEntries: [],
    // Modals
    approveModalOpen: null,
    addTimesheetModal: false,
    exportModalOpen: false,
  };

  componentDidMount() {
    this.fetchItems();
    this.fetchStatusCounts();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.page !== this.state.page) {
      this.setState(
        {
          entries: null,
          selectedEntries: [],
        },
        this.fetchItems,
      );
    }

    // If filters change, reset results and fetch
    if (
      prevProps.staffRequestId !== this.props.staffRequestId ||
      prevState.staffRequest !== this.state.staffRequest ||
      prevState.search !== this.state.search ||
      prevState.partner !== this.state.partner ||
      prevState.client !== this.state.client ||
      prevState.clientName !== this.state.clientName ||
      prevState.staffRequest !== this.state.staffRequest ||
      prevState.startDate !== this.state.startDate ||
      prevState.endDate !== this.state.endDate ||
      prevState.status !== this.state.status
    ) {
      this.setState(
        {
          page: 1,
          entries: null,
          total: null,
          totalPages: null,
          selectedEntries: [],
        },
        this.fetchItems,
      );

      // If the status didn't change, it means we have to reset counts
      if (prevState.status === this.state.status) {
        this.setState(
          {
            statusCounts: null,
            allCount: null,
          },
          this.fetchStatusCounts,
        );
      }
    }
  }

  exportData = () => {
    this.setState({
      exporting: true,
    });
    timesheetEntryApi
      .download({
        status: this.state.status,
        search: this.state.search,
        country: getCountryCode(),
        partner: this.state.partner,
        client: this.state.client,
        client_name: this.state.clientName,
        staff_request: this.props.staffRequestId || this.state.staffRequest,
        clock_in_time_after: this.state.startDate && this.state.startDate.toISOString(),
        clock_in_time_before: this.state.endDate && this.state.endDate.toISOString(),
      })
      .then(data => {
        this.setState({
          exporting: false,
        });
        fileDownload(data, `timesheet_entries_${moment().format('YYYYMMDDHHmmss')}.csv`);
      })
      .catch(error => {
        this.setState({
          exporting: false,
        });
        if (error.response.status === 400) {
          window.alert(error.response.data.detail);
        }
      });
  };

  handleUpdate = () => {
    this.fetchItems();
    this.fetchStatusCounts();
  };

  fetchItems = () => {
    const { startDate, endDate } = this.state;

    timesheetEntryApi
      .list({
        status: this.state.status,
        page: this.state.page,
        search: this.state.search,
        partner: this.state.partner,
        client: this.state.client,
        client_name: this.state.clientName,
        country: this.props.staffRequestId ? undefined : getCountryCode(),
        staff_request: this.props.staffRequestId || this.state.staffRequest,
        clock_in_time_after: startDate && startDate.toISOString(),
        clock_in_time_before: endDate && endDate.toISOString(),
      })
      .then(data => {
        this.setState({
          entries: data.results,
        });

        if (this.state.page === 1) {
          this.setState({
            total: data.count,
            totalPages: getTotalPages(data.count, data.results.length),
          });
        }
      });
  };

  fetchStatusCounts = () => {
    const { startDate, endDate } = this.state;

    timesheetEntryApi
      .list({
        search: this.state.search,
        partner: this.state.partner,
        country: this.props.staffRequestId ? undefined : getCountryCode(),
        staff_request: this.props.staffRequestId,
        clock_in_time_after: startDate && startDate.toISOString(),
        clock_in_time_before: endDate && endDate.toISOString(),
      })
      .then(data => {
        this.setState({
          statusCounts: data.status_counts,
          allCount: data.count,
        });
      });
  };

  handleSelect = entry => {
    this.setState(prevState => {
      if (prevState.selectedEntries.filter(selectedEntry => selectedEntry.id === entry.id).length > 0) {
        return {
          selectedEntries: prevState.selectedEntries.filter(selectedEntry => selectedEntry.id !== entry.id),
        };
      }
      return {
        selectedEntries: [...prevState.selectedEntries, entry],
      };
    });
  };

  handleSelectView = () => {
    this.setState(prevState => {
      if (prevState.selectedEntries.length === prevState.entries.length) {
        return {
          selectedEntries: [],
        };
      }

      return {
        selectedEntries: this.state.entries,
      };
    });
  };

  bulkApprove = () => {
    this.setState({ loading: true });
    const promises = this.state.selectedEntries
      .filter(
        entry => [timesheetEntryStatuses.CLOCKED_OUT, timesheetEntryStatuses.RESOLVED].indexOf(entry.status) !== -1,
      )
      .map(entry => {
        return timesheetEntryApi.approve(entry.id);
      });

    Promise.all(promises).then(() => {
      this.setState(
        {
          loading: false,
          approveModalOpen: false,
          selectedEntries: [],
        },
        this.fetchItems,
      );
    });
  };

  getCount = status => {
    if (!this.state.statusCounts) return '-';
    if (!status) return this.state.allCount;
    return this.state.statusCounts[status] || '-';
  };

  render() {
    const { noHeader, staffRequestId, ...props } = this.props;
    const { startDate, endDate, partner, client, clientName, staffRequest } = this.state;
    return (
      <CustomCard {...props}>
        {!noHeader && <CustomCard.Header>Timesheet Entries</CustomCard.Header>}
        <Actions>
          {!!this.props.staffRequestId && (
            <>
              <Button
                onClick={() => {
                  this.setState({ addTimesheetModal: true });
                }}
              >
                <Icon name="plus circle" />
                Add Timesheet Entries
              </Button>
              <AddTimesheetEntryModal
                staffRequestId={this.props.staffRequestId}
                onClose={() => {
                  this.setState({ addTimesheetModal: false });
                }}
                open={this.state.addTimesheetModal}
                onUpdate={this.handleUpdate}
              />
            </>
          )}

          <DownloadFileButton loading={this.state.exporting} onClick={this.exportData}>
            Export CSV
          </DownloadFileButton>
          {this.state.selectedEntries.length > 0 && (
            <Modal
              open={this.state.approveModalOpen}
              trigger={
                <Button variation="primary" onClick={() => this.setState({ approveModalOpen: true })}>
                  <Icon name="check" />
                  Approve {this.state.selectedEntries.length} Selected Entries
                </Button>
              }
              size="small"
            >
              <Modal.Header>Please confirm</Modal.Header>
              <Modal.Content>
                You are approving {this.state.selectedEntries.length} timesheet entries. Only clocked out and resolved
                entries will be approved.
              </Modal.Content>
              <Modal.Actions>
                <Button onClick={() => this.setState({ approveModalOpen: false })}>Cancel</Button>
                <Button loading={this.state.loading} variation="primary" onClick={this.bulkApprove}>
                  Approve
                </Button>
              </Modal.Actions>
            </Modal>
          )}
        </Actions>
        <Filters>
          <DateRangeFilter
            onChange={({ startDate, endDate }) => this.setState({ startDate, endDate })}
            value={{ startDate, endDate }}
          />
          {staffRequestId ? (
            <CustomSearchForm
              placeholder="Search by Partner Name or ID"
              onSubmit={search => this.setState({ search })}
              onClose={() => this.setState({ search: null })}
            />
          ) : (
            <TimesheetEntriesFilter
              onChange={value =>
                this.setState({
                  partner: value.partner,
                  client: value.client,
                  clientName: value.clientName,
                  staffRequest: value.staffRequest,
                })
              }
              values={{ partner, client, clientName, staffRequest }}
            />
          )}
        </Filters>
        <TableContainer>
          <CustomMenu secondary pointing>
            {statusList.map(menuItem => (
              <Menu.Item
                key={menuItem.value}
                name={menuItem.value}
                active={this.state.status === menuItem.value}
                onClick={(e, { name }) => this.setState({ status: name })}
              >
                {menuItem.text}
                <CustomLabel status={menuItem.value} tiny>
                  {this.getCount(menuItem.value)}
                </CustomLabel>
              </Menu.Item>
            ))}
          </CustomMenu>
          <CustomTimesheetEntriesTable
            entries={this.state.entries}
            selectedEntries={this.state.selectedEntries}
            onUpdate={this.handleUpdate}
            onSelect={entry => this.handleSelect(entry)}
            onSelectView={this.handleSelectView}
            showStaffRequest={!this.props.staffRequestId}
          />
        </TableContainer>
        <CustomPagination
          page={this.state.page}
          total={this.state.total}
          totalPages={this.state.totalPages}
          onPageChange={newPage => this.setState({ page: newPage })}
        />
      </CustomCard>
    );
  }
}
