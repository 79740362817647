import React from 'react';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import numeral from 'numeral';
import { Typography, Table, Row, Col, Avatar, Tooltip, Icon } from 'antd';
import getPaginationConfig from '../../../utilities/getPaginationConfig';

import StatusTag from '../../../shared/components/StatusTag';
import EntityLink from '../../../../shared/components/EntityLink';
import routes from '../../../../routes';
import { paymentReportTypes } from '../../../../constants';
import textUtils from '../../../utilities/textUtils';

const { Text } = Typography;

class PaymentReportList extends React.Component {
  state = {};

  columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      render: text => <EntityLink to={`${routes.paymentReports}/${text}`}>{text}</EntityLink>,
    },
    {
      width: 200,
      title: 'Name',
      dataIndex: 'partner',
      render: text => (
        <Row gutter={8}>
          <Link to={routes.partnerDetail.replace(':id', text.id)}>
            <Col span={8}>
              <Avatar shape="square" size="large" src={text.image} />
            </Col>
            <Text style={{ fontSize: '14px' }}>
              {`${text.first_name} ${text.last_name}`} <br />
              <Text type="secondary">#{text.id}</Text>
            </Text>
          </Link>
        </Row>
      ),
    },
    {
      width: 50,
      dataIndex: 'account_name',
      render: text => (
        <>
          {text ? (
            ''
          ) : (
            <Tooltip title="Missing bank details">
              <Icon
                type="info-circle"
                style={{ fontSize: '18px', verticalAlign: 'middle', cursor: 'auto', color: '#FFC400' }}
              />
            </Tooltip>
          )}
        </>
      ),
    },
    {
      title: 'Created',
      dataIndex: 'created_date',
      render: text => moment(text).format('DD MMM YYYY'),
    },

    {
      title: 'Items',
      dataIndex: 'payment_items',
      render: text => text.length,
    },

    {
      title: 'Income',
      dataIndex: 'income',
      render: text => {
        return <>{numeral(text).format('0,0.00')}</>;
      },
    },
    {
      title: 'Tax Rate',
      dataIndex: 'tax_rate',
      render: text => {
        return <>{numeral(text).format('0,0.00')}</>;
      },
    },
    {
      title: 'Tax',
      dataIndex: 'tax',
      render: text => {
        return <>{numeral(text).format('0,0.00')}</>;
      },
    },
    {
      title: 'Net Payment',
      dataIndex: 'net_payment',
      render: text => {
        return <>{numeral(text).format('0,0.00')}</>;
      },
    },
    {
      title: 'Type',
      dataIndex: 'type',
      render: text => {
        return <>{text === paymentReportTypes.DCO ? textUtils.toUpperCase(text) : textUtils.makeFriendly(text)}</>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: text => {
        return (
          <>
            <StatusTag status={text} />
          </>
        );
      },
    },
  ];

  handleSelectChange = (keys, rows) => {
    this.props.onSelectChange(rows);
  };

  handleSelectAll = (keys, rows) => {
    this.props.onSelectAll(rows);
  };

  render() {
    const { dataSource, selectedPaymentReports, loading, count, page, onPageChange } = this.props;

    const selectedRowKeys = selectedPaymentReports.map(({ id }) => id);
    return (
      <>
        <Table
          style={{ marginBottom: '100px' }}
          loading={loading}
          columns={this.columns}
          dataSource={dataSource}
          rowKey="id"
          pageSize={20}
          rowSelection={{
            selectedRowKeys,
            onSelectAll: this.handleSelectAll,
            onChange: this.handleSelectChange,
          }}
          pagination={{ ...getPaginationConfig(count, onPageChange), current: page }}
        />
      </>
    );
  }
}

export default PaymentReportList;
