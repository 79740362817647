import { stringify } from 'query-string';
import base from './base';
import { endpoints, readonlyEndpoints } from '../config';
import getPageFromURL from '../utilities/getPageFromURL';

const fetchPaymentReports = async params => {
  const response = await base.api.get(`${readonlyEndpoints.paymentReports}/`, {
    params,
  });
  return {
    nextPage: getPageFromURL(response.next),
    ...response,
  };
};

const fetchPaymentReportById = async id => {
  const response = await base.api.get(`${readonlyEndpoints.paymentReports}/${id}/`);
  return response;
};

const markAllPaid = async params => {
  const response = await base.api.post(`${endpoints.paymentReports}/mark-all-paid/?${stringify(params)}`);
  return response;
};

const markPaid = async id => {
  const response = await base.api.post(`${endpoints.paymentReports}/${id}/mark-paid/`);
  return response;
};

const bulkMarkPaid = async params => {
  const response = await base.api.post(`${endpoints.paymentReports}/mark-paid/`, {}, { params });
  return response;
};

const markOnHold = async id => {
  const response = await base.api.post(`${endpoints.paymentReports}/${id}/mark-on-hold/`);
  return response;
};

const bulkMarkOnHold = async params => {
  const response = await base.api.post(`${endpoints.paymentReports}/mark-on-hold/`, {}, { params });
  return response;
};

const bulkVoidReports = async params => {
  const response = await base.api.post(`${endpoints.paymentReports}/void/`, {}, { params });
  return response;
};

const generate = async params => {
  const response = await base.api.post(`${endpoints.paymentReports}/generate/`, params);
  return response;
};

const downloadPaymentReports = async params => {
  const response = await base.api.get(`${readonlyEndpoints.paymentReports}/download/`, {
    params,
  });
  return response;
};

const fetchPaymentItems = async params => {
  const response = await base.api.get(`${readonlyEndpoints.paymentItems}/`, { params });
  return response;
};

const createPaymentItem = async paymentItem => {
  const response = await base.api.post(`${endpoints.paymentItems}/`, paymentItem);
  return response;
};

const generateSgPlacementReport = async params => {
  const response = await base.api.get(`${readonlyEndpoints.paymentReports}/generate-sg-report/`, { params });
  return response;
};

const updatePaymentItem = async (id, paymentItem) => {
  const response = await base.api.put(`${endpoints.paymentItems}/${id}/`, paymentItem);
  return response;
};

const patchPaymentItem = async (id, data) => {
  const response = await base.api.patch(`${endpoints.paymentItems}/${id}/`, data);
  return response;
};

const uploadAdjustment = async adjustments => {
  const response = await base.api.post(`${endpoints.paymentItems}/upload/`, adjustments);
  return response;
};

const bulkMarkVoid = async params => {
  const response = await base.api.post(`${endpoints.paymentItems}/mark-void/`, {}, { params });
  return response;
};

export default {
  fetchPaymentReports,
  fetchPaymentReportById,
  fetchPaymentItems,
  createPaymentItem,
  updatePaymentItem,
  generateSgPlacementReport,
  patchPaymentItem,
  markPaid,
  markAllPaid,
  bulkMarkPaid,
  bulkMarkVoid,
  markOnHold,
  bulkMarkOnHold,
  downloadPaymentReports,
  uploadAdjustment,
  generate,
  bulkVoidReports,
};
