import React from 'react';
import { withTranslation } from 'react-i18next';
import { Table, Typography, Avatar, Tag } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import numeral from 'numeral';

import textUtils from '../../../utilities/textUtils';
import routes from '../../../../routes';
import { colors } from '../../../../styles/colors';

const { Text } = Typography;

const WorkforceTable = ({
  t,
  employments,
  selectedEmployments,
  loading,
  page,
  defaultPageSize,
  currentEmploymentsCount,
  onTableChange,
  onEmploymentsSelectedChange,
  testId,
}) => {
  let tableColumns = [
    {
      title: (
        <Text strong style={{ color: colors.black }}>
          {t('Name')}
        </Text>
      ),
      dataIndex: 'worker',
      sorter: true,
      width: 210,
      key: 'worker',
      render: worker => (
        <div style={{ display: 'flex', flexDirection: 'row', width: 310, alignItems: 'center' }}>
          <Avatar shape="square" size={36} src={worker.imgSrc} icon={<UserOutlined />} />
          <a
            href={`${routes.partners}/${worker.id}`}
            style={{ fontWeight: 600, color: colors.black, paddingLeft: '8px' }}
            target="_blank"
            rel="noopener noreferrer"
            className="underline-on-hover"
          >
            {textUtils.toTitleCase(worker.name)}
          </a>
        </div>
      ),
    },
    {
      title: (
        <Text strong style={{ color: colors.black }}>
          {t('Location')}
        </Text>
      ),
      width: 130,
      dataIndex: 'location',
      key: 'location',
      sorter: true,
    },
    {
      title: (
        <Text strong style={{ color: colors.black }}>
          {t('Position')}
        </Text>
      ),
      width: 180,
      dataIndex: 'position',
      key: 'position',
      sorter: true,
    },
    {
      title: (
        <Text strong style={{ color: colors.black }}>
          {t('Wage')}
        </Text>
      ),
      width: 140,
      dataIndex: 'wage',
      key: 'wage',
      sorter: false,
    },
    {
      title: (
        <Text strong style={{ color: colors.black }}>
          {t('wageType')}
        </Text>
      ),
      width: 120,
      dataIndex: 'wageType',
      key: 'wageType',
      sorter: false,
    },
    {
      title: (
        <Text strong style={{ color: colors.black }}>
          {t('overtime')}
        </Text>
      ),
      width: 160,
      dataIndex: 'overtimeWage',
      key: 'overtimeWage',
      sorter: false,
    },
    {
      title: (
        <Text strong style={{ color: colors.black }}>
          {t('status')}
        </Text>
      ),
      width: 100,
      dataIndex: 'status',
      key: 'status',
      sorter: false,
    },
  ];

  let tableRows = [];

  for (const employment of employments) {
    const partner = employment.partner;
    const location = employment.location?.name;
    const position = employment.position?.name;
    const currencyCode = employment.client.country.currency_code;

    const baseWage = employment.base_wage ? parseFloat(employment.base_wage) : 0;
    const transportAllowance = employment.transport_allowance ? parseFloat(employment.transport_allowance) : 0;
    const mealAllowance = employment.meal_allowance ? parseFloat(employment.meal_allowance) : 0;
    const otherAllowance = employment.other_allowance ? parseFloat(employment.other_allowance) : 0;
    const wage = baseWage + transportAllowance + mealAllowance + otherAllowance;

    const row = {
      key: employment.id,
      worker: {
        id: partner.id,
        name: `${partner.first_name} ${partner.last_name}`,
        imgSrc: partner.image,
      },
      mobile: partner.mobile,
      location,
      position,
      wage: `${currencyCode} ${numeral(wage).format('0,0.00')}`,
      wageType: t(`${employment.wage_type.replace('_', ' ')}`),
      overtimeWage: employment.overtime_wage ? `${currencyCode} ${employment.overtime_wage} /hr` : '',
      status: partner.last_opened_app ? <Tag color="green">{t('Active')}</Tag> : <Tag color="gold">{t('Invited')}</Tag>,
    };

    tableRows = [...tableRows, row];
  }

  return (
    <Table
      indentSize={0}
      loading={loading}
      columns={tableColumns}
      dataSource={tableRows}
      rowKey={'key'}
      rowSelection={{
        type: 'checkbox',
        selectedRowKeys: selectedEmployments,
        onChange: selectedRowKeys => onEmploymentsSelectedChange(selectedRowKeys),
      }}
      pagination={{
        defaultPageSize,
        current: page,
        showSizeChanger: true,
        pageSizeOptions: ['20', '50', '100'],
        total: currentEmploymentsCount,
        showTotal: (total, range) => `${range[0]}-${range[1]} ${t('of')} ${total} ${t('items')}`,
        style: { margin: '24px 0px' },
      }}
      onChange={onTableChange}
      data-testid={testId}
      scroll={{ x: 'max-content' }}
      size="middle"
    />
  );
};

export default withTranslation()(WorkforceTable);
