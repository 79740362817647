import React from 'react';
import { notification, Form, Input, Modal, Select, Typography, Checkbox } from 'antd';
import managerApi from '../../../../../services/managerApi';
import { handleError } from '../../../../../utilities/serviceUtils';
import { roles, errorCodes } from '../../../../../constants';

const roleOptions = [
  { label: 'Admin', value: 'admin', description: 'Full access to everything' },
  {
    label: 'Supervisor',
    value: 'supervisor',
    description: 'Access only assigned locations. No account management privelege',
  },
];

class ManagerModal extends React.Component {
  state = {
    loading: false,
  };

  componentDidMount() {
    const { form, manager } = this.props;
    if (manager) {
      form.setFieldsValue({ role: manager.role });
    }
  }

  handleSubmit = () => {
    this.setState({ loading: true });
    const { form, manager } = this.props;

    form.validateFieldsAndScroll((errors, values) => {
      if (!errors) {
        const promise = manager ? this.editManager(values) : this.createManager(values);
        promise
          .then(() => {
            this.props.onUpdate();
            this.setState({ loading: false });
            this.handleClose();
          })
          .catch(() => {
            this.setState({ loading: false });
          });
      }
    });
  };

  createManager = values => {
    const payload = {
      client: this.props.client.id,
      name: values.name,
      email: values.email,
      phone: values.phone,
      role: values.role,
      locations: values.locations,
      access_client_dashboard: values.access_client_dashboard || values.role === roles.ADMIN, // if undefined, default to true
    };

    return managerApi
      .createManager(payload)
      .then(() => {
        notification.success({
          message: 'Manager created successfully',
        });
      })
      .catch(error => this.handleError(error, values));
  };

  editManager = values => {
    const { manager, client } = this.props;

    const payload = {
      id: manager.id,
      hubspot_vid: values.hubspot_vid,
      client: client.id,
      name: values.name,
      email: values.email,
      phone: values.phone,
      role: values.role,
      locations: values.locations,
      access_client_dashboard: values.access_client_dashboard || values.role === roles.ADMIN,
    };

    return managerApi
      .updateManager(payload)
      .then(() => {
        notification.success({
          message: 'Manager edited successfully',
        });
      })
      .catch(error => this.handleError(error, values));
  };

  reactivateMember = values => {
    const payload = {
      client: this.props.client.id,
      name: values.name,
      email: values.email,
      phone: values.phone,
      role: values.role,
      locations: values.locations,
      access_client_dashboard: values.access_client_dashboard || values.role === roles.ADMIN, // ensure admin access always true
    };

    return managerApi
      .reactivateManager(payload)
      .then(() => {
        this.props.onUpdate();
        this.handleClose();
        notification.success({
          message: 'Manager reactivated succesfully',
        });
      })
      .catch(error => this.handleError(error, values));
  };

  handleError = (error, values) => {
    if (!error.response || error.response.status !== 400) {
      // We don't recognise this error, handle it using the generic method
      handleError(error);
    }

    const { data } = error.response;
    const emailErrors = data.email || [];

    if (error.response.data.code === errorCodes.INACTIVE_MANAGER_RECORD_ALREADY_EXISTS) {
      this.reactivateMember(values);
      return;
    }

    this.props.form.setFields({
      email: {
        value: values.email,
        errors: emailErrors.map(string => new Error(string)),
      },
    });

    throw error;
  };

  handleClose = () => {
    this.props.form.resetFields();
    this.props.onClose();
  };

  render() {
    const { loading } = this.state;
    const { visible, manager, adminCount, client } = this.props;
    const { getFieldDecorator } = this.props.form;
    const locationOptions = client ? client.locations : [];
    const formValues = this.props.form.getFieldsValue();
    const showLocationOptions = formValues.role === roles.SUPERVISOR;
    const showClientDashboardAccessControl = formValues.role === roles.SUPERVISOR;
    // Each client should at least have one "Admin". Show only if adminCount > 1,
    let showRoleOptions = true;
    if (manager) {
      showRoleOptions = adminCount > 1 || manager.role !== roles.ADMIN;
    }
    return (
      <Modal
        confirmLoading={loading}
        visible={visible}
        title={manager ? 'Edit manager' : 'Add manager'}
        okText="Save"
        onOk={this.handleSubmit}
        onCancel={this.handleClose}
      >
        <Form onSubmit={this.handleSubmit}>
          <Form.Item label="Name" hasFeedback>
            {getFieldDecorator('name', {
              rules: [
                {
                  required: true,
                  message: 'Please input a name',
                },
              ],
              initialValue: manager && manager.name,
            })(<Input placeholder="Name" />)}
          </Form.Item>

          <Form.Item label="Email (Username)" hasFeedback>
            {getFieldDecorator('email', {
              rules: [
                {
                  required: true,
                  message: 'Please input an email',
                },
                {
                  type: 'email',
                  message: 'Please enter a valid email',
                },
              ],
              initialValue: manager && manager.email,
            })(<Input placeholder="Email" />)}
          </Form.Item>

          <Form.Item label="Phone" hasFeedback>
            {getFieldDecorator('phone', {
              rules: [
                {
                  required: true,
                  message: 'Please input a phone number',
                },
              ],
              initialValue: manager && manager.phone,
            })(<Input placeholder="Phone" />)}
          </Form.Item>
        </Form>

        {showRoleOptions ? (
          <Form.Item label="Role" hasFeedback>
            {getFieldDecorator('role', {
              rules: [{ required: true, message: 'Please select a role' }],
              initialValue: manager ? manager.role : roleOptions[0].value,
            })(
              <Select optionLabelProp="label" style={{ width: '100%' }}>
                {roleOptions.map(({ label, value, description }) => (
                  <Select.Option key={value} value={value} label={label}>
                    <>
                      <Typography.Text>{label}</Typography.Text>
                      <br />
                      <Typography.Text type="secondary">{description}</Typography.Text>
                    </>
                  </Select.Option>
                ))}
              </Select>,
            )}
          </Form.Item>
        ) : (
          <Typography.Text type="warning">
            Warning: Cannot edit "Role". There should at least be one "Admin"
            <br />
            <br />
          </Typography.Text>
        )}
        {manager && (
          <Form.Item hasFeedback label="HubSpot VID">
            {getFieldDecorator('hubspot_vid', {
              rules: [
                {
                  max: 10,
                  message: 'HubSpot VID should not be longer than 10 digits',
                },
                {
                  pattern: /^\d+$/,
                  message: 'Please input numbers only',
                },
              ],
              initialValue: manager && manager.hubspot_vid,
            })(<Input />)}
          </Form.Item>
        )}

        {showLocationOptions ? (
          <Form.Item label="Locations" hasFeedback>
            {getFieldDecorator('locations', {
              rules: [
                {
                  required: true,
                  message: 'Please select at least one location',
                },
              ],
              initialValue: manager ? manager.locations : [],
            })(
              <Select
                mode="multiple"
                placeholder="Select locations for your supervisor"
                style={{ width: '100%' }}
                getPopupContainer={triggerNode => triggerNode.parentNode}
              >
                {locationOptions.map(({ id, name }) => (
                  <Select.Option key={id} value={id}>
                    {name || `"No name"`}
                  </Select.Option>
                ))}
              </Select>,
            )}
          </Form.Item>
        ) : (
          <Typography.Text type="warning">Warning: An "Admin" has full access to everything</Typography.Text>
        )}

        <Form.Item
          style={{ width: '100%', marginBottom: '16px', display: !showClientDashboardAccessControl ? 'none' : null }}
        >
          {getFieldDecorator('access_client_dashboard', {
            initialValue: manager ? manager.access_client_dashboard : true,
            valuePropName: 'checked',
          })(<Checkbox>Allow login to Business Dashboard</Checkbox>)}
        </Form.Item>
      </Modal>
    );
  }
}

export default Form.create()(ManagerModal);
