import { environment } from './constants';
import getEnvironment from './utilities/getEnvironment';

let HOSTNAME = null;
let API_ROOT = null;
let GRAPHQL_ENDPOINT = null;
let READONLY_API_ROOT = null;
let SR_DEEP_LINK = null;
let FIREBASE_API_KEY = null;
let FIREBASE_AUTH_DOMAIN = null;
let FIREBASE_PROJECT_ID = null;

switch (getEnvironment()) {
  case environment.PRODUCTION:
    HOSTNAME = 'https://api.workmate.asia';
    SR_DEEP_LINK = 'https://link.workmate.asia/e/rxb5twxwMob';
    FIREBASE_API_KEY = 'AIzaSyCmXqtY9kJ_1m5krpPC0zQk5xvB1xhuHxs';
    FIREBASE_AUTH_DOMAIN = 'helpster-thailand.firebaseapp.com';
    FIREBASE_PROJECT_ID = 'helpster-thailand';
    break;
  case environment.DEMO:
    HOSTNAME = 'https://api.demo.helpster.asia';
    SR_DEEP_LINK = '';
    FIREBASE_API_KEY = 'AIzaSyAWUFIbmuHxZxhjZ-RjAfdaWU3tZXqjLiE';
    FIREBASE_AUTH_DOMAIN = 'workmate-firebase-demo.firebaseapp.com';
    FIREBASE_PROJECT_ID = 'workmate-firebase-demo';
    break;
  default:
    // This is captures Staging and Dev environments
    HOSTNAME = 'https://api.staging.workmate.asia';
    SR_DEEP_LINK = 'https://link-dev.workmate.asia/RmeGwDU6T3';
    FIREBASE_API_KEY = 'AIzaSyCphRsi9nbzmpbwpg-ZX-gB1NKgFABiMUw';
    FIREBASE_AUTH_DOMAIN = 'helpster-dev-3ab0a.firebaseapp.com';
    FIREBASE_PROJECT_ID = 'helpster-dev-3ab0a';
    break;
}

// Local overrides for the backendHost
// Add `REACT_APP_API_ROOT=http://localhost:8000` to .env.local file on root folder
HOSTNAME = process.env.REACT_APP_API_ROOT || HOSTNAME;

API_ROOT = `${HOSTNAME}/v1`;
GRAPHQL_ENDPOINT = `${HOSTNAME}/graphql/`;
READONLY_API_ROOT = `${API_ROOT}/readonly`;

export {
  HOSTNAME,
  GRAPHQL_ENDPOINT,
  API_ROOT,
  READONLY_API_ROOT,
  SR_DEEP_LINK,
  FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_PROJECT_ID,
};

export const endpoints = {
  user: `${API_ROOT}/user`,
  staffRequests: `${API_ROOT}/staff-requests`,
  offers: `${API_ROOT}/offers`,
  recommendations: `${API_ROOT}/recommendations`,
  applications: `${API_ROOT}/applications`,
  employments: `${API_ROOT}/employments`,
  partners: `${API_ROOT}/partners`,
  clients: `${API_ROOT}/clients`,
  managers: `${API_ROOT}/managers`,
  positions: `${API_ROOT}/positions`,
  countries: `${API_ROOT}/countries`,
  areas: `${API_ROOT}/areas`,
  banks: `${API_ROOT}/banks`,
  paymentReports: `${API_ROOT}/payment-reports`,
  paymentItems: `${API_ROOT}/payment-items`,
  billingReports: `${API_ROOT}/billing-reports`,
  billingItems: `${API_ROOT}/billing-items`,
  timesheetEntries: `${API_ROOT}/timesheet-entries`,
  leaveEntries: `${API_ROOT}/leave-entries`,
  operators: `${API_ROOT}/operators`,
  channels: `${API_ROOT}/channels`,
  shifts: `${API_ROOT}/shifts`,
  schedules: `${API_ROOT}/schedules`,
  attendances: `${API_ROOT}/attendances`,
  religions: `${API_ROOT}/religions`,
};

export const readonlyEndpoints = {
  paymentReports: `${READONLY_API_ROOT}/payment-reports`,
  paymentItems: `${READONLY_API_ROOT}/payment-items`,
  billingReports: `${READONLY_API_ROOT}/billing-reports`,
  billingItems: `${READONLY_API_ROOT}/billing-items`,
  timesheetEntries: `${READONLY_API_ROOT}/timesheet-entries`,
  partners: `${READONLY_API_ROOT}/partners`,
  applications: `${READONLY_API_ROOT}/applications`,
  staffRequests: `${READONLY_API_ROOT}/staff-requests`,
  employments: `${READONLY_API_ROOT}/employments`,
};

export const GOOGLE_MAPS_API_KEY = 'AIzaSyC5TTpdovokRb8nt3mokEfPSxVxgnbZyW4';

export const DEFAULT_PAGINATION_SIZE = 20;
