import React, { Component } from 'react';
import { Select } from 'antd';
import clientApi from '../../../../services/clientApi';
import getCountryCode from '../../../../utilities/getCountryCode';

class SgClientSelect extends Component {
  state = { clients: [] };

  componentDidMount() {
    clientApi
      .fetchClients({
        country: getCountryCode(),
        status: 'active',
        page_size: 1000,
      })
      .then(clients =>
        this.setState({
          clients: clients.results,
        }),
      );
  }

  render() {
    return (
      <Select
        {...this.props}
        mode="multiple"
        placeholder="Select client"
        showSearch
        allowClear
        filterOption={(input, option) =>
          // allow search by client id & name
          option.props.children
            .join('')
            .toLowerCase()
            .includes(input.toLowerCase())
        }
      >
        {this.state.clients &&
          this.state.clients.map(option => (
            <Select.Option key={option.id} value={option.id}>
              {option.id} - {option.name}
            </Select.Option>
          ))}
      </Select>
    );
  }
}

export default SgClientSelect;
