import React from 'react';
import { rrulestr } from 'rrule';
import { format } from 'date-fns';
import { Table } from 'antd';
import moment from 'moment-timezone';
import WeekDays from '../../../shared/components/WeekDays';
import { DATE_FORMAT, TIME_FORMAT } from '../../../constants';

function parseTime(time) {
  const date = new Date();
  const [hour, minute, seconds] = time.split(':').map(i => parseInt(i));
  return date.setHours(hour, minute, seconds);
}

export default class SchedulesTable extends React.Component {
  getDataSource = () => {
    const { schedules, timezone } = this.props;

    if (!schedules) return [];

    return schedules.map((schedule, index) => {
      const rrule = rrulestr(schedule.recurrences);

      return {
        key: schedule.id,
        shift: (index + 1).toString().padStart(2, '0'),
        starts: moment.tz(schedule.start_date, timezone).format(DATE_FORMAT),
        ends: rrule.options.until
          ? moment
              .tz(rrule.options.until, timezone)
              .subtract(1, 'd')
              .format(DATE_FORMAT)
          : 'No end date',
        recurrence: <WeekDays days={rrule.options.byweekday} />,
        shiftStart: format(parseTime(schedule.start_time), TIME_FORMAT),
        shiftEnd: format(parseTime(schedule.end_time), TIME_FORMAT),
      };
    });
  };

  getColumns = () => {
    return [
      {
        title: 'Shift',
        dataIndex: 'shift',
        width: 30,
      },
      {
        title: 'Starts',
        dataIndex: 'starts',
        width: 50,
      },
      {
        title: 'Ends',
        dataIndex: 'ends',
        width: 50,
      },
      {
        title: 'Recurrence',
        dataIndex: 'recurrence',
        width: 100,
      },
      {
        title: 'Shift Start',
        dataIndex: 'shiftStart',
        width: 50,
      },
      {
        title: 'Shift End',
        dataIndex: 'shiftEnd',
        width: 50,
      },
    ];
  };

  render() {
    const { schedules } = this.props;

    return (
      <Table
        dataSource={this.getDataSource()}
        columns={this.getColumns()}
        size="middle"
        pagination={false}
        loading={!schedules}
      />
    );
  }
}
