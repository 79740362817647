import React from 'react';
import { Button, Card, Empty, List } from 'antd';
import LocationModal from '../modals/LocationModal';
import Address from '../../../../../shared/components/Address';
import Access from '../../../../../shared/access/Access';
import { permissions } from '../../../../../shared/access/accessConfig';

export default class ClientLocationsCard extends React.Component {
  state = {
    locationModalOpen: false,
    location: null,
  };

  render() {
    const { client, locations, onUpdate, ...props } = this.props;

    return (
      <Card
        {...props}
        title="Locations"
        extra={
          <Access
            action={permissions.editClient}
            yes={() => (
              <Button
                icon="plus"
                size="large"
                onClick={() => this.setState({ locationModalOpen: true, location: null })}
              >
                Add location
              </Button>
            )}
          />
        }
      >
        <LocationModal
          client={client}
          location={this.state.location}
          visible={this.state.locationModalOpen}
          onUpdate={onUpdate}
          onClose={() => this.setState({ locationModalOpen: false })}
        />
        {locations && locations.length > 0 ? (
          <List
            itemLayout="horizontal"
            loading={!locations}
            dataSource={locations || []}
            renderItem={location => (
              <List.Item
                actions={[
                  <Access
                    action={permissions.editClient}
                    yes={() => (
                      <Button
                        type="link"
                        onClick={() =>
                          this.setState({
                            locationModalOpen: true,
                            location,
                          })
                        }
                      >
                        Edit
                      </Button>
                    )}
                  />,
                ]}
              >
                <List.Item.Meta title={location.name} description={<Address address={location.address} />} />
              </List.Item>
            )}
          />
        ) : (
          <Empty image={Empty.PRESENTED_IMAGE_DEFAULT} description={<span>Please add a location</span>}>
            <Button type="primary" onClick={() => this.setState({ locationModalOpen: true, location: null })}>
              Add location
            </Button>
          </Empty>
        )}
      </Card>
    );
  }
}
