import React from 'react';
import { Row, Checkbox, Card, Typography, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { colors } from '../../../../styles/colors';

const CheckboxGroup = Checkbox.Group;
const { Text } = Typography;

const CardWithCheckboxFilter = ({
  title,
  selectAllTitle,
  toolTipTitle,
  options,
  selectedOptions,
  extra,
  onChange,
  onCheckAllChange,
  style,
}) => {
  return (
    <Card
      title={
        <Row>
          <Text strong style={{ color: colors.black, fontSize: '14px', paddingRight: '5px' }}>
            {title}
          </Text>
          <Tooltip title={toolTipTitle}>
            <QuestionCircleOutlined style={{ color: colors.blue, fontSize: '14px' }} />
          </Tooltip>
        </Row>
      }
      size="small"
      style={{ minWidth: 230, width: 230, ...style }}
      extra={extra}
      bodyStyle={{ maxHeight: 220, overflowY: 'auto', overflowX: 'hidden' }}
    >
      <Row style={{ borderBottom: '1px solid #D9D9D9', padding: '0px 0px 10px 0px', marginBottom: '10px' }}>
        <Checkbox
          indeterminate={selectedOptions.length > 0 && selectedOptions.length < options.length}
          checked={selectedOptions.length === options.length}
          onChange={onCheckAllChange}
          disabled={!options.length}
        >
          {selectAllTitle}
        </Checkbox>
      </Row>
      {options.length ? (
        <CheckboxGroup value={selectedOptions} onChange={onChange} style={{ display: 'flex', flexDirection: 'column' }}>
          {options.map(option => (
            <Row key={option.key} style={{ display: 'block', padding: '5px 0px', whiteSpace: 'nowrap' }}>
              <Checkbox value={option.id}>
                <span style={{ display: 'inline-block', whiteSpace: 'normal', verticalAlign: 'top' }}>
                  {option.name}
                </span>
              </Checkbox>
            </Row>
          ))}
        </CheckboxGroup>
      ) : null}
    </Card>
  );
};

export default CardWithCheckboxFilter;
