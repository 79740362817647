import { initializeApp, getApps, getApp } from 'firebase/app';
import { FIREBASE_API_KEY, FIREBASE_AUTH_DOMAIN, FIREBASE_PROJECT_ID } from './config';

let app;
if (getApps().length === 0) {
  // Initialize Firebase app
  app = initializeApp({
    apiKey: FIREBASE_API_KEY,
    authDomain: FIREBASE_AUTH_DOMAIN,
    projectId: FIREBASE_PROJECT_ID,
  });
} else {
  // Use existing app if already initialized
  app = getApp();
}

// Initialize Firebase
export const firebaseApp = app;
