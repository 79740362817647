import React from 'react';
import styled from 'styled-components';
import { Card } from 'antd';
import { Link } from 'react-router-dom';
import staffRequestApi from '../../../services/staffRequestApi';
import { Header1 } from '../../../shared/components/headers';
import HeaderCard from '../../StaffRequestsPage/staffRequests/cards/HeaderCard';
import SchedulesCard from '../../StaffRequestsPage/staffRequests/cards/SchedulesCard';
import Grid from '../../../shared/components/Grid';
import routes from '../../../routes';
import TimesheetEntriesCard from './cards/TimesheetEntriesCard';
import LeaveAndSickView from './LeaveAndSickView';

const CustomHeader1 = styled(Header1)``;

const CustomHeaderCard = styled(HeaderCard)``;

const CustomSchedulesCard = styled(SchedulesCard)``;

const CustomLeaveAndSickCard = styled(Card)``;

const CustomTimesheetEntriesCard = styled(TimesheetEntriesCard)``;

const Container = styled(Grid)`
  ${CustomHeader1} {
    grid-column: 1 / span 6;
  }

  ${CustomHeaderCard} {
    grid-column: 1 / span 12;
  }

  ${CustomSchedulesCard} {
    grid-column: 1 / span 12;
  }

  ${CustomLeaveAndSickCard} {
    grid-column: 1 / span 12;
  }

  ${CustomTimesheetEntriesCard} {
    grid-column: 1 / span 12;
  }
`;
export default class TimesheetPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      staffRequest: null,
    };
  }

  componentDidMount() {
    const staffRequestId = this.props.match.params.id;
    this.setState({
      staffRequestId,
    });

    staffRequestApi.fetchStaffRequest(staffRequestId).then(staffRequest => {
      this.setState({ staffRequest });
    });
  }

  render() {
    const { staffRequest } = this.state;

    return (
      <Container {...this.props}>
        <CustomHeader1>
          Timesheet: {staffRequest && <Link to={`${routes.staffRequests}/${staffRequest.id}`}>{staffRequest.id}</Link>}
        </CustomHeader1>
        <CustomHeaderCard staffRequest={staffRequest} />
        <CustomSchedulesCard
          timezone={staffRequest && staffRequest.timezone}
          schedules={staffRequest && staffRequest.schedules}
        />
        <CustomLeaveAndSickCard title="Leave and Sick Days">
          <LeaveAndSickView staffRequestId={staffRequest && staffRequest.id} />
        </CustomLeaveAndSickCard>
        <CustomTimesheetEntriesCard staffRequestId={this.props.match.params.id} />
      </Container>
    );
  }
}
