// Belongs to helpster-components
import React from 'react';
import { Row, Col, Typography, Avatar, Progress, Card } from 'antd';

const { Text } = Typography;

const calculatePercentage = (numWorkersHired, numWorkersRequired) => {
  let percentage = (numWorkersHired / numWorkersRequired) * 100;
  // +4% to create orange circle when progress at zero
  if (percentage === 0) {
    percentage += 4;
  }
  return percentage;
};

const createAvatarList = (data, totalDataNum) => {
  if (!data || data.length === 0) {
    return <> </>;
  }
  return (
    <Row type="flex" align="middle" gutter={3} style={{ paddingTop: '4px' }}>
      <Col>
        {data.slice(0, 3).map((url, index) => (
          <Avatar size={24} src={url} key={index} style={{ marginRight: '4px' }} />
        ))}
      </Col>
      {totalDataNum > 3 && (
        <Col>
          <Text type="secondary" style={{ fontSize: '12px' }}>
            {`+${totalDataNum - 3} more`}
          </Text>
        </Col>
      )}
    </Row>
  );
};

const TaskCard = ({ numWorkersHired, numWorkersRequired, hiredWorkerImageUrls, taskMessages }) => {
  return (
    <Card style={{ width: '100%', borderRadius: '4px', boxShadow: '0 2px 6px #00000026' }}>
      <Card.Grid hoverable={false} style={{ minHeight: '90px', width: '100%', padding: '8px 16px 12px 16px' }}>
        <Row type="flex" justify="space-between">
          <Col>
            <Text strong style={{ fontSize: '20px', marginRight: '4px' }}>
              {numWorkersHired}
            </Text>
            <Text type="secondary">HIRED</Text>
          </Col>
          <Col>
            <Text strong style={{ fontSize: '20px', marginRight: '4px' }}>
              {numWorkersRequired}
            </Text>
            <Text type="secondary">NEEDED</Text>
          </Col>
        </Row>
        <Progress
          percent={calculatePercentage(numWorkersHired, numWorkersRequired)}
          showInfo={false}
          strokeColor={calculatePercentage(numWorkersHired, numWorkersRequired) === 4 ? '#FF8474' : null}
        />
        {createAvatarList(hiredWorkerImageUrls, numWorkersHired)}
      </Card.Grid>

      {/* Render staff request task messages e.g 'Continue editing your request', '3 new timesheets to approve' etc */}
      {taskMessages && taskMessages.length > 0 && (
        <Card.Grid hoverable={false} style={{ minHeight: '88px', width: '100%', padding: '8px 16px' }}>
          <Row style={{ marginBottom: '4px' }}>
            <Text type="secondary" strong style={{ fontSize: '12px' }}>
              TASKS
            </Text>
          </Row>

          {taskMessages}
        </Card.Grid>
      )}
    </Card>
  );
};

export default TaskCard;
