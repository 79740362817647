import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import routes from '../../../routes';
import RouteWithProps from '../../shared/components/RouteWithProps';

import StaffRequestListView from './StaffRequestListView';
import StaffRequestDetailView from './StaffRequestDetailView';
import StaffRequestFormView from './StaffRequestFormView';
// The below routes are deprecated & should be removed
import DeprecatedTimesheetPage from '../../../pages/TimesheetsPage/timesheets/TimesheetPage';
import DeprecatedMatchesPage from '../../../pages/StaffRequestsPage/matches/MatchesPage';

const StaffRequestsPage = props => {
  const { positions } = props;
  return (
    <Switch>
      <RouteWithProps
        exact
        path={routes.createStaffRequest}
        extraProps={{ positions }}
        component={StaffRequestFormView}
      />
      <RouteWithProps
        exact
        path={routes.editStaffRequest}
        extraProps={{ positions }}
        component={StaffRequestFormView}
      />
      <RouteWithProps exact path={routes.staffRequests} extraProps={{ positions }} component={StaffRequestListView} />
      <Route exact path={routes.staffRequestDetail} component={StaffRequestDetailView} />
      {/* The below routes are deprecated & should be removed */}
      <Route exact path={routes.staffRequestTimesheet} component={DeprecatedTimesheetPage} />
      <Route exact path={routes.matches} component={DeprecatedMatchesPage} />
    </Switch>
  );
};

const mapStateToProps = state => ({
  positions: state.global.positions,
});

export default connect(mapStateToProps)(StaffRequestsPage);
