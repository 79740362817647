import React from 'react';
import { Avatar } from 'antd';
import styled from 'styled-components';

const CustomAvatar = styled(Avatar)`
  img {
    object-fit: cover;
  }
`;

export default ({ name, src, ...props }) => {
  if (src) {
    return <CustomAvatar {...props} src={src} />;
  }

  return <CustomAvatar {...props}>{name && name.slice(0, 1)}</CustomAvatar>;
};
