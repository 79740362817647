import React from 'react';
import { Card, Row, Typography, Button } from 'antd';
import Maps from '../../v2/shared/components/Maps';

const { Paragraph, Text } = Typography;

const AddressCard = ({ street_1, street_2, area, latitude, longitude }) => {
  const lat = latitude ? parseFloat(latitude) : null;
  const lng = longitude ? parseFloat(longitude) : null;
  return (
    <Card
      id="address-card"
      title={
        <Text type="secondary" style={{ fontSize: '14px' }}>
          ADDRESS
        </Text>
      }
      headStyle={{ padding: '8px 0 8px 12px', minHeight: 0 }}
      bodyStyle={{ padding: '12px' }}
      style={{ width: '373px' }}
    >
      <Row style={{ marginBottom: '8px' }}>
        <Paragraph style={{ marginBottom: 0 }}>
          {street_1 || street_2 || area ? (
            <>
              {street_1 && <span>{street_1},</span>}
              <br />
              {street_2 && <span>{street_2},</span>}{' '}
              {area && (
                <span>
                  {area.name}, {area.city.name}
                </span>
              )}
            </>
          ) : (
            <Text type="secondary" style={{ color: '#BFBFBF' }}>
              Not provided
            </Text>
          )}
          <br />
          {lat && lng && (
            <Button
              type="link"
              style={{ padding: 0, height: 0 }}
              onClick={() => window.open(`https://www.google.com/maps/search/?api=1&query=${lat},${lng}`, '_blank')}
            >
              Google Maps
            </Button>
          )}
        </Paragraph>
      </Row>

      <Row style={{ height: '170px' }}>
        <Maps
          centerPosition={{
            lat: lat || 0,
            lng: lng || 0,
          }}
          markerPositions={[
            {
              lat: lat || 0,
              lng: lng || 0,
            },
          ]}
          zoom={15}
        />
      </Row>
    </Card>
  );
};

export default AddressCard;
