import base from './base';
import getPageFromURL from '../utilities/getPageFromURL';
import getCountryCode from '../utilities/getCountryCode';
import { endpoints, readonlyEndpoints } from '../config';

const fetchStaffRequests = async params => {
  const response = await base.api.get(`${readonlyEndpoints.staffRequests}/`, { params });
  return {
    nextPage: getPageFromURL(response.next),
    ...response,
  };
};

const fetchStaffRequestSummarysWithTasks = async params => {
  const response = await base.api.get(`${readonlyEndpoints.staffRequests}/summary-with-tasks/`, {
    params: {
      country: getCountryCode(),
      ...params,
    },
  });
  return {
    nextPage: getPageFromURL(response.next),
    ...response,
  };
};

const fetchStaffRequest = async id => {
  const response = await base.api.get(`${endpoints.staffRequests}/${id}/`);
  return response;
};

const createStaffRequest = async staffRequest => {
  const response = await base.api.post(`${endpoints.staffRequests}/`, staffRequest);
  return response;
};

const submitStaffRequest = async id => {
  const response = await base.api.post(`${endpoints.staffRequests}/${id}/submit/`);
  return response;
};

const postStaffRequest = async id => {
  const response = await base.api.post(`${endpoints.staffRequests}/${id}/post/`);
  return response;
};

const cancelStaffRequest = async id => {
  const response = await base.api.post(`${endpoints.staffRequests}/${id}/cancel/`);
  return response;
};

const editStaffRequest = async staffRequest => {
  const response = await base.api.put(`${endpoints.staffRequests}/${staffRequest.id}/`, staffRequest);
  return response;
};

const fetchMatches = async (id, params) => {
  const response = await base.api.get(`${readonlyEndpoints.staffRequests}/${id}/matches/`, { params });
  return response;
};

const fetchStaffRequestOffers = async id => {
  const response = await base.api.get(`${endpoints.staffRequests}/${id}/offers/`);
  return response;
};

const exportContracts = async (id, partnerList) => {
  const response = await base.api.post(
    `${endpoints.staffRequests}/${id}/contracts/download-list/`,
    {
      partner_ids: partnerList,
    },
    { responseType: 'blob' },
  );
  const url = window.URL.createObjectURL(new Blob([response]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'contract.zip');
  document.body.appendChild(link);
  link.click();
};

export default {
  fetchStaffRequest,
  fetchStaffRequests,
  fetchStaffRequestSummarysWithTasks,
  createStaffRequest,
  submitStaffRequest,
  postStaffRequest,
  editStaffRequest,
  cancelStaffRequest,
  fetchMatches,
  fetchStaffRequestOffers,
  exportContracts,
};
