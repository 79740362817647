import { environment } from '../constants';

const getEnvironment = () => {
  const hostname = window && window.location && window.location.hostname;
  if (hostname === 'admin.helpster.asia' || hostname === 'admin.workmate.asia') {
    return environment.PRODUCTION;
  } else if (hostname === 'demo.admin.helpster.tech') {
    return environment.DEMO;
  } else if (hostname === 'admin.helpster.tech') {
    return environment.STAGING;
  } else {
    return environment.DEVELOPMENT;
  }
};

export default getEnvironment;
