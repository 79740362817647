import React from 'react';
import { Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import routes from '../../../routes';
import RouteWithProps from '../../shared/components/RouteWithProps';
import BroadcastView from './BroadcastView';

const BroadcastPage = props => {
  const { operator } = props;
  return (
    <Switch>
      <RouteWithProps exact path={routes.broadcast} extraProps={{ operator }} component={BroadcastView} />
    </Switch>
  );
};

const mapStateToProps = state => ({
  operator: state.user.operator,
});

export default connect(mapStateToProps)(BroadcastPage);
