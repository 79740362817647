import { Button, Card, Input, Row } from 'antd';
import React, { Component } from 'react';
import operatorApi from '../../../../services/operatorApi';
import { checkAccess } from '../../../../shared/access/Access';
import { permissions } from '../../../../shared/access/accessConfig';
import OperatorFormModal from '../modals/OperatorFormModal';
import UserList from '../components/UserList';

class UserAndPermissionsContainer extends Component {
  state = {
    page: null,
    search: null,
    operators: null,
    total: null,
    addUserModalVisible: false,
    loading: false,
  };

  componentDidMount() {
    this.fetchOperators();
  }

  handleChange = e => {
    const { value } = e.target;

    if (!value) {
      this.setState(
        {
          page: 1,
          search: null,
        },
        this.fetchOperators,
      );
    }
  };

  handleSearch = search => {
    this.setState({ search, page: 1 }, () => this.fetchOperators());
  };

  openAddUserModal = () => {
    this.setState({ addUserModalVisible: true });
  };

  closeAddUserModal = () => {
    this.setState({ addUserModalVisible: false });
  };

  handlePageChange = page => {
    this.setState({ page }, this.fetchOperators);
  };

  fetchOperators = () => {
    const { page, search } = this.state;

    this.setState({ loading: true });
    operatorApi
      .fetchOperators({ search, page })
      .then(data => this.setState({ operators: data.results, total: data.count, loading: false }));
  };

  render() {
    const { page, total, loading, operators, addUserModalVisible } = this.state;

    return (
      <Card
        title="User and Permissions"
        extra={
          checkAccess(permissions.createUser) && (
            <Button icon="plus" onClick={this.openAddUserModal}>
              Add User
            </Button>
          )
        }
      >
        <Row type="flex" justify="end">
          <Input.Search
            allowClear
            onChange={this.handleChange}
            placeholder="Search by name"
            onSearch={this.handleSearch}
            style={{ width: 300, marginBottom: 16 }}
          />
        </Row>
        <UserList
          page={page}
          total={total}
          loading={loading}
          operators={operators}
          onUpdate={this.fetchOperators}
          onPageChange={this.handlePageChange}
        />
        {/* Create Operator */}
        <OperatorFormModal
          visible={addUserModalVisible}
          onClose={this.closeAddUserModal}
          onUpdate={this.fetchOperators}
        />
      </Card>
    );
  }
}

export default UserAndPermissionsContainer;
