import moment from 'moment-timezone';

const getDayStart = (date, timezone) => {
  return moment(date)
    .tz(timezone)
    .startOf('day');
};

const getIsoWeekStart = (date, timezone) => {
  return moment(date)
    .tz(timezone)
    .startOf('isoWeek');
};

const getEndTimeFromStartTimeAndDuration = (startTime, duration) => {
  return moment(startTime).add(moment.duration(duration));
};

const getDurationFromStartTimeAndEndTime = (startTime, endTime) => {
  const diff = endTime.diff(startTime); // diff is in milliseconds to parse into utc format
  return moment.utc(moment.duration(diff).as('milliseconds'));
};

export default {
  getDayStart,
  getIsoWeekStart,
  getEndTimeFromStartTimeAndDuration,
  getDurationFromStartTimeAndEndTime,
};
