// Should be in HC library
import React from 'react';
import { Icon, Tooltip } from 'antd';

const ToolTipWithInfo = ({ infoText }) => {
  return (
    <Tooltip title={infoText}>
      <Icon
        type="info-circle"
        style={{ fontSize: '18px', verticalAlign: 'middle', cursor: 'auto', color: '#8C8C8C' }}
      />
    </Tooltip>
  );
};

export default ToolTipWithInfo;
