import { css } from 'styled-components';
import openSansRegular from '../assets/fonts/OpenSans-Regular.woff2';
import openSansBold from '../assets/fonts/OpenSans-Bold.woff2';
import openSansLight from '../assets/fonts/OpenSans-Light.woff2';

const fontWeights = {
  light: 300,
  regular: 400,
  bold: 700,
};

export const fontFaces = css`
  @font-face {
    src: url(${openSansRegular});
    font-family: 'Open Sans';
    font-weight: ${fontWeights.regular};
  }

  @font-face {
    src: url(${openSansLight});
    font-family: 'Open Sans';
    font-weight: ${fontWeights.light};
  }

  @font-face {
    src: url(${openSansBold});
    font-family: 'Open Sans';
    font-weight: ${fontWeights.bold};
  }
`;

const fontFamily = "'Open Sans', sans-serif";

export const fontO18r = css`
  font-family: ${fontFamily};
  font-size: 18px;
  font-weight: ${fontWeights.regular};
  line-height: 20px;
`;

export const fontO14r = css`
  font-family: ${fontFamily};
  font-size: 14px;
  font-weight: ${fontWeights.regular};
  line-height: 19px;
`;

export const fontO11r = css`
  font-family: ${fontFamily};
  font-size: 11px;
  font-weight: ${fontWeights.regular};
  line-height: 20px;
`;

export const fontO11b = css`
  font-family: ${fontFamily};
  font-size: 11px;
  font-weight: ${fontWeights.bold};
  line-height: 20px;
`;

export const fontO10b = css`
  font-family: ${fontFamily};
  font-size: 10px;
  font-weight: ${fontWeights.bold};
  line-height: 13px;
`;

export const fontO8b = css`
  font-family: ${fontFamily};
  font-size: 8px;
  font-weight: ${fontWeights.bold};
`;

export const fontO28l = css`
  font-family: ${fontFamily};
  font-size: 28px;
  font-weight: ${fontWeights.light};
  line-height: 40px;
`;

export const fontO28r = css`
  font-family: ${fontFamily};
  font-size: 28px;
  font-weight: ${fontWeights.regular};
  line-height: 40px;
`;
