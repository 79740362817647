import base from './base';
import { endpoints, readonlyEndpoints } from '../config';

const fetchApplications = async params => {
  const response = await base.api.get(`${readonlyEndpoints.applications}/`, { params });
  return response;
};

const fetchApplicationRejectReasons = async params => {
  const response = await base.api.get(`${endpoints.applications}/reject-reasons/`, params);
  return response;
};

const createApplication = async (staffRequestId, partnerId, createSource) => {
  const response = await base.api.post(`${endpoints.applications}/`, {
    staff_request: staffRequestId,
    partner: partnerId,
    create_source: createSource,
  });
  return response;
};

// TODO: Look into a better way to handle list of promises
// TODO: The below 2 functions can be refactored
const approveApplicationList = async applicationList => {
  const promises = applicationList.map(application =>
    base.api.post(`${endpoints.applications}/${application.id}/approve/`),
  );
  const response = await base.api.all(promises);
  return response;
};
const rejectApplicationList = async applicationList => {
  const promises = applicationList.map(application =>
    base.api.post(`${endpoints.applications}/${application.id}/reject/`),
  );
  const response = await base.api.all(promises);
  return response;
};

// Turns offer status to contract sent
const approveApplication = async applicationId => {
  const response = await base.api.post(`${endpoints.applications}/${applicationId}/approve/`);
  return response;
};

const rejectApplication = async (applicationId, reason, notes) => {
  const response = await base.api.post(`${endpoints.applications}/${applicationId}/reject/`, {
    rejection_reason: reason,
    notes,
  });
  return response;
};

const interview = async applicationId => {
  const response = await base.api.post(`${endpoints.applications}/${applicationId}/interview/`);
  return response;
};

const shortlist = async applicationId => {
  const response = await base.api.post(`${endpoints.applications}/${applicationId}/shortlist/`);
  return response;
};

export default {
  fetchApplications,
  fetchApplicationRejectReasons,
  createApplication,
  approveApplication,
  rejectApplication,
  approveApplicationList,
  rejectApplicationList,
  interview,
  shortlist,
};
