import React from 'react';
import { Field } from 'formik';
import styled, { css } from 'styled-components';
import { colors } from '../../../styles/colors';
import { fontO14r } from '../../../styles/fonts';

export const fieldCss = css`
  border: 1px solid ${colors.grey};
  border-radius: 4px;
  padding: 10px 15px;
  width: 100%;
  height: initial;
  ${fontO14r};

  &::placeholder {
    ${fontO14r};
    color: ${colors.grey};
  }
`;

const CustomField = styled(Field)`
  ${fieldCss};
`;

export default ({ ...props }) => <CustomField {...props} />;
