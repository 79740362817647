import React from 'react';
import MultipleSelect from './MultipleSelect';

export default class StaffRequestFilterButton extends React.Component {
  state = {
    staffRequestOptions: undefined,
  };

  handleSelectChange = staffRequests => {
    this.props.onSelectChange(staffRequests);
  };

  render() {
    const { staffRequestOptions } = this.state;
    const { selectedOptions } = this.props;

    return (
      <MultipleSelect
        title={`SR ID ${selectedOptions && selectedOptions.length > 0 ? `(${selectedOptions.length})` : ''}`}
        options={staffRequestOptions}
        onSelectChange={this.handleSelectChange}
        placeholder="SR IDs split by comma or space"
        optionLabelField="name"
      />
    );
  }
}
