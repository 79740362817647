import queryString from 'query-string';

export const updateUrlWithParams = (params, history) => {
  const stringified = queryString.stringify(params);
  history.push(`?${stringified}`);
};

export const retrieveParamsFromUrl = url => {
  return queryString.parse(url);
};
