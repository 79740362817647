import React from 'react';
import { Modal } from 'semantic-ui-react';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import Field from '../../../../shared/components/forms/Field';
import Button from '../../../../shared/components/Button';
import FormGroup from '../../../../shared/components/forms/FormGroup';
import leaveApi from '../../../../services/leaveApi';

const Message = styled.div``;

const NotesField = styled(Field)`
  width: 100%;
`;

const Actions = styled.div`
  display: grid;
  grid-column-gap: 15px;
  grid-auto-flow: column;
`;

const Container = styled.div`
  display: grid;
  grid-gap: 30px;
  padding: 1.25rem 1.5rem;
`;

export default class RejectLeaveAndSickModal extends React.Component {
  render() {
    const { entry, onClose, onUpdate, ...props } = this.props;

    return (
      <Modal onClose={onClose} {...props}>
        <Modal.Header>Reject this leave or sick day request?</Modal.Header>
        <Formik
          initialValues={{
            notes: '',
          }}
          validationSchema={Yup.object().shape({
            notes: Yup.string().required('This field is required'),
          })}
          onSubmit={(values, actions) => {
            actions.setSubmitting(true);
            leaveApi.reject(entry.id, values.notes).then(data => {
              actions.setSubmitting(false);
              onUpdate(data);
              onClose();
            });
          }}
          render={() => (
            <Form>
              <Container>
                <Message>
                  Is there something wrong with <b>{entry.partner.first_name}</b> <b>{entry.partner.last_name}</b>
                  's leave request for {entry.created_date}?
                </Message>
                <FormGroup>
                  <NotesField
                    name="notes"
                    component="textarea"
                    placeholder="Please give your reason and details..."
                    rows={5}
                  />
                  <FormGroup.Error>
                    <ErrorMessage name="notes" />
                  </FormGroup.Error>
                </FormGroup>
                <Actions>
                  <Button type="submit" variation="danger">
                    Reject
                  </Button>
                  <Button variation="neutral" onClick={onClose}>
                    Cancel
                  </Button>
                </Actions>
              </Container>
            </Form>
          )}
        />
      </Modal>
    );
  }
}
