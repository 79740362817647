import React from 'react';
import { timesheetEntryPaymentStatuses } from '../../../constants';
import TreeCheckboxFilter from './TreeCheckboxFilter';

const timesheetPaymentStatusOptions = [
  { key: timesheetEntryPaymentStatuses.UNPAID, title: 'Unpaid' },
  { key: timesheetEntryPaymentStatuses.IN_PROGRESS, title: 'In Progress' },
  { key: timesheetEntryPaymentStatuses.PAID, title: 'Paid' },
];

export default class TimesheetPaymentStatus extends React.Component {
  state = {
    selectedOptions: [],
  };

  componentDidMount() {
    if (this.props.initialOptions) this.setState({ selectedOptions: this.props.initialOptions });
  }

  handleApply = checkedKeys => {
    this.props.onApply(checkedKeys);
  };

  render() {
    const { selectedOptions } = this.state;

    return (
      <TreeCheckboxFilter
        treeData={timesheetPaymentStatusOptions}
        checkedKeys={selectedOptions}
        label="Payment status"
        showActionButtons
        onChange={this.handleChange}
        onApply={this.handleApply}
      />
    );
  }
}
