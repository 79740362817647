// TODO: Refactor out into helpster-components library
import React from 'react';
import { Typography, Row, Col } from 'antd';

const { Text } = Typography;

const EmptyCard = ({ title, description, imgSrc, type = 'vertical', style }) => (
  <>
    {type === 'vertical' && (
      <Row style={{ textAlign: 'center', ...style }}>
        <Row style={{ marginBottom: '16px' }}>
          <img src={imgSrc} alt="no_result" />
        </Row>
        <Row>
          <Text strong type="secondary" style={{ fontSize: '20px' }}>
            {title}
          </Text>
        </Row>
        <Row>
          <Text type="secondary">{description}</Text>
        </Row>
      </Row>
    )}
    {type === 'horizontal' && (
      <Row type="flex" gutter={16} style={{ textAlign: 'left', ...style }}>
        <Col style={{ marginBottom: '16px' }}>
          <img src={imgSrc} alt="no_result" />
        </Col>
        <Col>
          <Row>
            <Text strong type="secondary">
              {title}
            </Text>
          </Row>
          <Row>
            <Text type="secondary">{description}</Text>
          </Row>
        </Col>
      </Row>
    )}
  </>
);

export default EmptyCard;
