import base from './base';
import getPageFromURL from '../utilities/getPageFromURL';
import { endpoints } from '../config';

const fetchChatToken = async userId => {
  const response = await base.api.get(`${endpoints.user}/${userId}/chat-token/`);
  return response;
};

const fetchChannels = async params => {
  const response = await base.api.get(`${endpoints.channels}/`, {
    params,
  });
  return {
    nextPage: getPageFromURL(response.next),
    ...response,
  };
};

const fetchChannelById = async (id, params) => {
  const response = await base.api.get(`${endpoints.channels}/${id}/`, params);
  return response;
};

const joinChannel = async channelId => {
  const response = await base.api.post(`${endpoints.channels}/${channelId}/members/`);
  return response;
};

const createChannel = async (staffRequestId, audience) => {
  const response = await base.api.post(`${endpoints.channels}/broadcast/`, {
    staff_request: staffRequestId,
    audience,
  });
  return response;
};

export default {
  fetchChatToken,
  fetchChannels,
  fetchChannelById,
  joinChannel,
  createChannel,
};
