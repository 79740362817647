import React from 'react';
import styled from 'styled-components';
import { Tabs, Switch, message, Typography, Row, Col } from 'antd';
import Card from '../../../../../shared/components/Card';
import ListContainer from '../../../../../shared/components/ListContainer';
import InfiniteList from '../../../../../shared/components/InfiniteList';
import StatusLabel from '../../../../../shared/components/StatusLabel';
import { staffRequestStatuses } from '../../../../../constants';
import { humanizeText } from '../../../../utilities/textUtils';
import clientApi from '../../../../../services/clientApi';
import staffRequestApi from '../../../../../services/staffRequestApi';

// importing from v1
import StaffRequestTable from '../../../../../pages/StaffRequestsPage/staffRequests/StaffRequestTable';

const CustomStatusLabel = styled(StatusLabel)`
  margin-left: 5px;
`;

const statusList = [
  { label: 'All Requests', value: null },
  {
    label: humanizeText(staffRequestStatuses.DRAFT),
    value: staffRequestStatuses.DRAFT,
  },
  {
    label: humanizeText(staffRequestStatuses.PENDING_REVIEW),
    value: staffRequestStatuses.PENDING_REVIEW,
  },
  {
    label: humanizeText(staffRequestStatuses.POSTED),
    value: staffRequestStatuses.POSTED,
  },
  {
    label: humanizeText(staffRequestStatuses.IN_PROGRESS),
    value: staffRequestStatuses.IN_PROGRESS,
  },
  {
    label: humanizeText(staffRequestStatuses.ENDED),
    value: staffRequestStatuses.ENDED,
  },
  {
    label: humanizeText(staffRequestStatuses.CANCELLED),
    value: staffRequestStatuses.CANCELLED,
  },
];
// TODO: logic can be refactored into its own container
export default class ClientStaffRequestsCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStatus: statusList[0].label,
      counts: {},
      statusCounts: {},
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.client !== this.props.client) {
      this.fetchStatusCounts();
    }
  }

  getCount = status => {
    const count = status && this.state.statusCounts ? this.state.statusCounts[status] : this.state.allCount;
    return count ? count.toString() : '-';
  };

  handleItemClick = (e, { name }) => {
    this.setState({ activeStatus: name });
  };

  fetchSRItems = async ({ page, search, status, label, setCount, client }, actions) => {
    if (!page) return;

    actions.setFetching(true);
    const data = await staffRequestApi.fetchStaffRequests({
      page,
      search,
      status,
      client,
      setCount,
    });
    actions.receiveItems(data.results);
    actions.setParams({
      page: data.nextPage,
    });
    actions.setFetching(false);
  };

  fetchStatusCounts = async () => {
    const data = await staffRequestApi.fetchStaffRequests({
      client: this.props.client.id,
    });
    this.setState({
      statusCounts: data.status_counts,
      allCount: data.count,
    });
  };

  renderItems = (statusList, client) => {
    return statusList.map(statusObj => (
      <Tabs.TabPane
        tab={
          <div style={{ display: 'flex' }}>
            {statusObj.label}{' '}
            <CustomStatusLabel small status={statusObj.value || ''}>
              {this.getCount(statusObj.value)}
            </CustomStatusLabel>
          </div>
        }
        key={statusObj.value}
      >
        <ListContainer
          style={{ overflowX: 'auto' }}
          initialParams={{
            page: 1,
            status: statusObj.value,
            client: client.id,
            label: statusObj.label,
            setCount: this.setCount,
          }}
          fetchItems={this.fetchSRItems}
          render={({ items, handleFetch, isFetching }) => {
            return (
              <InfiniteList
                onScrollBottom={handleFetch}
                render={() => <StaffRequestTable staffRequests={items} />}
                loading={isFetching}
              />
            );
          }}
        />
      </Tabs.TabPane>
    ));
  };

  handleUpdateClient = async (field, value) => {
    const { client, onUpdate } = this.props;
    this.setState({ isLoading: true });
    const data = {
      id: client.id,
    };
    data[field] = value;
    try {
      await clientApi.editClient(data);
      message.success('Updated client successfully');
      onUpdate();
    } catch (error) {
      message.error(error.response.data.detail || error.message);
    } finally {
      this.setState({
        isLoading: false,
      });
    }
  };

  render() {
    const { client, ...props } = this.props;
    const { isLoading } = this.state;
    return (
      <Card {...props}>
        <Card.Header>Staff Requests</Card.Header>
        <Row type="flex" gutter={8} style={{ marginBottom: 16 }}>
          <Col>
            <Switch
              defaultChecked={client?.gig_enabled || false}
              onChange={e => this.handleUpdateClient('gig_enabled', e)}
              loading={isLoading}
            />
          </Col>
          <Col>
            <Typography.Text>Can post gigs</Typography.Text>
          </Col>
        </Row>
        <Row type="flex" gutter={8} style={{ marginBottom: 48 }}>
          <Col>
            <Switch
              defaultChecked={client?.enable_vip_worker_job_posting || false}
              onChange={e => this.handleUpdateClient('enable_vip_worker_job_posting', e)}
              loading={isLoading}
            />
          </Col>
          <Col>
            <Typography.Text>Can post exclusive jobs available only to their vip workers</Typography.Text>
          </Col>
        </Row>
        <Tabs animated={false}>{client && this.renderItems(statusList, client)}</Tabs>
      </Card>
    );
  }
}
