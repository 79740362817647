import { debounce } from 'lodash';
import React from 'react';
import clientApi from '../../../../services/clientApi';
import FilterWithSearch from '../../../shared/components/FilterWithSearch';

import { clientStatuses } from '../../../../constants';

export default class ClientFilter extends React.Component {
  state = {
    loading: false,
    clientOptions: undefined,
    search: undefined,
  };

  constructor(props) {
    super(props);
    this.handleSearch = debounce(this.handleSearch, 300);
  }

  componentDidMount() {
    this.fetchClients();
  }

  fetchClients = async () => {
    const { search } = this.state;

    this.setState({ loading: true });

    // search for pilot and active valid clients only
    const validClientStatuses = [clientStatuses.PILOT, clientStatuses.ACTIVE];
    const response = await clientApi.fetchClients({ search, status: validClientStatuses.join(',') });

    this.setState({
      clientOptions: response.results.map(client => ({ key: client.id, name: client.name })),
      loading: false,
    });
  };

  handleSearch = search => {
    this.setState({ search }, this.fetchClients);
  };

  render() {
    const { selectedOptions, onSelectChange } = this.props;
    const { clientOptions, loading } = this.state;

    return (
      <FilterWithSearch
        title={`Client ${selectedOptions.length > 0 ? `(${selectedOptions.length})` : ''}`}
        options={clientOptions}
        onSelectChange={onSelectChange}
        loading={loading}
        onSearch={this.handleSearch}
        placeholder="Search by client name or ID"
        optionLabelField="name"
        value={selectedOptions}
      />
    );
  }
}
