import React from 'react';

import { Row } from 'antd';

import applicationApi from '../../../../services/applicationApi';

import LoadingSpinner from '../../../shared/components/LoadingSpinner';
import ListContainer from '../../../shared/components/ListContainer';

import WorkerActivityItem from './WorkerActivityItem';

class ApplicationsTab extends React.Component {
  state = {
    applications: undefined,
    count: undefined,
    page: 1,
    loading: false,
  };

  async componentDidMount() {
    const { partner } = this.props;
    const applications = await applicationApi.fetchApplications({ partner: partner.id });
    this.setState({
      applications: applications.results,
      count: applications.count,
    });
  }

  fetchNewPage = async () => {
    const { page } = this.state;
    const { partner } = this.props;
    const response = await applicationApi.fetchApplications({
      partner: partner.id,
      page,
    });
    this.setState({
      applications: response.results,
      loading: false,
    });
  };

  handlePageChange = pageNum => {
    this.setState({ page: pageNum, loading: true }, () => this.fetchNewPage());
  };

  render() {
    const { applications, page, count, loading } = this.state;
    const { emptyState } = this.props;

    return (
      <>
        {/* List of activities of worker e.g staff requests he has applied for... etc. */}
        {count > 0 && (
          <ListContainer
            loading={loading}
            page={page}
            pageSize={20}
            dataSource={applications}
            totalDataCount={count}
            onPageChange={pageNum => this.handlePageChange(pageNum)}
            listItem={application => <WorkerActivityItem item={application} staffRequest={application.staff_request} />}
          />
        )}
        {!loading && count === 0 && emptyState}
        {!applications && (
          <Row style={{ margin: '100px' }}>
            <LoadingSpinner />
          </Row>
        )}
      </>
    );
  }
}

export default ApplicationsTab;
