import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import FormGroup from './FormGroup';
import Input from './Input';
import YearInput from './YearInput';

const CustomInput = styled(Input)``;

const Container = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;
  grid-column-gap: 30px;
`;

export default class ExperienceInput extends React.Component {
  static propTypes = {
    value: PropTypes.object.isRequired,
    onChange: PropTypes.func,
  };

  render() {
    const { value, onChange, errors, touched } = this.props;

    return (
      <Container>
        <FormGroup>
          <FormGroup.Label>Name:</FormGroup.Label>
          <CustomInput
            placeholder="Name"
            value={value.name}
            onChange={({ target: { value: name } }) => {
              onChange({
                ...value,
                name,
              });
            }}
          />
          {errors && touched && errors.name && touched.name && <FormGroup.Error>{errors.name}</FormGroup.Error>}
        </FormGroup>
        <FormGroup>
          <FormGroup.Label>Institution:</FormGroup.Label>
          <CustomInput
            placeholder="Institution"
            value={value.institution}
            onChange={({ target: { value: institution } }) => {
              onChange({
                ...value,
                institution,
              });
            }}
          />
          {errors && touched && errors.institution && touched.institution && (
            <FormGroup.Error>{errors.institution}</FormGroup.Error>
          )}
        </FormGroup>
        <FormGroup>
          <FormGroup.Label>Year:</FormGroup.Label>
          <YearInput
            value={value.year}
            onChange={year => {
              onChange({
                ...value,
                year,
              });
            }}
          />
          {errors && touched && errors.year && touched.year && <FormGroup.Error>{errors.year}</FormGroup.Error>}
        </FormGroup>
      </Container>
    );
  }
}
