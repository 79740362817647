import styled from 'styled-components';
import React from 'react';
import { Placeholder } from 'semantic-ui-react';
import { colors } from '../../styles/colors';
import { fontO11b } from '../../styles/fonts';

const Value = styled.div``;

const Label = styled.div`
  ${fontO11b};
`;

const InnerContainer = styled.div`
  display: flex;
  position: relative;
  border-bottom: 1px solid ${colors.lightGrey};
  padding: 5px 0;

  ${Label} {
    min-width: 120px;
    margin-right: 10px;
  }

  ${Value} {
    flex-grow: 1;
  }
`;

const ItemContainer = props => (
  <div {...props}>
    <InnerContainer>{props.children}</InnerContainer>
  </div>
);

const Item = styled(ItemContainer)``;

const Definitions = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 0 -15px;
  overflow: hidden;

  ${InnerContainer} {
    ${props => props.borderless && `border: none`};
  }

  ${Item} {
    width: ${props => 100 / props.columns}%;
    padding-left: 15px;
    padding-right: 15px;
  }
`;

const PlaceholderItem = ({ small, ...props }) => (
  <Definitions.Item {...props}>
    <Definitions.Label>
      <Placeholder>
        <Placeholder.Line />
      </Placeholder>
    </Definitions.Label>
    <Definitions.Value>
      <Placeholder>
        <Placeholder.Line />
        {!small && <Placeholder.Line />}
      </Placeholder>
    </Definitions.Value>
  </Definitions.Item>
);

Definitions.Item = Item;
Definitions.Label = Label;
Definitions.Value = Value;
Definitions.PlaceholderItem = PlaceholderItem;

export default Definitions;
