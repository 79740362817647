import styled, { css } from 'styled-components';
import { colors } from '../../styles/colors';

const getVariationStyle = variation => {
  switch (variation) {
    case 'primary':
      return css`
        color: ${colors.blue};
      `;
    case 'danger':
      return css`
        color: ${colors.orange};
      `;
    default:
      return css``;
  }
};

export default styled.span`
  color: ${colors.blue};
  text-decoration: underline;
  cursor: pointer;

  ${props => getVariationStyle(props.variation)};
`;
