import React from 'react';
import styled from 'styled-components';
import { Popover, Avatar } from 'antd';
import { colors } from '../../../../styles/colors';

const CusomCenterIcon = styled(Avatar)`
  z-index: 10;
`;

export default ({ title, content }) => {
  return (
    <div>
      <Popover title={title} content={content}>
        <CusomCenterIcon style={{ backgroundColor: colors.orange }} size={30} icon="environment" />
      </Popover>
    </div>
  );
};
