import base from './base';
import { endpoints } from '../config';

const fetchBillingReport = async id => {
  const response = await base.api.get(`${endpoints.billingReports}/${id}/`);
  return response;
};

const fetchBillingReports = async params => {
  const response = await base.api.get(`${endpoints.billingReports}/`, {
    params,
  });
  return response;
};

const downloadBillingReports = params => {
  const response = base.api.get(`${endpoints.billingReports}/download/`, { params });
  return response;
};

const generateBillingReport = async billingDate => {
  const response = await base.api.post(`${endpoints.billingReports}/generate/`, { billing_date: billingDate });
  return response;
};

const billBillingReport = async id => {
  const response = await base.api.post(`${endpoints.billingReports}/${id}/bill/`);
  return response;
};

const updateBillingReport = async (id, params) => {
  const response = await base.api.patch(`${endpoints.billingReports}/${id}/`, params);
  return response;
};

const batchBillBillingReport = async ids => {
  const response = await base.api.post(`${endpoints.billingReports}/batch_bill/`, { billing_reports: ids });
  return response;
};

const fetchBillingItems = async (page, params) => {
  params.page = page || 1;
  const response = await base.api.get(`${endpoints.billingItems}/`, {
    params,
  });
  return response;
};

// TODO: refactor functions below to -> changeBillingItemStatus = async (id, status) => ... ${id}/${status}/
const disputeBillingItem = async id => {
  const response = await base.api.post(`${endpoints.billingItems}/${id}/dispute/`);
  return response;
};

const resolveBillingItem = async id => {
  const response = await base.api.post(`${endpoints.billingItems}/${id}/resolve/`);
  return response;
};

const billBillingItem = async id => {
  const response = await base.api.post(`${endpoints.billingItems}/${id}/bill/`);
  return response;
};

const voidBillingItem = async id => {
  const response = await base.api.post(`${endpoints.billingItems}/${id}/void/`);
  return response;
};

export default {
  fetchBillingReport,
  fetchBillingReports,
  downloadBillingReports,
  generateBillingReport,
  batchBillBillingReport,
  billBillingReport,
  updateBillingReport,
  fetchBillingItems,
  disputeBillingItem,
  resolveBillingItem,
  billBillingItem,
  voidBillingItem,
};
