import React from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import { colors } from '../../styles/colors';
import { fontO10b } from '../../styles/fonts';

const Day = styled.div`
  width: 21px;
  height: 21px;
  border-radius: 100%;
  background: ${props => (props.active ? colors.yellow : colors.lightGrey)};
  color: ${props => (props.active ? colors.black : colors.grey)};
  ${fontO10b};
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;

  cursor: ${props => (props.clickable ? 'pointer' : 'initial')};

  &:hover {
    ${props => props.clickable && `background: ${rgba(colors.yellow, 0.5)};`}
  }
`;

const Container = styled.div`
  display: flex;

  ${Day} {
    margin-right: 2px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const daysList = [
  { value: 6, label: 'S', name: 'Sunday' },
  { value: 0, label: 'M', name: 'Monday' },
  { value: 1, label: 'T', name: 'Tuesday' },
  { value: 2, label: 'W', name: 'Wednesday' },
  { value: 3, label: 'T', name: 'Thursday' },
  { value: 4, label: 'F', name: 'Friday' },
  { value: 5, label: 'S', name: 'Saturday' },
];

export default ({ days, onDayClick, ...props }) => (
  <Container {...props}>
    {daysList.map(day => (
      <Day
        key={day.value}
        active={days.indexOf(day.value) !== -1}
        title={day.name}
        onClick={() => {
          if (onDayClick) {
            onDayClick(day.value);
          }
        }}
        clickable={!!onDayClick}
      >
        <span>{day.label}</span>
      </Day>
    ))}
  </Container>
);
