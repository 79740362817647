import React from 'react';
import { Typography, Modal } from 'antd';

import paymentApi from '../../../../services/paymentApi';

const { Title, Text } = Typography;

class MarkPaidAllModal extends React.Component {
  state = {
    isLoading: false,
  };

  handleSubmit = async () => {
    this.setState({ isLoading: true });
    await paymentApi.markAllPaid(this.props.params);
    this.setState({ isLoading: false });
    this.props.onChange();
  };

  render() {
    const { onCancel, visible, count } = this.props;
    if (!visible) return <> </>;

    const { isLoading } = this.state;

    return (
      <Modal
        title={<Title level={4}>Mark reports as paid</Title>}
        centered
        visible={visible}
        onCancel={onCancel}
        onOk={this.handleSubmit}
        width={480}
        okType="v2-primary"
        okText="Yes"
        okButtonProps={{ loading: isLoading }}
      >
        <Text>You are about to mark {count} reports as paid. Are you sure?</Text>
      </Modal>
    );
  }
}

export default MarkPaidAllModal;
