import { Tag } from 'antd';
import React from 'react';
import { accountStatus } from '../../constants';
import { humanizeText } from '../../utilities/textUtils';

const getColor = (status, verified) => {
  // un-approved accounts can be deactivated so deactivated status comes first
  if (verified || status === accountStatus.DEACTIVATED) {
    switch (status) {
      case accountStatus.PILOT:
        return 'purple';
      case accountStatus.ACTIVE:
        return 'green';
      case accountStatus.REJECTED:
        return 'red';
      case accountStatus.DEACTIVATED:
      case accountStatus.DISABLED:
      case accountStatus.SUSPENDED:
        return 'grey';
      default:
        return 'grey';
    }
  }
  return 'gold'; // for pending approval status
};

const getText = (status, verified) => {
  if (verified || status === accountStatus.DEACTIVATED) {
    return humanizeText(status);
  }
  return humanizeText(accountStatus.PENDING_APPROVAL);
};

export default ({ style, status, verified }) => (
  <Tag style={style} color={getColor(status, verified)}>
    {getText(status, verified)}
  </Tag>
);
