import React from 'react';
import { Divider, Popover, Button, Row, Col, Radio } from 'antd';

class RadioButtonFilter extends React.Component {
  state = {
    visible: false,
  };

  toggleVisibility = () => {
    this.setState(prevState => {
      return {
        visible: !prevState.visible,
      };
    });
  };

  handleApply = () => {
    this.setState(
      {
        visible: false,
      },
      this.props.onApply,
    );
  };

  handleCheckAll = () => {
    const { options, selectedOptions, onChange } = this.props;

    if (selectedOptions.length === options.length) {
      onChange([]);
    } else {
      onChange(options.map(option => option.id));
    }
  };

  render() {
    const { title, options, selectedOptions, optionLabelField, onChange, showActionButtons = false } = this.props;
    const { visible } = this.state;
    return (
      <Popover
        visible={visible}
        trigger={['click']}
        placement="bottomLeft"
        onVisibleChange={value => this.setState({ visible: value })}
        overlayStyle={{ zIndex: 2 }}
        overlayClassName="ant-popover-wrapper"
        content={
          <>
            <Radio.Group
              value={selectedOptions}
              onChange={onChange}
              style={{ display: 'flex', flexDirection: 'column', paddingLeft: '6px' }}
            >
              {options.map(option => (
                <Row type="flex" align="middle" key={option.id} style={{ height: '32px' }}>
                  <Radio value={option.id}>{option[optionLabelField]}</Radio>
                </Row>
              ))}
              {showActionButtons && (
                <>
                  <Divider style={{ margin: '12px 0' }} />
                  <Row type="flex" justify="space-between" style={{ paddingBottom: '12px' }}>
                    <Col>
                      <Button size="small" onClick={this.toggleVisibility}>
                        Cancel
                      </Button>
                    </Col>
                    <Col>
                      <Button size="small" className="ant-btn-v2-primary" onClick={this.handleApply}>
                        Apply
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            </Radio.Group>
          </>
        }
      >
        <Button block style={{ padding: '0' }} onClick={this.toggleVisibility}>
          {title}
        </Button>
      </Popover>
    );
  }
}

export default RadioButtonFilter;
