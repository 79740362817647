import styled from 'styled-components';

export default styled.div`
  max-width: 900px;
  margin: 0 auto;

  display: grid;
  grid-row-gap: 30px;
  grid-template-columns: 1fr 1fr;
  align-items: flex-start;

  > * {
    grid-column: 1 / span 2;
  }
`;
