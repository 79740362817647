import React, { Component } from 'react';
import { message } from 'antd';
import routes from '../../routes';
import { logInWithGoogle } from '../../services/firebaseApi';
import { firebaseErrorCodes, WORKMATE_DOMAIN } from '../../constants';
import { withTranslation } from 'react-i18next';
import { deleteUser } from 'firebase/auth';
import GoogleButton from './GoogleButton';

class LoginPageView extends Component {
  // Redirect to homepage if user is loggedIn
  componentDidMount() {
    if (localStorage.getItem('token')) {
      this.props.history.push(routes.home);
    }
  }

  // Redirect to homepage after logging in.
  componentDidUpdate() {
    if (localStorage.getItem('token')) {
      this.props.history.push(routes.home);
    }
  }

  isValidAdmin = user => {
    const userEmailDomain = user.email.split('@')[1];
    return userEmailDomain === WORKMATE_DOMAIN;
  };

  handleSignIn = async () => {
    const { t } = this.props;
    try {
      const result = await logInWithGoogle();
      const user = result.user;
      if (!this.isValidAdmin(user)) {
        await deleteUser(user);
        message.error(t('loginFailed'));
      } else {
        this.props.handleLogin(user);
      }
    } catch (error) {
      console.log(error.code);
      if (error.code !== firebaseErrorCodes.POPUP_CLOSED_BY_USER) {
        message.error(t('loginFailed'));
      }
    }
  };

  render() {
    return <GoogleButton onClick={this.handleSignIn} />;
  }
}

export default withTranslation()(LoginPageView);
