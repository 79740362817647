import styled from 'styled-components';
import { colors } from '../../styles/colors';
import { fontO18r } from '../../styles/fonts';

const CardHeader = styled.h2`
  ${fontO18r};
  display: block;
  padding-bottom: 10px;
  border-bottom: 1px solid ${colors.lightGrey};
  margin-bottom: 20px;
`;

const Content = styled.div``;

const Card = styled.div`
  padding: ${props => (props.size === 'large' ? '30px 20px 30px' : '20px 20px 30px')};
  background: ${colors.white};
  border-radius: 4px;
  display: flex;
  flex-direction: column;

  ${Content} {
    overflow-y: auto;
    overflow-x: hidden;
  }
`;

export default {
  ...Card,
  Header: CardHeader,
  Content,
};
