import React from 'react';
import { Switch, Route } from 'react-router-dom';
import BillingReportListView from './BillingReportListView';
import BillingReportDetailView from './BillingReportDetailView';
import routes from '../../../routes';

const BillingsPage = () => {
  return (
    <Switch>
      <Route exact path={routes.billingReports} component={BillingReportListView} />
      <Route exact path={routes.billingReportDetail} component={BillingReportDetailView} />
    </Switch>
  );
};

export default BillingsPage;
