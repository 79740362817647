import React from 'react';
import { Spin } from 'antd';
import clientApi from '../../../../services/clientApi';
import ClientForm from './ClientForm';

class ClientEdit extends React.Component {
  state = {
    client: null,
  };

  componentDidMount() {
    const { id } = this.props.match.params;
    clientApi.fetchClientById(id).then(data => {
      this.setState({
        client: data,
      });
    });
  }

  render() {
    const { client } = this.state;
    const { history } = this.props;
    return (
      <Spin tip="Loading" spinning={!client}>
        {client && <ClientForm client={client} history={history} />}
      </Spin>
    );
  }
}

export default ClientEdit;
