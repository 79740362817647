import React from 'react';
import { Route, Switch } from 'react-router-dom';
import routes from '../../../routes';
import SettingsContainer from './SettingsContainer';

const SettingsPage = () => {
  return (
    <Switch>
      <Route exect path={routes.settings} component={SettingsContainer} />
    </Switch>
  );
};

export default SettingsPage;
