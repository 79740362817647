import React from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';

const { RangePicker } = DatePicker;

const DateRangePicker = ({ onChange, ...props }) => {
  return (
    <RangePicker
      {...props}
      ranges={{
        Today: [moment(), moment()],
        '7 days ago': [moment().subtract(7, 'd'), moment()],
        '14 days ago': [moment().subtract(14, 'd'), moment()],
        '30 days ago': [moment().subtract(30, 'd'), moment()],
      }}
      onChange={onChange}
      format="DD/MM/YYYY"
    />
  );
};

export default DateRangePicker;
