import { endpoints } from '../config';
import base from './base';

const fetchBanks = async params => {
  const response = await base.api.get(`${endpoints.banks}/`, {
    params,
  });
  return response;
};

export default {
  fetchBanks,
};
