import React from 'react';
import { staffRequestStatuses } from '../../../../constants';
import CheckboxFilter from '../../../shared/components/CheckboxFilter';

const staffRequestStatusOptions = [
  { id: staffRequestStatuses.DRAFT, name: 'Draft' },
  { id: staffRequestStatuses.PENDING_REVIEW, name: 'Pending review' },
  { id: staffRequestStatuses.POSTED, name: 'Posted' },
  { id: staffRequestStatuses.IN_PROGRESS, name: 'In progress' },
  { id: staffRequestStatuses.ENDED, name: 'Ended' },
  { id: staffRequestStatuses.CANCELLED, name: 'Cancelled' },
];

export default class StatusFilter extends React.Component {
  state = {
    selectedOptions: [],
  };

  componentDidMount() {
    // We store selectedOptions locally because it's independent from parent's state until we hit apply
    const { selectedOptions } = this.props;

    this.setState({
      selectedOptions,
    });
  }

  handleChange = values => {
    this.setState({
      selectedOptions: values,
    });
  };

  handleApply = () => {
    this.props.onChange(this.state.selectedOptions);
  };

  render() {
    const { selectedOptions } = this.state;

    return (
      <CheckboxFilter
        showActionButtons
        showSelectAll
        title={`Status ${selectedOptions.length > 0 ? `(${selectedOptions.length})` : ''}`}
        options={staffRequestStatusOptions}
        selectedOptions={selectedOptions}
        selectAllTitle="All status"
        onChange={this.handleChange}
        onApply={this.handleApply}
        optionLabelField="name"
      />
    );
  }
}
