import React from 'react';
import { message } from 'antd';
import SelectionWithNotesModal from '../../../shared/components/SelectionWithNotesModal';
import { workerSuspensionReasons } from '../../../../constants';
import partnerApi from '../../../../services/partnerApi';

class SuspendWorkerModal extends React.Component {
  state = {
    loading: false,
  };

  handleSuspend = async values => {
    const { partner, onUpdate, onClose } = this.props;
    const { option, notes } = values;

    this.setState({ loading: true });
    try {
      await partnerApi.suspendPartner(
        { id: partner.id },
        {
          suspension_reason: option,
          notes,
        },
      );
      this.setState({ loading: false });
      onClose();
      onUpdate();
    } catch (error) {
      message.error(error.response.data.detail || error.message);
      this.setState({ loading: false });
    }
  };

  render() {
    const { loading } = this.state;
    const { visible, partner, onClose } = this.props;

    return (
      <SelectionWithNotesModal
        visible={visible}
        loading={loading}
        modalTitle="Suspend worker"
        modalPrompt={
          <>
            Are you sure you want to suspend{' '}
            <b>
              {partner.first_name} {partner.last_name}
            </b>
            ? This user will no longer be able to use our platform and app. Please give a reason.
          </>
        }
        optionsLabel="Reason for suspending worker"
        options={workerSuspensionReasons}
        optionValidationRules={[
          {
            required: true,
            message: 'Please select an option',
          },
        ]}
        notesLabel="Additional notes (optional)"
        notesInputPlaceholderText="Please give your reasons and details"
        onOk={this.handleSuspend}
        okButtonType="danger"
        okButtonLabel="Suspend"
        onCancel={onClose}
      />
    );
  }
}

export default SuspendWorkerModal;
