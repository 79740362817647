import React from 'react';
import { Row, Card, Typography } from 'antd';
import workmateLogo from '../../assets/images/workmate-logo.svg';

const PublicLayout = props => {
  return (
    <Row type="flex" justify="center" align="middle" style={{ height: '100vh' }}>
      <Card bordered={false} style={{ border: '1px solid #dcdcdc', boxShadow: '0px 15px 20px 5px #0000001a' }}>
        <div style={{ width: '100%', textAlign: 'center', padding: '16px 16px 32px' }}>
          <img src={workmateLogo} alt="Logo" />
          <Typography.Text strong>ADMIN</Typography.Text>
        </div>
        {props.children}
      </Card>
    </Row>
  );
};

export default PublicLayout;
