import { Button, Col, Divider, Input, List, message, Radio, Row, Select, Typography } from 'antd';
import moment from 'moment';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { applicationStatuses, pendingContractStatuses } from '../../../../constants';
import applicationApi from '../../../../services/applicationApi';
import CardWithCount from '../../../shared/components/CardWithCount';
import CheckboxFilter from '../../../shared/components/CheckboxFilter';
import StatusTag from '../../../shared/components/StatusTag';
import { getAbsoluteDistance } from '../../../../utilities/distanceUtils';

import getPaginationConfig from '../../../utilities/getPaginationConfig';
import ApplicationRejectionModal from './ApplicationRejectionModal';
import WorkerInfoItem from './WorkerInfoItem';
import ForceHireApplicationModal from '../Modals/ForceHireApplicationModal';
import HireApplicationModal from '../Modals/HireApplicationModal';
import DirectJobInviteModal from '../Modals/DirectJobInviteModal';
import { checkAccess } from '../../../../shared/access/Access';
import { permissions } from '../../../../shared/access/accessConfig';
import DirectJobInviteLeadModal from '../Modals/DirectJobInviteLeadModal';

const { Paragraph, Text } = Typography;
const { Option } = Select;

class StaffRequestApplicantsList extends React.Component {
  state = {
    isLoading: false,
    searchParams: {
      page: 1,
      staff_request: this.props.staffRequestId,
      status: 'pending_onboarding,applied',
      shortlisted: 'false',
      interviewed: 'false',
      ordering: 'reliability_score',
      // filter for fetching all non parent applications
      shift_applications__isnull: true,
    },
    numApplied: 0,
    numContractSent: 0,
    numRecords: 0,
    applications: null,
    hasFetchedApplications: false,
    showApplicationRejectionModal: false,
    selectedApplication: null,
    showHireModal: false,
    showForceHireModal: false,
    showInviteWorkerModal: false,
    showInviteLeadWorkerModal: false,
  };

  async componentDidMount() {
    this.setState({ isLoading: true });
    this.fetchData();
  }

  // For filtering by shortlisted workers
  handleRadioChange = event => {
    const { value } = event.target;
    this.handleSearchParamChange('shortlisted', value);
  };

  handleAllFilterSelectChange = (field, checkedValues, allOptions) => {
    const allOptionsSelected = checkedValues.length === allOptions.length;
    const { searchParams } = this.state;
    const nextSearchParams = searchParams;
    // De-select all
    if (allOptionsSelected) {
      nextSearchParams[field] = undefined;
    } else {
      // Select all available options
      nextSearchParams[field] = allOptions.map(({ id }) => id).join(',');
    }
    this.setState({ searchParams: nextSearchParams });
  };

  handleSearchParamChange = (field, searchValue) => {
    this.setState(
      prevState => {
        const nextSearchParams = prevState.searchParams;
        nextSearchParams[field] = searchValue;
        nextSearchParams.page = 1;
        return { searchParams: nextSearchParams };
      },
      () => {
        this.fetchApplicationsWithParams();
      },
    );
  };

  getStatusCounts = () => {
    const { contractRequired } = this.props;
    return [
      { title: 'Applied', num: this.state.numApplied, hasBadge: true },
      {
        title: contractRequired ? 'Contract Sent' : 'Job Offer sent',
        num: this.state.numContractSent,
        hasBadge: false,
      },
      { title: 'Workers needed', num: this.props.numWorkersRequired, hasBadge: false },
    ];
  };

  getStatusFilter = () => {
    const { contractRequired } = this.props;
    const applicationStatusOptions = [
      { id: applicationStatuses.PENDING_ONBOARDING, name: 'Pending onboarding' },
      { id: applicationStatuses.APPLIED, name: 'Applied' },
      { id: applicationStatuses.PENDING_CONTRACT, name: contractRequired ? 'Contract sent' : 'Job Offer sent' },
      { id: applicationStatuses.REJECTED, name: 'Rejected' },
      { id: applicationStatuses.WITHDRAWN, name: 'Withdrawn' },
    ];
    const { searchParams } = this.state;
    const { status } = searchParams;
    const statusCheckedList = status ? status.split(',') : [];
    return {
      title: 'Status',
      selectAllTitle: 'All status',
      selectedOptions: statusCheckedList,
      options: applicationStatusOptions,
      FilterComponent: CheckboxFilter,
      onChange: values => {
        const joinedValues = values.join(',');
        this.setState(prevState => {
          const nextSearchParams = prevState.searchParams;
          nextSearchParams.status = joinedValues;
          return { searchParams: nextSearchParams };
        });
      },
      onCheckAllChange: () => this.handleAllFilterSelectChange('status', statusCheckedList, applicationStatusOptions),
      onApply: () => {
        this.setState(
          prevState => {
            const nextSearchParams = prevState.searchParams;
            nextSearchParams.page = 1;
            return { searchParams: nextSearchParams };
          },
          () => {
            this.fetchApplicationsWithParams();
          },
        );
      },
    };
  };

  getApplicationListItemFields = application => {
    const {
      status,
      shortlisted,
      created_date: appliedDate,
      id: applicationId,
      rejection_reason: rejectionReason,
      notes,
      partner,
      staff_request: staffRequest,
    } = application;

    const partnerAddress = partner.address;
    const srAddress = staffRequest.location.address;
    const absoluteDistance = partnerAddress && srAddress && getAbsoluteDistance(partnerAddress, srAddress);
    const distance = absoluteDistance && `${absoluteDistance.toFixed(2)} km`;
    return {
      applicationId,
      shortlisted,
      appliedDate,
      status,
      rejectionReason,
      notes,
      partnerName: partner.last_name ? `${partner.first_name} ${partner.last_name}` : partner.first_name,
      distance,
      staffRequest,
    };
  };

  getApplicationOptions = (status, application, staffRequest, index) => {
    if (!checkAccess(permissions.editStaffRequest)) {
      return <></>;
    }

    const RejectButton = () => (
      <Button
        type="v2-secondary"
        style={{ color: '#FF5251', width: '108px' }}
        icon="close"
        onClick={() => {
          this.openRejectionModal(application);
        }}
      >
        Reject
      </Button>
    );

    const UpdateWorkerState = () => {
      if (staffRequest.client_interview_required === true) {
        return (
          <Button
            type="v2-secondary"
            style={{ color: '#69E4A6', width: '108px', marginBottom: '2px' }}
            icon="check"
            onClick={() => {
              this.onInterview(application.id, index);
            }}
          >
            Interview
          </Button>
        );
      }

      return (
        <Button
          type="v2-secondary"
          style={{ color: '#69E4A6', width: '108px', marginBottom: '2px' }}
          icon="check"
          onClick={() => {
            this.onHire(application);
          }}
        >
          Hire
        </Button>
      );
    };

    switch (status) {
      case applicationStatuses.APPLIED:
      case applicationStatuses.PENDING_ONBOARDING:
        return (
          <>
            <section>
              <UpdateWorkerState />
            </section>
            <section>
              <RejectButton />
            </section>
          </>
        );
      case applicationStatuses.PENDING_CONTRACT:
      case applicationStatuses.WITHDRAWN:
      case applicationStatuses.REJECTED:
      default:
        return <> </>;
    }
  };

  handleHireUpdate = applicationId => {
    this.setState(prevState => {
      this.props.updateParentStatusCounts([
        { status: applicationStatuses.APPLIED, increment: false },
        { status: applicationStatuses.PENDING_CONTRACT, increment: true },
      ]);
      const nextApplications = this.updateLocalApplicationData(
        prevState.applications,
        applicationId,
        applicationStatuses.PENDING_CONTRACT,
      );
      return {
        numApplied: prevState.numApplied - 1,
        numContractSent: prevState.numContractSent + 1,
        applications: nextApplications,
      };
    });
  };

  onReject = async (applicationId, reason, notes) => {
    const { selectedApplication } = this.state;
    const {
      id,
      status: prevStatus,
      partner: { first_name, last_name },
    } = selectedApplication;
    const workerName = last_name ? `${first_name} - ${last_name}` : first_name;

    await this.setState(prevState => {
      const nextApplications = this.updateLocalApplicationData(
        prevState.applications,
        id,
        applicationStatuses.REJECTED,
        reason,
        notes,
      );
      if (prevStatus === applicationStatuses.PENDING_CONTRACT) {
        this.props.updateParentStatusCounts([
          { status: applicationStatuses.PENDING_CONTRACT, increment: false },
          { status: applicationStatuses.REJECTED, increment: true },
        ]);
        return { numContractSent: prevState.numContractSent - 1, applications: nextApplications };
      }
      this.props.updateParentStatusCounts([
        { status: applicationStatuses.APPLIED, increment: false },
        { status: applicationStatuses.REJECTED, increment: true },
      ]);
      return { numApplied: prevState.numApplied - 1, applications: nextApplications };
    });
    message.success(`Successfully rejected ${workerName}`);
    this.closeRejectionModal();
  };

  onPageChange = pageNum => {
    this.setState(
      prevState => {
        const nextSearchParams = prevState.searchParams;
        nextSearchParams.page = pageNum;
        return { searchParams: nextSearchParams };
      },
      () => this.fetchApplicationsWithParams(),
    );
  };

  onInterview = async (selectedWorkerId, localApplicationIndex) => {
    const response = await applicationApi.interview(selectedWorkerId);
    if (response) {
      this.setState(prevState => {
        const { applications } = prevState;
        const nextApplications = applications;
        nextApplications[localApplicationIndex].interviewed = true;
        nextApplications[localApplicationIndex].shortlisted = false;
        return { applications: nextApplications };
      });
      message.success(`Moved worker to interview. Please refresh the page.`);
    }
  };

  onHire = async application => {
    console.log('clicked on hire here');
    this.setState({
      showHireModal: true,
      selectedApplication: application,
    });
  };

  openRejectionModal = application => {
    this.setState({
      showApplicationRejectionModal: true,
      selectedApplication: application,
    });
  };

  openInviteWorkerModal = () => {
    this.setState({ showInviteWorkerModal: true });
  };

  closeInviteWorkerModal = () => {
    this.setState({ showInviteWorkerModal: false });
  };

  openInviteLeadWorkerModal = application => {
    this.setState({
      showInviteLeadWorkerModal: true,
      selectedApplication: application,
    });
  };

  closeInviteLeadWorkerModal = () => {
    this.setState({
      showInviteLeadWorkerModal: false,
    });
  };

  closeRejectionModal = () => {
    this.setState({
      showApplicationRejectionModal: false,
      selectedApplication: undefined,
    });
  };

  closeForceHireModal = () => {
    this.setState({ showForceHireModal: false, selectedApplication: undefined });
  };

  closeHireModal = () => {
    this.setState({ showHireModal: false, selectedApplication: undefined });
  };

  // Takes a list of workers and update the worker status given a ID.
  // This is used to avoid re-fetching the entire table, but to update the worker table in our state.
  updateLocalApplicationData = (
    applications,
    applicationId,
    nextStatus,
    nextReason = undefined,
    nextNotes = undefined,
  ) => {
    const applicationIndex = applications.findIndex(application => application.id === applicationId);
    applications[applicationIndex].status = nextStatus;
    if (nextReason) {
      applications[applicationIndex].reject_reason = {
        code: nextReason,
      };
    }
    if (nextNotes) {
      applications[applicationIndex].notes = nextNotes;
    }
    return applications;
  };

  fetchData = async () => {
    this.setState({ isLoading: true });
    const { searchParams } = this.state;
    const [responseWithCount, response] = await Promise.all([
      applicationApi.fetchApplications({
        staff_request: this.props.staffRequestId,
        // fetch unqualified workers only
        shortlisted: 'false',
        interviewed: 'false',
        // filter for fetching all non parent applications
        shift_applications__isnull: true,
      }),
      applicationApi.fetchApplications(searchParams),
    ]);

    if (responseWithCount && response) {
      const { status_counts } = responseWithCount;
      this.setState({
        isLoading: false,
        numApplied: status_counts.applied,
        numContractSent: status_counts.pending_contract,
        numRecords: response.count,
        applications: response.results,
        hasFetchedApplications: true,
      });
    }
  };

  fetchApplicationsWithParams = async () => {
    this.setState({ isLoading: true });
    const { searchParams } = this.state;
    const response = await applicationApi.fetchApplications({
      staff_request: this.props.staffRequestId,
      ...searchParams,
    });
    this.setState({
      isLoading: false,
      applications: response.results,
      numRecords: response.count,
    });
  };

  getStatusSection = application => {
    const rejectionReason = application.reject_reason;
    const status =
      // eslint-disable-next-line no-nested-ternary
      application.status === applicationStatuses.PENDING_CONTRACT
        ? this.props.contractRequired
          ? pendingContractStatuses.CONTRACT_SENT
          : pendingContractStatuses.JOB_OFFER_SENT
        : application.status;
    return (
      <>
        <StatusTag status={status} style={{ marginRight: '8px' }} />
        <Text type="secondary">{`${moment(application.created_date).toNow()} ago`}</Text>

        {application?.reject_reason?.name && (
          <section style={{ paddingTop: '16px' }}>
            <Text strong type="secondary">
              REJECTION REASON
            </Text>
            <Paragraph ellipsis={{ rows: 1 }}>
              <Text strong>{`${rejectionReason ? rejectionReason.name : ''}`}</Text>
              {application.notes && <Text type="secondary">{` · ${application.notes}`}</Text>}
            </Paragraph>
          </section>
        )}
      </>
    );
  };

  render() {
    const {
      hasFetchedApplications,
      searchParams,
      numRecords,
      isLoading,
      applications,
      selectedApplication,
      showApplicationRejectionModal,
      showHireModal,
      showForceHireModal,
      showInviteWorkerModal,
      showInviteLeadWorkerModal,
    } = this.state;
    const { country, staffRequestId, clientDirectJobInviteLink, isSGClient } = this.props;
    const statusCounts = this.getStatusCounts();
    const statusFilter = this.getStatusFilter();
    return (
      <>
        {/* Top level summary */}
        <CardWithCount loading={!hasFetchedApplications} countList={statusCounts} />

        {/* Filters & Search */}
        <Row type="flex" align="bottom" justify="space-between" style={{ marginBottom: '32px' }}>
          <Col>
            <Row style={{ marginBottom: '16px' }}>
              <Radio.Group defaultValue="false" className="v2-radio-wrapper" onChange={this.handleRadioChange}>
                <Radio.Button value="false">All workers</Radio.Button>
                <Radio.Button value="true">Shortlisted</Radio.Button>
              </Radio.Group>
              {!isSGClient && (
                <Button icon="plus" onClick={this.openInviteWorkerModal} style={{ marginLeft: '8px' }}>
                  Invite Workers
                </Button>
              )}
            </Row>
            <Row style={{ width: '128px' }}>
              <CheckboxFilter
                showActionButtons
                title={`${statusFilter.title} ${
                  statusFilter.selectedOptions.length > 0 ? `(${statusFilter.selectedOptions.length})` : ''
                }`}
                options={statusFilter.options}
                selectedOptions={statusFilter.selectedOptions}
                selectAllTitle={statusFilter.selectAllTitle}
                onChange={statusFilter.onChange}
                onCheckAllChange={statusFilter.onCheckAllChange}
                indeterminate={
                  statusFilter.selectedOptions.length > 0 &&
                  statusFilter.selectedOptions.length < statusFilter.options.length
                }
                allChecked={statusFilter.selectedOptions.length === statusFilter.options.length}
                onApply={statusFilter.onApply}
                optionLabelField="name"
              />
            </Row>
          </Col>
          <Col span={8}>
            <Input.Search
              allowClear
              placeholder="Search by Workmate"
              onSearch={value => this.handleSearchParamChange('search', value)}
            />
          </Col>
        </Row>
        <Divider />

        {/* Sorting and recommendation */}
        <Row type="flex" justify="end" style={{ marginBottom: '48px' }}>
          <Col style={{ width: '216px' }}>
            <Text type="secondary">Sort by</Text>
            {/* Temprorarily disable all other options. They might be removed soon in the future. */}
            <Select
              disabled
              value="reliability_score"
              style={{ marginLeft: '8px', width: '156px' }}
              onChange={value => this.handleSearchParamChange('ordering', value)}
            >
              <Option value="reliability_score">Reliability</Option>
              <Option value="created_date">First applied</Option>
              <Option value="-created_date">Latest applied</Option>
              <Option value="distance">Nearest workers</Option>
            </Select>
          </Col>
        </Row>

        {/* Actual Worker list */}
        <List
          itemLayout="vertical"
          pagination={{ ...getPaginationConfig(numRecords, this.onPageChange), current: searchParams.page || 1 }}
          loading={isLoading}
          dataSource={applications || []}
          renderItem={(application, index) => {
            const { status, distance, hasRisk, staffRequest } = this.getApplicationListItemFields(application);

            return (
              <Row key={index}>
                <WorkerInfoItem
                  staffRequestId={this.props.staffRequestId}
                  worker={application.partner}
                  hasRisk={hasRisk}
                  distance={distance}
                  created_date={application.created_date}
                  statusSection={this.getStatusSection(application)}
                  experiences={application.partner.experiences}
                  actions={
                    checkAccess(permissions.editStaffRequest) && (
                      <>{this.getApplicationOptions(status, application, staffRequest, index)}</>
                    )
                  }
                />
                <Divider />
              </Row>
            );
          }}
        />

        {showApplicationRejectionModal && (
          <ApplicationRejectionModal
            visible={showApplicationRejectionModal}
            application={selectedApplication}
            onReject={this.onReject}
            workerName={
              selectedApplication.partner.last_name
                ? `${selectedApplication.partner.first_name} ${selectedApplication.partner.last_name}`
                : selectedApplication.partner.first_name
            }
            onClose={() => this.closeRejectionModal()}
          />
        )}

        {showHireModal && (
          <HireApplicationModal
            visible
            applicationId={selectedApplication.id}
            worker={selectedApplication.partner}
            onCancel={this.closeHireModal}
            onUpdate={() => this.handleHireUpdate(selectedApplication.id)}
          />
        )}

        {showForceHireModal && (
          <ForceHireApplicationModal
            visible
            applicationId={selectedApplication.id}
            worker={selectedApplication.partner}
            onCancel={this.closeForceHireModal}
            onUpdate={() => this.handleHireUpdate(selectedApplication.id)}
          />
        )}

        {showInviteWorkerModal && (
          <DirectJobInviteModal
            onUpdate={this.fetchData}
            visible={showInviteWorkerModal}
            onCancel={this.closeInviteWorkerModal}
            country={country}
            staffRequestId={staffRequestId}
            directJobInviteLink={clientDirectJobInviteLink}
          />
        )}

        {showInviteLeadWorkerModal && (
          <DirectJobInviteLeadModal
            onUpdate={this.fetchData}
            onCancel={this.closeInviteLeadWorkerModal}
            application={selectedApplication}
          />
        )}
      </>
    );
  }
}

export default withRouter(StaffRequestApplicantsList);
