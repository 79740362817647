import React from 'react';
import moment from 'moment-timezone';
import { message, Tooltip, Button, Badge, Tabs, Typography, Row, Col } from 'antd';

import staffRequestApi from '../../../services/staffRequestApi';
import staffRequestUtils from '../../utilities/staffRequestUtils';
import getDeepLink from '../../utilities/getDeepLink';
import { reformatDate } from '../../../utilities/dateUtils';
import { staffRequestStatuses, applicationStatuses, employmentStatuses, countries } from '../../../constants';
import { humanizeText } from '../../../utilities/textUtils';
import routes from '../../../routes';

// Should be in HC library
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import ConfirmModals from '../../shared/components/ConfirmModals';
import StaffRequestHighlight from '../../shared/components/StaffRequestHighlight';
import TimesheetContainer from './containers/TimesheetContainer';
import LeaveClaimsList from '../../containers/LeaveClaimsList';
import StaffRequestApplicantsList from './components/StaffRequestApplicantsList';
import StaffRequestEmployeesList from './components/StaffRequestEmployeesList';
import StatusTag from '../../shared/components/StatusTag';
import PopoverCopyText from '../../shared/components/PopoverCopyText';
import DropdownButtons from '../../shared/components/DropdownButtons';
import DetailCard from '../../shared/components/DetailCard';
import TaskCard from '../../shared/components/TaskCard';

// V1 folders
import { permissions } from '../../../shared/access/accessConfig';
import { checkAccess } from '../../../shared/access/Access';
import MatchedWorkersContainer from './containers/MatchedWorkersContainer';

const { TabPane } = Tabs;
const { Text } = Typography;
class StaffRequestDetailView extends React.Component {
  state = {
    isLoading: false,
    currentActiveTabKey: '0',
    staffRequest: null,
  };

  async componentDidMount() {
    this.setState({ isLoading: true });
    const staffRequestId = this.props.match.params.id;
    const staffRequest = await staffRequestApi.fetchStaffRequest(staffRequestId);
    this.setState({ staffRequest, isLoading: false });
  }

  // For children components to update secondary content.
  // We can only update by a magnitude of "1" at a time.
  updateStatusCounts = statusAndCountsList => {
    this.setState(prevState => {
      const nextSr = prevState.staffRequest;
      for (let i = 0; i < statusAndCountsList.length; i += 1) {
        const { status, increment } = statusAndCountsList[i];

        if (Object.values(applicationStatuses).includes(status)) {
          if (increment) {
            nextSr.statuses_counts[status] += 1;
          } else {
            nextSr.statuses_counts[status] = Math.max(nextSr.statuses_counts[status] - 1, 0);
          }
        } else if (Object.values(employmentStatuses).includes(status)) {
          if (increment) {
            nextSr.statuses_counts[status] += 1;
          } else {
            nextSr.statuses_counts[status] = Math.max(nextSr.statuses_counts[status] - 1, 0);
          }
        }
      }
      return {
        staffRequest: nextSr,
      };
    });
  };

  // Categorize data of the SR For secondary column
  groupStaffRequestDetails = () => {
    const { staffRequest } = this.state;
    if (!staffRequest) {
      return [];
    }

    return [
      {
        inline: true,
        title: 'Worker status',
        contentList: [
          { title: 'Pending onboarding', value: `${staffRequest.statuses_counts.pending_onboarding || 0} workers` },
          { title: 'Applied', value: `${staffRequest.statuses_counts.applied || 0} workers` },
          {
            title: staffRequest.client.contract_required ? 'Contract sent' : 'Job Offer sent',
            value: `${staffRequest.statuses_counts.pending_contract || 0} workers`,
          },
          { title: 'Hired', value: `${staffRequest.statuses_counts.hired || 0} workers` },
          { title: 'Rejected', value: `${staffRequest.statuses_counts.rejected || 0} workers` },
          { title: 'Withdrawn', value: `${staffRequest.statuses_counts.withdrawn || 0} workers` },
          { title: 'Active', value: `${staffRequest.statuses_counts.active || 0} workers` },
          { title: 'Cancelled', value: `${staffRequest.statuses_counts.cancelled || 0} workers` },
          { title: 'No-show', value: `${staffRequest.statistics.no_show || 0} workers` },
        ],
      },
      {
        inline: false,
        title: 'Requirements',
        contentList: [
          { title: 'Experience required', value: staffRequest.require_experience ? 'Yes' : 'No' },
          { title: 'Min age', value: staffRequest.min_age ? staffRequest.min_age : '-' },
          { title: 'Max age', value: staffRequest.max_age ? staffRequest.max_age : '-' },
          { title: 'Gender', value: humanizeText(staffRequest.gender) || 'Any' },
        ],
      },

      {
        inline: false,
        title: 'Client Info',
        contentList: [
          { title: 'Client location', value: `${staffRequest.location.name}` },
          { title: 'Location manager', value: `${staffRequest.manager && staffRequest.manager.name}` },
          { title: 'Contact number', value: `${staffRequest.manager && staffRequest.manager.phone}` },
          { title: 'Email', value: `${staffRequest.manager && staffRequest.manager.email}` },
        ],
      },
      {
        inline: false,
        title: 'Staff request details',
        contentList: [
          { title: 'Staff request ID', value: staffRequest.id },
          { title: 'Created date', value: reformatDate(staffRequest.created_date) },
          { title: 'Modified date', value: reformatDate(staffRequest.modified_date) },
          { title: 'Approval date', value: reformatDate(staffRequest.modified_date) },
        ],
      },
    ];
  };

  updateActiveKey = value => {
    this.setState({ currentActiveTabKey: value });
  };

  getTabsForSr = () => {
    const { staffRequest } = this.state;
    return [
      {
        tabTitle: 'Matched',
        content: (
          <MatchedWorkersContainer
            staffRequestId={staffRequest.id}
            isDirectHire={staffRequest.direct_hire ? true : false}
          />
        ),
        hasNotification: false,
      },
      {
        tabTitle: 'Applicants',
        content: (
          <StaffRequestApplicantsList
            staffRequestId={staffRequest.id}
            clientDirectJobInviteLink={staffRequest.client.direct_job_invite_link}
            country={staffRequest.client.country}
            numWorkersRequired={staffRequest.staff_required}
            updateParentStatusCounts={this.updateStatusCounts}
            contractRequired={staffRequest.client.contract_required}
            isSGClient={staffRequest?.client?.country?.code === countries.singapore.code}
          />
        ),
        hasNotification: false,
      },
      {
        tabTitle: 'Employees',
        content: (
          <StaffRequestEmployeesList
            staffRequestId={staffRequest.id}
            numWorkersRequired={staffRequest.staff_required}
            updateParentStatusCounts={this.updateStatusCounts}
          />
        ),
        hasNotification: false,
      },
      {
        tabTitle: 'Timesheets',
        content: (
          <TimesheetContainer
            staffRequestId={staffRequest.id}
            locationId={staffRequest.location.id}
            positionId={staffRequest.position.id}
          />
        ),
        hasNotification: false,
      },
      { tabTitle: 'Leave', content: <LeaveClaimsList staffRequestId={staffRequest.id} />, hasNotification: false },
    ];
  };

  getSrActions = () => {
    const { staffRequest } = this.state;
    const { title, client, id } = staffRequest;

    const ApproveButton = () => {
      if (!checkAccess(permissions.postStaffRequest)) {
        return <></>;
      }
      return (
        <Button
          type="v2-primary"
          style={{ marginRight: '4px' }}
          icon="file-done"
          onClick={() => {
            ConfirmModals.approveSr(title, id, client.name, () => this.onApproveSr(id));
          }}
        >
          Approve
        </Button>
      );
    };
    const DisabledApproveButton = () => {
      if (!checkAccess(permissions.postStaffRequest)) {
        return <></>;
      }
      return (
        <Tooltip placement="topLeft" title="SR end date must be after today to be approved">
          <Button disabled icon="file-done" style={{ marginRight: '4px' }}>
            Approve
          </Button>
        </Tooltip>
      );
    };
    const ReviewButton = () => {
      if (!checkAccess(permissions.createStaffRequest)) {
        return <></>;
      }
      return (
        <Button
          type="v2-secondary"
          style={{ marginRight: '4px' }}
          icon="download"
          onClick={() => {
            ConfirmModals.submitSr(title, id, client.name, () => this.onSubmitSr(id));
          }}
        >
          Submit for review
        </Button>
      );
    };

    if (
      staffRequest.status === staffRequestStatuses.ENDED &&
      (!staffRequest.end_time || (staffRequest.end_time && moment(staffRequest.end_time).isBefore(new Date())))
    ) {
      return <DisabledApproveButton />;
    }

    if (
      [staffRequestStatuses.CANCELLED, staffRequestStatuses.ENDED, staffRequestStatuses.PENDING_REVIEW].includes(
        staffRequest.status,
      )
    ) {
      return <ApproveButton />;
    }

    if (staffRequest.status === staffRequestStatuses.DRAFT) {
      return (
        <>
          <ApproveButton />
          <ReviewButton />
        </>
      );
    }
    // Posted SRs have no actions.
    return <> </>;
  };

  getDropdownButtons = (title, id, clientName, status) => {
    const buttons = [];
    if (checkAccess(permissions.editStaffRequest)) {
      buttons.push({
        title: 'Edit',
        onClick: () => this.goToEditPage(),
      });
    }

    if (checkAccess(permissions.createStaffRequest)) {
      buttons.push({
        title: 'Duplicate',
        onClick: () => this.goToCreationPage(id),
      });
    }

    if (checkAccess(permissions.cancelStaffRequest)) {
      buttons.push({
        title: 'Cancel',
        style: { color: '#FF5251' },
        onClick: () => {
          ConfirmModals.cancelSr(title, id, clientName, () => this.onCancelSr(id));
        },
        disabled: status === staffRequestStatuses.CANCELLED,
      });
    }

    return buttons;
  };

  goToEditPage = () => {
    const { staffRequest } = this.state;
    this.props.history.push(routes.editStaffRequest.replace(':id', staffRequest.id));
  };

  goToCreationPage = id => {
    this.props.history.push({ pathname: routes.createStaffRequest, state: { replicateId: id } });
  };

  onCancelSr = async staffRequestId => {
    const cancelledSr = await staffRequestApi.cancelStaffRequest(staffRequestId);
    if (cancelledSr) {
      message.warning(`SR #${cancelledSr.id} has been cancelled`);
      this.setState({ staffRequest: cancelledSr });
    }
  };

  onApproveSr = async staffRequestId => {
    const approvedSr = await staffRequestApi.postStaffRequest(staffRequestId);
    if (approvedSr) {
      message.success(`SR #${approvedSr.id} has been approved`);
      this.setState({ staffRequest: approvedSr });
    }
  };

  onSubmitSr = async staffRequestId => {
    const submittedSr = await staffRequestApi.submitStaffRequest(staffRequestId);
    if (submittedSr) {
      message.success(`SR #${submittedSr.id} has been submitted for review`);
      this.setState({ staffRequest: submittedSr });
    }
  };

  render() {
    const { currentActiveTabKey, isLoading, staffRequest } = this.state;
    if (isLoading || !staffRequest) return <LoadingSpinner />;
    const staffRequestDetailGroups = this.groupStaffRequestDetails();
    const sRTabs = this.getTabsForSr();
    const { id, clientName, title, status, footNoteText } = staffRequestUtils.cleanSRListData([staffRequest])[0];

    return (
      <Row type="flex" gutter={50}>
        <Col span={16}>
          <section style={{ marginBottom: '48px' }}>
            <StaffRequestHighlight
              staffRequest={staffRequest}
              footNote={
                <>
                  <StatusTag status={status} style={{ marginRight: '8px' }} />
                  <Text type="secondary" style={{ fontSize: '12px' }}>
                    {footNoteText}
                  </Text>
                </>
              }
            />
            <Row style={{ marginTop: '24px' }}>
              {this.getSrActions()}

              <PopoverCopyText
                text={getDeepLink(
                  id,
                  staffRequest.client.country.code,
                  staffRequest.location.address.area.city.name.toLowerCase(),
                )}
                title="Share worker app deep link"
              />
              <DropdownButtons width="128px" buttons={this.getDropdownButtons(title, id, clientName, status)} />
            </Row>
          </section>

          <section style={{ paddingBottom: '160px' }}>
            <Tabs activeKey={currentActiveTabKey} className="v2-tab-wrapper" onChange={this.updateActiveKey}>
              {sRTabs.map((tab, index) => {
                const { tabTitle, content, hasNotification } = tab;
                const extra = hasNotification ? <Badge status="error" /> : <> </>;
                return (
                  <TabPane
                    tab={
                      <span>
                        {tabTitle} {extra}
                      </span>
                    }
                    key={index}
                  >
                    {content}
                  </TabPane>
                );
              })}
            </Tabs>
          </section>
        </Col>

        {/* Secondary column on the right */}
        <Col span={8}>
          <Row style={{ marginBottom: '24px' }}>
            <TaskCard
              numWorkersHired={staffRequest.statuses_counts.active}
              numWorkersRequired={staffRequest.staff_required}
            />
          </Row>
          {staffRequestDetailGroups.map((detailGroup, index) => {
            const { title, contentList, inline } = detailGroup;
            return (
              <Row style={{ marginBottom: '24px', borderTop: '1px solid #00000017' }} key={index}>
                <DetailCard key={index} headerTitle={title} contentList={contentList} inline={inline} />
              </Row>
            );
          })}
        </Col>
      </Row>
    );
  }
}

export default StaffRequestDetailView;
