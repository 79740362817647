import styled from 'styled-components';
import { fontO11r } from '../../../styles/fonts';
import { colors } from '../../../styles/colors';

const Label = styled.label`
  ${fontO11r};
`;

const Error = styled.div`
  color: ${colors.orange};
`;

const FormGroup = styled.div`
  ${Label} {
    margin-bottom: 6px;
    display: block;
  }

  ${Error} {
    margin-top: 5px;
  }
`;

FormGroup.Label = Label;

FormGroup.Error = Error;

export default FormGroup;
