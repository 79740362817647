import { attendanceStatuses, timesheetEntryStatuses, derivedWorkingStatus } from '../../constants';

const isAssigned = attendance => {
  return attendance?.status === attendanceStatuses.ASSIGNED;
};

const isAbsent = attendance => {
  return attendance && attendance.absence_reason;
};
const isChangeRequest = attendance => {
  return (
    attendance && attendance.change_reason && !attendance.confirmed && attendance.status === attendanceStatuses.ASSIGNED
  );
};

const hasNonVoidTimesheetEntry = timesheetEntries => {
  return timesheetEntries?.length > 0 && !timesheetEntries?.find(ts => ts.status === timesheetEntryStatuses.VOID);
};

const isValidWorkingDay = contractStatus => {
  return ![derivedWorkingStatus.CONTRACT_NOT_SIGNED, derivedWorkingStatus.ON_BREAK].includes(contractStatus);
};

export default {
  isAssigned,
  isAbsent,
  isChangeRequest,
  hasNonVoidTimesheetEntry,
  isValidWorkingDay,
};
