import React from 'react';
import PropTypes from 'prop-types';
import { Loader, Dimmer } from 'semantic-ui-react';

class CustomLoader extends React.Component {
  static propTypes = {
    isActive: PropTypes.bool,
    size: PropTypes.string,
  };

  static defaultProps = {
    isActive: true,
    size: 'large',
  };

  render() {
    const { isActive, size, ...props } = this.props;

    return (
      <Dimmer inverted active={isActive} {...props}>
        <Loader size={size} />
      </Dimmer>
    );
  }
}

export default CustomLoader;
