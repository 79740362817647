import React from 'react';
import { Modal, Typography, Button, Form, Row, Col, Select, Divider } from 'antd';
import { withTranslation } from 'react-i18next';

import { employmentCancellationReasons } from '../../../../../src/constants';
import TextArea from 'antd/lib/input/TextArea';
import { colors } from '../../../../styles/colors';

const { Title, Paragraph } = Typography;
const { Item } = Form;
const { Option } = Select;

// eslint-disable-next-line react/prefer-stateless-function
class EndEmploymentModal extends React.Component {
  render() {
    const { t, confirmLoading, visible, closeModal, workerCount, onConfirm, hasDirectEmployment, form } = this.props;

    const cancellationReasons = hasDirectEmployment
      ? employmentCancellationReasons.filter(reason => reason.value !== 'staff_request_reassignment')
      : employmentCancellationReasons;

    return (
      <Modal
        className={'v2-end-employment-modal'}
        visible={visible}
        onCancel={() => {
          closeModal();
        }}
        title={<Title level={4}>{t('endEmploymentTitle')}</Title>}
        confirmLoading={confirmLoading}
        footer={[
          <Button key="close" onClick={closeModal}>
            {t('Close')}
          </Button>,
          <Button
            key="submit"
            loading={confirmLoading}
            onClick={() => {
              form.validateFieldsAndScroll((errors, values) => {
                if (!errors) {
                  onConfirm(values);
                }
              });
            }}
            style={{ background: colors.functionalError, color: colors.white }}
          >
            {t('endEmploymentTitle')}
          </Button>,
        ]}
      >
        <Form hideRequiredMark colon={false} className="v2-end-employment-form">
          <section key={0}>
            <Row type="flex" gutter={24}>
              <Col span={24}>
                <Typography>
                  <Paragraph>{t('endEmploymentMessage', { workerCount })}</Paragraph>
                  <Paragraph>{t('endEmploymentReason')}</Paragraph>
                </Typography>
              </Col>
              <Col span={24}>
                <Item label={t('endEmploymentReasonLabel')} style={{ marginBottom: '4px' }}>
                  {form.getFieldDecorator('reason', {
                    rules: [{ required: true, message: t('Field is required.') }],
                  })(
                    <Select
                      search
                      placeholder={t('endEmploymentReasonPlaceholder')}
                      style={{ width: '100%' }}
                      dropdownMatchSelectWidth={true}
                    >
                      {cancellationReasons.map(reason => (
                        <Option key={reason.value} value={reason.value}>
                          <Typography.Text>{t(`${reason.title}`)}</Typography.Text>
                          <br />
                          <Typography.Text style={{ whiteSpace: 'normal' }}>
                            {t(`${reason.description}`)}
                          </Typography.Text>
                        </Option>
                      ))}
                    </Select>,
                  )}
                </Item>
              </Col>
              <Col span={24}>
                <Item label={t('endEmploymentNotes')} style={{ marginBottom: '4px' }}>
                  {form.getFieldDecorator('notes')(
                    <TextArea placeholder={t('endEmploymentDetailsPlaceholder')}></TextArea>,
                  )}
                </Item>
              </Col>
            </Row>
          </section>
          <Divider />
          <section key={1} style={{ paddingTop: 8 }}>
            <Row type="flex" gutter={24}>
              <Col span={24}>
                <Typography.Text strong>{t('Disclaimer')}</Typography.Text>
                <br />
                <Typography.Paragraph>{t('endEmploymentDisclaimer')}</Typography.Paragraph>
              </Col>
            </Row>
          </section>
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(withTranslation()(EndEmploymentModal));
