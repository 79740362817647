import React, { Component } from 'react';
import { Modal, Typography, message } from 'antd';
import timesheetEntryApi from '../../../services/timesheetEntryApi';

const { Text } = Typography;

class ConfirmTimesheetEntryModal extends Component {
  state = { isLoading: false };

  handleConfirmTimesheetEntry = async () => {
    const timesheetEntryId = this.props.timesheet.id;
    this.setState({ isLoading: true });
    try {
      const response = await timesheetEntryApi.confirm(timesheetEntryId);
      message.success(`Successfully confirmed timesheet entry #${timesheetEntryId}`);
      this.props.onOk(response); // pass in updated timesheet back
    } catch (error) {
      const detail = error?.response?.data?.detail;
      message.error(detail ?? error.message);
    }
    this.setState({ isLoading: false });
  };

  render() {
    const { timesheet, visible, onCancel } = this.props;
    return (
      <Modal
        visible={visible}
        title="Confirm timesheet entry"
        okText="Yes, confirm"
        okType="v2-primary"
        onOk={this.handleConfirmTimesheetEntry}
        okButtonProps={{ loading: this.state.isLoading }}
        onCancel={onCancel}
      >
        <Text>
          You are about to confirm
          <Text strong> {timesheet && timesheet.partner && timesheet.partner.first_name} </Text>
          timesheet entry #{timesheet.id}. Are you sure?
        </Text>
      </Modal>
    );
  }
}

export default ConfirmTimesheetEntryModal;
