import NumberFormat from 'react-number-format';
import styled from 'styled-components';
import { colors } from '../../../styles/colors';
import { fontO14r } from '../../../styles/fonts';

export default styled(NumberFormat)`
  width: 100%;
  border: 1px solid ${colors.grey};
  border-radius: 4px;
  padding: 10px 15px;
  height: initial;
  ${fontO14r};

  &::placeholder {
    ${fontO14r};
    color: ${colors.grey};
  }
`;
