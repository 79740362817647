import React from 'react';
import styled from 'styled-components';
import { format, parse } from 'date-fns';
import * as numeral from 'numeral';
import { Placeholder } from 'semantic-ui-react';
import { colors } from '../../../../styles/colors';
import { fontO10b, fontO11b, fontO18r } from '../../../../styles/fonts';
import ClientAvatar from '../../../../shared/components/ClientAvatar';
import Address from '../../../../shared/components/Address';
import { DEFAULT_DATETIME_FORMAT } from '../../../../constants';
import Card from '../../../../shared/components/Card';
import { humanizeText } from '../../../../utilities/textUtils';

const Header = styled.h2`
  ${fontO10b};
  text-transform: uppercase;
  color: ${colors.darkGrey};
  margin-bottom: 15px;
`;

const Position = styled.div`
  ${fontO18r};
`;

const LocationName = styled.div`
  ${fontO18r};
  display: inline-block;
`;

const ItemLabel = styled.span`
  ${fontO11b};
`;

const ItemValue = styled.span``;

const Item = styled.div`
  ${ItemLabel} {
    margin-right: 5px;
  }
`;

const Section = styled.div``;

const Container = styled.div``;

const CustomCard = styled(Card)`
  ${Container} {
    margin: 0 -20px;
    display: flex;
  }

  ${Section} {
    width: ${(1 / 4) * 100}%;
    padding: 0 20px;
    border-right: 1px solid ${colors.lightGrey};

    &:last-child {
      border-right: none;
    }
  }
`;

export default ({ staffRequest, ...props }) => (
  <CustomCard {...props}>
    <Container>
      <Section>
        <Header>Client</Header>
        {staffRequest ? (
          <ClientAvatar size="large" client={staffRequest.client} />
        ) : (
          <Placeholder>
            <Placeholder.Header image>
              <Placeholder.Line />
            </Placeholder.Header>
          </Placeholder>
        )}
      </Section>
      <Section>
        <Header>Role</Header>
        {staffRequest ? (
          <>
            <Position>{staffRequest.position && staffRequest.position.name}</Position>
            <Item>
              <ItemValue>{staffRequest.title}</ItemValue>
            </Item>
            <Item>
              <ItemLabel>Wage</ItemLabel>
              <ItemValue>
                {numeral(staffRequest.wage_amount).format('0,0[.]00')}{' '}
                {staffRequest.wage_type && humanizeText(staffRequest.wage_type)}
              </ItemValue>
            </Item>
          </>
        ) : (
          <Placeholder>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder>
        )}
      </Section>
      <Section>
        <Header>Location</Header>
        {staffRequest && staffRequest.location ? (
          <>
            <LocationName>{staffRequest.location.name}</LocationName>
            <Address address={staffRequest.location.address} />
          </>
        ) : (
          <Placeholder>
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder>
        )}
      </Section>
      <Section>
        <Header>Log</Header>
        {staffRequest ? (
          <>
            <Item>
              <ItemLabel>Created</ItemLabel>
              <ItemValue>{format(parse(staffRequest.created_date), DEFAULT_DATETIME_FORMAT)}</ItemValue>
            </Item>
            <Item>
              <ItemLabel>Modified</ItemLabel>
              <ItemValue>{format(parse(staffRequest.modified_date), DEFAULT_DATETIME_FORMAT)}</ItemValue>
            </Item>
          </>
        ) : (
          <Placeholder>
            <Placeholder.Line />
            <Placeholder.Line />
          </Placeholder>
        )}
      </Section>
    </Container>
  </CustomCard>
);
