import React from 'react';
import ProfilePhoto from '../ProfilePhoto';

export default class ImagePreview extends React.Component {
  state = {
    loading: false,
    thumb: undefined,
  };

  componentWillReceiveProps(nextProps) {
    if (!nextProps.file) {
      return;
    }

    this.setState({ loading: true }, () => {
      const reader = new FileReader();

      reader.onloadend = () => {
        this.setState({ loading: false, thumb: reader.result });
      };

      reader.readAsDataURL(nextProps.file);
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state.thumb !== nextState.thumb;
  }

  render() {
    const { file } = this.props;
    const { loading, thumb } = this.state;

    if (!file) {
      return null;
    }

    if (loading) {
      return <p>loading...</p>;
    }

    return (
      <ProfilePhoto>
        <img src={thumb} alt={file.name} className="img-thumbnail mt-2" height={200} width={200} />
      </ProfilePhoto>
    );
  }
}
