import gql from 'graphql-tag';

// eslint-disable-next-line import/prefer-default-export
export const GET_TIMESHEETS = gql`
  query getTimesheetEntries(
    $first: Int
    $after: String
    $orderBy: [String]
    $status: [String]
    $partner: [String]
    $client: [String]
    $staffRequest: [String]
    $offset: Int
    $clockInTimeBefore: DateTime
    $clockInTimeAfter: DateTime
    $supervisorConfirmed: Boolean
    $country: String
    $paymentStatus: [String]
  ) {
    timesheetEntries(
      first: $first
      after: $after
      orderBy: $orderBy
      status: $status
      partner: $partner
      client: $client
      staffRequest: $staffRequest
      offset: $offset
      clockInTimeBefore: $clockInTimeBefore
      clockInTimeAfter: $clockInTimeAfter
      supervisorConfirmed: $supervisorConfirmed
      country: $country
      paymentStatus: $paymentStatus
    ) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          baseWage
          createdDate
          clockInTime
          clockOutTime
          clockInLatitude
          clockInLongitude
          clockOutLatitude
          clockOutLongitude
          status
          disputedReason {
            code
          }
          late
          supervisorConfirmed
          overtimeDuration
          partner {
            id
            image
            firstName
            lastName
          }
          location {
            name
            address {
              latitude
              longitude
            }
            client {
              breakTimeRequired
            }
          }
          clockInSelfie
          clockInSelfieStatus
          clockOutSelfie
          clockOutSelfieStatus
          clockInSource
          clockOutSource
          roundedClockInTime
          roundedClockOutTime
          clockInSupervisor {
            id
            name
            email
          }
          clockOutSupervisor {
            id
            name
            email
          }
          breaks {
            id
          }
          clientRoundingRules
          totalWorkDurationInMinutes
          unpaidBreak
          breakDurationInMinutes
          client {
            id
            name
            breakTimeRequired
            logo
          }
          employment {
            staffRequest {
              id
            }
          }
          paymentStatus
        }
      }
    }
  }
`;
