import { Card, Descriptions } from 'antd';
import React from 'react';
import numeral from 'numeral';
import { useTranslation } from 'react-i18next';
import ClientTypeBadge from '../../../../../shared/components/ClientTypeBadge';
import { humanizeText } from '../../../../../utilities/textUtils';
import {
  clientTypes,
  pricingUnitTypes,
  platformFeeAmountOptions,
  platformAmountOptions,
  platformManagedServiceAmountOptions,
} from '../../../../../constants';
import { toCamelCase } from '../../../../utilities/textUtils';

const invoiceRequirementTextOptions = {
  soft: 'Soft copy only',
  hard: 'Hard copy only',
  both: 'Both hard and soft copy',
};

const getCurrencyCode = client => {
  let currencyCode = client?.country.currency_code;
  if (client.pricing?.currency) currencyCode = client.pricing.currency;
  return currencyCode;
};

const formatCurrencyValue = value => numeral(value).format('0,0.00');

const renderPricingDetails = (clientType, currencyCode, pricing) => {
  switch (clientType) {
    case clientTypes.WMP:
      return (
        <>
          {pricing.tier && <Descriptions.Item label="Client tier">{`Tier ${pricing.tier}`}</Descriptions.Item>}
          {pricing.package && (
            <Descriptions.Item label="Client package">{`${pricing.package} month(s)`}</Descriptions.Item>
          )}
          <Descriptions.Item label={`${humanizeText(clientType)} fee`}>
            {`${currencyCode} ${formatCurrencyValue(pricing.unit_cost)} for ${pricing.amount_cost} ${
              platformFeeAmountOptions[pricing.amount_type]
            }`}
          </Descriptions.Item>
          <Descriptions.Item label={`${humanizeText(clientType)} fee`}>
            {`${currencyCode} ${formatCurrencyValue(pricing.unit_cost)} for ${pricing.amount_cost} ${
              platformFeeAmountOptions[pricing.amount_type]
            }`}
          </Descriptions.Item>
          {pricing.additional_unit_cost && (
            <Descriptions.Item label="Additional">
              {`${currencyCode} ${formatCurrencyValue(pricing.additional_unit_cost)} per ${
                platformAmountOptions[pricing.additional_amount_type]
              } per month`}
            </Descriptions.Item>
          )}
          {pricing.managed_service_unit_cost && (
            <Descriptions.Item label="Staffing">
              {platformManagedServiceAmountOptions[pricing.managed_service_type]}
              <br />
              {`${currencyCode} ${formatCurrencyValue(pricing.managed_service_unit_cost)} per ${
                platformAmountOptions[pricing.managed_service_amount_type]
              } per month`}
            </Descriptions.Item>
          )}
        </>
      );
    case clientTypes.MANAGED_SERVICE:
      return (
        <>
          <Descriptions.Item label={`${humanizeText(clientType)} fee`}>
            {pricing.unit_cost &&
              (pricing.unit_type === pricingUnitTypes.FLAT_RATE
                ? `${currencyCode} ${formatCurrencyValue(pricing.unit_cost)} per worker per month`
                : `${parseInt(pricing.unit_cost, 10)}% per worker per month`)}
          </Descriptions.Item>
          <Descriptions.Item label="Platform fee">
            {pricing.platform_fee_unit_cost &&
              (pricing.platform_fee_unit_type === pricingUnitTypes.FLAT_RATE
                ? `${currencyCode} ${formatCurrencyValue(pricing.platform_fee_unit_cost)} per worker per month`
                : `${parseInt(pricing.platform_fee_unit_cost, 10)}% per worker per month`)}
          </Descriptions.Item>
        </>
      );
    case clientTypes.BPO:
      return (
        <>
          <Descriptions.Item label={`${humanizeText(clientType)} fee`}>
            {pricing.custom_pricing_fee ? pricing.custom_pricing_fee : '-'}
          </Descriptions.Item>
          <Descriptions.Item label="Platform fee">
            {pricing.platform_fee_unit_cost &&
              (pricing.platform_fee_unit_type === pricingUnitTypes.FLAT_RATE
                ? `${currencyCode} ${formatCurrencyValue(pricing.platform_fee_unit_cost)} per worker per month`
                : `${parseInt(pricing.platform_fee_unit_cost, 10)}% per worker per month`)}
          </Descriptions.Item>
        </>
      );
    default:
      return <Descriptions.Item label={`${humanizeText(clientType)} fee`}>-</Descriptions.Item>;
  }
};

export default ({ client }) => {
  const currencyCode = getCurrencyCode(client);
  const { t } = useTranslation();

  return (
    <Card title="Service Information" size="small">
      {client && (
        <Descriptions colon={false} column={1} layout="vertical">
          <Descriptions.Item label="Product Type">
            <ClientTypeBadge type={client.type} text={t(toCamelCase(client.type))} />
          </Descriptions.Item>
          <Descriptions.Item label="Service Level">
            <ClientTypeBadge type={client.service_level} text={humanizeText(client.service_level)} />
          </Descriptions.Item>
          {client.pricing && renderPricingDetails(client.type, currencyCode, client.pricing)}
          <Descriptions.Item label="Payroll fee">
            {client.payroll_fee
              ? `${currencyCode} ${formatCurrencyValue(client.payroll_fee)} per worker per month`
              : '-'}
          </Descriptions.Item>
          <Descriptions.Item label="Revenue sharing">
            {client.revenue_sharing ? `Partner - ${client.revenue_sharing_partner}` : '-'}
          </Descriptions.Item>
          <Descriptions.Item label="Billing type">
            {client.billing_schedule ? humanizeText(client.billing_schedule) : '-'}
          </Descriptions.Item>
          <Descriptions.Item label="Payment type">
            {client.payment_method ? humanizeText(client.payment_method) : '-'}
          </Descriptions.Item>
          <Descriptions.Item label="Payment terms">
            {client.payment_term ? humanizeText(client.payment_term) : '-'}
          </Descriptions.Item>
          <Descriptions.Item label="Other securities">
            {client.other_security ? humanizeText(client.other_security) : '-'}
          </Descriptions.Item>
          <Descriptions.Item label="Invoice requirement">
            {client.invoice_requirement ? invoiceRequirementTextOptions[client.invoice_requirement] : '-'}
          </Descriptions.Item>
          <Descriptions.Item label="Service comments">
            {client.service_comments ? client.service_comments : '-'}
          </Descriptions.Item>
        </Descriptions>
      )}
    </Card>
  );
};
