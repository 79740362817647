import moment from 'moment-timezone';

const getDifferenceInHours = (startString, endString) => {
  const start = moment(startString);
  const end = moment(endString);
  return end.diff(start, 'hours');
};

const getFriendlyClockInOutString = (
  clockInTime,
  clockOutTime,
  roundedClockInTime = null,
  roundedClockOutTime = null,
) => {
  const inTime = roundedClockInTime || clockInTime;
  const outTime = roundedClockOutTime || clockOutTime;

  // Assume clockInTime will always be here
  if (!inTime) {
    return '-';
  }

  const clockInFormatted = moment(inTime).format('DD MMM YYYY, hh:mm A');
  if (!outTime) {
    return { start: clockInFormatted };
  }

  const clockOutFormatted = moment(outTime).format('DD MMM YYYY, hh:mm A');
  const differenceInHours = getDifferenceInHours(inTime, outTime);
  return {
    start: clockInFormatted,
    end: clockOutFormatted,
    differenceInHours,
  };
};

export default {
  getFriendlyClockInOutString,
};
