import React, { Component } from 'react';
import numeral from 'numeral';
import moment from 'moment';
import { Row, Col, Typography, Divider, Icon, Skeleton, Tooltip } from 'antd';
import billingApi from '../../../services/billingApi';
import BillingItemsContainer from './containers/BillingItemsContainer';
import StatusTag from '../../shared/components/StatusTag';
import textUtils from '../../utilities/textUtils';
import { DATE_FORMAT, billingReportStatuses } from '../../../constants';

const { Title, Text, Paragraph } = Typography;

class BillingReportDetailView extends Component {
  state = { billingReport: undefined };

  componentDidMount() {
    this.fetchBillingReport();
  }

  fetchBillingReport = async () => {
    const response = await billingApi.fetchBillingReport(this.props.match.params.id);
    this.setState({ billingReport: response });
  };

  render() {
    const { billingReport } = this.state;
    if (!billingReport) return <Skeleton />;

    return (
      <>
        <Row type="flex" justify="space-between">
          <Col>
            <Title level={3}>Billing report: {this.props.match.params.id}</Title>
          </Col>
        </Row>

        <Paragraph style={{ marginBottom: '4px' }}>
          <Icon type="shop" style={{ color: '#BFBFBF', marginRight: '8px' }} />
          {billingReport.client.name} &bull; #{billingReport.client.id}
        </Paragraph>
        <div style={{ marginBottom: '20px' }}>
          {billingReport.invoices.map(invoice => {
            return (
              <Paragraph style={{ marginBottom: '4px' }}>
                <Icon type="file" style={{ color: '#BFBFBF', marginRight: '8px' }} />
                {textUtils.makeFriendly(invoice.name)} Invoice: {invoice.number} &bull;
                <a style={{ marginLeft: '4px' }} target="_blank" rel="noreferrer noopener" href={invoice.url}>
                  View {invoice.service} invoice
                </a>
              </Paragraph>
            );
          })}
        </div>
        <Paragraph type="secondary" style={{ marginBottom: '40px' }}>
          <StatusTag status={billingReport.status} />
          Requested on {moment(billingReport.billing_date).format(DATE_FORMAT)}
          {billingReport.status === 'billed' ? (
            <>&bull; Paid on {moment(billingReport.paid_date).format(DATE_FORMAT)}</>
          ) : (
            ''
          )}
        </Paragraph>

        <Row style={{ marginBottom: '50px' }}>
          <BillingItemsContainer billingReportId={this.props.match.params.id} />
        </Row>

        <Row type="flex" justify="end" style={{ marginBottom: '100px' }}>
          <div style={{ display: 'grid', gridGap: '10px 100px', gridTemplateColumns: 'auto auto' }}>
            <Text strong>Subtotal</Text>
            <Text style={{ textAlign: 'right' }}>{numeral(billingReport.wage_fee).format('0,0.00')}</Text>
            <Text strong>Service fee ({billingReport.service_rate}%)</Text>
            <Text style={{ textAlign: 'right' }}>{numeral(billingReport.service_fee).format('0,0.00')}</Text>
            <Text strong>
              VAT ({billingReport.tax_rate}%)
              {billingReport.client.country.code === 'ID' && (
                <Tooltip title="Indonesia: VAT only applies to service fees">
                  <Icon
                    type="info-circle"
                    style={{
                      fontSize: '18px',
                      verticalAlign: 'middle',
                      cursor: 'auto',
                      color: '#108EE9',
                      marginLeft: '5px',
                    }}
                    theme="filled"
                  />
                </Tooltip>
              )}
            </Text>
            <Text style={{ textAlign: 'right' }}>
              {numeral(parseFloat(billingReport.wage_tax_fee) + parseFloat(billingReport.service_tax_fee)).format(
                '0,0.00',
              )}
            </Text>
            <Divider style={{ gridColumnStart: 1, gridColumnEnd: 3, marginTop: '0px', marginBottom: '0px' }} />
            <Text strong>{billingReport.status === billingReportStatuses.BILLED ? 'Amount paid' : 'Amount due'}</Text>
            <Text strong style={{ textAlign: 'right' }}>
              {numeral(billingReport.billing_amount).format('0,0.00')}
            </Text>
          </div>
        </Row>
      </>
    );
  }
}

export default BillingReportDetailView;
