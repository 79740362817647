import { Button, Modal } from 'antd';
import React from 'react';
import { clientStatuses } from '../../../../constants';
import clientApi from '../../../../services/clientApi';
import Access from '../../../../shared/access/Access';
import { permissions } from '../../../../shared/access/accessConfig';

export default class ClientActions extends React.Component {
  handleApprove = () => {
    const { client, onUpdate } = this.props;

    Modal.confirm({
      title: 'Approve client',
      content: 'You should only approve this client after they have been reviewed by finance.',
      onOk: () => clientApi.approveClient(client).then(onUpdate),
    });
  };

  render() {
    const { client } = this.props;

    return (
      <div>
        {!client.verified && client.status !== clientStatuses.DEACTIVATED && (
          <Access
            action={permissions.approveClient}
            yes={() => (
              <Button icon="check" type="primary" onClick={this.handleApprove}>
                Approve
              </Button>
            )}
          />
        )}
      </div>
    );
  }
}
