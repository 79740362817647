import React from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import styled from 'styled-components';
import { fieldCss } from './Field';

const CustomPhoneInput = styled(PhoneInput)`
  .react-phone-number-input__input {
    ${fieldCss};
  }
  .react-phone-number-input__icon-image {
    display: block;
  }
`;

export default ({ ...props }) => <CustomPhoneInput {...props} />;
