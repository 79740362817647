import React from 'react';
import GoogleMapReact from 'google-map-react';
import { GOOGLE_MAPS_API_KEY } from '../../../../config';
import MatchedPartnerMarker from './PartnerMarker';
import MatchedPartnerSRMarker from './StaffRequestMarker';

export default ({ staffRequest, partners, defaultValue, currentPartner, onSelect }) => {
  return (
    <GoogleMapReact
      bootstrapURLKeys={{
        key: GOOGLE_MAPS_API_KEY,
      }}
      center={{
        lat: (staffRequest && Number(staffRequest.location.address.latitude)) || defaultValue.center.lat,
        lng: (staffRequest && Number(staffRequest.location.address.longitude)) || defaultValue.center.lng,
      }}
      zoom={defaultValue.zoom}
    >
      <MatchedPartnerSRMarker
        title={staffRequest ? staffRequest.client.name : ''}
        content={staffRequest ? staffRequest.title : ''}
        lat={(staffRequest && staffRequest.location.address.latitude) || defaultValue.center.lat}
        lng={(staffRequest && staffRequest.location.address.longitude) || defaultValue.center.lng}
      />

      {partners.map(partner => (
        <MatchedPartnerMarker
          key={partner.id}
          partner={partner}
          onSelect={onSelect}
          highlightItem={currentPartner === partner.id}
          title={`${partner.first_name} ${partner.last_name}`}
          content={partner.user.username}
          lat={partner.address.latitude}
          lng={partner.address.longitude}
        />
      ))}
    </GoogleMapReact>
  );
};
