import React from 'react';
import { Alert, Button, Typography } from 'antd';

// default verified to true for cases where status tag is used outside of Client Status
const AlertWithAction = ({ message, description, action = false, type, showIcon, icon, onClick }) => {
  return (
    <div>
      <Alert
        style={{ paddingTop: 6, paddingBottom: 6, paddingLeft: 40 }}
        showIcon={showIcon}
        icon={<div style={{ fontSize: '14px', left: ' 16px', top: '8px' }}>{icon}</div>}
        message={
          <Typography.Text strong style={{ fontSize: '14px', lineHeight: '22px' }}>
            {message}
          </Typography.Text>
        }
        description={<Typography.Text style={{ fontSize: '14px', lineHeight: '22px' }}>{description}</Typography.Text>}
        type={type}
      />
      {action && (
        <Button onClick={onClick} size="default" style={{ position: 'absolute', top: '16px', right: '16px' }}>
          {action}
        </Button>
      )}
    </div>
  );
};

export default AlertWithAction;
