import React from 'react';
import { Modal } from 'semantic-ui-react';
import { ErrorMessage, Form, Formik } from 'formik';
import styled from 'styled-components';
import moment from 'moment';
import * as Yup from 'yup';
import dateFns, { addDays } from 'date-fns';
import Button from '../../../../shared/components/Button';
import FormGroup from '../../../../shared/components/forms/FormGroup';
import DatePicker from '../../../../shared/components/forms/DatePicker';
import timesheetEntryApi from '../../../../services/timesheetEntryApi';
import { DEFAULT_DATETIME_FORMAT } from '../../../../constants';

const Message = styled.div``;

const Details = styled.div`
  display: grid;
  grid-row-gap: 15px;
`;

const Actions = styled.div`
  display: grid;
  grid-column-gap: 15px;
  grid-auto-flow: column;
`;

const Container = styled.div`
  display: grid;
  grid-gap: 30px;
  padding: 1.25rem 1.5rem;
`;

const CustomDatePicker = styled(DatePicker)``;

export default class ForcedClockOutModal extends React.Component {
  render() {
    const { entry, onClose, onUpdate, ...props } = this.props;

    return (
      <Modal onClose={onClose} {...props} size="tiny">
        <Modal.Header>Clock Out</Modal.Header>
        <Modal.Content scrolling>
          <Formik
            initialValues={{
              clock_out_time: moment().toDate(),
            }}
            validationSchema={Yup.object().shape({
              clock_out_time: Yup.date()
                .min(entry.clock_in_time, 'Must be after clock in time')
                .required('This field is required')
                .typeError('This field is required'),
            })}
            onSubmit={(values, actions) => {
              actions.setSubmitting(true);
              timesheetEntryApi
                .update(entry.id, {
                  clock_out_time: values.clock_out_time,
                })
                .then(data => {
                  actions.setSubmitting(false);
                  onUpdate();
                  onClose();
                });
            }}
            render={({ values, isSubmitting, setFieldValue }) => (
              <Form>
                <Container>
                  <Message>
                    You are about to manually clock out for{' '}
                    <strong>
                      {entry.partner.first_name} {entry.partner.last_name}
                    </strong>
                  </Message>
                  <Details>
                    <FormGroup>
                      <FormGroup.Label>Clock In Time</FormGroup.Label>
                      {dateFns.format(entry.clock_in_time, DEFAULT_DATETIME_FORMAT)}
                    </FormGroup>
                    <FormGroup>
                      <FormGroup.Label>Clock Out Time</FormGroup.Label>
                      <CustomDatePicker
                        fixedHeight
                        showTimeSelect
                        placeholderText="Select Time"
                        selected={values.clock_out_time}
                        minDate={values.clock_out_time}
                        maxDate={addDays(values.clock_out_time, 1)}
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        dateFormat="dd/MM/yyyy h:mm aa"
                        onChange={newValue => {
                          setFieldValue('clock_out_time', newValue);
                        }}
                      />
                      <ErrorMessage name="clock_out_time" component={FormGroup.Error} />
                    </FormGroup>
                  </Details>
                  <Actions>
                    <Button variation="neutral" onClick={onClose}>
                      Cancel
                    </Button>
                    <Button loading={isSubmitting} type="submit" variation="danger">
                      Confirm
                    </Button>
                  </Actions>
                </Container>
              </Form>
            )}
          />
        </Modal.Content>
      </Modal>
    );
  }
}
