import React from 'react';
import { Icon } from 'semantic-ui-react';
import Button from './Button';

export default props => (
  <Button {...props}>
    <Icon name="download" />
    {props.children}
  </Button>
);
