import styled from 'styled-components';
import { Icon } from 'antd';

export default styled(Icon)`
  font-size: 25px;
  cursor: pointer;
  transition: all 300ms ease-out;
  margin: 2px;

  &:hover {
    opacity: 0.5;
  }
}
`;
