import React, { useMemo, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import L from 'leaflet';

import blueMarker from '../../../assets/images/blue-marker-40.png';
import redMarker from '../../../assets/images/red-marker-40.png';

const getIcon = type => {
  // Anchor follow the mark https://stackoverflow.com/questions/17875438/leafletjs-markers-move-on-zoom
  switch (type) {
    case 'red':
      return new L.Icon({
        iconUrl: redMarker,
        iconSize: [40, 41],
        iconAnchor: [20, 41],
        popupAnchor: [0, -30],
      });
    default:
      return new L.Icon({
        iconUrl: blueMarker,
        iconSize: [40, 41],
        iconAnchor: [20, 41],
        popupAnchor: [0, -30],
      });
  }
};

function ChangeView({ center, zoom }) {
  // Refer to https://stackoverflow.com/a/64667351
  const map = useMap();
  map.setView(center, zoom);
  return null;
}

const Maps = ({ centerPosition, markerPositions, style, zoom, scrollWheelZoom, onDrag }) => {
  const bounds = L.latLngBounds(
    markerPositions.map(markerPosition => [
      markerPosition?.lat || centerPosition?.lat,
      markerPosition?.lng || centerPosition?.lat,
    ]),
  );
  const center = [centerPosition?.lat, centerPosition?.lng];
  const markerRef = useRef(null);

  const handleEvents = useMemo(
    // Currently only support single point draggable
    () => ({
      dragend() {
        const marker = markerRef.current;
        if (marker != null) {
          onDrag(marker.getLatLng());
        }
      },
    }),
    // eslint-disable-next-line
    [],
  );

  return (
    <MapContainer
      style={{ width: '100%', height: '100%', ...style }}
      center={center}
      scrollWheelZoom={scrollWheelZoom}
      zoom={zoom}
      bounds={bounds}
    >
      <ChangeView center={center} zoom={zoom} />
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {markerPositions.map((markerPosition, index) => (
        <Marker
          key={index}
          position={[markerPosition.lat, markerPosition.lng]}
          icon={getIcon(markerPosition.icon)}
          draggable={markerPosition.draggable}
          ref={markerRef}
          eventHandlers={handleEvents}
        >
          {markerPosition.popup && <Popup>{markerPosition.popup}</Popup>}
        </Marker>
      ))}
    </MapContainer>
  );
};

export default Maps;
