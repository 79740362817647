import React from 'react';
import PartnerForm from './PartnerForm';
import partnerApi from '../../../services/partnerApi';

class EditPartner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: true,
      partner: null,
    };
  }

  componentDidMount() {
    this.fetchPartner();
  }

  fetchPartner = async () => {
    const { id } = this.props.match.params;
    try {
      const partner = await partnerApi.fetchPartnerWithSensitiveInfo(id);
      this.setState({ partner });
    } catch (error) {
      console.log('> Error:', error);
    }
  };

  render() {
    const { history } = this.props;
    return <PartnerForm edit={this.state.edit} partner={this.state.partner} history={history} />;
  }
}

export default EditPartner;
