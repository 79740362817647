import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Table, Typography } from 'antd';
import { withTranslation } from 'react-i18next';

import AssignmentTableCell from './AssignmentTableCell';
import routes from '../../../../routes';
import { colors } from '../../../../styles/colors';
import textUtils from '../../../utilities/textUtils';

const { Text } = Typography;

class AssignmentTable extends Component {
  handleSelectChange = selectedRowKeys => {
    this.props.onSelect(selectedRowKeys);
  };

  handleAttendanceUpdate = update => {
    this.props.onUpdate(update);
  };

  render() {
    const { loading, weekdayDates, dataSource, selectedRowKeys, filteredPartnerIds, timezone } = this.props;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.handleSelectChange,
    };

    const tableColumns = [
      {
        title: <Text strong>Worker</Text>,
        dataIndex: 'worker',
        width: 170,
        filteredValue: filteredPartnerIds,
        onFilter: (value, record) => record.id.toString() === value.toString(),
        render: worker => (
          <Link to={routes.partnerDetail.replace(':id', worker.id)} target="_blank">
            <Text strong>{textUtils.toTitleCase(`${worker.first_name} ${worker.last_name}`)}</Text>
          </Link>
        ),
      },
      {
        title: <Text strong>Days</Text>,
        width: 30,
        dataIndex: 'assignedCount',
        render: assignedCount => (
          <div style={{ width: 115, whiteSpace: 'normal' }}>
            <Text style={{ lineHeight: 1 }}>{assignedCount}</Text>
          </div>
        ),
      },

      // Each day is a column of attendances that belong to the shift of that day
      ...weekdayDates.map((date, index) => {
        return {
          title: (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text style={{ color: date.isSame(moment(), 'day') && colors.functionalSuccess }}>
                {date.format('ddd')}
              </Text>
              <Text style={{ color: date.isSame(moment(), 'day') && colors.functionalSuccess }} strong>
                {date.format('DD MMM')}
              </Text>
            </div>
          ),
          width: 100,
          render: (attendance, record) => ({
            props: { style: { padding: 0 }, width: '100' },
            children: (
              <AssignmentTableCell
                employmentId={record.employmentId}
                worker={record.worker}
                assignment={record.assignments[index]}
                onUpdate={this.handleAttendanceUpdate}
                timezone={timezone}
              />
            ),
          }),
        };
      }),
    ];

    return (
      <Table
        rowSelection={rowSelection}
        pagination={false}
        loading={loading}
        columns={tableColumns}
        dataSource={dataSource}
        rowKey="id"
        size="middle"
      />
    );
  }
}

export default withTranslation()(AssignmentTable);
