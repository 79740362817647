import React from 'react';
import { withRouter } from 'react-router';
import { Row, Col, Icon, Typography, Button } from 'antd';
import ErrorLogo from '../../../assets/images/brand_error.png';
import routes from '../../../routes';

const { Title, Paragraph, Text } = Typography;
const ErrorPage = ({ onClick, title, subTitle, errorStack, history }) => {
  const linesOfError = errorStack?.split('\n').slice(1);
  return (
    <section
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <Row>
        <img src={ErrorLogo} alt="Error" style={{ width: '600px' }} />
      </Row>
      <Row type="flex" justify="center">
        <Paragraph style={{ width: '100%', textAlign: 'center' }}>
          <Title level={4}>{title}</Title>
        </Paragraph>
        <Paragraph style={{ width: '100%', textAlign: 'center' }}>{subTitle}</Paragraph>
      </Row>
      <Row type="flex" justify="space-around" gutter={16}>
        <Col>
          <Button
            type="v2-secondary"
            onClick={() => {
              onClick();
              history.goBack();
            }}
            style={{ marginBottom: '24px' }}
          >
            Go back
          </Button>
        </Col>
        <Col>
          <Button
            type="v2-primary"
            onClick={() => {
              onClick();
              history.push(routes.home);
            }}
            style={{ marginBottom: '24px' }}
          >
            Back to home
          </Button>
        </Col>
      </Row>
      <section
        style={{
          textAlign: 'left',
          height: '400px',
          width: '800px',
          overflow: 'scroll',
          backgroundColor: '#f5f5f5',
          padding: '16px',
        }}
      >
        <Paragraph>
          <Text strong style={{ fontSize: '16px' }}>
            The page has the following error:
          </Text>
        </Paragraph>
        {linesOfError &&
          linesOfError.map((errorText, index) => (
            <Paragraph key={index}>
              <Icon style={{ color: 'red', marginRight: '8px' }} type="close-circle" /> {errorText}
            </Paragraph>
          ))}
      </section>
    </section>
  );
};

export default withRouter(ErrorPage);
