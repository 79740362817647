import React from 'react';
import { Modal } from 'semantic-ui-react';
import { Formik, Form, ErrorMessage } from 'formik';
import styled from 'styled-components';
import moment from 'moment';
import * as Yup from 'yup';
import Field from '../../../../shared/components/forms/Field';
import Definitions from '../../../../shared/components/Definitions';
import Button from '../../../../shared/components/Button';
import FormGroup from '../../../../shared/components/forms/FormGroup';
import timesheetEntryApi from '../../../../services/timesheetEntryApi';

const Message = styled.div``;

const Details = styled.div``;

const NotesField = styled(Field)`
  width: 100%;
`;

const Actions = styled.div`
  display: grid;
  grid-column-gap: 15px;
  grid-auto-flow: column;
`;

const Container = styled.div`
  display: grid;
  grid-gap: 30px;
  padding: 1.25rem 1.5rem;
`;

export default class DisputeTimesheetEntryModal extends React.Component {
  render() {
    const { entry, onClose, onUpdate, ...props } = this.props;

    return (
      <Modal onClose={onClose} {...props}>
        <Modal.Header>Dispute Timesheet Entry</Modal.Header>
        <Formik
          initialValues={{
            notes: '',
          }}
          validationSchema={Yup.object().shape({
            notes: Yup.string().required('This field is required'),
          })}
          onSubmit={(values, actions) => {
            actions.setSubmitting(true);
            timesheetEntryApi.dispute(entry.id, values.notes).then(data => {
              actions.setSubmitting(false);
              onUpdate();
              onClose();
            });
          }}
          render={({ isSubmitting }) => (
            <Form>
              <Container>
                <Message>
                  You are about to dispute a timesheet entry on for{' '}
                  <strong>
                    {entry.partner.first_name} {entry.partner.last_name}
                  </strong>
                </Message>
                <Details>
                  <Definitions borderless>
                    <Definitions.Item>
                      <Definitions.Label>Clock In Time</Definitions.Label>
                      <Definitions.Value>{moment(entry.clock_in_time).format('YYYY-MM-DD hh:mm A')}</Definitions.Value>
                    </Definitions.Item>
                    <Definitions.Item>
                      <Definitions.Label>Clock Out Time</Definitions.Label>
                      <Definitions.Value>{moment(entry.clock_out_time).format('YYYY-MM-DD hh:mm A')}</Definitions.Value>
                    </Definitions.Item>
                  </Definitions>
                </Details>
                <FormGroup>
                  <NotesField
                    name="notes"
                    component="textarea"
                    placeholder="Add more details about this dispute..."
                    rows={5}
                  />
                  <FormGroup.Error>
                    <ErrorMessage name="notes" />
                  </FormGroup.Error>
                </FormGroup>
                <Actions>
                  <Button type="submit" variation="danger" loading={isSubmitting}>
                    Dispute
                  </Button>
                  <Button variation="neutral" onClick={onClose}>
                    Cancel
                  </Button>
                </Actions>
              </Container>
            </Form>
          )}
        />
      </Modal>
    );
  }
}
