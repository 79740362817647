import moment from 'moment-timezone';
import { DATE_FORMAT } from '../../constants';

// Transforms form data to a payload to send for API. Shape of payload below:
// https://pegasusapi.helpster.asia/v1/docs/#operation/Create%20a%20Staff%20Request
const formDataToPayload = (values, timezone) => {
  const payload = {};
  payload.client = values.client;
  payload.title = values.title;
  payload.responsibilities = values.responsibilities;
  payload.requirements = values.requirements;
  payload.benefits = values.benefits;
  payload.wage_amount = values.wage_amount;
  payload.transport_allowance = values.transport_allowance;
  payload.meal_allowance = values.meal_allowance;
  payload.other_allowance = values.other_allowance;
  payload.wage_type = values.wage_type;
  payload.payment_frequency = values.payment_frequency;
  payload.staff_required = values.staff_required;
  payload.gender = values.gender === 'any' || values.gender === null ? null : values.gender;
  payload.min_age = values.min_age === true ? 21 : null;
  payload.require_experience = values.require_experience;
  payload.require_english = values.require_english;
  payload.location = values.location;
  payload.position = values.position;
  payload.skip_screening_test_rejection = values.skip_screening_test_rejection;
  payload.start_time = values.start_time
    .tz(timezone)
    .startOf('d')
    .toISOString();
  if (values.end_time) {
    payload.end_time = values.end_time
      .tz(timezone)
      .endOf('d')
      .toISOString();
  }
  payload.auto_hire = values.auto_hire;

  return payload;
};

const getSRSummaryData = ({ id, status, position, title, description, client, created_date }) => {
  return {
    id,
    clientName: client.name,
    title,
    position: position.name.toUpperCase(),
    description,
    status,
    footNoteText: `Created on ${moment(created_date).format(DATE_FORMAT)}`,
  };
};

// Used primarily to transform "order_by" in URL to a friendly label
const getFriendlyNameFromOrdering = ordering => {
  if (ordering === '-end_time') {
    return 'End date';
  }
  if (ordering === '-start_time') {
    return 'Start date';
  }
  return 'Most recent';
};

// Given a list of staff requests, only return cleaned data
const cleanSRListData = staffRequests => {
  return staffRequests.map(staffRequest => {
    return getSRSummaryData(staffRequest);
  });
};

// Returns the period of the SR & the number of schedules there are
const durationToString = (startTime, endTime, numSchedules, includeShifts = true) => {
  const friendlyStartDate = moment(startTime).format(DATE_FORMAT);
  const friendlyEndDate = moment(endTime).format(DATE_FORMAT);
  if (includeShifts === false || !numSchedules) {
    return `${friendlyStartDate} - ${friendlyEndDate}`;
  }
  return `${friendlyStartDate} - ${friendlyEndDate} · ${numSchedules} ${numSchedules === 1 ? 'shift' : 'shifts'}`;
};

const getEffectiveWageAmount = staffRequest => {
  const baseWage = staffRequest.wage_amount ? parseFloat(staffRequest.wage_amount) : 0;
  const transportAllowance = staffRequest.transport_allowance ? parseFloat(staffRequest.transport_allowance) : 0;
  const mealAllowance = staffRequest.meal_allowance ? parseFloat(staffRequest.meal_allowance) : 0;
  const otherAllowance = staffRequest.other_allowance ? parseFloat(staffRequest.other_allowance) : 0;

  return baseWage + transportAllowance + mealAllowance + otherAllowance;
};

export default {
  getSRSummaryData,
  getFriendlyNameFromOrdering,
  cleanSRListData,
  formDataToPayload,
  durationToString,
  getEffectiveWageAmount,
};
