import React from 'react';
import { message, Typography, Modal } from 'antd';

import paymentApi from '../../../../services/paymentApi';

const { Title, Text } = Typography;

class MarkOnHoldModal extends React.Component {
  state = {
    isLoading: false,
  };

  handleSubmit = async () => {
    this.setState({ isLoading: true });
    const keys = this.props.items.map(({ id }) => id).join(',');
    await paymentApi.bulkMarkOnHold({ id: keys });
    message.success(`Successfully mark ${this.props.items.length} reports as on hold`);
    this.setState({ isLoading: false });
    this.props.onChange();
  };

  render() {
    const { onCancel, visible, items } = this.props;
    if (!visible) return <> </>;

    const { isLoading } = this.state;

    return (
      <Modal
        title={<Title level={4}>Mark reports as on hold</Title>}
        centered
        visible={visible}
        onCancel={onCancel}
        onOk={this.handleSubmit}
        width={480}
        okType="danger"
        okText="Yes"
        okButtonProps={{ loading: isLoading }}
      >
        <Text>You are about to mark {items.length} reports as on hold. Are you sure?</Text>
      </Modal>
    );
  }
}

export default MarkOnHoldModal;
