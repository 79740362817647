import React from 'react';
import { timesheetEntryStatuses } from '../../../constants';
import TreeCheckboxFilter from './TreeCheckboxFilter';

const timesheetStatusOptions = [
  { key: timesheetEntryStatuses.CLOCKED_IN, title: 'Clocked In' },
  { key: timesheetEntryStatuses.CONFIRMED, title: 'Confirmed Clocked Out' },
  { key: timesheetEntryStatuses.UNCONFIRMED, title: 'Uncofirmed Clocked Out' },
  { key: timesheetEntryStatuses.DISPUTED, title: 'Disputed' },
  { key: timesheetEntryStatuses.APPROVED, title: 'Approved' },
  { key: timesheetEntryStatuses.VOID, title: 'Void' },
];

export default class TimesheetStatusFilterButton extends React.Component {
  state = {
    selectedOptions: [],
  };

  componentDidMount() {
    if (this.props.initialOptions) this.setState({ selectedOptions: this.props.initialOptions });
  }

  handleApply = checkedKeys => {
    this.props.onApply(checkedKeys);
  };

  render() {
    const { selectedOptions } = this.state;

    return (
      <TreeCheckboxFilter
        treeData={timesheetStatusOptions}
        checkedKeys={selectedOptions}
        label="Timesheet status"
        showActionButtons
        onChange={this.handleChange}
        onApply={this.handleApply}
      />
    );
  }
}
