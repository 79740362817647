import { Button, Col, DatePicker, Divider, message, Row } from 'antd';
import moment from 'moment-timezone';
import React from 'react';
import { uniq } from 'lodash';
import { timesheetEntryStatuses } from '../../../../constants';
import AddTimesheetEntryModal from '../../../../pages/TimesheetsPage/timesheets/modals/AddTimesheetEntryModal';
import timesheetEntryApi from '../../../../services/timesheetEntryApi';

import CardWithCount from '../../../shared/components/CardWithCount';
import ConfirmModals from '../../../shared/components/ConfirmModals';
import FilterWithSearch from '../../../shared/components/FilterWithSearch';
import TimesheetStatusFilterButton from '../../../shared/components/TimesheetStatusFilterButton';
import TimesheetDownloadButton from '../../../shared/components/TimesheetDownloadButton';
import TimesheetList from '../components/TimesheetList';
import { checkAccess } from '../../../../shared/access/Access';
import { permissions } from '../../../../shared/access/accessConfig';

const { RangePicker } = DatePicker;

class TimesheetContainer extends React.Component {
  state = {
    isLoading: false,
    timesheets: null,
    status_counts: undefined,
    status: [],
    partner: [],
    page: 1,
    allWorkers: [],
    clock_in_time_after: undefined,
    clock_in_time_before: undefined,
    showAddTimesheetEntryModal: false,
    selectedTimesheetEntries: [],
  };

  async componentDidMount() {
    await this.handleLoadTimesheetEntries();
  }

  handleLoadTimesheetEntries = async () => {
    await this.fetchTimesheetWithParams({ include_workers: true });
    await this.fetchStatus();
  };

  fetchStatus = async () => {
    const response = await timesheetEntryApi.list({
      staff_request: this.props.staffRequestId,
    });
    if (response.status_counts) {
      this.setState({
        status_counts: response.status_counts,
      });
    }
  };

  fetchTimesheetWithParams = async (params = undefined) => {
    this.setState({ isLoading: true, selectedTimesheetEntries: [] });
    let queryParams = this.getQueryParams();
    if (params) {
      queryParams = { ...params, ...queryParams };
    }
    const response = await timesheetEntryApi.list({
      ...queryParams,
    });
    this.setState({
      isLoading: false,
      numRecords: response.count,
      timesheets: response.results,
    });
    if (response.all_workers) {
      this.setState({
        allWorkers: response.all_workers.map(worker => {
          return { name: `#${worker.id} ${worker.first_name}`, id: worker.id };
        }),
      });
    }

    return response;
  };

  getQueryParams = () => {
    const { partner, status, page, clock_in_time_after, clock_in_time_before } = this.state;

    // convert all confirmed/unconfirmed statuses to "clocked_out"
    const newStatus = uniq(
      status.map(st => {
        if (st === timesheetEntryStatuses.CONFIRMED || st === timesheetEntryStatuses.UNCONFIRMED) {
          return timesheetEntryStatuses.CLOCKED_OUT;
        }
        return st;
      }),
    );

    const queryParams = {
      partner: partner.length > 0 ? partner.join(',') : undefined,
      staff_request: this.props.staffRequestId,
      status: newStatus.join(','),
      page,
      clock_in_time_after,
      clock_in_time_before,
    };

    // set supervisor_confirmed value specifically according to status filter
    if (status.includes(timesheetEntryStatuses.CONFIRMED) && !status.includes(timesheetEntryStatuses.UNCONFIRMED)) {
      queryParams.supervisor_confirmed = true;
    } else if (
      status.includes(timesheetEntryStatuses.UNCONFIRMED) &&
      !status.includes(timesheetEntryStatuses.CONFIRMED)
    ) {
      queryParams.supervisor_confirmed = false;
    }

    return queryParams;
  };

  getStatusCounts = () => {
    if (this.state.status_counts) {
      const { clocked_in, clocked_out, disputed, resolved } = this.state.status_counts;
      return [
        { title: 'Clocked in', num: clocked_in, hasBadge: false },
        { title: 'Approval Needed', num: clocked_out + resolved, hasBadge: clocked_out + resolved > 0 },
        { title: 'Disputed', num: disputed, hasBadge: disputed > 0 },
      ];
    }
  };

  handleStatusFilterChange = status => {
    this.setState({ status, page: 1 }, () => {
      this.fetchTimesheetWithParams();
    });
  };

  handleWorkerFilterChange = partner => {
    this.setState({ partner, page: 1 }, () => {
      this.fetchTimesheetWithParams();
    });
  };

  getFilterWithSearch = () => {
    const { allWorkers } = this.state;
    return {
      title: 'Workers',
      options: allWorkers,
      onSelectChange: values => {
        const selectedPartnerIds = values.map(({ key }) => key);
        this.setState({ partner: selectedPartnerIds }, () => this.fetchTimesheetWithParams());
      },
    };
  };

  handleDateChange = dates => {
    let before;
    let after;
    if (dates[0] && dates[1]) {
      after = moment(dates[0])
        .startOf('day')
        .toISOString();
      before = moment(dates[1])
        .endOf('day')
        .toISOString();
    }
    this.setState({ clock_in_time_after: after, clock_in_time_before: before }, () => this.fetchTimesheetWithParams());
  };

  handlePageChange = page => {
    this.setState({ page }, () => this.fetchTimesheetWithParams());
  };

  handleSelectChange = rows => {
    this.setState({ selectedTimesheetEntries: rows });
  };

  handleApprove = async () => {
    const { selectedTimesheetEntries } = this.state;
    const listOfApprovePromises = selectedTimesheetEntries.map(timesheetEntry =>
      timesheetEntryApi.approve(timesheetEntry.id),
    );
    const responses = await Promise.all(listOfApprovePromises);
    if (responses) {
      // Deselect all rows  & update counts
      const selectedTimesheetIds = selectedTimesheetEntries.map(({ id }) => id);
      this.setState(prevState => {
        const updatedTimesheets = this.updateTimesheetStatus(
          prevState.timesheets,
          selectedTimesheetIds,
          timesheetEntryStatuses.APPROVED,
        );
        return {
          selectedTimesheetEntries: [],
          timesheets: updatedTimesheets,
        };
      });
      message.success(`Successfully approved ${selectedTimesheetEntries.length} timesheet entries`);
    }
  };

  handleVoid = selectedTimesheet => {
    this.setState(prevState => {
      const updatedTimesheets = this.updateTimesheetStatus(
        prevState.timesheets,
        [selectedTimesheet.id],
        timesheetEntryStatuses.VOID,
      );
      return { timesheets: updatedTimesheets };
    });
  };

  handleDispute = selectedTimesheet => {
    this.setState(prevState => {
      const updatedTimesheets = this.updateTimesheetStatus(
        prevState.timesheets,
        [selectedTimesheet.id],
        timesheetEntryStatuses.DISPUTED,
      );
      return { timesheets: updatedTimesheets };
    });
  };

  handleSingleApprove = updatedTimesheet => {
    this.setState(prevState => {
      const updatedTimesheets = this.updateTimesheetStatus(
        prevState.timesheets,
        [updatedTimesheet.id],
        timesheetEntryStatuses.APPROVED,
        updatedTimesheet.voided_reason,
        updatedTimesheet.supervisor_confirmed,
      );
      return { timesheets: updatedTimesheets };
    });
  };

  handleConfirm = updatedTimesheet => {
    this.setState(prevState => {
      const updatedTimesheets = this.updateTimesheetStatus(
        prevState.timesheets,
        [updatedTimesheet.id],
        updatedTimesheet.status,
        updatedTimesheet.voided_reason,
        updatedTimesheet.supervisor_confirmed,
      );
      return { timesheets: updatedTimesheets };
    });
  };

  updateTimesheetStatus = (timesheets, timesheetIds, newStatus, newReason = null, supervisor_confirmed) => {
    for (let i = 0; i < timesheets.length; i += 1) {
      if (timesheetIds.includes(timesheets[i].id)) {
        timesheets[i].status = newStatus;
        if (newReason) timesheets[i].voided_reason = newReason;
        if (supervisor_confirmed) timesheets[i].supervisor_confirmed = supervisor_confirmed;
      }
    }
    this.fetchStatus();
    return timesheets;
  };

  render() {
    const { isLoading, timesheets, partner, page, status, selectedTimesheetEntries } = this.state;
    const hasSelectedRows = selectedTimesheetEntries.length > 0;
    const workersFilter = this.getFilterWithSearch();
    const totalPartnerIdSearched = (partner && partner.length) || 0;
    const statusCounts = this.getStatusCounts();
    return (
      <>
        {/* Top level summary */}
        <CardWithCount loading={!statusCounts} countList={statusCounts} />

        {/* Period Filters */}
        <Row type="flex" justify="space-between" style={{ marginBottom: '16px' }}>
          <Col span={8}>
            <RangePicker
              ranges={{
                Today: [moment(), moment()],
                '7 days ago': [moment().subtract(7, 'd'), moment()],
                '14 days ago': [moment().subtract(14, 'd'), moment()],
                '30 days ago': [moment().subtract(30, 'd'), moment()],
              }}
              onChange={this.handleDateChange}
              format="DD/MM/YYYY"
            />
          </Col>
          <Col>
            <TimesheetDownloadButton params={this.getQueryParams(status)} />
          </Col>
        </Row>

        {/* Status & Workers filter */}
        <Row gutter={8}>
          <Col span={4}>
            <TimesheetStatusFilterButton onApply={this.handleStatusFilterChange} initialOptions={status} />
          </Col>
          <Col span={4}>
            <FilterWithSearch
              title={`${workersFilter.title} ${totalPartnerIdSearched > 0 ? `(${totalPartnerIdSearched})` : ''}`}
              options={workersFilter.options}
              onSelectChange={workersFilter.onSelectChange}
              placeholder="Search by Workmate"
              optionLabelField="name"
            />
          </Col>
        </Row>
        <Divider />

        {/* Bulk action buttons for table */}
        {checkAccess(permissions.createTimesheet) && (
          <Row gutter={8} style={{ marginBottom: '32px' }}>
            <Col span={4}>
              <Button
                block
                type="v2-primary"
                disabled={!hasSelectedRows}
                style={hasSelectedRows ? {} : { backgroundColor: 'inherit !important' }}
                onClick={() =>
                  ConfirmModals.approveTimesheetEntries(selectedTimesheetEntries.length, this.handleApprove)
                }
              >
                {`Approve ${hasSelectedRows ? `(${selectedTimesheetEntries.length})` : ''}`}
              </Button>
            </Col>
            <Col span={4}>
              <Button onClick={() => this.setState({ showAddTimesheetEntryModal: true })} icon="plus-circle">
                Add Timesheet Entries
              </Button>
            </Col>
          </Row>
        )}

        <TimesheetList
          page={page}
          loading={isLoading}
          dataSource={timesheets}
          count={this.state.numRecords}
          onVoid={this.handleVoid}
          onEdit={this.fetchTimesheetWithParams}
          onConfirm={this.handleConfirm}
          onDispute={this.handleDispute}
          onApprove={this.handleSingleApprove}
          onPageChange={this.handlePageChange}
          onSelectChange={this.handleSelectChange}
          selectedTimesheetEntries={selectedTimesheetEntries}
        />

        <AddTimesheetEntryModal
          staffRequestId={this.props.staffRequestId}
          locationId={this.props.locationId}
          positionId={this.props.positionId}
          onClose={() => {
            this.setState({ showAddTimesheetEntryModal: false });
          }}
          open={this.state.showAddTimesheetEntryModal}
          onUpdate={this.handleLoadTimesheetEntries}
        />
      </>
    );
  }
}

export default TimesheetContainer;
