import React from 'react';
import { Modal, Table, Input, Icon } from 'antd';
import partnerApi from '../../../../services/partnerApi';
import { partnerStatuses, partnerSourceTypes } from '../../../../constants';
import PartnerAvatar from '../../../../shared/components/PartnerAvatar';
import NoResults from '../../../../shared/components/NoResults';
import ButtonWithLoading from '../../../../shared/components/ButtonWithLoading';
import recommendationApi from '../../../../services/recommendationApi';

export default class SendRecommendationModal extends React.Component {
  state = {
    partners: [],
    loading: false,
  };

  componentDidMount() {
    this.searchPartners();
  }

  searchPartners = async search => {
    await this.setState({
      partners: null,
      sendList: {},
      loading: true,
    });
    const response = await partnerApi.fetchPartners({
      page: 1,
      search,
      status: partnerStatuses.ACTIVE,
    });

    const sourcedPartners = response.results.filter(partner => partner.source !== partnerSourceTypes.DIRECT_HIRE);

    this.setState({
      partners: sourcedPartners.map(partner => {
        partner.sendState = false;
        partner.errorState = false;
        return partner;
      }),
      loading: false,
    });
  };

  createRecommendation = async (partnerId, createSource) => {
    const partners = [...this.state.partners];
    const index = partners.findIndex(obj => obj.id === partnerId);

    try {
      const response = await recommendationApi.createRecommendation(this.props.staffRequestId, partnerId, createSource);
      if (response) {
        // After offer created, update partners send flag to true
        partners[index].sendState = true;
        this.setState({ partners });
      }
    } catch (error) {
      if (error) {
        partners[index].errorState = true;
        this.setState({ partners });
      }
    }
  };

  getColumns = () => {
    return [
      {
        title: 'Partner ID',
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: 'Name',
        render: partner => <PartnerAvatar partner={partner} />,
      },
      {
        title: 'Mobile',
        dataIndex: 'mobile',
        key: 'mobile',
      },
      {
        title: 'Send Recommendation',
        render: partner => (
          <ButtonWithLoading
            initialText="Send"
            postText={partner.errorState ? 'Already Sent' : 'Sent'}
            status={partner.sendState || partner.errorState}
            onClick={() => {
              this.createRecommendation(partner.id, 'send_recommendation_button');
            }}
          />
        ),
      },
    ];
  };

  cleanState() {
    this.setState({ partners: null });
  }

  render() {
    const { staffRequestId, ...props } = this.props;
    const { partners } = this.state;
    // Filter out partner already have recommendation
    const partnerList = partners;

    return (
      <Modal
        title="Send recommendation to Partner"
        width={1000}
        okButtonProps={{ style: { display: 'none' } }}
        {...props}
      >
        <Input
          suffix={<Icon type="search" />}
          placeholder="Search by Partner ID, Mobile, Name"
          onPressEnter={({ target: { value } }) => this.searchPartners(value)}
          allowClear
          style={{ width: '100%', marginBottom: 30 }}
        />
        {partnerList && partnerList.length === 0 ? (
          <NoResults>No results to display. Please update your search.</NoResults>
        ) : (
          <Table
            loading={this.state.loading}
            size="small"
            pagination={false}
            columns={this.getColumns()}
            dataSource={partners}
          />
        )}
      </Modal>
    );
  }
}
