import { notification } from 'antd';
import authApi from '../services/authApi';

export const handleError = error => {
  if (!error.response) {
    notification.error({
      message: 'An unexpected error occurred',
      duration: 0,
    });
    return;
  }

  if (error.response.status === 401) {
    authApi.logout();
  }

  const message = `${error.response.status} ${error.response.statusText}`;
  const description = error.response?.data?.detail
    ? `${JSON.stringify(error.response.data.detail)}`
    : `${JSON.stringify(error.response.data)}`;

  notification.error({
    message,
    description,
    duration: 0,
  });
  throw error;
};
