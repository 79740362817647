import React from 'react';
import { Modal } from 'antd';
import operatorApi from '../../../../services/operatorApi';

class RemoveOperatorModal extends React.Component {
  state = {
    loading: false,
  };

  removeOperator = () => {
    const { operator } = this.props;

    this.setState({ loading: true });
    operatorApi.removeOperator(operator.id).then(() => {
      this.handleCompletion();
    });
  };

  handleCompletion = () => {
    const { onUpdate, onClose } = this.props;

    this.setState({ loading: false });
    onUpdate();
    onClose();
  };

  render() {
    const { loading } = this.state;
    const { operator, onClose, visible } = this.props;

    return (
      <Modal
        title="Remove user access"
        confirmLoading={loading}
        visible={visible}
        onCancel={onClose}
        onOk={this.removeOperator}
        okText="Yes, remove"
        okType="danger"
      >
        <p>
          Are you sure you want to remove access for {operator && operator.name} ({operator && operator.user.email})?
        </p>
        <p>This user will no longer be able to log in or view the Admin Dashboard.</p>
      </Modal>
    );
  }
}

export default RemoveOperatorModal;
