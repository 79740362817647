import { getAuth, GoogleAuthProvider, signInWithPopup, browserLocalPersistence } from 'firebase/auth';
import { WORKMATE_DOMAIN } from '../constants';
import { firebaseApp } from '../firebase';

const logInWithGoogle = async () => {
  const auth = getAuth(firebaseApp);
  const provider = new GoogleAuthProvider();
  provider.setCustomParameters({ hd: WORKMATE_DOMAIN, prompt: 'select_account' }); // set default login domain and enforce account selection
  await auth.setPersistence(browserLocalPersistence);
  const response = await signInWithPopup(auth, provider);
  return response;
};

export { logInWithGoogle };
