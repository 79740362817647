import React from 'react';
import { withTranslation } from 'react-i18next';
import { Card, Typography, Spin } from 'antd';
import { MinusOutlined } from '@ant-design/icons';

import { colors } from '../../../../styles/colors';
const { Text } = Typography;

const TotalWorkforceCard = ({ t, loading, totalWorkforceCount, empty }) => {
  return (
    <Card
      style={{
        minWidth: 230,
        height: 100,
        backgroundColor: colors.infoHighlightBlue,
        border: `0.5px solid ${colors.componentBorder}`,
        borderRadius: 4,
        boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15)',
      }}
      bodyStyle={{
        paddingLeft: '40px',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Text style={{ fontWeight: 600, fontSize: 12, lineHeight: '20px', color: colors.white }}>
          {t('totalWorkforce').toUpperCase()}
        </Text>
        <Text
          style={{ fontWeight: 600, fontSize: 24, lineHeight: '32px', color: colors.white, opacity: empty && 0.45 }}
        >
          {empty ? (
            <MinusOutlined />
          ) : loading ? (
            <Spin className={'total-workforce-card-spinner'} data-testid={'total-workforce-card-spinner'} />
          ) : (
            totalWorkforceCount
          )}
        </Text>
      </div>
    </Card>
  );
};

export default withTranslation()(TotalWorkforceCard);
