import React from 'react';
import moment from 'moment';
import { Button, Icon } from 'antd';
import { withTranslation } from 'react-i18next';
import WeekSelect from './WeekSelect';
import { getWeekStartOptions } from '../../../../utilities/dateUtils';

const NUM_WEEKS_BEFORE = 4;
const NUM_WEEKS_AFTER = 5;

export default withTranslation()(({ onChange, selectedWeekStart, timezone, loading, t }) => {
  const currentWeekStart = moment()
    .tz(timezone)
    .startOf('isoWeek')
    .toISOString();
  const weekStartOptions = getWeekStartOptions(currentWeekStart, NUM_WEEKS_BEFORE, NUM_WEEKS_AFTER);
  const reachedStart = selectedWeekStart?.clone().toISOString() === weekStartOptions[0];
  const reachedEnd = selectedWeekStart?.clone().toISOString() === weekStartOptions[weekStartOptions.length - 1];
  return (
    <div style={{ display: 'flex' }}>
      <WeekSelect
        mainSelector
        onChange={onChange}
        selectedWeekStart={selectedWeekStart}
        weekStartOptions={weekStartOptions}
        loading={loading}
      />
      <Button
        style={{ margin: '0 8px' }}
        onClick={() =>
          onChange(
            moment()
              .tz(timezone)
              .startOf('isoWeek'),
          )
        }
      >
        {t('thisWeek')}
      </Button>
      <Button
        style={{ margin: '0 8px', width: '32px', padding: 'unset' }}
        disabled={reachedStart}
        onClick={() =>
          onChange(
            moment(selectedWeekStart)
              .subtract(1, 'weeks')
              .toISOString(),
          )
        }
      >
        <Icon type="left" />
      </Button>
      <Button
        style={{ margin: '0 8px', width: '32px', padding: 'unset' }}
        disabled={reachedEnd}
        onClick={() =>
          onChange(
            moment(selectedWeekStart)
              .add(1, 'weeks')
              .toISOString(),
          )
        }
      >
        <Icon type="right" />
      </Button>
    </div>
  );
});
