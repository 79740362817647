import React from 'react';
import moment from 'moment-timezone';
import { Typography, DatePicker, Button, Divider, Row, Col, Select, Input, Alert } from 'antd';
import paymentApi from '../../../services/paymentApi';
import { countries, paymentItemStatuses } from '../../../constants';

import CardWithCount from '../../shared/components/CardWithCount';
import WorkerFilterTagButton from '../../shared/components/WorkerFilterTagButton';
import StatusFilterButton from './components/StatusFilterButton';
import { checkAccess } from '../../../shared/access/Access';
import { permissions } from '../../../shared/access/accessConfig';
import GenerateReportModal from './Modals/GenerateReportModal';
import SgGenerateReportModal from './Modals/SgGenerateReportModal';
import PaymentReportList from './components/PaymentReportList';
import getCountryCode from '../../../utilities/getCountryCode';
import MarkPaidModal from './Modals/MarkPaidModal';
import MarkOnHoldModal from './Modals/MarkOnHoldModal';
import VoidReportModal from './Modals/VoidReportModal';
import PaymentReportDownloadButton from '../../shared/components/PaymentReportDownloadButton';
import MarkPaidAllModal from './Modals/MarkPaidAllModal';
import PaymentReportTypeFilter from './components/PaymentReportTypeFilter';

const { RangePicker } = DatePicker;
const { Text, Title } = Typography;

class PaymentReportPage extends React.Component {
  state = {
    isLoading: false,
    paymentReports: null,
    totalCount: null,
    status_counts: undefined,
    status: [],
    partner: [],
    type: [],
    search: undefined,
    ordering: undefined,
    page: 1,
    created_date_after: undefined,
    created_date_before: undefined,
    showGenerateReportModal: false,
    showSgGenerateReportModal: false,
    showMarkPaidModal: false,
    showMarkPaidAllModal: false,
    showMarkOnHoldModal: false,
    showVoidReportModal: false,
    selectedPaymentReports: [],
    selectedAllFlag: false,
    selectedAllFilteredReportFlag: false,
  };

  async componentDidMount() {
    this.fetchPaymentReportsWithParams({ include_counts: true });
  }

  fetchPaymentReportsWithParams = async (params = undefined) => {
    this.clearSelectionState();
    this.setState({ isLoading: true });
    let searchParams = this.getSearchParams();
    if (params) {
      searchParams = { ...params, ...searchParams };
    }
    const response = await paymentApi.fetchPaymentReports(searchParams);

    this.setState({
      isLoading: false,
      paymentReports: response.results,
      totalCount: response.count,
    });

    if (response.status_counts) {
      this.setState({
        status_counts: response.status_counts,
      });
    }
  };

  getSearchParams = () => {
    const { partner, status, type, search, ordering, page, created_date_after, created_date_before } = this.state;
    const searchParams = {
      country: getCountryCode(),
      partner: partner.length > 0 ? partner.join(',') : undefined,
      status: status.join(','),
      type: type.length > 0 ? type.join(',') : undefined,
      search,
      ordering,
      page,
      created_date_after,
      created_date_before,
    };
    return searchParams;
  };

  getStatusCounts = () => {
    if (this.state.status_counts) {
      const { unpaid, on_hold, error, paid } = this.state.status_counts;
      return [
        { title: 'Unpaid', num: unpaid, hasBadge: unpaid > 0 },
        { title: 'On Hold', num: on_hold, hasBadge: on_hold > 0 },
        { title: 'Error', num: error, hasBadge: error > 0 },
        { title: 'Paid', num: paid, hasBadge: false },
      ];
    }
  };

  handleSearchChange = search => {
    this.setState({ search, page: 1 }, () => this.fetchPaymentReportsWithParams());
  };

  handleDateChange = dates => {
    let before;
    let after;
    if (dates[0] && dates[1]) {
      after = moment(dates[0])
        .startOf('day')
        .toISOString();
      before = moment(dates[1])
        .endOf('day')
        .toISOString();
    }
    this.setState({ created_date_after: after, created_date_before: before }, () =>
      this.fetchPaymentReportsWithParams(),
    );
  };

  handleStatusFilterChange = status => {
    this.setState({ status }, () => this.fetchPaymentReportsWithParams());
  };

  handleWorkerFilterChange = partner => {
    this.setState({ partner }, () => this.fetchPaymentReportsWithParams());
  };

  handleTypeFilterChange = type => {
    this.setState({ type }, () => this.fetchPaymentReportsWithParams());
  };

  handleMarkPaid = async () => {
    this.setState({ showMarkPaidModal: false });
    this.fetchPaymentReportsWithParams({ include_counts: true });
  };

  handleMarkPaidAll = async () => {
    this.setState({ showMarkPaidAllModal: false });
    this.fetchPaymentReportsWithParams({ include_counts: true });
  };

  handleOpenMarkAsPaidModal = () => {
    if (this.state.selectedAllFilteredReportFlag) this.setState({ showMarkPaidAllModal: true });
    else this.setState({ showMarkPaidModal: true });
  };

  handleMarkOnHold = async () => {
    this.setState({ showMarkOnHoldModal: false });
    this.fetchPaymentReportsWithParams({ include_counts: true });
  };

  handleVoidReport = async () => {
    this.setState({ showVoidReportModal: false });
    this.fetchPaymentReportsWithParams({ include_counts: true });
  };

  handlePageChange = page => {
    this.setState({ page }, () => this.fetchPaymentReportsWithParams());
  };

  handleOrderChange = ordering => {
    this.setState({ ordering }, () => this.fetchPaymentReportsWithParams());
  };

  handleSelectChange = selectedPaymentReports => {
    if (selectedPaymentReports.length === 0)
      this.setState({ selectedAllFlag: false, selectedAllFilteredReportFlag: false });
    this.setState({ selectedPaymentReports });
  };

  handleSelectAll = selectedPaymentReports => {
    const deselectFlag = selectedPaymentReports.length === 0;
    if (deselectFlag) this.setState({ selectedAllFilteredReportFlag: false });
    this.setState({ selectedAllFlag: !deselectFlag });
  };

  handleSelectAllFilteredReports = () => {
    this.setState({ selectedAllFilteredReportFlag: true });
  };

  handleClearSelection = () => {
    this.clearSelectionState();
  };

  clearSelectionState = () => {
    this.setState({
      selectedPaymentReports: [],
      selectedAllFlag: false,
      selectedAllFilteredReportFlag: false,
    });
  };

  getApproveCount = () => {
    const { selectedAllFilteredReportFlag, totalCount, selectedPaymentReports } = this.state;
    let approveCount = 0;
    if (selectedAllFilteredReportFlag) approveCount = totalCount;
    else if (selectedPaymentReports.length > 0) approveCount = selectedPaymentReports.length;
    return approveCount;
  };

  render() {
    const {
      isLoading,
      paymentReports,
      totalCount,
      page,
      showGenerateReportModal,
      showSgGenerateReportModal,
      showMarkPaidModal,
      showMarkOnHoldModal,
      showMarkPaidAllModal,
      showVoidReportModal,
      selectedPaymentReports,
      selectedAllFlag,
      selectedAllFilteredReportFlag,
    } = this.state;
    const approveCount = this.getApproveCount();
    const hasRowSelected = approveCount > 0;
    return (
      <>
        {/* Header & Create button */}
        <Row type="flex" justify="space-between" style={{ marginBottom: '32px' }}>
          <Col>
            <Title level={3}>Payment reports</Title>
          </Col>
          <Col>
            {checkAccess(permissions.createPaymentReport) && (
              <Button
                type="v2-primary"
                icon="file-add"
                onClick={() =>
                  getCountryCode() === countries.singapore.code
                    ? this.setState({ showSgGenerateReportModal: true })
                    : this.setState({ showGenerateReportModal: true })
                }
              >
                Generate reports
              </Button>
            )}
          </Col>
        </Row>
        {/* Top level summary */}
        <CardWithCount loading={!paymentReports} countList={this.getStatusCounts()} />

        {/* Period Filters */}
        <Row type="flex" justify="space-between" style={{ marginBottom: '16px' }}>
          <Col span={6}>
            <RangePicker
              ranges={{
                Today: [moment(), moment()],
                '7 days ago': [moment().subtract(7, 'd'), moment()],
                '14 days ago': [moment().subtract(14, 'd'), moment()],
                '30 days ago': [moment().subtract(30, 'd'), moment()],
              }}
              onChange={this.handleDateChange}
              format="DD/MM/YYYY"
            />
          </Col>
          <Col>
            <PaymentReportDownloadButton params={this.getSearchParams()} />
          </Col>
        </Row>

        {/* Status & Workers filter */}
        <Row type="flex" gutter={8}>
          <Col span={3}>
            <StatusFilterButton onApply={this.handleStatusFilterChange} initialOptions={this.state.status} />
          </Col>
          <Col span={3}>
            <WorkerFilterTagButton
              onSelectChange={this.handleWorkerFilterChange}
              selectedOptions={this.state.partner}
            />
          </Col>
          <Col span={3}>
            <PaymentReportTypeFilter onApply={this.handleTypeFilterChange} initialOptions={this.state.type} />
          </Col>
          <Col span={5} style={{ marginLeft: 'auto' }}>
            <Input.Search allowClear placeholder="Search Report #" onSearch={this.handleSearchChange} />
          </Col>
        </Row>
        <Divider />

        <Row type="flex" gutter={8} style={{ marginBottom: '32px' }}>
          <Col span={9}>
            <div style={{ display: 'flex' }}>
              {checkAccess(permissions.editPaymentReport) && (
                <Button
                  disabled={selectedPaymentReports.length === 0}
                  type="v2-primary"
                  onClick={this.handleOpenMarkAsPaidModal}
                  style={{ backgroundColor: 'inherit !important', marginRight: '8px' }}
                >
                  Mark as paid {hasRowSelected ? <> ({approveCount})</> : ''}
                </Button>
              )}
              {checkAccess(permissions.editPaymentReport) && (
                <Button
                  disabled={selectedPaymentReports.length === 0}
                  onClick={() => {
                    this.setState({ showMarkOnHoldModal: true });
                  }}
                  style={{ marginRight: '8px' }}
                >
                  Mark on hold {hasRowSelected ? <> ({selectedPaymentReports.length})</> : ''}
                </Button>
              )}
              {checkAccess(permissions.editPaymentReport) && selectedPaymentReports && (
                <Button
                  disabled={
                    selectedPaymentReports.length === 0 ||
                    selectedPaymentReports.some(report => report.status === paymentItemStatuses.PAID)
                  }
                  type="danger"
                  onClick={() => {
                    this.setState({ showVoidReportModal: true });
                  }}
                  style={{ marginRight: '8px' }}
                >
                  Void {hasRowSelected ? <> ({selectedPaymentReports.length})</> : ''}
                </Button>
              )}
            </div>
          </Col>
          <Col span={5} style={{ marginLeft: 'auto' }}>
            <Text type="secondary">Sort by</Text>
            <Select
              defaultValue="-created_date"
              style={{ marginLeft: '8px', width: '180px' }}
              onChange={orderByValue => this.handleOrderChange(orderByValue)}
            >
              <Select.Option value="-created_date">Most recent</Select.Option>
            </Select>
          </Col>
        </Row>
        {checkAccess(permissions.editPaymentReport) && selectedAllFlag && (
          <Row style={{ marginBottom: '16px' }}>
            <Alert
              message={
                <>
                  {!selectedAllFilteredReportFlag ? (
                    <>
                      All {selectedPaymentReports.length} payment reports on this page are selected.
                      <Text
                        strong
                        style={{ cursor: 'pointer', color: '#1890FF', marginLeft: '5px' }}
                        onClick={this.handleSelectAllFilteredReports}
                      >
                        Select all {totalCount} payment reports
                      </Text>
                    </>
                  ) : (
                    <>
                      All {totalCount} payment reports are selected.
                      <Text
                        strong
                        style={{ cursor: 'pointer', color: '#1890FF', marginLeft: '5px' }}
                        onClick={this.handleClearSelection}
                      >
                        Clear selection
                      </Text>
                    </>
                  )}
                </>
              }
              type="info"
            />
          </Row>
        )}

        <PaymentReportList
          loading={isLoading}
          selectedPaymentReports={selectedPaymentReports}
          dataSource={paymentReports}
          count={totalCount}
          onPageChange={this.handlePageChange}
          onSelectChange={this.handleSelectChange}
          onSelectAll={this.handleSelectAll}
          page={page || 1}
        />

        <GenerateReportModal
          visible={showGenerateReportModal}
          onChange={() => this.fetchPaymentReportsWithParams({ include_counts: true })}
          onCancel={() => this.setState({ showGenerateReportModal: false })}
        />
        <SgGenerateReportModal
          visible={showSgGenerateReportModal}
          onChange={() => this.fetchPaymentReportsWithParams({ include_counts: true })}
          onCancel={() => this.setState({ showSgGenerateReportModal: false })}
        />
        <MarkPaidModal
          visible={showMarkPaidModal}
          items={selectedPaymentReports}
          onCancel={() => this.setState({ showMarkPaidModal: false })}
          onChange={this.handleMarkPaid}
        />
        <MarkPaidAllModal
          visible={showMarkPaidAllModal}
          count={approveCount}
          params={this.getSearchParams()}
          onCancel={() => this.setState({ showMarkPaidAllModal: false })}
          onChange={this.handleMarkPaidAll}
        />
        <MarkOnHoldModal
          visible={showMarkOnHoldModal}
          items={selectedPaymentReports}
          onCancel={() => this.setState({ showMarkOnHoldModal: false })}
          onChange={this.handleMarkOnHold}
        />
        <VoidReportModal
          visible={showVoidReportModal}
          items={selectedPaymentReports}
          onCancel={() => this.setState({ showVoidReportModal: false })}
          onChange={this.handleVoidReport}
        />
      </>
    );
  }
}

export default PaymentReportPage;
