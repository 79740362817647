import { Spin } from 'antd';
import React from 'react';
import clientApi from '../../../../services/clientApi';
import ClientDetail from './ClientDetail';

export default class ClientDetailContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      client: null,
    };
  }

  componentDidMount() {
    this.fetchClient();
  }

  fetchClient = () => {
    const { id } = this.props.match.params;

    clientApi.fetchClientById(id).then(data => {
      this.setState({
        client: data,
      });
    });
  };

  render() {
    const { client } = this.state;

    if (!client) {
      return (
        <div style={{ textAlign: 'center' }}>
          <Spin size="large" />
        </div>
      );
    }

    return <ClientDetail client={this.state.client} onUpdate={this.fetchClient} />;
  }
}
