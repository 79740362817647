import React from 'react';
import { Typography, Table, Row, Col, Avatar } from 'antd';
import { Link } from 'react-router-dom';
import { startCase } from 'lodash';
import { timesheetEntryStatuses, timesheetEntryPaymentStatuses } from '../../../../constants';
import getPaginationConfig from '../../../utilities/getPaginationConfig';
import timesheetUtils from '../../../utilities/timesheetUtils';

import DropdownButtons from '../../../shared/components/DropdownButtons';
import StatusTag from '../../../shared/components/StatusTag';
import EditTimesheetFormModal from '../../../shared/components/EditTimesheetFormModal';
import ToolTipWithInfo from '../../../shared/components/ToolTipWithInfo';
import routes from '../../../../routes';
import { checkAccess } from '../../../../shared/access/Access';
import { permissions } from '../../../../shared/access/accessConfig';
import VoidTimesheetEntryModal from '../../../shared/components/VoidTimesheetEntryModal';
import ConfirmTimesheetEntryModal from '../../../shared/components/ConfirmTimesheetEntryModal';
import ApproveTimesheetEntryModal from '../../../shared/components/ApproveTimesheetEntryModal';
import { colors } from '../../../../styles/colors';
import { formatDuration } from '../../../../utilities/dateUtils';

const { Text } = Typography;

// Some rows on the table are not allowed to be selected since there are no actions to perform
const unselectableTimesheetStatuses = [
  timesheetEntryStatuses.VOID,
  timesheetEntryStatuses.DISPUTED,
  timesheetEntryStatuses.CLOCKED_IN,
];
const actionableTimesheetStatuses = [
  timesheetEntryStatuses.CLOCKED_IN,
  timesheetEntryStatuses.CLOCKED_OUT,
  timesheetEntryStatuses.RESOLVED,
  timesheetEntryStatuses.DISPUTED,
];
class TimesheetList extends React.Component {
  state = {
    currentTimesheetEntry: {},
    showEditModal: false,
    showVoidModal: false,
    showConfirmModal: false,
    showApproveModal: false,
  };

  columns = [
    {
      width: 180,
      title: 'Name',
      dataIndex: 'partner',
      render: text => (
        <Row gutter={8}>
          <Link to={routes.partnerDetail.replace(':id', text.id)}>
            <Col span={8}>
              <Avatar shape="square" size="large" src={text.image} />
            </Col>
            <Text style={{ fontSize: '14px' }}>
              {`${text.firstName} ${text.lastName}`} <br />
              <Text type="secondary">#{text.id}</Text>
            </Text>
          </Link>
        </Row>
      ),
    },
    {
      width: 320,
      title: 'Timesheet',
      dataIndex: 'createdDate',
      render: (_, record) => {
        const { start, end } = timesheetUtils.getFriendlyClockInOutString(
          record.clockInTime,
          record.clockOutTime,
          record.roundedClockInTime,
          record.roundedClockOutTime,
        );
        return (
          <Text style={{ fontSize: '14px' }}>
            {start}
            <Text type="secondary"> ~ </Text>
            {end}
            <Text type="secondary">
              {record.clockOutTime ? ` (${formatDuration(record?.totalWorkDurationInMinutes)})` : ''}
            </Text>
            <br />
            <Text type="secondary">Entry #{record.id}</Text>
          </Text>
        );
      },
    },
    {
      width: 250,
      title: 'Client',
      dataIndex: 'staff_request_id',
      render: (text, record) => (
        <>
          <Row gutter={6}>
            <Link to={routes.clientsDetail.replace(':id', record?.client?.id)}>
              <Col span={6}>
                <Avatar shape="square" size="large" src={record?.client?.logo} />
              </Col>
              <Text style={{ fontSize: '14px' }}>
                {record?.client?.name}
                <br />
                <Text type="secondary">
                  {record?.employment?.staffRequest?.id ? `SR #${record.employment.staffRequest.id}` : 'Direct Hire'}
                </Text>
              </Text>
            </Link>
          </Row>
        </>
      ),
    },
    {
      width: 210,
      title: 'Timesheet status',
      dataIndex: 'status',
      render: (text, record) => (
        <Row align="middle">
          <StatusTag
            status={text}
            confirmedClockedOut={
              record.status === timesheetEntryStatuses.RESOLVE ||
              (record.status === timesheetEntryStatuses.CLOCKED_OUT && record.supervisorConfirmed === true)
            }
          />
          {((record.status === timesheetEntryStatuses.DISPUTED && record.disputedReason) ||
            (record.status === timesheetEntryStatuses.VOID && record.voidedReason) ||
            record.notes) && (
            <ToolTipWithInfo
              infoText={
                (record.status === timesheetEntryStatuses.DISPUTED &&
                  record.disputedReason &&
                  record.disputedReason.name) ||
                (record.status === timesheetEntryStatuses.VOID && record.voidedReason && record.voidedReason.name) ||
                record.notes
              }
            />
          )}
        </Row>
      ),
    },
    {
      width: 100,
      title: 'Payment Status',
      render: (text, record) => (
        <Row align="middle">
          <Text>{startCase(record?.paymentStatus.replace('_', ' '))}</Text>
        </Row>
      ),
    },
    {
      width: 100,
      title: 'Wage',
      render: (text, record) => (
        <Row align="middle">
          <Text>{record?.baseWage}</Text>
        </Row>
      ),
    },
    {
      width: 150,
      title: 'Break duration (in minutes)',
      render: (text, record) => (
        <Row align="middle">
          <Text>{record?.breakDurationInMinutes}</Text>
        </Row>
      ),
    },
    {
      width: 100,
      title: 'Unpaid break',
      render: (text, record) => (
        <Row align="middle">
          <Text>{record?.unpaidBreak ? 'True' : 'False'}</Text>
        </Row>
      ),
    },
    {
      key: 'action',
      dataIndex: 'status',
      render: (text, record) => {
        // TODO Separate button definitions and permissions check
        const editButton = {
          title: 'Edit',
          onClick: () => this.setState({ showEditModal: true, currentTimesheetEntry: record }),
        };
        const confirmButton = checkAccess(permissions.resolveTimesheet) && {
          title: 'Confirm',
          onClick: () => this.setState({ showConfirmModal: true, currentTimesheetEntry: record }),
          style: { color: colors.workmateGreen },
        };
        const voidButton = {
          title: 'Void',
          onClick: () => this.setState({ showVoidModal: true, currentTimesheetEntry: record }),
          style: { color: colors.red },
        };
        const approveButton = {
          title: 'Approve',
          onClick: () => this.setState({ showApproveModal: true, currentTimesheetEntry: record }),
          style: { color: colors.functionalApproved },
        };

        let buttons = [];
        if (text === timesheetEntryStatuses.CLOCKED_IN) {
          buttons = [voidButton];
        }
        if (text === timesheetEntryStatuses.CLOCKED_OUT) {
          buttons = [editButton, confirmButton, approveButton, voidButton];
        }
        if (text === timesheetEntryStatuses.CLOCKED_OUT && record.supervisorConfirmed) {
          buttons = [editButton, approveButton, voidButton];
        }
        if (text === timesheetEntryStatuses.DISPUTED) {
          buttons = [editButton, confirmButton, voidButton];
        }

        // Buttons not allowed for access returns false
        const accessibleButtons = buttons.filter(x => x);
        return (
          <>
            {actionableTimesheetStatuses.includes(text) && (
              <Col>
                <DropdownButtons size="small" buttons={accessibleButtons} />
              </Col>
            )}
          </>
        );
      },
    },
  ];

  handleSelectChange = (keys, rows) => {
    this.props.onSelectChange(rows);
  };

  handleSelectAll = (keys, rows) => {
    this.props.onSelectAll(rows);
  };

  render() {
    const { currentTimesheetEntry, showEditModal, showVoidModal, showConfirmModal, showApproveModal } = this.state;

    const {
      selectedTimesheetEntries,
      loading,
      dataSource,
      onVoid,
      onEdit,
      onConfirm,
      onApprove,
      count,
      onPageChange,
      page,
    } = this.props;

    const selectedRowKeys = selectedTimesheetEntries.map(entry => entry.id);

    return (
      <>
        <Table
          style={{ marginBottom: '100px' }}
          loading={loading}
          columns={this.columns}
          dataSource={dataSource}
          rowKey="id"
          scroll={{ x: 'scroll' }}
          pagination={{
            ...getPaginationConfig(count, onPageChange),
            current: page,
          }}
          rowSelection={{
            selectedRowKeys,
            hideDefaultSelections: true,
            onSelectAll: this.handleSelectAll,
            onChange: this.handleSelectChange,
            getCheckboxProps: record => ({
              disabled:
                // NOTE:
                // Determine if the checkbox should be disabled based on various conditions
                //
                // Disable checkbox if the status is in 'unselectableTimesheetStatuses'
                // or if the status is UNCONFIRMED_CLOCK_OUT
                // or if the status is APPROVED and payment status is PAID or IN_PROGRESS
                unselectableTimesheetStatuses.includes(record.status) ||
                (record.status === timesheetEntryStatuses.CLOCKED_OUT && !record.supervisorConfirmed) ||
                (record.status === timesheetEntryStatuses.APPROVED &&
                  (record.paymentStatus === timesheetEntryPaymentStatuses.PAID ||
                    record.paymentStatus === timesheetEntryPaymentStatuses.IN_PROGRESS)),
            }),
          }}
        />

        {/* Modals */}
        <ConfirmTimesheetEntryModal
          visible={showConfirmModal}
          onCancel={() => this.setState({ showConfirmModal: false })}
          onOk={updatedTimesheetEntry => {
            onConfirm(updatedTimesheetEntry);
            this.setState({ showConfirmModal: false });
          }}
          timesheet={currentTimesheetEntry}
        />
        <VoidTimesheetEntryModal
          visible={showVoidModal}
          onCancel={() => this.setState({ showVoidModal: false })}
          onOk={updatedTimeSheet => {
            onVoid(updatedTimeSheet);
            this.setState({ showVoidModal: false });
          }}
          timesheet={currentTimesheetEntry}
        />
        <EditTimesheetFormModal
          visible={showEditModal}
          onCancel={() => this.setState({ showEditModal: false })}
          onOk={() => {
            onEdit();
            this.setState({ showEditModal: false });
          }}
          timesheet={currentTimesheetEntry}
        />
        <ApproveTimesheetEntryModal
          visible={showApproveModal}
          onCancel={() => this.setState({ showApproveModal: false })}
          onOk={updatedTimesheetEntry => {
            onApprove(updatedTimesheetEntry);
            this.setState({ showApproveModal: false });
          }}
          timesheet={currentTimesheetEntry}
        />
      </>
    );
  }
}

export default TimesheetList;
