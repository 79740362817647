import React from 'react';
import moment from 'moment-timezone';
import { cloneDeep, groupBy, uniq, uniqBy, isEqual, debounce, sortBy } from 'lodash';
import { Button, Typography, Divider, Row, Select, Col, Empty, message } from 'antd';
import { withTranslation } from 'react-i18next';
import { Prompt } from 'react-router';
import { connect } from 'react-redux';

import { employmentStatuses, attendanceStatuses, clientStatuses } from '../../../constants';
import shiftApi from '../../../services/shiftApi';
import employmentApi from '../../../services/employmentApi';
import scheduleApi from '../../../services/scheduleApi';
import clientApi from '../../../services/clientApi';
import { fetchAll } from '../../utilities/apiUtils';
import getCountryCode from '../../../utilities/getCountryCode';
import datetimeUtils from '../../utilities/datetimeUtils';
import employmentUtils from '../../utilities/employmentUtils';
import attendanceUtils from '../../utilities/attendanceUtils';
import { updateUrlWithParams, retrieveParamsFromUrl } from '../../../utilities/urlUtils';

import LoadingSpinner from '../../shared/components/LoadingSpinner';
import ShiftsSummaryCard from './components/ShiftsSummaryCard';
import WeekSelectGroup from './components/WeekSelectGroup';
import AssignmentListView from './AssignmentListView';
import ScheduleListView from './ScheduleListView';
import shiftsEmpty from '../../../assets/images/empty.png';

const { Title, Text } = Typography;
const { Option, OptGroup } = Select;

const SHIFT_FILTERS_KEY = 'shiftFilters';
const CLIENT_KEY = 'clientId';
const LOCATION_KEY = 'locationId';
const POSITION_KEY = 'positionId';
const PAGE_SIZE = 100;
const DATE_KEY_FORMAT = 'YYYY-MM-DD';

class ShiftPage extends React.Component {
  state = {
    scheduleLoading: false,
    assignmentsLoading: false,
    statisticsLoading: false,
    clientLoading: false,
    locationLoading: false,
    // selectedWeekStart is a moment object with local timezone info, NOT user's browser timezone
    selectedWeekStart: undefined,
    // weekdayDates are derived from selectedWeekStart, each date is a moment object with local timezone info
    // Used to derive schedules and shifts table column and match corresponding shift and attendances of the day
    weekdayDates: [],
    shifts: [],
    originalShifts: [],
    employments: [],
    absencesCount: undefined,
    unconfirmedWorkerList: [],
    unconfirmedAssignmentCount: undefined,
    confirmedAssignmentCount: undefined,
    clientSearchResults: [],
    clientId: undefined,
    locations: [],
    locationId: undefined,
    positionId: undefined,
    timezone: 'Asia/Jakarta',
    allSchedules: [],
  };

  async componentDidMount() {
    const { timezone } = this.state;
    const { clientId, locationId, positionId } = await this.getFilterValues();

    // fetch shifts only when all params are present
    if (clientId && locationId && positionId) {
      try {
        const client = await clientApi.fetchClientById(clientId);
        const selectedLocation = client.locations.find(location => location.id === locationId);
        const weekStart = this.initializeWeekdayDates(selectedLocation.address.area.city.timezone);
        await this.fetchSchedules(client.id, weekStart);
        this.setState(
          {
            clientId: client.id,
            clientSearchResults: [client],
            locations: sortBy(client.locations, 'name'),
            locationId,
            positionId,
          },
          () => this.handleRefresh(),
        );
      } catch {
        message.error('Sorry! Client not found, please contact support');
      }
    } else {
      this.initializeWeekdayDates(timezone);
      this.fetchClients();
    }
  }

  getFilterValues = async () => {
    const { client_id, location_id, position_id } = retrieveParamsFromUrl(this.props.location.search);
    let clientId = client_id && Number(client_id);
    let locationId = location_id && Number(location_id);
    let positionId = position_id && Number(position_id);

    // If no URL param, get local storage values if any
    if (!(clientId || locationId || positionId)) {
      if (localStorage.getItem(SHIFT_FILTERS_KEY)) {
        const shiftFilters = JSON.parse(localStorage.getItem(SHIFT_FILTERS_KEY));
        clientId = shiftFilters[CLIENT_KEY];
        locationId = shiftFilters[LOCATION_KEY];
        positionId = shiftFilters[POSITION_KEY];
        // Update URL only when params are complete
        if (clientId && locationId && positionId) {
          updateUrlWithParams(
            { client_id: clientId, location_id: locationId, position_id: positionId },
            this.props.history,
          );
        }
      }
    }
    return { clientId, locationId, positionId };
  };

  initializeWeekdayDates = timezone => {
    const selectedWeekStart = datetimeUtils.getIsoWeekStart(moment(), timezone);
    const weekdayDates = [...Array(7).keys()].map(day => selectedWeekStart.clone().add(day, 'days'));
    this.setState({ selectedWeekStart, weekdayDates, timezone });
    return selectedWeekStart;
  };

  handleParamsChange = async () => {
    const { locationId, positionId, clientId, locations } = this.state;

    if (!locationId || !positionId) return;

    localStorage.setItem(SHIFT_FILTERS_KEY, JSON.stringify({ clientId, locationId, positionId }));

    this.setState({ scheduleLoading: true, assignmentsLoading: true, statisticsLoading: true });

    const selectedLocation = locations.find(location => location.id === locationId);
    const selectedWeekStart = this.initializeWeekdayDates(selectedLocation.address.area.city.timezone);

    const shifts = await this.fetchShifts(selectedWeekStart);
    await this.fetchEmployments();
    this.getStatistics(shifts);
    this.setState({ scheduleLoading: false, assignmentsLoading: false, statisticsLoading: false });

    // To prevent navigate away warning prompt from old publish count, update URL after new shifts are set
    updateUrlWithParams({ client_id: clientId, location_id: locationId, position_id: positionId }, this.props.history);
  };

  handleRefresh = async () => {
    const [shifts] = await Promise.all([this.fetchShifts(this.state.selectedWeekStart), this.fetchEmployments()]);
    this.getStatistics(shifts);
  };

  handleWeekSelect = async isoDate => {
    this.updateSelectDateState(isoDate);

    const shifts = await this.fetchShifts(moment(isoDate));
    this.getStatistics(shifts);
  };

  fetchEmployments = async () => {
    this.setState({ assignmentsLoading: true, statisticsLoading: true });
    const { locationId, positionId } = this.state;

    const params = {
      status: employmentStatuses.ACTIVE,
      location: locationId,
      position: positionId,
      lean: true, // fetch only needed employment data
    };
    const employments = sortBy(await fetchAll(employmentApi.fetchEmployments, params, PAGE_SIZE), 'partner.first_name');
    this.setState({ employments, assignmentsLoading: false, statisticsLoading: false });
  };

  fetchShifts = async selectedWeekStart => {
    this.setState({ scheduleLoading: true });
    const { locationId, positionId } = this.state;
    const startDate = selectedWeekStart;
    const endDate = selectedWeekStart.clone().endOf('isoWeek');

    const params = {
      start_time_after: startDate.toISOString(),
      start_time_before: endDate.toISOString(),
      location: locationId,
      position: positionId,
    };
    const shifts = await fetchAll(shiftApi.fetchShifts, params);
    this.setState({
      shifts,
      originalShifts: shifts,
      scheduleLoading: false,
    });
    return shifts;
  };

  updateSelectDateState = isoDate => {
    const { timezone } = this.state;
    const weekdayDates = [...Array(7).keys()].map(day => moment(isoDate).add(day, 'days'));

    this.setState({
      selectedWeekStart: datetimeUtils.getIsoWeekStart(isoDate, timezone),
      weekdayDates,
    });
    return weekdayDates;
  };

  // Inputs structure: [{targetShiftId, date, workerId }]
  handleAssignmentUpdate = updates => {
    const { shifts, originalShifts } = this.state;
    const draftShifts = cloneDeep(shifts);

    updates.forEach(update => {
      const { targetShiftId, date, workerId } = update;

      const targetShiftIndex = draftShifts.findIndex(shiftItem => shiftItem.id === targetShiftId);

      // Update same day existing attendances to unassigned
      draftShifts.forEach(shift => {
        if (moment(date).isSame(shift.start_time, 'day')) {
          const attendanceIndex = shift.attendances.findIndex(
            attendance => attendance.partner_id === workerId && attendance.status === attendanceStatuses.ASSIGNED,
          );
          if (attendanceIndex >= 0) {
            if (shift.attendances[attendanceIndex].id) {
              shift.attendances[attendanceIndex] = {
                ...shift.attendances[attendanceIndex],
                status: attendanceStatuses.UNASSIGNED,
                confirmed: false,
                change_reason: undefined,
              };
            } else shift.attendances.splice(attendanceIndex, 1);
          }
        }
      });

      // Update or insert attendance on the target shift
      if (targetShiftIndex >= 0) {
        const attendanceIndex = draftShifts[targetShiftIndex].attendances.findIndex(
          attendance => attendance.partner_id === workerId,
        );
        // if attendance already exists in table/shifts
        if (attendanceIndex >= 0) {
          const originalAttendance = originalShifts[targetShiftIndex].attendances.find(
            attendance =>
              attendance.id === draftShifts[targetShiftIndex].attendances[attendanceIndex].id &&
              attendance.status === attendanceStatuses.ASSIGNED,
          );
          // If original attendance exist, revert back to original attendance state
          if (originalAttendance) {
            draftShifts[targetShiftIndex].attendances[attendanceIndex] = {
              ...originalAttendance,
              change_reason: null,
              employment_id: update?.employmentId,
            };
          } else {
            draftShifts[targetShiftIndex].attendances[attendanceIndex] = {
              ...draftShifts[targetShiftIndex].attendances[attendanceIndex],
              status: attendanceStatuses.ASSIGNED,
              confirmed: false,
              change_reason: null,
              employment_id: update?.employmentId,
            };
          }
        } else {
          // if no attendance exist in table/shifts yet, 'init' one
          draftShifts[targetShiftIndex].attendances.push({
            shift_id: targetShiftId,
            partner_id: workerId,
            status: attendanceStatuses.ASSIGNED,
            confirmed: false,
            employment_id: update?.employmentId,
          });
        }
      }
    });
    this.setState({ shifts: draftShifts });
  };

  /***************** Data Mapping Start ***********************/
  /* Generate data structure for assignment table
     Combine employment with shifts and originalShift, populate data structure like below
   assignmentData: [
      {
        id,
        worker: {},
        assignedCount: 0,
        assignments: [{
          date,
          schedule: {id, name},
          originalAttendance: {id, partner_id, shift_id,status, change_reason, confirmed},
          attendance: {id, partner_id, shift_id, status, change_reason, confirmed },
          shifts: {id, schedule: {id, name}},
          contractStatus,
        }]
      }
    ]*/
  generateAssignmentTableData = () => {
    let count = 0;
    const { weekdayDates, employments, originalShifts, shifts, timezone } = this.state;

    const shiftsByDay = groupBy(shifts, shift => {
      return moment(shift.start_time).format(DATE_KEY_FORMAT);
    });

    const originalShiftsByDay = groupBy(originalShifts, shift => {
      return moment(shift.start_time).format(DATE_KEY_FORMAT);
    });

    let availableShiftsPerDay = {};
    for (const date in shiftsByDay) {
      const shifts = shiftsByDay[date];
      const availableShifts = shifts.map(shiftItem => {
        const startTime = moment.tz(shiftItem.start_time, timezone);
        const endTime = datetimeUtils.getEndTimeFromStartTimeAndDuration(startTime, shiftItem.duration);
        return {
          id: shiftItem.id,
          schedule: shiftItem.schedule,
          startTime,
          endTime,
        };
      });

      availableShiftsPerDay = { ...availableShiftsPerDay, [date]: availableShifts };
    }

    const assignments = employments.map(employment => {
      let assignedCount = 0;
      const dataItem = {
        id: employment.partner.id, // For table rowKey
        worker: employment.partner,
        employmentId: employment.id,
        assignments: weekdayDates.map(currentDay => {
          const dateKey = currentDay.format(DATE_KEY_FORMAT);

          const currentDayShifts = shiftsByDay[dateKey] || [];
          const attendance = this.getAttendance(employment.partner.id, currentDayShifts);
          const shift = shifts.find(shiftItem => shiftItem.id === attendance?.shift_id);

          const originCurrentDayShifts = originalShiftsByDay[dateKey] || [];
          const originalAttendance = this.getAttendance(employment.partner.id, originCurrentDayShifts);

          // Statistics, write in this loop to avoid multiple loops when set as derived fields
          assignedCount += attendance?.status === attendanceStatuses.ASSIGNED ? 1 : 0;
          count += isEqual(attendance, originalAttendance) ? 0 : 1;

          return {
            date: currentDay,
            schedule: shift?.schedule,
            attendance,
            originalAttendance,
            shifts: availableShiftsPerDay[dateKey] || [],
            contractStatus: employmentUtils.getDerivedWorkingStatus(currentDay, employment),
          };
        }),
      };
      dataItem.assignedCount = assignedCount;
      return dataItem;
    });

    return { assignments, count };
  };

  getAttendance = (workerId, shifts) => {
    const attendances = shifts.flatMap(shift => shift.attendances);
    return attendances.find(
      attendance => attendance.partner_id === workerId && attendance.status === attendanceStatuses.ASSIGNED,
    );
  };

  /* Copy attendances from week1 to week2
     1. Loop for week2 shifts and find the from shifts in week1 (based on schedule and weekday name)
     2. Loop for all the attendances in the from shift and create updates record list
     3. Update the target week attendances
     TODO Check if issue valid: if worker do not have attendance record in week1 shift1,
        but there is an attendance in the target week2 shift2, the attendance in the week2 shift2 won't be reset to off
  */
  handleCopyShift = async selectedCopyDateStart => {
    const { shifts, employments, timezone } = this.state;
    const fromShifts = cloneDeep(shifts);

    this.updateSelectDateState(selectedCopyDateStart.toISOString());
    const targetShifts = await this.fetchShifts(selectedCopyDateStart);

    this.getStatistics(targetShifts);
    const draftTargetShifts = cloneDeep(targetShifts);

    const updates = draftTargetShifts
      .flatMap(shift => {
        const fromShift = fromShifts.find(
          item =>
            moment(shift.start_time)
              .tz(timezone)
              .format('ddd') ===
              moment(item.start_time)
                .tz(timezone)
                .format('ddd') &&
            item.schedule.id === shift.schedule.id &&
            // only allow copying to shifts starting from today and future
            moment(shift.start_time) > datetimeUtils.getDayStart(moment(), timezone),
        );

        return fromShift?.attendances
          .filter(attendance => {
            const targetShiftDay = datetimeUtils.getDayStart(shift.start_time, timezone);
            const workerEmployment = employments.find(employment => employment.partner.id === attendance.partner_id);
            // skip attendance without a matching active employment
            if (!workerEmployment) return false;
            const contractStatus = employmentUtils.getDerivedWorkingStatus(targetShiftDay, workerEmployment);
            return (
              attendance.status === attendanceStatuses.ASSIGNED &&
              // copy only if the worker is on valid working day
              attendanceUtils.isValidWorkingDay(contractStatus)
            );
          })
          .map(attendance => {
            return {
              targetShiftId: shift.id,
              date: shift.start_time,
              workerId: attendance.partner_id,
              employmentId:
                attendance.employmentId ||
                employments.find(employment => employment.partner.id === attendance.partner_id).id,
            };
          });
      })
      .filter(update => update);

    this.handleAssignmentUpdate(updates);
  };

  getStatistics = shifts => {
    this.setState({ statisticsLoading: true });
    const { employments, timezone } = this.state;
    const dayStart = datetimeUtils.getDayStart(moment(), timezone);

    const attendances = shifts
      .filter(shift => moment(shift.start_time).isSameOrAfter(dayStart))
      .flatMap(shift =>
        shift.attendances.filter(attendance =>
          employments.find(employment => employment.partner.id === attendance.partner_id),
        ),
      );
    const absencesCount = attendances.reduce((count, attendance) => (attendance.absence_reason ? count + 1 : count), 0);

    const unconfirmedAttendances = attendances.filter(
      attendance => attendance.status === attendanceStatuses.ASSIGNED && !attendance.confirmed,
    );
    const confirmedAttendances = attendances.filter(
      attendance => attendance.status === attendanceStatuses.ASSIGNED && attendance.confirmed,
    );
    const unconfirmedAssignmentCount = unconfirmedAttendances.length || 0;
    const confirmedAssignmentCount = confirmedAttendances.length || 0;
    const unconfirmedWorkerList = uniq(unconfirmedAttendances.map(attendance => attendance.partner_id));

    const unfilledShiftCount = shifts.filter(shift => {
      const assignedCount = shift.attendances.filter(att => att.status === attendanceStatuses.ASSIGNED).length;
      return moment(shift.start_time).isSameOrAfter(dayStart) && assignedCount < shift.staff_required;
    }).length;

    this.setState({
      confirmedAssignmentCount,
      unconfirmedAssignmentCount,
      unconfirmedWorkerList,
      absencesCount,
      unfilledShiftCount,
      statisticsLoading: false,
    });
  };

  fetchClients = async (search = undefined) => {
    const { results } = await clientApi.fetchClients({
      country: getCountryCode(),
      status: clientStatuses.ACTIVE,
      search,
    });
    this.setState({ clientSearchResults: results });
  };

  debouncedFetchClients = debounce(this.fetchClients, 600);

  fetchSchedules = async (clientId, weekStart) => {
    this.setState({ locationLoading: true });

    const startDate = weekStart;
    const endDate = weekStart.clone().endOf('isoWeek');

    const allSchedules = await fetchAll(scheduleApi.fetchSchedules, {
      client: clientId,
      start_date_before: endDate.toISOString(),
      end_date_after: startDate.toISOString(),
    });

    this.setState({ allSchedules, locationLoading: false });
  };

  shouldNavigateAway = publishCount => {
    const { t } = this.props;
    if (publishCount > 0) {
      const confirmNavigateAway = window.confirm(t('shiftsNavigateAwayWarning'));
      if (!confirmNavigateAway) {
        return false;
      }
    }
    return true;
  };

  render() {
    const {
      employments,
      shifts,
      originalShifts,
      scheduleLoading,
      assignmentsLoading,
      statisticsLoading,
      weekdayDates,
      selectedWeekStart,
      absencesCount,
      confirmedAssignmentCount,
      unconfirmedAssignmentCount,
      unconfirmedWorkerList,
      unfilledShiftCount,
      clientSearchResults,
      locations,
      clientLoading,
      locationLoading,
      locationId,
      positionId,
      clientId,
      timezone,
      allSchedules,
    } = this.state;
    const { t, positions } = this.props;

    if (positions.length === 0) {
      return <LoadingSpinner />;
    }

    const rate = (confirmedAssignmentCount / (confirmedAssignmentCount + unconfirmedAssignmentCount)) * 100;
    const confirmationRate = rate ? rate.toFixed(0) : 0;

    let assignmentData;
    let publishCount;
    if (employments.length > 0) {
      const { assignments, count } = this.generateAssignmentTableData();
      assignmentData = assignments;
      publishCount = count;
    }

    const schedules = uniqBy(
      shifts.map(shifts => shifts.schedule),
      'id',
    );
    const locationIdsWithSchedule = uniq(allSchedules.map(schedule => schedule.location));
    const locationSchedules = allSchedules.filter(schedule => schedule.location === locationId);
    const positionIdsWithSchedule = uniq(locationSchedules.map(schedule => schedule.position));

    // Prompt warning on close and refresh window if there is new attendance to publish
    window.onbeforeunload = () => (publishCount > 0 ? '' : null);

    return (
      <div style={{ paddingBottom: 50 }}>
        {/* Prompt warning on route change if there is new attendance to publish */}
        <Prompt when={publishCount > 0} message={t('shiftsNavigateAwayWarning')} />
        <Row type="flex" gutter={4}>
          <Col>
            <Title level={2} style={{ paddingRight: 24, display: 'inline' }}>
              {t('shifts')}
            </Title>
          </Col>
          <Col span={6}>
            <Select
              showSearch
              placeholder={t('selectClient')}
              style={{ width: '100%' }}
              loading={clientLoading}
              filterOption={false}
              onSearch={search => {
                this.debouncedFetchClients(search);
              }}
              onChange={async clientId => {
                if (this.shouldNavigateAway(publishCount)) {
                  const client = clientSearchResults.find(({ id }) => id === clientId);
                  await this.fetchSchedules(clientId, selectedWeekStart);
                  this.setState({
                    clientId: client.id,
                    locations: sortBy(client.locations, 'name'),
                    locationId: undefined,
                    shifts: [],
                    originalShifts: [],
                  });
                }
              }}
              value={clientId}
            >
              {clientSearchResults.length > 0 &&
                clientSearchResults.map(({ id, name }) => (
                  <Option key={id} value={id}>
                    {name}
                  </Option>
                ))}
            </Select>
          </Col>
          <Col span={6}>
            <Select
              placeholder={t('selectLocation')}
              style={{ width: '100%' }}
              loading={clientLoading || locationLoading}
              filterOption={false}
              onChange={locationId => {
                this.shouldNavigateAway(publishCount) &&
                  this.setState(
                    {
                      locationId,
                      timezone: locations.find(location => location.id === locationId).address.area.city.timezone,
                    },
                    () => this.handleParamsChange(),
                  );
              }}
              value={locationId}
            >
              <OptGroup label={t('hasSchedules').toUpperCase()}>
                {locations
                  .filter(({ id }) => locationIdsWithSchedule.includes(id))
                  .map(({ id, name }) => (
                    <Option key={id} value={id}>
                      {name}
                    </Option>
                  ))}
              </OptGroup>
              <OptGroup label={t('noSchedules').toUpperCase()}>
                {locations
                  .filter(({ id }) => !locationIdsWithSchedule.includes(id))
                  .map(({ id, name }) => (
                    <Option key={id} value={id}>
                      {name}
                    </Option>
                  ))}
              </OptGroup>
            </Select>
          </Col>
          <Col span={6}>
            <Select
              placeholder={t('selectPosition')}
              style={{ width: '100%' }}
              filterOption={false}
              onChange={positionId =>
                this.shouldNavigateAway(publishCount) && this.setState({ positionId }, () => this.handleParamsChange())
              }
              value={positionId}
            >
              <OptGroup label={t('hasSchedules').toUpperCase()}>
                {positions
                  .filter(({ id }) => positionIdsWithSchedule.includes(id))
                  .map(({ id, name }) => (
                    <Option key={id} value={id}>
                      {name}
                    </Option>
                  ))}
              </OptGroup>
              <OptGroup label={t('noSchedules').toUpperCase()}>
                {positions
                  .filter(({ id }) => !positionIdsWithSchedule.includes(id))
                  .map(({ id, name }) => (
                    <Option key={id} value={id}>
                      {name}
                    </Option>
                  ))}
              </OptGroup>
            </Select>
          </Col>
        </Row>

        <Divider style={{ marginBottom: '48px' }} />
        {positions.length > 0 && clientId && locationId && positionId && timezone ? (
          <>
            {/* SR Summary Card */}
            <div style={{ marginBottom: '48px' }}>
              <ShiftsSummaryCard
                employmentCount={employments.length}
                confirmationRate={confirmationRate}
                loading={statisticsLoading}
                unconfirmedWorkerCount={unconfirmedWorkerList.length}
                absencesCount={absencesCount}
                unfilledShiftCount={unfilledShiftCount}
                position={positions.find(position => position.id === positionId)}
                location={locations.find(location => location.id === locationId)}
                scheduleCount={schedules.length}
              />
            </div>
            {/* Week Selector and Action Buttons */}
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {/* Select value is always in ISO format */}
              {/* Convert it into a moment object when setting selectedWeekStart from the selected value */}
              <WeekSelectGroup
                selectedWeekStart={selectedWeekStart}
                timezone={timezone}
                loading={scheduleLoading}
                onChange={value => this.shouldNavigateAway(publishCount) && this.handleWeekSelect(value)}
              />
              <Button type="v2-primary" disabled={scheduleLoading} onClick={() => {}}>
                {`+ ${t('addSchedule')}`}
              </Button>
            </div>

            {/* Scheduling */}
            <ScheduleListView
              loading={scheduleLoading}
              schedules={schedules}
              shifts={shifts}
              weekdayDates={weekdayDates}
              onScheduleUpdate={this.handleRefresh}
              timezone={timezone}
            />

            {/* Assignment */}
            <AssignmentListView
              employments={employments}
              schedules={schedules}
              shifts={shifts}
              originalShifts={originalShifts}
              weekdayDates={weekdayDates}
              onRefresh={this.handleRefresh}
              onCopy={this.handleCopyShift}
              onUpdate={this.handleAssignmentUpdate}
              loading={assignmentsLoading}
              assignmentData={assignmentData}
              publishCount={publishCount}
              timezone={timezone}
              positionId={positionId}
              locationId={locationId}
              clientId={clientId}
            />
          </>
        ) : (
          <Empty
            image={shiftsEmpty}
            imageStyle={{ height: 200, alignContent: 'center' }}
            description={
              <>
                <Row style={{ marginTop: '32px' }}>
                  <Text style={{ fontSize: 20, fontWeight: 'bold' }}>{t('emptyScheduleTitle')}</Text>
                </Row>
                <Row style={{ marginTop: '8px' }}>
                  <Text>{t('emptyScheduleDescription')}</Text>
                </Row>
              </>
            }
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  positions: state.global.positions,
});

export default withTranslation()(connect(mapStateToProps)(ShiftPage));
