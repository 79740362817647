import React from 'react';
import styled from 'styled-components';
import { staffRequestStatuses, offerStatuses } from '../../constants';
import { humanizeText } from '../../utilities/textUtils';
import { colors } from '../../styles/colors';
import { fontO10b } from '../../styles/fonts';

const getStyle = status => {
  switch (status) {
    case staffRequestStatuses.DRAFT:
    case offerStatuses.NO_SHOW:
      return `
        background: transparent;
        border-color: ${colors.darkGrey};
        color: ${colors.darkGrey};
      `;
    case staffRequestStatuses.PENDING_REVIEW:
    case offerStatuses.PENDING_CONTRACT:
      return `
        background: ${colors.orange};
        border-color: ${colors.orange};
        color: ${colors.white};
      `;
    case staffRequestStatuses.POSTED:
      return `
        background: ${colors.yellow};
        border-color: ${colors.yellow};
        color: ${colors.white};
      `;
    case staffRequestStatuses.IN_PROGRESS:
    case offerStatuses.CONFIRMED:
      return `
        background: ${colors.green};
        border-color: ${colors.green};
        color: ${colors.white};
      `;
    case staffRequestStatuses.ENDED:
    case offerStatuses.REJECTED:
      return `
        background: ${colors.darkGrey};
        border-color: ${colors.darkGrey};
        color: ${colors.white};
      `;
    case staffRequestStatuses.CANCELLED:
      return `
        background: transparent;
        border-color: ${colors.orange};
        color: ${colors.orange};
      `;
    case offerStatuses.APPLIED:
      return `
        background: ${colors.yellow};
        border-color: ${colors.yellow};
        color: ${colors.white};
      `;
    default:
      return '';
  }
};

const CustomLabel = styled.div`
  ${fontO10b};
  border: 2px solid;
  border-radius: 2em;
  ${props => getStyle(props.status)};
  ${props => (props.small ? 'height: 18px' : null)}
  width: ${props => (props.small ? '50px' : '120px')};
  padding: ${props => (props.small ? '0' : '4px 0')};
  text-align: center;
`;

export default ({ status, small, children, ...props }) => (
  <CustomLabel small={small} circular status={status} {...props}>
    {children || humanizeText(status)}
  </CustomLabel>
);
