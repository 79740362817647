import React from 'react';
import CheckboxFilter from '../../../shared/components/CheckboxFilter';
import { paymentReportStatuses } from '../../../../constants';
import textUtils from '../../../utilities/textUtils';

const options = Object.values(paymentReportStatuses).map(paymentReportStatus => ({
  id: paymentReportStatus,
  name: textUtils.makeFriendly(paymentReportStatus),
}));

export default class StatusFilterButton extends React.Component {
  state = {
    selectedOptions: [],
  };

  componentDidMount() {
    this.setState({ selectedOptions: this.props.initialOptions });
  }

  handleChange = values => {
    this.setState({
      selectedOptions: values,
    });
  };

  handleApply = () => {
    this.props.onApply(this.state.selectedOptions);
  };

  render() {
    const { selectedOptions } = this.state;

    return (
      <CheckboxFilter
        showActionButtons
        showSelectAll
        title={`Status ${selectedOptions.length > 0 ? `(${selectedOptions.length})` : ''}`}
        options={options}
        selectedOptions={selectedOptions}
        selectAllTitle="All status"
        onChange={this.handleChange}
        onApply={this.handleApply}
        optionLabelField="name"
      />
    );
  }
}
