import base from './base';
import { endpoints } from '../config';
import getCountryCode from '../utilities/getCountryCode';
import { countryId } from '../constants';

const fetchPositions = async params => {
  const country = getCountryCode() ? `${countryId[getCountryCode()]}` : null;
  const response = await base.api.get(`${endpoints.positions}/`, {
    params: {
      ...params,
      country,
    },
  });
  return response;
};

export default {
  fetchPositions,
};
