import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import routes from '../../routes';
import { colors } from '../../styles/colors';
import { fontO18r } from '../../styles/fonts';
import AvatarImage from './AvatarImage';

const ImageLink = styled(Link)``;

const Name = styled(Link)`
  color: ${colors.black};
`;

const Container = styled.div`
  display: flex;
  align-items: center;

  ${ImageLink} {
    flex-shrink: 0;
  }

  ${Name} {
    ${props => (props.size === 'large' ? fontO18r : '')};
    margin-left: ${props => (props.size === 'large' ? '15px' : '5px')};
  }
`;

export default ({ client, size = 'small', ...props }) => (
  <Container size={size} {...props}>
    <ImageLink to={`${routes.clients}/${client.id}`}>
      <AvatarImage size={size} src={client.logo} name={client.name} />
    </ImageLink>
    <Name to={`${routes.clients}/${client.id}`}>{client.name}</Name>
  </Container>
);
