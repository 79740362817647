import { Button, Dropdown, Menu, Modal, Typography } from 'antd';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import routes from '../../../../routes';
import clientApi from '../../../../services/clientApi';
import { checkAccess } from '../../../../shared/access/Access';
import { permissions } from '../../../../shared/access/accessConfig';
import { colors } from '../../../../styles/colors';
import { clientStatuses } from '../../../../constants';

/* NOTE: modals used here are from v2 components */
import SimpleModal from '../../../shared/components/SimpleModal';

const { Text } = Typography;

export default ({ client, onUpdate }) => {
  // Deactivate client
  const handleDeactivate = async () => {
    Modal.confirm({
      title: 'Deactivate client account',
      content:
        'All the draft, pending review, and posted staff requests will be cancelled. Client will not be able to log in into client dashboard.',
      okType: 'danger',
      onOk: () => clientApi.deactivateClient(client).then(onUpdate),
    });
  };

  // Reactivate client
  const [showActivateModal, setShowActivateModal] = useState(false);
  const handleActivate = async () => {
    await clientApi.reactivateClient(client);
    onUpdate();
    setShowActivateModal(false);
  };

  // Suspend client
  const handleSuspend = () => {
    Modal.confirm({
      title: 'Suspend client account',
      content:
        'All the draft, pending review, and posted staff requests will be cancelled. Client will not be able to log in into client dashboard.',
      okType: 'danger',
      onOk: () => clientApi.suspendClient(client).then(onUpdate),
    });
  };

  // Unsuspend client
  const [showUnsuspendModal, setShowUnsuspendModal] = useState(false);
  const handleUnsuspend = async () => {
    await clientApi.unsuspendClient(client);
    onUpdate();
    setShowUnsuspendModal(false);
  };

  // Archive client
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const handleArchive = async () => {
    await clientApi.archiveClient(client);
    onUpdate();
    setShowArchiveModal(false);
  };

  // Unarchive client
  const [showUnarchiveModal, setShowUnarchiveModal] = useState(false);
  const handleUnarchive = async () => {
    await clientApi.unarchiveClient(client);
    onUpdate();
    setShowUnarchiveModal(false);
  };

  const menu = () => (
    <Menu>
      <Menu.Item disabled={!checkAccess(permissions.editClient)}>
        <Link to={routes.editClient.replace(':id', client.id)}>Edit profile</Link>
      </Menu.Item>
      {(client.verified === false ||
        client.status === clientStatuses.PILOT ||
        client.status === clientStatuses.ACTIVE) &&
        client.status !== clientStatuses.DEACTIVATED && (
          <Menu.Item
            style={{ color: colors.red }}
            onClick={handleDeactivate}
            disabled={!checkAccess(permissions.deactivateClient, null)}
          >
            Deactivate client
          </Menu.Item>
        )}
      {client.status === clientStatuses.DEACTIVATED && (
        <Menu.Item
          onClick={() => setShowActivateModal(true)}
          disabled={!checkAccess(permissions.reactivateClient, null)}
        >
          Reactivate client
        </Menu.Item>
      )}
      {/* can suspend clients who are active only */}
      {client.verified && client.status === clientStatuses.ACTIVE && (
        <Menu.Item
          style={{ color: colors.red }}
          onClick={handleSuspend}
          disabled={!checkAccess(permissions.suspendClient, null)}
        >
          Suspend client
        </Menu.Item>
      )}
      {client.status === clientStatuses.SUSPENDED && (
        <Menu.Item
          onClick={() => setShowUnsuspendModal(true)}
          disabled={!checkAccess(permissions.unsuspendClient, null)}
        >
          Unsuspend client
        </Menu.Item>
      )}
      {/* can archive suspended clients only */}
      {client.status === clientStatuses.SUSPENDED && (
        <Menu.Item
          style={{ color: colors.red }}
          onClick={() => setShowArchiveModal(true)}
          disabled={!checkAccess(permissions.archiveClient, null)}
        >
          Archive client
        </Menu.Item>
      )}
      {client.status === clientStatuses.ARCHIVED && (
        <Menu.Item
          onClick={() => setShowUnarchiveModal(true)}
          disabled={!checkAccess(permissions.unarchiveClient, null)}
        >
          Restore archived client
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <>
      <Dropdown trigger={['click']} overlay={menu}>
        <Button icon="ellipsis" />
      </Dropdown>
      {client.status === clientStatuses.DEACTIVATED && (
        <SimpleModal
          title="Reactivate client account"
          body={
            <Text>
              Are you sure you want to Reactivate <Text strong>{client.name}</Text>? This client's account will be set
              back to active once they are approved again.
            </Text>
          }
          visible={showActivateModal}
          onOk={handleActivate}
          okText="Reactivate"
          onCancel={() => setShowActivateModal(false)}
        />
      )}
      {client.status === clientStatuses.SUSPENDED && (
        <SimpleModal
          title="Unsuspend client account"
          body={
            <Text>
              Are you sure you want to unsuspend <Text strong>{client.name}</Text>? This client's account will be set to
              active.
            </Text>
          }
          visible={showUnsuspendModal}
          onOk={handleUnsuspend}
          okText="Unsuspend"
          onCancel={() => setShowUnsuspendModal(false)}
        />
      )}
      {client.status === clientStatuses.SUSPENDED && (
        <SimpleModal
          title=" Archive client account"
          body={
            <Text>
              Are you sure you want to archive <Text strong>{client.name}</Text>? All the draft, pending review, and
              posted staff requests will be cancelled. Client will not be able to log in into client dashboard.
            </Text>
          }
          visible={showArchiveModal}
          onOk={handleArchive}
          okType="danger"
          okText="Archive"
          onCancel={() => setShowArchiveModal(false)}
        />
      )}
      {client.status === clientStatuses.ARCHIVED && (
        <SimpleModal
          title="Restore archived client"
          body={
            <Text>
              Are you sure you want to restore <Text strong>{client.name}</Text>? This client's account will be restored
              to its original status.
            </Text>
          }
          visible={showUnarchiveModal}
          onOk={handleUnarchive}
          okText="Restore"
          onCancel={() => setShowUnarchiveModal(false)}
        />
      )}
    </>
  );
};
