import React from 'react';
import styled from 'styled-components';
import { rgba } from 'polished';
import Label from './Label';
import { timesheetEntryStatuses, leaveAndSickStatuses } from '../../constants';
import { colors } from '../../styles/colors';
import { humanizeText } from '../../utilities/textUtils';

function getStatusStyle(status) {
  switch (status) {
    case timesheetEntryStatuses.CLOCKED_IN:
      return `
        background: ${rgba(colors.blue, 0.25)};
        color: ${colors.blue};
        border-color: ${rgba(colors.blue, 0.25)};
      `;
    case timesheetEntryStatuses.CLOCKED_OUT:
    case timesheetEntryStatuses.RESOLVED:
      return `
        background: ${rgba(colors.yellow, 0.2)};
        color: ${colors.yellow};
        border-color: ${rgba(colors.yellow, 0.2)};
      `;
    case timesheetEntryStatuses.APPROVED:
      return `
        background: ${rgba(colors.green, 0.2)};
        color: ${colors.green};
        border-color: ${rgba(colors.green, 0.2)};
      `;
    case timesheetEntryStatuses.DISPUTED:
      return `
        background: ${rgba(colors.orange, 0.25)};
        color: ${colors.orange};
        border-color: ${rgba(colors.orange, 0.25)};
      `;
    case timesheetEntryStatuses.VOID:
      return `
        background: ${colors.white};
        color: ${colors.darkGrey};
        border-color: ${colors.darkGrey};
      `;
    case leaveAndSickStatuses.REQUESTED:
      return `
        background: ${rgba(colors.yellow, 0.2)};
        color: ${colors.yellow};
        border-color: ${rgba(colors.yellow, 0.2)};
      `;
    case leaveAndSickStatuses.APPROVED:
      return `
        background: ${rgba(colors.green, 0.2)};
        color: ${colors.green};
        border-color: ${rgba(colors.green, 0.2)};
      `;
    case leaveAndSickStatuses.REJECTED:
      return `
        background: ${colors.white};
        color: ${colors.darkGrey};
        border-color: ${colors.darkGrey};
      `;
    default:
      return `
        background: ${colors.darkGrey};
        color: ${colors.white};
        border-color: ${colors.darkGrey};
      `;
  }
}

const CustomLabel = styled(Label)`
  ${props => getStatusStyle(props.status)};
`;

export default ({ status, tiny, children, ...props }) => (
  <CustomLabel status={status} {...props}>
    {children || (status && humanizeText(status))}
  </CustomLabel>
);
