import _ from 'lodash';

const makeFriendly = string => {
  const lowerCaseString = string.replace('_', ' ').toLowerCase();
  return _.startCase(lowerCaseString);
};

// TODO name confusing - needs renaming
export const humanizeText = string => {
  if (!string || string.length === 0) {
    return '';
  }
  return _.startCase(string.replace('_', ' '));
};

export const capitalizeWord = word => {
  if (!word || word.length === 0) {
    return '';
  }
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export default {
  makeFriendly,
  humanizeText,
  capitalizeWord,
};
