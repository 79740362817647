import base from './base';
import { endpoints } from '../config';
import getCountryCode from '../utilities/getCountryCode';
import getPageFromURL from '../utilities/getPageFromURL';

const fetchClients = async params => {
  const response = await base.api.get(`${endpoints.clients}/`, {
    params: {
      ...params,
      country: getCountryCode(),
    },
  });
  return {
    nextPage: getPageFromURL(response.next),
    ...response,
  };
};

const fetchClientById = async id => {
  const response = await base.api.get(`${endpoints.clients}/${id}/`);
  return response;
};

const fetchLocationsByClientId = async (id, page, { search }) => {
  const response = await base.api.get(`${endpoints.clients}/${id}/locations/`, {
    params: {
      search,
      page: page || 1,
    },
  });
  return {
    nextPage: getPageFromURL(response.next),
    ...response,
  };
};

const createLocation = async (clientId, location) => {
  const response = await base.api.post(`${endpoints.clients}/${clientId}/locations/`, location);
  return response;
};

const editLocation = async (clientId, location) => {
  const response = await base.api.put(`${endpoints.clients}/${clientId}/locations/${location.id}/`, location);
  return response;
};

const createClient = async client => {
  const response = await base.api.post(`${endpoints.clients}/`, client);
  return response;
};

const editClient = async client => {
  const response = await base.api.patch(`${endpoints.clients}/${client.id}/`, client);
  return response;
};

const uploadLogo = async (clientId, logo) => {
  const formData = new FormData();
  formData.append('logo', logo);
  const response = await base.api.put(`${endpoints.clients}/${clientId}/upload-logo/`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response;
};

const approveClient = async client => {
  const response = await base.api.post(`${endpoints.clients}/${client.id}/approve/`);
  return response;
};

const suspendClient = async client => {
  const response = await base.api.post(`${endpoints.clients}/${client.id}/suspend/`);
  return response;
};

const unsuspendClient = async client => {
  const response = await base.api.post(`${endpoints.clients}/${client.id}/unsuspend/`);
  return response;
};

const archiveClient = async client => {
  const response = await base.api.post(`${endpoints.clients}/${client.id}/archive/`);
  return response;
};

const unarchiveClient = async client => {
  const response = await base.api.post(`${endpoints.clients}/${client.id}/unarchive/`);
  return response;
};

const deactivateClient = async client => {
  const response = await base.api.post(`${endpoints.clients}/${client.id}/deactivate/`);
  return response;
};

const reactivateClient = async client => {
  const response = await base.api.post(`${endpoints.clients}/${client.id}/reactivate/`);
  return response;
};

export default {
  fetchClients,
  fetchClientById,
  fetchLocationsByClientId,
  createLocation,
  editLocation,
  createClient,
  editClient,
  approveClient,
  suspendClient,
  unsuspendClient,
  archiveClient,
  unarchiveClient,
  uploadLogo,
  deactivateClient,
  reactivateClient,
};
