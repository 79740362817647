import { Col, Row } from 'antd';
import React from 'react';

export default ({ primary, secondary }) => (
  <Row gutter={16} style={{ maxWidth: 1280, margin: '0 auto' }}>
    <Col style={{ marginBottom: 16 }} lg={18}>
      {primary}
    </Col>
    <Col lg={6}>{secondary}</Col>
  </Row>
);
