import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import numeral from 'numeral';
import { Table, Typography, Tooltip, Icon, Row, Col, Avatar } from 'antd';
import routes from '../../../../routes';
import { DATE_FORMAT } from '../../../../constants';
import EntityLink from '../../../../shared/components/EntityLink';
import StatusTag from '../../../shared/components/StatusTag';
import getPaginationConfig from '../../../utilities/getPaginationConfig';

const { Text } = Typography;

const BillingReportList = ({ billingReports, loading, count, page, onPageChange }) => {
  const columns = [
    {
      title: 'Report',
      dataIndex: 'id',
      render: id => <EntityLink to={`${routes.billingReports}/${id}`}>{id}</EntityLink>,
    },
    {
      width: 250,
      title: 'Client',
      dataIndex: 'client',
      render: client => (
        <>
          <Row gutter={8}>
            <Link to={routes.clientsDetail.replace(':id', client.id)}>
              <Col span={6}>
                <Avatar shape="square" size="large" src={client.logo} />
              </Col>
              <Text strong>
                {client.name} <br />
                <Text type="secondary">#{client.id}</Text>
              </Text>
            </Link>
          </Row>
        </>
      ),
    },
    {
      width: 250,
      title: () => (
        <>
          <Text>Period</Text>
          <Tooltip title="Each billing report contains billing items within the specified date period">
            <Icon
              type="info-circle"
              style={{ fontSize: '18px', verticalAlign: 'middle', cursor: 'auto', color: '#108EE9', marginLeft: '5px' }}
              theme="filled"
            />
          </Tooltip>
        </>
      ),
      render: (_, record) => {
        return (
          <Text style={{ fontSize: '14px' }}>
            {moment(record.start_date).format(DATE_FORMAT)}
            <Text type="secondary"> - </Text>
            {moment(record.end_date).format(DATE_FORMAT)}
          </Text>
        );
      },
    },
    { title: 'Items', dataIndex: 'items_count' },
    {
      title: () => (
        <>
          <Text>Amount</Text>
          <Tooltip title="Total amount includes total wages approved, service fees, and VAT">
            <Icon
              type="info-circle"
              style={{ fontSize: '18px', verticalAlign: 'middle', cursor: 'auto', color: '#108EE9', marginLeft: '5px' }}
              theme="filled"
            />
          </Tooltip>
        </>
      ),
      dataIndex: 'billing_amount',
      render: billing_amount => numeral(billing_amount).format('0,0.00'),
    },
    { title: 'Status', dataIndex: 'status', render: status => <StatusTag status={status} /> },
  ];

  return (
    <Table
      columns={columns}
      dataSource={billingReports}
      loading={loading}
      pageSize="20"
      pagination={{ ...getPaginationConfig(count, onPageChange), current: page }}
    />
  );
};

export default BillingReportList;
