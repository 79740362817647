import React from 'react';
import styled from 'styled-components';
import { Icon, Popup } from 'semantic-ui-react';
import IconButton from '../../../shared/components/IconButton';
import { timesheetEntryStatuses } from '../../../constants';
import timesheetEntryApi from '../../../services/timesheetEntryApi';
import { colors } from '../../../styles/colors';
import DisputeTimesheetEntryModal from './modals/DisputeTimesheetEntryModal';
import ClickableText from '../../../shared/components/ClickableText';
import EditTimesheetEntryModal from './modals/EditTimesheetEntryModal';
import VoidTimesheetEntryModal from './modals/VoidTimesheetEntryModal';
import ForcedClockOutModal from './modals/ForcedClockOutModal';
import { permissions } from '../../../shared/access/accessConfig';
import { checkAccess } from '../../../shared/access/Access';

const CustomIconButton = styled(IconButton)``;

const Container = styled.div`
  margin: 0 -5px;
  > *,
  > ${CustomIconButton} {
    margin: 0 5px;
    display: inline-block;

    &:last-child {
      border: none;
    }
  }
`;

export default class TimesheetEntryActions extends React.Component {
  state = {
    loading: false,
  };

  handleApprove = id => {
    this.setState({ loading: true });
    timesheetEntryApi.approve(id).then(() => {
      this.setState({ loading: false });
      this.props.onUpdate();
    });
  };

  handleResolve = id => {
    this.setState({ loading: true });
    timesheetEntryApi.resolve(id).then(() => {
      this.setState({ loading: false });
      this.props.onUpdate();
    });
  };

  render() {
    const { entry, onUpdate, ...props } = this.props;

    if (this.state.loading) {
      return (
        <Container {...props}>
          <Icon loading name="spinner" />
        </Container>
      );
    }

    return (
      <Container {...props}>
        {[timesheetEntryStatuses.CLOCKED_OUT, timesheetEntryStatuses.RESOLVED].includes(entry.status) && (
          <>
            <Popup
              basic
              hideOnScroll
              content="Approve"
              trigger={
                <CustomIconButton
                  type="check-circle"
                  style={{ color: colors.green }}
                  onClick={() => {
                    this.handleApprove(entry.id);
                  }}
                />
              }
            />
            <Popup
              basic
              hideOnScroll
              content="Dispute"
              trigger={
                <CustomIconButton
                  type="exclamation-circle"
                  onClick={() => this.setState({ disputeModal: true })}
                  style={{ color: colors.orange }}
                />
              }
            />
            <DisputeTimesheetEntryModal
              entry={entry}
              open={this.state.disputeModal}
              onClose={() => this.setState({ disputeModal: null })}
              onUpdate={onUpdate}
            />
          </>
        )}
        {entry.status === timesheetEntryStatuses.DISPUTED && (
          <>
            <ClickableText onClick={() => this.setState({ editModal: true })}>Edit</ClickableText>
            <EditTimesheetEntryModal
              entry={entry}
              open={this.state.editModal}
              onClose={() => this.setState({ editModal: false })}
              onUpdate={onUpdate}
            />
            {checkAccess(permissions.resolveTimesheet) && (
              <ClickableText
                onClick={() => {
                  this.handleResolve(entry.id);
                }}
              >
                Resolve
              </ClickableText>
            )}
            <ClickableText
              onClick={() => {
                this.setState({ voidModal: true });
              }}
            >
              Void
            </ClickableText>
            <VoidTimesheetEntryModal
              entry={entry}
              open={this.state.voidModal}
              onClose={() => this.setState({ voidModal: false })}
              onUpdate={onUpdate}
            />
          </>
        )}
        {entry.status === timesheetEntryStatuses.CLOCKED_IN && (
          <>
            <Popup
              basic
              hideOnScroll
              content="Clock Out"
              trigger={
                <CustomIconButton
                  type="clock-circle"
                  style={{ color: colors.darkGrey }}
                  onClick={() => {
                    this.setState({ manualClockOut: true });
                  }}
                />
              }
            />
            <ForcedClockOutModal
              entry={entry}
              open={this.state.manualClockOut}
              onClose={() => this.setState({ manualClockOut: false })}
              onUpdate={onUpdate}
            />
          </>
        )}
      </Container>
    );
  }
}
