import React from 'react';
import { withTranslation } from 'react-i18next';
import { Card, Row, Col, Typography, Modal, Avatar, Icon, Tag } from 'antd';
import { SafetyCertificateFilled } from '@ant-design/icons';
import { colors } from '../../../../styles/colors';

const { Text } = Typography;

class VaccinationDetailCard extends React.Component {
  state = {
    imageModalVisible: false,
  };

  render() {
    const { covidCertificationImage, t } = this.props;
    const { imageModalVisible } = this.state;
    const emptyState = (
      <Text type="secondary" style={{ color: '#BFBFBF' }}>
        Not provided
      </Text>
    );
    return (
      <Card
        className="detail-card"
        title={
          <Text type="secondary" style={{ fontSize: '14px' }}>
            {t('vaccinationStatus').toUpperCase()}
          </Text>
        }
        headStyle={{ padding: '8px 0 8px 12px', minHeight: 0 }}
        bodyStyle={{ padding: '12px' }}
        style={{ width: '373px' }}
      >
        <Row style={{ marginBottom: '16px' }}>
          <Col>
            <Tag color={colors.infoBackgroundBlue} style={{ padding: '1px 8px', marginRight: 0 }}>
              <SafetyCertificateFilled style={{ color: colors.infoHighlightBlue, marginRight: 8 }} />
              <Typography.Text style={{ color: colors.infoHighlightBlue }}>
                {t('workerTagCovidVaccinated')}
              </Typography.Text>
            </Tag>
          </Col>
        </Row>
        <Row>
          <Col style={{ marginBottom: '4px' }}>
            <Text strong>{t('vaccinationCertification')}</Text>
          </Col>
          <Col>
            {covidCertificationImage ? (
              <Avatar
                shape="square"
                size={128}
                icon="user"
                src={covidCertificationImage}
                style={{ padding: '8px', border: '1px solid #D9D9D9' }}
                onClick={() =>
                  this.setState({
                    imageModalVisible: true,
                  })
                }
              />
            ) : (
              <Row
                type="flex"
                align="middle"
                style={{
                  width: '128px',
                  height: '128px',
                  background: '#F5F5F5',
                  border: '1px dashed #D9D9D9',
                  borderRadius: '4px',
                  justifyContent: 'center',
                }}
              >
                <Col>
                  <Row style={{ textAlign: 'center' }}>
                    <Icon type="inbox" style={{ fontSize: '24px', color: '#8C8C8C' }} />
                  </Row>
                  <Row>{emptyState}</Row>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
        <Modal visible={imageModalVisible} footer={null} onCancel={() => this.setState({ imageModalVisible: false })}>
          <img alt="Covid Vaccination Certificate" style={{ width: '100%' }} src={covidCertificationImage} />
        </Modal>
      </Card>
    );
  }
}

export default withTranslation()(VaccinationDetailCard);
