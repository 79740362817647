export const fetchAll = async (requestPromise, params, pageSize = 50) => {
  const response = await requestPromise({
    page_size: pageSize,
    page: 1,
    ...params,
  });
  let items = response.results;
  const totalPages = Math.ceil(response.count / pageSize);

  if (totalPages > 1) {
    const requests = [];
    for (let i = 2; i <= totalPages; i++) {
      requests.push(
        requestPromise({
          page_size: pageSize,
          ...params,
          page: i,
        }),
      );
    }

    const responses = await Promise.all(requests);
    items = [...items, ...responses.flatMap(response => response.results)];
  }
  return items;
};
