import { Button, Col, Row, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import routes from '../../../routes';

import staffRequestApi from '../../../services/staffRequestApi';
import { checkAccess } from '../../../shared/access/Access';
import { permissions } from '../../../shared/access/accessConfig';
import { retrieveParamsFromUrl, updateUrlWithParams } from '../../../utilities/urlUtils';
import CardWithCount from '../../shared/components/CardWithCount';
import getPaginationConfig from '../../utilities/getPaginationConfig';
import StaffRequestList from './components/StaffRequestList';
import FiltersContainer from './containers/FiltersContainer';

const { Title } = Typography;
class StaffRequestListView extends React.Component {
  state = {
    loading: false,
    staffRequests: undefined,
    numRecords: 0, // Total SR with current search params - for pagination
    numTotal: 0, // Total SR count in database
    numPending: 0,
    numPosted: 0,
    numInProgress: 0,
    searchParams: {
      status: [],
      clients: [],
      positions: [],
      ordering: '-created_date',
    },
    page: 1,
  };

  // TODO: [Performance] Fetching client is very bloated & slow. We only need client.id & client.name
  async componentDidMount() {
    // retrieve params from url
    await this.updateStateFromUrl();
    try {
      const staffRequestResponse = await this.fetchStaffRequestWithParams(true);

      const { status_counts, results, count } = staffRequestResponse;
      this.setState({
        staffRequests: results,
        numTotal: count,
        numRecords: count,
        numPending: status_counts.pending_review,
        numPosted: status_counts.posted,
        numInProgress: status_counts.in_progress,
        loading: false,
      });
    } catch (error) {
      await this.setState({ page: 1 });

      const staffRequestResponse = await this.fetchStaffRequestWithParams(true);

      const { status_counts, results, count } = staffRequestResponse;
      this.setState({
        staffRequests: results,
        numTotal: count,
        numRecords: count,
        numPending: status_counts.pending_review,
        numPosted: status_counts.posted,
        numInProgress: status_counts.in_progress,
        loading: false,
      });

      this.updateUrl();
    }
  }

  getStatusCounts = () => {
    const { numTotal, numPending, numPosted, numInProgress } = this.state;
    return [
      { title: 'Total Srs', num: numTotal, hasBadge: false },
      { title: 'Pending Review', num: numPending, hasBadge: numPending > 0 },
      { title: 'Posted', num: numPosted, hasBadge: false },
      { title: 'In Progress', num: numInProgress, hasBadge: false },
    ];
  };

  fetchStaffRequestWithParams = async (include_counts = false) => {
    this.setState({ loading: true });

    const {
      searchParams: { search, status, clients, positions, ordering },
      page,
    } = this.state;

    const params = {
      page,
      status: status.join(','),
      client: clients.join(','),
      position: positions.join(','),
      search,
      ordering,
    };

    if (include_counts) {
      params.include_counts = true;
    }

    const response = await staffRequestApi.fetchStaffRequestSummarysWithTasks(params);
    this.setState({
      loading: false,
      staffRequests: response.results,
      numRecords: response.count,
    });

    return response;
  };

  handleFiltersChange = async filters => {
    await this.setState({ searchParams: filters, page: 1 });
    this.updateUrl();
    this.fetchStaffRequestWithParams();
  };

  onPageChange = async pageNum => {
    await this.setState(
      {
        page: pageNum,
      },
      this.fetchStaffRequestWithParams,
    );
    this.updateUrl();
  };

  updateStateFromUrl = async () => {
    const { status, search, client, position, ordering, page = 1 } = await retrieveParamsFromUrl(
      this.props.location.search,
    );
    await this.setState(prevState => {
      const searchParams = {
        search,
        ordering: ordering || prevState.searchParams.ordering,
        status: (status && status.split(',')) || [],
        clients: (client && client.split(',')) || [],
        positions: (position && position.split(',')) || [],
      };
      return {
        searchParams,
        page: parseInt(page, 10),
      };
    });
  };

  updateUrl() {
    const {
      page,
      searchParams: { search, status, clients, positions, ordering },
    } = this.state;
    updateUrlWithParams(
      {
        page,
        search,
        ordering,
        status: (status && status.join(',')) || undefined,
        client: (clients && clients.join(',')) || undefined,
        position: (positions && positions.join(',')) || undefined,
      },
      this.props.history,
    );
  }

  render() {
    const { page, numRecords, staffRequests, searchParams, loading } = this.state;
    const statusCounts = this.getStatusCounts();

    return (
      <Row type="flex" gutter={50} style={{ marginBottom: '86px' }}>
        <Col span={24}>
          {/* Header & Create button */}
          <Row type="flex" justify="space-between" style={{ marginBottom: '32px' }}>
            <Col>
              <Title level={3}>Staff requests</Title>
            </Col>
            <Col>
              {checkAccess(permissions.createStaffRequest) && (
                <Link to={routes.createStaffRequest}>
                  <Button type="v2-primary" icon="plus">
                    Create staff request
                  </Button>
                </Link>
              )}
            </Col>
          </Row>

          {/* Count card */}
          <CardWithCount loading={!staffRequests} countList={statusCounts} />

          {/* Filters, search & order by*/}
          <FiltersContainer filters={searchParams} onFiltersChange={this.handleFiltersChange} />

          {/* Actual List */}
          <StaffRequestList
            pagination={{
              ...getPaginationConfig(numRecords, this.onPageChange),
              current: page || 1,
            }}
            staffRequests={staffRequests}
            loading={loading}
          />
        </Col>
      </Row>
    );
  }
}

export default StaffRequestListView;
