import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'react-fast-compare';

export default class ListContainer extends React.Component {
  static propTypes = {
    fetchItems: PropTypes.func.isRequired,
    render: PropTypes.func.isRequired,
    initialParams: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      items: null,
      isFetching: false,
      params: props.initialParams || {},
    };

    this.actions = {
      receiveItems: items =>
        this.setState(prevState => ({
          items: (prevState.items || []).concat(items),
        })),
      setFetching: isFetching => this.setState({ isFetching }),
      setParams: (params, callback) => {
        this.setState(
          prevState => ({
            params: {
              ...prevState.params,
              ...params,
            },
          }),
          callback,
        );
      },
    };
  }

  componentDidMount() {
    this.fetchItems();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.enableReinitialize && !isEqual(prevProps.initialParams, this.props.initialParams)) {
      this.setState(
        {
          isFetching: false,
          params: this.props.initialParams,
          items: null,
        },
        this.fetchItems,
      );
    }
  }

  fetchItems = () => {
    this.props.fetchItems(
      {
        ...this.state.params,
      },
      this.actions,
    );
  };

  render() {
    return this.props.render({
      items: this.state.items,
      params: this.state.params,
      setParams: this.actions.setParams,
      handleFetch: () => {
        if (!this.state.isFetching) {
          this.fetchItems();
        }
      },
      reset: () => this.setState({ params: this.props.initialParams, items: null }),
      isFetching: this.state.isFetching,
    });
  }
}
