import React from 'react';
import { Typography, Table, Row, Col, Avatar } from 'antd';
import { Link } from 'react-router-dom';
import { timesheetEntryStatuses } from '../../../../constants';
import getPaginationConfig from '../../../utilities/getPaginationConfig';
import timesheetUtils from '../../../utilities/timesheetUtils';

import DropdownButtons from '../../../shared/components/DropdownButtons';
import StatusTag from '../../../shared/components/StatusTag';
import EditTimesheetFormModal from '../../../shared/components/EditTimesheetFormModal';
import ApproveTimesheetEntryModal from '../../../shared/components/ApproveTimesheetEntryModal';
import ToolTipWithInfo from '../../../shared/components/ToolTipWithInfo';
import routes from '../../../../routes';
import { checkAccess } from '../../../../shared/access/Access';
import { permissions } from '../../../../shared/access/accessConfig';
import VoidTimesheetEntryModal from '../../../shared/components/VoidTimesheetEntryModal';
import ConfirmTimesheetEntryModal from '../../../shared/components/ConfirmTimesheetEntryModal';
import { colors } from '../../../../../src/styles/colors';

const { Text } = Typography;

// Some rows on the table are not allowed to be selected since there are no actions to perform
const unselectableTimesheetStatuses = [
  timesheetEntryStatuses.VOID,
  timesheetEntryStatuses.DISPUTED,
  timesheetEntryStatuses.APPROVED,
  timesheetEntryStatuses.CLOCKED_IN,
];
const actionableTimesheetStatuses = [
  timesheetEntryStatuses.CLOCKED_OUT,
  timesheetEntryStatuses.RESOLVED,
  timesheetEntryStatuses.DISPUTED,
  timesheetEntryStatuses.APPROVED,
];

// TODO P1 This is duplicated with the TimesheetsPage TimesheetList. We should refactor
class TimesheetList extends React.Component {
  state = {
    currentTimesheetEntry: {},
    showEditModal: false,
    showVoidModal: false,
    showConfirmModal: false,
    showApproveModal: false,
  };

  columns = [
    {
      width: 250,
      title: 'Name',
      dataIndex: 'partner',
      render: partner => (
        <Row gutter={8}>
          <Link to={routes.partnerDetail.replace(':id', partner?.id)}>
            <Col span={8}>
              <Avatar shape="square" size="large" src={partner?.image} />
            </Col>
            <Text style={{ fontSize: '14px' }}>
              {`${partner?.first_name} ${partner?.last_name}`} <br />
              <Text type="secondary">#{partner?.id}</Text>
            </Text>
          </Link>
        </Row>
      ),
    },
    {
      width: 200,
      title: 'Timesheet',
      dataIndex: 'created_date',
      render: (_, record) => {
        const { start, end, differenceInHours } = timesheetUtils.getFriendlyClockInOutString(
          record.clock_in_time,
          record.clock_out_time,
        );
        return (
          <Text style={{ fontSize: '14px' }}>
            {start}
            <Text type="secondary"> ~ </Text>
            {end}
            <Text type="secondary">{record.clock_out_time ? ` (${differenceInHours}h)` : ''}</Text>
            <br />
            <Text type="secondary">Entry #{record.id}</Text>
          </Text>
        );
      },
    },
    {
      width: 200,
      title: 'Status',
      dataIndex: 'status',
      render: (text, record) => {
        return (
          <Row align="middle">
            <StatusTag
              status={text}
              {...(record.status === timesheetEntryStatuses.RESOLVED ||
                (record.status === timesheetEntryStatuses.CLOCKED_OUT &&
                  record.supervisor_confirmed === true && {
                    confirmedClockedOut: true,
                  }))}
            />
            {((record.status === timesheetEntryStatuses.DISPUTED && record.disputed_reason) ||
              (record.status === timesheetEntryStatuses.VOID && record.voided_reason) ||
              record.notes) && (
              <ToolTipWithInfo
                infoText={
                  (record.status === timesheetEntryStatuses.DISPUTED &&
                    record.disputed_reason &&
                    record.disputed_reason.name) ||
                  (record.status === timesheetEntryStatuses.VOID &&
                    record.voided_reason &&
                    record.voided_reason.name) ||
                  record.notes
                }
              />
            )}
          </Row>
        );
      },
    },
    {
      key: 'action',
      dataIndex: 'status',
      render: (text, record) => {
        const editButton = {
          title: 'Edit',
          onClick: () => this.setState({ showEditModal: true, currentTimesheetEntry: record }),
        };
        const confirmButton = checkAccess(permissions.resolveTimesheet) && {
          title: 'Confirm',
          onClick: () => this.setState({ showConfirmModal: true, currentTimesheetEntry: record }),
          style: { color: colors.workmateGreen },
        };
        const voidButton = {
          title: 'Void',
          onClick: () => this.setState({ showVoidModal: true, currentTimesheetEntry: record }),
          style: { color: colors.red },
        };
        const approveButton = {
          title: 'Approve',
          onClick: () => this.setState({ showApproveModal: true, currentTimesheetEntry: record }),
          style: { color: colors.functionalApproved },
        };

        let buttons = [];
        if (text === timesheetEntryStatuses.CLOCKED_IN) {
          buttons = [voidButton];
        }
        if (text === timesheetEntryStatuses.CLOCKED_OUT) {
          buttons = [editButton, confirmButton, approveButton, voidButton];
        }
        if (text === timesheetEntryStatuses.CLOCKED_OUT && record.supervisor_confirmed) {
          buttons = [editButton, approveButton, voidButton];
        }
        if (text === timesheetEntryStatuses.DISPUTED) {
          buttons = [editButton, confirmButton, voidButton];
        }

        // Buttons not allowed for access returns false
        const accessibleButtons = buttons.filter(x => x);
        return (
          <>
            {actionableTimesheetStatuses.includes(text) && (
              <Col>
                <DropdownButtons size="small" buttons={accessibleButtons} />
              </Col>
            )}
          </>
        );
      },
    },
  ];

  handleSelectChange = (keys, rows) => {
    this.props.onSelectChange(rows);
  };

  render() {
    const { currentTimesheetEntry, showEditModal, showVoidModal, showConfirmModal, showApproveModal } = this.state;

    const {
      selectedTimesheetEntries,
      loading,
      dataSource,
      onVoid,
      onEdit,
      onConfirm,
      onApprove,
      count,
      onPageChange,
      page,
    } = this.props;

    const selectedRowKeys = selectedTimesheetEntries.map(entry => entry.id);

    return (
      <>
        <Table
          style={{ marginBottom: '100px' }}
          loading={loading}
          columns={this.columns}
          dataSource={dataSource}
          rowKey="id"
          pagination={{
            ...getPaginationConfig(count, onPageChange),
            current: page,
          }}
          rowSelection={{
            selectedRowKeys,
            hideDefaultSelections: true,
            onChange: this.handleSelectChange,
            getCheckboxProps: record => ({
              disabled: unselectableTimesheetStatuses.includes(record.status),
            }),
          }}
        />

        {/* Modals */}
        <ConfirmTimesheetEntryModal
          visible={showConfirmModal}
          onCancel={() => this.setState({ showConfirmModal: false })}
          onOk={updatedTimesheetEntry => {
            onConfirm(updatedTimesheetEntry);
            this.setState({ showConfirmModal: false });
          }}
          timesheet={currentTimesheetEntry}
        />
        <VoidTimesheetEntryModal
          visible={showVoidModal}
          onCancel={() => this.setState({ showVoidModal: false })}
          onOk={() => {
            onVoid(this.state.currentTimesheetEntry);
            this.setState({ showVoidModal: false });
          }}
          timesheet={currentTimesheetEntry}
        />
        <EditTimesheetFormModal
          visible={showEditModal}
          onCancel={() => this.setState({ showEditModal: false })}
          onOk={() => {
            onEdit();
            this.setState({ showEditModal: false });
          }}
          timesheet={currentTimesheetEntry}
        />
        <ApproveTimesheetEntryModal
          visible={showApproveModal}
          onCancel={() => this.setState({ showApproveModal: false })}
          onOk={updatedTimesheetEntry => {
            onApprove(updatedTimesheetEntry);
            this.setState({ showApproveModal: false });
          }}
          timesheet={currentTimesheetEntry}
        />
      </>
    );
  }
}

export default TimesheetList;
