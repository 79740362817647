import React, { Component } from 'react';
import { Row, Col, Divider, Button, Input, Typography, Select } from 'antd';
import CardWithCount from '../../../shared/components/CardWithCount';
import MatchedStatusFilterButton from '../components/MatchedStatusFilterButton';
import SendRecommendationModal from '../components/SendRecommendationModal';
import recommendationApi from '../../../../services/recommendationApi';
import MatchedWorkerList from '../components/MatchedWorkerList';
import { checkAccess } from '../../../../shared/access/Access';
import { permissions } from '../../../../shared/access/accessConfig';

const { Text } = Typography;

class MatchedWorkersContainer extends Component {
  state = {
    loading: false,
    recommendations: [],
    totalCount: 0,
    statusCounts: undefined,
    status: [],
    search: undefined,
    ordering: undefined,
    showSendRecommendationModal: false,
  };

  async componentDidMount() {
    await this.fetchMatchedWorkers();
  }

  fetchMatchedWorkers = async () => {
    const { status, search, ordering, page } = this.state;
    this.setState({ loading: true });
    const response = await recommendationApi.fetchRecommendations({
      staff_request: this.props.staffRequestId,
      search,
      ordering,
      page,
      status: status && status.length > 0 ? status.join(',') : undefined,
      include_status_counts: status && status.length === 0,
      exclude_applied: true,
    });

    const recommendations = response.results;

    this.setState({ recommendations, totalCount: response.count, loading: false });
    if (response.status_counts) {
      this.setState({ statusCounts: response.status_counts });
    }
  };

  getStatusCounts = () => {
    const sent = this.state.statusCounts ? this.state.statusCounts.sent : 0;
    const viewed = this.state.statusCounts ? this.state.statusCounts.viewed : 0;
    return [
      { title: 'Sent', num: sent + viewed, hasBadge: false },
      { title: 'Viewed', num: viewed, hasBadge: false },
    ];
  };

  handleStatusChange = status => {
    this.setState({ status, page: 1 }, () => this.fetchMatchedWorkers());
  };

  handleSearchChange = search => {
    this.setState({ search, page: 1 }, () => this.fetchMatchedWorkers());
  };

  handlePageChange = page => {
    this.setState({ page }, () => this.fetchMatchedWorkers());
  };

  handleCloseSendRecommendationModal = () => {
    this.setState({ showSendRecommendationModal: false });
    this.fetchMatchedWorkers();
  };

  render() {
    const { loading, recommendations, showSendRecommendationModal, page, totalCount } = this.state;
    const { isDirectHire } = this.props;
    return (
      <>
        <CardWithCount loading={loading} countList={this.getStatusCounts()} />

        <Row type="flex" align="bottom" justify="space-between">
          <Col span={3}>
            <MatchedStatusFilterButton onApply={this.handleStatusChange} />
          </Col>
          <Col span={7}>
            <Input.Search allowClear placeholder="Search by Workmate" onSearch={this.handleSearchChange} />
          </Col>
        </Row>
        <Divider />
        <Row type="flex" justify="space-between" style={{ marginBottom: '48px' }}>
          <Col>
            {checkAccess(permissions.editStaffRequest) && (
              <Button
                className="ant-btn-v2-primary"
                onClick={() => {
                  this.setState({ showSendRecommendationModal: true });
                }}
                disabled={isDirectHire}
              >
                Send recommendation
              </Button>
            )}
          </Col>
          <Col style={{ width: '216px' }}>
            <Text type="secondary">Sort by</Text>
            <Select defaultValue="created_date" style={{ marginLeft: '8px', width: '156px' }}>
              <Select.Option value="created_date">Most recent</Select.Option>
            </Select>
          </Col>
        </Row>

        <MatchedWorkerList
          totalCount={totalCount}
          loading={loading}
          onPageChange={this.handlePageChange}
          page={page}
          recommendations={recommendations}
          staffRequestId={this.props.staffRequestId}
          onUpdate={this.fetchMatchedWorkers}
        />

        <SendRecommendationModal
          visible={showSendRecommendationModal}
          staffRequestId={this.props.staffRequestId}
          onCancel={this.handleCloseSendRecommendationModal}
        />
      </>
    );
  }
}

export default MatchedWorkersContainer;
