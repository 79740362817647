import React from 'react';
import { message } from 'antd';
import SelectionWithNotesModal from '../../../shared/components/SelectionWithNotesModal';

import applicationApi from '../../../../services/applicationApi';

export default class ApplicationRejectionModal extends React.Component {
  state = {
    loading: false,
    applicationRejectReasons: [],
  };

  async componentDidMount() {
    const results = await applicationApi.fetchApplicationRejectReasons();
    this.setState({ applicationRejectReasons: results });
  }

  handleReject = async values => {
    const {
      application: { id },
      onReject,
    } = this.props;
    const { option, notes } = values;

    await this.setState({ loading: true });
    try {
      await applicationApi.rejectApplication(id, option, notes);
      onReject(id, option, notes);
    } catch (error) {
      const detail = error?.response?.data?.detail;
      message.error(detail ?? error.message);
    }
    await this.setState({ loading: false });
  };

  render() {
    const { visible, workerName, onClose } = this.props;
    const { loading, applicationRejectReasons } = this.state;

    return (
      <SelectionWithNotesModal
        visible={visible}
        loading={loading}
        modalTitle="Reject Job Application"
        modalPrompt={
          <>
            Are you sure you want to reject <span style={{ fontWeight: 'bold' }}>{workerName}</span>?
          </>
        }
        optionsLabel="Reason for rejecting"
        options={applicationRejectReasons}
        optionValidationRules={[
          {
            required: true,
            message: 'Please select an option',
          },
        ]}
        notesLabel="Additional notes (optional)"
        notesInputPlaceholderText="Please give your reasons and details"
        onOk={this.handleReject}
        okButtonType="danger"
        okButtonLabel="Yes, reject"
        onCancel={onClose}
      />
    );
  }
}
