export const paymentMethods = {
  INVOICE: 'invoice',
  CREDIT: 'credit',
  PREPAID: 'prepaid',
};

export const paymentTypes = {
  BANK_TRANSFER: 'bank_transfer',
  ONLINE_DIRECT_PAYMENT: 'online_direct_payment',
  ONLINE_INVOICE_PAYMENT: 'online_invoice_payment',
};

export const paymentTypeDisplayText = {
  [paymentTypes.BANK_TRANSFER]: 'Bank Transfer (Manually handled by Finance)',
  [paymentTypes.ONLINE_DIRECT_PAYMENT]: 'Credit Card (Automatically billed every Tuesday)',
  [paymentTypes.ONLINE_INVOICE_PAYMENT]: 'Postpaid billing',
};

export const paymentTerms = {
  IMMEDIATE: 'immediate',
  FOURTEEN_DAYS: '14 days',
  FIFTEEN_DAYS: '15 days',
  THIRTY_DAYS: '30 days',
  FOURTY_FIVE_DAYS: '45 days',
  SIXTY_DAYS: '60 days',
};

export const paymentFrequency = {
  WEEKLY: 'weekly',
  BIWEEKLY: 'bi-weekly',
  MONTHLY: 'monthly',
};

export const clientServiceOtherSecurities = {
  DEPOSIT: 'deposit',
  PREPAYMENT: 'prepayment',
};

export const revenueSharingPartners = {
  JACKSON_GRANT: 'JacksonGrant (previous known RLC)',
  CGP: 'CGP',
  TIM_RASENBERGER: 'Tim Rasenberger',
  RLC_MARKUS: 'RLC Markus',
  KINGSMEN_HOSPITALITY: 'Kingsmen Hospitality',
};

export const clientServiceInvoiceRequirements = {
  SOFT: 'soft',
  HARD: 'hard',
  BOTH: 'both',
};

export const billingSchedules = {
  MONTHLY: 'monthly',
  QUARTERLY: 'quarterly',
  ANNUALLY: 'annually',
  WEEKLY: 'weekly',
};

export const countryCodes = {
  INDO: 'ID',
  THAI: 'TH',
};

export const currencyCodes = {
  IDR: 'IDR',
  THB: 'THB',
  SGD: 'SGD',
  USD: 'USD',
  INR: 'INR',
};

export const countries = {
  indonesia: {
    id: 1,
    code: 'ID',
    dialCode: '+62',
    currencyCode: 'IDR',
    name: 'Indonesia',
    languageCode: 'id',
  },
  thailand: {
    id: 2,
    code: 'TH',
    dialCode: '+66',
    currencyCode: 'THB',
    name: 'Thailand',
    languageCode: 'th',
  },
  singapore: {
    id: 3,
    code: 'SG',
    dialCode: '+65',
    currencyCode: 'SGD',
    name: 'Singapore',
    languageCode: 'en',
  },
  india: {
    id: 4,
    code: 'IN',
    dialCode: '+91',
    currencyCode: 'USD',
    name: 'India',
    languageCode: 'hi',
  },
};

export const countryId = {
  [countries.indonesia.code]: countries.indonesia.id,
  [countries.thailand.code]: countries.thailand.id,
  [countries.singapore.code]: countries.singapore.id,
  [countries.india.code]: countries.india.id,
};

export const countryOptions = {
  list: [
    { key: 'all', value: 'all', text: 'All Countries', emoji: '' },
    { key: 'ID', value: 'ID', flag: 'id', text: 'Indonesia', emoji: '🇮🇩' },
    { key: 'TH', value: 'TH', flag: 'th', text: 'Thailand', emoji: '🇹🇭' },
    { key: 'SG', value: 'SG', flag: 'sg', text: 'Singapore', emoji: '🇸🇬' },
    { key: 'IN', value: 'SG', flag: 'in', text: 'India', emoji: '🇮🇳' },
  ],
  default: 'all', // undefined is for all countries
};

export const genderOptions = [
  { value: 'female', label: 'Female' },
  { value: 'male', label: 'Male' },
  { value: 'unknown', label: 'Not provided' },
];

export const channelAudiences = {
  APPLIED_APPLICANTS: 'applied_applicants',
  ACTIVE_EMPLOYEES: 'active_employees',
  SELECTED_APPLICANTS: 'selected_applicants',
};

export const channelBroadcastAudienceLabel = {
  [channelAudiences.APPLIED_APPLICANTS]: 'Applicants',
  [channelAudiences.ACTIVE_EMPLOYEES]: 'Employees',
};

export const recurrenceOptions = [
  { value: 6, label: 'Sun' },
  { value: 0, label: 'Mon' },
  { value: 1, label: 'Tue' },
  { value: 2, label: 'Wed' },
  { value: 3, label: 'Thu' },
  { value: 4, label: 'Fri' },
  { value: 5, label: 'Sat' },
];

export const roles = {
  SUPERVISOR: 'supervisor',
  ADMIN: 'admin',
};

export const environment = {
  DEVELOPMENT: 'development',
  STAGING: 'staging',
  PRODUCTION: 'production',
  DEMO: 'demo',
};

export const staffRequestStatuses = {
  DRAFT: 'draft',
  PENDING_REVIEW: 'pending_review',
  POSTED: 'posted',
  IN_PROGRESS: 'in_progress',
  ENDED: 'ended',
  CANCELLED: 'cancelled',
};

export const offerStatuses = {
  SENT: 'sent',
  VIEWED: 'viewed',
  APPLIED: 'applied',
  PENDING_ONBOARDING: 'pending_onboarding',
  PENDING_CONTRACT: 'pending_contract',
  CONFIRMED: 'confirmed',
  REJECTED: 'rejected',
  CANCELLED: 'cancelled',
  NO_SHOW: 'no_show',
  WITHDRAWN: 'withdrawn',
  CONTRACT_ENDED: 'contract_ended',
  INVITED: 'invited',
};

export const recommendationStatuses = {
  SENT: 'sent',
  VIEWED: 'viewed',
};

export const applicationStatuses = {
  PENDING_ONBOARDING: 'pending_onboarding',
  APPLIED: 'applied',
  PENDING_CONTRACT: 'pending_contract',
  REJECTED: 'rejected',
  WITHDRAWN: 'withdrawn',
  HIRED: 'hired',
};

export const employmentStatuses = {
  ACTIVE: 'active',
  CANCELLED: 'cancelled',
  ENDED: 'ended',
};

export const employmentCancellationReasons = [
  {
    title: 'Moving to another SR',
    description: 'Worker is being moved to another SR',
    value: 'staff_request_reassignment',
  },
  {
    title: 'Client reduced demand',
    description: 'Client requires less workers now',
    value: 'reduced_demand',
  },
  {
    title: 'Worker did not show up',
    description: 'Worker did not show up at all for the job',
    value: 'no_show',
  },
  {
    title: 'Worker quit without notice',
    description: 'Worker stopped showing up after one or more days of work',
    value: 'absence_without_notice',
  },
  {
    title: 'Worker submitted resignation',
    description: 'Worker quit or submitted resignation with notice',
    value: 'resigned',
  },
  {
    title: 'Worker was underperforming',
    description: 'Worker was not performing sufficiently well for the job',
    value: 'underperformance',
  },
  {
    title: 'Worker displayed inappropriate behaviour',
    description: 'Worker displayed inappropriate behaviour while on the job e.g. Lost uniform, broke equipment',
    value: 'inappropriate_behaviour',
  },
  {
    title: 'Worker cancelled all shifts',
    description: 'Worker cancelled all shift from employment',
    value: 'cancelled_all_shifts',
  },
];

export const workerSuspensionReasons = [
  {
    title: 'Worker did not show up',
    description: 'Worker did not show up at all for the job',
    value: 'no_show',
  },
  {
    title: 'Worker quit without notice',
    description: 'Worker stopped showing up after one or more days of work',
    value: 'absence_without_notice',
  },
  {
    title: 'Worker had inappropriate behaviour',
    description: 'Worker displayed inappropriate behaviour while on the job e.g. Lost uniform, broke equipment',
    value: 'inappropriate_behaviour',
  },
  {
    title: 'Worker has duplicate account',
    description: 'Worker has signed up with another account before',
    value: 'duplicate_account',
  },
  {
    title: 'Worker engaged in illegal activity',
    description: 'Worker has committed an illegal act e.g. cheating, stealing, fraud',
    value: 'illegal_activity',
  },
];

export const onboardingRejectionReasons = [
  {
    title: 'Failed skill test',
    description: 'Worker failed the automated recruitment skill test on the worker app.',
    value: 'failed_skill_test',
  },
  {
    title: 'Poor attitude or personality',
    description: 'Worker has personality issues, odd/rude behaviour, or poor presentation.',
    value: 'attitude_issues',
  },
  {
    title: 'Communication issues',
    description:
      'Worker is unclear about the jobs they want. Unable to answer interview questions clearly. Lacking basic communication skills.',
    value: 'communication_issues',
  },
  {
    title: 'Underage',
    description: 'Worker does not meet the minimum age requirement.',
    value: 'underage',
  },
  {
    title: 'Not tech savy / No smartphone',
    description:
      "Worker lacks basic tech skills, and will not know how to clock in/out on the app. Or they don't own a smartphone.",
    value: 'not_tech_savvy',
  },
  {
    title: 'Availability issues',
    description: 'Worker is only available for a short period of time.',
    value: 'availability_issues',
  },
  {
    title: 'Lack of experience or skills',
    description:
      'Worker is unlikely to be hired by any clients on our platform due to a lack of relevant skills and experience.',
    value: 'inexperienced',
  },
  {
    title: 'Did not show up to interview',
    description: 'Worker did not come for scheduled interview at recruitment center.',
    value: 'no_show',
  },
  {
    title: 'Duplicate account',
    description: 'Worker already has an account.',
    value: 'duplicate_account',
  },
  {
    title: 'Other reasons',
    description: 'Please provide your reason in the notes section.',
    value: 'others',
  },
];

export const accountStatus = {
  PENDING_APPROVAL: 'pending approval',
  LEAD: 'lead',
  PILOT: 'pilot',
  ACTIVE: 'active',
  REJECTED: 'rejected',
  SUSPENDED: 'suspended',
  DISABLED: 'disabled',
  ARCHIVED: 'archived',
  DEACTIVATED: 'deactivated',
};

export const partnerStatuses = {
  LEAD: 'lead',
  ACTIVE: 'active',
  REJECTED: 'rejected',
  SUSPENDED: 'suspended',
  DISABLED: 'disabled',
  DELETED: 'deleted',
  PENDING_DELETION: 'pending_deletion',
};

export const clientStatuses = {
  PENDING_APPROVAL: 'pending approval',
  LEAD: 'lead',
  PILOT: 'pilot',
  ACTIVE: 'active',
  DEACTIVATED: 'deactivated',
  SUSPENDED: 'suspended',
  ARCHIVED: 'archived',
};

export const pendingApprovalClientStatuses = [
  clientStatuses.PENDING_APPROVAL,
  clientStatuses.LEAD,
  clientStatuses.PILOT,
  clientStatuses.ACTIVE,
];

export const selectableClientStatuses = {
  PILOT: 'pilot',
  ACTIVE: 'active',
};

export const pilotTypes = {
  FREE: 'free',
  PAID: 'paid',
};

export const paymentReportStatuses = {
  UNPAID: 'unpaid',
  ERROR: 'error',
  PAID: 'paid',
  ON_HOLD: 'on_hold',
};

export const paymentItemStatuses = {
  UNPAID: 'unpaid',
  DISPUTED: 'disputed',
  PAID: 'paid',
  VOID: 'void',
};

export const paymentItemTypes = {
  BASE: 'base',
  OVERTIME: 'overtime',
  BONUS: 'bonus',
  INCENTIVE: 'incentive',
  ADJUSTMENT: 'adjustment',
  EXPENSE: 'expense',
  SEVERANCE_PAY: 'severance_pay',
  DEPOSIT: 'deposit',
  TAX_EXCLUDE: 'tax_exclude',
};

export const wageTypes = {
  PER_HOUR: {
    value: 'per_hour',
    label: 'Hourly',
  },
  PER_DAY: {
    value: 'per_day',
    label: 'Daily',
  },
  PER_MONTH: {
    value: 'per_month',
    label: 'Monthly',
  },
  PER_JOB: {
    value: 'per_job',
    label: 'Job',
  },
};

export const billingReportStatuses = {
  UNBILLED: 'unbilled',
  BILLED: 'billed',
  ON_HOLD: 'on_hold',
};

export const billingItemStatuses = {
  UNBILLED: 'unbilled',
  DISPUTED: 'disputed',
  BILLED: 'billed',
  VOID: 'void',
};

export const timesheetEntryStatuses = {
  CLOCKED_IN: 'clocked_in',
  CLOCKED_OUT: 'clocked_out',
  APPROVED: 'approved',
  DISPUTED: 'disputed',
  RESOLVED: 'resolved',
  VOID: 'void',
  CONFIRMED: 'confirmed',
  UNCONFIRMED: 'unconfirmed',
};

export const timesheetEntryPaymentStatuses = {
  PAID: 'paid',
  UNPAID: 'unpaid',
  IN_PROGRESS: 'in_progress',
};

export const staffRequestContractStatus = {
  PENDING_SIGNATURE: 'pending_signature',
  SIGNED: 'signed',
  EXPIRED: 'expired',
  VOID: 'void',
};

export const attendanceStatuses = {
  ASSIGNED: 'assigned',
  UNASSIGNED: 'unassigned',
};

export const attendanceOffScheduleCode = -1;
export const unsetCode = -1;

export const assignmentStatuses = {
  UNCONFIRMED: 'unconfirmed',
  ABSENT: 'absent',
};

export const timesheetEntryStatusList = [
  timesheetEntryStatuses.CLOCKED_IN,
  timesheetEntryStatuses.CLOCKED_OUT,
  timesheetEntryStatuses.DISPUTED,
  timesheetEntryStatuses.RESOLVED,
  timesheetEntryStatuses.APPROVED,
  timesheetEntryStatuses.VOID,
];

export const leaveAndSickStatuses = {
  REQUESTED: 'pending_approval',
  APPROVED: 'approved',
  REJECTED: 'rejected',
};

export const clientTiers = { noTier: null, tier1: 1, tier2: 2, tier3: 3, tier4: 4, tier5: 5, tier6: 6 };

export const clientPlatformPackages = {
  ONE: 1,
  TWELVE: 12,
};

export const clientTypes = {
  MANAGED_SERVICE: 'managed_service',
  SELF_SERVE: 'self_serve',
  WMP: 'wmp',
  BPO: 'bpo',
};

export const serviceLevelTypes = {
  MANAGED_SERVICE: 'managed_service',
  SELF_SERVE: 'self_serve',
};

export const clientTypesTitle = {
  [clientTypes.WMP]: 'WMP',
  [clientTypes.SELF_SERVE]: 'Self serve/SME',
  [clientTypes.MANAGED_SERVICE]: 'Managed Service',
  [clientTypes.BPO]: 'BPO',
};

export const pricingUnitTypes = {
  FLAT_RATE: 'flat_rate',
  PERCENTAGE: 'percentage',
};

// key value should align with pricing JSONField
export const platformFeeAmountOptions = {
  day: 'Day(s) worked',
  worker: 'Worker(s)',
};

// key value should aligns with pricing JSONField
export const platformAmountOptions = {
  day: 'Day worked',
  worker: 'Worker',
};

// key value should aligns with pricing JSONField
export const platformManagedServiceAmountOptions = {
  worker_management: 'Worker Management',
  hiring_management: 'Hiring Management',
  worker_payments: 'Worker Payments',
};

export const industries = {
  WAREHOUSE_AND_LOGISTICS: 'warehouse_and_logistics',
  FOOD_AND_BEVERAGE: 'food_and_beverage',
  EVENTS: 'events',
  PROMOTIONS: 'promotions',
  RETAIL: 'retail',
  MANUFACTURING: 'manufacturing',
  OTHERS: 'others',
};

export const DEFAULT_DATETIME_FORMAT = 'DD/MM/YYYY hh:mm A';
export const API_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss[Z]';
export const DATE_WITH_DAY_FORMAT = 'ddd, DD/MM/YYYY';
export const DATE_FORMAT = 'DD MMM YYYY';
export const TIME_FORMAT = 'hh:mm A';
export const DEFAULT_TIME_FORMAT = 'hh:mmA';
export const MINIMAL_TIME_FORMAT = 'hh:mma';
export const TIME_FORMAT_24_HOUR = 'HH:mm';

export const JakartaLatLng = {
  lat: -6.2087634,
  lng: 106.84559899999999,
};

export const BangkokLatLng = {
  lat: 13.7563309,
  lng: 100.50176510000006,
};

export const derivedWorkingStatus = {
  EMPLOYMENT_NOT_STARTED: 'employment_not_started',
  EMPLOYMENT_ENDED: 'employment_ended',
  RESIGNED: 'resigned',
  CONTRACT_NOT_SIGNED: 'contract_not_signed',
  ON_BREAK: 'on_break',
  WORKING: 'working',
};

export const attendanceAbsenceReasons = {
  SICK: 'sick',
  PERSONAL_LEAVE: 'personal_leave',
};

export const shiftFullfillments = {
  NEEDED: 'Needed',
  ASSIGNED: 'Assigned',
  WORKED: 'Worked',
};

export const partnerSourceTypes = {
  WEBSITE: 'website',
  SALES: 'sales',
  REFERRED: 'referred',
  INTERNET_SEARCH: 'internet_search',
  JOB_BOARD: 'job_board',
  FACEBOOK: 'facebook',
  INSTAGRAM: 'instagram',
  LINKEDIN: 'linkedin',
  BLOG: 'blog',
  DIRECT_HIRE: 'direct_hire',
  OTHER: 'other',
};

export const qrClockInOptions = [
  {
    name: 'Manual worker clock-in',
    description: ' Workers clock-in themselves on the worker app.',
    value: false,
  },
  {
    name: 'QR code scanning',
    description: 'Workers clock-in via QR code scanning by on-ground Supervisors.',
    value: true,
  },
];

export const errorCodes = {
  MOBILE_ALREADY_EXISTS: 'mobile_already_exists',
  INVALID_MOBILE: 'invalid_mobile',
  SCHEDULE_CONFLICT: 'schedule_conflict',
  PARTNER_WITH_MOBILE_EXISTS: 'partner_with_mobile_already_exists',
  INACTIVE_MANAGER_RECORD_ALREADY_EXISTS: 'inactive_manager_record_already_exists',
  PARTNER_WITH_EMAIL_EXISTS: 'partner_with_email_already_exists',
};

export const partnerCovidCertificationStatuses = {
  VERIFIED: 'verified',
  INVALID: 'invalid',
  PENDING_VERIFICATION: 'pending_verification',
};

export const SELF_SIGNUP_SOURCE = 'self_signup';

export const WORKMATE_DOMAIN = 'workmate.asia';
export const firebaseErrorCodes = {
  POPUP_CLOSED_BY_USER: 'auth/popup-closed-by-user',
};

export const selectableClientContractManagement = [
  {
    value: false,
    text: 'No contract',
  },
  {
    value: true,
    text: 'With contract',
  },
];

export const gigContractTypes = {
  PART_TIME: 'gig_part_time',
  FREELANCE: 'freelance',
};

export const selectableClientGigContractType = [
  {
    value: gigContractTypes.PART_TIME,
    text: 'Employee contract',
  },
  {
    value: gigContractTypes.FREELANCE,
    text: 'Freelancer',
  },
];

export const selectableYesOrNo = [
  {
    value: false,
    text: 'No',
  },
  {
    value: true,
    text: 'Yes',
  },
];

export const selectableClientCaptureBankDetails = [
  {
    value: false,
    text: 'No bank details',
  },
  {
    value: true,
    text: 'Capture bank details',
  },
];

export const selectableClientBreakDuration = [
  {
    value: false,
    text: 'No break duration',
  },
  {
    value: true,
    text: 'With break duration',
  },
];

export const pendingContractStatuses = {
  CONTRACT_SENT: 'Contract sent',
  JOB_OFFER_SENT: 'Job offer sent',
};

export const mobileCountryCodes = {
  CN: { label: '🇨🇳CN', length: 13, example: '+86 318 4567 8912' },
  ID: { label: '🇮🇩ID', length: 12, example: '+62 801 4561 7890' },
  SG: { label: '🇸🇬SG', length: 8, example: '+65 4802 5188' },
  TH: { label: '🇹🇭TH', length: 11, example: '+66 081 2328 8037' },
  IN: { label: '🇮🇳IN', length: 10, example: '+91 91 3801 8018' },
};

export const httpResponseStatuses = {
  OK_200: 200,
  CREATED_201: 201,
  BAD_REQUEST_400: 400,
  UNAUTHORIZED_401: 401,
  FORBIDDEN: 403,
};

export const partnerSources = {
  DIRECT_JOB_INVITE: 'direct_job_invite',
};

export const NOT_PROVIDED = 'Not provided';

export const RaceTypes = {
  CHINESE: 'Chinese',
  MALAY: 'Malay',
  INDIAN: 'Indian',
  SIKH: 'Sikh',
  EUROASIAN: 'Euroasian',
  CAUCASIAN: 'Caucasian',
  VIETNAMESE: 'Vietnamese',
  THAI: 'Thai',
  OTHERS: 'Others',
};

export const ResidencyTypes = {
  CITIZEN: 'Citizen',
  PERMANENT_RESIDENT: 'PR',
  WORK_PERMIT: 'Work Permit',
};

export const FundTypes = {
  CDAC: 'CDAC',
  MBMF: 'MBMF',
  SINDA: 'SINDA',
  ECF: 'ECF',
  OPTOUT: 'Optout',
  NOT_APPLICABLE: 'N/A',
};

export const EmploymentTypes = {
  CONTRACT: 'contract',
  DAILY: 'daily',
  PART_TIME: 'part_time',
  FULL_TIME: 'full_time',
  GIG: 'gig',
};

export const staffRequestContractTypes = {
  thailand: {
    SSO: 'SSO',
    WHT: 'WHT',
  },
};

export const paymentReportTypes = {
  DCO: 'dco',
  STANDARD: 'standard',
  FREELANCE: 'freelance',
};

export const MINUTES_IN_HOUR = 60;

export const FEATURE_FLAGS = {
  KEYS: {
    SHOW_ADMIN_DASHBOARD_VERSION: 'show-admin-dashboard-version',
  },
};
