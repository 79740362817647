import { endpoints } from '../config';
import base from './base';

const createManager = async manager => {
  const response = await base.api.post(`${endpoints.managers}/`, manager);
  return response;
};

const updateManager = async manager => {
  const response = await base.api.put(`${endpoints.managers}/${manager.id}/`, manager);
  return response;
};

const setPassword = async (managerId, password) => {
  const response = await base.api.post(`${endpoints.managers}/${managerId}/set-password/`, { password });
  return response;
};

const reactivateManager = async manager => {
  const response = await base.api.post(`${endpoints.managers}/reactivate/`, manager);
  return response;
};

const deleteManager = async manager => {
  const response = await base.api.delete(`${endpoints.managers}/${manager.id}/`, manager);
  return response;
};

export default {
  createManager,
  updateManager,
  setPassword,
  reactivateManager,
  deleteManager,
};
