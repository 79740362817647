import React from 'react';
import { Col, Form, Input, Menu, Modal, notification, Row, Select, Switch, Tooltip, Icon } from 'antd';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import clientApi from '../../../../../services/clientApi';
import locationApi from '../../../../../services/locationApi';
import { colors } from '../../../../../styles/colors';
import Maps from '../../../../shared/components/Maps';

const { Option } = Select;
const { Item } = Form;

// Utility function to extract an address component from the Google Maps result's address components
const getAddressComponent = (components, type) => {
  const component = components.filter(comp => comp.types.includes(type))[0];
  return component && component.short_name;
};

class LocationModal extends React.Component {
  state = {
    loading: false,
    cities: [],
    areas: [],
  };

  componentDidMount() {
    this.initializeCitiesAndAreas();
  }

  componentDidUpdate(prevProps) {
    const { client, location } = this.props;

    if (prevProps.client !== client || prevProps.location !== location) {
      this.initializeCitiesAndAreas();
    }
  }

  initializeCitiesAndAreas = () => {
    const { location } = this.props;

    this.fetchCities();

    if (location && location.address && location.address.area) {
      this.fetchAreas(location.address.area.city.id);
    }
  };

  handleLocationSelect = async address => {
    const results = await geocodeByAddress(address);
    const result = results[0];
    const { address_components, formatted_address } = result;

    const postalCode = getAddressComponent(address_components, 'postal_code');
    this.props.form.setFieldsValue({ street_1: formatted_address });

    this.props.form.setFieldsValue({ zip: postalCode });

    const latLng = await getLatLng(result);
    this.props.form.setFieldsValue({ latitude: latLng.lat });
    this.props.form.setFieldsValue({ longitude: latLng.lng });
  };

  handleMapClick = ({ lat, lng }) => {
    this.props.form.setFieldsValue({ latitude: lat });
    this.props.form.setFieldsValue({ longitude: lng });
  };

  handleCityChange = cityId => {
    this.props.form.setFieldsValue({ area: undefined });
    this.setState({ areas: [] });
    this.fetchAreas(cityId);
  };

  fetchCities = async () => {
    const countryId = this.props.client.country.id;
    const cities = await locationApi.fetchCities(countryId);
    this.setState({ cities });
  };

  fetchAreas = async cityId => {
    const countryId = this.props.client.country.id;
    let areas = [];
    if (cityId) {
      areas = await locationApi.fetchAreas(countryId, cityId);
      this.setState({ areas });
    }
  };

  handleClose = () => {
    this.setState({
      loading: false,
    });
    this.props.form.resetFields();
    this.props.onClose();
  };

  handleSubmit = () => {
    this.props.form.validateFieldsAndScroll((errors, values) => {
      if (!errors) {
        this.setState({ loading: true });

        let promise = null;
        if (!this.props.location) {
          // Create location
          promise = this.createLocation(values);
        } else {
          // Edit location
          promise = this.editLocation(values);
        }
        promise.then(() => {
          this.props.onUpdate();
          this.handleClose();
        });
      }
    });
  };

  createLocation = async values => {
    await clientApi.createLocation(this.props.client.id, {
      name: values.name,
      client: this.props.client.id,
      address: {
        street_1: values.street_1,
        street_2: values.street_2,
        zip: values.zip,
        area: values.area,
        latitude: values.latitude,
        longitude: values.longitude,
        country: this.props.client.country.id,
      },
      qr_override_allowed: values.qr_override_allowed,
      fixed_location: values.fixed_location,
      selfie_allowed: values.selfie_allowed,
    });
    return notification.success({
      message: 'Location created successfully',
    });
  };

  editLocation = async values => {
    await clientApi.editLocation(this.props.client.id, {
      id: this.props.location.id,
      name: values.name,
      client: this.props.client.id,
      address: {
        street_1: values.street_1,
        street_2: values.street_2,
        zip: values.zip,
        area: values.area,
        latitude: values.latitude,
        longitude: values.longitude,
        country: this.props.location.address.country.id,
      },
      qr_override_allowed: values.qr_override_allowed,
      fixed_location: values.fixed_location,
      selfie_allowed: values.selfie_allowed,
    });
    return notification.success({
      message: 'Location edited successfully',
    });
  };

  render() {
    const { location, visible, client } = this.props;
    const { getFieldDecorator, getFieldValue, setFieldsValue } = this.props.form;

    return (
      <Modal
        visible={visible}
        title={location ? 'Edit location' : 'Add location'}
        onOk={this.handleSubmit}
        okText="Save"
        onCancel={this.handleClose}
        confirmLoading={this.state.loading}
      >
        <Form layout="vertical" onSubmit={this.handleSubmit} hideRequiredMark>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item label="Location Name" hasFeedback>
                {getFieldDecorator('name', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input a location name',
                    },
                  ],
                  initialValue: location && location.name,
                })(<Input placeholder="Name of your business location" />)}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Country" hasFeedback={false}>
                {getFieldDecorator('country', {
                  rules: [
                    {
                      required: false,
                      message: 'Please input a country',
                    },
                  ],
                  initialValue: client && client.country.id,
                })(
                  <Select disabled>
                    <Option value={client && client.country.id}>{client && client.country.name}</Option>
                  </Select>,
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="City" hasFeedback>
                {getFieldDecorator('city', {
                  rules: [
                    {
                      required: true,
                      message: 'Please select a city',
                    },
                  ],
                  initialValue: location && location.address.area && location.address.area.city.id,
                })(
                  <Select
                    onChange={this.handleCityChange}
                    placeholder="Select city"
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {this.state.cities.map(city => (
                      <Option value={city.id}>{city.name}</Option>
                    ))}
                  </Select>,
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Area" hasFeedback>
                {getFieldDecorator('area', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input an area',
                    },
                  ],
                  initialValue: location && location.address.area && location.address.area.id,
                })(
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder="Select area"
                    disabled={!getFieldValue('city')}
                  >
                    {this.state.areas.map(area => (
                      <Select.Option key={area.id} value={area.id}>
                        {area.name}
                      </Select.Option>
                    ))}
                  </Select>,
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Address">
                {getFieldDecorator('street_1', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input select your address',
                    },
                  ],
                  initialValue: location && location.address.street_1,
                })(
                  <PlacesAutocomplete value="street_1" onSelect={this.handleLocationSelect}>
                    {({ getInputProps, suggestions, getSuggestionItemProps }) => (
                      <div>
                        <Input.Search
                          {...getInputProps({
                            placeholder: 'Street address and number',
                          })}
                        />
                        <Menu>
                          {suggestions.map(suggestion => (
                            <Menu.Item {...getSuggestionItemProps(suggestion)} key={suggestion.id}>
                              {suggestion.description}
                            </Menu.Item>
                          ))}
                        </Menu>
                      </div>
                    )}
                  </PlacesAutocomplete>,
                )}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Address line 2 (Optional)" hasFeedback>
                {getFieldDecorator('street_2', {
                  initialValue: location && location.address.street_2,
                })(<Input placeholder="Unit, building, floor etc." />)}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label="Postcode" hasFeedback>
                {getFieldDecorator('zip', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input a postcode',
                    },
                  ],
                  initialValue: location && location.address.zip,
                })(<Input placeholder="Postcode" />)}
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <h4>Where are you located?</h4>
            <p style={{ paddingBottom: '20px' }}>
              Drag and zoom the map then position the marker on the exact spot where your business is located.
            </p>
          </Row>
          <Row style={{ height: '400px' }}>
            <Maps
              centerPosition={{
                lat: parseFloat(getFieldValue('latitude') || 0),
                lng: parseFloat(getFieldValue('longitude') || 0),
              }}
              markerPositions={[
                {
                  lat: parseFloat(getFieldValue('latitude') || 0),
                  lng: parseFloat(getFieldValue('longitude') || 0),
                  draggable: true,
                },
              ]}
              onDrag={this.handleMapClick}
              scrollWheelZoom
            />
          </Row>
          <Row gutter={16} style={{ paddingTop: '20px' }}>
            <Col span={12}>
              <Form.Item label="Latitude" hasFeedback>
                {getFieldDecorator('latitude', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input a latitude',
                    },
                  ],
                  initialValue: location && location.address.latitude,
                })(<Input placeholder="Latitude" />)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Longitude" hasFeedback>
                {getFieldDecorator('longitude', {
                  rules: [
                    {
                      required: true,
                      message: 'Please input a longitude',
                    },
                  ],
                  initialValue: location && location.address.longitude,
                })(<Input placeholder="Longitude" />)}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            Latitude and longitude are automatically determined by your location on Google Maps. You can manually type
            the coordinates only if you are adding a remote location without a registered street address.
          </Row>
          <Row>
            <Col span={24} style={{ marginTop: '16px' }}>
              <h4>Clock-in method</h4>
              <p style={{ paddingBottom: '20px' }}>
                Choose whether to let workers clock-in themselves, or through secure QR code scanning by your location
                Supervisors.
              </p>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Item style={{ marginBottom: '4px' }}>
                {getFieldDecorator('qr_override_allowed', {
                  initialValue: location?.qr_override_allowed ?? true,
                })(
                  <>
                    <Switch
                      checked={getFieldValue('qr_override_allowed')}
                      onChange={checked => {
                        setFieldsValue({ qr_override_allowed: checked });
                        if (!checked) {
                          setFieldsValue({ fixed_location: false });
                        }
                      }}
                    />{' '}
                    {'Allow GPS clock-in as backup'}
                  </>,
                )}
              </Item>
              <Row>• {'Recommended clock-in method: QR clock-in without GPS (faster & easier)'}</Row>
              <Row>
                •{' '}
                {
                  'Only turn on this option when you have no supervisors on site so that workers can clock-in themselves.'
                }
              </Row>
            </Col>
            <Col span={24} style={{ marginTop: '8px' }}>
              <Item style={{ marginBottom: '4px' }}>
                {getFieldDecorator('fixed_location', {
                  initialValue: location?.fixed_location,
                })(
                  <>
                    <Switch
                      disabled={!getFieldValue('qr_override_allowed')}
                      checked={!!getFieldValue('fixed_location')}
                      onChange={checked => {
                        setFieldsValue({ fixed_location: checked });
                      }}
                    />{' '}
                    {'Workers must clock-in at location'}
                    <Tooltip
                      placement="right"
                      title={'If turned on, workers must clock-in within the location GPS radius.'}
                    >
                      <Icon type="info-circle" theme="filled" style={{ marginLeft: 5, color: colors.blue }} />
                    </Tooltip>
                  </>,
                )}
              </Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(LocationModal);
