import React from 'react';
import {
  Row,
  Col,
  Form,
  Select,
  Checkbox,
  Radio,
  Input,
  InputNumber,
  Button,
  Icon,
  message,
  Typography,
  Steps,
  Switch,
  DatePicker,
  Alert,
} from 'antd';
import moment from 'moment';
import numeral from 'numeral';

import {
  paymentMethods,
  paymentFrequency,
  staffRequestStatuses,
  currencyCodes,
  wageTypes,
  EmploymentTypes,
} from '../../../constants';
import { colors } from '../../../styles/colors';

import routes from '../../../routes';
import staffRequestApi from '../../../services/staffRequestApi';
import staffRequestUtils from '../../utilities/staffRequestUtils';

import LoadingSpinner from '../../../shared/components/LoadingSpinner';
import ClientAndLocationForm from './components/ClientAndLocationForm';

const { Step } = Steps;
const { Item } = Form;
const { Option } = Select;
const { TextArea } = Input;
const { Title, Text, Paragraph } = Typography;

const editInitialStep = 4;
const duplicateInitialStep = 3;

const StepTitle = ({ title, description }) => {
  return (
    <>
      <Title level={4} style={{ marginBottom: '8px' }}>
        {title}
      </Title>
      <Row style={{ lineHeight: '22px' }}>
        <Text type="secondary" style={{ fontSize: '14px' }}>
          {description}
        </Text>
      </Row>
    </>
  );
};

class StaffRequestFormView extends React.Component {
  state = {
    isEdit: false,
    staffRequest: undefined,
    currencyCode: currencyCodes.IDR,
    isLoading: false,
    showErrorMessage: false,
    showSchedulingError: false,
    timezone: undefined,
    wageTypeOptions: [wageTypes.PER_DAY],
    initialStep: 0,
  };

  async componentDidMount() {
    const { history, location } = this.props;
    const isDuplicate = location.state && location.state.replicateId;
    this.setState({ isLoading: true });
    const urlParts = history.location.pathname.split('/');
    if (urlParts.includes('edit')) {
      // Edit an existing SR
      const { id } = this.props.match.params;
      await this.initializeForm(id);
      this.setState({ isEdit: true, initialStep: editInitialStep });
    } else if (isDuplicate) {
      // Copy from an existing SR
      const id = location.state.replicateId;
      await this.initializeForm(id);

      // Append title with "copy of" for the duplicate SR
      this.setState(prevState => ({
        staffRequest: {
          ...prevState.staffRequest,
          id: undefined,
          title: `Copy of ${prevState.staffRequest.title}`,
        },
        initialStep: duplicateInitialStep,
      }));
    }
    this.setState({ isLoading: false });
  }

  initializeForm = async id => {
    const staffRequest = await staffRequestApi.fetchStaffRequest(id);
    this.getCurrencyCode(staffRequest.client);
    this.getWageTypeOptions(staffRequest.client);
    this.getTimezone(staffRequest.location.address.area.city.timezone);
    this.setState({ staffRequest });
  };

  getCurrencyCode = client => {
    this.setState({ currencyCode: client.country.currency_code || currencyCodes.USD });
  };

  getWageTypeOptions = client => {
    // Monthly wage type is only available for clients paying by invoice.
    // This is because CC billing relies on daily calculations in BE & only functions for daily wage SRs.
    if (!client || client.payment_method === paymentMethods.CREDIT) {
      this.setState({ wageTypeOptions: [wageTypes.PER_DAY] });
      return;
    }

    this.setState({ wageTypeOptions: [wageTypes.PER_HOUR, wageTypes.PER_DAY, wageTypes.PER_MONTH] });
  };

  getTimezone = timezone => {
    this.setState({ timezone });
  };

  handleFieldChange = (fieldLabel, fieldValue) => {
    this.props.form.setFieldsValue({ [fieldLabel]: fieldValue });
  };

  handleCancel = e => {
    e.preventDefault();
    this.props.history.push(`${routes.staffRequests}/${this.state.staffRequest.id}`);
  };

  handleSubmit = async e => {
    this.setState({ showErrorMessage: false });
    e.preventDefault();
    const eventName = e.target.name;
    const { isEdit, staffRequest, timezone } = this.state;
    const { form, history } = this.props;

    form.validateFieldsAndScroll(async (err, values) => {
      if (!err) {
        try {
          this.setState({ isSubmitting: true });
          const payload = staffRequestUtils.formDataToPayload(values, timezone);
          let data = {};
          if (isEdit) {
            payload.id = staffRequest.id;
            data = await staffRequestApi.editStaffRequest(payload);
          } else {
            // Create a draft SR
            data = await staffRequestApi.createStaffRequest(payload);
            // Submit the draft SR for review, SR status gets updated to 'pending review'
            if (eventName === 'submit') {
              data = await staffRequestApi.submitStaffRequest(data.id);
            }
          }
          if (!data) {
            message.warning('Sorry! An error occurred in submission, please contact support');
            return;
          }
          // Successfully updated SRs will go back to their detail page
          message.success('Staff Request successfully saved');
          this.setState({ isSubmitting: false });
          history.push(`${routes.staffRequests}/${data.id}`);
        } catch (error) {
          this.setState({ isSubmitting: false });
          message.warning('Sorry! An error occurred in submission, please contact support');
        }
      } else {
        this.setState({ showErrorMessage: true });
      }
    });
  };

  handleWageTypeChange = e => {
    if (e.target.value === wageTypes.PER_MONTH.value) {
      this.props.form.setFieldsValue({ transport_allowance: 0, meal_allowance: 0, other_allowance: 0 });
    }
  };

  // Throw error if user selected a field but left it blank
  // TODO: Find correct validate trigger to dropdown fields and add validations
  checkIfTouchedFormHasError = () => {
    const { isFieldTouched, getFieldError } = this.props.form;
    if (isFieldTouched('title')) {
      getFieldError('title');
    }
    if (isFieldTouched('wage_amount')) {
      getFieldError('wage_amount');
    }
    if (isFieldTouched('description')) {
      getFieldError('description');
    }
  };

  getEarliestIncompleteStep = () => {
    const { getFieldsValue } = this.props.form;
    const values = getFieldsValue();
    const {
      client,
      position,
      staff_required,
      title,
      responsibilities,
      requirements,
      wage_amount,
      end_time,
      location,
    } = values;

    const formFieldsInEachStep = [
      [client, location],
      [position, staff_required],
      [title, responsibilities, requirements, wage_amount, position, staff_required, client, location],
      [end_time, title, responsibilities, requirements, wage_amount, position, staff_required, client, location],
      // undefined here is necessary to complete last step
      [undefined],
    ];

    for (let i = 0; i < formFieldsInEachStep.length; i++) {
      const formFields = formFieldsInEachStep[i];
      if (this.isStepIncomplete(formFields)) {
        return i;
      }
    }
  };

  isStepIncomplete = formFields => {
    for (let j = 0; j < formFields.length; j++) {
      if (!formFields[j] || formFields[j] === '') {
        return true;
      }
    }
    return false;
  };

  render() {
    if (this.state.isLoading || this.state.isSubmitting) {
      return <LoadingSpinner />;
    }
    const {
      staffRequest,
      currencyCode,
      isEdit,
      isLoading,
      showErrorMessage,
      showSchedulingError,
      wageTypeOptions,
      initialStep,
      timezone,
    } = this.state;
    const { positions } = this.props;
    const { getFieldDecorator, getFieldValue, setFieldsValue, getFieldsValue } = this.props.form;

    const otherRequirementFields = [
      {
        field: 'require_experience',
        label: 'Relevant work experience needed',
        initialValue: staffRequest ? staffRequest.require_experience : false,
      },
      {
        field: 'require_english',
        label: 'English language needed',
        initialValue: staffRequest ? staffRequest.require_english : false,
      },
      {
        field: 'min_age',
        label: 'Minimum age of 21',
        initialValue: staffRequest && staffRequest.min_age >= 21 ? 21 : undefined,
      },
    ];

    const jobTitleExamples = ['Picker and Packer needed at Jakarta Warehouse', 'Server needed at private function'];

    const jobResponsibilitiesExamples = [
      'Responsibilities that someone is expected to do.',
      'Work schedule and number of days in a week.',
      'Working hours and Shifts.',
      'Location and any relevant logistic information.',
      'Any OT expectations you might have.',
      'Any dress code requirements.',
    ];

    const jobRequirementsExamples = [
      'Minimum academic qualification.',
      'Minimum professional experience (e.g. 2 years experience in Warehouse or Ecommerce companies).',
      'Hard skills needed (e.g. Ability to operate a POS machine).',
      'Soft skills needed (e.g. can work well under pressure).',
      'Nice to have requirements (e.g. knows the local language)',
    ];

    const jobBenefitsExamples = ['Do you offer bonuses, OT, allowances?', 'Any transportation options?'];

    const paymentFrequencyOptions = [
      { disabled: false, value: paymentFrequency.WEEKLY, label: 'Weekly' },
      { disabled: false, value: paymentFrequency.BIWEEKLY, label: 'Bi-weekly' },
      { disabled: false, value: paymentFrequency.MONTHLY, label: 'Monthly' },
    ];

    this.checkIfTouchedFormHasError();
    return (
      <Form hideRequiredMark layout="vertical" style={{ marginBottom: '160px', maxWidth: '728px' }}>
        {/* Header */}
        <Row style={{ marginBottom: '48px' }}>
          <Title level={2} style={{ marginBottom: '8px' }}>
            {isEdit ? 'Edit staff request' : 'Request Workmates'}
          </Title>
          <Text type="secondary">
            Request workers by telling us who you need, your job requirements, and schedules. We send pre-vetted workers
            directly to your business.
          </Text>
        </Row>

        <Steps
          direction="vertical"
          current={this.getEarliestIncompleteStep() || initialStep}
          className="v2-steps-wrapper"
        >
          {/* Section 1 - Client and location */}
          <Step
            title={
              <StepTitle
                title="Client & Location"
                description="Select the client, business location, and worker clock-in settings."
              />
            }
            description={
              <Row style={{ marginTop: '24px', marginBottom: '48px' }}>
                <ClientAndLocationForm
                  isEdit={isEdit}
                  client={staffRequest && staffRequest.client}
                  location={staffRequest && staffRequest.location}
                  isLoading={isLoading}
                  getFieldDecorator={getFieldDecorator}
                  getFieldValue={getFieldValue}
                  onFieldChange={this.handleFieldChange}
                  getCurrencyCode={this.getCurrencyCode}
                  getWageTypeOptions={this.getWageTypeOptions}
                  getTimezone={this.getTimezone}
                />
              </Row>
            }
          />
          {/* Section 2 - Job position */}
          <Step
            title={
              <StepTitle
                title="Choose Workmates"
                description="Choose a job position and set how many Workmates you need."
              />
            }
            description={
              <section style={{ marginBottom: '48px' }}>
                <Row>
                  <Col xs={24} md={16} style={{ marginTop: '18px' }}>
                    <Item label="Job position" style={{ marginBottom: '8px' }}>
                      {getFieldDecorator('position', {
                        rules: [{ required: true, message: 'Please select a position' }],
                        initialValue: staffRequest ? staffRequest.position.id : undefined,
                      })(
                        <Select search placeholder="Select a job position" style={{ width: '100%' }}>
                          {positions.map(position => (
                            <Option key={position.id} value={position.id}>
                              {position.name}
                            </Option>
                          ))}
                        </Select>,
                      )}
                    </Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={18} md={12}>
                    <Item label="How many workmates do you need?" style={{ marginBottom: 8 }}>
                      {getFieldDecorator('staff_required', {
                        rules: [{ required: true, message: 'Number of staff must be at least 1' }],
                        initialValue: staffRequest ? staffRequest.staff_required : 1,
                      })(
                        <InputNumber
                          min={1}
                          formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                          parser={value => value.replace(/\$\s?|(,*)/g, '')}
                          style={{ width: '100%' }}
                        />,
                      )}
                    </Item>
                  </Col>
                </Row>
                <Row>
                  {/* Skip reject applicant screening toggle */}
                  {getFieldDecorator('skip_screening_test_rejection', {
                    initialValue: staffRequest ? staffRequest.skip_screening_test_rejection : false,
                  })(
                    <div style={{ paddingBottom: 8 }}>
                      <Item label="Applicant Screening" style={{ marginBottom: 0 }}>
                        <Switch
                          checkedChildren={<Icon type="check" />}
                          unCheckedChildren={<Icon type="close" />}
                          defaultChecked={staffRequest ? !staffRequest.skip_screening_test_rejection : true}
                          disabled={
                            staffRequest &&
                            [staffRequestStatuses.POSTED, staffRequestStatuses.IN_PROGRESS].includes(
                              staffRequest.status,
                            )
                          }
                          onChange={checked => setFieldsValue({ skip_screening_test_rejection: !checked })}
                        />
                        <Text style={{ marginLeft: 8 }}>Auto-reject applicants who fail screening</Text>
                      </Item>
                      <Text style={{ flex: 1, marginTop: 0, whiteSpace: 'pre-wrap' }}>
                        • Once the SR is posted, you can no longer make changes
                      </Text>
                    </div>,
                  )}
                  {/* Auto-hiring toggle */}
                  {staffRequest && staffRequest.employment_type === EmploymentTypes.GIG && (
                    <>
                      {getFieldDecorator('auto_hire', {
                        initialValue: staffRequest ? staffRequest.auto_hire : false,
                      })(
                        <Item label="Auto-hiring" style={{ marginBottom: 0 }}>
                          <Switch
                            checkedChildren={<Icon type="check" />}
                            unCheckedChildren={<Icon type="close" />}
                            defaultChecked={staffRequest && staffRequest.auto_hire}
                            onChange={checked => setFieldsValue({ auto_hire: checked })}
                          />
                          <Text style={{ marginLeft: 8 }}>Hire workers automatically based on ranking</Text>
                        </Item>,
                      )}
                      <Text style={{ whiteSpace: 'break-spaces' }}>
                        {` • Workers will be automatically hired by the system based on their ranking model score.\n • You can still hire workers yourself or cancel the workers that the system has hired.`}
                      </Text>
                    </>
                  )}
                </Row>
              </section>
            }
          />
          {/* Section 3 - Title, Description */}
          <Step
            title={<StepTitle title="Job Details" description="Tell us about the job and your requirements." />}
            description={
              <section style={{ marginBottom: '48px' }}>
                <Row style={{ marginBottom: '16px' }}>
                  <Item label="Job title" style={{ marginTop: '24px', marginBottom: 0 }}>
                    {getFieldDecorator('title', {
                      validate: [
                        {
                          trigger: 'onBlur',
                          rules: [{ required: true, message: 'Please input a title' }],
                        },
                      ],
                      initialValue: staffRequest ? staffRequest.title : undefined,
                    })(<Input style={{ width: '100%' }} />)}
                  </Item>
                  <Text type="secondary">
                    Here are some good examples:
                    <ul style={{ marginBottom: 0 }}>
                      {jobTitleExamples.map((example, index) => (
                        <li key={index} style={{ listStyleType: 'disc' }}>
                          {example}
                        </li>
                      ))}
                    </ul>
                  </Text>
                </Row>
                <Row style={{ marginBottom: '16px' }}>
                  <Item label="Describe the responsibilities" style={{ marginBottom: 0 }}>
                    {getFieldDecorator('responsibilities', {
                      validate: [
                        {
                          trigger: 'onBlur',
                          rules: [
                            { required: true, message: 'Please write a job responsibilities' },
                            {
                              min: 50,
                              message: 'Please provide more information, this should be a minimum of 50 characters',
                            },
                          ],
                        },
                      ],
                      initialValue: staffRequest ? staffRequest.responsibilities : undefined,
                    })(<TextArea autosize={{ minRows: 4 }} style={{ width: '100%', marginBottom: 0 }} />)}
                  </Item>
                  <Text type="secondary">
                    A clear description includes:
                    <ul style={{ marginBottom: 0 }}>
                      {jobResponsibilitiesExamples.map((example, index) => (
                        <li key={index} style={{ listStyleType: 'disc' }}>
                          {example}
                        </li>
                      ))}
                    </ul>
                  </Text>
                </Row>
                <Row style={{ marginBottom: '16px' }}>
                  <Item label="Describe the requirements" style={{ marginBottom: 0 }}>
                    {getFieldDecorator('requirements', {
                      validate: [
                        {
                          trigger: 'onBlur',
                          rules: [
                            { required: true, message: 'Please write a job requirements' },
                            {
                              min: 50,
                              message: 'Please provide more information, this should be a minimum of 50 characters',
                            },
                          ],
                        },
                      ],
                      initialValue: staffRequest ? staffRequest.requirements : undefined,
                    })(<TextArea autosize={{ minRows: 4 }} style={{ width: '100%', marginBottom: 0 }} />)}
                  </Item>
                  <Text type="secondary">
                    A good requirement includes:
                    <ul style={{ marginBottom: 0 }}>
                      {jobRequirementsExamples.map((example, index) => (
                        <li key={index} style={{ listStyleType: 'disc' }}>
                          {example}
                        </li>
                      ))}
                    </ul>
                  </Text>
                </Row>
                <Row style={{ marginBottom: '16px' }}>
                  <Col span={10}>
                    <Item label="Wage Type" style={{ marginBottom: 0 }}>
                      {getFieldDecorator('wage_type', {
                        initialValue: staffRequest ? staffRequest.wage_type : wageTypes.PER_DAY.value,
                      })(
                        <Radio.Group>
                          {wageTypeOptions.map(({ value, label, disabled }, index) => (
                            <Radio key={index} value={value} disabled={disabled} onChange={this.handleWageTypeChange}>
                              {label}
                            </Radio>
                          ))}
                        </Radio.Group>,
                      )}
                    </Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Text style={{ fontSize: 14, color: colors.black }}>Wage Amount</Text>

                    <Row type="flex" style={{ paddingTop: 8, alignItems: 'center' }}>
                      <Col span={11}>
                        <Text>Base wage</Text>
                      </Col>
                      <Col span={13}>
                        <Item style={{ marginBottom: 0, paddingBottom: 0 }}>
                          {getFieldDecorator('wage_amount', {
                            rules: [{ required: true, message: 'Please input a wage amount' }],
                            initialValue: staffRequest ? staffRequest.wage_amount : 0,
                          })(
                            <InputNumber
                              min={0}
                              formatter={value => `${currencyCode} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                              parser={value => value.replace(/([A-Z])\w+|(,|\s)*/g, '')}
                              style={{ width: '100%' }}
                            />,
                          )}
                        </Item>
                      </Col>
                    </Row>
                    {currencyCode === currencyCodes.THB && (
                      <>
                        <Row type="flex" style={{ paddingTop: 16, alignItems: 'center' }}>
                          <Col span={11}>
                            <Text>Transport allowance</Text>
                          </Col>
                          <Col span={13}>
                            <Item style={{ marginBottom: 0, paddingBottom: 0 }}>
                              {getFieldDecorator('transport_allowance', {
                                initialValue: staffRequest ? staffRequest.transport_allowance : 0,
                              })(
                                <InputNumber
                                  min={0}
                                  disabled={getFieldValue('wage_type') === wageTypes.PER_MONTH.value}
                                  formatter={value => `${currencyCode} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                  parser={value => value.replace(/([A-Z])\w+|(,|\s)*/g, '')}
                                  style={{ width: '100%' }}
                                />,
                              )}
                            </Item>
                          </Col>
                        </Row>
                        <Row type="flex" style={{ paddingTop: 16, alignItems: 'center' }}>
                          <Col span={11}>
                            <Text>Meal allowance</Text>
                          </Col>
                          <Col span={13}>
                            <Item style={{ marginBottom: 0, paddingBottom: 0 }}>
                              {getFieldDecorator('meal_allowance', {
                                initialValue: staffRequest ? staffRequest.meal_allowance : 0,
                              })(
                                <InputNumber
                                  min={0}
                                  disabled={getFieldValue('wage_type') === wageTypes.PER_MONTH.value}
                                  formatter={value => `${currencyCode} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                  parser={value => value.replace(/([A-Z])\w+|(,|\s)*/g, '')}
                                  style={{ width: '100%' }}
                                />,
                              )}
                            </Item>
                          </Col>
                        </Row>
                        <Row type="flex" style={{ paddingTop: 16, alignItems: 'center' }}>
                          <Col span={11}>
                            <Text>Other allowance</Text>
                          </Col>
                          <Col span={13}>
                            <Item style={{ marginBottom: 0, paddingBottom: 0 }}>
                              {getFieldDecorator('other_allowance', {
                                initialValue: staffRequest ? staffRequest.other_allowance : 0,
                              })(
                                <InputNumber
                                  min={0}
                                  disabled={getFieldValue('wage_type') === wageTypes.PER_MONTH.value}
                                  formatter={value => `${currencyCode} ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                  parser={value => value.replace(/([A-Z])\w+|(,|\s)*/g, '')}
                                  style={{ width: '100%' }}
                                />,
                              )}
                            </Item>
                          </Col>
                        </Row>
                      </>
                    )}

                    <Row type="flex" style={{ paddingTop: 24 }}>
                      <Col span={11}>
                        <Text strong>Effective wage</Text>
                      </Col>
                      <Col span={13}>
                        <Text strong>
                          {`${currencyCode} ${numeral(
                            staffRequestUtils.getEffectiveWageAmount(getFieldsValue()),
                          ).format('0,0.00')}`}
                        </Text>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <Item label="Payment Frequency" style={{ marginBottom: 0 }}>
                      {getFieldDecorator('payment_frequency', {
                        initialValue: staffRequest ? staffRequest.payment_frequency : paymentFrequency.MONTHLY,
                      })(
                        <Radio.Group>
                          {paymentFrequencyOptions.map(({ value, label, disabled }, index) => (
                            <Radio key={index} value={value} disabled={disabled}>
                              {label}
                            </Radio>
                          ))}
                        </Radio.Group>,
                      )}
                    </Item>
                  </Col>
                </Row>
                <Row style={{ paddingTop: 32, paddingBottom: 24 }}>
                  <Text type="secondary">
                    Base wage and allowance will be paid automatically to workers at each payment cycle. Tax and social
                    security deductions will only be calculated on the base wage.
                  </Text>
                </Row>
                <Row style={{ marginBottom: '16px' }}>
                  <Item label="Benefits (optional)" style={{ marginBottom: 0 }}>
                    {getFieldDecorator('benefits', {
                      validate: [
                        {
                          trigger: 'onBlur',
                        },
                      ],
                      initialValue: staffRequest ? staffRequest.benefits : undefined,
                    })(<TextArea autosize={{ minRows: 4 }} style={{ width: '100%', marginBottom: 0 }} />)}
                  </Item>
                  <Text type="secondary">
                    Apart from the salary information, candidates might like to know:
                    <ul style={{ marginBottom: 0 }}>
                      {jobBenefitsExamples.map((example, index) => (
                        <li key={index} style={{ listStyleType: 'disc' }}>
                          {example}
                        </li>
                      ))}
                    </ul>
                  </Text>
                </Row>
                <Row>
                  <Row style={{ marginBottom: '8px' }}>
                    <Text style={{ color: colors.black }}>Other requirements</Text> <Text>(optional)</Text>
                  </Row>
                  <Row>
                    {otherRequirementFields.map((option, index) => (
                      <Item style={{ margin: 0 }} key={index}>
                        {getFieldDecorator(option.field, {
                          // Unique to <Checkbox>. We need a "valuePropName" field with "initialValue"
                          valuePropName: 'checked',
                          initialValue: option.initialValue,
                        })(
                          <Checkbox
                            onChange={e => {
                              setFieldsValue({ [option.field]: e.target.checked });
                            }}
                          >
                            {option.label}
                          </Checkbox>,
                        )}
                      </Item>
                    ))}
                  </Row>
                </Row>
              </section>
            }
          />
          {/* Section 4 - Period */}
          <Step
            title={<StepTitle title="Period" description="When do you need Workmates?" />}
            description={
              <section style={{ marginBottom: '48px' }}>
                <Row gutter={24} style={{ marginTop: '24px' }}>
                  <Col xs={24} md={12}>
                    <Item label="Start date" style={{ marginBottom: '8px' }}>
                      {getFieldDecorator('start_time', {
                        initialValue:
                          isEdit && staffRequest?.start_time ? moment.tz(staffRequest.start_time, timezone) : moment(),
                      })(
                        <DatePicker
                          allowClear={false}
                          format="DD-MM-YYYY"
                          disabledDate={current => current < moment().endOf('day')}
                          style={{ width: '100%' }}
                        />,
                      )}
                    </Item>
                  </Col>
                  <Col xs={24} md={12}>
                    <Item label="End date" style={{ marginBottom: '8px' }}>
                      {getFieldDecorator('end_time', {
                        rules: [
                          {
                            validator: (rule, value, callback) => {
                              if (value && getFieldValue('start_time')) {
                                const daysApart = value.diff(getFieldValue('start_time'), 'days');
                                if (daysApart > 365) {
                                  // Set error on form
                                  callback('End date cannot be more than a year from start date.');
                                }
                                if (daysApart < 0) {
                                  callback('End date cannot be earlier than start date.');
                                }
                              }
                              // Have to be called to validate the rest of the fields.
                              callback();
                            },
                          },
                        ],
                        initialValue:
                          isEdit && staffRequest?.end_time ? moment.tz(staffRequest.end_time, timezone) : undefined,
                      })(
                        <DatePicker
                          format="DD-MM-YYYY"
                          disabledDate={current => current < moment().endOf('day')}
                          style={{ width: '100%' }}
                        />,
                      )}
                    </Item>
                  </Col>
                </Row>
              </section>
            }
          />
        </Steps>

        {/* Footer: action buttons */}
        {isEdit ? (
          <Row style={{ marginBottom: '16px' }}>
            <Button
              type="v2-primary"
              onClick={e => {
                this.handleSubmit(e);
              }}
              style={{ padding: '0 32px', marginRight: '10px', marginBottom: '12px' }}
            >
              Save
            </Button>
            <Button
              type="v2-secondary"
              onClick={e => {
                this.handleCancel(e);
              }}
              style={{ padding: '0 32px' }}
            >
              Cancel
            </Button>
            {isEdit && (
              <Alert
                message="Changes made will only apply to future workers. Go to Workforce Page if you want to update information for current workers."
                type="info"
                showIcon
              />
            )}
          </Row>
        ) : (
          <Row style={{ marginBottom: '16px' }}>
            <Button
              type="v2-primary"
              name="submit"
              onClick={e => {
                this.handleSubmit(e);
              }}
              style={{ padding: '0 20px', marginRight: '10px', marginBottom: '12px' }}
            >
              Submit request
            </Button>
            <Button
              type="v2-secondary"
              onClick={e => {
                this.handleSubmit(e);
              }}
              style={{ padding: '0 32px' }}
            >
              Save draft
            </Button>
          </Row>
        )}
        {showErrorMessage && (
          <Paragraph style={{ color: 'red' }}>
            <Icon type="close-circle" theme="filled" style={{ marginRight: '8px' }} />
            There are incomplete fields. Please check the form and try again.
          </Paragraph>
        )}
        {showSchedulingError && (
          <Paragraph style={{ color: 'red' }}>
            <Icon type="close-circle" theme="filled" style={{ marginRight: '8px' }} />
            {staffRequest && staffRequest.short_term === true && (
              <span>
                You cannot extend the SR period beyond 30 days because:
                <ul style={{ paddingLeft: 20 }}>
                  <li>This SR was first posted as a short-term SR (less than 30 days).</li>
                  <li>Workers on this SR are not deducted for SSO.</li>
                </ul>
              </span>
            )}
            {staffRequest && staffRequest.short_term === false && (
              <span>
                You cannot reduce the SR period below 30 days because:
                <ul style={{ paddingLeft: 20 }}>
                  <li>This SR was first posted as a long-term SR (more than 30 days).</li>
                  <li>Workers on this SR are being deducted for SSO.</li>
                </ul>
              </span>
            )}
          </Paragraph>
        )}
      </Form>
    );
  }
}

export default Form.create({ name: 'Staff Request' })(StaffRequestFormView);
