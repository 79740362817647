import React from 'react';
import loader from '../../assets/images/dual_ring_loader.svg';

const LoadingSpinner = ({ text = '', width = '100px' }) => {
  return (
    <section
      style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}
    >
      <img style={{ width, marginBottom: '16px' }} src={loader} alt="loading" />
      {text.length > 0 && <h3 style={{ textAlign: 'center' }}>{text}</h3>}
    </section>
  );
};

export default LoadingSpinner;
