import React from 'react';
import { message } from 'antd';
import SelectionWithNotesModal from '../../../shared/components/SelectionWithNotesModal';
import { onboardingRejectionReasons } from '../../../../constants';
import partnerApi from '../../../../services/partnerApi';

class RejectWorkerModal extends React.Component {
  state = {
    loading: false,
  };

  handleReject = async values => {
    const { workerId, onUpdate, onClose } = this.props;
    const { option, notes } = values;
    this.setState({ loading: true });
    try {
      await partnerApi.rejectPartner(
        { id: workerId },
        {
          rejection_reason: option,
          notes,
        },
      );
      this.setState({ loading: false });
      onClose();
      onUpdate();
    } catch (error) {
      message.error(error.response.data.detail || error.message);
      this.setState({ loading: false });
    }
  };

  render() {
    const { workerName, visible, onClose } = this.props;
    const { loading } = this.state;

    const modalPrompt = (
      <>
        Are you sure you want to reject <span style={{ fontWeight: 'bold' }}>{workerName}</span>?
      </>
    );
    return (
      <SelectionWithNotesModal
        loading={loading}
        visible={visible}
        modalTitle="Reject Worker"
        modalPrompt={modalPrompt}
        optionsLabel="Reason for rejecting"
        options={onboardingRejectionReasons}
        optionValidationRules={[
          {
            required: true,
            message: 'Please select an option',
          },
        ]}
        notesLabel="Additional notes (optional)"
        notesInputPlaceholderText="Please give your reasons and details"
        onOk={this.handleReject}
        okButtonType="danger"
        okButtonLabel="Yes, reject"
        onCancel={onClose}
      />
    );
  }
}

export default RejectWorkerModal;
