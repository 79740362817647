import React from 'react';
import { startCase } from 'lodash';
import styled from 'styled-components';
import { rgba } from 'polished';
import Label from './Label';
import { paymentItemStatuses } from '../../constants';
import { colors } from '../../styles/colors';

function getStatusStyle(status) {
  switch (status) {
    case paymentItemStatuses.DISPUTED:
      return `
        background: ${colors.orange};
        color: ${colors.white};
        border-color: ${colors.orange};
      `;
    case paymentItemStatuses.PAID:
      return `
        background: ${rgba(colors.green, 0.25)};
        color: ${colors.green};
        border-color: ${rgba(colors.green, 0.25)};
      `;
    case paymentItemStatuses.UNPAID:
      return `
        background: ${colors.white};
        color: ${colors.orange};
        border-color: ${colors.orange};
      `;
    case paymentItemStatuses.VOID:
      return `
        background: ${colors.lightGrey};
        color: ${colors.black};
        border-color: ${colors.lightGrey};
      `;
    default:
      return `
        background: ${colors.darkGrey};
        color: ${colors.white};
        border-color: ${colors.darkGrey};
      `;
  }
}

const CustomLabel = styled(Label)`
  ${props => getStatusStyle(props.status)};
`;

export default ({ status, ...props }) => (
  <CustomLabel status={status} {...props}>
    {startCase(status)}
  </CustomLabel>
);
