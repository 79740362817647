import React from 'react';
import { Modal, Form, Select, Input } from 'antd';
import operatorApi from '../../../../services/operatorApi';
import { roles } from '../../../../shared/access/accessConfig';

const { Option } = Select;

class OperatorFormModal extends React.Component {
  state = {
    loading: false,
  };

  handleOnSubmit = () => {
    const { form, operator } = this.props;

    form.validateFields((errors, values) => {
      if (!errors) {
        this.setState({ loading: true });
        if (!operator) {
          operatorApi
            .createOperator({
              name: values.name,
              email: values.email,
              role: values.role,
            })
            .then(data => {
              this.handleCompletion();
            });
        } else {
          operatorApi
            .updateOperator({
              id: operator.id,
              name: values.name,
              email: values.email,
              role: values.role,
            })
            .then(data => {
              this.handleCompletion();
            });
        }
      }
    });
  };

  handleCompletion = () => {
    const { onUpdate, onClose, form } = this.props;

    this.setState({ loading: false });
    form.resetFields();
    onUpdate();
    onClose();
  };

  render() {
    const { loading } = this.state;
    const { operator, visible, onClose, form } = this.props;

    const { getFieldDecorator } = form;

    return (
      <Modal
        visible={visible}
        title={operator ? 'Edit User' : 'Add User'}
        confirmLoading={loading}
        onOk={this.handleOnSubmit}
        onCancel={onClose}
        okButtonProps={{ htmlType: 'submit' }}
        okText={operator ? 'Save' : 'Add User'}
      >
        <Form>
          <Form.Item label="Full Name">
            {getFieldDecorator('name', {
              rules: [{ required: true, message: 'Please input operator Name' }],
              initialValue: operator && operator.name,
            })(<Input placeholder="Enter User Full Name" />)}
          </Form.Item>
          <Form.Item label="Email">
            {getFieldDecorator('email', {
              rules: [{ required: true, type: 'email', message: 'Please input valid operator email' }],
              initialValue: operator && operator.user.email,
            })(<Input placeholder="Enter Workmate email address." />)}
          </Form.Item>
          <Form.Item label="Role">
            {getFieldDecorator('role', {
              rules: [{ required: true, message: 'Please select operator role' }],
              initialValue: operator && operator.role,
            })(
              <Select placeholder="Select one" optionLabelProp="label">
                {roles.map(role => (
                  <Option value={role.value} label={role.title} key={role.value}>
                    <b>{role.title}</b>
                    <p>{role.description}</p>
                  </Option>
                ))}
              </Select>,
            )}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(OperatorFormModal);
