import React from 'react';
import { connect } from 'react-redux';
import { debounce, sortBy } from 'lodash';
import { Typography, Input, Button, Empty, Row, Col, Select } from 'antd';
import { withTranslation } from 'react-i18next';

import employmentApi from '../../../services/employmentApi';
import clientApi from '../../../services/clientApi';
import { clientStatuses, employmentStatuses } from '../../../constants';
import { colors } from '../../../styles/colors';
import { retrieveParamsFromUrl, updateUrlWithParams } from '../../../utilities/urlUtils';
import WorkforceTable from './components/WorkforceTable';
import TotalWorkforceCard from './components/TotalWorkforceCard';
import WorkforceStatsCard from './components/WorkforceStatsCard';
import CardWithCheckboxFilter from './components/CardWithCheckboxFilter';
import LocationPositionUpdateModal from './components/LocationPositionUpdateModal';
import WagesUpdateModal from './components/WagesUpdateModal';
import EndEmploymentModal from './components/EndEmploymentModal';
import EndingEmploymentsModal from './components/EndingEmploymentsModal';
import getCountryCode from '../../../utilities/getCountryCode';
import workforceEmpty from '../../../assets/images/empty.png';

const { Option } = Select;
const { Title, Text } = Typography;
const DEFAULT_PAGE_SIZE = 20;
const DEFAULT_ORDERING = 'partner__first_name';
const UNASSIGNED_OPTION_KEY = 'null';

class WorkforcePage extends React.Component {
  constructor(props) {
    super(props);
    this.searchFilter = React.createRef();
  }

  state = {
    loading: false,
    hasFetchedEmploymentStats: false,
    employments: [],
    hasFetchedClients: false,
    clients: [],
    selectedClient: undefined,
    positions: [],
    selectedPositions: [],
    locations: [],
    selectedLocations: [],
    selectedEmployments: [], // seperate list of checked employments
    showLocationPositionUpdateModal: false,
    showWagesUpdateModal: false,
    showEndEmploymentModal: false,
    showEndingEmploymentsModal: false,
    search: undefined,
    employmentStatistics: undefined,
    currentEmploymentsCount: 0,
    pageSize: DEFAULT_PAGE_SIZE,
    page: 1,
    ordering: DEFAULT_ORDERING, // sort by partner first name asc as default
  };

  async componentDidMount() {
    await this.fetchClients();
    await this.updateStateFromParams(); // Get params from url
    const { selectedClient } = this.state;
    if (selectedClient) {
      // if client id was passed in url params, select initial locations and positions
      this.getInitialSelectedLocationsAndPositions(selectedClient);
      await this.fetchEmploymentsAndStatistics();
    }
  }

  getInitialSelectedLocationsAndPositions = () => {
    const { selectedLocations, selectedPositions, selectedClient } = this.state;
    const { locationOptions, positionOptions } = this.getSelectedClientLocationsAndPositions(selectedClient);
    // Set locations and positions
    this.setState({ locations: locationOptions, positions: positionOptions });
    // If selected locations/positions are already passed in through URL params, use them instead.
    if (selectedLocations.length > 0 || selectedPositions.length > 0) {
      this.setState({
        selectedLocations,
        selectedPositions,
      });
    } else {
      // If not, select all locations and positions.
      this.setState({
        selectedLocations: locationOptions.map(({ id }) => id),
        selectedPositions: positionOptions.map(({ id }) => id),
      });
    }
  };

  fetchEmploymentsAndStatistics = async () => {
    await Promise.all([this.fetchEmployments(), this.fetchEmploymentStatistics()]);
  };

  getEmploymentFetchParams = () => {
    const { selectedPositions, selectedLocations, search, selectedClient } = this.state;
    return {
      position: selectedPositions.join(','),
      location: selectedLocations.join(','),
      search,
      client: selectedClient,
    };
  };

  /* Fetch and update employment stats */
  fetchEmploymentStatistics = async () => {
    this.updateParamsFromState(); // Update params when there is change in filter/page
    const { page, pageSize } = this.state;
    const params = {
      ...this.getEmploymentFetchParams(),
      page,
      page_size: pageSize,
      status: employmentStatuses.ACTIVE,
    };
    this.setState({ hasFetchedEmploymentStats: false });
    try {
      const employmentStatistics = await employmentApi.getStatistics(params);
      this.setState({
        hasFetchedEmploymentStats: true,
        currentEmploymentsCount: employmentStatistics.status_counts.active,
        employmentStatistics,
      });
    } catch (err) {
      this.setState({
        hasFetchedEmploymentStats: true,
        currentEmploymentsCount: 0,
        employmentStatistics: undefined,
      });
    }
  };

  /* Fetch and update employments */
  fetchEmployments = async () => {
    this.updateParamsFromState(); // Update params when there is change in filter/page
    const { page, pageSize, ordering } = this.state;
    const params = {
      ...this.getEmploymentFetchParams(),
      page,
      page_size: pageSize,
      status: employmentStatuses.ACTIVE,
      ordering: ordering || DEFAULT_ORDERING,
    };

    this.setState({ loading: true });
    try {
      const response = await employmentApi.fetchEmployments(params);
      this.setState({ loading: false, employments: response.results, currentEmploymentsCount: response.count });
    } catch (err) {
      this.setState({ loading: false, employments: [], currentEmploymentsCount: 0 });
    }
  };

  fetchClients = async (search = undefined) => {
    this.setState({ hasFetchedClients: false });
    try {
      const { results } = await clientApi.fetchClients({
        country: getCountryCode(),
        status: `${clientStatuses.ACTIVE},${clientStatuses.PILOT}`,
        search,
      });
      this.setState({ clients: results, hasFetchedClients: true });
    } catch (err) {
      this.setState({ clients: [], hasFetchedClients: false });
    }
  };

  debouncedFetchClients = debounce(this.fetchClients, 400);

  getOrdering = sorter => {
    const { field, order } = sorter;
    let sortField;
    if (field === 'worker') {
      sortField = 'partner__first_name';
    } else {
      sortField = field;
    }
    return order === 'descend' ? `-${sortField}` : sortField;
  };

  handleTableChange = (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    const ordering = this.getOrdering(sorter);
    this.setState(
      {
        page: current,
        selectedEmployments: [],
        pageSize,
        ordering,
      },
      async () => await this.fetchEmployments(),
    );
  };

  resetSearchFilter = async () => {
    // Use ref to clear search filter value
    await this.setState({ search: undefined }, () => {
      if (this.searchFilter.current) {
        // ensure that search filter has been rendered before reset, happens when we select client on initial page load
        this.searchFilter.current.input.state.value = undefined;
      }
    });
  };

  handleLocationsCheckboxChange = values => {
    this.resetSearchFilter();
    this.setState(
      { page: 1, selectedLocations: values, selectedEmployments: [] },
      async () => await this.fetchEmploymentsAndStatistics(),
    );
  };

  handlePositionsCheckboxChange = values => {
    this.resetSearchFilter();
    this.setState(
      { page: 1, selectedPositions: values, selectedEmployments: [] },
      async () => await this.fetchEmploymentsAndStatistics(),
    );
  };

  handleSelectAllLocations = () => {
    this.resetSearchFilter();
    const { selectedLocations, locations } = this.state;
    const allLocationsSelected = selectedLocations.length === locations.length;
    if (allLocationsSelected) {
      // unselect all selected locations
      this.setState(
        {
          selectedLocations: [],
          selectedEmployments: [],
        },
        async () => await this.fetchEmploymentsAndStatistics(),
      );
    } else {
      // select all locations
      this.setState(
        { selectedLocations: locations.map(({ id }) => id), selectedEmployments: [] },
        async () => await this.fetchEmploymentsAndStatistics(),
      );
    }
  };

  handleSelectAllPositions = () => {
    this.resetSearchFilter();
    const { selectedPositions, positions } = this.state;
    const allPositionsSelected = selectedPositions.length === positions.length;
    if (allPositionsSelected) {
      // unselect all selected positions
      this.setState(
        { selectedPositions: [], selectedEmployments: [] },
        async () => await this.fetchEmploymentsAndStatistics(),
      );
    } else {
      // select all positions
      this.setState(
        { selectedPositions: positions.map(({ id }) => id), selectedEmployments: [] },
        async () => await this.fetchEmploymentsAndStatistics(),
      );
    }
  };

  handleSearch = value => {
    this.setState({ page: 1, search: value, selectedEmployments: [] }, async () => await this.fetchEmployments());
  };

  onSearchChange = debounce(search => {
    this.handleSearch(search);
  }, 600);

  handleClientSelectionChange = selectedClient => {
    this.resetSearchFilter();
    const { locationOptions, positionOptions } = this.getSelectedClientLocationsAndPositions(selectedClient);
    this.setState(
      {
        selectedClient,
        locations: locationOptions,
        positions: positionOptions,
        selectedLocations: locationOptions.map(({ id }) => id),
        selectedPositions: positionOptions.map(({ id }) => id),
        selectedEmployments: [],
      },
      async () => await this.fetchEmploymentsAndStatistics(),
    );
  };

  hasDirectEmployment = () => {
    const { employments, selectedEmployments } = this.state;
    const filteredEmployments = employments.filter(employment => selectedEmployments.includes(employment.id));

    return filteredEmployments.some(employment => employment.direct_hire === true);
  };

  getSelectedClientLocationsAndPositions = selectedClient => {
    // Returns a list of location and position options based on selected client
    const { positions, t } = this.props;
    const { clients } = this.state;
    const client = clients.find(({ id }) => id === selectedClient);
    const locationOptions = [
      { id: UNASSIGNED_OPTION_KEY, name: t('unassigned') },
      ...sortBy(client?.locations, 'name'),
    ];
    const positionOptions = [{ id: UNASSIGNED_OPTION_KEY, name: t('unassigned') }, ...positions];
    return { locationOptions, positionOptions };
  };

  updateStateFromParams = async () => {
    const { position, location, search, ordering, client } = retrieveParamsFromUrl(this.props.location.search);
    this.setState({
      selectedPositions:
        (position &&
          position
            .split(',')
            .map(position => (position === UNASSIGNED_OPTION_KEY ? position : parseInt(position, 10)))) ||
        [],
      selectedLocations:
        (location &&
          location
            .split(',')
            .map(location => (location === UNASSIGNED_OPTION_KEY ? location : parseInt(location, 10)))) ||
        [],
      search,
      ordering: ordering || DEFAULT_ORDERING,
      selectedClient: parseInt(client, 10) || undefined,
    });
  };

  updateParamsFromState() {
    const { selectedPositions, selectedLocations, search, ordering, selectedClient } = this.state;
    updateUrlWithParams(
      {
        position: (selectedPositions && selectedPositions.join(',')) || undefined,
        location: (selectedLocations && selectedLocations.join(',')) || undefined,
        search: search || undefined,
        ordering,
        client: selectedClient,
      },
      this.props.history,
    );
  }

  handleEmploymentsSelectedChange = selectedEmployments => {
    this.setState({
      selectedEmployments,
    });
  };

  handleUpdateEmploymentsLocationPosition = async (selectedLocation, selectedPosition) => {
    const { selectedEmployments } = this.state;
    const requests = selectedEmployments.map(employmentId =>
      employmentApi.update(employmentId, {
        position: selectedPosition,
        location: selectedLocation,
      }),
    );

    await Promise.all(requests);

    await this.fetchEmployments(); //refresh
    this.setState({ showLocationPositionUpdateModal: false });
  };

  handleUpdateEmploymentsWages = async data => {
    const { selectedEmployments } = this.state;

    const requests = selectedEmployments.map(employmentId =>
      employmentApi.update(employmentId, {
        ...data,
      }),
    );

    await Promise.all(requests);

    await this.fetchEmployments(); //refresh
    this.setState({ showWagesUpdateModal: false });
  };

  handleEndEmployment = async data => {
    const { selectedEmployments } = this.state;
    const requests = selectedEmployments.map(employmentId =>
      employmentApi.cancelEmployment(employmentId, data.reason, data.notes),
    );

    await Promise.all(requests);
    await this.fetchEmploymentsAndStatistics();
    this.setState({ showEndEmploymentModal: false, selectedEmployments: [] }); // unselect employments since it's cancelled and will not appear in table
  };

  render() {
    const { t } = this.props;
    const {
      employments,
      employmentStatistics,
      currentEmploymentsCount,
      loading,
      hasFetchedEmploymentStats,
      page,
      selectedPositions,
      selectedLocations,
      showLocationPositionUpdateModal,
      showWagesUpdateModal,
      showEndEmploymentModal,
      showEndingEmploymentsModal,
      selectedEmployments,
      selectedClient,
      clients,
      hasFetchedClients,
      locations,
      positions,
    } = this.state;

    return (
      <>
        <Row
          type="flex"
          style={{
            marginBottom: '32px',
          }}
        >
          <Title level={2}>{t('Workforce')}</Title>
        </Row>
        <Row type="flex" gutter={32}>
          {/* Client/Location/Position Filters */}
          <Col span={5}>
            <Select
              showSearch
              placeholder={t('selectClient')}
              filterOption={false}
              onSearch={search => {
                this.debouncedFetchClients(search);
              }}
              onChange={this.handleClientSelectionChange}
              value={selectedClient}
              style={{ marginBottom: '16px', minWidth: 230, width: 230 }}
              className="workforce-client-select"
              loading={!hasFetchedClients}
            >
              {clients.length > 0 &&
                clients.map(({ id, name }) => (
                  <Option key={id} value={id}>
                    {name}
                  </Option>
                ))}
            </Select>
            <CardWithCheckboxFilter
              title={t('Locations')}
              selectAllTitle={t('allLocations')}
              toolTipTitle={t('locationFilterToolTip')}
              options={locations}
              selectedOptions={selectedLocations}
              onChange={this.handleLocationsCheckboxChange}
              onCheckAllChange={this.handleSelectAllLocations}
            />
            <CardWithCheckboxFilter
              title={t('Positions')}
              selectAllTitle={t('allPositions')}
              toolTipTitle={t('positionFilterToolTip')}
              options={positions}
              selectedOptions={selectedPositions}
              onChange={this.handlePositionsCheckboxChange}
              onCheckAllChange={this.handleSelectAllPositions}
              style={{ marginTop: '16px' }}
            />
          </Col>
          <Col span={19}>
            <Row type="flex" gutter={120} style={{ marginBottom: '48px' }}>
              {/* Cards Section */}
              <Col span={6}>
                <TotalWorkforceCard
                  loading={!hasFetchedEmploymentStats}
                  totalWorkforceCount={employmentStatistics?.status_counts.active || 0}
                  empty={!selectedClient}
                />
              </Col>
              <Col span={18}>
                <WorkforceStatsCard
                  loading={!hasFetchedEmploymentStats}
                  employmentStats={employmentStatistics}
                  empty={!selectedClient}
                  onEndingEmploymentsClick={() => {
                    this.setState({ showEndingEmploymentsModal: true });
                  }}
                />
              </Col>
            </Row>
            {(employments.length > 0 || employments.length === 0) && selectedClient ? (
              <>
                <Row type="flex" style={{ justifyContent: 'space-between', marginBottom: '24px' }}>
                  {/* Action Buttons */}
                  <div>
                    <Button
                      disabled={!selectedEmployments.length > 0}
                      onClick={() => this.setState({ showLocationPositionUpdateModal: true })}
                    >
                      {t('setLocationPosition')}
                    </Button>
                    <Button
                      disabled={!selectedEmployments.length > 0}
                      onClick={() => this.setState({ showWagesUpdateModal: true })}
                      style={{ marginLeft: 8 }}
                    >
                      {t('setWages')}
                    </Button>
                    <Button
                      disabled={!selectedEmployments.length > 0}
                      onClick={() => this.setState({ showEndEmploymentModal: true })}
                      style={{ marginLeft: 8, color: selectedEmployments.length > 0 && colors.functionalError }}
                    >
                      {t('endEmploymentTitle')}
                    </Button>
                    <Text style={{ marginLeft: 8, color: colors.darkGrey }}>
                      {selectedEmployments.length > 0 && t('numSelected', { num: selectedEmployments.length })}
                    </Text>
                  </div>
                  {/* Search Input */}
                  <Input.Search
                    loading={loading}
                    placeholder={t('searchWorker')}
                    style={{ width: 360 }}
                    allowClear
                    onSearch={this.handleSearch}
                    onChange={event => this.onSearchChange(event.target.value)}
                    ref={this.searchFilter}
                  />
                </Row>
                <Row style={{ maxWidth: '100%' }}>
                  <WorkforceTable
                    employments={employments}
                    selectedEmployments={selectedEmployments}
                    currentEmploymentsCount={currentEmploymentsCount}
                    loading={loading}
                    page={page}
                    defaultPageSize={DEFAULT_PAGE_SIZE}
                    onTableChange={this.handleTableChange}
                    onPageChange={this.handlePageChange}
                    onEmploymentsSelectedChange={this.handleEmploymentsSelectedChange}
                    testId="workforce-table"
                  />
                </Row>
                <LocationPositionUpdateModal
                  visible={showLocationPositionUpdateModal}
                  confirmLoading={loading}
                  onConfirm={this.handleUpdateEmploymentsLocationPosition}
                  closeModal={() => this.setState({ showLocationPositionUpdateModal: false })}
                  locations={locations.filter(({ id }) => id !== UNASSIGNED_OPTION_KEY)}
                />
                {showWagesUpdateModal && (
                  <WagesUpdateModal
                    visible={showWagesUpdateModal}
                    confirmLoading={loading}
                    onConfirm={this.handleUpdateEmploymentsWages}
                    closeModal={() => this.setState({ showWagesUpdateModal: false })}
                    currencyCode={clients.find(client => client.id === selectedClient).country.currency_code}
                  />
                )}
                <EndEmploymentModal
                  visible={showEndEmploymentModal}
                  workerCount={selectedEmployments.length}
                  confirmLoading={loading}
                  closeModal={() => {
                    this.setState({ showEndEmploymentModal: false });
                  }}
                  hasDirectEmployment={this.hasDirectEmployment()}
                  onConfirm={this.handleEndEmployment}
                />
                {showEndingEmploymentsModal && (
                  <EndingEmploymentsModal
                    visible={showEndingEmploymentsModal}
                    onUpdate={() => {
                      this.fetchEmploymentStatistics();
                    }}
                    closeModal={() => {
                      this.setState({ showEndingEmploymentsModal: false });
                    }}
                    selectedLocations={selectedLocations}
                    selectedPositions={selectedPositions}
                  />
                )}
              </>
            ) : (
              <Empty
                image={workforceEmpty}
                imageStyle={{ height: 200, alignContent: 'center', justifyContent: 'center' }}
                description={
                  <>
                    <Row style={{ marginTop: '32px' }}>
                      <Text style={{ fontSize: 20, fontWeight: 'bold' }}>{t('emptyWorkforceTitle')}</Text>
                    </Row>
                    <Row style={{ marginTop: '8px' }}>
                      <Text>{t('emptyWorkforceDescription')}</Text>
                    </Row>
                  </>
                }
              />
            )}
          </Col>
        </Row>
      </>
    );
  }
}

const mapStateToProps = state => ({
  positions: state.global.positions,
});

export default connect(
  mapStateToProps,
  null,
)(withTranslation()(WorkforcePage));
