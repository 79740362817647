import { Alert, Button, Col, DatePicker, Divider, message, Row, Select, Typography } from 'antd';
import moment from 'moment-timezone';
import React from 'react';
import { uniq } from 'lodash';
import { countryId, timesheetEntryPaymentStatuses, timesheetEntryStatuses } from '../../../constants';
import timesheetEntryApi from '../../../services/timesheetEntryApi';
import { checkAccess } from '../../../shared/access/Access';
import { permissions } from '../../../shared/access/accessConfig';
import getCountryCode from '../../../utilities/getCountryCode';
import withQueryAndGraphQLClient from '../../../graphql/helpers/withQueryAndGraphQLClient';

import ClientFilterButton from '../../shared/components/ClientFilterButton';
import ConfirmModals from '../../shared/components/ConfirmModals';
import StaffRequestFilterButton from '../../shared/components/StaffRequestFilterButton';
import TimesheetStatusFilterButton from '../../shared/components/TimesheetStatusFilterButton';
import WorkerFilterButton from '../../shared/components/WorkerFilterButton';
import TimesheetList from './components/TimesheetList';
import TimesheetDownloadButton from '../../shared/components/TimesheetDownloadButton';
import { GET_TIMESHEETS } from '../../../graphql/queries/timesheetEntry';
import TimesheetPaymentStatus from '../../shared/components/TimesheetPaymentStatus';

const { RangePicker } = DatePicker;
const { Text, Title } = Typography;

const pageSize = 20;

class TimesheetPage extends React.Component {
  state = {
    isLoading: false,
    timesheets: null,
    status: [],
    paymentStatus: [],
    partner: [],
    client: [],
    staff_request: [],
    search: undefined,
    ordering: '-created_date',
    page: 1,
    clock_in_time_after: undefined,
    clock_in_time_before: undefined,
    selectedTimesheetEntries: [],
    selectedAllFlag: false,
    selectedAllFilteredTimesheetEntriesFlag: false,
  };

  async componentDidMount() {
    await this.fetchTimesheetWithParams();
  }

  fetchTimesheetWithParams = async () => {
    const { graphQLClient, queryClient } = this.props;
    this.clearSelectionState();
    this.setState({ isLoading: true });
    const queryParams = this.getGQLQueryParams(this.state.status);
    const { page } = this.state;

    const { timesheetEntries } = await queryClient.fetchQuery(['timesheet_entries'], () =>
      graphQLClient.request({
        document: GET_TIMESHEETS,
        variables: {
          ...queryParams,
          first: pageSize,
          offset: pageSize * (page - 1),
        },
      }),
    );

    const timesheetEntryList = timesheetEntries?.edges?.map(item => {
      return item.node;
    });

    this.setState({
      isLoading: false,
      totalCount: timesheetEntries?.totalCount || 0,
      timesheets: timesheetEntryList,
    });
  };

  getGQLQueryParams = status => {
    const {
      partner,
      client,
      staff_request,
      search,
      clock_in_time_after,
      clock_in_time_before,
      ordering,
      paymentStatus,
    } = this.state;

    // convert all confirmed/unconfirmed statuses to "clocked_out"
    const newStatus = uniq(
      status.map(item =>
        item === timesheetEntryStatuses.CONFIRMED || item === timesheetEntryStatuses.UNCONFIRMED
          ? timesheetEntryStatuses.CLOCKED_OUT
          : item,
      ),
    );
    const country = getCountryCode() ? `${countryId[getCountryCode()]}` : null;
    const queryParams = {
      staffRequest: staff_request,
      status: newStatus,
      clockInTimeAfter: clock_in_time_after,
      clockInTimeBefore: clock_in_time_before,
      supervisorConfirmed: undefined,
      orderBy: ordering === '-clock_in_time' ? ['-clockInTime'] : ['-createdDate'],
      paymentStatus,
      search,
      partner,
      client,
      country,
    };

    // set supervisor_confirmed value specifically according to status filter
    if (status.includes(timesheetEntryStatuses.CONFIRMED) && !status.includes(timesheetEntryStatuses.UNCONFIRMED)) {
      queryParams.supervisorConfirmed = true;
    } else if (
      status.includes(timesheetEntryStatuses.UNCONFIRMED) &&
      !status.includes(timesheetEntryStatuses.CONFIRMED)
    ) {
      queryParams.supervisorConfirmed = false;
    }

    return queryParams;
  };

  getQueryParams = status => {
    const { partner, client, staff_request, search, ordering, clock_in_time_after, clock_in_time_before } = this.state;

    // convert all confirmed/unconfirmed statuses to "clocked_out"
    const newStatus = uniq(
      status.map(st => {
        if (st === timesheetEntryStatuses.CONFIRMED || st === timesheetEntryStatuses.UNCONFIRMED) {
          return timesheetEntryStatuses.CLOCKED_OUT;
        }
        return st;
      }),
    );

    const queryParams = {
      country: getCountryCode(),
      partner: partner.length > 0 ? partner.join(',') : undefined,
      client: client.length > 0 ? client.join(',') : undefined,
      staff_request: staff_request.length > 0 ? staff_request.join(',') : undefined,
      status: newStatus.length > 0 ? newStatus.join(',') : undefined,
      search,
      ordering,
      clock_in_time_after,
      clock_in_time_before,
    };

    // set supervisor_confirmed value specifically according to status filter
    if (status.includes(timesheetEntryStatuses.CONFIRMED) && !status.includes(timesheetEntryStatuses.UNCONFIRMED)) {
      queryParams.supervisor_confirmed = true;
    } else if (
      status.includes(timesheetEntryStatuses.UNCONFIRMED) &&
      !status.includes(timesheetEntryStatuses.CONFIRMED)
    ) {
      queryParams.supervisor_confirmed = false;
    }

    return queryParams;
  };

  handleStatusFilterChange = status => {
    this.setState({ status, page: 1 }, this.fetchTimesheetWithParams);
  };

  handlePaymentStatusFilterChange = paymentStatus => {
    this.setState({ paymentStatus, page: 1 }, this.fetchTimesheetWithParams);
  };

  handleWorkerFilterChange = partner => {
    this.setState({ partner, page: 1 }, this.fetchTimesheetWithParams);
  };

  handleClientFilterChange = client => {
    this.setState({ client, page: 1 }, this.fetchTimesheetWithParams);
  };

  handleStaffRequestFilterChange = staff_request => {
    // clean the input, attempt to parse out ids as much as possible
    const integerRegex = /\d+/;
    const staffRequestIds = staff_request.flatMap(sr_id => integerRegex.exec(sr_id)).filter(sr_id => sr_id !== null);

    this.setState({ staff_request: staffRequestIds, page: 1 }, this.fetchTimesheetWithParams);
  };

  handleDateChange = dates => {
    let before;
    let after;
    if (dates[0] && dates[1]) {
      after = moment(dates[0])
        .startOf('day')
        .toISOString();
      before = moment(dates[1])
        .endOf('day')
        .toISOString();
    }
    this.setState({ clock_in_time_after: after, clock_in_time_before: before }, () => this.fetchTimesheetWithParams());
  };

  handlePageChange = page => {
    this.setState({ page }, () => this.fetchTimesheetWithParams());
  };

  handleOrderingChange = ordering => {
    this.setState({ ordering, page: 1 }, () => this.fetchTimesheetWithParams());
  };

  handleSelectChange = rows => {
    if (rows.length === 0) this.setState({ selectedAllFlag: false, selectedAllFilteredTimesheetEntriesFlag: false });
    this.setState({ selectedTimesheetEntries: rows });
  };

  handleApprove = async () => {
    const { selectedTimesheetEntries, selectedAllFilteredTimesheetEntriesFlag } = this.state;

    if (selectedAllFilteredTimesheetEntriesFlag) {
      const status = this.state.status.filter(value =>
        [timesheetEntryStatuses.CLOCKED_OUT, timesheetEntryStatuses.RESOLVED].includes(value),
      );
      const queryParams = this.getQueryParams(status);

      await timesheetEntryApi.bulkApprove(queryParams);
      this.fetchTimesheetWithParams();
    } else {
      const listOfApprovePromises = selectedTimesheetEntries.map(timesheetEntry =>
        timesheetEntryApi.approve(timesheetEntry.id),
      );
      const responses = await Promise.all(listOfApprovePromises);
      if (responses) {
        // Deselect all rows  & update counts
        const selectedTimesheetIds = selectedTimesheetEntries.map(({ id }) => id);
        this.setState(prevState => {
          const updatedTimesheets = this.updateTimesheetStatus(
            prevState.timesheets,
            selectedTimesheetIds,
            timesheetEntryStatuses.APPROVED,
          );
          return {
            selectedTimesheetEntries: [],
            timesheets: updatedTimesheets,
          };
        });
        message.success(`Successfully approved ${selectedTimesheetEntries.length} timesheet entries`);
      }
    }
    this.clearSelectionState();
  };

  handleVoid = selectedTimesheet => {
    this.setState(prevState => {
      const updatedTimesheets = this.updateTimesheetStatus(
        prevState.timesheets,
        [selectedTimesheet.id],
        timesheetEntryStatuses.VOID,
        selectedTimesheet.voided_reason,
      );
      return { timesheets: updatedTimesheets };
    });
  };

  // special resolve logic using fresh data
  handleConfirm = updatedTimesheet => {
    this.setState(prevState => {
      const updatedTimesheets = this.updateTimesheetStatus(
        prevState.timesheets,
        [updatedTimesheet.id],
        updatedTimesheet.status,
        updatedTimesheet.voided_reason,
        updatedTimesheet.supervisor_confirmed,
      );
      return { timesheets: updatedTimesheets };
    });
  };

  handleSingleApprove = updatedTimesheet => {
    this.setState(prevState => {
      const updatedTimesheets = this.updateTimesheetStatus(
        prevState.timesheets,
        [updatedTimesheet.id],
        timesheetEntryStatuses.APPROVED,
        updatedTimesheet.voided_reason,
        updatedTimesheet.supervisor_confirmed,
      );
      return { timesheets: updatedTimesheets };
    });
  };

  handleSelectAll = timesheetEntries => {
    const deselectFlag = timesheetEntries.length === 0;
    if (deselectFlag) this.setState({ selectedAllFilteredTimesheetEntriesFlag: false });
    this.setState({ selectedAllFlag: !deselectFlag });
  };

  handleSelectAllFilteredTimesheetEntries = () => {
    this.setState({ selectedAllFilteredTimesheetEntriesFlag: true });
  };

  handleClearSelection = () => {
    this.clearSelectionState();
  };

  clearSelectionState = () => {
    this.setState({
      selectedTimesheetEntries: [],
      selectedAllFlag: false,
      selectedAllFilteredTimesheetEntriesFlag: false,
    });
  };

  getApproveCount = () => {
    const { selectedTimesheetEntries } = this.state;
    const unapproveTimesheetEntries = selectedTimesheetEntries.filter(
      item => item.status !== timesheetEntryStatuses.APPROVED,
    );
    return unapproveTimesheetEntries.length;
  };

  getConfirmedAndApprovedTimesheetEntries = () => {
    const { selectedTimesheetEntries } = this.state;
    const confirmedAndApprovedTimesheetEntries = selectedTimesheetEntries.filter(
      item => item.supervisorConfirmed === true || item.status === timesheetEntryStatuses.APPROVED,
    );
    return confirmedAndApprovedTimesheetEntries;
  };

  // This is used to avoid re-fetching the entire table, but to update the worker table in our state.
  updateTimesheetStatus = (timesheets, timesheetIds, newStatus, newReason = null, supervisor_confirmed) => {
    for (let i = 0; i < timesheets.length; i += 1) {
      if (timesheetIds.includes(timesheets[i].id)) {
        timesheets[i].status = newStatus;
        if (newReason) timesheets[i].voided_reason = newReason;
        if (supervisor_confirmed) timesheets[i].supervisor_confirmed = supervisor_confirmed;
      }
    }
    this.clearSelectionState();
    return timesheets;
  };

  handleMarkInprogress = async () => {
    const { selectedAllFilteredTimesheetEntriesFlag } = this.state;
    try {
      if (selectedAllFilteredTimesheetEntriesFlag) {
        const status = this.state.status.filter(value =>
          [timesheetEntryStatuses.CONFIRMED, timesheetEntryStatuses.APPROVED].includes(value),
        );
        const queryParams = this.getQueryParams(status);
        await timesheetEntryApi.bulkMarkInProgress(queryParams);
        this.fetchTimesheetWithParams();
        message.success(`Successfully marked timesheet entries as in progress`);
      } else {
        const confirmedAndApprovedTimesheetEntries = this.getConfirmedAndApprovedTimesheetEntries();
        const timesheetEntryIds = confirmedAndApprovedTimesheetEntries.map(item => item.id);
        await timesheetEntryApi.bulkMarkInProgress({ id: timesheetEntryIds.toString() });
        this.fetchTimesheetWithParams();
        message.success(
          `Successfully marked ${confirmedAndApprovedTimesheetEntries.length} timesheet entries as in progress`,
        );
      }
    } catch (error) {
      message.error('Server is busy, please try again later.');
    }
    this.clearSelectionState();
  };

  render() {
    const {
      isLoading,
      timesheets,
      partner,
      client,
      staff_request,
      page,
      selectedAllFlag,
      selectedAllFilteredTimesheetEntriesFlag,
      status,
      totalCount,
      selectedTimesheetEntries,
    } = this.state;
    const selectAllCount = selectedAllFilteredTimesheetEntriesFlag ? totalCount : 0;
    const approveCount =
      this.getApproveCount() > 0 && !selectedAllFilteredTimesheetEntriesFlag ? this.getApproveCount() : selectAllCount;

    // NOTE:
    // Allow marking payment entries as in-progress for:
    // - Unpaid entries that are confirmed as clocked out by supervisor
    // - Unpaid entries that are approved
    const markInProgressTotalCount = this.getConfirmedAndApprovedTimesheetEntries().reduce(
      (sum, item) =>
        item.paymentStatus === timesheetEntryPaymentStatuses.UNPAID &&
        ((item.status === timesheetEntryStatuses.CLOCKED_OUT && item.supervisorConfirmed) ||
          item.status === timesheetEntryStatuses.APPROVED)
          ? sum + 1
          : sum,
      0,
    );
    const markInProgressCount =
      markInProgressTotalCount > 0 && !selectedAllFilteredTimesheetEntriesFlag
        ? markInProgressTotalCount
        : selectAllCount;
    const hasSelectedRows = approveCount > 0;

    return (
      <>
        {/* Header & Create button */}
        <Row type="flex" justify="space-between" style={{ marginBottom: '32px' }}>
          <Col>
            <Title level={3}>Timesheets</Title>
          </Col>
        </Row>

        {/* Period Filters */}
        <Row type="flex" justify="space-between" style={{ marginBottom: '16px' }}>
          <Col span={6}>
            <RangePicker
              ranges={{
                Today: [moment(), moment()],
                '7 days ago': [moment().subtract(7, 'd'), moment()],
                '14 days ago': [moment().subtract(14, 'd'), moment()],
                '30 days ago': [moment().subtract(30, 'd'), moment()],
              }}
              onChange={this.handleDateChange}
              format="DD/MM/YYYY"
            />
          </Col>
          <Col>
            <TimesheetDownloadButton params={this.getQueryParams(status)} />
          </Col>
        </Row>

        {/* Status & Workers filter */}
        <Row type="flex" gutter={8}>
          <Col span={3}>
            <TimesheetStatusFilterButton onApply={this.handleStatusFilterChange} initialOptions={status} />
          </Col>
          <Col span={3}>
            <WorkerFilterButton onSelectChange={this.handleWorkerFilterChange} selectedOptions={partner} />
          </Col>
          <Col span={3}>
            <ClientFilterButton onSelectChange={this.handleClientFilterChange} selectedOptions={client} />
          </Col>
          <Col span={3}>
            <StaffRequestFilterButton
              onSelectChange={this.handleStaffRequestFilterChange}
              selectedOptions={staff_request}
            />
          </Col>
          <Col span={3}>
            <TimesheetPaymentStatus onApply={this.handlePaymentStatusFilterChange} initialOptions={status} />
          </Col>
        </Row>
        <Divider />

        {/* Bulk action buttons for table */}
        <Row type="flex" gutter={8} style={{ marginBottom: '32px' }}>
          <Col span={4}>
            {checkAccess(permissions.bulkApproveTimesheet) && (
              <Button
                block
                type="v2-primary"
                disabled={approveCount === 0 && !selectedAllFilteredTimesheetEntriesFlag}
                style={hasSelectedRows ? {} : { backgroundColor: 'inherit !important' }}
                onClick={() => ConfirmModals.approveTimesheetEntries(approveCount, this.handleApprove)}
              >
                {`Approve ${approveCount > 0 ? `(${approveCount})` : ''}`}
              </Button>
            )}
          </Col>
          <Col span={5}>
            <Button
              block
              type="v2-primary"
              disabled={markInProgressCount === 0 && !selectedAllFilteredTimesheetEntriesFlag}
              style={hasSelectedRows ? {} : { backgroundColor: 'inherit !important' }}
              onClick={() =>
                ConfirmModals.markInprogressTimesheetEntries(markInProgressCount, this.handleMarkInprogress)
              }
            >
              {`Mark as in-progress ${markInProgressCount > 0 ? `(${markInProgressCount})` : ''}`}
            </Button>
          </Col>

          <Col style={{ marginLeft: 'auto' }}>
            <Text type="secondary">Sort by</Text>
            <Select
              defaultValue="-created_date"
              style={{ marginLeft: '8px', width: '148px' }}
              onChange={orderByValue => this.handleOrderingChange(orderByValue)}
            >
              <Select.Option value="-created_date">Most recent</Select.Option>
              <Select.Option value="-clock_in_time">Clock in date</Select.Option>
            </Select>
          </Col>
        </Row>
        {selectedAllFlag && checkAccess(permissions.bulkApproveTimesheet) ? (
          <Row style={{ marginBottom: '16px' }}>
            <Alert
              message={
                <>
                  {!selectedAllFilteredTimesheetEntriesFlag ? (
                    <>
                      All {selectedTimesheetEntries.length} timesheet entries on this page are selected.
                      <Text
                        strong
                        style={{ cursor: 'pointer', color: '#1890FF', marginLeft: '5px' }}
                        onClick={this.handleSelectAllFilteredTimesheetEntries}
                      >
                        Select all {totalCount} timesheet entries
                      </Text>
                    </>
                  ) : (
                    <>
                      All {totalCount} timesheet entries are selected.
                      <Text
                        strong
                        style={{ cursor: 'pointer', color: '#1890FF', marginLeft: '5px' }}
                        onClick={this.handleClearSelection}
                      >
                        Clear selection
                      </Text>
                    </>
                  )}
                </>
              }
              type="info"
            />
          </Row>
        ) : (
          ''
        )}

        <TimesheetList
          page={page}
          loading={isLoading}
          dataSource={timesheets}
          count={this.state.totalCount}
          onVoid={this.handleVoid}
          onEdit={this.fetchTimesheetWithParams}
          onConfirm={this.handleConfirm}
          onApprove={this.handleSingleApprove}
          onPageChange={this.handlePageChange}
          onSelectChange={this.handleSelectChange}
          onSelectAll={this.handleSelectAll}
          selectedTimesheetEntries={selectedTimesheetEntries}
        />
      </>
    );
  }
}

export default withQueryAndGraphQLClient(TimesheetPage);
