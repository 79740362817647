// TODO: Refactor out into helpster-components library
/* eslint-disable no-else-return */
import React from 'react';
import { Card, Button, Select, Row } from 'antd';

const { Option } = Select;

class FilterWithSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopover: false,
    };
    this.filterWithSearchNode = React.createRef();
    this.id = `filter-with-search-${Date.now()}`;
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick, false);
  }

  handleClick = event => {
    // If click happens inside filter component, we don't toggle & return
    if (this.filterWithSearchNode.current.contains(event.target)) {
      return;
    }
    // A click happened outside. "Close" our filter if it is opened.
    if (this.state.showPopover) {
      this.togglePopover();
    }
  };

  togglePopover = () => {
    this.setState(prevState => {
      return { showPopover: !prevState.showPopover };
    });
  };

  render() {
    const {
      title,
      options,
      optionLabelField,
      onSelectChange,
      onSearch,
      loading,
      placeholder = 'Search here',
      value,
    } = this.props;
    const { showPopover } = this.state;
    const shouldHideCard = showPopover ? '' : 'none';

    const values =
      value &&
      value.map(key => {
        const option = options && options.find(option => option.key === key);
        const label = (option && option.name) || `...(${key})`;
        return {
          key,
          value: key,
          label,
        };
      });

    return (
      // Append ref to the parent <div> to detect clicks inside this component
      <div ref={this.filterWithSearchNode}>
        <Row>
          <Button block onClick={() => this.togglePopover()}>
            {title}
          </Button>
          <Card
            style={{ display: shouldHideCard, position: 'absolute', zIndex: 2, width: '200%', marginTop: '8px' }}
            bodyStyle={{ padding: '4px 4px 8px 4px', backgroundColor: 'white' }}
            id={this.id}
            className="v2-select-wrapper"
          >
            <Select
              showSearch
              labelInValue
              mode="multiple"
              optionFilterProp="filter"
              notFoundContent="Not Found"
              open={showPopover}
              onChange={onSelectChange}
              onSearch={onSearch}
              loading={loading}
              placeholder={placeholder}
              style={{ width: '100%', marginBottom: '4px' }}
              dropdownStyle={{ padding: '4px', boxShadow: 'none' }}
              getPopupContainer={() => document.getElementById(this.id)}
              value={values}
            >
              {options &&
                options.map(option => (
                  <Option key={option.id || option.key} filter={option[optionLabelField]}>
                    {option[optionLabelField]}
                  </Option>
                ))}
            </Select>
          </Card>
        </Row>
      </div>
    );
  }
}
export default FilterWithSearch;
