import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import styled from 'styled-components';
import { colors } from '../../../styles/colors';
import { fontO14r } from '../../../styles/fonts';

const CustomDropdown = styled(Dropdown)`
  border: 1px solid ${colors.grey};
  border-radius: 4px;

  &.ui.search.selection.dropdown,
  &.ui.selection.dropdown {
    ${fontO14r};
    padding: 10px 15px;
  }

  &.ui.search.selection.dropdown > input.search,
  &.ui.search.dropdown.active > input.search,
  &.ui.search.dropdown.visible > input.search,
  &.ui.search.dropdown > .text {
    ${fontO14r};
  }
`;

export default ({ ...props }) => <CustomDropdown fluid selection {...props} />;
