import React from 'react';
import moment from 'moment-timezone';
import { message, Typography, Button, Row, Col, Divider, Table } from 'antd';

import leaveApi from '../../services/leaveApi';
import { leaveAndSickStatuses } from '../../constants';
import getPaginationConfig from '../utilities/getPaginationConfig';
import textUtils from '../utilities/textUtils';

// Should be in HC library
import ConfirmModals from '../shared/components/ConfirmModals';
import CardWithCount from '../shared/components/CardWithCount';
import StatusTag from '../shared/components/StatusTag';
import DropdownButtons from '../shared/components/DropdownButtons';
import RejectLeaveClaimModal from '../shared/components/RejectLeaveClaimModal';
import ToolTipWithInfo from '../shared/components/ToolTipWithInfo';
import CheckboxFilter from '../shared/components/CheckboxFilter';
import FilterWithSearch from '../shared/components/FilterWithSearch';
import { checkAccess } from '../../shared/access/Access';
import { permissions } from '../../shared/access/accessConfig';

const { Text } = Typography;
class LeaveClaimsList extends React.Component {
  state = {
    isLoading: false,
    hasFetchedLeaveClaimCounts: false,
    leaveClaims: null,
    numPending: 0,
    numApproved: 0,
    numRejected: 0,
    numRecords: 0,
    searchParams: {
      status: `${Object.values(leaveAndSickStatuses).join(',')}`,
    },
    selectedRowKeys: [],
    allWorkers: [],
    showRejectModal: false,
    selectedLeaveClaim: {},
  };

  columns = [
    {
      title: 'Name',
      dataIndex: 'partner',
      render: text => <Text style={{ fontSize: '14px' }}>{`${text.first_name} ${text.last_name}`}</Text>,
    },
    {
      title: 'Date requested',
      dataIndex: 'created_date',
      render: text => <Text style={{ fontSize: '14px' }}>{moment(text).format('ddd, DD MMM YYYY')}</Text>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text, record) => (
        <Row type="flex" justify="space-between" align="middle">
          <Col>
            <StatusTag status={text} />
            {record.notes && <ToolTipWithInfo infoText={record.notes} />}
          </Col>
          {text === leaveAndSickStatuses.REQUESTED && (
            <Col>
              <DropdownButtons
                size="small"
                buttons={[
                  {
                    title: 'Reject',
                    onClick: () => this.setState({ showRejectModal: true, selectedLeaveClaim: record }),
                    style: { color: '#FF5251' },
                  },
                ]}
              />
            </Col>
          )}
        </Row>
      ),
    },
  ];

  async componentDidMount() {
    this.setState({ isLoading: true });
    const response = await leaveApi.fetchLeaveEntries({
      staff_request: this.props.staffRequestId,
      page: 1,
      include_workers: true,
    });
    const { results, status_counts, all_workers } = response;
    this.setState({
      isLoading: false,
      hasFetchedLeaveClaimCounts: true,
      leaveClaims: results,
      numPending: status_counts.pending_approval,
      numApproved: status_counts.approved,
      numRejected: status_counts.rejected,
      numRecords: status_counts.pending_approval + status_counts.approved + status_counts.rejected,
      allWorkers: all_workers.map(worker => {
        return { name: `${worker.first_name} ${worker.last_name}`, id: worker.id };
      }),
    });
  }

  onSelectAll = (_, selectedRows) => {
    // Remove keys that are not allowed to be selected
    // Specifically, we only allow "Requested" leave claims to be selected
    const filteredSelectedRowKeys = selectedRows
      .filter(({ status }) => {
        return status === leaveAndSickStatuses.REQUESTED;
      })
      .map(({ id }) => id);

    // BUG: AntD calls this function with an array of size > 0 even with de-select
    // Check if length of selected rows have changed. If no change, de-select must have been selected & we clear selected keys
    if (filteredSelectedRowKeys.length === this.state.selectedRowKeys.length) {
      this.setState({ selectedRowKeys: [] });
      return;
    }
    this.setState({ selectedRowKeys: filteredSelectedRowKeys });
  };

  onPageChange = pageNum => {
    this.setState(
      prevState => {
        const nextSearchParams = prevState.searchParams;
        nextSearchParams.page = pageNum;
        return { searchParams: nextSearchParams };
      },
      () => this.fetchClaimsWithParams(),
    );
  };

  fetchClaimsWithParams = async () => {
    this.setState({ isLoading: true });
    const response = await leaveApi.fetchLeaveEntries({
      staff_request: this.props.staffRequestId,
      ...this.state.searchParams,
    });
    this.setState(prevState => ({
      isLoading: false,
      leaveClaims: response.results,
      numRecords: response.count || prevState.numRecords,
    }));
  };

  onApproveLeaveClaims = async () => {
    const { selectedRowKeys } = this.state;
    const listOfLeaveClaimPromises = selectedRowKeys.map(key => leaveApi.approve(key));
    const responses = await Promise.all(listOfLeaveClaimPromises);
    if (responses) {
      // Now deselect all row keys, & update number of approved
      this.setState(prevState => {
        const nextLeaveClaims = this.updateLeaveClaimStatus(
          prevState.leaveClaims,
          selectedRowKeys,
          leaveAndSickStatuses.APPROVED,
        );
        return {
          selectedRowKeys: [],
          leaveClaims: nextLeaveClaims,
          numApproved: prevState.numApproved + selectedRowKeys.length,
        };
      });
      message.success(`Successfully approved ${selectedRowKeys.length} leave claims`);
    }
  };

  onReject = async () => {
    // TODO: Error handling missing
    this.setState(prevState => {
      const nextLeaveClaims = this.updateLeaveClaimStatus(
        prevState.leaveClaims,
        [prevState.selectedLeaveClaim.id],
        leaveAndSickStatuses.REJECTED,
      );
      return {
        showRejectModal: false,
        leaveClaims: nextLeaveClaims,
        numRejected: prevState.numRejected + 1,
      };
    });
  };

  handleAllFilterSelectChange = (field, checkedValues, allOptions) => {
    const allOptionsSelected = checkedValues.length === allOptions.length;
    const { searchParams } = this.state;
    const nextSearchParams = searchParams;
    // De-select all
    if (allOptionsSelected) {
      nextSearchParams[field] = undefined;
    } else {
      // Select all available options
      nextSearchParams[field] = allOptions.map(({ id }) => id).join(',');
    }
    this.setState({ searchParams: nextSearchParams });
  };

  handleFilterSelectChange = (field, values) => {
    this.setState(
      prevState => {
        const nextSearchParams = prevState.searchParams;
        nextSearchParams[field] = values.join(',');
        nextSearchParams.page = 1;
        return { searchParams: nextSearchParams };
      },
      () => this.fetchClaimsWithParams(),
    );
  };

  getStatusFilter = () => {
    const leaveStatusOptions = Object.values(leaveAndSickStatuses).map(offerStatus => {
      return { id: offerStatus, name: textUtils.makeFriendly(offerStatus).split(' ')[0] };
    });
    const { searchParams } = this.state;
    const { status } = searchParams;
    const statusCheckedList = status ? status.split(',') : [];
    return {
      title: 'Status',
      selectAllTitle: 'All status',
      selectedOptions: statusCheckedList,
      options: leaveStatusOptions,
      FilterComponent: CheckboxFilter,
      onChange: values => {
        const joinedValues = values.join(',');
        this.setState(prevState => {
          const nextSearchParams = prevState.searchParams;
          nextSearchParams.status = joinedValues;
          return { searchParams: nextSearchParams };
        });
      },
      onCheckAllChange: () => this.handleAllFilterSelectChange('status', statusCheckedList, leaveStatusOptions),
      onApply: () => {
        this.setState(
          prevState => {
            const nextSearchParams = prevState.searchParams;
            nextSearchParams.page = 1;
            return { searchParams: nextSearchParams };
          },
          () => this.fetchClaimsWithParams(),
        );
      },
    };
  };

  getFilterWithSearch = () => {
    const { allWorkers } = this.state;
    return {
      title: 'Workers',
      options: allWorkers,
      onSelectChange: values => {
        const selectedPartnerIds = values.map(({ key }) => key);
        this.handleFilterSelectChange('partner', selectedPartnerIds);
      },
    };
  };

  getStatusCounts = () => {
    return [
      { title: 'Pending', num: this.state.numPending, hasBadge: this.state.numPending > 0 },
      { title: 'Approved', num: this.state.numApproved, hasBadge: false },
      { title: 'Rejected', num: this.state.numRejected, hasBadge: false },
    ];
  };

  // This is used to avoid re-fetching the entire table, but to update the worker table in our state.
  updateLeaveClaimStatus = (leaveClaims, leaveClaimIds, nextStatus) => {
    for (let i = 0; i < leaveClaims.length; i += 1) {
      if (leaveClaimIds.includes(leaveClaims[i].id)) {
        leaveClaims[i].status = nextStatus;
      }
    }
    return leaveClaims;
  };

  render() {
    const {
      isLoading,
      leaveClaims,
      numRecords,
      selectedRowKeys,
      searchParams,
      hasFetchedLeaveClaimCounts,
      showRejectModal,
      selectedLeaveClaim,
    } = this.state;
    const statusFilter = this.getStatusFilter();
    const workersFilter = this.getFilterWithSearch();
    const numSelectedRows = selectedRowKeys.length;
    const totalPartnerIdSearched = (searchParams.partner && searchParams.partner.split(',').length) || 0;
    // TODO: Count number of applied filters
    return (
      <>
        {/* Top level summary */}
        <CardWithCount loading={!hasFetchedLeaveClaimCounts} countList={this.getStatusCounts()} />

        {/* Filters */}
        <Row type="flex" gutter={8}>
          <Col span={4}>
            <CheckboxFilter
              showActionButtons
              title={`${statusFilter.title} ${
                statusFilter.selectedOptions.length > 0 ? `(${statusFilter.selectedOptions.length})` : ''
              }`}
              options={statusFilter.options}
              selectedOptions={statusFilter.selectedOptions}
              selectAllTitle={statusFilter.selectAllTitle}
              onChange={statusFilter.onChange}
              onCheckAllChange={statusFilter.onCheckAllChange}
              indeterminate={
                statusFilter.selectedOptions.length > 0 &&
                statusFilter.selectedOptions.length < statusFilter.options.length
              }
              allChecked={statusFilter.selectedOptions.length === statusFilter.options.length}
              onApply={statusFilter.onApply}
              optionLabelField="name"
            />
          </Col>
          <Col span={4}>
            <FilterWithSearch
              title={`${workersFilter.title} ${totalPartnerIdSearched > 0 ? `(${totalPartnerIdSearched})` : ''}`}
              options={workersFilter.options}
              onSelectChange={workersFilter.onSelectChange}
              defaultValue={workersFilter.defaultValue}
              placeholder="Search by Workmate"
              optionLabelField="name"
            />
          </Col>
        </Row>
        <Divider />

        {/* Action buttons for table */}
        {checkAccess(permissions.bulkApproveTimesheet) && (
          <Row type="flex" gutter={8} style={{ marginBottom: '32px' }}>
            <Col span={4}>
              <Button
                disabled={numSelectedRows === 0}
                block
                type="v2-primary"
                onClick={() => ConfirmModals.approveLeaveClaims(numSelectedRows, () => this.onApproveLeaveClaims())}
              >
                {`Approve ${numSelectedRows > 0 ? `(${numSelectedRows})` : ''}`}
              </Button>
            </Col>
          </Row>
        )}

        {/* Table of leave claims */}
        <Table
          loading={isLoading}
          columns={this.columns}
          dataSource={leaveClaims}
          rowKey="id"
          pagination={{ ...getPaginationConfig(numRecords, this.onPageChange), current: searchParams.page || 1 }}
          rowSelection={{
            selectedRowKeys,
            onSelectAll: this.onSelectAll,
            onChange: keys => this.setState({ selectedRowKeys: keys }),
          }}
          rowClassName={record => {
            if (record.status !== leaveAndSickStatuses.REQUESTED) {
              return 'disable-table-row';
            }
          }}
        />

        {/* Modals */}
        <RejectLeaveClaimModal
          visible={showRejectModal}
          onCancel={() => this.setState({ showRejectModal: false })}
          onComplete={() => this.onReject()}
          leaveClaim={selectedLeaveClaim}
        />
      </>
    );
  }
}

export default LeaveClaimsList;
