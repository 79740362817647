import { ErrorMessage, FieldArray, Form, Formik } from 'formik';
import moment from 'moment';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { Icon, Rating } from 'semantic-ui-react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { message } from 'antd';
import { countries, errorCodes, FundTypes, RaceTypes, ResidencyTypes } from '../../../constants';
import routes from '../../../routes';
import bankApi from '../../../services/bankApi';
import locationApi from '../../../services/locationApi';
import partnerApi from '../../../services/partnerApi';
import Button from '../../../shared/components/Button';
import Card from '../../../shared/components/Card';
import AddButton from '../../../shared/components/forms/AddButton';
import CertificateInput from '../../../shared/components/forms/CertificateInput';
import CheckboxGroup from '../../../shared/components/forms/CheckboxGroup';
import DatePicker from '../../../shared/components/forms/DatePicker';
import Dropdown from '../../../shared/components/forms/Dropdown';
import EducationInput from '../../../shared/components/forms/EducationInput';
import ExperienceInput from '../../../shared/components/forms/ExperienceInput';
import Field from '../../../shared/components/forms/Field';
import FormContainer from '../../../shared/components/forms/FormContainer';
import FormGroup from '../../../shared/components/forms/FormGroup';
import ImagePicker from '../../../shared/components/forms/ImagePicker';
import ImagePreview from '../../../shared/components/forms/ImagePreview';
import PhoneInput from '../../../shared/components/forms/PhoneInput';
import PlacesAutocomplete from '../../../shared/components/forms/PlacesAutocomplete';
import PositionDropdown from '../../../shared/components/forms/PositionDropdown';
import RadioGroup from '../../../shared/components/forms/RadioGroup';
import RemoteDropdown from '../../../shared/components/forms/RemoteDropdown';
import RemoveButton from '../../../shared/components/forms/RemoveButton';
import { Header1 } from '../../../shared/components/headers';
import CustomLoader from '../../../shared/components/Loader';
import ProfilePhoto from '../../../shared/components/ProfilePhoto';
import { colors } from '../../../styles/colors';
import getCountryCode from '../../../utilities/getCountryCode';
import Maps from '../../../v2/shared/components/Maps';
import religionApi from '../../../services/religionApi';

const CustomHeader1 = styled(Header1)`
  display: flex;
  align-items: center;
  span {
    margin-right: 15px;
  }
`;

const RepeatableInput = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${colors.lightGrey};
  padding: 15px 0;
  margin-bottom: 20px;

  ${RemoveButton} {
    margin-left: 20px;
  }
`;

const Actions = styled.div``;

const CardContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 20px;
`;

const SubmitButton = styled(Button)`
  margin-right: 1rem !important;
`;

const CustomFormContainer = styled(FormContainer)`
  ${CustomHeader1} {
    grid-column: 1 / span 1;
  }

  ${Actions} {
    grid-column: 2 / span 1;
    justify-self: flex-end;
    align-items: center;
  }
`;

const JakartaLatLng = {
  lat: -6.2087634,
  lng: 106.84559899999999,
};

const BangkokLatLng = {
  lat: 13.7563309,
  lng: 100.50176510000006,
};

const defaultExperience = {
  start_year: '',
  start_month: '',
  end_year: '',
  end_month: '',
  company_name: '',
  position: undefined,
};

const defaultEducation = {
  graduation_year: undefined,
  field_of_study: '',
  degree: '',
  school: '',
};

const defaultCertificate = {
  name: '',
  institution: '',
  year: undefined,
};

const CustomProfilePhoto = styled(ProfilePhoto)``;

const initialValues = {
  first_name: '',
  last_name: '',
  date_of_birth: undefined,
  gender: 'male',
  identification_number: '',
  email: '',
  mobile: '',
  home_phone: '',
  image: undefined,
  country: null,
  address: {
    street_1: '',
    latitude: getCountryCode() === 'ID' ? JakartaLatLng.lat : BangkokLatLng.lat,
    longitude: getCountryCode() === 'ID' ? JakartaLatLng.lng : BangkokLatLng.lng,
  },
  has_car: false,
  has_motorcycle: false,
  english_proficiency: 1,
  bank_account: {
    bank: null,
    full_name: '',
    number: '',
  },
  approved_positions: [],
  preferred_positions: [],
  preferred_job_areas: [],
  experiences: [],
  educations: [],
  certificates: [],
  tax_id: '',
  // Sensitive Info For SG workers
  nationality: '',
  race: '',
  religion: '',
  residency: '',
  permanent_resident_date: '',
  fund_type: '',
};

const decomposePartner = partner => {
  return {
    first_name: partner.first_name,
    last_name: partner.last_name,
    date_of_birth: partner.date_of_birth || '',
    gender: partner.gender,
    identification_number: partner.identification_number,
    email: partner.email,
    mobile: partner.mobile,
    home_phone: partner.home_phone,
    image: partner.image,
    id_card: partner.id_card,
    country: partner.country.id,
    city: partner.city && partner.city.id,
    address: {
      ...partner.address,
      street_1: partner.address && partner.address.street_1,
      country: partner.address && partner.address.country.id,
    },
    has_car: partner.has_car ? 1 : 0,
    has_motorcycle: partner.has_motorcycle ? 1 : 0,
    english_proficiency: partner.english_proficiency,
    bank_account: partner.bank_account && {
      id: partner.bank_account.id,
      bank: partner.bank_account.bank ? partner.bank_account.bank.id : '',
      full_name: partner.bank_account.full_name,
      number: partner.bank_account.number,
    },
    approved_positions: partner.approved_positions.map(pos => pos.id),
    preferred_positions: partner.preferred_positions.map(pos => pos.id),
    preferred_job_areas: partner.preferred_job_areas.map(area => area.id),
    experiences: partner.experiences.map(exp => ({
      id: exp.id,
      start_year: exp.start_year || '',
      start_month: exp.start_month || '',
      end_year: exp.end_year || '',
      end_month: exp.end_month || '',
      company_name: exp.company_name,
      position: exp.position.id,
    })),
    educations: partner.educations.map(edu => ({
      id: edu.id,
      graduation_year: edu.graduation_year,
      field_of_study: edu.field_of_study,
      degree: edu.degree,
      school: edu.school,
    })),
    certificates: partner.certificates.map(cert => ({
      id: cert.id,
      name: cert.name,
      institution: cert.institution,
      year: cert.year,
    })),
    tax_id: partner.tax_id,
    // Sensitive Info For SG workers
    nationality: partner.nationality?.id,
    race: partner.race,
    religion: partner.religion?.id,
    residency: partner.residency,
    permanent_resident_date: partner.permanent_resident_date,
    fund_type: partner.fund_type,
    secondary_race: partner.secondary_race,
  };
};

class PartnerForm extends React.Component {
  state = {
    showLoader: false,
  };

  componentDidMount() {
    this.fetchNationalities();
    this.fetchReligions();
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.partner && this.props.partner) {
      const { partner } = this.props;
      this.fetchCities(partner.country.id);
      if (partner.city) this.fetchAreas(partner.city.id);
    }
  }

  postSuccess = (actions, partner) => {
    actions.setSubmitting(false);
    this.setState({ showLoader: false });
    this.props.history.push(`${routes.partners}/${partner.id}`);
  };

  partnerErrorHandling = (actions, error) => {
    this.setState({ showLoader: false });
    const { response } = error;
    if (response.status === 500) {
      return;
    }

    if (response.data) {
      if (response.data.code === errorCodes.PARTNER_WITH_MOBILE_EXISTS) {
        message.error('Partner with same mobile number exists. Please contact support.');
      }
      Object.keys(error.response.data).forEach(key => {
        let message = error.response.data[key][0];

        if (message) {
          message = message.charAt(0).toUpperCase() + message.slice(1); // Capitalize first letter only
          actions.setFieldError(key, message);
        } else {
          // bank account type
          actions.setFieldError(key, error.response.data[key]);
        }
      });
    }
  };

  fetchCities = countryId => {
    locationApi
      .fetchCities(countryId)
      .then(data => this.setState({ cityList: data.map(city => ({ key: city.id, value: city.id, text: city.name })) }));
  };

  fetchAreas = cityId => {
    locationApi.fetchAreas(this.state.country, cityId).then(data =>
      this.setState({
        areaList: data.map(area => ({ key: area.id, value: area.id, text: area.name })),
      }),
    );
  };

  fetchNationalities = () => {
    partnerApi.fetchNationalities().then(data => {
      this.setState({
        nationalityList: data.map(nationality => ({
          key: nationality.id,
          value: nationality.id,
          text: nationality.name,
        })),
      });
    });
  };

  fetchReligions = () => {
    religionApi.fetchReligions().then(data => {
      this.setState({
        religionList: data.map(religion => ({
          key: religion.id,
          value: religion.id,
          text: religion.name,
        })),
      });
    });
  };

  render() {
    const { t, edit, partner } = this.props;
    return (
      <div>
        <Formik
          enableReinitialize
          initialValues={partner ? decomposePartner(partner) : initialValues}
          validationSchema={Yup.object().shape({
            first_name: Yup.string().required('First name is required'),
            last_name: Yup.string().required('Last name is required'),
            mobile: Yup.string()
              .typeError('Mobile is required')
              .required('Mobile is required'),
            country: Yup.number()
              .integer()
              .required(),
            date_of_birth: Yup.string().when('country', {
              is: countries.singapore.id,
              then: Yup.string()
                .nullable()
                .notRequired(),
              otherwise: Yup.string()
                .nullable()
                .required('Date of birth is required'),
            }),
            address: Yup.object().shape({
              latitude: Yup.number()
                .typeError('Latitude is required')
                .required('Latitude is required'),
              longitude: Yup.number()
                .typeError('Longitude is required')
                .required('Longitude is required'),
            }),
            experiences: Yup.array().of(
              Yup.object().shape({
                start_month: Yup.number()
                  .integer()
                  .typeError('Start month is required')
                  .min(1)
                  .max(12)
                  .required('Start month is required'),
                start_year: Yup.string()
                  .typeError('Start year is required')
                  .required('Start year is required'),
                end_month: Yup.number()
                  .integer()
                  .typeError('End month is required')
                  .min(1)
                  .max(12)
                  .required('End month is required'),
                end_year: Yup.string()
                  .typeError('End year is required')
                  .required('End year is required'),
                position: Yup.number()
                  .integer()
                  .required('Position is required'),
                company_name: Yup.string().required('Company name is required'),
              }),
            ),
            educations: Yup.array().of(
              Yup.object().shape({
                graduation_year: Yup.number()
                  .integer()
                  .required('Graduation year is required'),
                field_of_study: Yup.string().required('Field of study is required'),
                degree: Yup.string().required('Degree is required'),
                school: Yup.string().required('School is required'),
              }),
            ),
            certificates: Yup.array().of(
              Yup.object().shape({
                name: Yup.string().required('Name is required'),
                institution: Yup.string().required('Institution is required'),
                year: Yup.number()
                  .integer()
                  .required('Year is required'),
              }),
            ),
          })}
          onSubmit={(values, actions) => {
            actions.setSubmitting(true);
            this.setState({ showLoader: true });
            if (edit) {
              // payload modification
              const payload = {
                ...values,
                permanent_resident_date: values.permanent_resident_date
                  ? moment(values.permanent_resident_date).format('YYYY-MM-DD')
                  : null,
                date_of_birth: moment(values.date_of_birth).isValid()
                  ? moment(values.date_of_birth).format('YYYY-MM-DD')
                  : null,
                address: {
                  ...values.address,
                  country: values.country,
                  area: values.address.area ? values.address.area.id : null,
                },
                bank_account: values.bank_account && values.bank_account.bank ? values.bank_account : null,
              };

              if (payload.identification_number === partner.identification_number) {
                delete payload.identification_number;
              }

              partnerApi
                .editPartner(partner.id, payload)
                .then(partnerDetail => {
                  const promises = [];

                  if (payload.image && typeof payload.image !== 'string') {
                    promises.push(partnerApi.uploadImage(partnerDetail.id, payload.image));
                  }

                  if (payload.id_card && typeof payload.id_card !== 'string') {
                    promises.push(partnerApi.uploadIdCard(partnerDetail.id, payload.id_card));
                  }

                  Promise.all(promises).then(() => {
                    this.postSuccess(actions, partnerDetail);
                  });
                })
                .catch(error => {
                  this.partnerErrorHandling(actions, error);
                });
            } else {
              partnerApi
                .createPartner({
                  ...values,
                  permanent_resident_date: values.permanent_resident_date
                    ? moment(values.permanent_resident_date).format('YYYY-MM-DD')
                    : null,
                  date_of_birth: moment(values.date_of_birth).isValid()
                    ? moment(values.date_of_birth).format('YYYY-MM-DD')
                    : null,
                  address: {
                    ...values.address,
                    country: values.country,
                  },
                  bank_account: values.bank_account.bank ? values.bank_account : undefined,
                })
                .then(partnerDetail => {
                  const promises = [];

                  if (values.image) {
                    promises.push(partnerApi.uploadImage(partnerDetail.id, values.image));
                  }

                  if (values.id_card) {
                    promises.push(partnerApi.uploadIdCard(partnerDetail.id, values.id_card));
                  }

                  Promise.all(promises).then(() => {
                    this.postSuccess(actions, partnerDetail);
                  });
                })
                .catch(error => {
                  this.partnerErrorHandling(actions, error);
                });
            }
          }}
          render={({ values, setFieldValue, errors, touched }) => (
            <Form>
              <CustomFormContainer>
                <CustomLoader isActive={this.state.showLoader} size="massive" page />
                <CustomHeader1>{edit ? 'Edit' : 'Create'} Worker</CustomHeader1>
                <Actions>
                  <SubmitButton type="submit" variation="primary">
                    <Icon name="save" />
                    {edit ? 'Save' : 'Create Partner'}
                  </SubmitButton>
                  <Button
                    type="cancel"
                    variation="secondary"
                    to={edit && partner && partner.id ? `${routes.partners}/${partner.id}` : routes.partners}
                  >
                    Close
                  </Button>
                </Actions>
                <Card>
                  <Card.Header>Basic Information</Card.Header>
                  <CardContent>
                    <FormGroup>
                      <FormGroup.Label>First Name:*</FormGroup.Label>
                      <Field name="first_name" placeholder="First Name" />
                      {errors.first_name && touched.first_name && (
                        <FormGroup.Error>{errors.first_name}</FormGroup.Error>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <FormGroup.Label>Last Name:*</FormGroup.Label>
                      <Field name="last_name" placeholder="Last Name" />
                      {errors.last_name && touched.last_name && <FormGroup.Error>{errors.last_name}</FormGroup.Error>}
                    </FormGroup>
                    <FormGroup>
                      <FormGroup.Label>Date of Birth:*</FormGroup.Label>
                      <DatePicker
                        selected={values.date_of_birth && moment(values.date_of_birth).toDate()}
                        dropdownMode="select"
                        dateFormat="yyyy-MM-dd"
                        onChange={date => setFieldValue('date_of_birth', date)}
                        placeholderText="YYYY-MM-DD"
                      />
                      {errors.date_of_birth && touched.date_of_birth && (
                        <FormGroup.Error>{errors.date_of_birth}</FormGroup.Error>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <FormGroup.Label>Gender:</FormGroup.Label>
                      <RadioGroup
                        options={[
                          {
                            value: 'male',
                            text: 'Male',
                          },
                          {
                            value: 'female',
                            text: 'Female',
                          },
                          {
                            value: 'unknown',
                            text: 'Not provided',
                          },
                        ]}
                        value={values.gender}
                        onChange={value => setFieldValue('gender', value)}
                      />
                      {errors.gender && touched.gender && <FormGroup.Error>{errors.gender}</FormGroup.Error>}
                    </FormGroup>
                    <FormGroup>
                      <FormGroup.Label>Identification Card Number (optional):</FormGroup.Label>
                      <Field
                        name="identification_number"
                        value={values.identification_number || ''}
                        placeholder="Identification Card Number"
                      />
                      {errors.identification_number && touched.identification_number && (
                        <FormGroup.Error>{errors.identification_number}</FormGroup.Error>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <FormGroup.Label>Email (optional):</FormGroup.Label>
                      <Field name="email" placeholder="Email" value={values.email || ''} />
                      {errors.email && touched.email && <FormGroup.Error>{errors.email}</FormGroup.Error>}
                    </FormGroup>
                    <FormGroup>
                      <FormGroup.Label>Mobile (Username):*</FormGroup.Label>
                      <PhoneInput
                        placeholder="Mobile"
                        country={getCountryCode()}
                        countries={['ID', 'TH', 'SG', 'IN']}
                        international={false}
                        value={values.mobile}
                        onChange={value => setFieldValue('mobile', value)}
                      />
                      {errors.mobile && touched.mobile && <FormGroup.Error>{errors.mobile}</FormGroup.Error>}
                    </FormGroup>
                    <FormGroup>
                      <FormGroup.Label>Home Phone (optional):</FormGroup.Label>
                      <PhoneInput
                        placeholder="Home Phone"
                        country={getCountryCode()}
                        countries={['ID', 'TH', 'SG', 'IN']}
                        international={false}
                        value={values.home_phone}
                        onChange={value => setFieldValue('home_phone', value)}
                      />
                      {errors.home_phone && touched.home_phone && (
                        <FormGroup.Error>{errors.home_phone}</FormGroup.Error>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <FormGroup.Label>Tax ID:</FormGroup.Label>
                      <Field name="tax_id" placeholder="Tax ID" value={values.tax_id || ''} />
                    </FormGroup>
                    <FormGroup>
                      <FormGroup.Label>Profile Photo (optional):</FormGroup.Label>
                      <ImagePicker
                        value={values.image}
                        onChange={image => {
                          setFieldValue('image', image);
                        }}
                      />
                      <input
                        id="image"
                        name="image"
                        type="file"
                        onChange={event => {
                          setFieldValue('image', event.currentTarget.files[0]);
                        }}
                      />
                      <div>
                        {values.image && typeof values.image !== 'string' && <ImagePreview file={values.image} />}
                        {edit && typeof values.image === 'string' && (
                          <CustomProfilePhoto>
                            {values.image ? <img src={values.image} alt="" /> : ''}
                          </CustomProfilePhoto>
                        )}
                      </div>
                      <ErrorMessage name="values.image" component={FormGroup.Error} />
                    </FormGroup>

                    <FormGroup>
                      <FormGroup.Label>ID Card Photo (optional):</FormGroup.Label>
                      <ImagePicker
                        value={values.image}
                        onChange={image => {
                          setFieldValue('id_card', image);
                        }}
                      />
                      <input
                        id="idCard"
                        name="idCard"
                        type="file"
                        onChange={event => {
                          setFieldValue('id_card', event.currentTarget.files[0]);
                        }}
                      />
                      <div>
                        {values.id_card && typeof values.id_card !== 'string' && <ImagePreview file={values.id_card} />}
                        {edit && typeof values.id_card === 'string' && (
                          <CustomProfilePhoto>
                            {values.id_card ? <img src={values.id_card} alt="" /> : ''}
                          </CustomProfilePhoto>
                        )}
                      </div>
                      <ErrorMessage name="values.id_card" component={FormGroup.Error} />
                    </FormGroup>
                    {values.country === countries.singapore.id && (
                      <>
                        <FormGroup>
                          <FormGroup.Label>Nationality: </FormGroup.Label>
                          <Dropdown
                            options={this.state.nationalityList}
                            onChange={(e, { value }) => {
                              setFieldValue('nationality', value);
                            }}
                            value={values.nationality}
                            placeholder="Select Nationality"
                          />
                        </FormGroup>
                        <FormGroup>
                          <FormGroup.Label>Race:</FormGroup.Label>
                          <Dropdown
                            clearable
                            options={Object.values(RaceTypes).map(type => ({
                              key: type,
                              value: type,
                              text: t(type),
                            }))}
                            onChange={(e, { value }) => {
                              setFieldValue('race', value);
                            }}
                            value={values.race}
                            placeholder="Select Race"
                          />
                        </FormGroup>
                        <FormGroup>
                          <FormGroup.Label>{t('secondaryRace')}</FormGroup.Label>
                          <Dropdown
                            clearable
                            options={Object.values(RaceTypes).map(type => ({
                              key: type,
                              value: type,
                              text: t(type),
                            }))}
                            onChange={(e, { value }) => {
                              setFieldValue('secondary_race', value);
                            }}
                            value={values.secondary_race}
                            placeholder={t('selectSecondaryRace')}
                          />
                        </FormGroup>
                        <FormGroup>
                          <FormGroup.Label>Religion:</FormGroup.Label>
                          <Dropdown
                            clearable
                            options={this.state.religionList}
                            onChange={(e, { value }) => {
                              setFieldValue('religion', value);
                            }}
                            value={values.religion}
                            placeholder="Select Religion"
                          />
                        </FormGroup>
                        <FormGroup>
                          <FormGroup.Label>Residency:</FormGroup.Label>
                          <Dropdown
                            clearable
                            options={Object.values(ResidencyTypes).map(type => ({
                              key: type,
                              value: type,
                              text: t(type),
                            }))}
                            onChange={(e, { value }) => {
                              setFieldValue('residency', value);
                            }}
                            value={values.residency}
                            placeholder="Select Residency"
                          />
                        </FormGroup>
                        <FormGroup>
                          <FormGroup.Label>PR date (Valid from):</FormGroup.Label>
                          <DatePicker
                            isClearable
                            selected={
                              Boolean(values.permanent_resident_date) && moment(values.permanent_resident_date).toDate()
                            }
                            dropdownMode="select"
                            dateFormat="yyyy-MM-dd"
                            onChange={date => {
                              setFieldValue('permanent_resident_date', date);
                            }}
                            placeholderText="YYYY-MM-DD"
                          />
                        </FormGroup>
                        <FormGroup>
                          <FormGroup.Label>Fund type:</FormGroup.Label>
                          <Dropdown
                            clearable
                            multiple
                            options={Object.values(FundTypes).map(type => ({
                              key: type,
                              value: type,
                              text: t(type),
                            }))}
                            onChange={(e, { value }) => {
                              setFieldValue('fund_type', value);
                            }}
                            value={values.fund_type}
                            placeholder="Select Fund type"
                          />
                        </FormGroup>
                      </>
                    )}
                  </CardContent>
                </Card>
                <Card>
                  <Card.Header>Home Address</Card.Header>
                  <CardContent>
                    <FormGroup>
                      <FormGroup.Label>Country:*</FormGroup.Label>
                      <RemoteDropdown
                        fetchOptions={() => {
                          return locationApi.fetchCountries().then(countries => {
                            const currentCountry = countries.filter(country => country.code === getCountryCode())[0];
                            if (currentCountry) {
                              setFieldValue('country', currentCountry.id);
                              this.fetchCities(currentCountry.id);
                            }

                            return countries.map(country => ({
                              key: country.id,
                              value: country.id,
                              text: country.name,
                            }));
                          });
                        }}
                        onChange={(e, { value }) => {
                          setFieldValue('country', value);
                          this.fetchCities(value);
                        }}
                        value={values.country}
                        placeholder="Select Country"
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormGroup.Label>City:*</FormGroup.Label>
                      <Dropdown
                        options={this.state.cityList}
                        onChange={(e, { value }) => {
                          setFieldValue('city', value);
                          setFieldValue('preferred_job_areas', []);
                          this.fetchAreas(value);
                        }}
                        value={values.city}
                        placeholder="Select City"
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormGroup.Label>Street:*</FormGroup.Label>
                      <PlacesAutocomplete
                        value={(values.address && values.address.street_1 && values.address.street_1) || ''}
                        shouldFetchSuggestions={
                          !!(values.address && values.address.street_1 && values.address.street_1.length > 3)
                        }
                        onChange={address => {
                          setFieldValue('address.street_1', address);
                        }}
                        onSelect={address => {
                          setFieldValue('address.street_1', address);
                          geocodeByAddress(address)
                            .then(results => {
                              return getLatLng(results[0]);
                            })
                            .then(latLng => {
                              setFieldValue('address.latitude', latLng?.lat);
                              setFieldValue('address.longitude', latLng?.lng);
                            })
                            .catch(error => console.error(error));
                        }}
                      />
                      {errors.address && touched.address && (
                        <FormGroup.Error>
                          <div>
                            {[errors.address.street_1, errors.address.latitude, errors.address.longitude]
                              .filter(i => !!i)
                              .join(', ')}
                          </div>
                        </FormGroup.Error>
                      )}
                    </FormGroup>

                    <div style={{ height: '400px', gridColumn: '1 / span 2' }}>
                      <Maps
                        centerPosition={{
                          lat: Number(values.address?.latitude || initialValues.address.latitude),
                          lng: Number(values.address?.longitude || initialValues.address.longitude),
                        }}
                        zoom={values.address.street_1 ? 16 : 10}
                        markerPositions={[
                          {
                            lat: Number(values.address?.latitude || initialValues.address.latitude),
                            lng: Number(values.address?.longitude || initialValues.address.longitude),
                            draggable: true,
                          },
                        ]}
                        scrollWheelZoom
                      />
                    </div>
                  </CardContent>
                </Card>
                <Card>
                  <Card.Header>Work Information</Card.Header>
                  <CardContent>
                    <FormGroup>
                      <FormGroup.Label>Transport Type (Optional):</FormGroup.Label>
                      <CheckboxGroup>
                        <CheckboxGroup.Checkbox
                          label="Car"
                          checked={values.has_car}
                          onChange={() => setFieldValue('has_car', !values.has_car)}
                        />
                        <CheckboxGroup.Checkbox
                          label="Motorcycle"
                          checked={values.has_motorcycle}
                          onChange={() => setFieldValue('has_motorcycle', !values.has_motorcycle)}
                        />
                      </CheckboxGroup>
                    </FormGroup>
                    <FormGroup>
                      <FormGroup.Label>English Proficiency:</FormGroup.Label>
                      <Rating
                        maxRating={3}
                        rating={values.english_proficiency}
                        onRate={(e, { rating }) => setFieldValue('english_proficiency', rating)}
                      />
                    </FormGroup>
                  </CardContent>
                </Card>
                <Card>
                  <Card.Header>Bank Details</Card.Header>
                  <CardContent>
                    <FormGroup>
                      <FormGroup.Label>Bank Name (Optional):</FormGroup.Label>
                      <RemoteDropdown
                        search
                        clearable
                        control={values.country}
                        fetchOptions={async search => {
                          const banks = await bankApi.fetchBanks({
                            country: values.country,
                          });
                          return banks.map(bank => ({
                            key: bank.id,
                            value: bank.id,
                            text: `${bank.name} [${bank.bic}]`,
                          }));
                        }}
                        onChange={(e, { name, value }) => {
                          setFieldValue('bank_account.bank', value);
                        }}
                        placeholder="Select..."
                        value={values.bank_account ? values.bank_account.bank : ''}
                      />
                    </FormGroup>
                    <ErrorMessage name="values.bank_account.bank" component={FormGroup.Error} />
                    <FormGroup>
                      <FormGroup.Label>Account Holder's Name (Optional):</FormGroup.Label>
                      <Field
                        name="bank_account.full_name"
                        value={values.bank_account ? values.bank_account.full_name || '' : ''}
                        placeholder="Full Name"
                      />
                    </FormGroup>
                    <ErrorMessage name="values.bank_account.full_name" component={FormGroup.Error} />
                    <FormGroup>
                      <FormGroup.Label>Account Number (Optional):</FormGroup.Label>
                      <Field
                        name="bank_account.number"
                        value={values.bank_account ? values.bank_account.number || '' : ''}
                        placeholder="Account Number"
                      />
                    </FormGroup>
                    <ErrorMessage name="values.bank_account.bank.number" component={FormGroup.Error} />
                  </CardContent>
                </Card>
                <Card>
                  <Card.Header>Job Preference</Card.Header>
                  <CardContent>
                    <FormGroup>
                      <FormGroup.Label>Preferred Positions:*</FormGroup.Label>
                      <PositionDropdown
                        clearable
                        fluid
                        search
                        multiple
                        selection
                        placeholder="Preferred Position"
                        value={values.preferred_positions}
                        onChange={(e, { name, value }) => {
                          setFieldValue('preferred_positions', value);
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormGroup.Label>Approved Positions:*</FormGroup.Label>
                      <PositionDropdown
                        clearable
                        fluid
                        search
                        multiple
                        selection
                        placeholder="Approve Position"
                        value={values.approved_positions}
                        onChange={(e, { name, value }) => {
                          setFieldValue('approved_positions', value);
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <FormGroup.Label>Preferred Area:*</FormGroup.Label>
                      <Dropdown
                        clearable
                        fluid
                        search
                        multiple
                        selection
                        options={this.state.areaList}
                        placeholder="Preferred Area"
                        value={values.preferred_job_areas}
                        onChange={(e, { name, value }) => {
                          setFieldValue('preferred_job_areas', value);
                        }}
                        disabled={!values.city}
                      />
                    </FormGroup>
                  </CardContent>
                </Card>
                <Card>
                  <Card.Header>Work Experience</Card.Header>
                  <FieldArray
                    name="experiences"
                    render={arrayHelpers => (
                      <>
                        {values.experiences &&
                          values.experiences.map((experience, index) => (
                            <RepeatableInput key={index}>
                              <ExperienceInput
                                value={experience}
                                onChange={experience => {
                                  setFieldValue(`experiences.${index}`, experience);
                                }}
                                errors={errors.experiences && errors.experiences[index]}
                                touched={touched.experiences && touched.experiences[index]}
                              />
                              <RemoveButton
                                onClick={() => {
                                  arrayHelpers.remove(index);
                                }}
                              />
                            </RepeatableInput>
                          ))}
                        <AddButton onClick={() => arrayHelpers.insert(values.experiences.length, defaultExperience)}>
                          Add experience
                        </AddButton>
                      </>
                    )}
                  />
                </Card>
                <Card>
                  <Card.Header>Education</Card.Header>
                  <FieldArray
                    name="educations"
                    render={arrayHelpers => (
                      <>
                        {values.educations &&
                          values.educations.map((education, index) => (
                            <RepeatableInput key={index}>
                              <EducationInput
                                value={education}
                                onChange={education => {
                                  setFieldValue(`educations.${index}`, education);
                                }}
                                errors={errors.educations && errors.educations[index]}
                                touched={touched.educations && touched.educations[index]}
                              />
                              <RemoveButton
                                onClick={() => {
                                  arrayHelpers.remove(index);
                                }}
                              />
                            </RepeatableInput>
                          ))}
                        <AddButton onClick={() => arrayHelpers.insert(values.educations.length, defaultEducation)}>
                          Add education
                        </AddButton>
                      </>
                    )}
                  />
                </Card>
                <Card>
                  <Card.Header>Certifications</Card.Header>
                  <FieldArray
                    name="certificates"
                    render={arrayHelpers => (
                      <>
                        {values.certificates &&
                          values.certificates.map((certificate, index) => (
                            <RepeatableInput key={index}>
                              <CertificateInput
                                value={certificate}
                                onChange={certificate => {
                                  setFieldValue(`certificates.${index}`, certificate);
                                }}
                                errors={errors.certificates && errors.certificates[index]}
                                touched={touched.certificates && touched.certificates[index]}
                              />
                              <RemoveButton
                                onClick={() => {
                                  arrayHelpers.remove(index);
                                }}
                              />
                            </RepeatableInput>
                          ))}
                        <AddButton onClick={() => arrayHelpers.insert(values.certificates.length, defaultCertificate)}>
                          Add certificate
                        </AddButton>
                      </>
                    )}
                  />
                </Card>
              </CustomFormContainer>
            </Form>
          )}
        />
      </div>
    );
  }
}

export default withTranslation()(PartnerForm);
