// State of the app entity, holds information like country, positions... etc.
import positionApi from '../services/positionApi';

const UPDATE = 'GLOBAL_UPDATE_ACTION';

const globalInitialState = {
  isLoading: false,
  positions: [],
  error: '',
};

export default (state = globalInitialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE:
      return Object.assign({}, state, { ...payload });
    default:
      return state;
  }
};

export const globalUpdateAction = payload => {
  return { type: UPDATE, payload };
};

// Heavy-Weight logic
export const fetchPositions = () => {
  return async dispatch => {
    try {
      dispatch(globalUpdateAction({ isLoading: true }));
      const positions = await positionApi.fetchPositions();
      dispatch(globalUpdateAction({ isLoading: false, positions }));
    } catch (error) {
      dispatch(globalUpdateAction({ isLoading: false, error: error.message }));
    }
  };
};
