/* eslint-disable react/no-unused-state */
import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import { datadogLogs } from '@datadog/browser-logs';
import { Tooltip, Card, Row, Col, Button, Input, Typography, Avatar, Divider, Alert } from 'antd';

import channelApi from '../../../../services/channelApi';

import {
  DATE_FORMAT,
  channelBroadcastAudienceLabel,
  channelAudiences,
  applicationStatuses,
} from '../../../../constants';
import routes from '../../../../routes';

import workmateLogo from '../../../assets/images/workmate_light_logo.svg';
import noMessage from '../../../assets/images/no_message.png';
import StatusTag from '../../../shared/components/StatusTag';
import EmptyCard from '../../../shared/components/EmptyCard';
import ChannelUploadImageModal from './ChannelUploadImageModal';

const { Text } = Typography;
const { TextArea } = Input;
const { Grid } = Card;

const ChannelLoadingCard = () => (
  <Card loading style={{ height: 'calc(100vh - 266px)', boxShadow: 'rgba(0, 0, 0, 0.15) 0px 2px 6px' }} />
);

const ChannelNotSelectedCard = () => (
  <EmptyCard
    title="No channel selected"
    description="Choose a channel on the left."
    imgSrc={noMessage}
    style={{
      paddingTop: '64px',
      paddingBottom: 'calc(100vh - 464px)',
      boxShadow: 'rgba(0, 0, 0, 0.15) 0px 2px 6px',
      border: '1px solid #e8e8e8',
      borderRadius: '4px',
    }}
  />
);

const JoinChannelBanner = ({ onClick, srId, audience }) => (
  <Alert
    showIcon
    type="info"
    message={`You are viewing the channel of SR #${srId} ${audience}`}
    description={
      <>
        <Row>Join the channel to view previous broadcast messages and send new ones.</Row>
        <Button style={{ marginTop: '14px' }} onClick={onClick}>
          Join channel
        </Button>
      </>
    }
  />
);

const MessageDivider = ({ text }) => (
  <Divider style={{ fontSize: '14px', fontWeight: 'normal' }}>
    <Text type="secondary">{text}</Text>
  </Divider>
);

class ChannelChatSection extends React.Component {
  state = {
    messages: [],
    messagesReadCounts: [], // array of message read count
    isLoading: false,
    showUploadImageModal: false,
    isChannelMember: false,
    chatClient: undefined,
    chatChannel: undefined,
    broadCastMessage: '',
  };

  async componentDidMount() {
    const { operator, onChatClientLoaded } = this.props;
    this.setState({ isLoading: true });
    const chatToken = await channelApi.fetchChatToken(`${operator.user.id}`);
    const twilioToken = chatToken.apns;
    const chatClient = await window.Twilio.Chat.Client.create(twilioToken);
    onChatClientLoaded();
    this.setState({ isLoading: false, chatClient });
  }

  async componentDidUpdate(prevProps) {
    const { channel } = this.props;

    // Return if we don't have a channel or if channel didn't change
    if (this.state.isLoading) return;
    if (!channel || prevProps.channel === channel) {
      return;
    }

    if (prevProps.channel !== channel) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isChannelMember: true }); // Initially hide the join channel banner.

      // New channel selected.
      let isLoading = true;
      let isChannelMember = false;
      let chatChannel;

      const { chatClient } = this.state;

      chatClient.on('channelJoined', async () => {
        const channelSid = channel.sid;
        if (!this.state.chatChannel) {
          // eslint-disable-next-line no-shadow
          const chatChannel = await chatClient.getChannelBySid(channelSid);
          this.setState({ chatChannel }, () => this.loadChannel());
        }
      });

      try {
        chatChannel = await chatClient.getChannelBySid(channel.sid);
        isLoading = false;
        isChannelMember = true;
      } catch (error) {
        if (error.body?.status === 403 && error.body?.code === 50400) {
          // Not yet a member of the channel.
          isLoading = false;
        }
      }

      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ chatChannel, isLoading, isChannelMember, messages: [] }, () => this.loadChannel());
    }
  }

  scrollChatToBottom = () => {
    const chatBox = window.document.getElementById('chat-box');
    if (chatBox) chatBox.scrollTo(0, 10000);
  };

  joinChannel = async () => {
    this.setState({ isLoading: true });
    await channelApi.joinChannel(this.props.channel.id);
  };

  loadChannel = async () => {
    if (!this.state.chatChannel) {
      return;
    }

    try {
      this.getMessageSeenCounts(this.state.chatChannel);
      const messageHistory = await this.state.chatChannel.getMessages();
      this.state.chatChannel.on('messageAdded', this.printMessage);

      const messages = [];
      for (let i = 0; i < messageHistory.items.length; i += 1) {
        const message = messageHistory.items[i];
        const { author, body, type, timestamp, index } = message;
        // Normal plain text
        if (type !== 'media') {
          messages.push({ author, body, timestamp, index });
          // eslint-disable-next-line no-continue
          continue;
        }
        // eslint-disable-next-line no-await-in-loop
        const imageUrl = await message.media.getContentUrl();
        messages.push({ author, body, timestamp, imageUrl, index });
      }

      this.setState({ isChannelMember: true, isLoading: false, messages }, () => this.scrollChatToBottom());
    } catch (error) {
      console.log('> Error:', error);
      this.setState({ isLoading: false, isChannelMember: false, messages: [] });
    }
  };

  getMessageSeenCounts = async channel => {
    try {
      const members = await channel.getMembers();
      const numMessages = await channel.getMessagesCount();
      // messagesReadMembersInfo is 2D array. i.E [ [member, member], [], [member], [], [], []]
      const messagesReadMembersInfo = Array.from({ length: numMessages }, () => []);
      const messagesReadCounts = Array.from({ length: numMessages }, () => 0);
      members.forEach(member => {
        const { lastConsumedMessageIndex, identity } = member;
        if (lastConsumedMessageIndex !== null) {
          messagesReadMembersInfo[lastConsumedMessageIndex].push({ identity });
        }
      });
      // Use info of messagesReadMemberInfo to messagesReadCount
      // [ [member, member], [],  [member], [], [], []]
      // Becomes
      // [ 3, 1, 1, 0, 0, 0]
      let readCount = 0;
      for (let i = numMessages - 1; i >= 0; i -= 1) {
        readCount += messagesReadMembersInfo[i].length;
        messagesReadCounts[i] = readCount;
      }
      this.setState({ messagesReadCounts });
    } catch (error) {
      console.log('> Error:', error);
    }
  };

  // Append the new message into an array of messages & scroll to bottom
  printMessage = async textEvent => {
    const { author, body, timestamp, type, media, index } = textEvent;
    // Some textEvent might be an image. Fetch the URL if it's an image.
    let message;
    if (type !== 'media') {
      message = { author, body, timestamp, index };
    } else {
      const imageUrl = await media.getContentUrl();
      message = { author, body, timestamp, imageUrl, index };
    }

    this.setState(
      state => {
        // There's a chance the function can be called multiple times for the same message. Return & don't print then.
        if (state.messages.length > 0 && state.messages[state.messages.length - 1].timestamp === timestamp) {
          return;
        }
        const messages = state.messages.concat(message);
        return { messages, isLoading: false };
      },
      () => this.scrollChatToBottom(),
    );
  };

  typeMessage = e => this.setState({ broadCastMessage: e.target.value });

  sendMessage = () => {
    const { chatChannel, broadCastMessage } = this.state;
    chatChannel.sendMessage(broadCastMessage);
    datadogLogs.logger.info('Broadcast message', {
      message: broadCastMessage,
      staff_request_id: this.props.channel?.staff_request?.id,
    });
    this.setState({ broadCastMessage: '' });
  };

  onSendImage = (fileObj, fileType) => {
    const { chatChannel } = this.state;
    chatChannel.sendMessage({
      contentType: fileType,
      media: fileObj,
    });
    this.setState({ showUploadImageModal: false, isLoading: true });
  };

  render() {
    const { channel } = this.props;
    const {
      messagesReadCounts,
      messages,
      broadCastMessage,
      isChannelMember,
      isLoading,
      showUploadImageModal,
    } = this.state;
    if (isLoading) return <ChannelLoadingCard />;
    if (!channel || !channel.staff_request) return <ChannelNotSelectedCard />;
    const {
      id,
      position,
      title,
      client,
      location,
      start_time,
      end_time,
      status: staffRequestStatus,
      confirmed_partners,
      applicant_tasks,
    } = channel.staff_request;
    let messageDividerDate = moment(channel.created_date);
    let audienceName = '';
    if (channel.audience in channelBroadcastAudienceLabel) {
      audienceName = `(${channelBroadcastAudienceLabel[channel.audience]})`;
    }
    let workersInfo = '';
    let jobStatus = '';

    // TODO P2: add some counts in the backend, or use graphql
    if (channel.audience === channelAudiences.ACTIVE_EMPLOYEES) {
      workersInfo = `${confirmed_partners.count} employees`;
      jobStatus = 'hired'; // show as Hired instead of Active
    } else if (channel.audience === channelAudiences.APPLIED_APPLICANTS) {
      workersInfo = `${applicant_tasks.count} applicants`;
      jobStatus = applicationStatuses.APPLIED;
    }

    return (
      <>
        <Card
          bordered
          style={{
            borderRadius: '4px',
            overflow: 'hidden',
            width: '100%',
            boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15)',
          }}
        >
          {/* SR summary */}
          <Grid hoverable={false} style={{ width: '100%' }}>
            <Row type="flex" justify="space-between" align="top">
              <Col span={3}>
                <Avatar size={72} shape="square" src={client.logo} className="v2-avatar-wrapper" />
              </Col>
              <Col span={17}>
                <Row>
                  <Text strong type="secondary" style={{ fontSize: '12px' }}>
                    {`SR #${id} · ${position.name.toUpperCase()}`}
                  </Text>
                </Row>
                <Row>
                  <Link
                    to={routes.staffRequestDetail.replace(':id', id)}
                    style={{ color: '#595959' }}
                    className="ant-link-v2-with-underline"
                  >
                    <Text strong>{title}</Text>
                  </Link>
                </Row>
                <Row>{`${client.name} · ${location.name}`}</Row>
                <Row>{`${moment(start_time).format(DATE_FORMAT)} - ${moment(end_time).format(DATE_FORMAT)}`}</Row>
                <Row style={{ marginTop: '5px' }}>
                  <StatusTag status={staffRequestStatus} />
                </Row>
              </Col>
              <Col span={4} align="right">
                <Row>
                  <StatusTag status={jobStatus} style={{ marginRight: 0 }} />
                </Row>
                <Row>
                  <Text strong>{workersInfo}</Text>
                </Row>
              </Col>
            </Row>
          </Grid>

          {/* Broadcast messages */}
          <Grid
            id="chat-box"
            hoverable={false}
            style={{
              width: '100%',
              borderBottom: 0,
              height: 'calc(100vh - 500px)',
              overflowY: 'auto',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <MessageDivider text={`Created on ${moment(channel.created_date).format(DATE_FORMAT)}`} />
            {isChannelMember ? (
              <div>
                {messages.map((message, index) => {
                  const readCount = messagesReadCounts[index];
                  const readInfo = readCount ? `Seen by ${readCount} ✓✓ ·` : 'Sent ✓ ·';
                  const messageTimeStamp = moment(message.timestamp);
                  let showDivider = false;
                  if (!messageDividerDate.isSame(messageTimeStamp, 'd')) {
                    showDivider = true;
                    messageDividerDate = messageTimeStamp;
                  }
                  return (
                    <Row key={index} type="flex" justify="end" style={{ marginBottom: '32px', padding: '16px 0' }}>
                      {showDivider && <MessageDivider text={messageDividerDate.format(DATE_FORMAT)} />}
                      <Col span={18} style={{ textAlign: 'right', paddingRight: '16px' }}>
                        <Text type="secondary">
                          {`${readInfo} ${moment(message.timestamp).format(`${DATE_FORMAT} h:mm a`)}`}
                        </Text>
                        <section
                          style={{
                            marginTop: '8px',
                            textAlign: 'left',
                            padding: '12px 16px',
                            borderRadius: '4px',
                            backgroundColor: '#F0F2F5',
                            minHeight: '45px',
                            boxShadow: '#dfe1e4 1px 1px 3px 1px',
                            whiteSpace: 'pre-wrap',
                          }}
                        >
                          {message.imageUrl ? (
                            // We have to define "Width" & "Height" upfront. This is so we can accurately to the bottom.
                            <img
                              alt={`broadcast-img-${index}`}
                              src={message.imageUrl}
                              style={{
                                objectFit: 'cover',
                                maxWidth: '480px',
                                maxHeight: '320px',
                              }}
                            />
                          ) : (
                            <>{message.body}</>
                          )}
                        </section>
                        <div
                          style={{
                            position: 'absolute',
                            right: '6px',
                            top: '50px',
                            height: '10px',
                            width: '10px',
                            backgroundColor: '#F0F2F5',
                            transform: 'rotate(-45deg) translate(-4px, -6px)',
                            boxShadow: '#dfe1e4 1px 1px 2px 0px',
                          }}
                        />
                      </Col>
                      <Col>
                        <Avatar size={72} shape="square" src={workmateLogo} style={{ width: '100%' }} />
                      </Col>
                    </Row>
                  );
                })}
              </div>
            ) : (
              <JoinChannelBanner
                onClick={() => {
                  this.joinChannel();
                }}
                srId={id}
                audience={audienceName}
              />
            )}
          </Grid>

          {/* Chat box to send messages */}
          <Grid hoverable={false} style={{ width: '100%', padding: '24px 24px 16px 24px' }}>
            <Row type="flex" justify="space-between" align="bottom" gutter={16}>
              <Col>
                <Tooltip title="Upload image">
                  <Button
                    disabled={!isChannelMember}
                    icon="file-image"
                    onClick={() => {
                      this.setState({ showUploadImageModal: true });
                    }}
                  />
                </Tooltip>
              </Col>
              <Col style={{ flexGrow: 1 }}>
                <TextArea
                  disabled={!isChannelMember}
                  autosize={{ minRows: 1, maxRows: 4 }}
                  onChange={this.typeMessage}
                  value={broadCastMessage}
                  placeholder="Type a message..."
                />
              </Col>
              <Col>
                <Button
                  type="v2-primary"
                  disabled={!isChannelMember || broadCastMessage.length === 0}
                  onClick={() => {
                    this.sendMessage();
                  }}
                >
                  Send
                </Button>
              </Col>
            </Row>
          </Grid>
        </Card>
        {showUploadImageModal && (
          <ChannelUploadImageModal
            visible={showUploadImageModal}
            onCancel={() => this.setState({ showUploadImageModal: false })}
            onSend={(image, fileType) => {
              this.onSendImage(image, fileType);
            }}
          />
        )}
      </>
    );
  }
}

export default ChannelChatSection;
