import { Avatar, Card, Descriptions, Typography } from 'antd';
import React from 'react';
import AccountStatusLabel from '../../../../../shared/components/AccountStatusLabel';
import Address from '../../../../../shared/components/Address';
import Country from '../../../../../shared/components/Country';
import ClientActions from '../ClientActions';
import ClientStatusActions from '../ClientStatusActions';
import SelfSignupIndicator from '../../../../shared/components/SelfSignupIndicator';
import { SELF_SIGNUP_SOURCE, countries } from '../../../../../constants';

export default ({ client, onUpdate }) => {
  const showSelfSignupIndicator = client.source === SELF_SIGNUP_SOURCE;
  return (
    <Card
      title={
        <div
          style={{
            display: 'flex',
          }}
        >
          <div style={{ marginRight: 24 }}>
            <Avatar shape="square" size={120} icon="briefcase" src={client.logo} />
          </div>
          <div style={{ flexGrow: 1, marginRight: 24 }}>
            <Typography.Title level={4}>{client.name}</Typography.Title>
            {showSelfSignupIndicator && <SelfSignupIndicator />}
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: showSelfSignupIndicator ? 5 : 16 }}>
              <AccountStatusLabel style={{ marginRight: 16 }} status={client.status} verified={client.verified} />
              <Country country={client.country} />
            </div>
            <ClientStatusActions client={client} onUpdate={onUpdate} />
          </div>
          <ClientActions client={client} onUpdate={onUpdate} />
        </div>
      }
    >
      <div
        style={{
          display: 'grid',
          gridGap: 24,
        }}
      >
        <Descriptions size="small" bordered title="Company details">
          <Descriptions.Item label="Company name" span={3}>
            {client.name}
          </Descriptions.Item>
          <Descriptions.Item label="Website" span={3}>
            {client.website ? (
              <a href={client.website}>Link</a>
            ) : (
              <Typography.Text disabled>Not provided</Typography.Text>
            )}
          </Descriptions.Item>
        </Descriptions>
        <Descriptions size="small" bordered title="Billing details">
          <Descriptions.Item label="Reg. name" span={3}>
            {client.legal_name}
          </Descriptions.Item>
          <Descriptions.Item label="Reg. number" span={3}>
            {client.business_number}
          </Descriptions.Item>
          <Descriptions.Item label="Tax (VAT) number" span={3}>
            {client.tax_registration_number}
          </Descriptions.Item>
          <Descriptions.Item label="Contact name" span={3}>
            {client.billing_contact_name}
          </Descriptions.Item>
          <Descriptions.Item label="Contact email" span={3}>
            {client.billing_contact_email}
          </Descriptions.Item>
          <Descriptions.Item label="Contact phone" span={3}>
            {client.billing_contact_phone}
          </Descriptions.Item>
          <Descriptions.Item label="Billing address" span={3}>
            <Address address={client.billing_address} />
          </Descriptions.Item>
        </Descriptions>
        <Descriptions size="small" bordered title="Client Settings">
          <Descriptions.Item label="Contract" span={3}>
            {client.contract_required ? (
              <Typography.Text disabled>With contract</Typography.Text>
            ) : (
              <Typography.Text disabled>No contract</Typography.Text>
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Payment enable" span={3}>
            {client.payment_enabled ? (
              <Typography.Text disabled>Yes</Typography.Text>
            ) : (
              <Typography.Text disabled>No</Typography.Text>
            )}
          </Descriptions.Item>
          <Descriptions.Item label="DCO enabled" span={3}>
            <Typography.Text disabled>{client.dco_enabled ? 'Yes' : 'No'}</Typography.Text>
          </Descriptions.Item>
          <Descriptions.Item label="Bank Details" span={3}>
            {client.bank_details_required ? (
              <Typography.Text disabled>Capture bank details</Typography.Text>
            ) : (
              <Typography.Text disabled>No bank details</Typography.Text>
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Break Duration" span={3}>
            {client.break_time_required ? (
              <Typography.Text disabled>With break duration</Typography.Text>
            ) : (
              <Typography.Text disabled>No break duration</Typography.Text>
            )}
          </Descriptions.Item>
          {client.country.code === countries.thailand.code && (
            <Descriptions.Item label="Contract type">
              <Typography.Text disabled>{client.thai_contract_type}</Typography.Text>
            </Descriptions.Item>
          )}
        </Descriptions>
      </div>
    </Card>
  );
};
