import React, { Component } from 'react';
import { Row, Col, Typography, Divider } from 'antd';
import CardWithCount from '../../shared/components/CardWithCount';
import BillingReportFilters from './components/BillingReportFilters';
import BillingReportList from './components/BillingReportList';
import SortSelection from '../../shared/components/SortSelection';
import billingApi from '../../../services/billingApi';
import getCountryCode from '../../../utilities/getCountryCode';
import BillingReportDownloadButton from '../../shared/components/BillingReportDownloadButton';

const { Title } = Typography;

class BillingReportListView extends Component {
  state = {
    statusCounts: undefined,
    filters: {
      created_date_after: undefined,
      created_date_before: undefined,
      status: [],
      client: [],
      search: undefined,
    },
    ordering: undefined,
    billingReports: [],
    count: 0,
    page: 1,
    loading: false,
  };

  componentDidMount() {
    this.fetchBillingReports();
    this.fetchStatusCounts();
  }

  fetchBillingReports = async () => {
    this.setState({ loading: true });
    const params = this.getQueryParams();
    const response = await billingApi.fetchBillingReports(params);
    this.setState({ billingReports: response.results, loading: false, count: response.count });
  };

  fetchStatusCounts = async () => {
    const response = await billingApi.fetchBillingReports({ include_counts: true });
    this.setState({ statusCounts: response.status_counts });
  };

  getQueryParams = () => {
    const { created_date_after, created_date_before, status, client, search } = this.state.filters;
    const { ordering, page } = this.state;

    const queryParams = {
      country: getCountryCode(),
      created_date_after,
      created_date_before,
      client: client.length > 0 ? client.join(',') : undefined,
      status: status.length > 0 ? status.join(',') : undefined,
      search,
      ordering,
      page,
    };

    return queryParams;
  };

  getStatusCounts = () => {
    if (this.state.statusCounts && this.state.statusCounts.length > 0) {
      let unbilled = 0;
      let on_hold = 0;
      let billed = 0;
      this.state.statusCounts.forEach(statusCount => {
        if (statusCount.status === 'unbilled') unbilled = statusCount.total;
        if (statusCount.status === 'on_hold') on_hold = statusCount.total;
        if (statusCount.status === 'billed') billed = statusCount.total;
      });

      return [
        { title: 'Unbilled', num: unbilled || 0, hasBadge: unbilled > 0 },
        { title: 'On Hold', num: on_hold || 0, hasBadge: on_hold > 0 },
        { title: 'Billed', num: billed || 0, hasBadge: false },
      ];
    }
  };

  handleFilterChange = changes => {
    this.setState(
      prevState => ({ page: 1, filters: { ...prevState.filters, ...changes } }),
      () => this.fetchBillingReports(),
    );
  };

  handleOrderingChange = ordering => {
    this.setState({ ordering }, () => this.fetchBillingReports());
  };

  handlePageChange = page => {
    this.setState({ page }, () => this.fetchBillingReports());
  };

  render() {
    const { statusCounts, billingReports, loading, page, count } = this.state;
    return (
      <>
        <Row type="flex" justify="space-between" style={{ marginBottom: '32px' }}>
          <Col>
            <Title level={3}>Billing reports</Title>
          </Col>
        </Row>

        <CardWithCount loading={!statusCounts} countList={this.getStatusCounts()} />

        <Row>
          <BillingReportFilters onChange={this.handleFilterChange}>
            <BillingReportDownloadButton params={this.getQueryParams()} />
          </BillingReportFilters>
        </Row>

        <Divider />

        <Row type="flex" justify="end" style={{ marginBottom: '32px' }}>
          <Col style={{ marginLeft: 'auto' }}>
            <SortSelection
              onChange={this.handleOrderingChange}
              options={[{ name: 'Most recent', value: '-created_date' }]}
            />
          </Col>
        </Row>

        <Row>
          <BillingReportList
            billingReports={billingReports}
            count={count}
            page={page}
            loading={loading}
            onPageChange={this.handlePageChange}
          />
        </Row>
      </>
    );
  }
}

export default BillingReportListView;
