import React, { Component } from 'react';
import { Row, Col, Input } from 'antd';
import BillingReportStatusFilterButton from './BillingReportStatusFilterButton';
import ClientFilterButton from '../../../shared/components/ClientFilterButton';
import DateRangePicker from '../../../shared/components/RangePicker';
import { getStartOfNextDay, getStartOfDay } from '../../../../utilities/dateUtils';

class BillingReportFilters extends Component {
  handleDateChange = dates => {
    let before;
    let after;
    if (dates[0] && dates[1]) {
      after = getStartOfDay(dates[0]);
      before = getStartOfNextDay(dates[1]);
    }
    this.props.onChange({ created_date_before: before, created_date_after: after });
  };

  handleStatusChange = value => {
    this.props.onChange({ status: value });
  };

  handleClientChange = value => {
    this.props.onChange({ client: value });
  };

  handleSearchChange = value => {
    this.props.onChange({ search: value });
  };

  render() {
    return (
      <>
        <Row type="flex" justify="space-between" style={{ marginBottom: '16px' }}>
          <Col span={6}>
            <DateRangePicker onChange={this.handleDateChange} />
          </Col>
          <Col>{this.props.children}</Col>
        </Row>
        <Row gutter={8} type="flex">
          <Col span={3}>
            <BillingReportStatusFilterButton onApply={this.handleStatusChange} />
          </Col>
          <Col span={3}>
            <ClientFilterButton onSelectChange={this.handleClientChange} />
          </Col>
          <Col span={5} style={{ marginLeft: 'auto' }}>
            <Input.Search allowClear placeholder="Search Report #" onSearch={this.handleSearchChange} />
          </Col>
        </Row>
      </>
    );
  }
}

export default BillingReportFilters;
