import React from 'react';
import { connect } from 'react-redux';
import FilterWithSearch from '../../../shared/components/FilterWithSearch';

const PositionFilter = ({ positions: options, selectedOptions, onSelectChange }) => {
  return (
    <FilterWithSearch
      title={`Position ${selectedOptions.length > 0 ? `(${selectedOptions.length})` : ''}`}
      options={options.map(position => ({ key: position.id, name: position.name }))}
      onSelectChange={onSelectChange}
      placeholder="Search by position name"
      optionLabelField="name"
      value={selectedOptions}
    />
  );
};

const mapStateToProps = state => ({
  positions: state.global.positions || [],
});

export default connect(mapStateToProps)(PositionFilter);
