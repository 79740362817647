import { Button, Divider, Table } from 'antd';
import moment from 'moment';
import React from 'react';
import { DEFAULT_DATETIME_FORMAT } from '../../../../constants';
import Access, { checkAccess } from '../../../../shared/access/Access';
import { permissions, roles } from '../../../../shared/access/accessConfig';
import OperatorFormModal from '../modals/OperatorFormModal';
import RemoveOperatorModal from '../modals/RemoveOperatorModal';

const { Column } = Table;

class UserList extends React.Component {
  state = {
    editOperatorModalVisible: false,
    removeOperatorModalVisible: false,
    currentOperator: null,
  };

  openEditOperatorModal = operator => {
    this.setState({ editOperatorModalVisible: true, currentOperator: operator });
  };

  closeEditOperatorModal = () => {
    this.setState({ editOperatorModalVisible: false });
  };

  openRemoveOperatorModal = operator => {
    this.setState({ removeOperatorModalVisible: true, currentOperator: operator });
  };

  closeRemoveOperatorModal = () => {
    this.setState({ removeOperatorModalVisible: false });
  };

  handleUpdate = () => {
    this.props.onUpdate();
  };

  getRoleName = value => {
    return roles.find(role => role.value === value)?.title;
  };

  render() {
    const { editOperatorModalVisible, removeOperatorModalVisible, currentOperator } = this.state;
    const { loading, operators, total, page, onPageChange } = this.props;

    return (
      <>
        <Table
          dataSource={operators}
          loading={loading}
          pagination={{
            current: page,
            onChange: onPageChange,
            pageSize: 20,
            total,
          }}
        >
          <Column
            title="User"
            key="name"
            render={(text, record) => (
              <span>
                <b>{record.name}</b>
                <p>{record.user && record.user.email}</p>
              </span>
            )}
          />
          <Column title="Role" dataIndex="role" key="role" render={text => this.getRoleName(text) || 'Unassigned'} />
          <Column
            title="Date Added"
            dataIndex="created_date"
            key="created_date"
            render={text => moment(text).format(DEFAULT_DATETIME_FORMAT)}
          />
          <Column
            title="Last Login"
            dataIndex="user.last_login"
            key="last_login"
            render={text => text && moment(text).format(DEFAULT_DATETIME_FORMAT)}
          />
          {checkAccess(permissions.editUser) && (
            <Column
              title="Action"
              key="action"
              render={(text, operator) => (
                <Access
                  action={permissions.editUser}
                  yes={() => (
                    <span>
                      <Button type="link" onClick={() => this.openEditOperatorModal(operator)}>
                        Edit
                      </Button>
                      <Divider type="vertical" />
                      <Button type="link" onClick={() => this.openRemoveOperatorModal(operator)}>
                        Remove
                      </Button>
                    </span>
                  )}
                />
              )}
            />
          )}
        </Table>
        <OperatorFormModal
          visible={editOperatorModalVisible}
          operator={currentOperator}
          onClose={this.closeEditOperatorModal}
          onUpdate={this.handleUpdate}
        />
        <RemoveOperatorModal
          visible={removeOperatorModalVisible}
          operator={currentOperator}
          onClose={this.closeRemoveOperatorModal}
          onUpdate={this.handleUpdate}
        />
      </>
    );
  }
}

export default UserList;
