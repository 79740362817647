import authApi from '../services/authApi';

// Actions
const LOGOUT = 'USER_LOGOUT_ACTION'; // Has to be consistent with ./index.js
const UPDATE = 'USER_UPDATE_ACTION';

// Initial State
const userInitialState = {
  loaded: false,
  isLoading: false,
  operator: undefined,
  username: '',
  error: '',
};

// Reducer
export default (state = userInitialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case LOGOUT:
      return Object.assign({}, state, { isLoggedIn: false, token: '' });
    case UPDATE:
      return Object.assign({}, state, { ...payload });
    default:
      return state;
  }
};

// Action Creators
export const logoutAction = () => {
  return { type: LOGOUT };
};

export const userUpdateAction = user => {
  return { type: UPDATE, payload: user };
};

// Heavy-Weight Logic
export const login = user => {
  return async dispatch => {
    // Make login request
    dispatch(userUpdateAction({ isLoading: true }));
    try {
      const auth = await authApi.login(user);
      dispatch(
        userUpdateAction({
          username: user.email,
          token: auth.token,
          operator: auth.operator,
          isLoading: false,
          loaded: true,
          error: '',
        }),
      );
    } catch (error) {
      dispatch(userUpdateAction({ isLoading: false, error: error.message }));
    }
  };
};

export const fetchUserState = () => {
  return async dispatch => {
    try {
      dispatch(userUpdateAction({ isLoading: true }));
      const user = await authApi.fetchUser();
      const { operator, username } = user;
      dispatch(userUpdateAction({ isLoading: false, operator, username, loaded: true }));
    } catch (error) {
      const refreshCount = localStorage.getItem('refreshCount') || 0;
      if (refreshCount < 3) {
        dispatch(userUpdateAction({ isLoading: true }));
        localStorage.setItem('refreshCount', refreshCount + 1);
        window.location.reload();
      } else {
        localStorage.setItem('refreshCount', 0);
        dispatch(userUpdateAction({ isLoading: false, error: error.message }));
      }
    }
  };
};
