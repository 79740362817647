/* eslint-disable react/no-access-state-in-setstate */
import React from 'react';
import { ConfigProvider, Avatar, List, Input, Row, Col, Typography, Card, Pagination, message } from 'antd';
import moment from 'moment';

import channelApi from '../../../../services/channelApi';
import getCountryCode from '../../../../utilities/getCountryCode';

import EmptyListCard from '../../../shared/components/EmptyListCard';
import EmptySearchResult from '../../../shared/components/EmptySearchResult';
import LoadingSpinner from '../../../shared/components/LoadingSpinner';
import NoChannels from '../../../assets/images/no_channels.png';

import { DATE_FORMAT, channelBroadcastAudienceLabel, channelAudiences } from '../../../../constants';

const { Text, Paragraph } = Typography;
const { Grid } = Card;

class ChannelList extends React.Component {
  state = {
    isLoading: false,
    channels: undefined,
    numRecords: 0,
    searchParams: {
      page: 1,
      search: '',
    },
    selectedChannel: this.props.selectedChannel,
  };

  async componentDidMount() {
    await this.fetchChannels();
  }

  async componentDidUpdate() {
    if (!this.props.selectedChannel) return;
    // When a new channel is selected. The condition below evaluates to true
    if (this.props.selectedChannel !== this.state.selectedChannel) {
      // Apply search & select channel
      const staffRequestId = this.props.selectedChannel.staff_request.id;
      const channelResponse = await channelApi.fetchChannels({ page: 1, search: staffRequestId });
      this.setState({
        searchParams: { page: 1, search: staffRequestId },
        selectedChannel: this.props.selectedChannel,
        channels: channelResponse.results,
        numRecords: channelResponse.count,
        isLoading: false,
      });
    }
  }

  fetchChannels = async () => {
    this.setState({ isLoading: true });
    const country = getCountryCode();
    const params = {
      ...this.state.searchParams,
      country,
      audience: [channelAudiences.APPLIED_APPLICANTS, channelAudiences.ACTIVE_EMPLOYEES].join(','),
    };
    try {
      const channelResponse = await channelApi.fetchChannels(params);
      this.setState({
        isLoading: false,
        channels: channelResponse.results,
        numRecords: channelResponse.count,
      });
    } catch (error) {
      message.error(error.response.data.detail || error.message);
      this.setState({
        isLoading: false,
      });
    }
  };

  onSearchTyping = e => {
    const { value } = e.target;
    this.setState(prevState => {
      const nextSearchParams = prevState.searchParams;
      nextSearchParams.search = value;
      return { searchParams: nextSearchParams };
    });
  };

  onSearchParamChange = value => {
    this.setState(
      prevState => {
        const nextSearchParams = prevState.searchParams;
        nextSearchParams.search = value;
        nextSearchParams.page = 1;
        return { searchParams: nextSearchParams };
      },
      () => this.fetchChannels(),
    );
  };

  onPageChange = pageNum => {
    this.setState(
      prevState => {
        const nextSearchParams = prevState.searchParams;
        nextSearchParams.page = pageNum;
        return { searchParams: nextSearchParams };
      },
      () => this.fetchChannels(),
    );
  };

  getEmptyCard = () => {
    const { isLoading } = this.state;
    if (isLoading) return <></>;

    if (this.isSearchApplied()) {
      return (
        <Row style={{ marginTop: '48px' }}>
          <EmptySearchResult />
        </Row>
      );
    }
    return (
      <EmptyListCard
        title="No channels yet"
        description="Broadcasts sent to hired or applied workers in active SRs will show up here."
        type="list"
        imgSrc={NoChannels}
      />
    );
  };

  isSearchApplied = () => {
    const { searchParams } = this.state;
    return searchParams.search;
  };

  onChannelChange = selectedChannel => {
    this.props.handleChannelChange(selectedChannel);
    this.setState({
      selectedChannel,
    });
  };

  render() {
    const { channels, searchParams, isLoading, numRecords, selectedChannel } = this.state;
    // Ant D bug: The channelListItems have no padding causing the border bottom to disappear.
    const searchBarStyle =
      numRecords > 0
        ? { width: '100%', padding: '24px', borderBottom: '0.5px solid #E8E8E8' }
        : { width: '100%', padding: '24px' };
    return (
      <Card
        style={{
          width: '100%',
          borderRadius: '4px',
          boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15)',
          overflow: 'hidden',
        }}
      >
        <Grid hoverable={false} style={searchBarStyle}>
          <Input.Search
            allowClear
            placeholder="Search"
            value={searchParams.search}
            onChange={this.onSearchTyping}
            onSearch={searchValue => this.onSearchParamChange(searchValue)}
          />
        </Grid>
        <Grid hoverable={false} style={{ width: '100%', padding: 0, overflowY: 'auto' }}>
          <ConfigProvider renderEmpty={this.getEmptyCard}>
            <List
              itemLayout="vertical"
              style={{ height: 'calc(100vh - 420px)' }}
              loading={
                isLoading && {
                  indicator: (
                    <div style={{ marginTop: '100px' }}>
                      <LoadingSpinner width="50px" />
                    </div>
                  ),
                }
              }
              dataSource={channels || []}
              renderItem={item => {
                let activeChatStyle = {};
                if (selectedChannel && item.sid === selectedChannel.sid) {
                  activeChatStyle = { backgroundColor: '#F5F5F5' };
                }
                const { client, title, id } = item.staff_request;

                let audienceName = '';
                if (item.audience in channelBroadcastAudienceLabel) {
                  audienceName = `(${channelBroadcastAudienceLabel[item.audience]})`;
                }

                return (
                  <Card
                    bordered={false}
                    onClick={() => this.onChannelChange(item)}
                    style={{ padding: '16px 24px', cursor: 'pointer', borderBottom: 0, ...activeChatStyle }}
                  >
                    <Row type="flex" gutter={16} style={{ flexFlow: 'nowrap' }}>
                      <Col>
                        <Avatar
                          shape="square"
                          size={72}
                          src={client.logo}
                          alt="client_logo"
                          icon="user"
                          className="v2-avatar-wrapper"
                        />
                      </Col>
                      <Col span={18} style={{ paddingRight: 0 }}>
                        <Row type="flex" justify="space-between">
                          <Text strong style={{ color: '#262626' }}>
                            #{id} {audienceName}
                          </Text>
                          <Text type="secondary">{moment(item.created_date).format(DATE_FORMAT)}</Text>
                        </Row>
                        <Row>
                          <Paragraph ellipsis style={{ marginBottom: '6px' }}>
                            {title}
                          </Paragraph>
                          <Paragraph ellipsis type="secondary" style={{ marginBottom: 0 }}>
                            {client.name}
                          </Paragraph>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                );
              }}
            />
          </ConfigProvider>
        </Grid>
        <Grid hoverable={false} style={{ width: '100%', padding: '24px' }}>
          <Row type="flex" gutter={10} align="middle">
            <Col>
              <Text>{`Total ${numRecords} items`}</Text>
            </Col>
            <Col>
              <Pagination
                hideOnSinglePage
                current={searchParams.page}
                size="small"
                total={numRecords}
                onChange={this.onPageChange}
                pageSize={20}
              />
            </Col>
          </Row>
        </Grid>
      </Card>
    );
  }
}

export default ChannelList;
