import { Form, Input, Modal, Select, Typography } from 'antd';
import React from 'react';

const { Item } = Form;
const { TextArea } = Input;
const { Option } = Select;
const { Text } = Typography;

const SelectionWithNotesModal = ({
  form,
  visible,
  loading,
  modalTitle,
  modalPrompt,
  optionsLabel,
  options,
  optionValidationRules,
  notesLabel,
  notesInputPlaceholderText,
  notesValidationRules,
  onOk,
  okButtonType,
  okButtonLabel,
  onCancel,
}) => (
  <Modal
    visible={visible}
    confirmLoading={loading}
    title={<span style={{ fontSize: '20px' }}>{modalTitle}</span>}
    okType={okButtonType}
    okText={okButtonLabel}
    onOk={() => {
      form.validateFields(async (err, values) => {
        if (!err) {
          onOk(values);
        }
      });
    }}
    onCancel={onCancel}
  >
    <Form hideRequiredMark>
      <div
        style={{
          marginBottom: 12,
        }}
      >
        {modalPrompt}
      </div>
      <Item
        colon={false}
        label={optionsLabel}
        style={{
          marginBottom: 12,
        }}
      >
        {form.getFieldDecorator('option', {
          rules: optionValidationRules,
        })(
          <Select placeholder="Select one">
            {Object.values(options).map(({ key, title, description, value, code, name }) => (
              <Option key={key || value || code} value={value || code}>
                <div>
                  <Text>{title || name}</Text>
                </div>
                <div>
                  <Text type="secondary" style={{ whiteSpace: 'pre-line' }}>
                    {description}
                  </Text>
                </div>
              </Option>
            ))}
          </Select>,
        )}
      </Item>

      <Item
        colon={false}
        label={notesLabel}
        style={{
          marginBottom: 12,
        }}
      >
        {form.getFieldDecorator('notes', { rules: notesValidationRules })(
          <TextArea rows={5} placeholder={notesInputPlaceholderText} />,
        )}
      </Item>
    </Form>
  </Modal>
);

export default Form.create()(SelectionWithNotesModal);
