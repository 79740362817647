import React, { Component } from 'react';
import { Button, message } from 'antd';
import fileDownload from 'js-file-download';
import paymentApi from '../../../services/paymentApi';

class PaymentReportDownloadButton extends Component {
  state = { downloading: false };

  handleDownload = () => {
    this.setState({ downloading: true });
    paymentApi
      .downloadPaymentReports(this.props.params)
      .then(data => {
        fileDownload(data, 'payment_reports.csv');
        this.setState({ downloading: false });
      })
      .catch(error => {
        this.setState({
          downloading: false,
        });
        if (error.response.status === 400) {
          message.error(error.response.data.detail);
        }
      });
  };

  render() {
    return (
      <Button onClick={this.handleDownload} loading={this.state.downloading} icon="download">
        Download CSV
      </Button>
    );
  }
}

export default PaymentReportDownloadButton;
