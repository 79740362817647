import React from 'react';
import { Icon, Table, Tooltip, Row } from 'antd';
import moment from 'moment';
import StatusLabel from '../../../shared/components/StatusLabel';
import { DATE_FORMAT, DEFAULT_DATETIME_FORMAT, clientStatuses } from '../../../constants';
import ClientAvatar from '../../../shared/components/ClientAvatar';
import FillRateBar from '../../../shared/components/FillRateBar';
import routes from '../../../routes';
import EntityLink from '../../../shared/components/EntityLink';

const CLIENT_NOT_ACTIVE_MESSAGE = 'Client is not Active!'; // TODO: refactor into messages file

export default class StaffRequestTable extends React.Component {
  getDataSource = () => {
    const { staffRequests } = this.props;

    if (!staffRequests) return [];

    return staffRequests.map(staffRequest => {
      return {
        key: staffRequest.id,
        staffRequestId: <EntityLink to={`${routes.staffRequests}/${staffRequest.id}`}>{staffRequest.id}</EntityLink>,
        createdDate: moment(staffRequest.created_date).format(DEFAULT_DATETIME_FORMAT),
        client: staffRequest.client && (
          <Row type="flex">
            <ClientAvatar client={staffRequest.client} />
            {staffRequest.client.status !== clientStatuses.ACTIVE && (
              <Tooltip title={CLIENT_NOT_ACTIVE_MESSAGE}>
                <Icon type="warning" theme="filled" style={{ fontSize: 14, margin: 5 }} />
              </Tooltip>
            )}
          </Row>
        ),
        location: staffRequest.location && staffRequest.location.name,
        title: staffRequest.title && staffRequest.title,
        position: staffRequest.position && staffRequest.position.name,
        // show only dates for period
        period:
          staffRequest.start_time &&
          staffRequest.end_time &&
          `${moment(staffRequest.start_time).format(DATE_FORMAT)} - ${moment(staffRequest.end_time).format(
            DATE_FORMAT,
          )}`,
        status: <StatusLabel status={staffRequest.status} />,
        newApplications: staffRequest.statuses_counts.applied,
        fillRate: <FillRateBar current={staffRequest.statuses_counts.active} required={staffRequest.staff_required} />,
      };
    });
  };

  getColumns = () => {
    return [
      {
        title: 'ID',
        dataIndex: 'staffRequestId',
        width: 100,
      },
      {
        title: 'Created Date',
        dataIndex: 'createdDate',
        width: 140,
      },
      {
        title: 'Client',
        dataIndex: 'client',
        width: 200,
      },
      {
        title: 'Location',
        dataIndex: 'location',
        width: 200,
      },
      {
        title: 'Title',
        dataIndex: 'title',
        width: 200,
      },
      {
        title: 'Position',
        dataIndex: 'position',
        width: 160,
      },
      {
        title: 'Period',
        dataIndex: 'period',
        width: 180,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        width: 140,
      },
      {
        title: () => (
          <div>
            <Tooltip title="Number of workers pending approval">
              Pending Approval
              <Icon style={{ marginLeft: 5 }} type="info-circle" theme="filled" />
            </Tooltip>
          </div>
        ),
        dataIndex: 'newApplications',
        width: 100,
      },
      {
        title: 'Fill Rate',
        dataIndex: 'fillRate',
        width: 100,
      },
    ];
  };

  render() {
    const { staffRequests } = this.props;
    return (
      <Table
        style={{ overflowX: 'auto' }}
        dataSource={this.getDataSource()}
        columns={this.getColumns()}
        loading={!staffRequests}
        size="middle"
        pagination={false}
      />
    );
  }
}
