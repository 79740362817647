export const permissions = {
  // Partner
  createPartner: 'partners:create',
  editPartner: 'partners:edit',
  approvePartner: 'partners:approve',
  rejectPartner: 'partners:reject',
  suspendPartner: 'partners:suspend',
  // Client
  createClient: 'clients:create',
  editClient: 'clients:edit',
  approveClient: 'clients:approve',
  deactivateClient: 'clients:deactivate',
  reactivateClient: 'clients:reactivate',
  suspendClient: 'clients:suspend',
  unsuspendClient: 'clients:unsuspend',
  archiveClient: 'clients:archive',
  unarchiveClient: 'clients:unarchive',
  editPricingClient: 'clients:edit-pricing',
  // Manager
  suspendManager: 'managers:suspend',
  // Staff Request
  createStaffRequest: 'staff-requests:create',
  editStaffRequest: 'staff-requests:edit',
  postStaffRequest: 'staff-requests:post',
  cancelStaffRequest: 'staff-requests:cancel',
  // Timesheet
  createTimesheet: 'timesheet:create',
  editTimesheet: 'timesheet:edit',
  voidApprovedTimesheet: 'timesheet:void-approved',
  bulkApproveTimesheet: 'timesheet:bulk-approve',
  resolveTimesheet: 'timesheet:resolve',
  // Payment
  createPaymentItem: 'payment-items:create',
  editPaymentItem: 'payment-items:edit',
  createPaymentReport: 'payment-reports:create',
  editPaymentReport: 'payment-reports:edit',
  // Billing
  disputeBillingItem: 'billing-items:dispute',
  editBillingItem: 'billing-items:edit',
  editBillingReport: 'billing-reports:edit',
  // Setting
  createUser: 'users:create',
  editUser: 'users:edit',
};

export const roles = [
  {
    title: 'Admin',
    value: 'admin',
    description: 'Full access to everything, including user access management privileges.',
  },
  {
    title: 'Sales',
    value: 'sales',
    description: 'Create and edit client profiles. Create and submit Staff Requests for approval.',
  },
  {
    title: 'Project Manager',
    value: 'operations_manager',
    description:
      'Create, edit, approve, reject, and suspend worker profiles. Create, submit, approve, and cancel Staff Requests. Resolve timesheet entries and edit clients.',
  },
  {
    title: 'POPS',
    value: 'recruitment',
    description: 'Create, edit, approve and suspend worker profiles. Create and submit Staff Requests for approval.',
  },
  {
    title: 'Ops Support',
    value: 'fulfilment',
    description:
      'Create, edit, approve and suspend worker profiles. Create, submit, approve, and cancel Staff Requests. Approve and resolve timesheet entries.',
  },
  {
    title: 'Finance',
    value: 'finance',
    description:
      'Create, edit, approve, and suspend client profiles. Approve, resolve, and void timesheet entries. Edit payment items and reports.',
  },
  {
    title: 'HR',
    value: 'hr',
    description: 'Create or edit User',
  },
];

export const roleAuthorizations = {
  admin: {
    staticPermissions: Object.keys(permissions).map(key => permissions[key]),
  },
  sales: {
    staticPermissions: [
      permissions.createClient,
      permissions.editClient,
      permissions.createStaffRequest,
      permissions.editStaffRequest,
    ],
  },
  hr: {
    staticPermissions: [permissions.createUser, permissions.editUser],
  },
  recruitment: {
    staticPermissions: [
      permissions.createPartner,
      permissions.editPartner,
      permissions.approvePartner,
      permissions.rejectPartner,
      permissions.suspendPartner,
      permissions.createStaffRequest,
      permissions.editStaffRequest,
    ],
  },
  fulfilment: {
    staticPermissions: [
      permissions.createPartner,
      permissions.editPartner,
      permissions.suspendPartner,
      permissions.createStaffRequest,
      permissions.editStaffRequest,
      permissions.postStaffRequest,
      permissions.cancelStaffRequest,
      permissions.createTimesheet,
      permissions.editTimesheet,
      permissions.voidApprovedTimesheet,
      permissions.resolveTimesheet,
    ],
  },
  operations_manager: {
    staticPermissions: [
      permissions.createPartner,
      permissions.editPartner,
      permissions.approvePartner,
      permissions.rejectPartner,
      permissions.suspendPartner,
      permissions.createStaffRequest,
      permissions.editStaffRequest,
      permissions.postStaffRequest,
      permissions.cancelStaffRequest,
      permissions.editClient,
      permissions.resolveTimesheet,
      permissions.createTimesheet,
      permissions.editTimesheet,
      permissions.voidApprovedTimesheet,
      permissions.bulkApproveTimesheet,
      permissions.createClient,
      permissions.suspendManager,
    ],
  },
  finance: {
    staticPermissions: [
      permissions.editClient,
      permissions.approveClient,
      permissions.suspendClient,
      permissions.unsuspendClient,
      permissions.editPricingClient,
      permissions.archiveClient,
      permissions.unarchiveClient,
      permissions.deactivateClient,
      permissions.reactivateClient,
      permissions.createPaymentItem,
      permissions.editPaymentItem,
      permissions.createPaymentReport,
      permissions.editPaymentReport,
      permissions.disputeBillingItem,
      permissions.editBillingItem,
      permissions.editBillingReport,
      permissions.createTimesheet,
      permissions.editTimesheet,
      permissions.voidApprovedTimesheet,
      permissions.bulkApproveTimesheet,
      permissions.resolveTimesheet,
    ],
  },
};
