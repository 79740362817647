import React from 'react';
import { Icon, Progress } from 'semantic-ui-react';
import styled from 'styled-components/macro';
import { fontO11b } from '../../styles/fonts';
import { colors } from '../../styles/colors';

const CustomProgress = styled(Progress)`
  width: 90px;
  height: 5px;
`;

const CustomIcon = styled(Icon)`
  &&& {
    height: auto;
    color: ${colors.grey};
  }
`;

const Label = styled.div`
  ${fontO11b};
  margin-right: 5px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  max-width: 100%;

  ${CustomIcon} {
    margin-right: 13px;
  }

  ${CustomProgress} {
    &.ui.progress:last-child {
      margin: 0 0 0 auto;
    }
  }
`;

export default ({ current, required, ...props }) => (
  <Container {...props}>
    <Label>
      {current} /{required}
    </Label>
    <CustomProgress
      size="tiny"
      success={current >= required}
      warning={current > 0 && current < required}
      error={current === 0}
      percent={(current / required) * 100}
    />
  </Container>
);
