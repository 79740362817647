import React from 'react';
import moment from 'moment';
import * as Sentry from '@sentry/browser';
import { isEqual, intersection, minBy, maxBy, max } from 'lodash';
import { Row, message, Modal, Typography, Icon } from 'antd';
import { withTranslation } from 'react-i18next';
import shiftApi from '../../../services/shiftApi';
import { colors } from '../../../styles/colors';
import { attendanceStatuses, DATE_FORMAT, unsetCode, assignmentStatuses } from '../../../constants';
import datetimeUtils from '../../utilities/datetimeUtils';
import attendanceUtils from '../../utilities/attendanceUtils';
import employmentUtils from '../../utilities/employmentUtils';
import AssignmentTable from './components/AssignmentTable';
import AssignmentFilterAndAction from './components/AssignmentFilterAndAction';

const { Text } = Typography;

const squareIcon = (borderColor, backgroundColor) => (
  <div
    style={{
      display: 'inline-block',
      height: 12,
      width: 12,
      borderWidth: 1,
      borderStyle: 'solid',
      backgroundColor,
      borderColor,
    }}
  />
);

const getColorLabel = t => {
  const colorLabels = [
    {
      icon: squareIcon(colors.blue, colors.blue),
      name: t('unpublished').toUpperCase(),
    },
    {
      icon: squareIcon(colors.green, colors.green),
      name: t('published').toUpperCase(),
    },
    {
      icon: <Icon type="clock-circle" style={{ color: colors.grey }} />,
      name: t('unconfirmed').toUpperCase(),
    },
    {
      icon: <Icon type="check-circle" style={{ color: colors.green }} />,
      name: t('workerConfirmed').toUpperCase(),
    },
    {
      icon: <Icon type="exclamation-circle" style={{ color: colors.yellow }} />,
      name: t('absent').toUpperCase(),
    },
    {
      icon: squareIcon(colors.grey, colors.white),
      name: t('offDay').toUpperCase(),
    },
    {
      icon: squareIcon(colors.red, colors.red),
      name: t('noShow').toUpperCase(),
    },
  ];

  return colorLabels.map(label => (
    <span index={label.name} style={{ paddingRight: 24 }} key={label.name}>
      {label.icon} {label.name}
    </span>
  ));
};

class AssignmentListView extends React.Component {
  state = {
    submitting: false,
    selectedRowKeys: [],
    filteredPartnerIds: [], // Partner Id list pass into table as key filter
    searchFilter: [],
    scheduleFilter: [],
    statusFilter: [],
    scheduleFilterValue: unsetCode, // Default to select all schedule selection
    statusFilterValue: unsetCode, // Default to select all status selection
  };

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.weekdayDates, this.props.weekdayDates)) {
      this.resetFilter();
    }
  }

  resetFilter = () => {
    this.setState({
      scheduleFilterValue: unsetCode,
      statusFilterValue: unsetCode,
      searchFilter: [],
      scheduleFilter: [],
      statusFilter: [],
      filteredPartnerIds: [],
    });
  };

  // Compare current shift with original shift, if any attendance changed,
  // 1) update the shift, 2) notify the worker
  onClickPublish = async () => {
    const { weekdayDates, t } = this.props;
    const [pendingUpdateShifts, workersToNotify] = this.getShiftsAndWorkers();
    const startDate = moment(weekdayDates[0]).format(DATE_FORMAT);
    const endDate = moment(weekdayDates[6]).format(DATE_FORMAT);
    const workerCount = workersToNotify.length;
    Modal.confirm({
      centered: true,
      title: <Text strong>{t('publishShiftsTitle', { startDate, endDate })}</Text>,
      content: <Text>{t('publishShiftsBody', { workerCount })}</Text>,
      okText: t('publish'),
      okType: 'v2-primary',
      cancelText: t('Cancel'),
      icon: <Icon style={{ fontSize: '20px' }} type="question-circle" theme="twoTone" />,
      onOk: () => this.handlePublish(pendingUpdateShifts, workersToNotify),
    });
  };

  getShiftsAndWorkers = () => {
    const { shifts, originalShifts } = this.props;

    const pendingUpdateShifts = [];
    const workersToNotify = [];

    for (let i = 0; i < shifts.length; i += 1) {
      const newAttendances = shifts[i].attendances;
      const originalAttendances = originalShifts[i].attendances;

      if (!isEqual(newAttendances, originalAttendances)) {
        pendingUpdateShifts.push({
          id: shifts[i].id,
          attendances: shifts[i].attendances,
        });

        // A worker should be notified for three cases:
        // 1) New attendance (first time assigned to this shift)
        // 2) Attendance changed from assgined to unassigned (assigned off day or assigned to a different shift)
        // 3) Attendance changed from unassigned to assigned (previously assigned then unassigned, now back to assigned)
        newAttendances.forEach(attendance => {
          const matchingOriginalAttendance = originalAttendances.find(
            originalAtt => originalAtt.partner_id === attendance.partner_id,
          );
          // Case 1, new attendance
          if (!matchingOriginalAttendance) {
            workersToNotify.push(attendance.partner_id);
          } else {
            // Case 2 and Case 3, existing attendance has status changed
            if (matchingOriginalAttendance.status !== attendance.status) {
              workersToNotify.push(attendance.partner_id);
            }
          }
        });
      }
    }
    const uniqueWorkersToNotify = [...new Set(workersToNotify)];
    return [pendingUpdateShifts, uniqueWorkersToNotify];
  };

  handlePublish = async (shifts, workers) => {
    const { t, onRefresh } = this.props;
    this.setState({ submitting: true });

    const publishPromise = shifts.map(pendingUpdateShift => shiftApi.editShift(pendingUpdateShift));
    try {
      await Promise.all(publishPromise);
      await shiftApi.notifyPartner({
        client_id: this.props.clientId,
        partner_id: workers.join(','),
      });
      message.success(t('shiftPublishSuccess', { workerCount: workers.length }));
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({ submitting: false });
      onRefresh();
    }
  };

  /***************** Bulk Assign Start ***********************/
  handleSelect = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  /* 1.Based on the selected date and schedules, find the target shifts need to update
     2.Check of each worker, find their original shift, current shift
     3.Reassign worker to the target shift 
  */
  handleBulkAssign = (scheduleId, selectedScheduleDays) => {
    const selectedWorkerIds = this.state.selectedRowKeys;
    const { weekdayDates, shifts, timezone, employments } = this.props;
    const toBeUpdateDates = [];

    // Using recurrenceOptions value number to match with weekdayDates (Mon-0, Tue-1 ... Sun-6)
    selectedScheduleDays.forEach(dayIndex => {
      // only bulk assign future shifts
      if (weekdayDates[dayIndex] >= datetimeUtils.getDayStart(moment(), timezone)) {
        toBeUpdateDates.push(weekdayDates[dayIndex]);
      }
    });
    const updates = [];

    toBeUpdateDates.forEach(date => {
      const sameDayCurrentShifts = shifts.filter(shift => date.isSame(shift.start_time, 'day'));
      const targetShift = sameDayCurrentShifts.find(shift => shift.schedule.id === scheduleId);
      selectedWorkerIds.forEach(workerId => {
        // bulk assign only if the worker is on valid working day
        const workerEmployment = employments.find(employment => employment.partner.id === workerId);
        const contractStatus = employmentUtils.getDerivedWorkingStatus(date, workerEmployment);
        if (attendanceUtils.isValidWorkingDay(contractStatus)) {
          updates.push({
            date,
            workerId,
            targetShiftId: targetShift?.id,
          });
        }
      });
    });

    this.handleUpdates(updates);
    this.setState({ selectedRowKeys: [] });
  };

  /***************** Bulk Assign End ***********************/

  /***************** Filters Start ***********************/
  handleSearchChange = value => {
    this.setState({ searchFilter: value }, () => this.updateFilter());
  };

  handleScheduleChange = value => {
    this.setState({ scheduleFilterValue: value });
    let filterList;
    if (value === unsetCode) {
      filterList = [];
    } else {
      // If worker has assigned shift in the selected schedule
      const attendances = this.props.shifts
        .filter(shift => shift.schedule.id === value)
        .flatMap(shift => shift.attendances.filter(attendance => attendance.status === attendanceStatuses.ASSIGNED));
      const partnerIds = attendances
        .filter(attendance =>
          this.props.employments.find(employment => employment.partner.id === attendance.partner_id),
        )
        .map(attendance => attendance.partner_id);
      filterList = partnerIds;

      if (filterList.length === 0) {
        // If no partner matched, default to unmatchable array
        filterList = [unsetCode];
      }
    }
    this.setState({ scheduleFilter: filterList }, () => this.updateFilter());
  };

  handleStatusChange = value => {
    this.setState({ statusFilterValue: value });
    let filterList;
    const { shifts, employments } = this.props;
    if (value === unsetCode) {
      filterList = [];
    } else {
      let partnerIds = [];
      const attendances = shifts.flatMap(shifft => shifft.attendances);
      if (value === assignmentStatuses.UNCONFIRMED) {
        // if user assigned a shift but not confirmed yet
        partnerIds = employments
          .filter(employment =>
            attendances.find(
              attendance =>
                attendance.partner_id === employment.partner.id &&
                attendance.status === attendanceStatuses.ASSIGNED &&
                !attendance.confirmed,
            ),
          )
          .map(employment => employment.partner.id);
      } else if (value === assignmentStatuses.ABSENT) {
        // if user assigned a shift, but raised an absence
        partnerIds = employments
          .filter(employment =>
            attendances.find(
              attendance => attendance.partner_id === employment.partner.id && attendanceUtils.isAbsent(attendance),
            ),
          )
          .map(employment => employment.partner.id);
      }
      filterList = partnerIds;
      if (filterList.length === 0) {
        // If no partner matched, default to unmatchable array
        filterList = [unsetCode];
      }
    }
    this.setState({ statusFilter: filterList }, () => this.updateFilter());
  };

  updateFilter = () => {
    // If search filter, schedule filter and status filter all have value, intersect their common partners
    // If any filter in their blank array state, default to include all partners for intersection
    // Due to Antd will ignore null, undefined, blank array value, only can return an unmatchable array-[unsetCode] to show blank table
    const { searchFilter, scheduleFilter, statusFilter } = this.state;
    const allPartnerList = this.props.employments.map(employment => employment.partner.id);
    const search = searchFilter.length > 0 ? searchFilter : allPartnerList;
    const schedule = scheduleFilter.length > 0 ? scheduleFilter : allPartnerList;
    const status = statusFilter.length > 0 ? statusFilter : allPartnerList;

    const filteredPartnerIds = intersection(search, schedule, status);
    if (filteredPartnerIds.length > 0) {
      this.setState({ filteredPartnerIds });
    } else {
      this.setState({ filteredPartnerIds: [unsetCode] });
    }
  };
  /***************** Filters End ***********************/

  handleCopyShift = fromDate => {
    this.props.onCopy(fromDate);
  };

  handleUpdates = updates => {
    this.props.onUpdate(updates);
  };

  handleAutoAssign = async (maxWorkDays = null) => {
    const { shifts, weekdayDates, timezone, locationId, positionId, clientId, employments } = this.props;
    // special shifts with recommended_attendances
    this.setState({ submitting: true }); // TODO: borrow submititng, change it later
    try {
      const recommendedShifts = await shiftApi.getRecommendedShifts({
        shift_id: shifts.map(shift => shift.id).join(','),
        start_date: moment(minBy(weekdayDates, date => date))
          .tz(timezone)
          .toISOString(true),
        end_date: moment(maxBy(weekdayDates, date => date))
          .tz(timezone)
          .toISOString(true),
        max_work_days_per_worker: maxWorkDays,
        location: locationId,
        position: positionId,
        client: clientId,
      });
      this.setState({ submitting: false }); // TODO: borrow submititng, change it later
      // only update for future shifts
      const futureAssignableShifts = recommendedShifts.filter(shift =>
        moment(shift.start_time).isSameOrAfter(moment(), 'day'),
      );
      // call onUpdate, with special updates payload for parent
      // [{targetShiftId, date, workerId}]
      const updates = futureAssignableShifts.flatMap(shift => {
        return shift.recommended_attendances.map(att => ({
          targetShiftId: att.shift_id,
          date: shift.start_time,
          workerId: att.partner_id,
          employmenId: employments.find(employment => employment.partner.id === att.partner_id).id,
        }));
      });
      this.handleUpdates(updates);
    } catch (error) {
      Sentry.captureException(error);
      this.setState({ submitting: false }); // TODO: borrow submititng, change it later
      throw error; // throw up to caller
    }
  };

  shouldAllowAutoAssign = () => {
    const { originalShifts, weekdayDates } = this.props;
    // disable if no shifts to assign for
    if (originalShifts.length === 0) {
      return false;
    }

    // disable only if week has passed
    const earliestDay = max(weekdayDates);
    return moment().isBefore(earliestDay.clone().subtract(1, 'day'));
  };

  render() {
    const {
      submitting,
      selectedRowKeys,
      filteredPartnerIds,
      scheduleFilterValue,
      statusFilterValue,
      searchFilter,
    } = this.state;
    const {
      weekdayDates,
      loading: parentLoading,
      schedules,
      employments,
      onRefresh,
      assignmentData,
      publishCount,
      timezone,
      t,
    } = this.props;
    return (
      <Row>
        <Row style={{ paddingBottom: 24 }}>{getColorLabel(t)}</Row>
        <Row style={{ paddingBottom: 24 }}>
          <AssignmentFilterAndAction
            loading={submitting || parentLoading}
            employments={employments}
            timezone={timezone}
            schedules={schedules}
            weekdayDates={weekdayDates}
            publishCount={publishCount}
            selectedWorkerCount={selectedRowKeys.length}
            scheduleFilterValue={scheduleFilterValue}
            statusFilterValue={statusFilterValue}
            searchFilter={searchFilter}
            allowAutoAssign={this.shouldAllowAutoAssign()}
            onPublish={this.onClickPublish}
            onCopyShift={this.handleCopyShift}
            onBulkAssign={this.handleBulkAssign}
            onScheduleChange={this.handleScheduleChange}
            onStatusChange={this.handleStatusChange}
            onSearchChange={this.handleSearchChange}
            onAutoAssign={this.handleAutoAssign}
          />
        </Row>
        {/* Shifts Table */}
        <AssignmentTable
          loading={submitting || parentLoading}
          selectedRowKeys={selectedRowKeys}
          dataSource={assignmentData}
          weekdayDates={weekdayDates}
          filteredPartnerIds={filteredPartnerIds}
          onUpdate={this.handleUpdates}
          onSelect={this.handleSelect}
          onRefresh={onRefresh}
          timezone={timezone}
        />
      </Row>
    );
  }
}

export default withTranslation()(AssignmentListView);
