import React from 'react';
import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import AvatarImage from './AvatarImage';
import routes from '../../routes';
import { colors } from '../../styles/colors';

const Name = styled.span``;

const ContactInfo = styled.div`
  color: ${colors.darkGrey};
`;

const Identifiers = styled(Link)`
  color: ${colors.black};
`;

const Container = styled.div`
  display: flex;
  align-items: center;

  img {
    min-width: 2em;
  }

  ${Identifiers} {
    margin-left: 5px;
  }
`;

export default ({ partner, type, ...props }) => (
  <>
    {partner && (
      <Container {...props}>
        <Link to={`${routes.partners}/${partner.id}`}>
          <AvatarImage src={partner.image} name={partner.first_name} />
        </Link>
        <Identifiers to={`${routes.partners}/${partner.id}`}>
          <Name style={{ fontWeight: type === 'expanded' ? 'bold' : 'normal' }}>
            {`${partner.first_name} ${partner.last_name}`}
          </Name>
          {type === 'expanded' && (
            <ContactInfo>
              {partner.mobile} {partner.email && <>({partner.email})</>}
            </ContactInfo>
          )}
        </Identifiers>
      </Container>
    )}
  </>
);
