import React from 'react';
import { Card } from 'antd';
import TimesheetPage from './TimesheetPage';

const TimesheetsPage = () => {
  return (
    <Card>
      <TimesheetPage />
    </Card>
  );
};

export default TimesheetsPage;
