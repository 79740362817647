import React from 'react';
import { List, Pagination, Divider, Typography, Row, Col } from 'antd';

const { Text } = Typography;

const ListContainer = ({ dataSource, listItem, page, totalDataCount, onPageChange, pageSize, loading = false }) => (
  <>
    <List dataSource={dataSource} renderItem={listItem} loading={loading} />
    <Divider />
    <Row type="flex" style={{ margin: '30px 0 24px 0' }} justify="space-between" align="middle">
      <Col>
        <Pagination
          hideOnSinglePage
          current={page}
          pageSize={pageSize}
          total={totalDataCount}
          onChange={onPageChange}
        />
      </Col>
      <Col>
        <Text type="secondary">
          {pageSize * page < totalDataCount
            ? `Showing ${pageSize * (page - 1) + 1} - ${pageSize * page} of ${totalDataCount}`
            : `Showing ${pageSize * (page - 1) + 1} - ${totalDataCount} of ${totalDataCount}`}
        </Text>
      </Col>
    </Row>
  </>
);

export default ListContainer;
