import React, { Component } from 'react';
import { Modal, Typography, message } from 'antd';
import { withTranslation } from 'react-i18next';
import partnerApi from '../../../../services/partnerApi';

const { Text, Paragraph } = Typography;

class DirectJobInviteLeadModal extends Component {
  state = { loading: false };

  handleInvite = async () => {
    const { application, onCancel, onUpdate } = this.props;

    try {
      await partnerApi.invite(application.partner.id, application.staff_request.id);
      message.success('Workers successfully invited and notified by SMS');
      onUpdate();
    } catch (error) {
      message.error('Sorry! An error occurred in submission, please contact support');
    }

    this.setState({ loading: false });
    onCancel();
  };

  render() {
    const { application, onCancel } = this.props;
    return (
      <Modal
        title="Invite worker for SR"
        visible
        onOk={this.handleInvite}
        onCancel={onCancel}
        confirmLoading={this.state.loading}
        okText="Yes, invite"
        okType="v2-primary"
      >
        <Paragraph>
          You are about to invite
          <Text strong style={{ paddingLeft: 5, paddingRight: 5 }}>
            {application?.partner.last_name
              ? `${application?.partner.first_name} ${application?.partner.last_name}`
              : application?.partner.first_name}
          </Text>
          for this staff request. A contract will be sent for them to sign. Are you sure?
        </Paragraph>
      </Modal>
    );
  }
}

export default withTranslation()(DirectJobInviteLeadModal);
