import React, { Component } from 'react';
import { Modal, Typography, message } from 'antd';
import { withTranslation } from 'react-i18next';
import applicationApi from '../../../../services/applicationApi';
import { errorCodes } from '../../../../constants';

const { Text, Paragraph } = Typography;

class ForceHireApplicationModal extends Component {
  state = { loading: false };

  handleForceHire = async () => {
    const { t, applicationId, onUpdate, onCancel, worker } = this.props;
    this.setState({ loading: true });

    try {
      await applicationApi.approveApplication(applicationId);
      message.success(`Successfully sent contract to ${worker.first_name} ${worker.last_name}`);
      onUpdate();
    } catch (error) {
      if (error.response.data?.code === errorCodes.SCHEDULE_CONFLICT) {
        message.error(t('workerIsAlreadyInWorkforce'));
      } else {
        const msg = error.response.data?.detail ? error.response.data.detail : 'An unknown error occurred';
        message.error(msg);
      }
    }

    this.setState({ loading: false });
    onCancel();
  };

  render() {
    const { worker, visible, onCancel } = this.props;
    return (
      <Modal
        title="Force hire worker for SR"
        visible={visible}
        onOk={this.handleForceHire}
        onCancel={onCancel}
        confirmLoading={this.state.loading}
        okText="Yes, force hire"
        okType="v2-primary"
      >
        <Paragraph>
          You are about to force hire
          <Text strong style={{ paddingLeft: 5, paddingRight: 5 }}>
            {worker.last_name ? `${worker.first_name} ${worker.last_name}` : worker.first_name}
          </Text>
          for this staff request. A contract will be sent for them to sign. Are you sure?
        </Paragraph>
      </Modal>
    );
  }
}

export default withTranslation()(ForceHireApplicationModal);
