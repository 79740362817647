import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import numeral from 'numeral';
import moment from 'moment';
import { Table, Typography, Row, Col, Avatar } from 'antd';
import routes from '../../../../routes';
import StatusTag from '../../../shared/components/StatusTag';
import getPaginationConfig from '../../../utilities/getPaginationConfig';
import PaymentItemTypeLabel from '../../../shared/components/PaymentItemTypeLabel';

const { Text } = Typography;

class BillingItemList extends Component {
  columns = [
    {
      title: 'Item',
      dataIndex: 'id',
    },
    {
      width: 200,
      title: 'Worker',
      dataIndex: 'partner',
      render: partner => {
        if (!partner) return <></>;

        return (
          <>
            <Row gutter={8}>
              <Link to={routes.partnerDetail.replace(':id', partner.id)}>
                <Col span={8}>
                  <Avatar shape="square" size="large" src={partner.image} />
                </Col>
                <Text strong>
                  {partner.first_name} {partner.last_name}
                  <br />
                  <Text type="secondary">#{partner.id}</Text>
                </Text>
              </Link>
            </Row>
          </>
        );
      },
    },
    {
      title: 'Payment item',
      dataIndex: 'payment_item.id',
    },
    {
      title: 'Date',
      dataIndex: 'date',
      render: date => moment(date).format('DD MMM YYYY'),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      render: type => <PaymentItemTypeLabel type={type} />,
    },
    {
      title: 'Qty',
      dataIndex: 'quantity',
    },
    {
      title: 'Cost',
      dataIndex: 'unit_cost',
      render: unit_cost => numeral(unit_cost).format('0,0.00'),
    },
    {
      title: 'Amount',
      dataIndex: 'total',
      render: total => <Text strong>{numeral(total).format('0,0.00')}</Text>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: status => <StatusTag status={status} />,
    },
  ];

  render() {
    const { billingItems, loading, page, count, onPageChange } = this.props;
    return (
      <Table
        columns={this.columns}
        dataSource={billingItems}
        loading={loading}
        pageSize="20"
        pagination={{ ...getPaginationConfig(count, onPageChange), current: page }}
      />
    );
  }
}

export default BillingItemList;
