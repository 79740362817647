import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import FormGroup from './FormGroup';
import Dropdown from './Dropdown';
import Input from './Input';
import YearInput from './YearInput';

const CustomInput = styled(Input)``;

const Container = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 1fr;
  grid-column-gap: 30px;
`;

export default class ExperienceInput extends React.Component {
  static propTypes = {
    value: PropTypes.object.isRequired,
    onChange: PropTypes.func,
  };

  handleDegreeChange = degree => {
    this.props.onChange({
      ...this.props.value,
      degree,
    });
  };

  handleSchoolChange = school => {
    this.props.onChange({
      ...this.props.value,
      school,
    });
  };

  handleFieldChange = fieldOfStudy => {
    this.props.onChange({
      ...this.props.value,
      field_of_study: fieldOfStudy,
    });
  };

  handleYearChange = year => {
    this.props.onChange({
      ...this.props.value,
      graduation_year: year,
    });
  };

  render() {
    const { value, errors, touched } = this.props;

    console.log(value.graduation_year);

    return (
      <Container>
        <FormGroup>
          <FormGroup.Label>Degree:</FormGroup.Label>
          <Dropdown
            options={[
              {
                key: 'primary_school',
                value: 'primary_school',
                text: 'Primary School',
              },
              {
                key: 'high_school',
                value: 'high_school',
                text: 'High School',
              },
              {
                key: 'vocation',
                value: 'vocation',
                text: 'Vocation',
              },
              {
                key: 'university',
                value: 'university',
                text: 'University',
              },
              {
                key: 'others',
                value: 'others',
                text: 'Others',
              },
            ]}
            placeholder="Degree"
            value={value.degree}
            onChange={(e, { name, value }) => this.handleDegreeChange(value)}
          />
          {errors && touched && errors.degree && touched.degree && <FormGroup.Error>{errors.degree}</FormGroup.Error>}
        </FormGroup>
        <FormGroup>
          <FormGroup.Label>School:</FormGroup.Label>
          <CustomInput
            placeholder="School"
            value={value.school}
            onChange={({ target: { value } }) => {
              this.handleSchoolChange(value);
            }}
          />
          {errors && touched && errors.school && touched.school && <FormGroup.Error>{errors.school}</FormGroup.Error>}
        </FormGroup>
        <FormGroup>
          <FormGroup.Label>Field of Study:</FormGroup.Label>
          <CustomInput
            placeholder="Field of Study e.g. Major"
            value={value.field_of_study}
            onChange={event => {
              this.handleFieldChange(event.target.value);
            }}
          />
          {errors && touched && errors.field_of_study && touched.field_of_study && (
            <FormGroup.Error>{errors.field_of_study}</FormGroup.Error>
          )}
        </FormGroup>
        <FormGroup>
          <FormGroup.Label>Graduation Year:</FormGroup.Label>
          <YearInput onChange={this.handleYearChange} value={value.graduation_year} />
          {errors && touched && errors.graduation_year && touched.graduation_year && (
            <FormGroup.Error>{errors.graduation_year}</FormGroup.Error>
          )}
        </FormGroup>
      </Container>
    );
  }
}
