import { Avatar, Card, Col, Icon, Row, Typography } from 'antd';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { clientTypes, DATE_FORMAT, paymentTypes, SELF_SIGNUP_SOURCE } from '../../../constants';
import routes from '../../../routes';
import { colors } from '../../../styles/colors';
import SelfSignupIndicator from './SelfSignupIndicator';

import StatusTag from './StatusTag';
import { humanizeText, toCamelCase } from '../../utilities/textUtils';

const { Text, Title } = Typography;

const getClientSrStatusCounts = statusCounts => {
  const srStatusesWithOneOrMore = [];
  Object.keys(statusCounts).forEach(key => {
    if (statusCounts[key] > 0) {
      srStatusesWithOneOrMore.push(key);
    }
  });
  return (
    srStatusesWithOneOrMore &&
    srStatusesWithOneOrMore.length > 0 && (
      <>
        <Row style={{ marginTop: '16px' }}>
          <Text strong type="secondary" style={{ fontSize: '12px' }}>
            STAFF REQUESTS
          </Text>
        </Row>
        <Row>
          <Text style={{ fontSize: '12px' }}>
            {srStatusesWithOneOrMore.includes('pending_review_sr_count') && (
              <>
                <Text strong>{statusCounts.pending_review_sr_count}</Text>
                <Text>{srStatusesWithOneOrMore.length > 1 ? 'Pending Review  · ' : 'Pending Review'}</Text>
              </>
            )}
            {srStatusesWithOneOrMore.includes('posted_sr_count') && (
              <>
                <Text strong>{statusCounts.posted_sr_count}</Text>
                <Text>{srStatusesWithOneOrMore.includes('in_progress_sr_count') ? 'Posted  · ' : 'Posted'}</Text>
              </>
            )}
            {srStatusesWithOneOrMore.includes('in_progress_sr_count') && (
              <>
                <Text strong>{statusCounts.in_progress_sr_count}</Text> <Text>In progress</Text>
              </>
            )}
          </Text>
        </Row>
      </>
    )
  );
};

const ClientSummaryCard = ({ client, t }) => {
  const manager = client.managers[0];

  const { type, payment_method: paymentType, sr_status_counts: srStatusCounts } = client;

  const getTypeColor = type => {
    switch (type) {
      case clientTypes.WMP:
        return '#808080';
      case clientTypes.MANAGED_SERVICE:
        return '#FA8C16';
      case clientTypes.BPO:
        return '#722ED1';
      default:
        return '#808080';
    }
  };

  const notProvidedData = item => <Text style={{ color: '#BFBFBF' }}>{item} not provided</Text>;

  const getPaymentType = paymentType => {
    let icon = 'bank';
    let message = notProvidedData('Payment type');

    if (paymentType === paymentTypes.BANK_TRANSFER) {
      icon = 'dollar';
      message = 'Bank transfer';
    }
    if (paymentType === paymentTypes.CREDIT) {
      icon = 'credit-card';
      message = 'Credit card payment';
    }
    if (paymentType === paymentTypes.CHEQUE) {
      message = 'Cheque payment';
    }
    return (
      <span>
        <Icon type={icon} style={{ color: '#BFBFBF' }} /> {message}
      </span>
    );
  };

  return (
    <Card bordered={false} style={{ width: '100%', margin: '0px 16px 16px' }} bodyStyle={{ padding: 0 }}>
      <Row type="flex" gutter={24}>
        <Col>
          <Avatar shape="square" size={72} src={client.logo} />
        </Col>
        <Col>
          <Row type="flex" style={{ alignItems: 'baseline', marginBottom: '-4px' }} gutter={18}>
            <Col>
              <Title
                strong
                level={4}
                ellipsis={{ rows: 2, expandable: false }}
                style={{ wordWrap: 'break-word', maxWidth: '400px' }}
              >
                <Link to={routes.clientsDetail.replace(':id', client.id)} style={{ color: 'inherit' }}>
                  {client.name}
                </Link>
              </Title>
            </Col>
            {type && (
              <Col>
                <Text strong style={{ color: getTypeColor(type) }}>
                  <Icon type="crown" /> {`${t(toCamelCase(client.type))} (${humanizeText(client.service_level)})`}
                </Text>
              </Col>
            )}
          </Row>
          {manager && (
            <Row style={{ marginBottom: '8px', fontSize: '12px' }}>
              <Icon type="user" style={{ color: '#BFBFBF' }} />
              <>
                {' '}
                {manager ? <Text strong>{manager.name}</Text> : notProvidedData('Manager')} ·{' '}
                {manager.phone || notProvidedData('Phone')} · {manager.email || notProvidedData('Email')}
              </>
            </Row>
          )}
          <Row style={{ marginBottom: '8px', fontSize: '12px' }}>{getPaymentType(paymentType)}</Row>
          {client.source === SELF_SIGNUP_SOURCE && (
            <SelfSignupIndicator style={{ marginBottom: 8, fontSize: 12 }} color={colors.disabled} />
          )}
          <Row>
            <StatusTag status={client.status} verified={client.verified} />
            <Text type="secondary" style={{ fontSize: '12px' }}>
              {`Created ${moment(client.created_date).format(DATE_FORMAT)}`}
            </Text>
          </Row>
          <Row>{getClientSrStatusCounts(srStatusCounts)}</Row>
        </Col>
      </Row>
    </Card>
  );
};
export default withTranslation()(ClientSummaryCard);
