/* eslint-disable global-require */
/*
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
READ ME

Entry for Admin Portal redesign version.
We will be using existing components in this App. App.js however, will NOT use
existing "/pages" & "/shared" at the end of this project.
It will use it's own "/v2/pages" and "/v2/shared".
~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
*/
import React from 'react';
import { Router, Redirect, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import * as Sentry from '@sentry/browser';

import routes from './routes';
import AuthLayout from './v2/shared/layouts/AuthLayout';

// Pages
import StaffRequestsPage from './v2/pages/StaffRequestsPage';
import WorkersPage from './v2/pages/WorkersPage';
import ClientsPage from './v2/pages/ClientsPage';
import TimesheetsPage from './v2/pages/TimesheetsPage';
import BillingsPage from './v2/pages/BillingsPage';
import PaymentItemsPage from './v2/pages/PaymentItemsPage';
import PaymentReportsPage from './v2/pages/PaymentReportsPage';
import SettingsPage from './v2/pages/SettingsPage';
import BroadcastPage from './v2/pages/BroadcastPage';
import ShiftsPage from './v2/pages/ShiftsPage';
import WorkforcePage from './v2/pages/WorkforcePage';
import 'semantic-ui-css/semantic.min.css';
import 'antd/dist/antd.css';

// Deprecated imports. Should be removed in time.
import PublicLayout from './shared/layouts/PublicLayout';
import LoginPage from './pages/LoginPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ResetPasswordPage from './pages/ResetPasswordPage';

const pages = [
  { exact: true, path: routes.login, component: LoginPage, layout: PublicLayout },
  { exact: true, path: routes.forgotPassword, component: ForgotPasswordPage, layout: PublicLayout },
  { exact: true, path: routes.resetPassword, component: ResetPasswordPage, layout: PublicLayout },
  // Auth pages that requires login below
  { exact: false, path: routes.staffRequests, component: StaffRequestsPage, layout: AuthLayout },
  { exact: false, path: routes.partners, component: WorkersPage, layout: AuthLayout },
  { exact: false, path: routes.clients, component: ClientsPage, layout: AuthLayout },
  { exact: false, path: routes.timesheets, component: TimesheetsPage, layout: AuthLayout },
  { exact: false, path: routes.billings, component: BillingsPage, layout: AuthLayout },
  { exact: false, path: routes.paymentItems, component: PaymentItemsPage, layout: AuthLayout },
  { exact: false, path: routes.paymentReports, component: PaymentReportsPage, layout: AuthLayout },
  { exact: false, path: routes.settings, component: SettingsPage, layout: AuthLayout },
  { exact: false, path: routes.broadcast, component: BroadcastPage, layout: AuthLayout },
  { exact: true, path: routes.shifts, component: ShiftsPage, layout: AuthLayout },
  { exact: true, path: routes.workforce, component: WorkforcePage, layout: AuthLayout },
];

class App extends React.Component {
  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    const history = createBrowserHistory();
    return (
      <Router history={history}>
        <Switch>
          <Route exact path={routes.home} render={() => <Redirect to={routes.workforce} />} />
          {pages.map(({ exact, path, component: Component, layout: Layout, layoutProps }, index) => (
            <Route
              key={index}
              exact={exact}
              path={path}
              component={props => (
                <Layout {...layoutProps}>
                  <Component {...props} />
                </Layout>
              )}
            />
          ))}
          <Redirect to={routes.home} />
        </Switch>
      </Router>
    );
  }
}

export default App;
