import React from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import styled from 'styled-components';
import { rgba } from 'polished';
import Field from './Field';
import { colors } from '../../../styles/colors';
import { fontO14r } from '../../../styles/fonts';

const Suggestion = styled.div`
  background: ${colors.white};
  padding: 10px 15px;
  ${fontO14r};
  cursor: pointer;

  &:hover {
    background: ${colors.lightGrey};
  }
`;

const Suggestions = styled.div`
  ${Suggestion} {
    border-bottom: 1px solid ${colors.grey};

    &:last-child {
      border-bottom: none;
    }
  }
`;

const Container = styled.div`
  position: relative;

  ${Suggestions} {
    position: absolute;
    z-index: 999;
    top: 100%;
    left: 0;
    right: 0;
    box-shadow: 0 3px 6px ${rgba(colors.absoluteBlack, 0.3)};
  }
`;

export default ({ ...props }) => (
  <PlacesAutocomplete {...props}>
    {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
      <Container>
        <Field
          {...getInputProps({
            placeholder: 'Street',
          })}
        />
        <Suggestions>
          {loading && <div>Loading...</div>}
          {suggestions.map(suggestion => (
            <Suggestion {...getSuggestionItemProps(suggestion)}>{suggestion.description}</Suggestion>
          ))}
        </Suggestions>
      </Container>
    )}
  </PlacesAutocomplete>
);
