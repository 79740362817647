import React from 'react';
import { Modal } from 'semantic-ui-react';
import { Formik, Form, ErrorMessage } from 'formik';
import styled from 'styled-components';
import moment from 'moment';
import * as Yup from 'yup';
import Field from '../../../../shared/components/forms/Field';
import Button from '../../../../shared/components/Button';
import FormGroup from '../../../../shared/components/forms/FormGroup';
import timesheetEntryApi from '../../../../services/timesheetEntryApi';
import PartnerAvatar from '../../../../shared/components/PartnerAvatar';
import DatePicker from '../../../../shared/components/forms/DatePicker';

const Details = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
`;

const Actions = styled.div`
  display: grid;
  grid-column-gap: 15px;
  grid-auto-flow: column;
`;

const Container = styled.div`
  display: grid;
  grid-gap: 30px;
  padding: 1.25rem 1.5rem;

  ${Details} {
    justify-self: flex-start;
  }

  ${Actions} {
    justify-self: flex-end;
  }
`;

export default class EditTimesheetEntryModal extends React.Component {
  render() {
    const { entry, onClose, onUpdate, ...props } = this.props;

    return (
      <Modal onClose={onClose} {...props}>
        <Modal.Header>Edit Timesheet Entry</Modal.Header>
        <Formik
          enableReinitialize
          initialValues={{
            clock_in_time: entry ? moment(entry.clock_in_time).toDate() : undefined,
            clock_out_time: entry ? moment(entry.clock_out_time).toDate() : undefined,
            notes: entry ? entry.notes : undefined,
          }}
          validationSchema={Yup.object().shape({
            // notes: Yup.string().required('This field is required'),
          })}
          onSubmit={(values, actions) => {
            actions.setSubmitting(true);
            timesheetEntryApi.update(entry.id, values).then(() => {
              actions.setSubmitting(false);
              onUpdate();
              onClose();
            });
          }}
          render={({ values, setFieldValue }) => (
            <Form>
              <Container>
                <PartnerAvatar partner={entry.partner} />
                <Details>
                  <FormGroup>
                    <FormGroup.Label>Clock In Time</FormGroup.Label>
                    <DatePicker
                      showTimeSelect
                      placeholderText="Select Date"
                      selected={values.clock_in_time}
                      dropdownMode="select"
                      timeFormat="HH:mm"
                      dateFormat="dd/MM/yyyy HH:mm"
                      onChange={value => {
                        setFieldValue('clock_in_time', value);
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormGroup.Label>Clock Out Time</FormGroup.Label>
                    <DatePicker
                      showTimeSelect
                      placeholderText="Select Date"
                      selected={values.clock_out_time}
                      dropdownMode="select"
                      timeFormat="HH:mm"
                      dateFormat="dd/MM/yyyy HH:mm"
                      onChange={value => {
                        setFieldValue('clock_out_time', value);
                      }}
                    />
                  </FormGroup>
                </Details>
                <FormGroup>
                  <FormGroup.Label>Notes</FormGroup.Label>
                  <Field
                    name="notes"
                    component="textarea"
                    placeholder="Add more details about this dispute..."
                    rows={5}
                    col={10}
                  />
                  <FormGroup.Error>
                    <ErrorMessage name="notes" />
                  </FormGroup.Error>
                </FormGroup>
                <Actions>
                  <Button variation="neutral" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button type="submit" variation="primary">
                    Save
                  </Button>
                </Actions>
              </Container>
            </Form>
          )}
        />
      </Modal>
    );
  }
}
