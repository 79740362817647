import React from 'react';
import { Row, Col, Card, Typography } from 'antd';

const { Text } = Typography;

const DetailCard = ({ headerTitle, contentList, inline, loading = false }) => {
  return (
    <Card
      loading={loading}
      bordered={false}
      style={{ backgroundColor: 'inherit', borderTop: '1px solid #00000017' }}
      bodyStyle={{ padding: 0 }}
    >
      <Row style={{ margin: '8px 0 16px 0' }}>
        <Text strong type="secondary" style={{ fontSize: '12px' }}>
          {headerTitle.toUpperCase()}
        </Text>
      </Row>

      {/* Vertical list of details */}
      {contentList.map(({ title, value }, index) => {
        return (
          <Row type="flex" justify="space-between" key={index} style={{ marginBottom: '16px' }}>
            <Col span={inline ? undefined : 24}>
              <Text strong>{title}</Text>
            </Col>
            <Col>
              <Text type="secondary">{value}</Text>
            </Col>
          </Row>
        );
      })}
    </Card>
  );
};

export default DetailCard;
