export default {
  home: '/',
  login: '/login',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset/:uid/:token',
  // Partner routes
  partners: `/partners`,
  partnerDetail: '/partners/:id',
  editPartner: '/partners/:id/edit',
  createPartners: '/partners/create',
  // Client routes
  clients: '/clients',
  clientsDetail: '/clients/:id',
  createClient: '/clients/create',
  editClient: '/clients/:id/edit',
  // SR routes
  staffRequests: `/staff-requests`,
  createStaffRequest: '/staff-requests/create',
  editStaffRequest: '/staff-requests/:id/edit',
  staffRequestDetail: '/staff-requests/:id',
  staffRequestTimesheet: '/staff-requests/:id/timesheet',
  matches: '/staff-requests/:id/matches',
  // TS routes
  timesheets: '/timesheets',
  // Payment routes
  payments: '/payments',
  paymentReports: '/payments/reports',
  paymentReportDetail: '/payments/reports/:id',
  editPaymentReport: '/payments/reports/:id/edit',
  paymentItems: '/payments/items',
  // Billing routes
  billings: '/billings',
  billingReports: '/billings/reports',
  billingReportDetail: '/billings/reports/:id',
  billingItems: '/billings/items',
  // Setting routes
  settings: `/settings`,
  // Broadcast routes
  broadcast: '/broadcast',
  // Shifts
  shifts: '/shifts',
  // Workforce
  workforce: '/workforce',
};
