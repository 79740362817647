import React from 'react';
import { Modal } from 'semantic-ui-react';
import Button from '../../../../shared/components/Button';
import timesheetEntryApi from '../../../../services/timesheetEntryApi';

export default ({ entry, onClose, onUpdate, ...props }) => (
  <Modal {...props}>
    <Modal.Header>Void Timesheet Entry</Modal.Header>
    <Modal.Content>You are about to void this timesheet entry. Are you sure?</Modal.Content>
    <Modal.Actions>
      <Button
        onClick={() => {
          timesheetEntryApi.voidItem(entry.id).then(data => {
            onClose();
            onUpdate();
          });
        }}
        variation="danger"
      >
        Void
      </Button>
      <Button onClick={onClose} variation="neutral">
        Cancel
      </Button>
    </Modal.Actions>
  </Modal>
);
