import React from 'react';
import { Select, Input, Row, Col } from 'antd';
import debounce from 'lodash/debounce';
import partnerApi from '../../../services/partnerApi';
import clientApi from '../../../services/clientApi';
import staffRequestApi from '../../../services/staffRequestApi';
import getCountryCode from '../../../utilities/getCountryCode';

export default class TimesheetEntriesFilter extends React.Component {
  state = {
    results: [],
    loading: false,
    searchType: 'partner',
    clientNameSearch: undefined,
  };

  constructor(props) {
    super(props);
    this.fetchPartners = debounce(this.fetchPartners, 1000);
    this.fetchClients = debounce(this.fetchClients, 1000);
    this.fetchStaffRequests = debounce(this.fetchStaffRequests, 1000);
  }

  handleSearch = value => {
    const { searchType } = this.state;

    this.setState({ loading: true });
    if (searchType === 'partner') {
      this.fetchPartners(value);
    } else if (searchType === 'client') {
      this.fetchClients(value);
    } else {
      this.fetchStaffRequests(value);
    }
  };

  handleChange = value => {
    this.props.onChange({
      [this.state.searchType]: value,
    });
  };

  handleSearchTypeChange = value => {
    this.setState({
      results: [],
      loading: false,
      searchType: value,
      clientNameSearch: undefined,
    });
    this.props.onChange({});
  };

  fetchPartners = value => {
    partnerApi
      .fetchPartners({
        page: 1,
        search: value,
        country: getCountryCode(),
      })
      .then(data => {
        this.setState({
          results: data.results,
          loading: false,
        });
      });
  };

  fetchClients = value => {
    clientApi
      .fetchClients({
        search: value,
        country: getCountryCode(),
      })
      .then(data => {
        this.setState({
          results: data.results,
          loading: false,
        });
      });
  };

  fetchStaffRequests = value => {
    staffRequestApi
      .fetchStaffRequests({
        page: 1,
        country: getCountryCode(),
        search: value,
      })
      .then(data => {
        this.setState({
          results: data.results,
          loading: false,
        });
      });
  };

  render() {
    const { values } = this.props;
    const { results, loading, searchType, clientNameSearch } = this.state;
    const value = values[searchType];

    const searchOptions = [
      {
        value: 'partner',
        label: 'Partner',
      },
      {
        value: 'client',
        label: 'Client',
      },
      {
        value: 'clientName',
        label: 'Client Name',
      },
      {
        value: 'staffRequest',
        label: 'SR ID',
      },
    ];
    return (
      <Row style={{ width: 400 }} type="flex" justify="end">
        <Col span={8}>
          <Select
            style={{ width: 130 }}
            defaultValue={searchType}
            onChange={this.handleSearchTypeChange}
            value={searchType}
          >
            {searchOptions.map(option => (
              <Select.Option key={option.value}>{option.label}</Select.Option>
            ))}
          </Select>
        </Col>
        <Col span={16} align="right">
          {searchType === 'clientName' ? (
            <Input.Search
              placeholder="Search by Client Name"
              onChange={({ target: { value: search } }) => this.setState({ clientNameSearch: search })}
              onSearch={searchValue => {
                this.handleChange(searchValue);
              }}
              value={clientNameSearch}
            />
          ) : (
            <Select
              showSearch
              allowClear
              showArrow={false}
              style={{ width: '100%' }}
              placeholder="Search"
              loading={loading}
              filterOption={false}
              onSearch={this.handleSearch}
              onChange={this.handleChange}
              value={value}
            >
              {searchType === 'partner' &&
                results.map(result => (
                  <Select.Option
                    key={result.id}
                  >{`${result.first_name} ${result.last_name} (${result.id})`}</Select.Option>
                ))}
              {searchType === 'client' &&
                results.map(client => <Select.Option key={client.id}>{`${client.name} (${client.id})`}</Select.Option>)}
              {searchType === 'staffRequest' &&
                results.map(staffRequest => (
                  <Select.Option key={staffRequest.id}>{`${staffRequest.id} ${staffRequest.title}`}</Select.Option>
                ))}
            </Select>
          )}
        </Col>
      </Row>
    );
  }
}
