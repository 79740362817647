import React from 'react';
import { Radio } from 'semantic-ui-react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colors } from '../../../styles/colors';
import { fontO14r } from '../../../styles/fonts';

const Tab = styled.div`
  background: ${props => (props.active ? colors.blue : colors.white)};
  color: ${props => (props.active ? colors.white : colors.black)};
  ${fontO14r};
  text-align: center;
  padding: 10px 30px;
  border: 1px solid ${colors.grey};
  cursor: pointer;
`;

const TabContainer = styled.div`
  display: flex;

  ${Tab} {
    flex-grow: 1;

    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
`;

const CustomRadio = styled(Radio)``;

const Container = styled.div`
  display: flex;
  align-items: center;

  ${CustomRadio} {
    margin-right: 24px;
  }
`;

export default class RadioGroup extends React.Component {
  static propTypes = {
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  handleChange = (e, { value }) => {
    this.props.onChange(value);
  };

  render() {
    const { tabs, options, value, className } = this.props;

    if (tabs) {
      return (
        <TabContainer className={className}>
          {options.map(option => (
            <Tab
              key={option.value}
              active={value === option.value}
              onClick={() => {
                console.log(option.value);
                this.handleChange(null, { value: option.value });
              }}
            >
              {option.text}
            </Tab>
          ))}
        </TabContainer>
      );
    }

    return (
      <Container className={className}>
        {options.map(option => (
          <CustomRadio
            key={option.value}
            value={option.value}
            label={option.text}
            checked={value === option.value}
            onChange={this.handleChange}
          />
        ))}
      </Container>
    );
  }
}
