import React from 'react';
import { Typography } from 'antd';

import EmptyCard from './EmptyCard';
import NoSearchLogo from '../../assets/images/no_search_result.png';

const { Text } = Typography;
const EmptySearchResult = () => {
  return (
    <EmptyCard
      imgSrc={NoSearchLogo}
      title={<Text type="secondary">Search returned no results</Text>}
      description="Try changing your search criteria"
    />
  );
};

export default EmptySearchResult;
