import React, { Component } from 'react';
import { Row, DatePicker, Modal, Typography, Select, Input, Alert, Form, message, Switch, Icon } from 'antd';
import Text from 'antd/lib/typography/Text';
import CountrySelect from '../../../shared/components/CountrySelect';
import paymentApi from '../../../../services/paymentApi';
import { getStartOfNextDay } from '../../../../utilities/dateUtils';

const { Option } = Select;
const InputGroup = Input.Group;

class GenerateReportModal extends Component {
  state = {
    isLoading: false,
    exclude: false,
    successCount: null,
    failedCount: null,
    showForm: true,
    showGenerationConfirmation: false,
  };

  handleSubmit = async () => {
    if (!this.state.showForm) {
      this.handleClose();
    } else {
      this.props.form.validateFields(async (err, values) => {
        if (!err) {
          this.setState({ isLoading: true });
          const clock_in_before = getStartOfNextDay(values.clock_in_before);

          let exclude_staff_request;
          let include_staff_request;

          if (values.exclude) exclude_staff_request = values.staffRequests;
          else include_staff_request = values.staffRequests;

          const filterParams = {
            country: values.country,
            clock_in_before,
            exclude_staff_request,
            include_staff_request,
            partners: values.partners,
            include_dco: values.include_dco === undefined || values.include_dco,
          };
          await this.generateReport(filterParams);
          this.props.onChange();
        }
      });
    }
  };

  generateReport = async filterParams => {
    try {
      const response = await paymentApi.generate(filterParams);
      if (response.send_email) {
        this.setState({ showGenerationConfirmation: true, showForm: false });
      } else {
        this.setState({ successCount: response.success_count, failedCount: response.failed_count, showForm: false });
      }
    } catch (error) {
      message.error(`Payment generation failed: ${error}`);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  handleClose = () => {
    this.props.onCancel();
    this.setState({ successCount: null, failedCount: null, showForm: true, isLoading: false });
    this.props.form.resetFields();
  };

  render() {
    const { isLoading, showForm, showGenerationConfirmation } = this.state;
    const { visible } = this.props;
    const { getFieldDecorator, setFieldsValue } = this.props.form;
    return (
      <Modal
        title={<Typography.Title level={4}>Generate payment reports</Typography.Title>}
        visible={visible}
        onCancel={this.handleClose}
        onOk={this.handleSubmit}
        width={500}
        okType="v2-primary"
        okText={showForm ? 'Next' : 'Done'}
        confirmLoading={isLoading}
      >
        {this.state.successCount != null && (
          <Alert
            message="Payment reports generated"
            description={<>{this.state.successCount} payment reports ready for viewing</>}
            type="success"
            showIcon
          />
        )}
        {this.state.failedCount > 0 && (
          <Alert
            message="There were errors in generating reports"
            description={<>{this.state.failedCount} payment reports generated with error</>}
            type="error"
            showIcon
          />
        )}
        {showGenerationConfirmation && (
          <Alert
            message="Generating payment reports..."
            description={<>Your payment reports are being generated. We will email you when they are done.</>}
            type="info"
            showIcon
          />
        )}
        {showForm && (
          <div>
            <Row style={{ marginBottom: '24px' }}>
              <Typography.Text>
                Generate payment reports for specific SRs before a specified date. All new unpaid items will be added to
                reports.
              </Typography.Text>
            </Row>

            <Form onSubmit={this.handleSubmit} layout="vertical">
              <Form.Item label="Country">
                {getFieldDecorator('country', {
                  rules: [{ required: true, message: 'Please choose country' }],
                })(<CountrySelect style={{ width: '100%' }} />)}
              </Form.Item>
              <Form.Item label="Clock in time until">
                {getFieldDecorator('clock_in_before', {
                  rules: [{ required: true, message: 'Please choose clock in end date' }],
                })(<DatePicker style={{ width: '100%' }} placeholder="Select end date" format="DD/MM/YYYY" />)}
              </Form.Item>
              <Row style={{ marginBottom: '4px' }}>Staff request ID</Row>
              <InputGroup compact>
                <Form.Item>
                  {getFieldDecorator('exclude', { initialValue: this.state.exclude })(
                    <Select style={{ width: 100 }}>
                      <Option value={false}>Include</Option>
                      <Option value={true}>Exclude</Option>
                    </Select>,
                  )}
                </Form.Item>
                <Form.Item>
                  {getFieldDecorator('staffRequests')(
                    <Select
                      mode="tags"
                      style={{ width: 350 }}
                      tokenSeparators={[',', ' ']}
                      placeholder="SR IDs split by comma or space"
                    />,
                  )}
                </Form.Item>
              </InputGroup>
              <Form.Item label="Worker ID">
                {getFieldDecorator('partners')(
                  <Select
                    mode="tags"
                    style={{ width: '100%' }}
                    tokenSeparators={[',', ' ']}
                    placeholder="Worker IDs split by comma or space"
                  />,
                )}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('include_dco')(
                  <>
                    <Switch
                      defaultChecked
                      checkedChildren={<Icon type="check" />}
                      unCheckedChildren={<Icon type="close" />}
                      onChange={checked => setFieldsValue({ include_dco: checked })}
                    />
                    <Text style={{ marginLeft: 8 }}>Include DCO deduction</Text>
                  </>,
                )}
              </Form.Item>
            </Form>
          </div>
        )}
      </Modal>
    );
  }
}

export default Form.create({ name: 'generate_report_form' })(GenerateReportModal);
