import styled from 'styled-components';
import React from 'react';
import { Flag } from 'semantic-ui-react';

const CustomFlag = styled(Flag)`
  &&& {
    vertical-align: middle;
  }
`;

const Country = styled.div``;

export default ({ country, ...props }) => {
  const countryCode = country ? country.code.toLowerCase() : '';
  const countryName = country ? country.name : '';
  return (
    <Country {...props}>
      <CustomFlag name={countryCode} />
      <span>{countryName}</span>
    </Country>
  );
};
