import base from './base';
import { endpoints } from '../config';

const fetchReligions = async params => {
  const response = await base.api.get(`${endpoints.religions}/`, {
    params,
  });
  return response;
};

export default {
  fetchReligions,
};
