import React, { Component } from 'react';
import { Select } from 'antd';
import locationApi from '../../../services/locationApi';

class CountrySelect extends Component {
  state = { countries: [] };

  componentDidMount() {
    locationApi.fetchCountries().then(countries =>
      this.setState({
        countries,
      }),
    );
  }

  render() {
    return (
      <Select {...this.props} placeholder="Select country">
        {this.state.countries &&
          this.state.countries.map(option => (
            <Select.Option key={option.id} value={option.id}>
              {option.name}
            </Select.Option>
          ))}
      </Select>
    );
  }
}

export default CountrySelect;
