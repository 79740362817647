// Belongs to helpster-components
import React from 'react';
import { Row, Typography, Popover, Button } from 'antd';

const { Text, Paragraph } = Typography;

const PopoverCopyText = ({ title, text }) => {
  const content = (
    <>
      <Row>
        <Text strong>{title}</Text>
      </Row>
      <Paragraph
        copyable
        ellipsis={{ row: 1 }}
        style={{
          marginBottom: 0,
          padding: '8px',
          border: '1px solid lightgray',
          borderRadius: '8px',
          marginTop: '4px',
        }}
      >
        {text}
      </Paragraph>
    </>
  );
  return (
    <Popover destroyTooltipOnHide content={content} trigger="click" placement="bottomLeft">
      <Button icon="share-alt" style={{ marginRight: '8px' }}>
        Share job
      </Button>
    </Popover>
  );
};

export default PopoverCopyText;
