import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { Provider } from 'react-redux';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

import getEnvironment from './utilities/getEnvironment';
import * as serviceWorker from './serviceWorker';
import App from './App';
import './i18n';
import store from './store';
import './v2/styles/index.css';
import './v2/styles/override.css';
import { environment } from './constants';
import VERSIONNAME from './versionname';

if ([environment.STAGING, environment.PRODUCTION].includes(getEnvironment())) {
  Sentry.init({
    dsn: 'https://da8a72cf800c4789bc9cd24bc58ec5d8@sentry.io/1381079',
    environment: getEnvironment(),
  });

  datadogRum.init({
    applicationId: '7bc7bafa-bebc-45eb-8145-819b2a20b1a6',
    clientToken: 'pub4b34093475d28551c0d524c9b6c0ba71',
    site: 'datadoghq.com',
    service: 'admin-dashboard',
    // Specify a version number to identify the deployed version of your application in Datadog
    version: VERSIONNAME,
    env: getEnvironment(),
    sampleRate: 10,
    trackInteractions: true,
  });

  datadogLogs.init({
    clientToken: 'pub4b34093475d28551c0d524c9b6c0ba71',
    site: 'datadoghq.com',
    forwardErrorsToLogs: true,
    sampleRate: 10,
    service: 'admin-dashboard',
    env: getEnvironment(),
    version: VERSIONNAME,
  });
}

// FIXME: god damn-it, why can't we use .env ?!?!?!
if (getEnvironment() === environment.STAGING) {
  posthog.init('phc_4lh9U63eQZwSM3pZDUJoHeRnlHjRKFn0OKawZp4M3E', {
    api_host: 'https://us.i.posthog.com',
    person_profiles: 'identified_only',
  });
}
// FIXME: god damn-it, why can't we use .env ?!?!?!
if (getEnvironment() === environment.PRODUCTION) {
  posthog.init('phc_tijvOJkes971aF7LnykD77cTgkafg6lV8D5bqGjhaeG', {
    api_host: 'https://us.i.posthog.com',
    person_profiles: 'identified_only',
  });
}

const queryClient = new QueryClient();

ReactDOM.render(
  <>
    <PostHogProvider client={posthog}>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <App />
        </QueryClientProvider>
      </Provider>
    </PostHogProvider>
  </>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
