import React, { Component } from 'react';
import styled from 'styled-components';
import { Field, Form, Formik } from 'formik';
import { Button, Header, Input, Message } from 'semantic-ui-react';
import authenticationService from '../../services/authApi';
import { withTranslation } from 'react-i18next';

const { Subheader } = Header;

const Container = styled.div`
  width: 400px;
  maxwidth: 400px;
  height: 420px;
  maxheight: 420px;
  padding: 60px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CustomInput = styled(Input)`
  &&& {
    display: block;
    input {
      width: 100%;
    }
  }
`;

const CustomForm = styled(Form)`
  min-width: 250px;
  ${CustomInput} {
    margin-bottom: 15px;
    width: 100%;
  }
`;

class ResetPasswordPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
    };
  }

  onSubmit = async (values, formikActions) => {
    const { setSubmitting, setErrors } = formikActions;
    const { uid, token } = this.props.match.params;
    const { password, confirmPassword } = values;
    setSubmitting(true);
    try {
      await authenticationService.resetPassword(uid, token, password, confirmPassword);
      this.setState({ submitted: true });
    } catch (error) {
      const errors = Object.values(error.response.data).reduce((acc, curr) => acc.concat(curr.join(' ')), '');
      setErrors({ message: errors.length > 0 ? errors : this.props.t('resetPasswordError') });
    }
    setSubmitting(false);
  };

  render() {
    const { t } = this.props;
    const { submitted } = this.state;
    return (
      <Container>
        {submitted ? (
          <CustomForm>
            <Header size="large">
              {t('resetPasswordSuccess')}
              <Subheader>{t('resetPasswordSuccessDetail')}</Subheader>
            </Header>
          </CustomForm>
        ) : (
          <Formik
            onSubmit={(values, formikActions) => this.onSubmit(values, formikActions)}
            initialValues={{ password: '', confirmPassword: '' }}
            render={props => {
              const { errors, isSubmitting } = props;
              return (
                <CustomForm>
                  <Header size="large">
                    {t('resetPassword')}
                    <Subheader>{t('resetPasswordTitle')}</Subheader>
                  </Header>
                  <Field
                    name="password"
                    render={({ field }) => <CustomInput {...field} type="password" placeholder={t('newPassword')} />}
                  />
                  <Field
                    name="confirmPassword"
                    render={({ field }) => (
                      <CustomInput {...field} type="password" placeholder={t('confirmPassword')} />
                    )}
                  />
                  {errors && errors.message && <Message negative>{errors.message}</Message>}
                  <Button primary type="submit" loading={isSubmitting}>
                    {t('resetPassword')}
                  </Button>
                </CustomForm>
              );
            }}
          />
        )}
      </Container>
    );
  }
}

export default withTranslation()(ResetPasswordPage);
