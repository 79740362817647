import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Dropdown from './Dropdown';

export default class RemoteDropdown extends React.Component {
  static propTypes = {
    fetchOptions: PropTypes.func.isRequired,
    control: PropTypes.any,
  };

  constructor(props) {
    super(props);
    this.state = {
      search: null,
      options: [],
    };
  }

  componentDidMount() {
    this.fetchOptions();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.control !== this.props.control) {
      this.fetchOptions();
    }
  }

  fetchOptions = () => {
    const promise = this.props.fetchOptions(this.state.search);
    if (!promise) return;

    promise.then(options =>
      this.setState({
        options,
      }),
    );
  };

  handleSearchChange = _.debounce((e, { searchQuery }) => {
    this.setState(
      {
        search: searchQuery,
      },
      this.fetchOptions,
    );
  }, 250);

  render() {
    const { fetchOptions, ...props } = this.props;

    const { options } = this.state;

    return <Dropdown {...props} fluid options={options} onSearchChange={this.handleSearchChange} />;
  }
}
