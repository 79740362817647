import { startCase, toLower, upperCase, camelCase } from 'lodash';

const makeFriendly = string => {
  const lowerCaseString = string.replace('_', ' ').toLowerCase();
  return startCase(lowerCaseString);
};

export const humanizeText = string => {
  if (!string || string.length === 0) {
    return '';
  }
  return startCase(string.replace('_', ' '));
};

export const capitalizeWord = word => {
  if (!word || word.length === 0) {
    return '';
  }
  return word.charAt(0).toUpperCase() + word.slice(1);
};

const toTitleCase = string => {
  return startCase(toLower(string));
};

const toUpperCase = string => {
  return upperCase(string);
};

export const toCamelCase = string => {
  if (!string || string.length === 0) {
    return '';
  }
  return camelCase(string);
};

export default {
  makeFriendly,
  humanizeText,
  capitalizeWord,
  toTitleCase,
  toUpperCase,
  toCamelCase,
};
