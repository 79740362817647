import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PaymentItemPage from './PaymentItemPage';
import routes from '../../../routes';

const PaymentItems = () => {
  return (
    <Switch>
      <Route exact path={routes.paymentItems} component={PaymentItemPage} />
    </Switch>
  );
};

export default PaymentItems;
