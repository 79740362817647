import React from 'react';
import { Modal } from 'semantic-ui-react';
import { ErrorMessage, Form, Formik } from 'formik';
import styled from 'styled-components';
import * as numeral from 'numeral';
import * as Yup from 'yup';
import FormGroup from '../../../../shared/components/forms/FormGroup';
import DatePicker from '../../../../shared/components/forms/DatePicker';
import Field from '../../../../shared/components/forms/Field';
import Dropdown from '../../../../shared/components/forms/Dropdown';
import { paymentItemTypes } from '../../../../constants';
import { humanizeText } from '../../../../utilities/textUtils';
import NumberFormat from '../../../../shared/components/forms/NumberFormat';
import Button from '../../../../shared/components/Button';
import paymentApi from '../../../../services/paymentApi';

const Container = styled.div`
  display: grid;
  grid-row-gap: 15px;
`;

const Actions = styled.div`
  margin-top: 30px;
  display: grid;
  grid-column-gap: 15px;
  grid-auto-flow: column;
`;

const typeOptions = [
  paymentItemTypes.BASE,
  paymentItemTypes.OVERTIME,
  paymentItemTypes.BONUS,
  paymentItemTypes.INCENTIVE,
  paymentItemTypes.ADJUSTMENT,
  paymentItemTypes.EXPENSE,
  paymentItemTypes.SEVERANCE_PAY,
  paymentItemTypes.DEPOSIT,
  paymentItemTypes.TAX_EXCLUDE,
].map(type => ({
  key: type,
  value: type,
  text: humanizeText(type),
}));

export default ({ paymentItem, paymentReport, onClose, onUpdate, ...props }) => {
  let initialValues = {
    date: new Date(),
    description: '',
    type: undefined,
    quantity: 1,
    unit_cost: 0,
  };

  if (paymentItem) {
    initialValues = {
      ...initialValues,
      ...paymentItem,
      partner: paymentItem.partner.id,
    };
  }

  if (paymentReport) {
    initialValues = {
      ...initialValues,
      payment_report: paymentReport.id,
      partner: paymentReport.partner.id,
    };
  }

  return (
    <Modal size="tiny" {...props}>
      <Modal.Header>
        {paymentItem && 'Edit Payment Item'}
        {paymentReport && 'Add Payment Item to Report'}
      </Modal.Header>
      <Modal.Content>
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object().shape({
            description: Yup.string().required('Description is required'),
            unit_cost: Yup.number().required('Please enter a unit cost'),
            type: Yup.string().required('Please select a type'),
          })}
          onSubmit={(values, actions) => {
            actions.setSubmitting(true);

            if (paymentItem) {
              paymentApi.updatePaymentItem(paymentItem.id, values).then(data => {
                actions.setSubmitting(false);
                onUpdate();
                onClose();
              });
              return;
            }

            if (paymentReport) {
              paymentApi.createPaymentItem(values).then(data => {
                actions.setSubmitting(false);
                onUpdate();
                onClose();
              });
            }
          }}
          render={({ values, setFieldValue, isSubmitting }) => (
            <Form>
              <Container>
                {paymentItem && (
                  <FormGroup>
                    <FormGroup.Label>Payment Item ID:</FormGroup.Label>
                    {paymentItem.id}
                  </FormGroup>
                )}
                {paymentItem && paymentItem.timesheet_entry && (
                  <FormGroup>
                    <FormGroup.Label>Timesheet Entry</FormGroup.Label>
                    {paymentItem.timesheet_entry.id}
                  </FormGroup>
                )}
                <FormGroup>
                  <FormGroup.Label>Date</FormGroup.Label>
                  <DatePicker
                    selected={new Date(values.date)}
                    dateFormat="dd/MM/yyyy"
                    onChange={date => setFieldValue('date', date)}
                  />
                </FormGroup>
                <FormGroup>
                  <FormGroup.Label>Description</FormGroup.Label>
                  <Field name="description" component="textarea" placeholder="Description" />
                  <FormGroup.Error>
                    <ErrorMessage name="description" />
                  </FormGroup.Error>
                </FormGroup>
                <FormGroup>
                  <FormGroup.Label>Type</FormGroup.Label>
                  <Dropdown
                    placeholder="Select Type"
                    options={typeOptions}
                    value={values.type}
                    onChange={(e, { value }) => setFieldValue('type', value)}
                  />
                  <FormGroup.Error>
                    <ErrorMessage name="type" />
                  </FormGroup.Error>
                </FormGroup>
                <FormGroup>
                  <FormGroup.Label>Unit Cost</FormGroup.Label>
                  <NumberFormat
                    thousandSeparator
                    value={values.unit_cost}
                    decimalScale={2}
                    fixedDecimalScale
                    onValueChange={({ value }) => {
                      setFieldValue('unit_cost', value);
                    }}
                  />
                </FormGroup>
                <FormGroup>
                  <FormGroup.Label>Quantity</FormGroup.Label>
                  <Field name="quantity" type="number" min={1} max={100} step={1} />
                </FormGroup>
                <FormGroup>
                  <FormGroup.Label>Amount:</FormGroup.Label>
                  {numeral(numeral(values.unit_cost).value() * values.quantity).format('0,0.00')}
                </FormGroup>
              </Container>
              <Actions>
                <Button type="button" onClick={onClose}>
                  Cancel
                </Button>
                <Button loading={isSubmitting} type="submit" variation="primary">
                  Save
                </Button>
              </Actions>
            </Form>
          )}
        />
      </Modal.Content>
    </Modal>
  );
};
