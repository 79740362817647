import base from './base';
import { endpoints, readonlyEndpoints } from '../config';

const fetchEmployments = async params => {
  const response = await base.api.get(`${endpoints.employments}/`, { params });
  return response;
};

const cancelEmployment = async (employmentId, reason, notes) => {
  const response = await base.api.post(`${endpoints.employments}/${employmentId}/cancel/`, {
    cancellation_reason: reason,
    notes,
  });
  return response;
};

const create = async params => {
  const response = await base.api.post(`${endpoints.employments}/`, params);
  return response;
};

const update = async (employmentId, params) => {
  const response = await base.api.patch(`${endpoints.employments}/${employmentId}/`, params);
  return response;
};

const getStatistics = async params => {
  const response = await base.api.get(`${readonlyEndpoints.employments}/statistics/`, { params });
  return response;
};

export default {
  fetchEmployments,
  cancelEmployment,
  create,
  update,
  getStatistics,
};
