import React, { Component } from 'react';
import { Icon, Modal } from 'semantic-ui-react';
import { ErrorMessage, FieldArray, Form, Formik } from 'formik';
import styled from 'styled-components';

import * as Yup from 'yup';
import dateFns, { addDays } from 'date-fns';
import RemoteDropdown from '../../../../shared/components/forms/RemoteDropdown';
import Button from '../../../../shared/components/Button';
import DatePicker from '../../../../shared/components/forms/DatePicker';
import RemoveButton from '../../../../shared/components/forms/RemoveButton';
import AddButton from '../../../../shared/components/forms/AddButton';
import employmentApi from '../../../../services/employmentApi';
import timesheetEntryApi from '../../../../services/timesheetEntryApi.js';

import FormGroup from '../../../../shared/components/forms/FormGroup';
import { colors } from '../../../../styles/colors';

import 'rc-time-picker/assets/index.css';
import 'react-datepicker/dist/react-datepicker.min.css';

const CopyButton = styled(Icon)`
  color: ${colors.grey};
  cursor: pointer;
`;

const EntryActions = styled.div`
  display: flex;
  align-items: center;
`;

const Duration = styled.div``;

const Entry = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr 2fr 1fr 30px;
  grid-auto-flow: column;
  grid-gap: 30px;
  align-items: flex-start;

  ${EntryActions} {
    padding-top: 25px;
  }

  ${Duration} {
    padding-top: 15px;
  }
`;

const Actions = styled.div`
  display: grid;
  grid-column-gap: 15px;
  grid-auto-flow: column;
`;

const Container = styled.div`
  display: grid;
  grid-gap: 30px;
  padding: 1.25rem 1.5rem;
  justify-items: flex-start;

  ${Entry} {
    justify-self: flex-start;
  }

  ${Actions} {
    justify-self: flex-end;
  }
`;

const defaultTimesheetEntry = {
  partner: undefined,
  clock_in_time: new Date(),
  clock_out_time: undefined,
};

class AddTimesheetEntryModal extends Component {
  state = {
    employments: [],
    selectedEmployment: undefined,
  };

  fetchPartners = async search => {
    const { staffRequestId } = this.props;
    // Retrieve partners that have ACTIVE, CANCELLED or ENDED Employments for the SR
    const response = await employmentApi.fetchEmployments({
      search,
      staff_request: staffRequestId,
    });

    this.setState({ employments: response.results });

    return response.results.map(employment => ({
      key: employment.partner.id,
      text: `${employment.partner.first_name} ${employment.partner.last_name}`,
      value: employment.partner.id,
    }));
  };

  render() {
    const { onUpdate, onClose, ...props } = this.props;

    return (
      <Modal onClose={onClose} {...props}>
        <Modal.Header>Add Timesheet Entry</Modal.Header>
        <Formik
          initialValues={{ timesheet_entries: [defaultTimesheetEntry] }}
          validationSchema={Yup.object().shape({
            timesheet_entries: Yup.array().of(
              Yup.object().shape({
                partner: Yup.number()
                  .typeError('Not a valid Partner')
                  .required('Partner is required'),
                clock_in_time: Yup.date().required('Clock in time is required'),
                clock_out_time: Yup.date()
                  .typeError('Clock out time is required')
                  .when('clock_in_time', (clockInTime, schema) => {
                    return clockInTime ? schema.min(clockInTime, 'Must be after clock in time') : schema;
                  }),
              }),
            ),
          })}
          onSubmit={(values, actions) => {
            actions.setSubmitting(true);
            const { positionId, locationId, staffRequestId } = this.props;
            const { selectedEmployment } = this.state;
            const promises = values.timesheet_entries.map(timesheetEntry => {
              return timesheetEntryApi.create({
                ...timesheetEntry,
                staff_request: staffRequestId,
                location: locationId,
                position: positionId,
                employment: selectedEmployment?.id,
              });
            });
            Promise.all(promises).then(() => {
              actions.setSubmitting(false);
              onUpdate();
              onClose();
            });
          }}
          render={({ values, errors, isSubmitting, setFieldValue }) => (
            <Form>
              <Container>
                <FieldArray
                  name="timesheet_entries"
                  render={arrayHelpers => (
                    <>
                      {values.timesheet_entries &&
                        values.timesheet_entries.map((timesheetEntry, index) => (
                          <Entry key={index}>
                            <FormGroup>
                              <FormGroup.Label>Partner</FormGroup.Label>
                              <RemoteDropdown
                                name="partner"
                                search
                                fetchOptions={this.fetchPartners}
                                control={this.props.staffRequestId}
                                value={timesheetEntry.partner}
                                onChange={(e, { value }) => {
                                  const { employments } = this.state;
                                  const selectedEmployment = employments.find(
                                    employment => employment.partner.id === value,
                                  );
                                  this.setState({ selectedEmployment });
                                  setFieldValue(`timesheet_entries.${index}.partner`, value);
                                }}
                                placeholder="Search Partner..."
                              />
                              <FormGroup.Error>
                                <ErrorMessage name={`timesheet_entries.${index}.partner`} />
                              </FormGroup.Error>
                            </FormGroup>
                            <FormGroup>
                              <FormGroup.Label>Clock In Time</FormGroup.Label>
                              <DatePicker
                                showTimeSelect
                                placeholderText="Select clock in time"
                                selected={timesheetEntry.clock_in_time}
                                maxDate={new Date()}
                                dropdownMode="select"
                                timeFormat="HH:mm"
                                dateFormat="dd/MM/yyyy HH:mm"
                                onChange={value => {
                                  setFieldValue(`timesheet_entries.${index}.clock_in_time`, value);
                                }}
                              />
                              <FormGroup.Error>
                                {console.log('Errors: ', errors)}
                                <ErrorMessage
                                  name={`timesheet_entries.${index}.clock_in_time`}
                                  render={msg => msg.toString()}
                                />
                              </FormGroup.Error>
                            </FormGroup>
                            <FormGroup>
                              <FormGroup.Label>Clock Out Time</FormGroup.Label>
                              <DatePicker
                                showTimeSelect
                                placeholderText="Select clock out time"
                                selected={timesheetEntry.clock_out_time}
                                minDate={timesheetEntry.clock_in_time}
                                maxDate={addDays(timesheetEntry.clock_in_time, 1)}
                                isClearable
                                dropdownMode="select"
                                timeFormat="HH:mm"
                                dateFormat="dd/MM/yyyy HH:mm"
                                onChange={value => {
                                  setFieldValue(`timesheet_entries.${index}.clock_out_time`, value);
                                }}
                              />
                              <FormGroup.Error>
                                <ErrorMessage
                                  name={`timesheet_entries.${index}.clock_out_time`}
                                  render={msg => msg.toString()}
                                />
                              </FormGroup.Error>
                            </FormGroup>
                            <FormGroup>
                              <FormGroup.Label>Duration</FormGroup.Label>
                              <Duration>
                                {timesheetEntry.clock_in_time && timesheetEntry.clock_out_time
                                  ? dateFns.differenceInHours(
                                      timesheetEntry.clock_out_time,
                                      timesheetEntry.clock_in_time,
                                    )
                                  : 0}{' '}
                                hour(s)
                              </Duration>
                            </FormGroup>
                            <EntryActions>
                              <CopyButton
                                name="copy outline"
                                onClick={() => {
                                  console.log(values.timesheet_entries[index]);
                                  arrayHelpers.push(values.timesheet_entries[index]);
                                }}
                              />
                              <RemoveButton
                                onClick={() => {
                                  arrayHelpers.remove(index);
                                }}
                              />
                            </EntryActions>
                          </Entry>
                        ))}
                      <AddButton
                        onClick={() => {
                          arrayHelpers.push(defaultTimesheetEntry);
                        }}
                      >
                        Add item
                      </AddButton>
                    </>
                  )}
                />
                <Actions>
                  <Button variation="neutral" onClick={onClose}>
                    Cancel
                  </Button>
                  <Button loading={isSubmitting} type="submit" variation="primary">
                    Save
                  </Button>
                </Actions>
              </Container>
            </Form>
          )}
        />
      </Modal>
    );
  }
}

export default AddTimesheetEntryModal;
