import { Badge } from 'antd';
import React from 'react';
import { clientTypes } from '../../constants';

function getTypeStyle(type) {
  switch (type) {
    case clientTypes.WMP:
      return 'default';
    case clientTypes.MANAGED_SERVICE:
      return 'orange';
    case clientTypes.BPO:
      return 'purple';
    default:
      return 'default';
  }
}

const ClientTypeBadge = ({ type, text }) => {
  return <Badge status={getTypeStyle(type)} text={text} />;
};

export default ClientTypeBadge;
