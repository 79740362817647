/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Col, Layout, Row } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

import { find, isEmpty } from 'lodash';
import getCountryCode from '../../../utilities/getCountryCode';
import * as globalActions from '../../../redux/global';
import * as userActions from '../../../redux/user';
import { countryOptions } from '../../../constants';

import routes from '../../../routes';
// TODO: Remove below imports once we are permanently switched to new V2
import LoadingSpinner from '../components/LoadingSpinner';
import TopNavDropdown from './TopNavDropdown';
import TopNavMenu from './TopNavMenu';
import ErrorPage from './ErrorPage';
import { firebaseApp } from '../../../firebase';

const { Header, Content } = Layout;
// High-level styles for easy customizability
const DEFAULT_MAX_WIDTH = 1168;
const contentBackgroundColor = 'white';

class AuthLayout extends React.Component {
  state = {
    error: null,
    errorStack: null,
  };

  componentDidMount() {
    const { user, global } = this.props;
    if (!localStorage.getItem('token')) {
      this.onLogout();
    }

    // Ref: https://firebase.google.com/docs/auth/web/manage-users#get_the_currently_signed-in_user
    const auth = getAuth(firebaseApp);
    onAuthStateChanged(auth, async state => {
      if (user) {
        const newToken = await state.getIdToken();
        localStorage.setItem('token', newToken);
      } else {
        // NOTE: user logged out
        this.onLogout();
      }
    });

    if (!user.loaded) {
      this.props.getUserState();
    }
    if (isEmpty(global.positions)) {
      this.props.getPositions();
    }
    datadogRum.setUser({
      email: user.username,
    });
  }

  onLogout = () => {
    this.props.logoutUser();
    this.props.history.push(routes.login);
  };

  onCountryChange = key => {
    const prevCountry = getCountryCode();
    if (prevCountry === key || (!prevCountry && key === 'all')) {
      return;
    }
    localStorage.setItem('country', key);
    window.location.reload();
  };

  clearError = () => {
    this.setState({ error: null, errorStack: null });
  };

  componentDidCatch(error, info) {
    this.setState({ error, errorStack: info.componentStack });
  }

  render() {
    const { user, maxWidth } = this.props;
    if (user.isLoading) return <LoadingSpinner />;
    if (!user.loaded)
      return (
        <ErrorPage
          title="Error! Try refreshing this page!"
          subTitle="Something went wrong while loading this page."
          errorStack={`Error:\n ${user.error}`}
        />
      );
    const selectedCountryCode = getCountryCode() || countryOptions.default;
    const selectedCountry = find(countryOptions.list, country => country.key === selectedCountryCode);

    return (
      <Layout style={{ backgroundColor: contentBackgroundColor, height: '100%' }}>
        <Header style={{ position: 'fixed', width: '100%', zIndex: 3 }}>
          <Row
            type="flex"
            justify="space-between"
            align="middle"
            style={{ maxWidth: (maxWidth || DEFAULT_MAX_WIDTH) + 180, margin: 'auto', color: 'white' }}
          >
            <Col>
              <TopNavMenu history={this.props.history} />
            </Col>
            <Col>
              <TopNavDropdown
                menuList={countryOptions.list.map(country => {
                  return {
                    name: `${country.emoji} ${country.text}`,
                    onClick: () => this.onCountryChange(country.key),
                  };
                })}
                text={`${selectedCountry.emoji} ${selectedCountry.text}`}
              />

              <TopNavDropdown menuList={[{ name: 'Logout', onClick: this.onLogout }]} text={user.username} />
            </Col>
          </Row>
        </Header>
        <Content
          style={{
            padding: '0 20px',
            width: '100%',
            margin: 'auto',
            maxWidth: (maxWidth || DEFAULT_MAX_WIDTH) + 100,
            marginTop: 128,
            minHeight: 'calc(100vh - 128px)',
          }}
        >
          {this.state.error ? (
            <ErrorPage
              title="Error"
              subTitle="Sorry! thiere is an error loading the page"
              errorStack={this.state.errorStack}
              onClick={this.clearError}
            />
          ) : (
            <>{this.props.children}</>
          )}
        </Content>
      </Layout>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    global: state.global,
  };
};

const mapDispatchToProps = dispatch => {
  const { logoutAction, fetchUserState } = userActions;
  const { fetchPositions, globalUpdateAction } = globalActions;
  return {
    logoutUser: () => {
      dispatch(logoutAction());
    },
    globalUpdate: payload => {
      dispatch(globalUpdateAction(payload));
    },
    getUserState: () => {
      dispatch(fetchUserState());
    },
    getPositions: () => {
      dispatch(fetchPositions());
    },
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthLayout));
