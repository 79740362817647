import { debounce } from 'lodash';
import React from 'react';
import partnerApi from '../../../services/partnerApi';
import FilterWithSingleSearchAndTags from './FilterWithSingleSearchAndTags';

// TODO should be merged with WorkFilterButton - pass props to determine functionality
export default class WorkerFilterTagButton extends React.Component {
  state = {
    loading: false,
    workerOptions: [],
  };

  constructor(props) {
    super(props);
    this.handleSearch = debounce(this.handleSearch, 500);
  }

  componentDidMount() {
    this.fetchWorkers();
  }

  fetchWorkers = async (search = undefined) => {
    this.setState({ loading: true, workerOptions: [] });
    const response = await partnerApi.fetchPartners({ search });

    this.setState({
      workerOptions: response.results.map(worker => ({
        key: worker.id,
        name: `#${worker.id} ${worker.first_name} ${worker.last_name}`,
      })),
      loading: false,
    });
  };

  handleSelectChange = workers => {
    const selectedWorkers = workers
      .map(worker => {
        const isWorkerKeyInt = Number.isInteger(Number(worker.key));
        if (isWorkerKeyInt) {
          return worker.key;
        }
        return undefined;
      })
      .filter(item => Number.isInteger(Number(item)));
    this.props.onSelectChange(selectedWorkers);
  };

  handleSearch = search => {
    this.fetchWorkers(search);
  };

  render() {
    const { loading, workerOptions } = this.state;
    const { selectedOptions } = this.props;

    return (
      <FilterWithSingleSearchAndTags
        title={`Worker ${selectedOptions && selectedOptions.length > 0 ? `(${selectedOptions.length})` : ''}`}
        options={workerOptions}
        onSelectChange={this.handleSelectChange}
        loading={loading}
        onSearch={this.handleSearch}
        placeholder="Search by list of IDs"
        optionLabelField="name"
        value={selectedOptions}
      />
    );
  }
}
