import React, { Component } from 'react';
import { Modal, Button, Typography, Icon } from 'antd';
import { withTranslation } from 'react-i18next';
import moment from 'moment';
import { DATE_FORMAT } from '../../../../constants';
import WeekSelect from './WeekSelect';
import { getWeekStartOptions } from '../../../../utilities/dateUtils';

const { Text } = Typography;

const NUM_WEEKS_BEFORE = 0;
const NUM_WEEKS_AFTER = 5;

class CopyAssignmentButtonModal extends Component {
  state = {
    showCopyShiftModal: false,
    selectedCopyWeekStart: this.props.weekdayDates[0],
  };

  handleClickCopyShift = () => {
    this.setState({ showCopyShiftModal: true });
  };

  handleCopyShift = () => {
    this.props.onCopy(this.state.selectedCopyWeekStart);
    this.handleCancelCopyShift();
  };

  handleShiftCopyWeekSelect = value => {
    this.setState({
      selectedCopyWeekStart: moment(value).tz(this.props.timezone),
    });
  };

  handleCancelCopyShift = () => {
    this.setState({ selectedCopyWeekStart: this.props.weekdayDates[0], showCopyShiftModal: false });
  };

  render() {
    const { showCopyShiftModal, selectedCopyWeekStart } = this.state;
    const { weekdayDates, disabled, timezone, t } = this.props;
    const currentWeekStart = moment()
      .tz(timezone)
      .startOf('isoWeek')
      .toISOString();
    const weekStartOptions = getWeekStartOptions(currentWeekStart, NUM_WEEKS_BEFORE, NUM_WEEKS_AFTER);
    return (
      <>
        <Button onClick={this.handleClickCopyShift} disabled={disabled}>
          {t('copy')}
        </Button>
        {showCopyShiftModal && (
          <Modal
            title={t('copyShifts')}
            visible={showCopyShiftModal}
            onOk={this.handleCopyShift}
            okButtonProps={{ disabled: !selectedCopyWeekStart }}
            okType="v2-primary"
            okText={t('copy')}
            cancelText={t('Cancel')}
            onCancel={this.handleCancelCopyShift}
            destroyOnClose
          >
            {/* TODO should not allow select history */}
            <div style={{ paddingBottom: 8 }}>
              {t('copyShiftsFrom', {
                weekStart: weekdayDates[0].format(DATE_FORMAT),
                weekEnd: weekdayDates[6].format(DATE_FORMAT),
              })}
            </div>
            <WeekSelect
              onChange={this.handleShiftCopyWeekSelect}
              selectedWeekStart={selectedCopyWeekStart}
              weekStartOptions={weekStartOptions}
            />
            <Text type="warning">
              <Icon type="exclamation-circle" style={{ paddingRight: 5 }} />
              <Text type="warning">{t('copyWarning')}</Text>
            </Text>
          </Modal>
        )}
      </>
    );
  }
}

export default withTranslation()(CopyAssignmentButtonModal);
