import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { SafetyCertificateFilled } from '@ant-design/icons';
import { Row, Col, Tabs, Button } from 'antd';
import { DetailCard } from 'helpster-components';

import routes from '../../../routes';
import partnerApi from '../../../services/partnerApi';

import noActivityImg from '../../assets/images/no_activity.png';

import workerUtils from '../../utilities/workerUtils';

import { partnerCovidCertificationStatuses } from '../../../constants';
import { checkAccess } from '../../../shared/access/Access';
import { permissions } from '../../../shared/access/accessConfig';
import AddressCard from '../../../shared/components/AddressCard';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import EmptyCard from '../../shared/components/EmptyCard';
import IdDetailCard from '../../shared/components/IdDetailCard';
import VaccinationDetailCard from './components/VaccinationDetailCard';
import ConfirmModals from '../../shared/components/ConfirmModals';
import AlertWithAction from '../../shared/components/AlertWithAction';

import ManageWorkerActions from './components/ManageWorkerActions';
import RejectWorkerModal from './components/RejectWorkerModal';
import ProfileCard from './components/ProfileCard';
import EmploymentTab from './components/EmploymentTab';
import ApplicationsTab from './components/ApplicationsTab';
import VerifyCovidCertificationModal from './components/VerifyCovidCertificationModal';
import ListWithShowMore from '../../shared/components/ListWithShowMore';

const { TabPane } = Tabs;

const EmptyState = () => (
  <EmptyCard
    title="Worker has no activity yet"
    description="Help this worker find a job!"
    imgSrc={noActivityImg}
    style={{ marginBottom: '605px' }}
  />
);

class WorkerProfileView extends React.Component {
  state = {
    partner: undefined,
    rejectWorkerModal: false,
    verifyCovidCertificationModal: false,
    workmateExperiences: [],
  };

  async componentDidMount() {
    const { id } = this.props.match.params;
    await this.fetchWorker();

    // Each experience is a confirmed or cancelled offer with valid clock in records
    const experiencesResponse = await partnerApi.fetchWorkmateExperiences(id);
    this.setState({
      workmateExperiences: experiencesResponse.results,
    });
  }

  fetchWorker = async () => {
    const { id } = this.props.match.params;
    const partner = await partnerApi.fetchPartnerWithSensitiveInfo(id);
    this.setState({ partner });
  };

  handleVerifyWorker = () => {
    const { partner } = this.state;
    const workerName = `${partner.first_name} ${partner.last_name}`;

    ConfirmModals.verifyWorker(workerName, async () => {
      const updatedPartner = await partnerApi.approvePartner({ id: partner.id });
      this.setState({ partner: updatedPartner });
    });
  };

  toggleModal = (modalName, visible) => {
    this.setState({ [modalName]: visible });
  };

  render() {
    const { partner, rejectWorkerModal, verifyCovidCertificationModal, workmateExperiences } = this.state;
    const { t } = this.props;
    if (!partner) return <LoadingSpinner />;

    const experienceCards = workerUtils.getExperienceCards(partner, workmateExperiences);
    const partnerName = `${partner.first_name} ${partner.last_name}`;
    // TODO: Refactor sections into smaller components using Helpster-components
    return (
      <Row type="flex" justify="space-between">
        <Col>
          <Row style={{ marginBottom: '24px' }}>
            <ProfileCard
              {...workerUtils.cleanProfile(partner)}
              handleVerify={() => this.handleVerifyWorker()}
              handleReject={() => this.toggleModal('rejectWorkerModal', true)}
              extra={
                <Row type="flex" gutter={8}>
                  <Col>
                    {checkAccess(permissions.editPartner) && (
                      <Button>
                        <Link to={routes.editPartner.replace(':id', partner.id)}>Edit profile</Link>
                      </Button>
                    )}
                  </Col>
                  <Col>
                    <ManageWorkerActions partner={partner} onUpdate={this.fetchWorker} />
                  </Col>
                </Row>
              }
            />
          </Row>
          <Row style={{ marginBottom: '24px' }}>
            <IdDetailCard
              socialSecurityId={partner.social_security_id}
              idNumber={partner.identification_number}
              idCardImage={partner.id_card}
              nationality={partner.nationality}
              race={partner.race}
              religion={partner.religion}
              residency={partner.residency}
              permanentResidentDate={partner.permanent_resident_date}
              fundType={partner.fund_type}
              countryId={partner.country.id}
            />
          </Row>
          {partner.covid_certification_status === partnerCovidCertificationStatuses.VERIFIED && (
            <Row style={{ marginBottom: '24px' }}>
              <VaccinationDetailCard covidCertificationImage={partner.covid_certification_image} />
            </Row>
          )}
          <Row style={{ marginBottom: '24px' }}>
            <DetailCard title="BANKING DETAILS" bodyData={workerUtils.getBankingDetails(partner)} />
          </Row>
          <Row style={{ marginBottom: '24px' }}>
            <AddressCard {...partner.address} />
          </Row>
          {workerUtils.getDetailCards(partner).map(card => (
            <Row key={card.title} style={{ marginBottom: '24px' }}>
              <DetailCard title={card.title} bodyData={card.bodyData} />
            </Row>
          ))}
        </Col>
        <Col span={16}>
          {partner.covid_certification_status === partnerCovidCertificationStatuses.PENDING_VERIFICATION && (
            <Row style={{ marginBottom: '24px' }}>
              <AlertWithAction
                showIcon
                icon={<SafetyCertificateFilled />}
                message={t('workerUploadedCovidCertificationAlertTitle', { name: partnerName })}
                description={t('workerUploadedCovidCertificationAlertDescription')}
                type="info"
                action={t('reviewAndVerify')}
                onClick={() => this.toggleModal('verifyCovidCertificationModal', true)}
              />
            </Row>
          )}
          <Row
            style={{
              backgroundColor: 'white',
              padding: '0 24px',
              border: '1px solid #e8e8e8',
              // height: '100%',
            }}
          >
            <Tabs defaultActiveKey="1">
              <TabPane tab="Employment" key="1">
                <EmploymentTab partner={partner} emptyState={<EmptyState />} />
              </TabPane>

              <TabPane tab="Applications" key="2">
                <ApplicationsTab partner={partner} emptyState={<EmptyState />} />
              </TabPane>

              {/* List of experience, education, certificates */}
              <TabPane tab="Experience" key="3">
                {experienceCards.length > 0 &&
                  experienceCards.map((experience, index) => {
                    if (experience.data.length === 0) return null;
                    return (
                      <ListWithShowMore
                        key={index}
                        header={t(experience.header)}
                        data={experience.data}
                        minimumLength={4}
                      />
                    );
                  })}
              </TabPane>
            </Tabs>
          </Row>
        </Col>
        <RejectWorkerModal
          workerId={partner.id}
          workerName={partnerName}
          onUpdate={this.fetchWorker}
          visible={rejectWorkerModal}
          onClose={() => this.toggleModal('rejectWorkerModal', false)}
        />
        <VerifyCovidCertificationModal
          partner={partner}
          partnerName={partnerName}
          onUpdate={this.fetchWorker}
          visible={verifyCovidCertificationModal}
          onClose={() => this.toggleModal('verifyCovidCertificationModal', false)}
        />
      </Row>
    );
  }
}

export default withTranslation()(WorkerProfileView);
