import React, { Component } from 'react';
import { Divider, Row, Col, Input, Select, Typography } from 'antd';
import billingApi from '../../../../services/billingApi';
import BillingItemList from '../components/BillingItemList';

const { Text } = Typography;

class BillingItemsContainer extends Component {
  state = { page: 1, count: 0, loading: false, billingItems: [], ordering: undefined, filters: { search: undefined } };

  componentDidMount() {
    this.fetchBillingItems();
  }

  fetchBillingItems = async () => {
    const { page, filters, ordering } = this.state;
    this.setState({ loading: true });
    const response = await billingApi.fetchBillingItems(page, {
      billing_report: this.props.billingReportId,
      search: filters.search,
      ordering,
    });
    this.setState({ billingItems: response.results, count: response.count, loading: false });
  };

  handleFiltersChange = (key, value) => {
    const filters = { ...this.state.filters };
    filters[key] = value;
    this.setState({ filters }, () => this.fetchBillingItems());
  };

  handleOrderingChange = ordering => {
    this.setState({ ordering }, () => this.fetchBillingItems());
  };

  handlePageChange = page => {
    this.setState({ page }, () => this.fetchBillingItems());
  };

  render() {
    const { billingItems, loading, page, count } = this.state;
    return (
      <>
        <Row type="flex" justify="end">
          <Col>
            <Input.Search
              allowClear
              placeholder="Search worker"
              onSearch={value => this.handleFiltersChange('search', value)}
            />
          </Col>
        </Row>
        <Divider />
        <Row type="flex" justify="space-between" style={{ marginBottom: '32px' }}>
          <Col>
            <Text type="secondary">{count} items</Text>
          </Col>
          <Col>
            <Text type="secondary">Sort by</Text>
            <Select
              defaultValue="-created_date"
              style={{ marginLeft: '8px', width: '148px' }}
              onChange={this.handleOrderingChange}
            >
              <Select.Option value="-created_date">Most recent</Select.Option>
            </Select>
          </Col>
        </Row>

        <BillingItemList
          billingItems={billingItems}
          loading={loading}
          page={page}
          count={count}
          onPageChange={this.handlePageChange}
        />
      </>
    );
  }
}

export default BillingItemsContainer;
