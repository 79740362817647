import base from './base';
import { endpoints, readonlyEndpoints } from '../config';

const create = async payload => {
  const response = await base.api.post(`${endpoints.timesheetEntries}/`, payload);
  return response;
};

const list = async params => {
  const response = await base.api.get(`${readonlyEndpoints.timesheetEntries}/`, { params });
  return response;
};

const download = async params => {
  const response = await base.api.get(`${readonlyEndpoints.timesheetEntries}/download/`, { params });
  return response;
};

const update = async (id, data) => {
  const response = await base.api.patch(`${endpoints.timesheetEntries}/${id}/`, data);
  return response;
};

const approve = async id => {
  const response = await base.api.post(`${endpoints.timesheetEntries}/${id}/approve/`);
  return response;
};

const bulkApprove = async filterParams => {
  const response = await base.api.post(`${endpoints.timesheetEntries}/approve/`, null, { params: filterParams });
  return response;
};

const bulkMarkInProgress = async filterParams => {
  const response = await base.api.post(`${endpoints.timesheetEntries}/bulk-mark-in-progress/`, null, {
    params: filterParams,
  });
  return response;
};

const dispute = async (id, disputed_reason) => {
  const response = await base.api.post(`${endpoints.timesheetEntries}/${id}/dispute/`, { disputed_reason });
  return response;
};

const resolve = async id => {
  const response = await base.api.post(`${endpoints.timesheetEntries}/${id}/resolve/`);
  return response;
};

const voidItem = async (id, voided_reason) => {
  const response = await base.api.post(`${endpoints.timesheetEntries}/${id}/void/`, { voided_reason });
  return response;
};

const fetchDisputeReasons = async () => {
  const response = await base.api.get(`${endpoints.timesheetEntries}/dispute-reasons/`);
  return response;
};

const fetchVoidReasons = async () => {
  const response = await base.api.get(`${endpoints.timesheetEntries}/void-reasons/`);
  return response;
};

const confirm = async id => {
  const response = await base.api.post(`${endpoints.timesheetEntries}/${id}/confirm/`);
  return response;
};

export default {
  create,
  list,
  download,
  update,
  approve,
  bulkApprove,
  dispute,
  resolve,
  voidItem,
  fetchDisputeReasons,
  fetchVoidReasons,
  confirm,
  bulkMarkInProgress,
};
