import React from 'react';
import styled from 'styled-components';
import numeral from 'numeral';
import { Icon, Placeholder } from 'semantic-ui-react';
import moment from 'moment';
import { Header1 } from '../../../../shared/components/headers';
import Card from '../../../../shared/components/Card';
import PaymentStatusLabel from '../../../../shared/components/PaymentReportStatusLabel';
import Definitions from '../../../../shared/components/Definitions';
import PartnerAvatar from '../../../../shared/components/PartnerAvatar';
import { DEFAULT_DATETIME_FORMAT, paymentReportStatuses, paymentReportTypes } from '../../../../constants';
import CopiableText from '../../../../shared/components/CopiableText';
import PaymentItemsTable from '../items/PaymentItemsTable';
import Button from '../../../../shared/components/Button';
import AddButton from '../../../../shared/components/forms/AddButton';
import PaymentItemModal from '../modals/PaymentItemModal';
import paymentApi from '../../../../services/paymentApi';
import Access from '../../../../shared/access/Access';
import { permissions } from '../../../../shared/access/accessConfig';

const CustomStatusLabel = styled(PaymentStatusLabel)`
  display: inline-block;
  vertical-align: bottom;
  margin-left: 20px;
`;

const CustomHeader1 = styled(Header1)``;

const Attributes = styled.div`
  margin-bottom: 30px;
`;

const CustomDefinitions = styled(Definitions)`
  height: 120px;
`;

const CustomPaymentItemsTable = styled(PaymentItemsTable)``;

const CustomAddButton = styled(AddButton)`
  margin-left: 10px;
  margin-top: 10px;
`;

const Actions = styled.div`
  display: flex;

  > * {
    margin-right: 15px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

const Container = styled.div`
  max-width: 910px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;

  ${CustomHeader1} {
    grid-column: 1 / span 1;
  }

  ${Actions} {
    grid-column: 2 / span 1;
    justify-self: flex-end;
  }

  ${Card} {
    grid-column: 1 / span 2;
  }
`;

export default class PaymentReportsPage extends React.Component {
  state = {
    addItemModalOpen: false,
  };

  render() {
    const { paymentReport, onUpdate, ...props } = this.props;
    const canUpdateItem = paymentReport?.status !== paymentReportStatuses.PAID;
    const isDco = paymentReport?.type === paymentReportTypes.DCO;

    return (
      <Container {...props}>
        <CustomHeader1>Payment Report</CustomHeader1>
        <Access
          action={permissions.editPaymentReport}
          yes={() => (
            <Actions>
              {paymentReport && (
                <>
                  {paymentReport.status === paymentReportStatuses.UNPAID && (
                    <Button
                      onClick={() => {
                        paymentApi.markPaid(paymentReport.id).then(() => {
                          onUpdate();
                        });
                      }}
                    >
                      <Icon name="check" />
                      Mark as Paid
                    </Button>
                  )}
                </>
              )}
            </Actions>
          )}
        />

        {paymentReport && paymentReport.partner ? (
          <Card>
            <Card.Header>
              {paymentReport.reference} for ${numeral(paymentReport.net_payment).format('0,0.00')}
              <CustomStatusLabel status={paymentReport.status} />
            </Card.Header>
            <Attributes>
              <CustomDefinitions borderless columns={3}>
                <Definitions.Item>
                  <Definitions.Label>Partner:</Definitions.Label>
                  <Definitions.Value>
                    <PartnerAvatar partner={paymentReport.partner} />
                  </Definitions.Value>
                </Definitions.Item>
                <Definitions.Item>
                  <Definitions.Label>Partner ID:</Definitions.Label>
                  <Definitions.Value>
                    <CopiableText text={paymentReport.partner.id} />
                  </Definitions.Value>
                </Definitions.Item>
                <Definitions.Item>
                  <Definitions.Label>Payment Date:</Definitions.Label>
                  <Definitions.Value>
                    {paymentReport.payment_date && moment(paymentReport.payment_date).format(DEFAULT_DATETIME_FORMAT)}
                  </Definitions.Value>
                </Definitions.Item>
                <Definitions.Item>
                  <Definitions.Label>Bank:</Definitions.Label>
                  <Definitions.Value>{paymentReport.bank && paymentReport.bank.name}</Definitions.Value>
                </Definitions.Item>
                <Definitions.Item>
                  <Definitions.Label>Account Name:</Definitions.Label>
                  <Definitions.Value>
                    <CopiableText text={paymentReport.account_name} />
                  </Definitions.Value>
                </Definitions.Item>
                <Definitions.Item>
                  <Definitions.Label>Account Number:</Definitions.Label>
                  <Definitions.Value>
                    <CopiableText text={paymentReport.account_number} />
                  </Definitions.Value>
                </Definitions.Item>
                <Definitions.Item>
                  <Definitions.Label>Income:</Definitions.Label>
                  <Definitions.Value>{numeral(paymentReport.income).format('0,0.00')}</Definitions.Value>
                </Definitions.Item>
                <Definitions.Item>
                  <Definitions.Label>Tax Rate:</Definitions.Label>
                  <Definitions.Value>{numeral(paymentReport.tax_rate).format('0,0.00')}</Definitions.Value>
                </Definitions.Item>
                <Definitions.Item>
                  <Definitions.Label>
                    {paymentReport.tax_type ? `Tax (${paymentReport.tax_type}):` : 'Tax:'}
                  </Definitions.Label>
                  <Definitions.Value>{numeral(paymentReport.tax).format('0,0.00')}</Definitions.Value>
                </Definitions.Item>
              </CustomDefinitions>
            </Attributes>
            <CustomPaymentItemsTable
              summary
              editable={canUpdateItem}
              paymentItems={paymentReport.payment_items}
              onUpdate={onUpdate}
              isDco={isDco}
              transactionFee={paymentReport.transaction_fee}
            />
            {canUpdateItem && (
              <Access
                action={permissions.createPaymentItem}
                yes={() => (
                  <CustomAddButton onClick={() => this.setState({ addItemModalOpen: true })}>Add item</CustomAddButton>
                )}
              />
            )}
            <PaymentItemModal
              paymentReport={paymentReport}
              open={this.state.addItemModalOpen}
              onClose={() => this.setState({ addItemModalOpen: false })}
              onUpdate={onUpdate}
            />
          </Card>
        ) : (
          <Card>
            <Card.Header>
              <Placeholder>
                <Placeholder.Header>
                  <Placeholder.Line />
                </Placeholder.Header>
              </Placeholder>
            </Card.Header>
            <Placeholder>
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder>
          </Card>
        )}
      </Container>
    );
  }
}
