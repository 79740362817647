import React from 'react';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import { fieldCss } from './Field';

const CustomNumberFormat = styled(NumberFormat)`
  ${fieldCss};
`;

const Container = styled.div``;

const placeholder = 'MM/YYYY';

const mask = ['M', 'M', 'Y', 'Y', 'Y', 'Y'];

export default class MonthYearInput extends React.Component {
  handleValueChange = value => {
    if (value.value.length === mask.length) {
      const [monthString, yearString] = value.formattedValue.split('/');

      const month = parseInt(monthString);
      const year = parseInt(yearString);

      return this.props.onChange({
        month,
        year,
      });
    }
    return this.props.onChange({
      month: undefined,
      year: undefined,
    });
  };

  render() {
    const { onChange, value, ...props } = this.props;

    const valueString = value.month && value.year && value.month.toString().padStart(2, '0') + value.year.toString();

    return (
      <Container {...props}>
        <CustomNumberFormat
          format="##/####"
          placeholder={placeholder}
          mask={mask}
          isNumericString
          value={valueString}
          onValueChange={this.handleValueChange}
        />
      </Container>
    );
  }
}
