import React from 'react';
import { message, Form, Input, Typography, Row, Col, Modal, Avatar } from 'antd';

import leaveApi from '../../../services/leaveApi';

const { TextArea } = Input;
const { Title } = Typography;

class RejectLeaveClaimModal extends React.Component {
  state = {
    isLoading: false,
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        this.setState({ isLoading: true });
        try {
          await leaveApi.reject(this.props.leaveClaim.id, values.notes);
          message.success(`Successfully rejected leave claim #${this.props.leaveClaim.id}`);
          this.props.onComplete();
        } catch (error) {
          const detail = error?.response?.data?.detail;
          message.error(detail ?? error.message);
        }
        this.setState({ isLoading: false });
      }
    });
  };

  render() {
    const { onCancel, visible, leaveClaim } = this.props;
    if (!visible) return <> </>;

    const { isLoading } = this.state;
    const { getFieldDecorator } = this.props.form;
    const { partner } = leaveClaim;
    const leaveClaimId = leaveClaim.id;
    const fullName = `${partner.first_name} ${partner.last_name}`;

    return (
      <Modal
        title={<Title level={4}>{`Reject leave claim #${leaveClaimId}`}</Title>}
        centered
        visible={visible}
        onCancel={onCancel}
        onOk={this.handleSubmit}
        width={480}
        okType="danger"
        okText="Reject"
        okButtonProps={{ loading: isLoading }}
      >
        <Row style={{ marginBottom: '16px' }}>
          Are you sure you want to reject this leave claim? Please provide your reasons for rejecting.
        </Row>

        <Row type="flex" style={{ marginBottom: '16px' }} align="middle" gutter={16}>
          <Col>
            <Avatar shape="square" icon="user" size={88} />
          </Col>
          <Col>
            <Title level={4}>{fullName}</Title>
          </Col>
        </Row>

        <Form hideRequiredMark>
          <Form.Item label="Reason for rejecting (required)">
            {getFieldDecorator('notes', {
              rules: [{ required: true, message: 'Reason is required' }],
              initialValue: leaveClaim.notes,
            })(<TextArea rows={4} placeholder="Please give your reason and some details..." />)}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default Form.create({ name: 'reject-leave-claim-form' })(RejectLeaveClaimModal);
