import React from 'react';
import CheckboxFilter from '../../../shared/components/CheckboxFilter';
import { paymentReportTypes } from '../../../../constants';
import textUtils from '../../../utilities/textUtils';

const options = [
  { id: paymentReportTypes.DCO, name: textUtils.toUpperCase(paymentReportTypes.DCO) },
  { id: paymentReportTypes.STANDARD, name: textUtils.makeFriendly(paymentReportTypes.STANDARD) },
  { id: paymentReportTypes.FREELANCE, name: textUtils.makeFriendly(paymentReportTypes.FREELANCE) },
];

export default class PaymentReportTypeFilter extends React.Component {
  state = {
    selectedOptions: [],
  };

  componentDidMount() {
    this.setState({ selectedOptions: this.props.initialOptions });
  }

  handleChange = values => {
    this.setState({
      selectedOptions: values,
    });
  };

  handleApply = () => {
    this.props.onApply(this.state.selectedOptions);
  };

  render() {
    const { selectedOptions } = this.state;

    return (
      <CheckboxFilter
        showActionButtons
        showSelectAll
        title="Type"
        options={options}
        selectedOptions={selectedOptions}
        selectAllTitle="All types"
        onChange={this.handleChange}
        onApply={this.handleApply}
        optionLabelField="name"
      />
    );
  }
}
