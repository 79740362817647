import React, { Component } from 'react';
import { Button, Header, Input, Message } from 'semantic-ui-react';
import styled from 'styled-components';
import { Field, Form, Formik } from 'formik';
import { object } from 'yup';
import authApi from '../../services/authApi';
import { companyEmailSchema } from '../../utilities/schemaUtils';

const { Subheader } = Header;
const Container = styled.div`
  padding: 60px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CustomInput = styled(Input)`
  &&& {
    display: block;
    input {
      width: 100%;
    }
  }
`;

const CustomForm = styled(Form)`
  min-width: 250px;
  ${CustomInput} {
    margin-bottom: 15px;
    width: 100%;
  }
`;

class ForgotPasswordPage extends Component {
  state = {
    submitted: false,
    success: false,
  };

  onSubmit = async (email, formikActions) => {
    const { setSubmitting, setErrors } = formikActions;
    try {
      setSubmitting(true);
      await authApi.forgotPassword(email);
      this.setState({ success: true });
    } catch (error) {
      setErrors({ email: error.message });
    } finally {
      setSubmitting(false);
    }
  };

  render() {
    const { submitted, success } = this.state;
    return (
      <Container>
        {submitted ? (
          <Formik
            render={props => (
              <CustomForm>
                <Header size="large">
                  Reset Link Sent!
                  <Subheader>Please check your inbox.</Subheader>
                </Header>
              </CustomForm>
            )}
          />
        ) : (
          <Formik
            onSubmit={(values, formikActions) => this.onSubmit(values.email, formikActions)}
            initialValues={{ email: '' }}
            validationSchema={object().shape({
              email: companyEmailSchema,
            })}
            render={props => {
              const { submitCount, isSubmitting, errors } = props;
              return (
                <CustomForm>
                  <Header size="large">
                    Forgot Password
                    <Subheader>Enter your Workmate Email</Subheader>
                  </Header>
                  <Field name="email" render={({ field }) => <CustomInput {...field} placeholder="Email" />} />
                  {submitCount > 0 && errors.email && <Message negative>{errors.email}</Message>}
                  {success && <Message positive>Password reset link sent. Please check your email</Message>}
                  <Button primary loading={isSubmitting} type="submit">
                    Send Reset Link
                  </Button>
                </CustomForm>
              );
            }}
          />
        )}
      </Container>
    );
  }
}

export default ForgotPasswordPage;
