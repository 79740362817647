import React, { Component } from 'react';
import moment from 'moment-timezone';
import { withTranslation } from 'react-i18next';
import { Table, Typography, Row } from 'antd';
import { isEqual } from 'lodash';

import shiftUtils from '../../utilities/shiftUtils';
import datetimeUtils from '../../utilities/datetimeUtils';
import { attendanceStatuses } from '../../../constants';
import { colors } from '../../../styles/colors';
import ShiftsTableCell from './components/ShiftsTableCell';

const { Text } = Typography;

class ScheduleListView extends Component {
  state = {
    loading: false,
    selectedShift: undefined,
    scheduleData: [],
    editShiftModalVisible: false,
    editScheduleModalVisible: false,
    deleteShiftModalVisible: false,
  };

  componentDidMount() {
    this.getTableData();
  }

  componentDidUpdate(prevProp) {
    if (!isEqual(prevProp.schedules, this.props.schedules) || !isEqual(prevProp.shifts, this.props.shifts)) {
      this.getTableData();
    }
  }

  getTableData = () => {
    const { schedules, shifts, weekdayDates } = this.props;
    this.setState({ loading: true });
    if (!schedules || !shifts) {
      return [];
    }
    const scheduleData = schedules.map(schedule => {
      const shiftData = {};
      const scheduleShifts = shifts.filter(shift => shift.schedule.id === schedule.id);
      if (scheduleShifts.length > 0) {
        weekdayDates.forEach(momentDateObj => {
          const shiftForTheDay = shiftUtils.getMatchingShiftForTheDay(scheduleShifts, momentDateObj);
          if (shiftForTheDay) {
            shiftData[moment(momentDateObj).format('DD MMM')] = shiftForTheDay;
          }
        });
      }
      // TODO: use start_date, duration and recurrences!!
      return {
        id: schedule.id,
        name: schedule.name,
        ...shiftData,
      };
    });
    this.setState({ scheduleData, loading: false });
  };

  getShiftFulfillment = shift => {
    const shiftAttendancesCount = shift?.attendances?.reduce((count, attendance) => {
      if (attendance.status === attendanceStatuses.ASSIGNED) return count + 1;
      return count;
    }, 0);
    if (shift.staff_required) {
      return (
        <div>
          <Text type={shiftAttendancesCount < shift.staff_required && 'danger'} style={{ paddingRight: 5 }}>
            {shiftAttendancesCount}
          </Text>
          <Text>/ {shift.staff_required}</Text>
        </div>
      );
    }
    return <div>{shiftAttendancesCount} / - </div>;
  };

  render() {
    const { weekdayDates, schedules, loading: partnerLoading, timezone } = this.props;
    const { loading, scheduleData } = this.state;

    const tableColumns = [
      {
        title: <Text strong></Text>,
        dataIndex: 'name',
        width: 100,
        render: name => (
          <Row>
            <Text type="primary" style={{ fontWeight: 600 }}>
              {name}
            </Text>
          </Row>
        ),
      },
      ...weekdayDates.map(momentDateObj => {
        const isPastShift = momentDateObj < datetimeUtils.getDayStart(moment(), timezone);
        return {
          title: (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Text style={{ color: moment(momentDateObj).isSame(moment(), 'day') && colors.functionalSuccess }}>
                {moment(momentDateObj).format('ddd')}
              </Text>
              <Text style={{ color: moment(momentDateObj).isSame(moment(), 'day') && colors.functionalSuccess }} strong>
                {moment(momentDateObj).format('DD MMM')}
              </Text>
            </div>
          ),
          width: 50,
          dataIndex: moment(momentDateObj).format('DD MMM'),
          render: (shift, schedule) => ({
            props: { style: { padding: 0 } },
            children: (
              <>
                {shift ? (
                  <ShiftsTableCell
                    isPastShift={isPastShift}
                    shift={shift}
                    timezone={timezone}
                    onClick={() => {
                      if (isPastShift) return;
                      this.setState({
                        editShiftModalVisible: true,
                        selectedShift: shift,
                        selectedSchedule: schedules.find(sch => sch.id === schedule.id),
                        selectedDate: momentDateObj,
                      });
                    }}
                  />
                ) : (
                  <div style={{ width: 150 }}></div>
                )}
              </>
            ),
          }),
        };
      }),
    ];

    return (
      <>
        <Table
          className={'schedules-list-table'}
          pagination={false}
          loading={loading || partnerLoading}
          columns={tableColumns}
          dataSource={scheduleData}
          rowKey="id"
          style={{ marginBottom: '80px' }}
          size="middle"
        />
      </>
    );
  }
}

export default withTranslation()(ScheduleListView);
