import React from 'react';
import { Row, Col, Form, Select } from 'antd';
import * as _ from 'lodash';
import { clientStatuses } from '../../../../constants';
import clientApi from '../../../../services/clientApi';
import getCountryCode from '../../../../utilities/getCountryCode';

const { Item } = Form;
const { Option } = Select;

class ClientAndLocationForm extends React.Component {
  // Contains selectors for Client, Location and worker Clock-in methods

  state = {
    clientSearch: undefined,
    clientSearchResults: [],

    locationSearch: undefined,
    locationSearchResults: [],

    loadingClients: false,
    loadingLocations: this.props.isEdit,
  };

  async componentDidMount() {
    const { client } = this.props;
    await this.fetchClients();
    if (client) {
      // TODO: fetchClient already fetches ALL locations with the client, making fetchLocations redundant.
      this.fetchLocations(client.id);
      this.fetchClient(client.id);
    }
  }

  fetchClients = async () => {
    this.setState({ loadingClients: true });
    const { clientSearch } = this.state;
    const { results } = await clientApi.fetchClients({
      country: getCountryCode(),
      status: clientStatuses.ACTIVE,
      search: clientSearch,
    });
    await this.setState({ clientSearchResults: results, loadingClients: false });
  };

  fetchClient = async id => {
    // push current client to the drop down list in case not fetched in the fetchClients API
    const response = await clientApi.fetchClientById(id);
    this.setState(prevState => ({ clientSearchResults: [...prevState.clientSearchResults, response] }));
  };

  fetchLocations = async clientId => {
    this.setState({ loadingLocations: true });
    const { locationSearch } = this.state;
    const response = await clientApi.fetchLocationsByClientId(clientId, 1, {
      search: locationSearch,
    });
    // TODO: Fetches the next batch of locations, up to 20.
    // This is a quick fix to solve the problem of clients having >20 locations. If client has > 40, the problem will arise.
    // long term fix is to do infinite loading
    let locationSearchResults = [];
    const { results, nextPage } = response;
    locationSearchResults = [...results];
    if (nextPage) {
      const nextResponse = await clientApi.fetchLocationsByClientId(clientId, nextPage, { search: locationSearch });
      locationSearchResults = [...locationSearchResults, ...nextResponse.results];
    }

    this.setState({
      locationSearchResults,
      loadingLocations: false,
    });
  };

  // eslint-disable-next-line react/sort-comp
  debouncedFetchClients = _.debounce(this.fetchClients, 800);

  // eslint-disable-next-line react/sort-comp
  debouncedFetchLocations = _.debounce(this.fetchLocations, 800);

  render() {
    const { clientSearchResults, locationSearchResults, loadingClients, loadingLocations } = this.state;
    const {
      isEdit,
      client,
      location,
      isLoading,
      getFieldDecorator,
      getFieldValue,
      onFieldChange,
      getCurrencyCode,
      getWageTypeOptions,
      getTimezone,
    } = this.props;
    if (isLoading) return <></>;
    if (isEdit) {
      getFieldDecorator('client', { initialValue: client.id });
    }
    return (
      <section>
        <Row>
          <Col xs={24} md={14}>
            <Item label="Choose a client" style={{ marginBottom: '8px' }}>
              {!isEdit &&
                getFieldDecorator('client', {
                  rules: [{ required: true, message: 'Please select a client' }],
                  initialValue: client ? client.id : undefined,
                })(
                  <Select
                    showSearch
                    placeholder="Select one"
                    style={{ width: '100%' }}
                    loading={loadingClients}
                    filterOption={false}
                    onSearch={async search => {
                      await this.setState({
                        clientSearch: search,
                        clientSearchResults: [],
                        locationSearchResults: [],
                      });
                      this.debouncedFetchClients();
                    }}
                    onChange={async clientId => {
                      this.setState({ locationSearchResults: [] });
                      const selectedClient = clientSearchResults.find(({ id }) => id === clientId);
                      await this.fetchLocations(clientId);
                      onFieldChange('client', clientId);
                      onFieldChange('location', undefined);
                      getCurrencyCode(selectedClient);
                      getWageTypeOptions(selectedClient);
                    }}
                  >
                    {clientSearchResults.length > 0 &&
                      clientSearchResults.map(({ id, name }) => (
                        <Option key={id} value={id}>
                          {`[${id}] ${name}`}
                        </Option>
                      ))}
                  </Select>,
                )}
              {isEdit && <span>{`[${client.id}] ${client.name}`}</span>}
            </Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} md={14}>
            <Item label="Choose a business location" style={{ marginBottom: '8px' }}>
              {getFieldDecorator('location', {
                rules: [{ required: true, message: 'Please select a location' }],
                // initialValue has to be location.id, ignore the issue with display name for now.
                initialValue: client ? location.id : undefined,
              })(
                <Select
                  showSearch
                  placeholder="Select one"
                  style={{ width: '100%' }}
                  loading={loadingLocations}
                  filterOption={false}
                  // Search by location name or street name
                  onSearch={async search => {
                    await this.setState({
                      locationSearch: search,
                      locationSearchResults: [],
                    });
                    // No client field value when isEdit and client isn't changed
                    this.debouncedFetchLocations(getFieldValue('client') || client.id);
                  }}
                  onChange={locationId => {
                    onFieldChange('location', locationId);
                    const selectedLocation = locationSearchResults.find(({ id }) => id === locationId);
                    getTimezone(selectedLocation.address.area.city.timezone);
                  }}
                >
                  {locationSearchResults.length > 0 &&
                    locationSearchResults.map(({ id, name }) => (
                      <Option key={id} value={id}>
                        {name}
                      </Option>
                    ))}
                </Select>,
              )}
            </Item>
          </Col>
        </Row>
      </section>
    );
  }
}

export default ClientAndLocationForm;
