import React from 'react';
import { Icon } from 'antd';

const LoadingSpinner = ({ text = '' }) => {
  return (
    <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
      <Icon style={{ fontSize: '5rem', marginBottom: '12px' }} type="loading" />
      {text.length > 0 && <h3 style={{ textAlign: 'center' }}>{text}</h3>}
    </div>
  );
};

export default LoadingSpinner;
