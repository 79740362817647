import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as userActions from '../../redux/user';
import LoginPageView from './LoginPageView';

class LoginPage extends React.Component {
  render() {
    return <LoginPageView {...this.props} />;
  }
}

const mapStateToProps = state => ({
  user: state.user,
});

const mapDispatchToProps = dispatch => {
  const { login } = userActions;
  return {
    handleLogin: user => {
      dispatch(login(user));
    },
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(LoginPage),
);
