import React from 'react';
import {
  ConfigProvider,
  Avatar,
  List,
  Modal,
  Input,
  Row,
  Col,
  Typography,
  Card,
  Pagination,
  message,
  Button,
  Icon,
  Dropdown,
  Menu,
} from 'antd';

import staffRequestUtils from '../../../utilities/staffRequestUtils';
import staffRequestApi from '../../../../services/staffRequestApi';
import channelApi from '../../../../services/channelApi';
import { staffRequestStatuses, channelAudiences } from '../../../../constants';

import StatusTag from '../../../shared/components/StatusTag';
import EmptySearchResult from '../../../shared/components/EmptySearchResult';
import LoadingSpinner from '../../../shared/components/LoadingSpinner';

const { Text, Paragraph, Title } = Typography;

class SrSelectorModal extends React.Component {
  state = {
    isLoading: false,
    staffRequests: undefined,
    numRecords: 0,
    searchParams: {
      page: 1,
      status: [staffRequestStatuses.POSTED, staffRequestStatuses.IN_PROGRESS].join(','),
    },
  };

  async componentDidMount() {
    this.fetchStaffRequests();
  }

  fetchStaffRequests = async () => {
    this.setState({ isLoading: true });
    try {
      const staffRequestResponse = await staffRequestApi.fetchStaffRequestSummarysWithTasks(this.state.searchParams);
      const { results, count } = staffRequestResponse;
      this.setState({
        staffRequests: results,
        numRecords: count,
        isLoading: false,
      });
    } catch (error) {
      message.error(error.response.data.detail || error.message);
      this.setState({
        isLoading: false,
      });
    }
  };

  onOpenChannel = async (staffRequest, audience) => {
    const response = await this.fetchChannelWithStaffRequestId(staffRequest.id, audience);
    if (response.results.length > 0) {
      this.props.handleChannelOpen(response.results[0]);
      this.handleClose();
      return;
    }
    this.setState({ isLoading: true });
    const channel = await channelApi.createChannel(staffRequest.id, audience);
    this.props.handleChannelOpen(channel);
    this.handleClose();
  };

  fetchChannelWithStaffRequestId = async (staffRequestId, audience) => {
    this.setState({ isLoading: true });
    try {
      const channelResponse = await channelApi.fetchChannels({
        staff_request: staffRequestId,
        audience,
      });
      this.setState({
        isLoading: false,
      });
      return channelResponse;
    } catch (error) {
      message.error(error.response.data.detail || error.message);
      this.setState({
        isLoading: false,
      });
    }
  };

  onSearchParamChange = value => {
    this.setState(
      prevState => {
        const nextSearchParams = prevState.searchParams;
        nextSearchParams.search = value;
        nextSearchParams.page = 1;
        return { searchParams: nextSearchParams };
      },
      () => this.fetchStaffRequests(),
    );
  };

  onPageChange = pageNum => {
    this.setState(
      prevState => {
        const nextSearchParams = prevState.searchParams;
        nextSearchParams.page = pageNum;
        return { searchParams: nextSearchParams };
      },
      () => this.fetchStaffRequests(),
    );
  };

  getEmptyCard = () => {
    const { isLoading, searchParams } = this.state;
    if (isLoading) return <></>;

    if (searchParams.search) {
      return (
        <Row style={{ marginTop: '48px' }}>
          <EmptySearchResult />
        </Row>
      );
    }
  };

  handleClose = () => {
    this.setState({ isLoading: false });
    this.props.onClose();
  };

  render() {
    const { isLoading, staffRequests, searchParams, numRecords } = this.state;
    const { visible } = this.props;
    return (
      <Modal
        width="793px"
        visible={visible}
        title={
          <Title strong level={4} style={{ marginBottom: 0 }}>
            Open broadcast channel
          </Title>
        }
        footer={null}
        onCancel={this.handleClose}
        bodyStyle={{ paddingTop: '16px' }}
      >
        <Text>Select an active SR to broadcast messages to hired or applied workers.</Text>
        <Row style={{ marginTop: '36px', marginBottom: '36px' }}>
          <Input.Search
            allowClear
            placeholder="Search by SR ID, SR title, client name, or job position"
            onSearch={searchValue => this.onSearchParamChange(searchValue)}
          />
        </Row>
        <Row style={{ height: 'calc(100vh - 464px)', overflowY: 'auto' }}>
          <ConfigProvider renderEmpty={this.getEmptyCard}>
            <List
              itemLayout="vertical"
              loading={
                isLoading && {
                  indicator: (
                    <div style={{ marginTop: '100px' }}>
                      <LoadingSpinner width="50px" />
                    </div>
                  ),
                }
              }
              dataSource={staffRequests || []}
              renderItem={item => {
                const {
                  client,
                  title,
                  id,
                  position,
                  location,
                  status,
                  confirmed_partners,
                  start_time,
                  end_time,
                  schedules_count,
                } = item;

                const menu = (
                  <Menu onClick={({ key: audience }) => this.onOpenChannel(item, audience)}>
                    <Menu.Item key={channelAudiences.APPLIED_APPLICANTS}>Applicants</Menu.Item>
                    <Menu.Item key={channelAudiences.ACTIVE_EMPLOYEES}>Employees</Menu.Item>
                  </Menu>
                );

                return (
                  // TODO: This is a similar card to ChatList & can be refactored out to a shared component
                  <Card bordered={false} bodyStyle={{ padding: '16px 0', borderBottom: '0.5px solid #E8E8E8' }}>
                    <Row type="flex" justify="space-between">
                      <Col span={18}>
                        <Row type="flex" gutter={16}>
                          <Col>
                            <Avatar
                              shape="square"
                              size={72}
                              src={client.logo}
                              alt="client_logo"
                              icon="user"
                              className="v2-avatar-logo-wrapper"
                            />
                          </Col>
                          <Col span={18}>
                            <Row>
                              <Text strong type="secondary" style={{ fontSize: '12px', paddingBottom: '8px' }}>
                                SR #{id} · {position.name.toUpperCase()}
                              </Text>
                            </Row>
                            <Text strong>{title}</Text>
                            <Paragraph ellipsis style={{ marginBottom: 0 }}>
                              {client.name} · {location.name}
                            </Paragraph>
                            <Row>
                              <Text>
                                {confirmed_partners.count} hired workers ·{' '}
                                {staffRequestUtils.durationToString(start_time, end_time, schedules_count, false)}
                              </Text>
                            </Row>
                            <StatusTag status={status} style={{ marginTop: '4px' }} />
                          </Col>
                        </Row>
                      </Col>
                      <Col style={{ margin: '40px 24px' }}>
                        <Dropdown overlay={menu}>
                          <Button>
                            Open <Icon type="down" />
                          </Button>
                        </Dropdown>
                      </Col>
                    </Row>
                  </Card>
                );
              }}
            />
          </ConfigProvider>
        </Row>
        <Row type="flex" justify="end" style={{ padding: '24px 0 0 24px' }}>
          <Pagination
            showQuickJumper
            current={searchParams.page}
            total={numRecords}
            onChange={this.onPageChange}
            pageSize={20}
            position="bottom"
            size="large"
          />
        </Row>
      </Modal>
    );
  }
}

export default SrSelectorModal;
