import React from 'react';
import { Icon, Input } from 'semantic-ui-react';
import { Form, Formik } from 'formik';
import styled from 'styled-components/macro';
import { rgba } from 'polished';
import PropTypes from 'prop-types';
import { colors } from '../../styles/colors';

const CustomInput = styled(Input)`
  &&& input {
    border-radius: 2em;
    border-color: ${colors.lightGrey};
    box-shadow: 0 2px 6px ${rgba(colors.absoluteBlack, 0.04)};
  }

  &.ui.icon.input > i.icon:not(.link) {
    cursor: pointer;
    pointer-events: initial;
  }
`;

export default class SearchForm extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    placeholder: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      hasSearch: false,
    };
  }

  render() {
    const { value, onSubmit, loading, onClose, initialString, placeholder, ...rest } = this.props;

    return (
      <Formik
        {...rest}
        enableReinitialize
        initialValues={{ string: value || '' }}
        onSubmit={(values, actions) => {
          if (loading) return;
          onSubmit(values.string);
          this.setState({ hasSearch: !!values.string });
        }}
        render={props => (
          <Form {...rest}>
            <CustomInput
              fluid
              type="search"
              icon={
                props.values.string || this.state.hasSearch ? (
                  <Icon
                    onClick={() => {
                      this.setState({ hasSearch: false });
                      props.resetForm();
                      onClose();
                    }}
                    name="close"
                  />
                ) : (
                  <Icon name="search" />
                )
              }
              placeholder={placeholder || 'Search'}
              name="string"
              value={props.values.string}
              onChange={props.handleChange}
              loading={loading}
            />
            <button hidden type="submit" />
          </Form>
        )}
      />
    );
  }
}
