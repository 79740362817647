/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import moment from 'moment';
import { Typography, Menu, Dropdown, Tooltip } from 'antd';
import { withTranslation } from 'react-i18next';
import { attendanceOffScheduleCode, TIME_FORMAT_24_HOUR } from '../../../../constants';
import datetimeUtils from '../../../utilities/datetimeUtils';
import attendanceUtils from '../../../utilities/attendanceUtils';
import { getCellAttributes, getLabelText, getToolTipTitle } from '../utils/assignmentCellUtils';

const { Text, Paragraph } = Typography;
class AssignmentTableCell extends React.Component {
  handleStatusChange = ({ key }) => {
    const { date } = this.props.assignment;
    this.props.onUpdate([
      {
        date,
        targetShiftId: parseInt(key, 10) >= 0 ? parseInt(key, 10) : undefined,
        workerId: this.props.worker.id,
        employmentId: this.props.employmentId,
      },
    ]);
  };

  render() {
    const { assignment, timezone, t } = this.props;
    const { attendance, shifts, contractStatus } = assignment;

    const cellAttributes = getCellAttributes(assignment, timezone);
    const cellBackgroundStyle = {
      display: 'flex',
      flexDirection: 'row',
      height: 50,
      width: '100%',
      backgroundColor: cellAttributes.backgroundColor,
      cursor: 'pointer',
    };
    const cellSidebarStyle = {
      width: 5,
      backgroundColor: cellAttributes.sideBarColor,
      marginRight: 8,
    };
    const cellLabelStyle = {
      display: 'flex',
      flexDirection: 'row',
      alignSelf: 'center',
      width: 100,
      paddingLeft: 8,
      margin: 5,
      justifyContent: 'space-between',
    };

    const dayStart = datetimeUtils.getDayStart(moment(), timezone);
    const assignmentDisabled =
      attendanceUtils.isAbsent(attendance) ||
      moment(assignment.date).isBefore(dayStart) ||
      !attendanceUtils.isValidWorkingDay(contractStatus);

    const menu = (
      <Menu onClick={this.handleStatusChange}>
        {shifts
          .filter(shiftItem => shiftItem.id !== attendance?.shift_id)
          .map(shiftItem => (
            <Menu.Item key={shiftItem.id}>
              {shiftItem.schedule.name} · {moment(shiftItem.startTime).format(TIME_FORMAT_24_HOUR)} -{' '}
              {moment(shiftItem.endTime).format(TIME_FORMAT_24_HOUR)}
            </Menu.Item>
          ))}
        <Menu.Item key={attendanceOffScheduleCode}>{t('offDay')}</Menu.Item>
      </Menu>
    );

    if (assignmentDisabled) {
      const tooltipTitle = getToolTipTitle(assignment, timezone, t);
      return (
        <>
          {tooltipTitle ? (
            <Tooltip title={tooltipTitle}>
              <div style={{ ...cellBackgroundStyle, cursor: 'initial' }}>
                <div style={cellSidebarStyle}>
                  <div style={cellLabelStyle}>
                    <Paragraph ellipsis style={{ fontSize: 12, color: cellAttributes.labelColor }}>
                      {getLabelText(attendance, assignment, timezone, t)}
                    </Paragraph>
                    <Text style={{ alignSelf: 'center' }}>{cellAttributes.icon}</Text>
                  </div>
                </div>
              </div>
            </Tooltip>
          ) : (
            <div style={{ ...cellBackgroundStyle, cursor: 'initial' }}>
              <div style={cellSidebarStyle}>
                <div style={cellLabelStyle}>
                  <Paragraph ellipsis style={{ fontSize: 12, color: cellAttributes.labelColor }}>
                    {getLabelText(attendance, assignment, timezone, t)}
                  </Paragraph>
                  <Text style={{ alignSelf: 'center' }}>{cellAttributes.icon}</Text>
                </div>
              </div>
            </div>
          )}
        </>
      );
    }

    return (
      <Dropdown overlay={menu} trigger={['click']}>
        <div style={cellBackgroundStyle}>
          <div style={cellSidebarStyle}>
            <div style={cellLabelStyle}>
              <Paragraph ellipsis style={{ fontSize: 12, color: cellAttributes.labelColor }}>
                {getLabelText(attendance, assignment, timezone, t)}
              </Paragraph>
              <Text style={{ alignSelf: 'center' }}>{cellAttributes.icon}</Text>
            </div>
          </div>
        </div>
      </Dropdown>
    );
  }
}

export default withTranslation()(AssignmentTableCell);
