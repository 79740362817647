// TODO: Refactor out into helpster-components library
import React from 'react';
import { Typography, Row, Col, Avatar, Card } from 'antd';

const { Text } = Typography;
const EmptyListCard = ({ title, description, imgSrc }) => (
  <Card bordered={false} style={{ padding: '16px 12px 16px 24px', borderBottom: 0, textAlign: 'left' }}>
    <Row type="flex" gutter={15}>
      <Col>
        <Avatar shape="square" size={48} src={imgSrc} alt="no_channel" />
      </Col>
      <Col span={18} style={{ paddingRight: 0 }}>
        <Row type="flex" style={{ color: '#262626' }}>
          <Text strong type="secondary">
            {title}
          </Text>
        </Row>
        <Row>
          <Text type="secondary" style={{ marginBottom: 0 }}>
            {description}
          </Text>
        </Row>
      </Col>
    </Row>
  </Card>
);
export default EmptyListCard;
