import React, { Component } from 'react';

import { Col, Row } from 'antd';

import { BangkokLatLng, JakartaLatLng } from '../../../constants';
import staffRequestApi from '../../../services/staffRequestApi';
import getCountryCode from '../../../utilities/getCountryCode';
import recommendationApi from '../../../services/recommendationApi';
import MatchedPartnerSidebar from './components/Sidebar';
import MatchedPartnerMap from './components/Map';

class MatchedPartnerPage extends Component {
  state = {
    staffRequestId: null,
    recommendations: [],
    totalMatchedPartners: null,
    staffRequest: null,
    matchedPartners: [],
    currentSelectPartner: null,
  };

  defaultParams = {
    center: {
      lat: getCountryCode() === 'ID' ? JakartaLatLng.lat : BangkokLatLng.lat,
      lng: getCountryCode() === 'ID' ? JakartaLatLng.lng : BangkokLatLng.lng,
    },
    zoom: 12,
    height: '800px',
  };

  componentDidMount() {
    this.setState({ staffRequestId: this.props.match.params.id });
    this.refresh();
  }

  refresh() {
    this.fetchSRDetails();
    this.fetchRecommendations();
    this.fetchMatchedPartners();
  }

  fetchSRDetails = () => {
    staffRequestApi.fetchStaffRequest(this.props.match.params.id).then(staffRequest => {
      this.setState({
        staffRequest,
      });
    });
  };

  fetchMatchedPartners = page => {
    const fetchParams = {
      page,
    };
    staffRequestApi.fetchMatches(this.props.match.params.id, fetchParams).then(partners => {
      const partnersWithOfferStatus = partners.results.map(partner => {
        partner.status = this.getRecommendationStatus(partner.id);
        return partner;
      });
      this.setState({
        matchedPartners: partnersWithOfferStatus,
        totalMatchedPartners: partners.count,
      });
    });
  };

  getRecommendationStatus = partnerId => {
    const recommendation = this.state.recommendations.filter(recommendation => recommendation.partner.id === partnerId);
    if (recommendation.length > 0) return recommendation[0].status;
    return null;
  };

  fetchRecommendations = () => {
    recommendationApi.fetchRecommendations({ staff_request: this.props.match.params.id }).then(recommendations => {
      this.setState({
        recommendations: recommendations.results,
      });
    });
  };

  createRecommendation = (partnerId, source) => {
    recommendationApi.createRecommendation(this.props.match.params.id, partnerId, source).then(() => {
      this.refresh();
    });
  };

  handleSelection = partnerId => {
    this.setState({ currentSelectPartner: partnerId });
  };

  handlePageChange = page => {
    this.fetchMatchedPartners(page);
  };

  render() {
    return (
      <Row>
        <Col span={18} style={{ height: this.defaultParams.height }}>
          <MatchedPartnerMap
            staffRequest={this.state.staffRequest}
            partners={this.state.matchedPartners}
            currentPartner={this.state.currentSelectPartner}
            defaultValue={this.defaultParams}
            onSelect={this.handleSelection}
          />
        </Col>
        <Col span={6}>
          <MatchedPartnerSidebar
            height={this.defaultParams.height}
            total={this.state.totalMatchedPartners}
            onPageChange={this.handlePageChange}
            partners={this.state.matchedPartners}
            currentPartner={this.state.currentSelectPartner}
            createRecommendation={this.createRecommendation}
            onSelect={this.handleSelection}
          />
        </Col>
      </Row>
    );
  }
}

export default MatchedPartnerPage;
