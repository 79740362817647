import React from 'react';
import moment from 'moment-timezone';
import { withTranslation } from 'react-i18next';
import { Modal, Typography, Button, Row, Col } from 'antd';

import partnerApi from '../../../../services/partnerApi';
import { colors } from '../../../../styles/colors';
import { DATE_FORMAT } from '../../../../constants';

const { Paragraph, Text } = Typography;

const VerifyCovidCertificationModal = ({ partner, partnerName, visible, onClose, onUpdate, t }) => {
  const handleVerifyCovidCertification = async () => {
    await partnerApi.verifyCovidCertification(partner);
    onClose();
    onUpdate();
  };

  const handleInvalidateCovidCertification = async () => {
    await partnerApi.invalidateCovidCertification(partner);
    onClose();
    onUpdate();
  };

  const handleOnCancel = () => {
    onClose();
  };

  const footerActions = [
    <Button key="cancel" onClick={handleOnCancel} style={{ marginRight: 'auto' }}>
      {t('cancel')}
    </Button>,
    <Button key="invalidate" style={{ color: colors.functionalError }} onClick={handleInvalidateCovidCertification}>
      {t('markAsInvalid')}
    </Button>,
    <Button
      key="verify"
      style={{ background: colors.infoHighlightBlue, color: colors.white }}
      onClick={handleVerifyCovidCertification}
    >
      {t('verify')}
    </Button>,
  ];

  return (
    <Modal
      className="v2-verify-covid-certification-modal"
      visible={visible}
      onCancel={handleOnCancel}
      width="761px"
      title={<strong style={{ fontSize: '14px', lineHeight: '22px' }}>{t('verifyCovidCertModalTitle')}</strong>}
      footer={footerActions}
    >
      <section key={0}>
        <Row style={{ marginBottom: 16 }}>
          <Col>
            <Paragraph style={{ marginBottom: 0 }}>
              <Text>{t('covidCertModalDescriptionHeader')}</Text>
            </Paragraph>
            <Paragraph style={{ marginBottom: 0, paddingLeft: 6 }}>
              <Text>{t('covidCertModalDescriptionRowOne')}</Text>
            </Paragraph>
            <Paragraph style={{ marginBottom: 0, paddingLeft: 6 }}>
              <Text>{t('covidCertModalDescriptionRowTwo')}</Text>
            </Paragraph>
            <Paragraph style={{ marginBottom: 0, paddingLeft: 6 }}>
              <Text>{t('covidCertModalDescriptionRowThree')}</Text>
            </Paragraph>
          </Col>
        </Row>
        <Row style={{ marginBottom: 16 }}>
          <img src={partner.covid_certification_image} alt="covid-certificate" style={{ width: '100%' }} />
        </Row>
        <Row>
          <Col>
            <Paragraph style={{ marginBottom: 0 }}>
              <Text>
                {t('covidCertModalUploadedText', {
                  name: partnerName,
                  date: moment(partner.covid_certification_image_upload_date).format(DATE_FORMAT),
                })}
              </Text>
            </Paragraph>
          </Col>
        </Row>
      </section>
    </Modal>
  );
};

export default withTranslation()(VerifyCovidCertificationModal);
