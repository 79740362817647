import { Button, Col, Input, List, Row, Select, Typography } from 'antd';
import React from 'react';
import { withTranslation } from 'react-i18next';
import {
  clientStatuses,
  clientTypes,
  clientTypesTitle,
  pendingApprovalClientStatuses,
  serviceLevelTypes,
} from '../../../constants';
import routes from '../../../routes';
import clientApi from '../../../services/clientApi';
// V1 folders
import { checkAccess } from '../../../shared/access/Access';
import { permissions } from '../../../shared/access/accessConfig';
import getCountryCode from '../../../utilities/getCountryCode';
import CardWithCount from '../../shared/components/CardWithCount';
// Should be in HC library
import ClientSummaryCard from '../../shared/components/ClientSummaryCard';
import DetailCard from '../../shared/components/DetailCard';
import RadioButtonFilter from '../../shared/components/RadioButtonFilter';
import CheckboxFilter from '../../shared/components/CheckboxFilter';
import getPaginationConfig from '../../utilities/getPaginationConfig';
import { humanizeText, toCamelCase } from '../../utilities/textUtils';

const { Option } = Select;
const { Title, Text } = Typography;
const allStatus = 'all status';

const filterTypes = {
  CHECK_BOX: 'checkbox',
  RADIO_BUTTON: 'radio button',
};

class ClientListView extends React.Component {
  state = {
    isLoading: false,
    searchParams: {
      page: 1,
      search: undefined,
      status: allStatus,
      tier: undefined,
      type: undefined,
      payment_method: undefined,
      service_level: undefined,
    },
    clients: null,
    numTotal: 0, // Total SR count in database
    numRecords: 0, // Total SR with current search params - for pagination
    numPendingApproval: 0,
    numLead: 0,
    numPilot: 0,
    numActive: 0,
    numDeactivated: 0,
    numArchived: 0,
    numSuspended: 0,
    numWMPClients: 0,
    numStaffingClients: 0,
    numBPOClients: 0,
  };

  async componentDidMount() {
    const clientApiResponse = await clientApi.fetchClients({
      country: getCountryCode(),
      include_status_counts: true,
      include_type_counts: true,
    });
    if (clientApiResponse) {
      const { count, status_counts, type_counts, results } = clientApiResponse;
      this.setState({
        clients: results,
        numTotal: count,
        numRecords: count,
        numPendingApproval: status_counts.pending_approval,
        numLead: status_counts.lead,
        numPilot: status_counts.pilot,
        numActive: status_counts.active,
        numDeactivated: status_counts.deactivated,
        numArchived: status_counts.archived,
        numSuspended: status_counts.suspended,
        numWMPClients: type_counts[clientTypes.WMP],
        numStaffingClients: type_counts[clientTypes.MANAGED_SERVICE],
        numBPOClients: type_counts[clientTypes.BPO],
      });
    }
  }

  fetchClientsWithParams = async () => {
    const { searchParams } = this.state;
    const { page, search, status, tier, type, payment_method, service_level } = searchParams;

    this.setState({ isLoading: true });

    // need to seperately handle pending_approval status as its checked thru the verified flag
    // if pending approval is not selected -- verified = 'true'
    let params = {
      country: getCountryCode(),
      page,
      search,
      status,
      tier,
      type,
      verified: 'true',
      payment_method,
      service_level,
    };
    const statusArray = status.split(',');
    if (
      statusArray.includes(clientStatuses.PENDING_APPROVAL) ||
      statusArray.includes(clientStatuses.DEACTIVATED) ||
      statusArray.includes(clientStatuses.SUSPENDED) ||
      statusArray.includes(clientStatuses.ARCHIVED)
    ) {
      // if pending approval include pilot, active, lead statuses with verfied = false
      params = {
        ...params,
        status: statusArray.includes(clientStatuses.PENDING_APPROVAL)
          ? pendingApprovalClientStatuses.join(',')
          : status,
        verified: 'false',
      };
    } else if (statusArray.includes(allStatus)) {
      delete params.status;
      delete params.verified;
    }

    const { results, count } = await clientApi.fetchClients(params);
    this.setState({
      isLoading: false,
      clients: results,
      numRecords: count,
    });
  };

  onPageChange = async page => {
    const prevParams = this.state.searchParams;
    if (page !== prevParams.page) {
      await this.setState({
        searchParams: { ...prevParams, page },
      });
      this.fetchClientsWithParams();
    }
  };

  onSearch = searchValue => {
    this.setState(
      prevState => {
        const nextSearchParams = prevState.searchParams;
        nextSearchParams.search = searchValue;
        return { searchParams: nextSearchParams };
      },
      () => this.fetchClientsWithParams(),
    );
  };

  goToCreateClient = () => {
    this.props.history.push(routes.createClient);
  };

  getOverview = () => {
    const {
      numTotal,
      numPendingApproval,
      numLead,
      numPilot,
      numActive,
      numDeactivated,
      numArchived,
      numSuspended,
    } = this.state;
    return {
      title: 'Overview',
      contentList: [
        { title: 'Total accounts', value: `${numTotal}` },
        { title: 'Pending Approval', value: `${numPendingApproval}` },
        { title: 'Lead', value: `${numLead}` },
        { title: 'Pilot', value: `${numPilot}` },
        { title: 'Active', value: `${numActive}` },
        { title: 'Deactivated', value: `${numDeactivated}` },
        { title: 'Suspended', value: `${numSuspended}` },
        { title: 'Archived', value: `${numArchived}` },
      ],
    };
  };

  getActiveClientTypeOverview = t => {
    const { numWMPClients, numStaffingClients, numBPOClients } = this.state;
    return {
      title: 'Active Product Types',
      contentList: [
        { title: t(clientTypesTitle[clientTypes.WMP]), value: `${numWMPClients}` },
        { title: t(toCamelCase(clientTypesTitle[clientTypes.MANAGED_SERVICE])), value: `${numStaffingClients}` },
        { title: t(clientTypesTitle[clientTypes.BPO]), value: `${numBPOClients}` },
      ],
    };
  };

  handleAllFilterSelectChange = (field, checkedValues, allOptions) => {
    const allOptionsSelected = checkedValues.length === allOptions.length;
    const { searchParams } = this.state;
    const nextSearchParams = searchParams;
    // De-select all
    if (allOptionsSelected) {
      nextSearchParams[field] = undefined;
    } else {
      // Select all available options
      nextSearchParams[field] = allOptions.map(({ id }) => id).join(',');
    }
    this.setState({ searchParams: nextSearchParams });
  };

  onFilterChange = (field, values) => {
    const joinedValues = values.join(',');
    this.setState(prevState => {
      const nextSearchParams = prevState.searchParams;
      nextSearchParams[field] = joinedValues;
      return { searchParams: nextSearchParams };
    });
  };

  getCheckBoxFilters = t => {
    const { searchParams } = this.state;
    const { status, type, service_level } = searchParams;

    const clientStatusOptions = [
      { id: allStatus, name: 'All Status' },
      { id: clientStatuses.PENDING_APPROVAL, name: 'Pending approval' },
      { id: clientStatuses.LEAD, name: 'Lead' },
      { id: clientStatuses.PILOT, name: 'Pilot' },
      { id: clientStatuses.ACTIVE, name: 'Active' },
      { id: clientStatuses.DEACTIVATED, name: 'Deactivated ' },
      { id: clientStatuses.SUSPENDED, name: 'Suspended' },
      { id: clientStatuses.ARCHIVED, name: 'Archived' },
    ];
    const typeStatusOptions = [
      { id: clientTypes.WMP, name: t(clientTypes.WMP) },
      { id: clientTypes.MANAGED_SERVICE, name: t(toCamelCase(clientTypes.MANAGED_SERVICE)) }, // return translated version
      { id: clientTypes.BPO, name: t(clientTypes.BPO) },
      { id: clientTypes.SELF_SERVE, name: t(toCamelCase(clientTypes.SELF_SERVE)) }, // return translated version
    ];
    const serviceLevelOptions = [
      { id: serviceLevelTypes.MANAGED_SERVICE, name: humanizeText(serviceLevelTypes.MANAGED_SERVICE) },
      { id: serviceLevelTypes.SELF_SERVE, name: humanizeText(serviceLevelTypes.SELF_SERVE) },
    ];
    const typeCheckedList = type ? type.split(',') : [];
    const statusArray = status.split(',');
    const serviceLevelArray = service_level ? service_level?.split(',') : [];
    return [
      {
        title: 'Status',
        type: filterTypes.RADIO_BUTTON,
        selectedOptions: statusArray.includes(allStatus) ? allStatus : status,
        options: clientStatusOptions,
        onChange: e => {
          this.onFilterChange('status', [e.target.value]);
        },
        onApply: () => this.fetchClientsWithParams(),
      },
      {
        title: 'Type',
        type: filterTypes.CHECK_BOX,
        selectAllTitle: 'All types',
        selectedOptions: typeCheckedList,
        options: typeStatusOptions,
        onChange: values => {
          this.onFilterChange('type', values);
        },
        onCheckAllChange: () => this.handleAllFilterSelectChange('type', typeCheckedList, typeStatusOptions),
        onApply: () => this.fetchClientsWithParams(),
      },
      {
        title: 'Service Level',
        type: filterTypes.CHECK_BOX,
        selectAllTitle: 'All service levels',
        selectedOptions: serviceLevelArray,
        options: serviceLevelOptions,
        onChange: values => {
          this.onFilterChange('service_level', values);
        },
        onCheckAllChange: () =>
          this.handleAllFilterSelectChange('service_level', serviceLevelArray, serviceLevelOptions),
        onApply: () => this.fetchClientsWithParams(),
      },
    ];
  };

  getStatusCounts = () => {
    const { numPendingApproval, numPilot, numActive } = this.state;
    return [
      { title: 'Pending approval', num: numPendingApproval, hasBadge: true },
      { title: 'Pilot accounts', num: numPilot, hasBadge: false },
      { title: 'Active accounts', num: numActive, hasBadge: false },
    ];
  };

  render() {
    const { isLoading, searchParams, clients, numRecords } = this.state;
    const { t } = this.props;
    const { search } = searchParams;
    const statusCounts = this.getStatusCounts();
    const overview = this.getOverview();
    const activeClientTypes = this.getActiveClientTypeOverview(t);
    const checkboxFilters = this.getCheckBoxFilters(t);

    return (
      <Row type="flex" gutter={50} style={{ marginBottom: '86px' }}>
        <Col span={16}>
          {/* Header & Create button */}
          <Row type="flex" justify="space-between" style={{ marginBottom: '32px' }}>
            <Col>
              <Title level={3}>Clients</Title>
            </Col>
            <Col>
              {checkAccess(permissions.createClient) && (
                <Button type="v2-primary" icon="plus" onClick={this.goToCreateClient}>
                  Create client
                </Button>
              )}
            </Col>
          </Row>
          {/* Count card e.g. Leads, Active Accounts */}
          <CardWithCount loading={!clients} countList={statusCounts} />

          {/* Row for filtering, searching & ordering clients */}
          <Row type="flex" justify="space-between" align="bottom" style={{ marginBottom: '32px' }}>
            <Col span={12}>
              <Row type="flex" gutter={8} style={{ margin: '16px -4px' }}>
                {checkboxFilters.map(filter => (
                  <Col span={8} key={filter.title}>
                    {filter.type === filterTypes.CHECK_BOX ? (
                      <CheckboxFilter
                        showActionButtons
                        title={`${filter.title} ${
                          filter.selectedOptions.length > 0 ? `(${filter.selectedOptions.length})` : ''
                        }`}
                        options={filter.options}
                        selectedOptions={filter.selectedOptions}
                        selectAllTitle={filter.selectAllTitle}
                        onChange={filter.onChange}
                        onCheckAllChange={filter.onCheckAllChange}
                        indeterminate={
                          filter.selectedOptions.length > 0 && filter.selectedOptions.length < filter.options.length
                        }
                        allChecked={filter.selectedOptions.length === filter.options.length}
                        onApply={filter.onApply}
                        optionLabelField="name"
                      />
                    ) : (
                      <RadioButtonFilter
                        showActionButtons
                        title={filter.title}
                        options={filter.options}
                        selectedOptions={filter.selectedOptions}
                        onChange={filter.onChange}
                        onApply={filter.onApply}
                        optionLabelField="name"
                      />
                    )}
                  </Col>
                ))}
              </Row>
              <Row>
                <Input.Search
                  allowClear
                  placeholder="Search"
                  defaultValue={search}
                  onSearch={this.onSearch}
                  style={{ zIndex: 0 }}
                />
              </Row>
            </Col>
            {/* TODO: Sort by currently only has one option. Consider removing or expanding capability */}
            <Col>
              <Text type="secondary">Sort by</Text>
              <Select defaultValue="Date created" style={{ width: '144px', marginLeft: '8px' }}>
                <Option value="-created_date">Date created</Option>
              </Select>
            </Col>
          </Row>

          {/* Actual List */}
          <List
            itemLayout="vertical"
            pagination={{ ...getPaginationConfig(numRecords, this.onPageChange), current: searchParams.page || 1 }}
            loading={!clients || isLoading}
            dataSource={clients || []}
            renderItem={(client, index) => {
              return (
                <Row
                  key={index}
                  style={{ paddingBottom: '24x', borderBottom: '1px solid #00000017', paddingTop: '16px' }}
                >
                  <ClientSummaryCard client={client} />
                </Row>
              );
            }}
          />
        </Col>
        <Col span={8}>
          <Row style={{ marginBottom: '24px' }}>
            <DetailCard inline headerTitle={overview.title} contentList={overview.contentList} loading={!clients} />
          </Row>
          <DetailCard
            inline
            headerTitle={activeClientTypes.title}
            contentList={activeClientTypes.contentList}
            loading={!clients}
          />
        </Col>
      </Row>
    );
  }
}

export default withTranslation()(ClientListView);
