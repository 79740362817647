import base from './base';
import { endpoints } from '../config';

const approve = async id => {
  const response = await base.api.post(`${endpoints.leaveEntries}/${id}/approve/`);
  return response;
};

const reject = async (id, notes) => {
  const response = await base.api.post(`${endpoints.leaveEntries}/${id}/reject/`, { notes });
  return response;
};

const fetchLeaveEntries = async params => {
  const response = await base.api.get(`${endpoints.leaveEntries}/`, { params });
  return response;
};

export default {
  approve,
  reject,
  fetchLeaveEntries,
};
