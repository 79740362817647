/* eslint-disable no-lonely-if */
/* eslint-disable no-else-return */
import moment from 'moment-timezone';

const getMatchingShiftForTheDay = (shifts, date) => {
  // !IMPORTANT! If the two moments have different timezones, the timezone of the first moment will be used for the comparison.
  // https://momentjscom.readthedocs.io/en/latest/moment/05-query/02-is-same/
  return shifts.find(shift => moment(date).isSame(shift.start_time, 'day'));
};

export default {
  getMatchingShiftForTheDay,
};
