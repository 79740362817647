import React, { Component } from 'react';
import { Modal, Button, Select, Typography, Checkbox, Tag, Row } from 'antd';
import { cloneDeep, remove, orderBy } from 'lodash';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { recurrenceOptions, attendanceOffScheduleCode, TIME_FORMAT_24_HOUR } from '../../../../constants';
import datetimeUtils from '../../../utilities/datetimeUtils';
import { rrulestr } from 'rrule';

const { Text } = Typography;
class BulkAssignButtonModal extends Component {
  state = {
    selectedSchedule: undefined,
    showBulkAssignModal: false,
    selectedScheduleDays: recurrenceOptions.map(recurrence => recurrence.value),
    selectedScheduleValidDays: [],
  };

  handleClickBulkAssign = () => {
    this.setState({ showBulkAssignModal: true });
  };

  handleScheduleSelection = key => {
    const { schedules } = this.props;
    let rrule;
    let scheduleDays = [];
    // Get valid days for the selected schedule
    if (key !== '-1') {
      rrule = rrulestr(schedules.find(schedule => schedule.id === key).recurrences);
      scheduleDays = rrule.options.byweekday;
    }
    this.setState({ selectedSchedule: key, selectedScheduleValidDays: scheduleDays });
  };

  handleScheduleDaySelection = (checked, key) => {
    const selectedScheduleDays = cloneDeep(this.state.selectedScheduleDays);
    if (checked && !selectedScheduleDays.includes(key)) {
      this.setState({ selectedScheduleDays: [...selectedScheduleDays, key] });
    }
    if (!checked && selectedScheduleDays.includes(key)) {
      remove(selectedScheduleDays, value => value === key);
      this.setState({ selectedScheduleDays });
    }
  };

  handleBulkAssign = () => {
    const { selectedSchedule, selectedScheduleDays } = this.state;
    this.props.onBulkAssign(selectedSchedule, selectedScheduleDays);
    this.setState({
      showBulkAssignModal: false,
      selectedScheduleDays: recurrenceOptions.map(recurrence => recurrence.value),
      selectedSchedule: undefined,
    });
  };

  handleCancelBulkAssign = () => {
    this.setState({
      showBulkAssignModal: false,
      selectedScheduleDays: recurrenceOptions.map(recurrence => recurrence.value),
      selectedSchedule: undefined,
    });
  };

  render() {
    const { showBulkAssignModal, selectedScheduleDays, selectedSchedule, selectedScheduleValidDays } = this.state;
    const { weekdayDates, selectedWorkerCount, schedules, timezone, t } = this.props;
    const isPastWeek = datetimeUtils.getIsoWeekStart(moment(), timezone) > weekdayDates[0];

    return (
      <>
        <Button onClick={this.handleClickBulkAssign} disabled={selectedWorkerCount === 0 || isPastWeek}>
          {t('bulkAssignShifts')}
        </Button>
        {showBulkAssignModal && (
          <Modal
            title="Bulk assign shifts"
            visible={showBulkAssignModal}
            onOk={this.handleBulkAssign}
            okButtonProps={{ disabled: !selectedSchedule || selectedScheduleDays.length === 0 }}
            okType="v2-primary"
            okText={t('assign')}
            cancelText={t('Cancel')}
            onCancel={this.handleCancelBulkAssign}
            destroyOnClose
          >
            <div style={{ display: 'flex', flexDirection: 'column', paddingBottom: 8 }}>
              <Text strong>{t('bulkAssignShiftsTo', { selectedWorkerCount })}</Text>
              <Text>
                {weekdayDates[0].format('DD MMM')} - {weekdayDates[6].format('DD MMM YYYY')}
              </Text>
            </div>
            <div>{t('selectSchedule')}</div>
            <Select
              onChange={this.handleScheduleSelection}
              style={{ width: 250, paddingBottom: 8 }}
              placeholder="Select one"
            >
              {schedules.map(schedule => (
                <Select.Option value={schedule.id} key={schedule.id}>
                  {schedule.name} · {moment(schedule.start_time, 'hh:mm:ss').format(TIME_FORMAT_24_HOUR)}-
                  {moment(schedule.end_time, 'hh:mm:ss').format(TIME_FORMAT_24_HOUR)}
                </Select.Option>
              ))}
              <Select.Option key={attendanceOffScheduleCode}>{t('offDay')}</Select.Option>
            </Select>
            <div>{t('applyScheduleTo')}</div>
            <Checkbox.Group style={{ width: '100%', paddingBottom: 8 }}>
              <Row type="flex" className="v2-checkable-tag-wrapper">
                {orderBy(recurrenceOptions, ['value'], ['asc']).map((option, index) => {
                  return (
                    <Tag.CheckableTag
                      key={option.value}
                      checked={
                        selectedScheduleDays.includes(option.value) && selectedScheduleValidDays.includes(option.value)
                      }
                      onChange={checked => {
                        this.handleScheduleDaySelection(checked, option.value);
                      }}
                      style={{
                        padding: '5px 8px',
                        marginBottom: '5px',
                        width: '54px',
                        textAlign: 'center',
                        border: '1px solid #D9D9D9',
                      }}
                    >
                      {t(option.label)}
                    </Tag.CheckableTag>
                  );
                })}
              </Row>
            </Checkbox.Group>
            <Text type="secondary">{t('selectShiftDays')}</Text>
          </Modal>
        )}
      </>
    );
  }
}

export default withTranslation()(BulkAssignButtonModal);
