import styled from 'styled-components';
import React from 'react';
import { colors } from '../../../styles/colors';

const Container = styled.div`
  color: ${props => (props.disabled ? colors.white : colors.grey)};
  cursor: ${props => (props.disabled ? 'initial' : 'pointer')};
  font-size: 20px;
  padding: 10px;
`;

const RemoveButton = ({ ...props }) => <Container {...props}>X</Container>;

export default styled(RemoveButton)``;
