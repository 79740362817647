import React, { Component } from 'react';
import { Button } from 'antd';

class ButtonWithLoading extends Component {
  state = { loading: false };

  render() {
    const { status, initialText, postText, onClick } = this.props;
    return (
      <Button
        type="primary"
        loading={status ? false : this.state.loading}
        disabled={status}
        size="small"
        onClick={() => {
          this.setState({ loading: true });
          onClick();
        }}
      >
        {status ? postText : initialText}
      </Button>
    );
  }
}

export default ButtonWithLoading;
