import { Card, Descriptions } from 'antd';
import moment from 'moment';
import React from 'react';
import { DEFAULT_DATETIME_FORMAT, clientStatuses } from '../../../../../constants';
import { humanizeText } from '../../../../../utilities/textUtils';

export default ({ client }) => {
  return (
    <Card title="Account Information" size="small">
      {client && (
        <Descriptions colon={false} column={1} layout="vertical">
          <Descriptions.Item label="Client ID">{`#${client.id}`}</Descriptions.Item>
          <Descriptions.Item label="Industry">{humanizeText(client.industry)}</Descriptions.Item>
          <Descriptions.Item label="Status">{humanizeText(client.status)}</Descriptions.Item>
          {client.status === clientStatuses.PILOT && (
            <>
              <Descriptions.Item label="Pilot type">
                {`${humanizeText(client.pilot_type)} for first ${parseInt(client.pilot_amount, 10)} workers`}
              </Descriptions.Item>
              <Descriptions.Item label="Pilot start date">
                {client.pilot_start_date ? moment(client.pilot_start_date).format(DEFAULT_DATETIME_FORMAT) : '-'}
              </Descriptions.Item>
              <Descriptions.Item label="Pilot expiry date">
                {client.pilot_expiry_date ? moment(client.pilot_expiry_date).format(DEFAULT_DATETIME_FORMAT) : '-'}
              </Descriptions.Item>
            </>
          )}
          <Descriptions.Item label="Contract start date">
            {client.contract_start_date ? moment(client.contract_start_date).format(DEFAULT_DATETIME_FORMAT) : '-'}
          </Descriptions.Item>
          <Descriptions.Item label="Contract expiry date">
            {client.contract_expiry_date ? moment(client.contract_expiry_date).format(DEFAULT_DATETIME_FORMAT) : '-'}
          </Descriptions.Item>
          <Descriptions.Item label="Other comments">
            {client.account_comments ? client.account_comments : 'Not provided'}
          </Descriptions.Item>
          <Descriptions.Item label="Sales manager">
            {client.sales_manager ? client.sales_manager.name : '-'}
          </Descriptions.Item>
          <Descriptions.Item label="Account manager">
            {client.account_manager ? client.account_manager.name : '-'}
          </Descriptions.Item>
        </Descriptions>
      )}
    </Card>
  );
};
