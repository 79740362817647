import { debounce } from 'lodash';
import React from 'react';
import clientApi from '../../../services/clientApi';
import FilterWithSearch from './FilterWithSearch';

export default class ClientFilterButton extends React.Component {
  state = {
    loading: false,
    clientOptions: undefined,
  };

  constructor(props) {
    super(props);
    this.handleSearch = debounce(this.handleSearch, 500);
  }

  componentDidMount() {
    this.fetchClients();
  }

  fetchClients = async (search = undefined) => {
    this.setState({ loading: true, clientOptions: [] });
    const response = await clientApi.fetchClients({ search });

    this.setState({
      clientOptions: response.results.map(client => ({
        key: client.id,
        name: `#${client.id} ${client.name}`,
      })),
      loading: false,
    });
  };

  handleSelectChange = clients => {
    const selectedClients = clients.map(client => client.key);
    this.props.onSelectChange(selectedClients);
  };

  handleSearch = search => {
    this.fetchClients(search);
  };

  render() {
    const { clientOptions, loading } = this.state;
    const { selectedOptions } = this.props;

    return (
      <FilterWithSearch
        title={`Client ${selectedOptions && selectedOptions.length > 0 ? `(${selectedOptions.length})` : ''}`}
        options={clientOptions}
        onSelectChange={this.handleSelectChange}
        loading={loading}
        onSearch={this.handleSearch}
        placeholder="Search by client name or ID"
        optionLabelField="name"
        value={selectedOptions}
      />
    );
  }
}
