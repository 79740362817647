import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Icon, Popup } from 'semantic-ui-react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const CustomSpan = styled.span`
  cursor: pointer;
`;

export default class CopiableText extends React.Component {
  static propTypes = {
    text: PropTypes.string.isRequired,
  };

  state = {
    open: false,
  };

  handleOpen = () => {
    this.setState({ open: true });
    this.timeout = setTimeout(this.handleClose, 1000);
  };

  handleClose = () => {
    this.setState({ open: false });
    clearTimeout(this.timeout);
  };

  componentWillUnmount() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

  render() {
    const { text, ...props } = this.props;

    return (
      <Popup
        trigger={
          <CopyToClipboard onCopy={this.handleOpen} text={text} {...props}>
            <CustomSpan>
              {text} <Icon name="copy outline" />
            </CustomSpan>
          </CopyToClipboard>
        }
        on="click"
        open={this.state.open}
        content="Copied to clipboard!"
      />
    );
  }
}
