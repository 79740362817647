// TODO: Should be refactored with `DropdownButtons' component
import React from 'react';
import { Button, Dropdown, Menu, Modal } from 'antd';
import { partnerStatuses } from '../../../../constants';
import { checkAccess } from '../../../../shared/access/Access';
import { permissions } from '../../../../shared/access/accessConfig';
import { handleError } from '../../../../utilities/serviceUtils';
import partnerApi from '../../../../services/partnerApi';
import ResetPasswordModal from './ResetPasswordModal';
import SuspendWorkerModal from './SuspendWorkerModal';

class ManageWorkerActions extends React.Component {
  state = {
    resetPasswordModal: false,
    suspendWorkerModal: false,
  };

  toggleModal = (modalName, visible) => {
    this.setState({ [modalName]: visible });
  };

  handleReactivate = () => {
    const { partner, onUpdate } = this.props;

    Modal.confirm({
      title: 'Reactivate partner',
      content: 'You are about to reactivate this partner. Are you sure?',
      onOk: () =>
        partnerApi
          .reactivatePartner(partner)
          .then(onUpdate)
          .catch(error => handleError(error)),
    });
  };

  handleInitateDelete = () => {
    const { partner, onUpdate } = this.props;

    Modal.confirm({
      title: 'Permanently delete this account?',
      content:
        'This is not reversible. Before proceeding, ensure all work has been paid and the partner and any clients are aligned. If you proceed, the partner will be logged out immediately and the account deletion process will start after 7 days.',
      onOk: () =>
        partnerApi
          .initiateDeleteRequest(partner.id)
          .then(onUpdate)
          .catch(error => handleError(error)),
      okType: 'danger',
      okText: 'Delete account',
    });
  };

  render() {
    const { resetPasswordModal, suspendWorkerModal } = this.state;
    const { partner, onUpdate } = this.props;

    const menu = () => (
      <Menu>
        {checkAccess(permissions.editPartner) && (
          <Menu.Item
            onClick={() => {
              this.toggleModal('resetPasswordModal', true);
            }}
          >
            Reset password
          </Menu.Item>
        )}
        {![partnerStatuses.SUSPENDED, partnerStatuses.PENDING_DELETION].includes(partner.status) &&
          checkAccess(permissions.suspendPartner) && (
            <Menu.Item
              style={{ color: '#EB5757' }}
              onClick={() => {
                this.toggleModal('suspendWorkerModal', true);
              }}
            >
              Suspend account
            </Menu.Item>
          )}
        {partner.status !== partnerStatuses.PENDING_DELETION && checkAccess(permissions.approvePartner) && (
          <Menu.Item
            style={{ color: '#EB5757' }}
            onClick={() => {
              this.handleInitateDelete();
            }}
          >
            Delete partner account
          </Menu.Item>
        )}
        {partner.status === partnerStatuses.PENDING_DELETION && checkAccess(permissions.approvePartner) && (
          <Menu.Item
            onClick={() => {
              this.handleReactivate();
            }}
          >
            Reactivate account
          </Menu.Item>
        )}
      </Menu>
    );

    return (
      <>
        <Dropdown trigger={['click']} overlay={menu}>
          <Button icon="ellipsis" />
        </Dropdown>
        <ResetPasswordModal
          partner={partner}
          visible={resetPasswordModal}
          onCancel={() => this.toggleModal('resetPasswordModal', false)}
        />
        <SuspendWorkerModal
          partner={partner}
          visible={suspendWorkerModal}
          onClose={() => this.toggleModal('suspendWorkerModal', false)}
          onUpdate={onUpdate}
        />
      </>
    );
  }
}

export default ManageWorkerActions;
