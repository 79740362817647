import React, { useState } from 'react';
import moment from 'moment-timezone';
import { Row, Divider, Button, Col, Tooltip, Select, Typography, Modal, InputNumber, Alert } from 'antd';
import { withTranslation } from 'react-i18next';
import BulkAssignButtonModal from './BulkAssignButtonModal';
import CopyAssignmentButtonModal from './CopyAssignmentButtonModal';
import { unsetCode, assignmentStatuses, TIME_FORMAT_24_HOUR } from '../../../../constants';
import datetimeUtils from '../../../utilities/datetimeUtils';

const { Text } = Typography;

const DEFAULT_WORKER_MAX_WORK_DAYS = 6; // default to 6 days per week

const AutoAssignButtonModal = withTranslation()(({ t, disabled, onAutoAssign }) => {
  const [showModal, setShowModal] = useState(false);
  const [maxWorkDays, setMaxWorkDays] = useState(DEFAULT_WORKER_MAX_WORK_DAYS);
  const [hasError, setHasError] = useState(false);

  return (
    <>
      <Button
        disabled={disabled}
        onClick={() => {
          setShowModal(true);
        }}
        style={{ marginRight: 8 }}
      >
        {t('autoAssign')}
      </Button>
      <Modal
        title={<Text strong>{t('autoAssignShifts')}</Text>}
        visible={showModal}
        onOk={async () => {
          try {
            await onAutoAssign(maxWorkDays);
            setShowModal(false);
          } catch (error) {
            setHasError(true);
          }
        }}
        okButtonProps={{ disabled: hasError }}
        okType="v2-primary"
        okText={t('assign')}
        cancelText={t('Cancel')}
        onCancel={() => {
          setShowModal(false);
        }}
        destroyOnClose
      >
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Text style={{ whiteSpace: 'pre-wrap' }}>{t('autoAssignDescription')}</Text>

          <div style={{ marginTop: 10, display: 'flex', flexDirection: 'column' }}>
            <Text style={{ fontWeight: 400 }}>{t('maxWorkingDaysPerWeek')}</Text>
            <InputNumber
              min={1}
              max={7}
              value={maxWorkDays}
              onChange={value => {
                setMaxWorkDays(value);
                setHasError(false); // extract this into a 'reset' function
              }}
              style={{ minWidth: 256 }}
            />
            <Text type="secondary" style={{ marginTop: 10 }}>
              {t('maxWorkingDaysPerWeekHint')}
            </Text>
          </div>
          {hasError && <Alert message={t('autoAssignError')} type="error" showIcon />}
        </div>
      </Modal>
    </>
  );
});

const AssignmentFilterAndAction = ({
  t,
  loading,
  timezone,
  schedules,
  weekdayDates,
  employments,
  publishCount,
  selectedWorkerCount,
  scheduleFilterValue,
  statusFilterValue,
  searchFilter,
  allowAutoAssign,
  onPublish,
  onCopyShift,
  onBulkAssign,
  onScheduleChange,
  onStatusChange,
  onSearchChange,
  onAutoAssign,
}) => {
  return (
    <Row>
      <Row type="flex" style={{ justifyContent: 'space-between' }}>
        <Col>
          <Text>{t('schedule')}</Text>
          <Select
            style={{ width: '160px', paddingLeft: 8 }}
            onChange={onScheduleChange}
            optionLabelProp="label"
            value={scheduleFilterValue}
            disabled={loading}
          >
            <Select.Option key={unsetCode} value={unsetCode} label={t('allSchedules')}>
              {t('allSchedules')}
            </Select.Option>
            {schedules.map(schedule => {
              const startTime = moment.tz(schedule.start_date, timezone);
              const endTime = datetimeUtils.getEndTimeFromStartTimeAndDuration(startTime, schedule.duration);
              return (
                <Select.Option key={schedule.id} value={schedule.id} label={schedule.name}>
                  <div>
                    <Text strong style={{ fontSize: 14 }}>
                      {schedule.name}
                    </Text>
                  </div>
                  <div>
                    <Text>
                      {moment(startTime).format(TIME_FORMAT_24_HOUR)}-{moment(endTime).format(TIME_FORMAT_24_HOUR)}
                    </Text>
                  </div>
                </Select.Option>
              );
            })}
          </Select>

          <Text style={{ paddingLeft: 24 }}>{t('status')}</Text>
          <Select
            style={{ width: '160px', paddingLeft: 8 }}
            defaultValue={unsetCode}
            onChange={onStatusChange}
            value={statusFilterValue}
            disabled={loading}
          >
            <Select.Option key={unsetCode} value={unsetCode}>
              {t('allStatus')}
            </Select.Option>
            <Select.Option key={assignmentStatuses.UNCONFIRMED} value={assignmentStatuses.UNCONFIRMED}>
              {t(assignmentStatuses.UNCONFIRMED)}
            </Select.Option>
            <Select.Option key={assignmentStatuses.ABSENT} value={assignmentStatuses.ABSENT}>
              {t(assignmentStatuses.ABSENT)}
            </Select.Option>
          </Select>
        </Col>
        <Col>
          <Select
            allowClear
            mode="multiple"
            style={{ width: '360px' }}
            placeholder={t('searchWorker')}
            onChange={onSearchChange}
            optionLabelProp="label"
            optionFilterProp="children"
            value={searchFilter}
            disabled={loading}
          >
            {employments.map(employment => (
              <Select.Option
                key={employment.partner.id}
                value={employment.partner.id}
                label={`${employment.partner.first_name} ${employment.partner.last_name}`}
              >
                {`${employment.partner.first_name} ${employment.partner.last_name} (${employment.partner
                  .identification_number || ''})`}
              </Select.Option>
            ))}
          </Select>
        </Col>
      </Row>
      <Divider />
      <Row type="flex" style={{ justifyContent: 'space-between' }}>
        <Col>
          <BulkAssignButtonModal
            weekdayDates={weekdayDates}
            selectedWorkerCount={selectedWorkerCount}
            schedules={schedules}
            onBulkAssign={onBulkAssign}
            timezone={timezone}
          />
        </Col>
        <Col>
          <AutoAssignButtonModal disabled={!allowAutoAssign} onAutoAssign={onAutoAssign} />
          <Tooltip arrowPointAtCenter title="Unpublished shifts cannot be copied">
            <span style={{ marginRight: 8 }}>
              <CopyAssignmentButtonModal
                weekdayDates={weekdayDates}
                disabled={publishCount || loading}
                timezone={timezone}
                onCopy={onCopyShift}
              />
            </span>
          </Tooltip>
          <Button onClick={onPublish} type="v2-primary" disabled={!publishCount}>
            {t('publish')} ({publishCount || 0})
          </Button>
        </Col>
      </Row>
    </Row>
  );
};

export default withTranslation()(AssignmentFilterAndAction);
