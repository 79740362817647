import * as Sentry from '@sentry/browser';
import { signOut } from 'firebase/auth';
import { API_ROOT } from '../config';
import base from './base';

const fetchUser = async () => {
  const response = await base.api.get(`${API_ROOT}/user/`);
  return response;
};

const logout = () => {
  // Clear the redux state after user logs out. Will still need a page reload for it to take effect
  localStorage.clear();
  window.location.reload();
  signOut(); // Sign out from Firebase
};

const refreshToken = async firebaseUser => {
  const token = await firebaseUser.getIdToken();
  if (token) {
    localStorage.setItem('token', token);
  }
};

const login = async firebaseUser => {
  const token = await firebaseUser.getIdToken();
  // Set token into local storage
  localStorage.setItem('token', token);

  // Fetch user info
  const user = await fetchUser();
  if (!user.operator) {
    logout();
    return;
  }

  // Setup third party tracking libraries
  if (window.FS) {
    window.FS.identify(user.pk, {
      username: user.username,
      email: user.username,
      role: user.operator.role,
    });
  }
  Sentry.configureScope(scope => {
    scope.setUser({
      id: user.pk,
      username: user.username,
      email: user.username,
      role: user.operator.role,
    });
  });

  return { token, operator: user.operator };
};

const forgotPassword = async email => {
  const response = await base.api.post(`${API_ROOT}/auth/password/reset/`, {
    email,
  });
  return response;
};

const resetPassword = async (id, token, new_password1, new_password2) => {
  const response = await base.api.post(`${API_ROOT}/auth/password/reset/confirm/`, {
    uid: id,
    token,
    new_password1,
    new_password2,
  });
  return response;
};

export default {
  login,
  logout,
  fetchUser,
  forgotPassword,
  resetPassword,
  refreshToken,
};
