import base from './base';
import { endpoints } from '../config';

const fetchCountries = async () => {
  const response = await base.api.get(`${endpoints.countries}/`);
  return response;
};

const fetchCities = async countryId => {
  const response = await base.api.get(`${endpoints.countries}/${countryId}/cities/`);
  return response;
};

const fetchAreas = async (countryId, cityId) => {
  const response = await base.api.get(`${endpoints.countries}/${countryId}/cities/${cityId}/areas/`);
  return response;
};

const fetchAreasList = async params => {
  const response = await base.api.get(`${endpoints.areas}/`, {
    params,
  });
  return response;
};

export default {
  fetchCountries,
  fetchCities,
  fetchAreas,
  fetchAreasList,
};
