import React from 'react';
import { Form, Input, Modal, notification } from 'antd';
import managerApi from '../../../../../services/managerApi';
import { handleError } from '../../../../../utilities/serviceUtils';

class ManagerResetPasswordModal extends React.Component {
  state = {
    loading: false,
  };

  handleSubmit = () => {
    const { manager, form } = this.props;

    this.setState({ loading: true });
    form.validateFieldsAndScroll((errors, values) => {
      if (!errors) {
        managerApi
          .setPassword(manager.id, values.password)
          .then(() => {
            notification.success({
              message: 'Reset password successfully',
            });
            this.handleClose();
          })
          .catch(handleError);
      }
    });
  };

  handleClose = () => {
    this.props.form.resetFields();
    this.props.onClose();
    this.setState({ loading: false });
  };

  render() {
    const { visible, form, manager } = this.props;
    const { getFieldDecorator, getFieldValue } = form;

    return (
      <Modal
        title={`Reset Password for ${manager ? manager.name : 'Manager'}`}
        visible={visible}
        confirmLoading={this.state.loading}
        onOk={this.handleSubmit}
        onCancel={this.handleClose}
        okText="Submit"
      >
        <Form onSubmit={this.handleSubmit}>
          <Form.Item hasFeedback label="Password">
            {getFieldDecorator('password', {
              rules: [
                {
                  required: true,
                  message: 'Please enter a password',
                },
                {
                  min: 7,
                  message: 'Please enter at least 7 characters',
                },
              ],
            })(<Input type="password" />)}
          </Form.Item>
          <Form.Item hasFeedback label="Password (Confirm)">
            {getFieldDecorator('password_confirm', {
              rules: [
                {
                  required: true,
                  message: 'Please enter the password again',
                },
                {
                  validator: (rule, value, callback) => {
                    if (value && value !== getFieldValue('password')) {
                      callback('Passwords do not match');
                    } else {
                      callback();
                    }
                  },
                },
              ],
            })(<Input type="password" />)}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default Form.create()(ManagerResetPasswordModal);
