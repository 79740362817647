import React, { useState } from 'react';
import { Button } from 'antd';
import staffRequestApi from '../../../../services/staffRequestApi';
import { fetchAll } from '../../../utilities/apiUtils';
import employmentApi from '../../../../services/employmentApi';
import { employmentStatuses } from '../../../../constants';

const fetchPartnerLists = async staffRequestId => {
  const staffRequest = await staffRequestApi.fetchStaffRequest(staffRequestId);

  const staffRequestEmployments = await fetchAll(employmentApi.fetchEmployments, {
    location: staffRequest.location.id,
    position: staffRequest.position.id,
    status: employmentStatuses.ACTIVE,
    lean: true,
  });

  const activePartnerIds = staffRequestEmployments.map(employment => employment.partner.id);
  return activePartnerIds;
};

const ContractDownload = ({ staffRequestId }) => {
  const [isExporting, updateExporting] = useState(false);

  const exportContract = async () => {
    updateExporting(true);
    const partnerList = await fetchPartnerLists(staffRequestId);
    staffRequestApi.exportContracts(staffRequestId, partnerList).then(data => {
      updateExporting(false);
    });
  };

  return (
    <span>
      <Button loading={isExporting} onClick={exportContract}>
        Download Contracts
      </Button>
    </span>
  );
};

export default ContractDownload;
