import React from 'react';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';
import { colors } from '../../styles/colors';
import { fontO18r } from '../../styles/fonts';

const CustomIcon = styled(Icon)`
  &.inverted.bordered.icon,
  &.inverted.circular.icon {
    background: ${colors.grey}!important;
  }
`;

const Message = styled.div`
  ${fontO18r};
`;

const Container = styled.div`
  text-align: center;
  color: ${colors.grey};
  padding: 60px;

  ${CustomIcon} {
    font-size: 80px;
  }

  ${Message} {
    margin-top: 30px;
  }
`;

export default ({ children, ...props }) => (
  <Container {...props}>
    <CustomIcon circular inverted name="inbox" />
    {children ? <Message>{children}</Message> : <Message>No results to display</Message>}
  </Container>
);
