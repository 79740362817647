// TODO: Move to helpster-components
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, Typography, Icon, Row, Col, Button, Alert, Tooltip } from 'antd';

import StatusTag from '../../../shared/components/StatusTag';
import {
  partnerStatuses,
  workerSuspensionReasons,
  onboardingRejectionReasons,
  NOT_PROVIDED,
  genderOptions,
} from '../../../../constants';

import routes from '../../../../routes';
// V1 folders
import { permissions } from '../../../../shared/access/accessConfig';
import { checkAccess } from '../../../../shared/access/Access';

const { Paragraph, Text, Title } = Typography;
const notProvidedIndicator = <Text style={{ color: '#BFBFBF' }}>Not provided</Text>;

const ProfileCard = ({
  history,
  imgSrc,
  status,
  name,
  age,
  gender,
  idNumber,
  birthday,
  location,
  phone,
  email,
  notes,
  lastActive,
  daysWorked,
  noShows,
  ratings,
  rejectionReason,
  suspensionReason,
  verifiedDate,
  referredBy,
  handleVerify,
  handleReject,
  extra,
}) => {
  const [profileImageError, setProfileImageError] = useState(false);
  const lead = status === partnerStatuses.LEAD;
  const rejected = status === partnerStatuses.REJECTED;
  const suspended = status === partnerStatuses.SUSPENDED;
  const disabled = status === partnerStatuses.DISABLED;
  const genderText = genderOptions.find(genderOption => genderOption.value === gender);

  const partnerData = [
    { icon: 'user', description: `${age || '[Age]'} · ${genderText?.label || '[Gender]'}` },
    {
      icon: 'idcard',
      description: idNumber || <Text style={{ color: '#BFBFBF' }}>Not provided</Text>,
    },
    { icon: 'gift', description: birthday !== NOT_PROVIDED ? birthday : notProvidedIndicator },
    { icon: 'environment', description: location !== NOT_PROVIDED ? location : notProvidedIndicator },
    { icon: 'phone', description: phone !== NOT_PROVIDED ? phone : notProvidedIndicator },
    { icon: 'mail', description: email !== NOT_PROVIDED ? email : notProvidedIndicator },
    {
      icon: 'mobile',
      description:
        lastActive !== NOT_PROVIDED ? <span style={{ marginBottom: '16px' }}>{lastActive}</span> : notProvidedIndicator,
    },
  ];

  const partnerAttributes = [
    {
      header: 'DAYS WORKED',
      icon: 'schedule',
      content: <Text style={{ color: '#1890FF' }}>{daysWorked || '-'}</Text>,
    },
    {
      header: 'NO SHOWS',
      icon: 'warning',
      content: <Text style={{ color: '#1890FF' }}>{noShows || '-'}</Text>,
    },
    {
      header: 'RECOMMENDED',
      icon: 'like',
      content: <Text style={{ color: '#1890FF' }}>{ratings || '-'}</Text>,
    },
    {
      header: 'VERIFIED WORKMATE',
      icon: 'safety-certificate',
      content: (
        // fontSize has to be explicitly set for this section. Everything else is 20px
        <Text style={verifiedDate ? { fontSize: '14px' } : { fontSize: '14px', color: '#BFBFBF' }}>
          {verifiedDate || 'Not verified'}
        </Text>
      ),
    },
  ];
  if (referredBy) {
    partnerAttributes.push({
      header: 'SOURCE',
      icon: 'usergroup-add',
      iconTheme: 'outlined',
      iconStyle: { color: '#1890ff' },
      content: (
        <>
          <Text style={{ fontSize: '14px' }}>Referred</Text>{' '}
          <Tooltip
            title={
              <>
                Referred by{' '}
                <Link
                  to={routes.partnerDetail.replace(':id', referredBy.id)}
                  onClick={() => history.push(routes.partnerDetail.replace(':id', referredBy.id))}
                >
                  {referredBy.name}
                </Link>
              </>
            }
          >
            <Icon type="info-circle" theme="filled" />
          </Tooltip>
        </>
      ),
    });
  }

  const getRejectionMessage = rejectionReason => {
    if (rejectionReason === 'others') {
      return {
        title: 'Other reasons',
        description: notes,
      };
    }
    const otherReason = {
      title: 'N/A',
      description: 'No reason provided',
    };

    return (
      onboardingRejectionReasons.find(reason => {
        return reason.value === rejectionReason;
      }) || otherReason
    );
  };

  const getSuspensionMessage = suspensionReason => {
    if (suspensionReason === 'first_day_no_show') {
      return {
        title: 'First day no show',
        description: 'Worker was suspended because of first day no show',
      };
    }
    const otherReason = {
      title: 'N/A',
      description: 'No reason provided',
    };
    return (
      workerSuspensionReasons.find(reason => {
        return reason.value === suspensionReason;
      }) || otherReason
    );
  };

  const ProfileAlert = ({ message, description, suspensionNotes = false }) => (
    <Alert
      message={
        <Text strong style={{ fontSize: '14px' }}>
          {message}
        </Text>
      }
      description={
        <>
          <Row>
            <Text style={{ fontSize: '12px' }}>{description}</Text>
          </Row>
          {suspensionNotes && suspensionNotes !== 'Not provided' && (
            <Row>
              <Text style={{ fontSize: '12px' }}>Notes: {suspensionNotes}</Text>
            </Row>
          )}
        </>
      }
      type="error"
      style={{ marginBottom: '16px' }}
    />
  );

  const workerSuspensionReason = getSuspensionMessage(suspensionReason);
  const workerRejectionReason = getRejectionMessage(rejectionReason);

  return (
    <Card
      id="profile-card"
      style={{ width: '373px' }}
      bodyStyle={{ padding: '0 24px 24px 24px' }}
      cover={
        !imgSrc || profileImageError ? (
          <div
            style={{
              width: '324px',
              height: '324px',
            }}
          />
        ) : (
          <img
            alt={name}
            src={imgSrc}
            style={{
              width: '324px',
              height: '324px',
              margin: '24px 24px 0 24px',
              objectFit: 'cover',
            }}
            onError={() => {
              setProfileImageError(true);
            }}
          />
        )
      }
    >
      <Title level={3} style={{ marginBottom: '8px', marginTop: '16px' }}>
        {name}
      </Title>
      <StatusTag style={{ marginBottom: '16px' }} status={status} />
      {(rejected || lead || suspended || disabled) && (
        <>
          {rejected && (
            <ProfileAlert
              message={`Rejection Reason: ${workerRejectionReason.title}`}
              description={workerRejectionReason.description}
            />
          )}
          {suspended && (
            <ProfileAlert
              message={`Suspension Reason: ${workerSuspensionReason.title}`}
              description={workerSuspensionReason.description}
              suspensionNotes={notes}
            />
          )}
          <Row type="flex" gutter={8} style={{ marginBottom: '32px' }}>
            {checkAccess(permissions.approvePartner) && (
              <Col span={12}>
                <Button block type="primary" icon="check" onClick={handleVerify}>
                  Verify
                </Button>
              </Col>
            )}
            {checkAccess(permissions.rejectPartner) && (
              <Col span={12}>
                <Button
                  block
                  icon="close"
                  disabled={rejected || suspended || disabled}
                  onClick={handleReject}
                  style={lead ? { backgroundColor: '#BFBFBF', color: '#FF1111' } : {}}
                >
                  Reject
                </Button>
              </Col>
            )}
          </Row>
        </>
      )}

      {/* Map partner data e.g. age */}
      {partnerData.map(({ icon, description }, index) => (
        <Paragraph key={index} style={{ marginBottom: '8px' }}>
          <Icon type={icon} style={{ color: '#BFBFBF', marginRight: '4px' }} /> {description}
        </Paragraph>
      ))}

      {/* Map partner attributes e.g. days worked */}
      <Row type="flex" gutter={34} style={{ margin: '24px 0px 8px 0px' }}>
        {partnerAttributes.map(({ header, icon, iconTheme, iconStyle, content }, index) => (
          <Col key={index} style={{ paddingLeft: 0, marginBottom: '16px' }}>
            <section>
              <Row>
                <Text type="secondary" style={{ fontSize: '12px' }}>
                  {header}
                </Text>
              </Row>
              <Row type="flex" align="middle" style={{ fontSize: '20px' }} gutter={4}>
                <Col>
                  <Icon type={icon} theme={iconTheme || 'twoTone'} style={iconStyle} />
                </Col>
                <Col>{content}</Col>
              </Row>
            </section>
          </Col>
        ))}
      </Row>

      {/* Render any additional React nodes at the bottom of the card e.g. Edit button, dropdown menus... etc */}
      {extra}
    </Card>
  );
};

export default ProfileCard;
