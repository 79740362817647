import { Avatar, Col, List, Progress, Row, Typography, Button, Dropdown, Menu, Icon, Tooltip } from 'antd';
import moment from 'moment-timezone';
import React from 'react';
import { Link } from 'react-router-dom';
import { DATE_FORMAT } from '../../../../constants';
import routes from '../../../../routes';
import StatusTag from '../../../shared/components/StatusTag';
import { checkAccess } from '../../../../shared/access/Access';
import { permissions } from '../../../../shared/access/accessConfig';
import { colors } from '../../../../styles/colors';

const { Text } = Typography;

export default ({ staffRequests, loading, ...props }) => {
  return (
    <List
      {...props}
      itemLayout="vertical"
      loading={loading}
      dataSource={staffRequests || []}
      renderItem={staffRequest => {
        const {
          id,
          client,
          location,
          position,
          title,
          status,
          start_time: startTime,
          end_time: endTime,
          created_date: createdDate,
          confirmed_partners: confirmedPartners,
          staff_required: staffRequired,
          applicant_tasks: applicantTasks,
          qr_code_clock_in: qrCodeClockIn,
          shifts_enabled: shiftsEnabled,
          auto_hire: autoHire,
        } = staffRequest;
        const timezone = location.address.area.city.timezone;

        const fillRate = (confirmedPartners.count / staffRequired) * 100;
        let progressStatus = 'normal';
        if (fillRate === 0) {
          progressStatus = 'exception';
        } else if (fillRate >= 100) {
          progressStatus = 'success';
        }

        const endDate = endTime ? moment.tz(endTime, timezone).format(DATE_FORMAT) : 'Permanent';

        return (
          <List.Item>
            <Row gutter={8}>
              <Col span={4}>
                <div>
                  <Link to={routes.staffRequestDetail.replace(':id', id)}>
                    <Text strong>{`SR #${id}`}</Text>
                    {qrCodeClockIn && (
                      <Tooltip title="QR code scanning">
                        <Icon type="qrcode" style={{ marginLeft: 4, color: colors.functionalSuccess }} />
                      </Tooltip>
                    )}
                    {shiftsEnabled && (
                      <Tooltip title="Shift management">
                        <Icon type="calendar" style={{ marginLeft: 4, color: colors.functionalSuccess }} />
                      </Tooltip>
                    )}
                    {autoHire && (
                      <Tooltip title="Auto hiring">
                        <Icon type="user-add" style={{ marginLeft: 4, color: colors.functionalSuccess }} />
                      </Tooltip>
                    )}
                  </Link>
                </div>
                <div>
                  <Text type="secondary">{`Created ${moment(createdDate).format(DATE_FORMAT)}`}</Text>
                </div>
              </Col>
              <Col span={6}>
                <Link to={routes.clientsDetail.replace(':id', client && client.id)}>
                  <div style={{ display: 'flex' }}>
                    <Avatar shape="square" size={42} src={client && client.logo} />
                    <div style={{ paddingLeft: 16 }}>
                      <div>
                        <Text style={{ color: 'black' }}>{client && client.name}</Text>
                      </div>
                      <div>
                        <Text type="secondary">{location && location.name}</Text>
                      </div>
                    </div>
                  </div>
                </Link>
              </Col>
              <Col span={5}>
                <div>
                  <Text style={{ color: 'black' }}>{position && position.name}</Text>
                </div>
                <div>
                  <Text type="secondary">{title}</Text>
                </div>
              </Col>
              <Col span={5}>
                <div>
                  <Text style={{ color: 'black' }}>
                    {`${moment.tz(startTime, timezone).format(DATE_FORMAT)} – ${endDate}`}
                  </Text>
                </div>
                <div>
                  <StatusTag status={status} style={{ marginRight: 8 }} />
                </div>
              </Col>
              <Col span={3}>
                <div>
                  <Text style={{ color: 'black' }}>{`${confirmedPartners.count} of ${staffRequired} hired`}</Text>
                </div>
                <div style={{ alignItems: 'center' }}>
                  <div style={{ width: 40, paddingBottom: 4 }}>
                    <Progress
                      size="small"
                      percent={fillRate > 0 ? fillRate : 4}
                      showInfo={false}
                      status={progressStatus}
                    />
                  </div>
                  {applicantTasks.count > 0 && <Text type="secondary">{`${applicantTasks.count} new applicants`}</Text>}
                </div>
              </Col>
              <Col span={1}>
                <Dropdown
                  overlay={
                    <Menu>
                      {checkAccess(permissions.createStaffRequest) && (
                        <Menu.Item key="duplicate">
                          {/* Link location state https://reacttraining.com/react-router/web/api/location */}
                          <Link to={{ pathname: routes.createStaffRequest, state: { replicateId: id } }}>
                            Duplicate
                          </Link>
                        </Menu.Item>
                      )}
                    </Menu>
                  }
                  placement="bottomRight"
                >
                  <Button>
                    <Icon type="ellipsis" />
                  </Button>
                </Dropdown>
              </Col>
            </Row>
          </List.Item>
        );
      }}
    />
  );
};
