/* eslint-disable import/prefer-default-export */
export const colors = {
  white: '#FFFFFF',
  lightGrey: '#F5F5F5',
  grey: '#CBCBCB',
  darkGrey: '#999999',
  red: '#FF1111',
  blue: '#3B86FF',
  orange: '#FF8474',
  green: '#00C360',
  workmateGreen: '#69E4A6',
  yellow: '#FFBD00',
  black: '#222222',
  absoluteBlack: '#000000',
  functionalSuccess: '#00CA7F',
  disabled: '#BFBFBF',
  overlay: '#F7F7F7',
  functionalWarning: '#FAAD14',
  tagYellow: '#FFFBE6',
  tagGreen: '#E6FFF1',
  infoBackgroundBlue: '#E6F7FF',
  infoHighlightBlue: '#1890FF',
  backgroundGrey: '#F0F0F0',
  backgroundRed: '#FFF1F0',
  componentBorder: '#D9D9D9',
  functionalError: '#FF4D4F',
  functionalApproved: '#6665DD',
  brandGreen: '#69E4A6',
};
