import React from 'react';
import { Card, Row, Col, Button, Typography, message, Tooltip, Icon } from 'antd';
import SrSelectorModal from './components/SrSelectorModal';
import ChannelList from './components/ChannelList';
import ChannelChatSection from './components/ChannelChatSection';

import { colors } from '../../../styles/colors';

const { Title, Text } = Typography;

class BroadcastView extends React.Component {
  state = {
    selectedChannel: undefined,
    showSrSelectorModal: false,
    chatClientLoaded: false,
  };

  handleChannelChange = selectedChannel => {
    // Selecting a channel before our client is ready causes issues with fetching channels.
    if (!this.state.chatClientLoaded) {
      message.info('Chat is still loading. Please wait before selecting a channel');
      return;
    }
    this.setState({ selectedChannel });
  };

  onChatClientLoaded = () => {
    this.setState({ chatClientLoaded: true });
  };

  render() {
    const { operator } = this.props;
    const { selectedChannel, showSrSelectorModal } = this.state;
    return (
      <>
        <Card bordered={false} bodyStyle={{ padding: 0 }}>
          {/* Header & Create button */}
          <Row type="flex" justify="space-between" style={{ marginBottom: '24px' }}>
            <Col>
              <Title level={2} style={{ marginBottom: 0 }}>
                Broadcast
                <Tooltip
                  placement="right"
                  title={
                    <Text
                      style={{
                        color: colors.white,
                      }}
                    >
                      You can broadcast messages for{' '}
                      <Text strong style={{ color: colors.white }}>
                        applicants
                      </Text>{' '}
                      and{' '}
                      <Text strong style={{ color: colors.white }}>
                        employees
                      </Text>{' '}
                      of an active staff request in seperate channels.
                    </Text>
                  }
                >
                  <Icon
                    type="info-circle"
                    style={{
                      fontSize: '14px',
                      verticalAlign: 'middle',
                      color: '#108EE9',
                      paddingLeft: '12px',
                    }}
                    theme="filled"
                  />
                </Tooltip>
              </Title>
            </Col>
            <Col>
              <Button
                type="v2-primary"
                icon="plus"
                onClick={() => {
                  this.setState({ showSrSelectorModal: true });
                }}
              >
                Open channel
              </Button>
            </Col>
          </Row>
          {/* Chat cards */}
          <Row gutter={24}>
            <Col span={8}>
              <ChannelList
                selectedChannel={selectedChannel}
                handleChannelChange={channel => {
                  this.handleChannelChange(channel);
                }}
              />
            </Col>
            <Col span={16}>
              <ChannelChatSection
                channel={selectedChannel}
                operator={operator}
                onChatClientLoaded={this.onChatClientLoaded}
              />
            </Col>
          </Row>
        </Card>
        <SrSelectorModal
          visible={showSrSelectorModal}
          onClose={() => this.setState({ showSrSelectorModal: false })}
          handleChannelOpen={channel => {
            this.handleChannelChange(channel);
          }}
        />
      </>
    );
  }
}

export default BroadcastView;
