import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Modal, Typography, Select, Button } from 'antd';
import { connect } from 'react-redux';

import { colors } from '../../../../styles/colors';

const LocationPositionUpdateModal = ({ t, visible, confirmLoading, closeModal, onConfirm, locations, positions }) => {
  const [selectedLocation, setLocation] = useState(undefined);
  const [selectedPosition, setPosition] = useState(undefined);

  const shouldDisableAssignButton = !selectedLocation && !selectedPosition;

  return (
    <Modal
      destroyOnClose
      afterClose={() => {
        // reset values when closed
        setLocation(undefined);
        setPosition(undefined);
      }}
      style={{ top: '20px' }}
      title={<Typography.Text style={{ fontWeight: 600, fontSize: 20 }}>{t('setLocationOrPosition')}</Typography.Text>}
      visible={visible}
      confirmLoading={confirmLoading}
      footer={[
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={closeModal}>{t('Cancel')}</Button>
          <Button
            disabled={shouldDisableAssignButton}
            style={{
              backgroundColor: !shouldDisableAssignButton && colors.brandGreen,
              color: !shouldDisableAssignButton && colors.white,
              fontWeight: 600,
            }}
            onClick={() => onConfirm(selectedLocation, selectedPosition)}
          >
            {t('assign')}
          </Button>
        </div>,
      ]}
      onCancel={closeModal}
    >
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography.Text style={{ fontWeight: 600 }}>{t('assignYourWorkers')}</Typography.Text>
        <Typography.Text>{t('assignYourWorkersDescription')}</Typography.Text>
      </div>

      <div style={{ marginTop: 10, display: 'flex', flexDirection: 'column' }}>
        <Typography.Text>{t('location')}</Typography.Text>
        <Select
          placeholder={t('selectLocationPlaceholder')}
          onSelect={option => setLocation(option)}
          style={{ width: 280 }}
        >
          {locations.map(position => (
            <Select.Option key={position.id}>{position.name}</Select.Option>
          ))}
        </Select>

        <Typography.Text style={{ marginTop: 12 }}>{t('position')}</Typography.Text>
        <Select
          placeholder={t('selectPositionPlaceholder')}
          onSelect={option => setPosition(option)}
          style={{ width: 280 }}
        >
          {positions.map(location => (
            <Select.Option key={location.id}>{location.name}</Select.Option>
          ))}
        </Select>
      </div>
    </Modal>
  );
};

const mapStateToProps = state => ({
  positions: state.global.positions,
});

export default connect(
  mapStateToProps,
  undefined,
)(withTranslation()(LocationPositionUpdateModal));
