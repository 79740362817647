import React from 'react';
import styled from 'styled-components';
import { Placeholder } from 'semantic-ui-react';
import Card from '../../../../shared/components/Card';
import SchedulesTable from '../SchedulesTable';

const CustomSchedulesTable = styled(SchedulesTable)``;

const Content = styled.div`
  overflow: auto;
`;

export default ({ schedules, timezone, ...props }) => (
  <Card {...props}>
    <Card.Header>Schedules</Card.Header>
    <Content>
      {/* {!isUserTimezone(timezone) && ( */}
      {/* <Warning> */}
      {/* <Icon name={'warning circle'}/> */}
      {/* You are in a different timezone from the SR */}
      {/* </Warning> */}
      {/* )} */}
      {schedules ? (
        <>
          {schedules.length > 0 ? (
            <CustomSchedulesTable timezone={timezone} schedules={schedules} />
          ) : (
            <div>No schedules</div>
          )}
        </>
      ) : (
        <Placeholder>
          <Placeholder.Line />
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder>
      )}
    </Content>
  </Card>
);
