import { Button, Col, Divider, Input, List, message, Row, Select, Typography, Dropdown, Menu, Tooltip } from 'antd';
import moment from 'moment';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { employmentStatuses, DATE_FORMAT, employmentCancellationReasons } from '../../../../constants';
import employmentApi from '../../../../services/employmentApi';
import applicationApi from '../../../../services/applicationApi';
import staffRequestApi from '../../../../services/staffRequestApi';
import CardWithCount from '../../../shared/components/CardWithCount';
import CheckboxFilter from '../../../shared/components/CheckboxFilter';
import StatusTag from '../../../shared/components/StatusTag';
import { colors } from '../../../../styles/colors';

import getPaginationConfig from '../../../utilities/getPaginationConfig';
import textUtils from '../../../utilities/textUtils';

import EmploymentCancellationModal from './EmploymentCancellationModal';
import ForceHireApplicationModal from '../Modals/ForceHireApplicationModal';
import WorkerInfoItem from './WorkerInfoItem';
import ContractDownload from './ContractDownload';
import { checkAccess } from '../../../../shared/access/Access';
import { permissions } from '../../../../shared/access/accessConfig';

const { Text, Paragraph } = Typography;
const { Option } = Select;

class StaffRequestEmployeesList extends React.Component {
  state = {
    isLoading: false,
    searchParams: {
      page: 1,
      staff_request: this.props.staffRequestId,
      status: `${Object.values(employmentStatuses).join(',')}`,
      ordering: 'created_date',
    },
    numActive: 0,
    numCancelled: 0,
    numEnded: 0,
    numRecords: 0,
    employments: null,
    hasFetchedEmployments: false,
    showEmploymentCancellationModal: false,
    showForceHireModal: false,
    selectedEmployment: null,
    selectedApplication: undefined, // Use for force hire
  };

  async componentDidMount() {
    const staffRequest = await staffRequestApi.fetchStaffRequest(this.props.staffRequestId);

    this.setState(
      prevState => {
        const nextSearchParams = prevState.searchParams;
        nextSearchParams.page = 1;
        return {
          numActive: staffRequest.statuses_counts.active,
          numCancelled: staffRequest.statuses_counts.cancelled,
          numEnded: staffRequest.statuses_counts.ended,
          hasFetchedEmployments: true,
          searchParams: nextSearchParams,
        };
      },
      () => {
        this.fetchEmploymentsWithParams();
      },
    );
  }

  exportContract = workers => {
    this.setState({ isLoading: true });
    const partnerList = workers.map(worker => worker.id);
    staffRequestApi.exportContracts(this.props.staffRequestId, partnerList).then(data => {
      this.setState({
        isLoading: false,
      });
    });
  };

  handleAllFilterSelectChange = (field, checkedValues, allOptions) => {
    const allOptionsSelected = checkedValues.length === allOptions.length;
    const { searchParams } = this.state;
    const nextSearchParams = searchParams;
    // De-select all
    if (allOptionsSelected) {
      nextSearchParams[field] = undefined;
    } else {
      // Select all available options
      nextSearchParams[field] = allOptions.map(({ id }) => id).join(',');
    }
    this.setState({ searchParams: nextSearchParams });
  };

  handleSearchParamChange = (field, searchValue) => {
    this.setState(
      prevState => {
        const nextSearchParams = prevState.searchParams;
        nextSearchParams[field] = searchValue;
        nextSearchParams.page = 1;
        return { searchParams: nextSearchParams };
      },
      () => {
        this.fetchEmploymentsWithParams();
      },
    );
  };

  getStatusCounts = () => {
    return [
      { title: 'Active', num: this.state.numActive, hasBadge: false },
      { title: 'Cancelled', num: this.state.numCancelled, hasBadge: false },
      { title: 'Ended', num: this.state.numEnded, hasBadge: false },
      { title: 'Workers needed', num: this.props.numWorkersRequired, hasBadge: false },
    ];
  };

  getStatusFilter = () => {
    const employmentStatusOptions = [
      { id: employmentStatuses.ACTIVE, name: 'Active' },
      { id: employmentStatuses.CANCELLED, name: 'Cancelled' },
      { id: employmentStatuses.ENDED, name: 'Ended' },
    ];
    const { searchParams } = this.state;
    const { status } = searchParams;
    const statusCheckedList = status ? status.split(',') : [];
    return {
      title: 'Status',
      selectAllTitle: 'All status',
      selectedOptions: statusCheckedList,
      options: employmentStatusOptions,
      FilterComponent: CheckboxFilter,
      onChange: values => {
        const joinedValues = values.join(',');
        this.setState(prevState => {
          const nextSearchParams = prevState.searchParams;
          nextSearchParams.status = joinedValues;
          return { searchParams: nextSearchParams };
        });
      },
      onCheckAllChange: () => this.handleAllFilterSelectChange('status', statusCheckedList, employmentStatusOptions),
      onApply: () => {
        this.setState(
          prevState => {
            const nextSearchParams = prevState.searchParams;
            nextSearchParams.page = 1;
            return { searchParams: nextSearchParams };
          },
          () => {
            this.fetchEmploymentsWithParams();
          },
        );
      },
    };
  };

  onCancelEmployment = async (employmentId, reason, notes) => {
    const { selectedEmployment, selectedWorkerName: workerName } = this.state;
    const { id } = selectedEmployment;

    this.setState(prevState => {
      const nextEmployments = this.updateLocalEmploymentData(
        prevState.employments,
        id,
        employmentStatuses.CANCELLED,
        reason,
        notes,
      );
      this.props.updateParentStatusCounts([
        { status: employmentStatuses.ACTIVE, increment: false },
        { status: employmentStatuses.CANCELLED, increment: true },
      ]);
      return {
        numActive: prevState.numActive - 1,
        numCancelled: prevState.numCancelled + 1,
        employments: nextEmployments,
      };
    });
    message.success(`Successfully cancelled ${workerName}`);
    this.closeCancellationModal();
  };

  handleForceHireUpdate = () => {
    this.setState(prevState => {
      const { employments, selectedEmployment } = prevState;
      const employmentIndex = employments.findIndex(employment => employment.id === selectedEmployment.id);
      employments.splice(employmentIndex, 1);
      return { employments };
    });
  };

  onPageChange = pageNum => {
    this.setState(
      prevState => {
        const nextSearchParams = prevState.searchParams;
        nextSearchParams.page = pageNum;
        return { searchParams: nextSearchParams };
      },
      () => this.fetchEmploymentsWithParams(),
    );
  };

  openCancellationModal = employment => {
    this.setState({
      showEmploymentCancellationModal: true,
      selectedEmployment: employment,
    });
  };

  closeCancellationModal = () => {
    this.setState({
      showEmploymentCancellationModal: false,
      selectedEmployment: undefined,
    });
  };

  openForceHireModal = async employment => {
    const response = await applicationApi.fetchApplications({
      staff_request: employment.staff_request_id || employment.staff_request.id, // NOTE: employment will eventually no longer have staff_request
      partner: employment.partner.id,
    });
    const application = response.results[0];

    this.setState({
      showForceHireModal: true,
      selectedApplication: application,
      selectedEmployment: employment,
    });
  };

  closeForceHireModal = () => {
    this.setState({ showForceHireModal: false, selectedApplication: undefined, selectedEmployment: undefined });
  };

  // Takes a list of workers and update the worker status given a ID.
  // This is used to avoid re-fetching the entire table, but to update the worker table in our state.
  updateLocalEmploymentData = (
    employments,
    employmentId,
    nextStatus,
    nextReason = undefined,
    nextNotes = undefined,
  ) => {
    for (let i = 0; i < employments.length; i += 1) {
      if (employments[i].id === employmentId) {
        employments[i].status = nextStatus;
        if (nextReason) {
          employments[i].cancellation_reason = nextReason;
        }
        if (nextNotes) {
          employments[i].notes = nextNotes;
        }
        break;
      }
    }
    return employments;
  };

  fetchEmploymentsWithParams = async () => {
    this.setState({ isLoading: true });
    const { searchParams } = this.state;
    const staffRequest = await staffRequestApi.fetchStaffRequest(this.props.staffRequestId);
    const response = await employmentApi.fetchEmployments({
      location: staffRequest.location.id,
      position: staffRequest.position.id,
      ...searchParams,
    });
    this.setState({
      isLoading: false,
      employments: response.results,
      numRecords: response.count,
    });
  };

  getMenu = employment => {
    return (
      <Menu onClick={e => this.handleMenuClick(e.key, employment)}>
        <Menu.Item key="download_contract">Download contract</Menu.Item>
        {employment.status !== employmentStatuses.CANCELLED && (
          <Menu.Item key="cancel">
            <Text type="danger">Cancel from job</Text>
          </Menu.Item>
        )}
      </Menu>
    );
  };

  getStatusSection = employment => {
    const cancellationReason = employmentCancellationReasons.find(
      reason => reason.value === employment.cancellation_reason,
    );
    return (
      <>
        <StatusTag status={employment.status} style={{ marginRight: '8px' }} />
        <Text type="secondary" style={{ fontSize: '12px' }}>
          {`${moment(employment.created_date).format(DATE_FORMAT)} `}
        </Text>
        {employment.cancellation_reason && (
          <section style={{ paddingTop: '16px' }}>
            <Text strong type="secondary">
              REJECTION/CANCELLATION REASON
            </Text>
            <Paragraph ellipsis={{ rows: 1 }}>
              <Text strong>
                {/*
                TODO: We are mapping BE value for cancellation reason to FE.
                TODO: Any changes to cancellation reason in BE WILL BREAK FE.
                */}
                {`${
                  cancellationReason ? cancellationReason.title : textUtils.makeFriendly(employment.cancellation_reason)
                }`}
              </Text>
              {employment.notes && (
                <Tooltip title={employment.notes} placement="topLeft">
                  <Text type="secondary">{` · ${employment.notes}`}</Text>
                </Tooltip>
              )}
            </Paragraph>
          </section>
        )}
      </>
    );
  };

  handleMenuClick = (key, employment) => {
    if (key === 'cancel') {
      this.openCancellationModal(employment);
    } else if (key === 'download_contract') {
      this.exportContract([employment.partner]);
    }
  };

  render() {
    const {
      hasFetchedEmployments,
      searchParams,
      numRecords,
      isLoading,
      employments,
      showEmploymentCancellationModal,
      showForceHireModal,
      selectedEmployment,
      selectedApplication,
    } = this.state;
    const statusCounts = this.getStatusCounts();
    const statusFilter = this.getStatusFilter();
    return (
      <>
        {/* Top level summary */}
        <CardWithCount loading={!hasFetchedEmployments} countList={statusCounts} />

        {/* Filters & Search */}
        <Row type="flex" align="bottom" justify="space-between" style={{ marginBottom: '32px' }}>
          <Col>
            <Row style={{ width: '128px' }}>
              <CheckboxFilter
                showActionButtons
                title={`${statusFilter.title} ${
                  statusFilter.selectedOptions.length > 0 ? `(${statusFilter.selectedOptions.length})` : ''
                }`}
                options={statusFilter.options}
                selectedOptions={statusFilter.selectedOptions}
                selectAllTitle={statusFilter.selectAllTitle}
                onChange={statusFilter.onChange}
                onCheckAllChange={statusFilter.onCheckAllChange}
                indeterminate={
                  statusFilter.selectedOptions.length > 0 &&
                  statusFilter.selectedOptions.length < statusFilter.options.length
                }
                allChecked={statusFilter.selectedOptions.length === statusFilter.options.length}
                onApply={statusFilter.onApply}
                optionLabelField="name"
              />
            </Row>
          </Col>
          <Col span={8}>
            <Input.Search
              allowClear
              placeholder="Search by Workmate"
              onSearch={value => this.handleSearchParamChange('search', value)}
            />
          </Col>
        </Row>
        <Divider />

        {/* Sorting */}
        <Row type="flex" justify="space-between" style={{ marginBottom: '48px' }}>
          <Col>
            <ContractDownload staffRequestId={this.props.staffRequestId} />
          </Col>
          <Col style={{ width: '216px' }}>
            <Text type="secondary">Sort by</Text>
            <Select
              defaultValue="created_date"
              style={{ marginLeft: '8px', width: '156px' }}
              onChange={value => this.handleSearchParamChange('ordering', value)}
            >
              <Option value="created_date">First created</Option>
              <Option value="-created_date">Latest created</Option>
            </Select>
          </Col>
        </Row>

        {/* Actual Worker list */}
        <List
          itemLayout="vertical"
          pagination={{ ...getPaginationConfig(numRecords, this.onPageChange), current: searchParams.page || 1 }}
          loading={isLoading}
          dataSource={employments || []}
          renderItem={(employment, index) => {
            return (
              <Row key={index}>
                <WorkerInfoItem
                  staffRequestId={this.props.staffRequestId}
                  worker={employment.partner}
                  created_date={employment.created_date}
                  status={employment.status}
                  statusSection={this.getStatusSection(employment)}
                  contracts={employment.contracts}
                  actions={
                    checkAccess(permissions.editStaffRequest) && (
                      <>
                        {[employmentStatuses.CANCELLED, employmentStatuses.ENDED].includes(employment.status) && (
                          <Button
                            icon="thunderbolt"
                            style={{ color: colors.yellow }}
                            onClick={() => this.openForceHireModal(employment)}
                          >
                            Force hire
                          </Button>
                        )}
                        <div style={{ position: 'absolute', bottom: '2px', right: '10px' }}>
                          <Dropdown overlay={this.getMenu(employment)} placement="topRight">
                            <Button>...</Button>
                          </Dropdown>
                        </div>
                      </>
                    )
                  }
                />
                <Divider />
              </Row>
            );
          }}
        />
        {showEmploymentCancellationModal && (
          <EmploymentCancellationModal
            visible={showEmploymentCancellationModal}
            employment={selectedEmployment}
            onCancel={this.onCancelEmployment}
            onClose={() => this.closeCancellationModal()}
          />
        )}

        {showForceHireModal && (
          <ForceHireApplicationModal
            visible
            applicationId={selectedApplication.id}
            worker={selectedApplication.partner}
            onCancel={this.closeForceHireModal}
            onUpdate={this.handleForceHireUpdate}
          />
        )}
      </>
    );
  }
}

export default withRouter(StaffRequestEmployeesList);
