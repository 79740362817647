import React, { Component } from 'react';
import { Modal, Typography, message, Form, Select } from 'antd';
import timesheetEntryApi from '../../../services/timesheetEntryApi';

const { Text } = Typography;

class VoidTmesheetEntryModal extends Component {
  state = { isLoading: false, voidReasons: [] };

  componentDidMount() {
    this.fetchVoidReasons();
  }

  fetchVoidReasons = async () => {
    this.setState({ isLoading: true });
    try {
      const response = await timesheetEntryApi.fetchVoidReasons();
      this.setState({ isLoading: false, voidReasons: response });
    } catch (error) {
      this.setState({ isLoading: false });
    }
  };

  handleSubmit = e => {
    const timesheetEntryId = this.props.timesheet.id;
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (!err) {
        try {
          this.setState({ isLoading: true });
          const response = await timesheetEntryApi.voidItem(timesheetEntryId, values.voided_reason);
          message.success(`Successfully voided timesheet entry #${timesheetEntryId}`);
          this.props.onOk(response);
        } catch (error) {
          const detail = error?.response?.data?.detail;
          message.error(detail ?? error.message);
        }
        this.setState({ isLoading: false });
      }
    });
  };

  render() {
    const { voidReasons } = this.state;
    const { timesheet, visible, onCancel } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <Modal
        visible={visible}
        title="Void disputed timesheet entry"
        okText="Yes, void"
        okType="danger"
        onCancel={onCancel}
        onOk={this.handleSubmit}
        okButtonProps={{ loading: this.state.isLoading }}
      >
        <Text>
          You are about to void
          <Text strong>{` ${timesheet && timesheet.partner && timesheet.partner.first_name} `}</Text>
          timesheet entry #{timesheet.id}. Are you sure?
        </Text>
        <Form hideRequiredMark>
          <Form.Item label="Reason for voiding (required)">
            {getFieldDecorator('voided_reason', {
              rules: [{ required: true, message: 'Reason is required' }],
            })(
              <Select autoFocus placeholder="Please give your reason and some details..." optionLabelProp="label">
                {voidReasons.map(({ id, name, description }) => (
                  <Select.Option key={id} label={name}>
                    <Typography.Text>{name}</Typography.Text>
                    <br />
                    <Typography.Text type="secondary" style={{ whiteSpace: 'normal' }}>
                      {description}
                    </Typography.Text>
                  </Select.Option>
                ))}
              </Select>,
            )}
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default Form.create({ name: 'void-timesheet-entry-form' })(VoidTmesheetEntryModal);
