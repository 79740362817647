import styled from 'styled-components';
import DatePicker from 'react-datepicker';
// import { DatePicker } from 'antd';
import React from 'react';
import { fontO14r } from '../../../styles/fonts';
import { colors } from '../../../styles/colors';

const CustomDatePicker = styled(DatePicker)`
  padding: 10px 15px;
  ${fontO14r};
  border: 1px solid ${colors.grey};
  border-radius: 4px;
  width: 100%;
`;

const Container = styled.div`
  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    width: 100%;

    .react-datepicker__close-icon::after {
      right: 15px;
      top: 60%;
      background: none;
      color: ${colors.black};
    }
  }
`;

export default ({ ...props }) => (
  <Container>
    <CustomDatePicker {...props} />
  </Container>
);
