import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import { colors } from '../../styles/colors';

const CustomIcon = styled(Icon)``;

const CustomLink = styled(Link)`
  span {
    text-decoration: underline;
    margin-right: 5px;
    color: ${colors.blue};
  }

  ${CustomIcon} {
    transform: rotate(-45deg);
    color: ${colors.blue};
  }
`;

export default ({ children, ...props }) => (
  <CustomLink {...props}>
    <span>{children}</span>
    <CustomIcon name="arrow circle right" />
  </CustomLink>
);
