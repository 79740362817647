import React from 'react';
import DetailWithSidebarLayout from '../../../../shared/layouts/DetailWithSidebarLayout';
import ClientAccountCard from './cards/ClientAccountInformationCard';
import ClientDescriptionCard from './cards/ClientDescriptionCard';
import ClientHeaderCard from './cards/ClientHeaderCard';
import ClientLocationsCard from './cards/ClientLocationsCard';
import ClientManagersCard from './cards/ClientManagerCard';
import ClientServiceCard from './cards/ClientServiceInformationCard';
import ClientStaffRequestsCard from './cards/ClientStaffRequestsCard';

export default ({ client, onUpdate }) => (
  <DetailWithSidebarLayout
    primary={
      <div
        style={{
          display: 'grid',
          gridGap: 16,
        }}
      >
        <ClientHeaderCard client={client} onUpdate={onUpdate} />
        <ClientManagersCard client={client} onUpdate={onUpdate} />
        <ClientLocationsCard client={client} locations={client && client.locations} onUpdate={onUpdate} />
        <div style={{ width: '100%', overflowX: 'auto' }}>
          <ClientStaffRequestsCard client={client} onUpdate={onUpdate} />
        </div>
      </div>
    }
    secondary={
      <div
        style={{
          display: 'grid',
          gridGap: 16,
        }}
      >
        <ClientAccountCard client={client} />
        <ClientServiceCard client={client} />
        <ClientDescriptionCard client={client} />
      </div>
    }
  />
);
