import React from 'react';
import { Table, Tooltip } from 'antd';
import { Popup } from 'semantic-ui-react';
import styled from 'styled-components';
import { format, parse } from 'date-fns';
import { DATE_WITH_DAY_FORMAT, leaveAndSickStatuses } from '../../../constants';
import { colors } from '../../../styles/colors';
import EntryStatusLabel from '../../../shared/components/EntryStatusLabel';
import leaveApi from '../../../services/leaveApi';
import RejectLeaveAndSickModal from './modals/RejectLeaveAndSickModal';
import PartnerAvatar from '../../../shared/components/PartnerAvatar';
import Checkbox from '../../../shared/components/forms/Checkbox';
import IconButton from '../../../shared/components/IconButton';

const CustomIconButton = styled(IconButton)``;

class LeaveAndSickTable extends React.Component {
  state = {
    rejectedEntryId: null,
  };

  getColumns = status => {
    const { leaveEntries, selectedEntries, onUpdate, onSelect, onSelectAll } = this.props;

    // Common columns to be rendered across different statuses
    let columns = [
      {
        title: (
          // header checkbox gets checked when the number of selected entries equals to the number of all entries
          <Checkbox
            checked={leaveEntries && selectedEntries.length === leaveEntries.length && leaveEntries.length !== 0}
            onChange={onSelectAll}
          />
        ),
        dataIndex: 'checkbox',
        width: 20,
        render: (text, record) => (
          // checkboxes in table body gets checked when the selected entry is the same entry at the checkbox's row
          <Checkbox
            checked={selectedEntries.filter(selectedEntry => selectedEntry.id === record.id).length > 0}
            onChange={() => onSelect(record)}
          />
        ),
      },
      {
        title: 'Name',
        dataIndex: 'partner',
        width: 100,
        render: partner => <PartnerAvatar partner={partner} />,
      },
      {
        title: 'Date requested',
        dataIndx: 'created_date',
        width: 100,
        render: entry => format(parse(entry.created_date), DATE_WITH_DAY_FORMAT),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        width: 100,
        render: status => <EntryStatusLabel status={status} />,
      },
    ];

    // Conditions for different columns to be rendered according to status
    if (status === leaveAndSickStatuses.APPROVED || status === leaveAndSickStatuses.REJECTED) {
      // 1. For approved/rejected entries: an additional column on approved/rejected date gets displayed
      const additionalColumns = [
        {
          title: status === leaveAndSickStatuses.APPROVED ? 'Approved on' : 'Rejected on',
          dataIndex: 'modified_date',
          width: 100,
          render: data => format(parse(data), DATE_WITH_DAY_FORMAT),
        },
      ];
      columns = [...columns.slice(0, 3), ...additionalColumns, ...columns.slice(3)];
    }

    status === leaveAndSickStatuses.REQUESTED || status === leaveAndSickStatuses.APPROVED
      ? // 2-1. For requested/approved entries: final column displayes Action
        columns.push({
          title: 'Action',
          dataIndex: 'action',
          width: 100,
          render: (text, record) =>
            record &&
            status === leaveAndSickStatuses.REQUESTED && (
              // 2-1-1. Only requested(pending approval) entries display action buttons: Approve and Reject(which triggers a modal)
              <>
                <Popup
                  basic
                  hideOnScroll
                  content="Approve"
                  trigger={
                    <CustomIconButton
                      type="check-circle"
                      style={{ color: colors.green }}
                      onClick={() => {
                        leaveApi.approve(record.id).then(onUpdate);
                      }}
                    />
                  }
                />
                <Popup
                  basic
                  hideOnScroll
                  content="Reject"
                  trigger={
                    <CustomIconButton
                      type="exclamation-circle"
                      onClick={() => {
                        this.setState({ rejectedEntryId: record.id });
                      }}
                      style={{ color: colors.orange }}
                    />
                  }
                />
                <RejectLeaveAndSickModal
                  entry={record}
                  open={this.state.rejectedEntryId === record.id}
                  onClose={() => this.setState({ rejectedEntryId: null })}
                  onUpdate={onUpdate}
                />
              </>
            ),
        })
      : // 2-2. For rejected entries: final column displayes Notes
        columns.push({
          title: 'Notes',
          dataIndex: 'notes',
          width: 100,
          render: text => (
            <Tooltip title={text}>
              <span>{text}</span>
            </Tooltip>
          ),
        });

    return columns;
  };

  render() {
    const { leaveEntries, status } = this.props;

    return (
      <Table
        dataSource={leaveEntries}
        columns={this.getColumns(status)}
        size="middle"
        loading={!leaveEntries}
        pagination={false}
      />
    );
  }
}

export default LeaveAndSickTable;
