import React from 'react';
import { Modal, Button, message, Upload, Icon, Typography } from 'antd';

const { Text } = Typography;
const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

const UploadButton = ({ isLoading }) => (
  <>
    <Icon type={isLoading ? 'loading' : 'plus'} />
    <div>Upload</div>
  </>
);

class ChannelUploadImageModal extends React.Component {
  state = {
    isLoading: false,
    imageUrl: undefined,
    fileType: undefined,
    fileObj: undefined,
  };

  beforeUpload = file => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
  };

  handleChange = info => {
    if (info.file.status === 'uploading') {
      this.setState({ isLoading: true });
      return;
    }
    if (info.file.status === 'done') {
      // Have to convert image to base64 for previewing & sending
      getBase64(info.file.originFileObj, imageUrl =>
        this.setState({
          imageUrl,
          fileObj: info.file.originFileObj,
          fileType: info.file.type,
          isLoading: false,
        }),
      );
    }
  };

  render() {
    const { visible, onCancel, onSend } = this.props;
    const { isLoading, imageUrl, fileType, fileObj } = this.state;
    return (
      <Modal
        centered
        destroyOnClose
        maskClosable
        visible={visible}
        width="600px"
        bodyStyle={{ height: '432px' }}
        title="Upload an image"
        onCancel={onCancel}
        footer={
          <>
            <Button onClick={onCancel}>Cancel</Button>
            <Button type="v2-primary" disabled={!imageUrl} onClick={() => onSend(fileObj, fileType)}>
              Send image
            </Button>
          </>
        }
      >
        <Upload
          name="avatar"
          listType="picture-card"
          accept=".jpg,.jpeg,.png"
          className="broadcast-image-uploader"
          showUploadList={false}
          beforeUpload={this.beforeUpload}
          onChange={this.handleChange}
          customRequest={({ onSuccess }) => {
            setTimeout(() => {
              onSuccess('ok');
            }, 0);
          }}
        >
          {imageUrl ? (
            <img src={imageUrl} alt="avatar" style={{ objectFit: 'cover', height: 'inherit', width: 'inherit' }} />
          ) : (
            <UploadButton isLoading={isLoading} />
          )}
        </Upload>

        {/* Description of constraints */}
        <section style={{ paddingTop: '16px', paddingBottom: '32px' }}>
          <Text type="secondary">Click or drag and drop an image file to upload. Maximum file size is 2.0MB.</Text>
        </section>
      </Modal>
    );
  }
}

export default ChannelUploadImageModal;
