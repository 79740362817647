import React from 'react';
import styled, { css } from 'styled-components';
import { paymentItemTypes } from '../../constants';
import { colors } from '../../styles/colors';
import { fontO11b } from '../../styles/fonts';

const getStyle = type => {
  switch (type) {
    case paymentItemTypes.BASE:
      return css`
        color: ${colors.green};
      `;
    case paymentItemTypes.OVERTIME:
      return css`
        color: ${colors.orange};
      `;
    default:
      return css`
        color: ${colors.black};
      `;
  }
};

const Container = styled.div`
  ${fontO11b};
  ${props => getStyle(props.type)}
`;

export default ({ type, ...props }) => (
  <Container type={type} {...props}>
    {type.toUpperCase()}
  </Container>
);
