import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import FormGroup from './FormGroup';
import MonthYearInput from './MonthYearInput';
import PositionDropdown from './PositionDropdown';
import Input from './Input';

const CustomInput = styled(Input)``;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 2fr 2fr;
  grid-column-gap: 30px;
`;

export default class ExperienceInput extends React.Component {
  static propTypes = {
    value: PropTypes.object.isRequired,
    onChange: PropTypes.func,
    errors: PropTypes.object,
    touched: PropTypes.object,
  };

  static defaultProps = {
    errors: {},
    touched: {},
  };

  handleStartChange = ({ month, year }) => {
    this.props.onChange({
      ...this.props.value,
      start_month: month,
      start_year: year,
    });
  };

  handleEndChange = ({ month, year }) => {
    this.props.onChange({
      ...this.props.value,
      end_month: month,
      end_year: year,
    });
  };

  handlePositionChange = position => {
    this.props.onChange({
      ...this.props.value,
      position,
    });
  };

  handleCompanyNameChange = companyName => {
    this.props.onChange({
      ...this.props.value,
      company_name: companyName,
    });
  };

  render() {
    const { value, errors, touched } = this.props;

    return (
      <Container>
        <FormGroup>
          <FormGroup.Label>Start:</FormGroup.Label>
          <MonthYearInput
            onChange={this.handleStartChange}
            value={{ month: value.start_month, year: value.start_year }}
          />
          {errors && touched && (errors.start_month || errors.start_year) && touched.start_month && (
            <FormGroup.Error>
              {errors.start_month}
              {` ${errors.start_year}`}
            </FormGroup.Error>
          )}
        </FormGroup>
        <FormGroup>
          <FormGroup.Label>End:</FormGroup.Label>
          <MonthYearInput onChange={this.handleEndChange} value={{ month: value.end_month, year: value.end_year }} />
          {errors && touched && (errors.end_month || errors.end_year) && touched.end_month && (
            <FormGroup.Error>
              {errors.end_month}
              {` ${errors.end_year}`}
            </FormGroup.Error>
          )}
        </FormGroup>
        <FormGroup>
          <FormGroup.Label>Company Name:</FormGroup.Label>
          <CustomInput
            placeholder="Company Name"
            value={value.company_name}
            onChange={event => {
              this.handleCompanyNameChange(event.target.value);
            }}
          />
          {errors && touched && errors.company_name && touched.company_name && (
            <FormGroup.Error>{errors.company_name}</FormGroup.Error>
          )}
        </FormGroup>
        <FormGroup>
          <FormGroup.Label>Position:</FormGroup.Label>
          <PositionDropdown
            search
            placeholder="Position"
            value={value.position}
            onChange={(e, { name, value }) => this.handlePositionChange(value)}
          />
          {errors && touched && errors.position && touched.position && (
            <FormGroup.Error>{errors.position}</FormGroup.Error>
          )}
        </FormGroup>
      </Container>
    );
  }
}
