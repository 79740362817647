import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import numeral from 'numeral';
import { Popup } from 'semantic-ui-react';
import Table from '../../../../shared/components/Table';
import PaymentTypeLabel from '../../../../shared/components/PaymentItemTypeLabel';
import EntityLink from '../../../../shared/components/EntityLink';
import PartnerAvatar from '../../../../shared/components/PartnerAvatar';
import routes from '../../../../routes';
import PaymentItemStatusLabel from '../../../../shared/components/PaymentItemStatusLabel';
import IconButton from '../../../../shared/components/IconButton';
import { colors } from '../../../../styles/colors';
import PaymentItemModal from '../modals/PaymentItemModal';
import paymentApi from '../../../../services/paymentApi';
import Access from '../../../../shared/access/Access';
import { permissions } from '../../../../shared/access/accessConfig';
import { humanizeText } from '../../../../utilities/textUtils';

const Actions = styled.div`
  display: grid;
  grid-column-gap: 5px;
  grid-auto-flow: column;
`;

export default class PaymentItemsTable extends React.Component {
  state = {
    editModal: null,
  };

  render() {
    const { paymentItems, summary, editable, onUpdate, isDco, transactionFee } = this.props;

    return (
      <Table>
        <Table.Headers>
          <Table.Header>Item ID</Table.Header>
          {!summary && <Table.Header>Report ID</Table.Header>}
          <Table.Header>Date</Table.Header>
          {!summary && (
            <>
              <Table.Header>Partner ID</Table.Header>
              <Table.Header>Name</Table.Header>
            </>
          )}
          <Table.Header style={{ width: 100 }}>Description</Table.Header>
          <Table.Header>Type</Table.Header>
          <Table.Header>Unit Cost</Table.Header>
          <Table.Header>Qty</Table.Header>
          {isDco && <Table.Header>Transaction fee</Table.Header>}
          <Table.Header align="right">Amount</Table.Header>
          {!summary && <Table.Header>Status</Table.Header>}
          {editable && <Table.Header>Actions</Table.Header>}
        </Table.Headers>
        <Table.Body>
          {paymentItems &&
            paymentItems.length > 0 &&
            paymentItems.map(paymentItem => (
              <Table.Row key={paymentItem.id}>
                <Table.Cell>{paymentItem.id}</Table.Cell>
                {!summary && (
                  <Table.Cell>
                    {paymentItem.payment_report && (
                      <EntityLink to={`${routes.paymentReports}/${paymentItem.payment_report}`}>
                        {paymentItem.payment_report}
                      </EntityLink>
                    )}
                  </Table.Cell>
                )}
                <Table.Cell>{moment(paymentItem.date).format('DD/MM/YYYY')}</Table.Cell>
                {!summary && (
                  <>
                    <Table.Cell>
                      {paymentItem.partner && (
                        <EntityLink to={`${routes.partners}/${paymentItem.partner.id}`}>
                          {paymentItem.partner.id}
                        </EntityLink>
                      )}
                    </Table.Cell>
                    <Table.Cell>{paymentItem.partner && <PartnerAvatar partner={paymentItem.partner} />}</Table.Cell>
                  </>
                )}
                <Table.Cell>
                  {/* These links go to workforce page */}
                  {paymentItem.timesheet_entry && <EntityLink to="">Timesheet</EntityLink>}
                  {paymentItem.leave_entry_id && <EntityLink to="">Leave</EntityLink>}
                  {paymentItem.description}
                </Table.Cell>
                <Table.Cell>
                  <PaymentTypeLabel type={humanizeText(paymentItem.type)} />
                </Table.Cell>
                <Table.Cell>{numeral(paymentItem.unit_cost).format('0,0.00')}</Table.Cell>
                <Table.Cell>{paymentItem.quantity}</Table.Cell>
                {isDco && <Table.Cell>{transactionFee}</Table.Cell>}
                <Table.Cell align="right">
                  {numeral(numeral(paymentItem.unit_cost).value() * paymentItem.quantity).format('0,0.00')}
                </Table.Cell>
                {!summary && (
                  <Table.Cell>
                    <PaymentItemStatusLabel status={paymentItem.status} />
                  </Table.Cell>
                )}
                {editable && (
                  <Table.Cell>
                    <Access
                      action={permissions.editPaymentItem}
                      yes={() => (
                        <Actions>
                          <Popup
                            basic
                            hideOnScroll
                            content="Edit"
                            trigger={
                              <IconButton type="edit" onClick={() => this.setState({ editModal: paymentItem.id })} />
                            }
                          />
                          <PaymentItemModal
                            open={this.state.editModal === paymentItem.id}
                            onClose={() => this.setState({ editModal: null })}
                            onUpdate={onUpdate}
                            paymentItem={paymentItem}
                          />
                          <Popup
                            basic
                            hideOnScroll
                            content="Remove from report"
                            trigger={
                              <IconButton
                                type="close-circle"
                                style={{ color: colors.orange }}
                                onClick={() => {
                                  paymentApi
                                    .patchPaymentItem(paymentItem.id, {
                                      payment_report: null,
                                    })
                                    .then(() => {
                                      onUpdate();
                                    });
                                }}
                              />
                            }
                          />
                        </Actions>
                      )}
                    />
                  </Table.Cell>
                )}
              </Table.Row>
            ))}
          {!paymentItems &&
            [...Array(5).keys()].map(i => (
              <Table.Row>
                <Table.CellPlaceholder />
                <Table.CellPlaceholder />
                <Table.CellPlaceholder />
                <Table.CellPlaceholder />
                <Table.CellPlaceholder />
                <Table.CellPlaceholder />
                <Table.CellPlaceholder />
                {!summary && (
                  <>
                    <Table.CellPlaceholder />
                    <Table.CellPlaceholder />
                    <Table.CellPlaceholder />
                  </>
                )}
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
    );
  }
}
