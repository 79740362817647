import React from 'react';
import styled from 'styled-components';

const TextAddress = styled.div``;

const Maps = styled.a``;

const Container = styled.div``;

export default ({ address, ...props }) => {
  if (!address) {
    return null;
  }

  return (
    <Container {...props}>
      {address && (
        <>
          <TextAddress>
            {address.street_1 && <div>{address.street_1},</div>}
            {address.street_2 && <div>{address.street_2},</div>}
          </TextAddress>
          {address.latitude && address.longitude && (
            <Maps
              target="_blank"
              href={`https://www.google.com/maps/search/?api=1&query=${address.latitude},${address.longitude}`}
            >
              Google Maps
            </Maps>
          )}
        </>
      )}
    </Container>
  );
};
