import React from 'react';
import styled from 'styled-components';
import { Popover, Tag } from 'antd';
import { Link } from 'react-router-dom';
import routes from '../../../../routes';
import AvatarImage from '../../../../shared/components/AvatarImage';

const CustomAvatar = styled(AvatarImage)`
  z-index: 5;
`;

export default ({ title, content, partner, highlightItem, onSelect }) => {
  const partnerTitle = (
    <Link to={`${routes.partners}/${partner && partner.id}`}>
      {title}
      {partner && partner.status ? <Tag color="green">{partner.status}</Tag> : ''}
    </Link>
  );

  return (
    <div>
      <Popover title={partnerTitle} content={content} visible={highlightItem}>
        <CustomAvatar
          size={highlightItem ? 'large' : 'small'}
          src={partner.image}
          onMouseOver={() => onSelect(partner.id)}
        />
      </Popover>
    </div>
  );
};
