import { roleAuthorizations } from './accessConfig';
import store from '../../store';
import authApi from '../../services/authApi';

const getOperator = () => {
  const storeState = store.getState();
  return storeState.user.operator;
};

const logOutIfNotOperator = operator => {
  if (!operator) authApi.logout();
};

export const checkAccess = (action, data) => {
  const operator = getOperator();
  logOutIfNotOperator(operator);
  const authorization = roleAuthorizations[operator.role];
  if (!authorization) {
    return false;
  }

  const { staticPermissions } = authorization;
  if (staticPermissions && staticPermissions.includes(action)) {
    return true;
  }

  const dynamicPermissions = authorization.dynamic;
  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action];
    if (!permissionCondition) {
      return false;
    }

    return permissionCondition(data);
  }
  return false;
};

// ~~~~~~~~~~ Do not use anymore ~~~~~~~~~~
// We are currently using both "checkAccess" & "<Access". We should be consistent and use only one.
// "checkAccess" is preferred as there's fewer LOC & also gives the Parent component more control.
const DeprecatedAccess = ({ action, data, yes, no }) => (checkAccess(action, data) ? yes() : no());

DeprecatedAccess.defaultProps = {
  yes: () => null,
  no: () => null,
};

export default DeprecatedAccess;
