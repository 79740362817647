import React from 'react';
import { connect } from 'react-redux';
import Dropdown from './Dropdown';

const PositionDropdown = ({ positions, dispatch, ...props }) => {
  return (
    <Dropdown
      {...props}
      options={positions.map(position => ({
        key: position.id,
        value: position.id,
        text: position.name,
      }))}
    />
  );
};

const mapStateToProps = state => ({
  positions: state.global.positions || [],
});

export default connect(mapStateToProps)(PositionDropdown);
