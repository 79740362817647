import React from 'react';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import numeral from 'numeral';
import { Typography, Table, Row, Col, Avatar, Tooltip, Icon } from 'antd';
import getPaginationConfig from '../../../utilities/getPaginationConfig';
import textUtils from '../../../utilities/textUtils';

import StatusTag from '../../../shared/components/StatusTag';
import ToolTipWithInfo from '../../../shared/components/ToolTipWithInfo';
import EntityLink from '../../../../shared/components/EntityLink';
import routes from '../../../../routes';
import { paymentItemStatuses } from '../../../../constants';

const { Text } = Typography;

const PaymentItemList = ({ dataSource, loading, count, page, onPageChange, selectedRowKeys, onSelectionChange }) => {
  const columns = [
    {
      title: 'Item',
      dataIndex: 'id',
    },
    {
      width: 200,
      title: 'Name',
      dataIndex: 'partner',
      render: text => (
        <Row gutter={8}>
          <Link to={routes.partnerDetail.replace(':id', text.id)}>
            <Col span={8}>
              <Avatar shape="square" size="large" src={text.image} />
            </Col>
            <Text style={{ fontSize: '14px' }}>
              {`${text.first_name} ${text.last_name}`} <br />
              <Text type="secondary">#{text.id}</Text>
            </Text>
          </Link>
        </Row>
      ),
    },
    {
      title: () => (
        <>
          <Text>Report</Text>
          <Tooltip title="Payment items that have been generated into a report will have its parent report shown here">
            <Icon
              type="info-circle"
              style={{ fontSize: '18px', verticalAlign: 'middle', cursor: 'auto', color: '#108EE9', marginLeft: '5px' }}
              theme="filled"
            />
          </Tooltip>
        </>
      ),
      dataIndex: 'payment_report',
      render: text => <>{text ? <EntityLink to={`${routes.paymentReports}/${text}`}>{text}</EntityLink> : '-'}</>,
    },
    {
      title: 'Date',
      dataIndex: 'date',
      render: text => moment(text).format('DD MMM YYYY'),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      render: text => <Text style={{ color: '#69e4a6' }}>{textUtils.makeFriendly(text)}</Text>,
    },
    {
      title: 'Qty',
      dataIndex: 'quantity',
    },
    {
      title: 'Cost',
      dataIndex: 'unit_cost',
      render: text => numeral(text).format('0,0.00'),
    },
    {
      title: 'Amount',
      render: (text, record) => {
        return <>{numeral(numeral(record.unit_cost).value() * record.quantity).format('0,0.00')}</>;
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (text, record) => {
        return (
          <>
            <StatusTag status={text} />
            {record.notes && <ToolTipWithInfo infoText={record.notes} />}
          </>
        );
      },
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: items => {
      onSelectionChange(items);
    },
    getCheckboxProps: record => ({
      disabled: [paymentItemStatuses.VOID, paymentItemStatuses.PAID].includes(record.status), // Column configuration not to be checked
    }),
  };

  return (
    <Table
      rowSelection={rowSelection}
      style={{ marginBottom: '100px' }}
      loading={loading}
      columns={columns}
      dataSource={dataSource}
      rowKey="id"
      pageSize={20}
      pagination={{ ...getPaginationConfig(count, onPageChange), current: page }}
    />
  );
};

export default PaymentItemList;
