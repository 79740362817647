import React from 'react';
import styled from 'styled-components';
import googleIcon from '../../assets/images/google-icon.svg';

const Button = styled.div`
  height: 50px;
  width: 240px;
  border: none;
  text-align: center;
  vertical-align: center;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.25);
  font-size: 16px;
  line-height: 48px;
  display: block;
  border-radius: 1px;
  background-color: #4285f4;
  color: #fff;
  transition: background-color .218s, border-color .218s, box-shadow .218s;
  font-family: Roboto,arial,sans-serif;
  cursor: pointer;
  user-select: none;
  &:hover {
    box-shadow: 0 0 3px 3px rgba(66,133,244,.3);
    transition: #4285f4 .218s, border-color .218s, box-shadow .218s;
  },
`;

const Icon = styled.div`
  width: 48px;
  height: 48px;
  text-align: center;
  vertical-align: center;
  display: block;
  margin-top: 1px;
  margin-left: 1px;
  float: left;
  background-color: #fff;
  border-radius: 1px;
  whitespace: nowrap;
`;

const GoogleIcon = () => (
  <Icon>
    <img src={googleIcon} alt="Google icon" />
  </Icon>
);

const GoogleButton = ({ onClick }) => {
  return (
    <Button onClick={onClick}>
      <GoogleIcon />
      <span>Sign in with Google</span>
    </Button>
  );
};

export default GoogleButton;
