import React from 'react';
import moment from 'moment-timezone';
import { message, Select, Avatar, Icon, List, Row, Col, Button, Card, Typography, Input, Tooltip } from 'antd';
import { Link } from 'react-router-dom';

import partnerApi from '../../../services/partnerApi';
import { colors } from '../../../styles/colors';
import getPaginationConfig from '../../utilities/getPaginationConfig';
import workerUtils from '../../utilities/workerUtils';
import routes from '../../../routes';
import { partnerStatuses } from '../../../constants';
import { permissions } from '../../../shared/access/accessConfig';
import { checkAccess } from '../../../shared/access/Access';
// Should be in HC library
import DetailCard from '../../shared/components/DetailCard';
import CardWithCount from '../../shared/components/CardWithCount';
import StatusTag from '../../shared/components/StatusTag';
import CheckboxFilter from '../../shared/components/CheckboxFilter';
import ConfirmModals from '../../shared/components/ConfirmModals';
import RejectWorkerModal from './components/RejectWorkerModal';
import { humanizeText } from '../../utilities/textUtils';

const { Paragraph, Title, Text } = Typography;
class WorkerListView extends React.Component {
  state = {
    isLoading: false,
    workers: undefined,
    hasFetchedWorkers: false,
    numRecords: 0,
    numLead: 0,
    numApproved: 0,
    numRejected: 0,
    numSuspended: 0,
    searchParams: {
      page: 1,
      status: `${Object.values(partnerStatuses).join(',')}`,
    },
    showRejectModal: false,
    selectedWorker: {},
  };

  async componentDidMount() {
    this.setState({ isLoading: true });
    const response = await partnerApi.fetchPartners({
      page: 1,
      include_counts: true,
    });
    if (response) {
      const { count, status_counts, results } = response;
      this.setState({
        isLoading: false,
        hasFetchedWorkers: true,
        workers: this.cleanWorkerProfiles(results),
        numRecords: count,
        numLead: status_counts.lead,
        numApproved: status_counts.active,
        numRejected: status_counts.rejected,
        numSuspended: status_counts.suspended,
      });
    }
  }

  cleanWorkerProfiles = workers => {
    return workers.map(worker => workerUtils.cleanProfile(worker));
  };

  goToCreateWorker = () => {
    this.props.history.push(routes.createPartners);
  };

  getStatusCounts = () => {
    const { numLead, numApproved } = this.state;
    return [
      { title: 'Leads', num: numLead, hasBadge: false },
      { title: 'Approved Workers', num: numApproved, hasBadge: false },
    ];
  };

  handleAllFilterSelectChange = (field, checkedValues, allOptions) => {
    const allOptionsSelected = checkedValues.length === allOptions.length;
    const { searchParams } = this.state;
    const nextSearchParams = searchParams;
    // De-select all
    if (allOptionsSelected) {
      nextSearchParams[field] = undefined;
    } else {
      // Select all available options
      nextSearchParams[field] = allOptions.map(({ id }) => id).join(',');
    }
    this.setState({ searchParams: nextSearchParams });
  };

  getStatusFilter = () => {
    const partnerStatusOptions = [
      { id: partnerStatuses.LEAD, name: humanizeText(partnerStatuses.LEAD) },
      { id: partnerStatuses.ACTIVE, name: humanizeText(partnerStatuses.ACTIVE) },
      { id: partnerStatuses.REJECTED, name: humanizeText(partnerStatuses.REJECTED) },
      { id: partnerStatuses.SUSPENDED, name: humanizeText(partnerStatuses.SUSPENDED) },
      { id: partnerStatuses.DISABLED, name: humanizeText(partnerStatuses.DISABLED) },
      { id: partnerStatuses.PENDING_DELETION, name: humanizeText(partnerStatuses.PENDING_DELETION) },
    ];
    const { searchParams } = this.state;
    const { status } = searchParams;
    const statusCheckedList = status ? status.split(',') : [];
    return {
      title: 'Status',
      selectAllTitle: 'All status',
      selectedOptions: statusCheckedList,
      options: partnerStatusOptions,
      FilterComponent: CheckboxFilter,
      onChange: values => {
        const joinedValues = values.join(',');
        this.setState(prevState => {
          const nextSearchParams = prevState.searchParams;
          nextSearchParams.status = joinedValues;
          return { searchParams: nextSearchParams };
        });
      },
      onCheckAllChange: () => this.handleAllFilterSelectChange('status', statusCheckedList, partnerStatusOptions),
      onApply: () => {
        this.setState(
          prevState => {
            const nextSearchParams = prevState.searchParams;
            nextSearchParams.page = 1;
            return { searchParams: nextSearchParams };
          },
          () => this.fetchWorkersWithParams(),
        );
      },
    };
  };

  getWorkerActions = (status, workerName, workerId) => {
    const VerifyButton = () => (
      <Button
        type="v2-primary"
        style={{ marginBottom: '4px', width: '108px' }}
        icon="check"
        onClick={() => ConfirmModals.verifyWorker(workerName, () => this.onVerifyWorker(workerId, workerName))}
      >
        Verify
      </Button>
    );
    const RejectButton = () => (
      <Button
        type="v2-secondary"
        style={{ color: '#FF5251', width: '108px' }}
        icon="close"
        onClick={() => this.setState({ showRejectModal: true, selectedWorker: { name: workerName, id: workerId } })}
      >
        Reject
      </Button>
    );
    switch (status) {
      case partnerStatuses.LEAD:
        return (
          <>
            {checkAccess(permissions.approvePartner) && (
              <section>
                <VerifyButton />
              </section>
            )}
            {checkAccess(permissions.rejectPartner) && (
              <section>
                <RejectButton />
              </section>
            )}
          </>
        );
      default:
        return <> </>;
    }
  };

  getOverview = () => {
    const { numRecords, numLead, numApproved, numRejected, numSuspended } = this.state;
    return {
      title: 'Overview',
      contentList: [
        { title: 'Total accounts', value: `${numRecords}` },
        { title: 'Lead', value: `${numLead}` },
        { title: 'Approved', value: `${numApproved}` },
        { title: 'Rejected', value: `${numRejected}` },
        { title: 'Suspended', value: `${numSuspended}` },
      ],
    };
  };

  onSearch = searchValue => {
    this.setState(
      prevState => {
        const nextSearchParams = prevState.searchParams;
        nextSearchParams.search = searchValue;
        nextSearchParams.page = 1;
        return { searchParams: nextSearchParams };
      },
      () => this.fetchWorkersWithParams(),
    );
  };

  onPageChange = pageNum => {
    this.setState(
      prevState => {
        const nextSearchParams = prevState.searchParams;
        nextSearchParams.page = pageNum;
        return { searchParams: nextSearchParams };
      },
      () => this.fetchWorkersWithParams(),
    );
  };

  onVerifyWorker = async (workerId, workerName) => {
    try {
      await partnerApi.approvePartner({ id: workerId });

      message.success(`Successfully verified ${workerName}`);
      this.setState(prevState => {
        const nextWorkers = this.updateWorkerStatus(prevState.workers, workerId, partnerStatuses.ACTIVE);
        return {
          numApproved: prevState.numApproved + 1,
          numLead: prevState.numLead - 1,
          workers: nextWorkers,
        };
      });
    } catch (error) {
      const detail = error?.response?.data?.detail;
      message.error(detail ?? error.message);
    }
  };

  onRejectWorker = async (workerId, workerName) => {
    // TODO: Assume reject modal worked. But this may not be true. Missing error handling
    message.success(`Successfully Rejected ${workerName}`);
    this.setState(prevState => {
      const nextWorkers = this.updateWorkerStatus(prevState.workers, workerId, partnerStatuses.REJECTED);
      return {
        numRejected: prevState.numRejected + 1,
        numLead: prevState.numLead - 1,
        workers: nextWorkers,
      };
    });
  };

  fetchWorkersWithParams = async () => {
    this.setState({ isLoading: true });

    // intercept number searches with prefixed 0 and strip it (to string 0 from mobile numbers)
    let { search } = this.state.searchParams;
    if (search && search.match(/^[0][0-9]*$/)) {
      search = search.substring(1);
    }

    const response = await partnerApi.fetchPartners({
      ...this.state.searchParams,
      search,
    });
    this.setState({
      isLoading: false,
      workers: this.cleanWorkerProfiles(response.results),
      numRecords: response.count,
    });
  };

  // Takes a list of workers and update the worker status given a ID.
  // This is used to avoid re-fetching the entire table, but to update the worker table in our state.
  updateWorkerStatus = (workers, workerId, nextStatus) => {
    for (let i = 0; i < workers.length; i += 1) {
      if (workers[i].id === workerId) {
        workers[i].status = nextStatus;
        break;
      }
    }
    return workers;
  };

  render() {
    const {
      isLoading,
      hasFetchedWorkers,
      workers,
      numRecords,
      searchParams,
      selectedWorker,
      showRejectModal,
    } = this.state;
    const statusCounts = this.getStatusCounts();
    const statusFilter = this.getStatusFilter();
    const overview = this.getOverview();
    return (
      <Row type="flex" gutter={50} style={{ marginBottom: '86px' }}>
        <Col span={16}>
          {/* Header & Create button */}
          <Row type="flex" justify="space-between" style={{ marginBottom: '32px' }}>
            <Col>
              <Title level={3}>Workers</Title>
            </Col>
            <Col>
              {checkAccess(permissions.createPartner) && (
                <Button type="v2-primary" icon="plus" onClick={this.goToCreateWorker}>
                  Create worker
                </Button>
              )}
            </Col>
          </Row>

          {/* Count card */}
          <CardWithCount loading={!hasFetchedWorkers} countList={statusCounts} />

          {/* Filters, search & order by*/}
          <Row type="flex" justify="space-between" align="bottom" style={{ marginBottom: '48px' }}>
            <Col span={14}>
              <Row style={{ width: '128px', marginBottom: '16px' }}>
                <CheckboxFilter
                  showActionButtons
                  title={`${statusFilter.title} ${
                    statusFilter.selectedOptions.length > 0 ? `(${statusFilter.selectedOptions.length})` : ''
                  }`}
                  options={statusFilter.options}
                  selectedOptions={statusFilter.selectedOptions}
                  selectAllTitle={statusFilter.selectAllTitle}
                  onChange={statusFilter.onChange}
                  onCheckAllChange={statusFilter.onCheckAllChange}
                  indeterminate={
                    statusFilter.selectedOptions.length > 0 &&
                    statusFilter.selectedOptions.length < statusFilter.options.length
                  }
                  allChecked={statusFilter.selectedOptions.length === statusFilter.options.length}
                  onApply={statusFilter.onApply}
                  optionLabelField="name"
                />
              </Row>
              <Row>
                <Input.Search allowClear placeholder="Search Workmates" onSearch={this.onSearch} />
              </Row>
            </Col>
            {/* TODO: Sort by currently only has one option. Consider removing or expanding capability */}
            <Col style={{ width: '210px', alignItems: 'right' }}>
              <Text type="secondary">Sort by</Text>
              <Select defaultValue="-created_date" style={{ marginLeft: '8px', width: '148px' }}>
                <Select.Option value="-created_date">Date created</Select.Option>
              </Select>
            </Col>
          </Row>

          {/* Actual List */}
          <List
            itemLayout="vertical"
            pagination={{ ...getPaginationConfig(numRecords, this.onPageChange), current: searchParams.page || 1 }}
            loading={isLoading}
            dataSource={workers || []}
            renderItem={(worker, index) => {
              return (
                <Row key={index} style={{ borderBottom: '1px solid #00000017', paddingTop: '16px' }}>
                  <Card
                    bordered={false}
                    style={{ paddingBottom: '24px', backgroundColor: 'inherit' }}
                    bodyStyle={{ padding: 0 }}
                  >
                    <Row type="flex" gutter={8}>
                      <Col span={3}>
                        <Avatar shape="square" size={88} src={worker.imgSrc} className="v2-avatar-wrapper" />
                      </Col>
                      <Col span={16} style={{ paddingLeft: '24px' }}>
                        {/* Clickable title  */}
                        <Paragraph ellipsis={{ row: 1 }} style={{ marginBottom: 0 }}>
                          <Title
                            strong
                            level={4}
                            style={{
                              margin: 0,
                              display: 'inline-block',
                              cursor: 'pointer',
                              width: 'fit-content',
                            }}
                          >
                            <Link style={{ color: colors.black }} to={`${routes.partners}/${worker.id}`}>
                              {`${worker.name}`}
                            </Link>
                          </Title>
                          <Text strong style={{ marginLeft: '14px', color: '#69E4A6' }}>
                            <Icon type="calendar" style={{ paddingRight: '4px', fontSize: '16px' }} />
                            {`${worker.daysWorked} days worked`}
                          </Text>
                          {worker.inSkillQuizExperiment && (
                            <Tooltip title="This user is part of the Digitized RC experiment.">
                              <Text style={{ marginLeft: '14px', color: colors.blue }}>
                                <Icon type="info-circle" style={{ paddingRight: '4px', fontSize: '16px' }} />
                                Automated screening
                              </Text>
                            </Tooltip>
                          )}
                        </Paragraph>

                        {/* Age, location & last active */}
                        <Paragraph ellipsis={{ row: 1 }} style={{ marginBottom: '4px' }}>
                          <Text type="secondary">
                            {`${worker.age || 0}yo · ${worker.location} · ${worker.lastActive}`}
                          </Text>
                        </Paragraph>

                        {/* Contact details. Mobile & email */}
                        <Paragraph ellipsis={{ row: 1 }} style={{ marginBottom: '4px' }}>
                          <Text type="secondary">{`${worker.phone} · ${worker.email}`}</Text>
                        </Paragraph>

                        {/* Created & Approval date */}
                        <Paragraph ellipsis={{ row: 1 }} style={{ marginBottom: '16px' }}>
                          <StatusTag status={worker.status} style={{ marginRight: '8px' }} />
                          <Text type="secondary">
                            {`Created ${moment(worker.createdDate).format('DD/MM/YYYY')}
                            ${
                              worker.trainingStartDate
                                ? ` · Verified ${moment(worker.trainingStartDate).format('DD/MM/YYYY')}`
                                : ''
                            }
                            ${
                              worker.approvalDate
                                ? ` · Approved ${moment(worker.approvalDate).format('DD/MM/YYYY')}`
                                : ''
                            }
                            `}
                          </Text>
                        </Paragraph>

                        {/* Approved positions */}
                        {worker.approvedPositions.length > 0 && (
                          <section style={{ marginBottom: '16px' }}>
                            <Text strong type="secondary">
                              APPROVED POSITIONS
                            </Text>
                            <Row type="flex" gutter={4} style={{ margin: '4px 0 16px 0' }}>
                              {worker.approvedPositions.map((position, positionIndex) => (
                                <StatusTag key={positionIndex} status={position.name} />
                              ))}
                            </Row>
                          </section>
                        )}

                        {/* Experiences */}
                        {worker.experiences.length > 0 && (
                          <section>
                            <Text strong type="secondary">
                              EXPERIENCE
                            </Text>
                            {worker.experiences.map((experience, experienceIndex) => (
                              <Paragraph key={experienceIndex} ellipsis={{ rows: 1 }} style={{ marginBottom: '2px' }}>
                                <Text strong>{`${experience.position}`}</Text>
                                <Text type="secondary">{` · ${experience.companyName} · ${experience.period}`}</Text>
                              </Paragraph>
                            ))}
                          </section>
                        )}
                      </Col>
                      <Col span={5} style={{ textAlign: 'right' }}>
                        {this.getWorkerActions(worker.status, worker.name, worker.id)}
                      </Col>
                    </Row>
                  </Card>
                </Row>
              );
            }}
          />
        </Col>
        <Col span={8}>
          <DetailCard
            inline
            headerTitle={overview.title}
            contentList={overview.contentList}
            loading={!hasFetchedWorkers}
          />
        </Col>

        {/* Modals */}
        <RejectWorkerModal
          visible={showRejectModal}
          workerId={selectedWorker.id}
          workerName={selectedWorker.name}
          onUpdate={() => this.onRejectWorker(selectedWorker.id, selectedWorker.name)}
          onClose={() => this.setState({ showRejectModal: false })}
        />
      </Row>
    );
  }
}

export default WorkerListView;
