import React from 'react';
import paymentApi from '../../../../services/paymentApi';
import PaymentDetail from './PaymentReportPage';

export default class PaymentReportContainer extends React.Component {
  state = {
    paymentReport: null,
  };

  componentDidMount() {
    this.fetchPaymentReport();
  }

  fetchPaymentReport = () => {
    const { id } = this.props.match.params;
    paymentApi.fetchPaymentReportById(id).then(paymentReport => this.setState({ paymentReport }));
  };

  render() {
    return <PaymentDetail paymentReport={this.state.paymentReport} onUpdate={this.fetchPaymentReport} />;
  }
}
