import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { paymentReportStatuses } from '../../constants';
import { colors } from '../../styles/colors';
import Label from './Label';

function getStatusStyle(status) {
  switch (status) {
    case paymentReportStatuses.UNPAID:
      return `
        background: ${colors.yellow};
        color: ${colors.white};
        border-color: ${colors.yellow};
      `;
    case paymentReportStatuses.ERROR:
      return `
        background: ${colors.orange};
        color: ${colors.white};
        border-color: ${colors.orange};
      `;
    case paymentReportStatuses.PAID:
      return `
        background: ${colors.green};
        color: ${colors.white};
        border-color: ${colors.green};
      `;
    default:
      return `
        background: ${colors.darkGrey};
        color: ${colors.white};
        border-color: ${colors.darkGrey};
      `;
  }
}

const CustomLabel = styled(Label)`
  ${props => getStatusStyle(props.status)};
`;

export default ({ status, ...props }) => (
  <CustomLabel status={status} {...props}>
    {_.startCase(status)}
  </CustomLabel>
);
