import { Col, Input, Row, Select, Typography } from 'antd';
import React from 'react';

import ClientFilter from '../components/ClientFilter';
import PositionFilter from '../components/PositionFilter';
import StatusFilter from '../components/StatusFilter';

const { Text } = Typography;

export default class FiltersContainer extends React.Component {
  onFiltersChange = (type, value) => {
    const newFilters = {
      ...this.props.filters,
      [type]: value,
    };
    this.props.onFiltersChange(newFilters);
  };

  render() {
    const { status, clients, positions, ordering } = this.props.filters;
    return (
      <Row type="flex" justify="space-between" align="bottom" style={{ marginBottom: '48px' }}>
        <Col span={14}>
          <Row style={{ marginBottom: '16px' }} gutter={8}>
            <Col span={6}>
              <StatusFilter
                key={status}
                selectedOptions={status}
                onChange={values => this.onFiltersChange('status', values)}
              />
            </Col>
            <Col span={6}>
              <ClientFilter
                selectedOptions={clients}
                onSelectChange={values => this.onFiltersChange('clients', values.map(value => value.key))}
              />
            </Col>
            <Col span={6}>
              <PositionFilter
                selectedOptions={positions}
                onSelectChange={values => this.onFiltersChange('positions', values.map(value => value.key))}
              />
            </Col>
          </Row>
          <Row>
            <Input.Search
              allowClear
              style={{ zIndex: 1 }}
              placeholder="Search"
              onSearch={searchValue => this.onFiltersChange('search', searchValue)}
            />
          </Row>
        </Col>

        {/* Sort by filters */}
        <Col style={{ width: '220px' }}>
          <Text type="secondary">Sort by</Text>
          <Select
            key={ordering}
            value={ordering}
            defaultValue={ordering || '-created_date'}
            style={{ marginLeft: '8px', width: '160px' }}
            onSelect={orderByValue => this.onFiltersChange('ordering', orderByValue)}
          >
            <Select.Option value="-created_date">Most recent</Select.Option>
            <Select.Option value="start_time">Earliest start date</Select.Option>
            <Select.Option value="end_time">Earliest end date</Select.Option>
            <Select.Option value="-staff_required">Most staff required</Select.Option>
          </Select>
        </Col>
      </Row>
    );
  }
}
