// Ducks separates state to different entities, e.g User, Partners.. etc
// Refer to './user' for comments on structure. More on: https://github.com/erikras/ducks-modular-redux
import { combineReducers } from 'redux';
import globalReducer from './global';
import userReducer from './user';

const appReducer = combineReducers({
  user: userReducer,
  global: globalReducer,
});

const rootReducer = (state, action) => {
  // Intercepts logout action. Remove & reset redux store in local storage.
  if (action.type === 'USER_LOGOUT_ACTION') {
    localStorage.clear();
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
