import React from 'react';
import { Modal, message, Typography, notification } from 'antd';

import managerApi from '../../../../../services/managerApi';
import { httpResponseStatuses } from '../../../../../constants';

class RemoveManagerModal extends React.Component {
  handleSubmit = async () => {
    const { manager } = this.props;

    try {
      if (manager) {
        await managerApi.deleteManager(manager);
        notification.success({
          message: 'Manager removed succesfully',
        });
      }
      this.props.onUpdate();
      this.handleClose();
    } catch (error) {
      if (error.response.status === httpResponseStatuses.FORBIDDEN) {
        message.error('You do not have permission to remove this manager.');
      } else {
        message.error(error.message);
      }
    }
  };

  handleClose = () => {
    this.props.onClose();
  };

  render() {
    const { manager, visible } = this.props;
    return (
      <Modal
        visible={visible}
        title="Remove Manager"
        okText="Yes, remove"
        okType="danger"
        onOk={this.handleSubmit}
        onCancel={this.handleClose}
      >
        <Typography.Text>
          You are about to remove <strong>{manager?.name}</strong> from this client. Are you sure?
        </Typography.Text>
      </Modal>
    );
  }
}

export default RemoveManagerModal;
