/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Button, Dropdown, Menu, Icon } from 'antd';

const TopNavDropdown = ({ text, menuList }) => {
  const menu = (
    <Menu className="v2-user-dropdown-menu">
      {menuList.map(({ onClick, name }, index) => (
        <Menu.Item key={`${index}`} onClick={onClick}>
          {name}
        </Menu.Item>
      ))}
    </Menu>
  );
  return (
    <Dropdown overlay={menu} trigger={['click']}>
      <Button type="link" style={{ color: 'white', opacity: '0.65' }}>
        {text} <Icon type="down" />
      </Button>
    </Dropdown>
  );
};

export default TopNavDropdown;
