import React from 'react';
import styled from 'styled-components';
import NumberFormat from 'react-number-format';
import { fieldCss } from './Field';

const CustomNumberFormat = styled(NumberFormat)`
  ${fieldCss};
`;

const Container = styled.div``;

const placeholder = 'YYYY';

const mask = ['Y', 'Y', 'Y', 'Y'];

export default class YearInput extends React.Component {
  handleValueChange = value => {
    const { onChange } = this.props;

    if (value.value.length === mask.length) {
      console.log(value.floatValue);
      return onChange(value.floatValue);
    }
    return onChange(undefined);
  };

  render() {
    const { onChange, value, ...props } = this.props;

    return (
      <Container {...props}>
        <CustomNumberFormat
          format="####"
          placeholder={placeholder}
          mask={mask}
          isNumericString
          value={value}
          onValueChange={this.handleValueChange}
        />
      </Container>
    );
  }
}
