import React, { Component } from 'react';
import { Row, DatePicker, Modal, Typography, Form, Switch, Icon, Button, Alert, message } from 'antd';
import Text from 'antd/lib/typography/Text';
import paymentApi from '../../../../services/paymentApi';
import { getStartOfNextDay } from '../../../../utilities/dateUtils';
import { countries } from '../../../../constants';
import SgClientSelect from './SgClientSelect';

class SgGenerateReportModal extends Component {
  state = {
    isLoading: false,
    showForm: true,
    showGenerationConfirmation: false,
    showPlacementGenerationConfirmation: false,
    successCount: null,
    failedCount: 0,
  };

  generateReport = async () => {
    if (!this.state.showForm) {
      this.handleClose();
    } else {
      this.props.form.validateFields(async (err, values) => {
        if (!err) {
          this.setState({ isLoading: true });
          const { clients } = values;
          const clock_in_before = getStartOfNextDay(values.clock_in_before);

          try {
            const responses = await Promise.all(
              clients.map(async client => {
                const filterParams = {
                  include_dco: values.include_dco || true,
                  country: countries.singapore.id, // TODO: Use code instead of ID
                  client,
                  clock_in_before,
                };
                return paymentApi.generate(filterParams);
              }),
            );

            responses.forEach(response => {
              if (response.send_email) {
                this.setState({ showGenerationConfirmation: true });
              } else {
                this.setState(prevState => ({
                  successCount: prevState.successCount + response.success_count,
                  failedCount: prevState.failedCount + response.failed_count,
                }));
              }
            });
          } catch (error) {
            message.error(`Payment report generation failed: ${error}`);
          } finally {
            this.setState({ isLoading: false, showForm: false });
            this.props.onChange();
          }
        }
      });
    }
  };

  generatePlacementReport = async () => {
    if (!this.state.showForm) {
      this.handleClose();
    } else {
      this.props.form.validateFields(async (err, values) => {
        if (!err) {
          this.setState({ isLoading: true });
          const clock_in_before = getStartOfNextDay(values.clock_in_before);
          const filterParams = {
            clients: values.clients.join(','),
            clock_in_before,
          };
          try {
            await paymentApi.generateSgPlacementReport(filterParams);
            this.setState({
              showPlacementGenerationConfirmation: true,
            });
          } catch (error) {
            message.error(`Placement report generation failed: ${error}`);
          } finally {
            this.setState({ isLoading: false });
          }
        }
      });
    }
  };

  handleClose = () => {
    this.props.onCancel();
    this.props.form.resetFields();
    this.setState({
      showGenerationConfirmation: false,
      showPlacementGenerationConfirmation: false,
      successCount: null,
      failedCount: null,
      showForm: true,
      isLoading: false,
    });
  };

  render() {
    const {
      isLoading,
      showForm,
      showGenerationConfirmation,
      showPlacementGenerationConfirmation,
      failedCount,
      successCount,
    } = this.state;
    const { visible } = this.props;
    const { getFieldDecorator, setFieldsValue } = this.props.form;
    return (
      <Modal
        title={<Typography.Title level={4}>Generate payment reports</Typography.Title>}
        visible={visible}
        width={500}
        confirmLoading={isLoading}
        onCancel={this.handleClose}
        footer={
          <div>
            <Button
              type="v2-secondary"
              icon="file-add"
              onClick={this.generatePlacementReport}
              disabled={showPlacementGenerationConfirmation}
            >
              Generate Placement Report
            </Button>
            <Button type="button" onClick={this.handleClose}>
              Cancel
            </Button>
            <Button type="v2-primary" onClick={this.generateReport}>
              {showForm ? 'Next' : 'Done'}
            </Button>
          </div>
        }
      >
        {showPlacementGenerationConfirmation && (
          <Alert
            style={{ marginBottom: 16 }}
            message="Generating placement reports..."
            description={<>Your placement reports are being generated. We will email you when they are done.</>}
            type="info"
            showIcon
          />
        )}
        {showGenerationConfirmation && (
          <Alert
            style={{ marginBottom: 8 }}
            message="Generating payment reports..."
            description={<>Your payment reports are being generated. We will email you when they are done.</>}
            type="info"
            showIcon
          />
        )}
        {successCount !== null && (
          <Alert
            style={{ marginBottom: 8 }}
            message="Payment reports generated"
            description={<>{successCount} payment reports ready for viewing</>}
            type="success"
            showIcon
          />
        )}
        {failedCount > 0 && (
          <Alert
            style={{ marginBottom: 8 }}
            message="There were errors in generating reports"
            description={<>{failedCount} payment reports generated with error</>}
            type="error"
            showIcon
          />
        )}
        {showForm && (
          <div>
            <Row style={{ marginBottom: '24px' }}>
              <Typography.Text>
                Generate payment reports for specific client before a specified date. All new unpaid items will be added
                to reports. You will receive an email within few minutes after you click Next.
              </Typography.Text>
            </Row>

            <Form onSubmit={this.handleSubmit} layout="vertical">
              <Form.Item label="Client">
                {getFieldDecorator('clients', {
                  rules: [{ required: true, message: 'Please choose client' }],
                })(<SgClientSelect style={{ width: '100%' }} />)}
              </Form.Item>
              <Form.Item label="Clock in time until (End of day)">
                {getFieldDecorator('clock_in_before', {
                  rules: [{ required: true, message: 'Please choose clock in end date' }],
                })(<DatePicker style={{ width: '100%' }} placeholder="Select clock in end date" format="DD/MM/YYYY" />)}
              </Form.Item>
              <Form.Item>
                {getFieldDecorator('include_dco')(
                  <>
                    <Switch
                      defaultChecked
                      checkedChildren={<Icon type="check" />}
                      unCheckedChildren={<Icon type="close" />}
                      onChange={checked => setFieldsValue({ include_dco: checked })}
                    />
                    <Text style={{ marginLeft: 8 }}>Include DCO deduction</Text>
                  </>,
                )}
              </Form.Item>
            </Form>
          </div>
        )}
      </Modal>
    );
  }
}

export default Form.create({ name: 'generate_report_form' })(SgGenerateReportModal);
