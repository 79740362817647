import styled from 'styled-components';
import Checkbox from './Checkbox';

const CustomCheckbox = styled(Checkbox)``;

const CheckboxGroup = styled.div`
  ${CustomCheckbox} {
    margin-right: 24px;
  }
`;

CheckboxGroup.Checkbox = CustomCheckbox;

export default CheckboxGroup;
