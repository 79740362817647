import React from 'react';
import { List, Tag } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import routes from '../../../../routes';
import { colors } from '../../../../styles/colors';
import AvatarImage from '../../../../shared/components/AvatarImage';
import ButtonWithLoading from '../../../../shared/components/ButtonWithLoading';

const CustomTitle = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default ({ height, partners, total, currentPartner, onPageChange, onSelect, createRecommendation }) => {
  return (
    <List
      itemLayout="horizontal"
      size="small"
      bordered
      style={{
        background: colors.white,
        height,
        overflowY: 'auto',
      }}
      dataSource={partners}
      pagination={{
        onChange: page => {
          onPageChange(page);
        },
        pageSize: 20,
        total,
        size: 'small',
        simple: true,
      }}
      renderItem={partner => (
        <List.Item
          key={partner.id}
          onClick={() => onSelect(partner.id)}
          style={{
            background: partner.id === currentPartner ? colors.grey : colors.white,
          }}
        >
          <List.Item.Meta
            avatar={<AvatarImage size="large" src={partner.image} />}
            title={
              <CustomTitle>
                <Link to={`${routes.partners}/${partner.id}`}>
                  {partner.first_name} {partner.last_name}
                </Link>
                {partner.status ? (
                  <Tag color="green">{partner.status}</Tag>
                ) : (
                  <ButtonWithLoading
                    initialText="Send Recommendation"
                    postText="Sent"
                    status={partner.sendState}
                    onClick={() => {
                      console.log(partner.id, partner.sendState);
                      createRecommendation(partner.id, 'map');
                    }}
                  />
                )}
              </CustomTitle>
            }
            description={partner.mobile}
          />
          {partner.address.street_1} {partner.address.street_2}
        </List.Item>
      )}
    />
  );
};
