import React from 'react';
import { withTranslation } from 'react-i18next';
import { Card, Row, Col, Typography, Modal, Avatar, Icon } from 'antd';
import { countries } from '../../../constants';

const { Text } = Typography;

class IdDetailCard extends React.Component {
  state = {
    imageModalVisible: false,
  };

  render() {
    const {
      socialSecurityId,
      idNumber,
      idCardImage,
      nationality,
      race,
      religion,
      residency,
      permanentResidentDate,
      fundType,
      countryId,
      t,
    } = this.props;
    const { imageModalVisible } = this.state;
    const emptyState = (
      <Text type="secondary" style={{ color: '#BFBFBF' }}>
        Not provided
      </Text>
    );
    const partnerIdNumbers = [
      {
        title: 'Social security ID',
        content: socialSecurityId,
      },
      {
        title: 'ID card number',
        content: idNumber,
      },
    ];
    const partnerSensitiveInfo = [
      {
        title: 'Nationality',
        content: nationality?.name || 'Not provided',
      },
      {
        title: 'Race',
        content: t(race) || 'Not provided',
      },
      {
        title: 'Religion',
        content: t(religion?.name) || 'Not provided',
      },
      {
        title: 'Residency',
        content: t(residency) || 'Not provided',
      },
      {
        title: 'PR date (valid from)',
        content: permanentResidentDate || 'Not provided',
      },
      {
        title: 'Fund type',
        content: t(fundType) || 'Not provided',
      },
    ];
    return (
      <Card
        id="id-detail-card"
        title={
          <Text type="secondary" style={{ fontSize: '14px' }}>
            IDENTIFICATION
          </Text>
        }
        headStyle={{ padding: '8px 0 8px 12px', minHeight: 0 }}
        bodyStyle={{ padding: '12px' }}
        style={{ width: '373px' }}
      >
        {partnerIdNumbers.map(({ title, content }, index) => {
          return (
            <Row key={index} style={{ marginBottom: '16px' }}>
              <Col>
                <Text strong>{title}</Text>
              </Col>
              <Col>
                <Text>{content || emptyState}</Text>
              </Col>
            </Row>
          );
        })}
        <Row style={{ marginBottom: '16px' }}>
          <Col style={{ marginBottom: '4px' }}>
            <Text strong>ID card photo</Text>
          </Col>
          <Col>
            {idCardImage ? (
              <Avatar
                shape="square"
                size={128}
                icon="user"
                src={idCardImage}
                style={{ padding: '8px', border: '1px solid #D9D9D9' }}
                onClick={() =>
                  this.setState({
                    imageModalVisible: true,
                  })
                }
              />
            ) : (
              <Row
                type="flex"
                align="middle"
                style={{
                  width: '128px',
                  height: '128px',
                  background: '#F5F5F5',
                  border: '1px dashed #D9D9D9',
                  borderRadius: '4px',
                  justifyContent: 'center',
                }}
              >
                <Col>
                  <Row style={{ textAlign: 'center' }}>
                    <Icon type="inbox" style={{ fontSize: '24px', color: '#8C8C8C' }} />
                  </Row>
                  <Row>{emptyState}</Row>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
        {countryId === countries.singapore.id &&
          partnerSensitiveInfo.map(({ title, content }, index) => {
            return (
              <Row key={index} style={{ marginBottom: '16px' }}>
                <Col>
                  <Text strong>{title}</Text>
                </Col>
                <Col>
                  <Text>{content || emptyState}</Text>
                </Col>
              </Row>
            );
          })}
        <Modal visible={imageModalVisible} footer={null} onCancel={() => this.setState({ imageModalVisible: false })}>
          <img alt="ID card" style={{ width: '100%' }} src={idCardImage} />
        </Modal>
      </Card>
    );
  }
}

export default withTranslation()(IdDetailCard);
