/* eslint-disable no-await-in-loop */
import { Alert, Form, Icon, Modal, Row, Typography, Upload } from 'antd';
import Papa from 'papaparse';
import React from 'react';
import * as Yup from 'yup';
import paymentApi from '../../../../services/paymentApi';

const adjustmentSchema = Yup.object().shape({
  timesheet_entry_id: Yup.number()
    .integer()
    .positive()
    .required(),
  type: Yup.string().required(),
  amount: Yup.number().required(),
  description: Yup.string().required(),
});

class UploadAdjustmentModal extends React.Component {
  state = {
    isLoading: false,
    disabled: false,
    successDescription: '.',
    errorDescription: '',
    adjustments: [],
    showSuccessMessage: false,
    showFailedMessage: false,
    showUpload: true,
  };

  clearState = () => {
    this.setState({ showSuccessMessage: false, showFailedMessage: false });
  };

  handleSubmit = async () => {
    this.setState({ isLoading: true });
    this.clearState();
    const response = await paymentApi.uploadAdjustment({ items: this.state.adjustments });
    const successCount = response.success_count;
    const failedCount = response.failed_count;
    const failedItems = response.failed_items;
    if (successCount > 0) {
      this.setState({ successDescription: `${successCount} items adjusted`, showSuccessMessage: true });
    } else {
      this.setState({ showSuccessMessage: false });
    }
    if (failedCount > 0) {
      this.setState({ showFailedMessage: true });
      this.generateErrorDescription(failedItems);
    } else {
      this.setState({ showFailedMessage: false, showUpload: false });
    }
    this.setState({ isLoading: false });
    this.props.form.resetFields();

    this.props.onChange();
  };

  handleChange = ({ file }) => {
    this.clearState();
    this.setState({ adjustments: [], disabled: true });
    const adjustments = [];
    const fileObj = file.originFileObj;
    const errorMessageList = [];

    Papa.parse(fileObj, {
      header: true,
      skipEmptyLines: true,
      step: (result, parser) => {
        const { data } = result;
        if (data[0] === '') parser.abort();
        adjustments.push(data);
      },
      complete: async () => {
        const validAdjustment = [];
        for (let i = 0; i < adjustments.length; i += 1) {
          const isValid = await this.validateData(adjustments[i]);
          if (isValid) {
            const parsedData = await this.parseData(adjustments[i]);
            validAdjustment.push(parsedData);
          } else errorMessageList.push({ row: i + 1, error: 'Value is not valid' });
        }

        this.setState({ adjustments: validAdjustment, disabled: false });
        if (errorMessageList.length > 0) {
          this.generateErrorDescription(errorMessageList);
        }
      },
    });
  };

  validateData = async data => {
    const valid = await adjustmentSchema.isValid(data);
    return valid;
  };

  parseData = async data => {
    const castedData = await adjustmentSchema.cast(data);
    return {
      timesheet_entry: castedData.timesheet_entry_id,
      type: castedData.type.toLowerCase(),
      unit_cost: castedData.amount,
      description: castedData.description,
    };
  };

  generateErrorDescription = rows => {
    const errorMessages = rows.map(row => (
      <>
        Row {row.row}: {row.error} <br />
      </>
    ));
    this.setState({
      showFailedMessage: true,
      errorDescription: (
        <div>
          {errorMessages} <p style={{ marginTop: '5px' }}>Correct the spreadsheet, upload the CSV and try again.</p>
        </div>
      ),
    });
  };

  normFile = e => {
    e.file.status = 'done';
    e.fileList = [e.file];
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  handleRequest = e => {
    console.log('request', e);
  };

  handleClose = () => {
    this.props.form.resetFields();
    this.setState({ isLoading: false, showFailedMessage: false, showSuccessMessage: false, showUpload: true });
    this.props.onCancel();
  };

  render() {
    const { visible } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { isLoading } = this.state;

    return (
      <Modal
        title={<Typography.Title level={4}>Bulk adjustment</Typography.Title>}
        visible={visible}
        onCancel={this.handleClose}
        onOk={this.handleSubmit}
        width={500}
        okType="v2-primary"
        okText="Submit"
        cancelText="Close"
        confirmLoading={isLoading}
        okButtonProps={{ disabled: !this.props.form.getFieldValue('adjustment') }}
      >
        <Row style={{ marginBottom: '24px' }}>
          <Typography.Text>
            Upload a CSV file generated from the adjustment spreadsheet to bulk edit timesheet entry type, amount, and
            description.
          </Typography.Text>
        </Row>
        <Row>
          <a href="/template/adjustment_template.csv" download>
            Download Template
          </a>
        </Row>
        <Row>
          {this.state.showSuccessMessage && (
            <Alert
              style={{ marginBottom: '24px' }}
              message="CSV successfully imported"
              description={this.state.successDescription}
              type="success"
              showIcon
            />
          )}
          {this.state.showFailedMessage && (
            <Alert
              style={{ marginBottom: '24px' }}
              message="There were errors in the CSV import"
              description={this.state.errorDescription}
              type="error"
              showIcon
            />
          )}
        </Row>
        {this.state.showUpload ? (
          <Form>
            <Form.Item>
              {getFieldDecorator('adjustment', {
                valuePropName: 'fileList',
                getValueFromEvent: this.normFile,
              })(
                <Upload.Dragger
                  customRequest={this.handleRequest}
                  disabled={this.state.disabled}
                  onChange={this.handleChange}
                  multiple={false}
                  accept=".csv"
                >
                  <p className="ant-upload-drag-icon">
                    <Icon type="inbox" style={{ color: '#69E4A6' }} />
                  </p>
                  <p className="ant-upload-text">Click or drag and drop to upload CSV</p>
                  <p className="ant-upload-hint">
                    The CSV file must contain headers for timesheet entry ID, type, amount, and description.
                  </p>
                </Upload.Dragger>,
              )}
            </Form.Item>
          </Form>
        ) : (
          ''
        )}
      </Modal>
    );
  }
}

export default Form.create({ name: 'upload_adjustment' })(UploadAdjustmentModal);
