import React from 'react';
import { Loader } from 'semantic-ui-react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

const Container = styled.div`
  position: relative;
  overflow-y: auto;
`;

const LoaderContainer = styled.div`
  height: 80px;
  position: relative;
`;

export default class InfiniteList extends React.Component {
  static propTypes = {
    onScrollBottom: PropTypes.func.isRequired,
    render: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
  };

  handleScroll = e => {
    const offset = 200;
    const bottom = e.target.clientHeight + e.target.scrollTop >= e.target.scrollHeight - offset;
    if (bottom) {
      this.props.onScrollBottom();
    }
  };

  render() {
    const { onScrollBottom, render, loading, ...props } = this.props;

    return (
      <Container {...props} onScroll={this.handleScroll}>
        {render()}
        <LoaderContainer>
          <Loader size="small" active={loading} />
        </LoaderContainer>
      </Container>
    );
  }
}
