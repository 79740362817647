import React from 'react';
import { Pagination } from 'semantic-ui-react';
import styled from 'styled-components';
import { rgba } from 'polished';
import PropTypes from 'prop-types';
import { colors } from '../../styles/colors';

const StyledPagination = styled(Pagination)`
  &.ui.pagination.menu {
    box-shadow: 0 2px 6px ${rgba(colors.absoluteBlack, 0.04)};
    border: 1px solid ${colors.lightGrey};
  }
`;

const Total = styled.div``;

const Container = styled.div`
  display: flex;
  align-items: center;

  ${Total} {
    margin-right: 20px;
  }
`;

const CustomPagination = ({ page, total, totalPages, onPageChange, ...props }) => (
  <Container {...props}>
    {total > 0 && <Total>{total} items</Total>}
    {totalPages && totalPages > 1 && (
      <StyledPagination
        firstItem={false}
        lastItem={false}
        activePage={page}
        totalPages={totalPages}
        onPageChange={(e, { activePage }) => onPageChange(activePage)}
      />
    )}
  </Container>
);

CustomPagination.propTypes = {
  page: PropTypes.number.isRequired,
  total: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
};

export default CustomPagination;
